import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { WFTmpWriteMode } from '../model';

@Component({
  selector: 'app-template-name',
  templateUrl: './template-name.component.html',
  styleUrls: ['./template-name.component.scss']
})

export class TemplateNameComponent implements AfterViewInit {
  public m_busy_str: string;

  public m_tmpname: string;
  public m_tmpname_err: boolean;
  public m_defmd: WFTmpWriteMode;

  @ViewChild('m_tmpwritemode', {static: false}) private m_tmpwritemode: ComboboxadpComponent;
  @Output() public onClosed: EventEmitter<boolean>;

  public get TmpName(): string {
    return this.m_tmpname.trim();
  }

  //

  public get WriteMode(): WFTmpWriteMode {
    return <WFTmpWriteMode>Number.parseInt(this.m_tmpwritemode.SelectedValue);
  }

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService) {

    this.m_busy_str = '';

    this.m_tmpname = '';
    this.m_tmpname_err = false;
    this.m_defmd = WFTmpWriteMode.ALLOWALL;

    this.onClosed = new EventEmitter<boolean>();
  }

  public ngAfterViewInit(): void {
    try {
      this.m_tmpwritemode.AddValidValue((<number>WFTmpWriteMode.DENYALL).toString(), this.m_strdata_srv.getStr('strTmpWriteMode_DenyAll'));
      this.m_tmpwritemode.AddValidValue((<number>WFTmpWriteMode.ALLOWALL).toString(), this.m_strdata_srv.getStr('strTmpWriteMode_AllowAll'));
      this.m_tmpwritemode.AddValidValue((<number>WFTmpWriteMode.ALLOWONLYCONTENT).toString(), this.m_strdata_srv.getStr('strTmpWriteMode_AllowEditContent'));
      this.m_tmpwritemode.SelectedValue = (<number>this.m_defmd).toString();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SetPrms(defmd: WFTmpWriteMode): void {
    this.m_defmd = defmd;
  }

  public OKButton_Click(): void {
    let nval = this.m_tmpname.trim();
    if (nval.length > 0) {
      this.m_tmpname_err = false;
    } else {
      this.m_tmpname_err = true;
      return;
    }

    this.onClosed.emit(true);
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

import { IWFCompanyObjAdp } from './IWFCompanyObjAdp';
import { WFInteractiveDictAdp } from './WFInteractiveDictAdp';
import { IWFDataFetchAdp, FetchData } from './IWFDataFetchAdp';
import { IWFObject, WFClassAttrib } from '../model/index';
import { CacheService } from '../data/cache.service';
//

export type FetchCompleted = (vv: Map<string, string>, ustate: Object) => void;

export abstract class IValidValues {
    protected m_fetchcplevh: FetchCompleted;

    public SetOnFetchCompleted(del: FetchCompleted): void {
        this.m_fetchcplevh = del;
    }

    public abstract FetchValidValues(serv: CacheService, ustate: Object, fromcache: boolean, lid: number): void;
}

export class WFDocumentValidValues extends IValidValues {
    private m_doc: IWFCompanyObjAdp;
    private m_dict: WFInteractiveDictAdp;
    private m_fetchdtaevh: FetchData;

    constructor(doc: IWFCompanyObjAdp, dict: WFInteractiveDictAdp) {
        super();
        this.m_doc = doc;
        this.m_dict = dict;
        const self = this;
        this.m_fetchdtaevh = (snd, dta, ustate) => {
            self.OnFetchData(snd, dta, ustate);
        };
    }
    
    private OnFetchData(snd: IWFDataFetchAdp, dta: Array<[string, Array<Object>]>, ustate: Object): void {
        let ii: number;

        this.m_dict.OnFetchData = null;
        if (dta != null) {
            if (dta.length > 1) {
                let values: Map<string, string> = new Map<string, string>();
                let valcol: [string, Array<Object>] = dta[0];
                let dsccol: [string, Array<Object>] = dta[1];
                for (ii = 0; ii < valcol[1].length; ii++) {
                    let val: Object = valcol[1][ii];
                    if (val != null) {
                        let sval: string = val.toString();
                        if (sval.length > 0) {
                            let dsc: Object = dsccol[1][ii];
                            let sdsc: string = (dsc == null) ? '' : dsc.toString();
                            values.set(sval, (sdsc.length > 0) ? sdsc : sval);
                        }
                    }
                }
                if (this.m_fetchcplevh != null)
                    this.m_fetchcplevh(values, ustate);
            }
        }
    }

    public FetchValidValues(serv: CacheService, ustate: Object, fromcache: boolean, lid: number): void {
        let ii: number;
        let prms: Map<number, Object> = new Map<number, Object>();
        for (ii = 0; ii < this.m_dict.Parameters.length; ii++) {
            let pr: WFClassAttrib = this.m_dict.Parameters[ii];
            if (!IWFObject.IsNullOrEmpty(pr.EvalCode)) {
                let oval: Object = this.m_doc.EvalExCode(pr.EvalCode, lid, pr.Type);
                if (oval != null) {
                    if (typeof oval === 'string') {
                        let sval: string = <string>oval;
                        sval = sval.trim();
                        if (sval.length === 0)
                            continue;
                        let off: number = sval.indexOf('<!>');
                        if (off > 0) {
                            oval = sval.substr(0, off);
                        }
                    }
                    prms.set(pr.ID, oval);
                }
            }
        }

        let dbname = (this.m_doc.CompanyID > 0) ? serv.Companies.get(this.m_doc.CompanyID).DBName : '';
        
        this.m_dict.OnFetchData = this.m_fetchdtaevh;
        this.m_dict.FetchDictData(serv, prms, fromcache, dbname, ustate);
    }
}
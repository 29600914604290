import { Component, OnInit, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, BUTTONSTYPE, WNDRESULT } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { UserPropertySpec, PropertySpec, CONVERT, DocviewerComponent } from '../docviewer/docviewer.component';
import { WFDocumentValidValues } from '../adapters/WFDocumentValidValues';
import { DatePipe } from '@angular/common';
import { WFDailyCorrespondenceAdp, PublishDailyCorrespondence, RemoveDailyCorrespondence } from '../adapters/WFDailyCorrespondenceAdp';
import { PropertyValidation, IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';
import { IWFObject, WFClassAttrib, WFAuthPermission, WFFieldType, WFCompany } from '../model';
import { SbobusinesspartnerComponent } from '../sbobusinesspartner/sbobusinesspartner.component';
import { EventAdp } from '../data/eventadp';
import { _OnError } from '../adapters/WFDocumentAdp';

@Component({
  selector: 'app-edit-daily-correspondence',
  templateUrl: './edit-daily-correspondence.component.html',
  styleUrls: ['./edit-daily-correspondence.component.scss']
})

export class EditDailyCorrespondenceComponent implements AfterViewInit {
  public m_busy_str: string;

  @Output() public onClosed: EventEmitter<boolean>;

  @ViewChild('m_inf_cls_cmb', {static: false}) private m_inf_cls_cmb: ComboboxadpComponent;
  @ViewChild('m_inf_company_cmb', {static: false}) private m_inf_company_cmb: ComboboxadpComponent;
  @ViewChild('m_inf_owner_cmb', {static: false}) private m_inf_owner_cmb: ComboboxadpComponent;
  @ViewChild('m_inf_optype_cmb', {static: false}) private m_inf_optype_cmb: ComboboxadpComponent;

  public m_inf_company: PropertySpec;
  public m_inf_owner: PropertySpec;
  public m_inf_optype: PropertySpec;
  public m_inf_desc: PropertySpec;
  public m_inf_docdate: PropertySpec;
  public m_inf_cls: PropertySpec;

  public m_datep: DatePipe;
  private m_curdocintmap: Map<number, WFDocumentValidValues>;
  public m_dateformat: string;

  public m_inf_props: Array<UserPropertySpec>;

  public m_entry: WFDailyCorrespondenceAdp;

  private m_propvalidation: PropertyValidation;
  private m_publishdcorr: PublishDailyCorrespondence;
  private m_removedcorr: RemoveDailyCorrespondence;

  private m_refafterclose: boolean;
  private m_selclsevh: (nval: string) => void;

  public m_deletebutton: boolean;
  private m_onerrorevh: EventAdp<_OnError>;
  public m_inf_cdate: string;

  public get NeedRefreshAfterClose(): boolean {
    return this.m_refafterclose;
  }

  public get Entry(): WFDailyCorrespondenceAdp {
    return this.m_entry;
  }

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {

    this.m_busy_str = '';
    this.onClosed = new EventEmitter<boolean>();

    this.m_inf_company = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };
    this.m_inf_owner = { iseditable: false, iserror: false, ismandatory: true, conv: CONVERT.TOINT };
    this.m_inf_optype = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };

    this.m_inf_desc = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };
    this.m_inf_docdate = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };

    this.m_inf_cls = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };

    this.m_datep = new DatePipe('en-US');
    this.m_dateformat = 'dd-MM-yyyy';
    this.m_curdocintmap = new Map<number, WFDocumentValidValues>();

    this.m_inf_props = new Array<UserPropertySpec>();
    this.m_entry = null;

    const self = this;
    this.m_propvalidation = (snd, prop, status) => {
      self.OnPropertyValidation(snd, prop, status);
    };

    this.m_publishdcorr = (snd, status) => {
      self.OnPublishDailyCorrespondence(snd, status);
    };

    this.m_removedcorr = (snd, status, oldid) => {
      self.OnRemoveDailyCorrespondence(snd, status, oldid);
    };

    this.m_selclsevh = (nval) => {
      self.OnChangeClass(nval);
    };

    this.m_refafterclose = false;
    this.m_deletebutton = true;

    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });

    this.m_inf_cdate= '';
  }

  //

  public SetPrms(entry: WFDailyCorrespondenceAdp): void {
    if (this.m_entry != null) {
      this.m_entry.OnError.unsubscribe2(this.m_onerrorevh);
      this.m_entry.OnPropertyValidation = null;
      this.m_entry.OnPublishDailyCorrespondence = null;
      this.m_entry.OnRemoveDailyCorrespondence = null;
    }

    this.m_entry = entry;
    this.m_deletebutton = this.m_entry.CanDeleteEntry;

    this.m_entry.OnPropertyValidation = this.m_propvalidation;
    this.m_entry.OnPublishDailyCorrespondence = this.m_publishdcorr;
    this.m_entry.OnRemoveDailyCorrespondence = this.m_removedcorr;

    this.m_entry.OnError.subscribe2(this.m_onerrorevh);
  }

  //

  public ngAfterViewInit(): void {
    try {
      this.m_inf_company_cmb.ClearValidValues();
      let scmp= Array.from(this.m_cache_srv.Companies.values()).sort((a,b)=> a.Company.localeCompare(b.Company));
      for (let cm of scmp) {
        this.m_inf_company_cmb.AddValidValue(cm.ID.toString(), cm.Company);
      }

      //user
      this.m_inf_owner_cmb.ClearValidValues();
      this.m_inf_owner_cmb.AddValidValue(this.m_cache_srv.User.ID.toString(), this.m_cache_srv.User.FriendlyName);
      let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
      for (let us of susr) {
        this.m_inf_owner_cmb.AddValidValue(us.ID.toString(), us.FriendlyName);
      }

      //optype
      this.m_inf_optype_cmb.AddValidValue('0', this.m_strdata_srv.getStr('strEntry'));
      this.m_inf_optype_cmb.AddValidValue('1', this.m_strdata_srv.getStr('strExit'));
      this.m_inf_optype_cmb.AddValidValue('2', this.m_strdata_srv.getStr('strOther'));

      //klasy
      IWFObject.SafeUnsubscribe(this.m_inf_cls_cmb.SelectedValueChange);

      let clsadded = new Array<number>();

      this.m_inf_cls_cmb.ClearValidValues();
      let scls= Array.from(this.m_cache_srv.DailyCorrepondenceClasses.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
      for (let cl of scls) {
        if (cl.Enabled && cl.CanAddNewEntry) {
          this.m_inf_cls_cmb.AddValidValue(cl.ID.toString(), IWFObject.Format('{0} - {1}', cl.Name.toUpperCase(), cl.Description.trim()));
          clsadded.push(cl.ID);
        }
      }

      //dodaj jesli wczesniej wybrano
      if (this.m_entry.DailyCorrClassID > 0 && clsadded.indexOf(this.m_entry.DailyCorrClassID) < 0 && this.m_entry.ID > 0) {
        let cl = this.m_cache_srv.DailyCorrepondenceClasses.get(this.m_entry.DailyCorrClassID);
        this.m_inf_cls_cmb.AddValidValue(cl.ID.toString(), IWFObject.Format('{0} - {1}', cl.Name.toUpperCase(), cl.Description.trim()));
      }

      this.m_inf_cls_cmb.SelectedValue = this.m_entry.DailyCorrClassID.toString();

      this.ReloadClassAttribs();
      this.m_inf_cls_cmb.SelectedValueChange.subscribe(this.m_selclsevh);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SetHeader(prop: string, val: object, ps: PropertySpec): void {
    try {
      if (this.m_entry != null) {
        switch (ps.conv) {
          case CONVERT.TOINT:
            let nval = IWFObject.ParseInt(val.toString());
            if (this.m_entry[prop] === nval) return;
            this.m_entry[prop] = nval;
            break;
          default:
            if (this.m_entry[prop] === val) return;
            this.m_entry[prop] = val;
        }
      }
      ps.iserror = false;
    } catch (ex) {
      ps.iserror = true;
    }
  }

  public SetAttrib(prop: WFClassAttrib, val: object, ps: UserPropertySpec): void {
    try {
      if (this.m_entry != null) {
        if (val instanceof Date) {
          let dta = <Date>val;
          this.m_entry.set(prop.Name, this.m_datep.transform(dta, this.m_dateformat));
          ps.dateval = dta;
        } else {
          this.m_entry.set(prop.Name, (val == null) ? '' : val.toString());
        }
      }
    } catch (ex) {
      let dbg = '';
    }
  }

  public ParseDate(ps: UserPropertySpec): Date {
    let ret: Date = null;
    if (ps.dateval == null) {
      try {
        let str: string = this.m_entry.get(ps.attrib.Name);
        if (str != null && str.length > 9) {
          let day = IWFObject.ParseInt(str.substr(0, 2));
          let month = IWFObject.ParseInt(str.substr(3, 2));
          let year = IWFObject.ParseInt(str.substr(6, 4));
          ret = new Date(year, month - 1, day);
        }
      } catch (ex) {
        ps.iserror = true;
      }
      ps.dateval = ret;
    } else {
      ret = ps.dateval;
    }
    return ret;
  }

  private OnPropertyValidation(snd: IWFCompanyObjAdp, prop: string, status: boolean): void {
    let ii: number;
    try {
      for (ii = 0; ii < this.m_inf_props.length; ii++) {
        let ps = this.m_inf_props[ii];
        if (ps.attrib.Name.toUpperCase() === prop) {
          ps.iserror = !status;
          break;
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnChangeClass(nval: string): void {
    try {
      if (!IWFObject.IsNullOrEmpty(nval)) {
        let clsid = Number.parseInt(nval);
        this.m_entry.DailyCorrClassID = clsid;
        this.ReloadClassAttribs();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ReloadClassAttribs(): void {
    let ii: number;

    this.m_inf_props.splice(0, this.m_inf_props.length);

    let cmp = (this.m_entry.CompanyID > 0) ? this.m_cache_srv.Companies.get(this.m_entry.CompanyID) : null;
    let cls = this.m_cache_srv.DailyCorrepondenceClasses.get(this.m_entry.DailyCorrClassID);

    for (ii = 0; ii < cls.Attributes.length; ii++) {
      let att: WFClassAttrib = cls.Attributes[ii];
      this.m_inf_props.push({
        label: IWFObject.Format('{0}:', (IWFObject.IsNullOrEmpty(att.Label)) ? att.Name : att.Label).toUpperCase(),
        attrib: att,
        iseditable: this.m_entry.CanEdit(att),
        iserror: false,
        dateval: null,
        comboischb: DocviewerComponent.IsComboBoxAsCheckBox(this.m_cache_srv, this.m_entry, att)
      });
    }

    let desc_editable = this.m_entry.CanEdit2(WFAuthPermission.STDATR_DESCRIPTION);
    this.m_inf_desc.iseditable = desc_editable;

    this.m_inf_company.iseditable = this.m_entry.CanEdit2(WFAuthPermission.STDATR_AUTHSCHEMA);
    this.m_inf_cls.iseditable = this.m_entry.CanEdit2(WFAuthPermission.STDATR_CLASS);
    this.m_inf_docdate.iseditable = this.m_inf_optype.iseditable = this.m_entry.CanEdit2(WFAuthPermission.STDATR_DOCDATE);
  }

  private OnPublishDailyCorrespondence(snd: WFDailyCorrespondenceAdp, status: boolean): void {
    this.m_busy_str = '';
    if (status) {
      this.m_refafterclose = true;
      this.onClosed.emit(true);
    } else {
      this.m_refafterclose = false;
    }
  }

  private OnRemoveDailyCorrespondence(snd: WFDailyCorrespondenceAdp, status: boolean, oldid: number): void {
    try {
      this.m_busy_str = '';
      if (status) {
        this.m_refafterclose = true;
        this.onClosed.emit(true);
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('strRemoveDailyCorrFail'), oldid));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public FillComboBox(cmb: ComboboxadpComponent, att: WFClassAttrib): void {
    try {
      DocviewerComponent.FillComboBox(this.m_entry, this.m_curdocintmap, this.m_cache_srv, cmb, att);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public FillSboBusinessPartner(cmp: SbobusinesspartnerComponent, att: WFClassAttrib): void {
    const self = this;
    cmp.SetSource(this.m_entry, null);
  }

  public OKButton_Click(): void {
    try {
      if (this.m_entry.IsModified) {
        let errstr = this.m_entry.CheckMandatory();
        if (IWFObject.IsNullOrEmpty(errstr)) {
          this.m_busy_str = IWFObject.Format(this.m_strdata_srv.getStr('strDocUpdateInfo'), this.m_entry.ID);
          this.m_entry.Publish();
        } else {
          this.m_global_srv.showWarning(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('strErrPrms'), errstr));
        }
      } else {
        this.onClosed.emit(true);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public DeleteButton_Click(): void {
    try {
      if (this.m_entry.CanDeleteEntry) {
        const self = this;
        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strDeleteDailyCorr'), (res) => {
          self.DeleteDailyCorrClosed(res);
        });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private DeleteDailyCorrClosed(res: WNDRESULT): void {
    try {
      if (res === WNDRESULT.OKYES) {
        this.m_busy_str = this.m_strdata_srv.getStr('strRemovingDocumentInfo');
        this.m_entry.Remove();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ParseBoolean(str: string): boolean {
    if (str != null) return (str.toUpperCase() === 'Y');
    return false;
  }
}

import { IWFObject } from './IWFObject';

export enum WFGroupRole {
    GENERAL,
    CREATORS,
    SUPERUSERS
}

export class WFUserGroup {
    public static readonly LASTAUTH = -1;
    public static readonly ALLUSERS = 0;
    //
    
    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_users: Array<number>;
    private m_createdat: Date;
    private m_updatedat: Date;
    private m_grprole: WFGroupRole;

    private m_organization_id: number;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_users = obj.users;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_grprole = obj.grprole;
        this.m_organization_id = obj.organization_id;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get Users(): Array<number> {
        return this.m_users;
    }

    //

    public get GroupRole(): WFGroupRole {
        return this.m_grprole;
    }

    //

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }
}
import { WFMailMonitorCfg } from './WFMailMonitorCfg';

export enum WFOcrTaskMode {
    EX_DISK,
    EX_WEBSERVICE
}

export class WFConfiguration {
    private m_profile: string;
    private m_active: boolean;
    private m_defSMTPadr: string;
    private m_defSMTPport: number;
    private m_defSMTPuser: string;
    private m_defSMTPpass: string;
    private m_OCRinputpth: string;
    private m_OCRoutputpth: string;
    private m_sessfile: string;
    private m_seckey: string;
    private m_seciv: string;
    private m_clientid: string;
    private m_centralsrv: string;
    private m_serviceport: number;
    private m_ocrtype: WFOcrTaskMode;
    private m_initscale: number;
    private m_rescachelen: number;
    private m_sighours: number;
    private m_jsfunctions: string;
    private m_checkrefdelay: number;
    private m_remoteaddress: string;
    private m_mailmonitor: Array<WFMailMonitorCfg>;

    private m_ksef_apiurl: string;
    private m_ksef_pubkeyb64: string;
    
    constructor(obj:any) {
        this.m_profile = obj.profile;
        this.m_active = obj.active;
        this.m_defSMTPadr = obj.defSMTPadr;
        this.m_defSMTPport = obj.defSMTPport;
        this.m_defSMTPuser = obj.defSMTPuser;
        this.m_defSMTPpass = obj.defSMTPpass;
        this.m_OCRinputpth = obj.OCRinputpth;
        this.m_OCRoutputpth = obj.OCRoutputpth;
        this.m_sessfile = obj.sessfile;
        this.m_seckey = obj.seckey;
        this.m_seciv = obj.seciv;
        this.m_clientid = obj.clientid;
        this.m_centralsrv = obj.centralsrv;
        this.m_serviceport = obj.serviceport;
        this.m_ocrtype = obj.ocrtype;
        this.m_initscale = obj.initscale;
        this.m_rescachelen = obj.rescachelen;
        this.m_sighours = obj.sighours;
        this.m_jsfunctions = obj.jsfunctions;
        this.m_checkrefdelay = obj.checkrefdelay;
        this.m_remoteaddress = obj.remoteaddress;

        this.m_mailmonitor = new Array<WFMailMonitorCfg>();
        for (let mm of obj.mailmonitor) this.m_mailmonitor.push(new WFMailMonitorCfg(mm));

        this.m_ksef_apiurl= obj.ksef_apiurl;
        this.m_ksef_pubkeyb64= obj.ksef_pubkeyb64;
    }
    
    public get Profile(): string {
        return this.m_profile;
    }

    public get Active(): boolean {
        return this.m_active;
    }

    public get DefaultSMTPAddress(): string {
        return this.m_defSMTPadr;
    }
    
    public get DefaultSMTPPort(): number {
        return this.m_defSMTPport;
    }
    
    public get DefaultSMTPUser(): string {
        return this.m_defSMTPuser;
    }
    
    public get DefaultSMTPPassword(): string {
        return this.m_defSMTPpass;
    }
    
    public get OCRInputPath(): string {
        return this.m_OCRinputpth;
    }
    
    public get OCROutputPath(): string {
        return this.m_OCRoutputpth;
    }
    
    public get SessionFile(): string {
        return this.m_sessfile;
    }
    
    /*public static GetBVecFromStr(str: string): Array<number> {
        var spl: string[] = str.Split(',');
        var ret: number[] = new Array(spl.length);
        for (var ii: number = 0; ii < spl.length; ii++) {
            var tst: string = spl[ii].Trim();
            if (tst.length > 0)
                ret[ii] = Convert.ToByte(tst, 16);
            else ret[ii] = 0;
        }
        return ret;
    }*/

    public get SecurityKey(): string {
        return this.m_seckey;
    }

    public get SecurityIV(): string {
        return this.m_seciv;
    }

    public get ClientID(): string {
        return this.m_clientid;
    }

    public get CentralService(): string {
        return this.m_centralsrv;
    }

    public get ServicePort(): number {
        return this.m_serviceport;
    }

    public get OcrTaskMode(): WFOcrTaskMode {
        return this.m_ocrtype;
    }
    
    public get InitPrevScale(): number {
        return this.m_initscale;
    }
    
    public get ResCacheLen(): number {
        return this.m_rescachelen;
    }

    public get SignatureLiveTime(): number {
        return this.m_sighours;
    }
    
    public get JSFunctions(): string {
        return this.m_jsfunctions;
    }
    
    public get CheckDelay(): number {
        return this.m_checkrefdelay;
    }
    
    public get MailMonitor(): Array<WFMailMonitorCfg> {
        return this.m_mailmonitor;
    }

    public get Remoteaddress(): string {
        return this.m_remoteaddress;
    }

    public get KSeF_APIUrl(): string {
        return this.m_ksef_apiurl;
    }

    public get KSeF_PubKeyB64(): string {
        return this.m_ksef_pubkeyb64;
    }
}
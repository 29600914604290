export class WFCompanyDict {
    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_regex: RegExp;
    private m_values: Map<string, string>;
    //5.8
    private m_displtips: boolean;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_regex = new RegExp(obj.regex, 'i');

        this.m_values = new Map<string, string>();
        for (let kv of obj.values) this.m_values.set(kv.key, kv.value);

        this.m_displtips = obj.displtips;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get Regex(): RegExp {
        return this.m_regex;
    }

    //

    public get Values(): Map<string, string> {
        return this.m_values;
    }

    //

    public get DisplayAsTips(): boolean {
        return this.m_displtips;
    }
}

import { WFProcessCndFunct } from './WFProcessCndFunct';

export enum WFProcessDstObjType {
    AUTHSCHEMA,
    CUSTOMPROCESS
}

export enum WFPointType {
    NORMAL, 
    DISAPPPROVE
}

export class WFProcessAction {
    public static readonly STARTPOINT = 0;
    public static readonly APPROVEPOINT = -1;
    public static readonly DISAPPROVEPOINT = -2;

    //

    public static readonly EVENT_CLICK = -10;
    public static readonly EVENT_VALIDATING = -11;
    public static readonly EVENT_VALIDATED = -12;
    public static readonly PROCESS_STOP = -13;

    //

    private m_int_id: number;
    private m_ins_int_id: number;
    private m_ins_int_id2: number;
    private m_cnd_funct: WFProcessCndFunct;

    private m_ptype: WFPointType;

    //

    constructor(obj: any) {
        this.m_int_id = obj.int_id;
        this.m_ins_int_id = obj.ins_int_id;
        this.m_ins_int_id2 = obj.ins_int_id2;

        if (obj.isset)
            this.m_cnd_funct = new WFProcessCndFunct(obj.cnd_funct);
        else
            this.m_cnd_funct = null;

        this.m_ptype= obj.ptype;
    }

    //

    public get InternalID(): number {
        return this.m_int_id;
    }

    //

    public get InsInternalID(): number {
        return this.m_ins_int_id;
    }

    //

    public get InsInternalID2(): number {
        return this.m_ins_int_id2;
    }

    //

    public get PointType(): WFPointType {
        return this.m_ptype;
    }

    //

    /*public SetConnection(ins:WFAuthInstruction, ins2:WFAuthInstruction): void
    {
        this.m_ins_int_id= (ins == null) ? WFProcessAction.STARTPOINT : ins.InternalID;
        this.m_ins_int_id2 = (ins2 == null) ? WFProcessAction.APPROVEPOINT : ins2.InternalID;
    }
 
    public SetConnection(ins:IWFCustomInstruction, ins2:IWFCustomInstruction): void
    {
        if (ins == null || ins2 == null) throw new RangeError();
        this.m_ins_int_id = ins.InternalID;
        this.m_ins_int_id2 = ins2.InternalID;
    }
 
    public SetConnection(evt:WFEventType, ins2:IWFCustomInstruction): void
    {
        if (ins2 == null) throw new RangeError();
 
        switch (evt)
        {
            case WFEventType.EVENT_CLICK:
                this.m_ins_int_id = WFProcessAction.EVENT_CLICK;
                break;
            case WFEventType.EVENT_VALIDATED:
                this.m_ins_int_id = WFProcessAction.EVENT_VALIDATED;
                break;
            case WFEventType.EVENT_VALIDATING:
                this.m_ins_int_id = WFProcessAction.EVENT_VALIDATING;
                break;
        }
 
        this.m_ins_int_id2 = ins2.InternalID;            
    }
 
    public void SetConnection(IWFCustomInstruction ins)
    {
        if (ins == null) throw new Exception();
        m_ins_int_id = ins.InternalID;
        m_ins_int_id2 = WFProcessAction.PROCESS_STOP;
    }*/

    //

    public get Condition(): WFProcessCndFunct {
        return this.m_cnd_funct;
    }

    public ClearCondition(): void {
        this.m_cnd_funct = null;
    }

    /*private WFProcessCndFunct AddCondition(WFProcessCndFunct pr, WFProcessCndType tp, int lvarid, string lcol,  WFProcessCndFunctType cmdtp, int rvarid, string cval, WFProcessGroupCndType grptp)
    {
        int maxid = (m_cnd_funct == null) ? 0 : m_cnd_funct.FindMaxInternalID(0);
        maxid++;
 
        WFProcessCndFunct ret = new WFProcessCndFunct(maxid, tp, lvarid, cmdtp, rvarid, cval, grptp, lcol);
        if (m_cnd_funct == null)
        {
            m_cnd_funct = ret;
        }
        else
        {
            pr.AddSubFunct(ret);
        }
 
        return ret;
    }
 
    public WFProcessCndFunct AddCondition(WFProcessCndFunct pr, WFProcessVariable var, string col, WFProcessCndFunctType cndtype, WFProcessVariable var2, string col2)
    {
        return AddCondition(pr, WFProcessCndType.TYPE_BASIC, var.InternalID, col,  cndtype, var2.InternalID, col2, WFProcessGroupCndType.CND_AND);
    }
 
    public WFProcessCndFunct AddCondition(WFProcessCndFunct pr, WFProcessVariable var, string col, WFProcessCndFunctType cndtype, string constv)
    {
        return AddCondition(pr, WFProcessCndType.TYPE_BASIC, var.InternalID, col, cndtype, 0, constv, WFProcessGroupCndType.CND_AND);
    }
 
    public WFProcessCndFunct AddCondition(WFProcessCndFunct pr, WFProcessGroupCndType grptp)
    {
        return AddCondition(pr, WFProcessCndType.TYPE_GROUP, 0, "", WFProcessCndFunctType.CND_EQUAL, 0, "", grptp);
    }*/
}
import { LOCALE_ID, enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// if (environment.production) {
enableProdMode();
// }

platformBrowserDynamic().bootstrapModule(AppModule);
//platformBrowserDynamic().bootstrapModule(AppModule, {providers: [{provide: LOCALE_ID, useValue: window['MY_LOCALE']}]});
//platformBrowserDynamic([{provide: LOCALE_ID, useValue: 'pl-PL'}]).bootstrapModule(AppModule, {providers: [{provide: LOCALE_ID, useValue: 'pl-PL'}]});

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appResizeComment]'
})
export class ResizeCommentDirective {

  constructor(private el: ElementRef) {

  }

  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {

    if (this.el.nativeElement.clientWidth <= 16 ) {
      this.el.nativeElement.style.cursor = 'default';
      return;
    }

    let evh= <any>event;
    let layerX= evh.layerX;
    let layerY= evh.layerY;

    /*let ele= this.el.nativeElement;
    while (ele && !isNaN(ele.offsetLeft) && !isNaN(ele.offsetTop)) {
      layerX += ele.offsetLeft - ele.scrollLeft;
      layerY += ele.offsetTop - ele.scrollTop;
      ele = ele.offsetParent;
    }*/

    if (layerY <= 10) {
      if (layerX <= 10) {
        this.el.nativeElement.style.cursor = 'nw-resize';
      } else if (layerX >= (this.el.nativeElement.clientWidth - 10)) {
        this.el.nativeElement.style.cursor = 'ne-resize';
      } else this.el.nativeElement.style.cursor = 'n-resize';
      return;
    } else if (layerY >= (this.el.nativeElement.clientHeight - 10)) {
      if (layerX <= 10) {
        this.el.nativeElement.style.cursor = 'sw-resize';
      } else if (layerX >= (this.el.nativeElement.clientWidth - 10)) {
        this.el.nativeElement.style.cursor = 'se-resize';
      } else this.el.nativeElement.style.cursor = 's-resize';
      return;
    } else {
      if (layerX <= 10) {
        this.el.nativeElement.style.cursor = 'w-resize';
      } else if (layerX >= (this.el.nativeElement.clientWidth - 10)) {
        this.el.nativeElement.style.cursor = 'e-resize';
      } else this.el.nativeElement.style.cursor = 'move';
      return;
    }

    /*if (event.layerY <= 10) {
      if (event.layerX <= 10) {
        this.el.nativeElement.style.cursor = 'nw-resize';
      } else if (event.layerX >= (this.el.nativeElement.clientWidth - 10)) {
        this.el.nativeElement.style.cursor = 'ne-resize';
      } else this.el.nativeElement.style.cursor = 'n-resize';
      return;
    } else if (event.layerY >= (this.el.nativeElement.clientHeight - 10)) {
      if (event.layerX <= 10) {
        this.el.nativeElement.style.cursor = 'sw-resize';
      } else if (event.layerX >= (this.el.nativeElement.clientWidth - 10)) {
        this.el.nativeElement.style.cursor = 'se-resize';
      } else this.el.nativeElement.style.cursor = 's-resize';
      return;
    } else {
      if (event.layerX <= 10) {
        this.el.nativeElement.style.cursor = 'w-resize';
      } else if (event.layerX >= (this.el.nativeElement.clientWidth - 10)) {
        this.el.nativeElement.style.cursor = 'e-resize';
      } else this.el.nativeElement.style.cursor = 'move';
      return;
    }*/

  }

  @HostListener('dblclick', ['$event']) onMouseDblClick(event: MouseEvent) {
    console.log(this.el);
    console.log(event);

    //this.highlight('yellow');
  }
}

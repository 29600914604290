import { WFProcessVariable, WFProcessVarType } from './WFProcessVariable';
import { IWFCustomInstruction, WFInstructionType } from './IWFCustomInstruction';
import { WFProcessAction } from './WFProcessAction';
import { WFLineopInstruction } from './WFLineopInstruction';
import { WFScriptopInstruction } from './WFScriptopInstruction';
import { WFDialogWndInstruction } from './WFDialogWndInstruction';
import { WFExcallopInstruction } from './WFExcallopInstruction';
import { IWFProcessVariableContainer } from './IWFProcessVariableContainer';
import { IWFObject } from './IWFObject';

export enum WFEventType {
    EVENT_CLICK,
    EVENT_VALIDATING,
    EVENT_VALIDATED
}

export class WFCustomProcess implements IWFProcessVariableContainer {
    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_class_id: number;
    private m_organization_id: number;

    private m_variables: Array<WFProcessVariable>;
    private m_instructions: Array<IWFCustomInstruction>;
    private m_actions: Array<WFProcessAction>;

    private m_createdat: Date;
    private m_updatedat: Date;

    // 

    public static CreateInsFromJson(obj: any): IWFCustomInstruction {
        let ret: IWFCustomInstruction = null;
        let itp: WFInstructionType = obj.type;
        switch (itp) {
            case WFInstructionType.LINEOP:
                ret = new WFLineopInstruction(obj);
                break;
            case WFInstructionType.SCRIPTOP:
                ret = new WFScriptopInstruction(obj);
                break;
            case WFInstructionType.DIALOGWND:
                ret = new WFDialogWndInstruction(obj);
                break;
            case WFInstructionType.EXCALLOP:
                ret = new WFExcallopInstruction(obj);
                break;
        }
        return ret;
    }

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_class_id = obj.class_id;
        this.m_organization_id = obj.organization_id;

        this.m_variables = new Array<WFProcessVariable>();
        for (let vr of obj.variables) this.m_variables.push(new WFProcessVariable(vr));

        this.m_instructions = new Array<IWFCustomInstruction>();
        for (let ins of obj.instructions) this.m_instructions.push(WFCustomProcess.CreateInsFromJson(ins));

        this.m_actions = new Array<WFProcessAction>();
        for (let act of obj.actions) this.m_actions.push(new WFProcessAction(act));

        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    //

    public get ClassID(): number {
        return this.m_class_id;
    }

    //

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    //

    public GetVariablesCount(): number {
        return this.m_variables.length;
    }

    public GetVariable(id: number): WFProcessVariable {
        return this.m_variables[id];
    }

    public GetVariableByID(id: number): WFProcessVariable {
        let ii: number;
        let tst: WFProcessVariable;

        if (id > 0) {
            for (ii = 0; ii < this.m_variables.length; ii++) {
                tst = this.m_variables[ii];
                if (tst.InternalID === id) return tst;
            }
        } else {
            let sysvars = WFProcessVariable.GetSystemDefinitions();
            for (ii = 0; ii < sysvars.length; ii++) {
                tst = sysvars[ii];
                if (tst.InternalID === id) return tst;
            }
        }

        return null;
    }

    public ClearVariables(): void {
        this.m_variables.splice(0, this.m_variables.length);
    }

    public AddVariable(name: string, tp: WFProcessVarType): WFProcessVariable
    {
        let ii: number;
 
        if (!WFProcessVariable.IsNameValid(name)) return null;
 
        //sprawdz czy istnieje juz dana nazwa
        let test: WFProcessVariable;
        let uname: string = name.toUpperCase();
        for (ii = 0; ii < this.m_variables.length; ii++)
        {
            test = this.m_variables[ii];
            if (test.Name.toUpperCase() === uname) return null;
        }
 
        let sysvars: Array<WFProcessVariable> = WFProcessVariable.GetSystemDefinitions();
        for (ii = 0; ii < sysvars.length; ii++)
        {
            test = sysvars[ii];
            if (test.Name.toUpperCase() === uname) return null;
        }
 
        let maxid = 0;
        if (this.m_variables.length > 0) maxid = this.m_variables[this.m_variables.length - 1].InternalID;
        maxid++;
        let vrr: WFProcessVariable = WFProcessVariable.Create(maxid, name, '', tp, '');
        this.m_variables.push(vrr);
 
        return vrr;
    }

    //

    public GetInstructionCount(): number {
        return this.m_instructions.length;
    }

    public GetInstruction(id: number): IWFCustomInstruction {
        return this.m_instructions[id];
    }

    public GetInstructionByID(id: number): IWFCustomInstruction {
        for (let ii = 0; ii < this.m_instructions.length; ii++) {
            let ins = this.m_instructions[ii];
            if (ins.InternalID === id) return ins;
        }
        return null;
    }

    public ClearInstructions(): void {
        this.m_actions.splice(0, this.m_actions.length);
        this.m_instructions.splice(0, this.m_instructions.length);
    }

    /*public IWFCustomInstruction AddInstruction(string name, WFInstructionType tp)
    {
        int ii;
 
        if (!IWFCustomInstruction.IsNameValid(name)) return null;
 
        int maxid = 0;
        //nie jednolita struktura
        //if (m_instructions.Count > 0) maxid = m_instructions[m_instructions.Count - 1].InternalID;
        for (ii = 0; ii < m_instructions.Count; ii++)
        {
            IWFCustomInstruction tst = m_instructions[ii];
            if (tst.InternalID > maxid) maxid = tst.InternalID;
        }
        maxid++;
 
        IWFCustomInstruction ins = null;
        switch (tp)
        {
            case WFInstructionType.LINEOP:
                ins = new WFLineopInstruction(maxid, name, "", WFLineOpType.GETLINES, 0);
                break;
            case WFInstructionType.SCRIPTOP:
                ins = new WFScriptopInstruction(maxid, name, "", WFScriptType.SQL, "select 'SQL' as msg", 0);
                break;
            case WFInstructionType.DIALOGWND:
                ins = new WFDialogWndInstruction(maxid, name, "", "Message", "Ok", "", "", 0);
                break;
            case WFInstructionType.EXCALLOP:
                ins = new WFExcallopInstruction(maxid, name, "", WFExcallOpType.DNETEXASSEMBLY, "ExternalDllName", "UtilsClass", "Method1", "", "", "", "", 0);
                break;
        }
        m_instructions.Add(ins);
        return ins;
    }
 
    public bool RemoveInstructionByID(int id)
    {
        IWFCustomInstruction ins = GetInstructionByID(id);
        if (ins != null)
        {
            int ii = 0;
            while (ii < m_actions.Count)
            {
                WFProcessAction act = m_actions[ii];
                if (act.InsInternalID == id || act.InsInternalID2 == id)
                {
                    m_actions.RemoveAt(ii);
                }
                else
                {
                    ii++;
                }
            }
            m_instructions.Remove(ins);
            return true;
        }
        return false;
    }*/

    //

    public GetActionCount(): number {
        return this.m_actions.length;
    }

    public GetAction(id: number): WFProcessAction {
        return this.m_actions[id];
    }

    /*public WFProcessAction AddAction(IWFCustomInstruction ins, IWFCustomInstruction ins2)
    {
        if(ins!= null && ins2!= null)
        {
            int maxid = 0;
            if (m_actions.Count > 0) maxid = m_actions[m_actions.Count - 1].InternalID;
            maxid++;
 
            WFProcessAction act = new WFProcessAction(maxid, ins.InternalID, ins2.InternalID);
 
            m_actions.Add(act);
            return act;
        }
        return null;
    }
 
    public WFProcessAction AddActionFromEvent(WFEventType evt, IWFCustomInstruction ins)
    {
        if (ins != null)
        {
            int maxid = 0;
            if (m_actions.Count > 0) maxid = m_actions[m_actions.Count - 1].InternalID;
            maxid++;
 
            int actid = 0;
            //remap
            switch (evt)
            {
                case WFEventType.EVENT_CLICK:
                    actid = WFProcessAction.EVENT_CLICK;
                    break;
                case WFEventType.EVENT_VALIDATING:
                    actid = WFProcessAction.EVENT_VALIDATING;
                    break;
                case WFEventType.EVENT_VALIDATED:
                    actid = WFProcessAction.EVENT_VALIDATED;
                    break;
            }
 
            WFProcessAction act = new WFProcessAction(maxid, actid, ins.InternalID);
 
            m_actions.Add(act);
            return act;
        }
        return null;
    }
 
    public WFProcessAction AddActionToStop(IWFCustomInstruction ins)
    {
        if (ins != null)
        {
            int maxid = 0;
            if (m_actions.Count > 0) maxid = m_actions[m_actions.Count - 1].InternalID;
            maxid++;
 
            WFProcessAction act = new WFProcessAction(maxid, ins.InternalID, WFProcessAction.PROCESS_STOP);
 
            m_actions.Add(act);
            return act;
        }
        return null;
    }*/
}


import { IWFCustomInstruction, WFInstructionType } from './IWFCustomInstruction';

export enum WFExcallOpType {
    DNETEXASSEMBLY
}

export class WFExcallopInstruction extends IWFCustomInstruction {
    private m_asmtype: WFExcallOpType;
    private m_assembly: string;
    private m_clstype: string;
    private m_method: string;
    private m_arg0: string;
    private m_arg1: string;
    private m_arg2: string;
    private m_arg3: string;
    private m_variable_id: number;

    //

    constructor(obj: any) {
        super(obj.int_id, obj.name, obj.description, WFInstructionType.EXCALLOP);
        this.m_asmtype = obj.asmtype;
        this.m_assembly = obj.assembly;
        this.m_clstype = obj.clstype;
        this.m_method = obj.method;
        this.m_arg0 = obj.arg0;
        this.m_arg1 = obj.arg1;
        this.m_arg2 = obj.arg2;
        this.m_arg3 = obj.arg3;
        this.m_variable_id = obj.variable_id;
    }

    //

    public get AssemblyType(): WFExcallOpType {
        return this.m_asmtype;
    }

    //

    public get Assembly(): string {
        return this.m_assembly;
    }

    //

    public get ClassType(): string {
        return this.m_clstype;
    }

    //

    public get Method(): string {
        return this.m_method;
    }

    //

    public get Arg0(): string {
        return this.m_arg0;
    }

    //

    public get Arg1(): string {
        return this.m_arg1;
    }

    //

    public get Arg2(): string {
        return this.m_arg2;
    }

    //

    public get Arg3(): string {
        return this.m_arg3;
    }

    //

    public get VariableID(): number {
        return this.m_variable_id;
    }
}
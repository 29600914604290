import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { GlobalService } from '../global/global.service';

@Component({
  selector: 'app-import-file-params',
  templateUrl: './import-file-params.component.html',
  styleUrls: ['./import-file-params.component.scss']
})

export class ImportFileParamsComponent implements AfterViewInit {
  private m_filectx: string;
  public m_frowiscols: boolean;
  public m_coldelim_err: boolean;
  @ViewChild('m_coldelim', {static: false}) private m_coldelim: ComboboxadpComponent;
  @ViewChild('m_colbegin', {static: false}) private m_colbegin: ComboboxadpComponent;

  @Output() public onClosed: EventEmitter<boolean>;

  //

  public get FirstRowIsColumn(): boolean {
    return this.m_frowiscols;
  }

  public get ColumnsDelimiter(): string {
    let sval = this.m_coldelim.SelectedValue;
    if (sval == null) return '';
    sval = sval.trim();
    sval = sval.replace(/{TAB}/g, '\t');
    return sval;
  }

  public get ColumnsSpecial(): string {
    let sval = this.m_colbegin.SelectedValue;
    if (sval == null) return '';
    sval = sval.trim();
    sval = sval.replace(/{TAB}/g, '\t');
    return sval;
  }

  public get FileContent(): string {
    return this.m_filectx;
  }

  //

  constructor(private m_global_srv: GlobalService) {
    this.m_filectx = '';
    this.m_frowiscols = true;
    this.m_coldelim_err = false;
    this.onClosed = new EventEmitter<boolean>();
  }

  public SetPrms(ctx: string): void {
    this.m_filectx = ctx;
  }

  public ngAfterViewInit(): void {
    try {
      this.m_coldelim.AddValidValue(';', ';');
      this.m_coldelim.AddValidValue('{TAB}', 'TAB');
      this.m_coldelim.AddValidValue(',', ',');
      this.m_coldelim.AddValidValue('.', '.');
      this.m_coldelim.AddValidValue('|', '|');
      this.m_coldelim.SelectedValue = ';';
      this.m_colbegin.AddValidValue('"', '"');
      this.m_colbegin.AddValidValue('\'', '\'');
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public OKButton_Click(): void {
    try {
      let sval = this.m_coldelim.SelectedValue;
      sval = (sval == null) ? '' : sval.trim();
      if (sval.length > 0) {
        this.onClosed.emit(true);
      } else {
        this.m_coldelim_err = true;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

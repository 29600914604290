import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';
import { _OnError } from './WFDocumentAdp';
import { InvoiceHeader } from '../data/data.service';
import { DocumentInterfaceConfig, WFDocument } from '../model';

export type ListEDocs = (snd: WFDocumentInterfaceAdp, page: number, edocs: Array<[InvoiceHeader,Array<number>]>, count: number) => void;
export type PreviewEDoc = (snd: WFDocumentInterfaceAdp, uniqueid: string, htmlctx: string) => void;
export type CreateDocumentFromEDoc= (snd: WFDocumentInterfaceAdp, uniqueid: string, doc: WFDocument) => void;

export class WFDocumentInterfaceAdp {
    public OnListEDocs: ListEDocs;
    public OnPreviewEDoc: PreviewEDoc;
    public OnCreateDocumentFromEDoc: CreateDocumentFromEDoc;

    private m_OnError: EventAdp<_OnError>;
    
    private m_cfg: DocumentInterfaceConfig;
    private m_count: number;

    //

    constructor(cfg: DocumentInterfaceConfig, count: number) {
        this.m_cfg= cfg;
        this.m_count= count; 
        this.OnListEDocs = null;
        this.OnPreviewEDoc = null;
        this.OnCreateDocumentFromEDoc = null;
        this.m_OnError = new EventAdp<_OnError>();        
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    public get Name(): string {
        return this.m_cfg.Name;
    }

    public get Count(): number {
        return this.m_count;
    }

    public get TotalDocs(): number {
        return this.m_count;
    }

    public ListEDocuments(serv: CacheService, page: number, keywords: string, only_unbind: boolean): void {
        const self = this;

        serv.DataService.listEDocuments(serv.SessionID, page, this.m_cfg, keywords, only_unbind,
                                        this.m_OnError, (rows, rowcount) => {
            self.ListEDocumentsCompleted(page, rows, rowcount);
            //self.m_count= rowcount;
        });
    }

    private ListEDocumentsCompleted(page: number, rows: Array<[InvoiceHeader,Array<number>]>, rowcount: number): void {
        if(rowcount != this.m_count) this.m_count= rowcount;
        
        if (this.OnListEDocs != null)
            this.OnListEDocs(this, page, rows, rowcount);
    }

    public PreviewEDocument(serv: CacheService, uniqueid: string): void {
        const self = this;

        serv.DataService.previewEDocument(serv.SessionID, this.m_cfg, uniqueid, this.m_OnError, (htmlctx)=> {
            self.PreviewEDocumentCompleted(uniqueid, htmlctx);
        });
    }

    private PreviewEDocumentCompleted(uniqueid: string, htmlctx: string): void {
        if(this.OnPreviewEDoc != null)
           this.OnPreviewEDoc(this, uniqueid, htmlctx);
    }

    public CreateDocumentFromEDoc(serv: CacheService, uniqueid: string): void {
        const self = this;

        serv.DataService.createDocumentFromEDoc(serv.SessionID, this.m_cfg, uniqueid, this.m_OnError, (doc)=> {
            self.CreateDocumentFromEDocCompleted(uniqueid, doc);
        });
    }

    private CreateDocumentFromEDocCompleted(uniqueid: string, doc: WFDocument): void {
        if(this.OnCreateDocumentFromEDoc != null)
           this.OnCreateDocumentFromEDoc(this, uniqueid, doc);
    }
}
/**
 * New typescript file
 */

export const LANG_PL_MAP = {
  'strDataServiceConnErr': 'Błąd połączenia z usługa danych',
  'strNeedLogin': 'Wymagane podanie loginu i hasła',
  'AllUserGroup': '(Wszyscy)',
  'askSaveAsTemplate': 'Czy zapisać dokument jako szablon?',
  'btAdd': 'Dodaj',
  'btCancel': 'Anuluj',
  'btNewColor': 'Losowo',
  'btnLblAll': 'Dla wszystkich',
  'btnLblSet': 'Ustaw',
  'btNo': 'Nie',
  'btNowyWpis': 'Nowy wpis',
  'btOK': 'Ok',
  'btPrint': 'Drukuj',
  'btSave': 'Zapisz',
  'btSend': 'Wyślij',
  'btYes': 'Tak',
  'hintClear': 'Wyczyść',
  'hintSearch': 'Szukaj dokumentów',
  'cbvOtherApprovals': 'Szukaj w zatwierdzeniach',
  'cbvOtherApproved': 'Zatwierdzone',
  'cbvOtherCompanies': 'Szukaj w katalogu firmy',
  'cbvOtherRejected': 'Odrzucone',
  'cbvOtherTrash': 'Szukaj w koszu',
  'cbvOtherWait': 'Oczekujące',
  'clBlack': 'Czarny',
  'clBlue': 'Niebieski',
  'clBrown': 'Brązowy',
  'clCyan': 'Cyan',
  'clDarkGray': 'Ciemny szary',
  'clGray': 'Szary',
  'clGreen': 'Zielony',
  'clLightGray': 'Jasno szary',
  'clMagenta': 'Magenta',
  'clOrange': 'Pomarańczowy',
  'clOther': 'Własny',
  'clPurple': 'Purpurowy',
  'clRed': 'Czerwony',
  'clWhite': 'Biały',
  'clYellow': 'Żółty',
  'cmbClassesNone': 'Nieokreślony',
  'cmbSchemaManual': 'Własny',
  'cmbSchemasNone': 'Nieokreślony',
  'enumWFAuthStatusAPPROVED': 'Zatwierdzony',
  'enumWFAuthStatusDISAPPROVED': 'Odrzucony',
  'enumWFAuthStatusWAITING': 'Oczekuje',
  'enumWFDocObjTypeUSER': 'Użytkownik',
  'enumWFDocObjTypeUSERGROUP': 'Grupa użytkowników',
  'enumWFDocPrmTypeCHANGE': 'Zmiana',
  'enumWFDocPrmTypeDISABLE': 'Wyłącz',
  'enumWFDocPrmTypeVIEW': 'Podgląd',
  'enumWFObjTypeAUTHSCHEMA': 'Schemat autoryzacji',
  'enumWFObjTypeUSER': 'Użytkownik',
  'enumWFObjTypeUSERGROUP': 'Grupa użytkowników',
  'errCannotChangeDocStatus': 'Nie można zmienić statusu. Dokument wymaga zdefiniowania schematu zatwierdzania',
  'errCannotChangePassword': 'Nie można zmienić hasła. Sprawdz poprawność wprowadzonych informacji',
  'errCannotChangeSchemaApproved': 'Nie można zmienić schematu zatwierdzania.\nJeden z odbiorców zatwierdził dokument',
  'errCannotChangeSchemaUndefined': 'Nie można zmienić schematu zatwierdzania. Schemat nie został zdefiniowany',
  'errCannotDelComment': 'Nie można usunąć komentarza',
  'errCannotGetCustomer': 'Nie można dodać ph:',
  'errCannotUpdateCustomer': 'Nie można zaktualizować danych ph:',
  'errDocCannotBeModified': 'Dokument {0} nie może zostać zmodyfikowany.\n{1}',
  'errFolderCannotDelete': 'Katalog {0} nie może zostać usunięty.\n{1}',
  'errFolderCannotModify': 'Katalog {0} nie może zostać zmodyfikowany.\n{1}',
  'errHiddenAttachment': 'Nie można otworzyć załącznika. Dokument znajduje się w sekretariacie lub nie posiadasz uprawnień.',
  'errMessageSending': 'Wystąpił błąd podczas próby wysłania wiadomości.\nSprawdz konfiguracje serwera SMTP',
  'errNoCurrentSession': 'Brak lub nieważny identyfikator sesji',
  'errWrongDataInForm': 'Formularz zawiera błędne dane',
  'eventTypeApprove': 'Zaakceptowano dokument',
  'eventTypeApprovedByAll': 'Zatwierdzono przez wszystkich',
  'eventTypeCommented': 'Skomentowano',
  'eventTypeDocChanged': 'Zmieniono dokument',
  'eventTypeNeedAuth': 'Wymagana autoryzacja',
  'eventTypeNeedComment': 'Wymagany komentarz',
  'eventTypeNone': 'Inne',
  'eventTypeReject': 'Odrzucono',
  'fldAlerts': 'Alarmy',
  'fldAuthByMe': 'Zatwierdzone',
  'fldAuthByOther': 'Zatwierdzone poza mną',
  'fldAuthorizations': 'Zatwierdzanie',
  'fldAuthWait': 'Do zatwierdzenia',
  'fldFavourites': 'Ulubione',
  'fldObserved': 'Obserwowane',
  'fldRejectByMe': 'Odrzucone przeze mnie',
  'fldRejectByOther': 'Odrzucone poza mną',
  'fldSearchResult': 'Wynik wyszukiwania',
  'fldSecretary': 'SEKRETARIAT',
  'fldSecretaryChecked': 'DO SPRAWDZENIA',
  'fldSecretaryOCR': 'ROZPOZNAWANE',
  'fldSecretaryReaded': 'WCZYTANE',
  'fldSecretaryTemp': 'Szablony',
  'fldTrash': 'Kosz',

  //Kendo Grid
  'kgrdgroupPanelEmpty': 'Przeciągnij aby zgrupować',
  'kgrdnoRecords': 'Brak danych',
  'kgrdpagerFirstPage': 'Pierwsza strona',
  'kgrdpagerLastPage': 'Ostatnia strona',
  'kgrdpagerPreviousPage': 'Poprzednia',
  'kgrdpagerNextPage': 'Następna',
  'kgrdpagerPage': 'pagerPage',
  'kgrdpagerItemsPerPage': 'pagerItemsPerPage',
  'kgrdpagerOf': 'pagerOf',
  'kgrdpagerItems': 'pagerItems',
  'kgrdfilterEqOperator': 'równy',
  'kgrdfilterNotEqOperator': 'rózny',
  'kgrdfilterIsNullOperator': 'null',
  'kgrdfilterIsNotNullOperator': '!null',
  'kgrdfilterIsEmptyOperator': 'pusty',
  'kgrdfilterIsNotEmptyOperator': 'nie pusty',
  'kgrdfilterStartsWithOperator': 'zaczyna się',
  'kgrdfilterContainsOperator': 'zawiera',
  'kgrdfilterNotContainsOperator': 'nie zawiera',
  'kgrdfilterEndsWithOperator': 'kończy się',
  'kgrdfilterGteOperator': '>=',
  'kgrdfilterGtOperator': '>',
  'kgrdfilterLteOperator': '<=',
  'kgrdfilterLtOperator': '<',
  'kgrdfilterIsTrue': 'prawda',
  'kgrdfilterIsFalse': 'fałsz',
  'kgrdfilterBooleanAll': 'wszystko',
  'kgrdfilterAfterOrEqualOperator': 'po lub równy',
  'kgrdfilterAfterOperator': 'po',
  'kgrdfilterBeforeOperator': 'przed',
  'kgrdfilterBeforeOrEqualOperator': 'przed lub równy',
  'kgrdfilterFilterButton': 'Filtruj',
  'kgrdfilterClearButton': 'Wyczyść filtr',
  'kgrdfilterAndLogic': 'Oraz',
  'kgrdfilterOrLogic': 'Lub',

  // hints
  'hintEditAuthSchemaAdd': 'Dodaj etap autoryzacji',
  'hintEditAuthSchemaDel': 'Usuń etap autoryzacji',
  'hintEditPermissionAdd': 'Dodaj wybrane uprawnienie',
  'hintEditPermissionDel': 'Usuń wybrane uprawnienie',
  'hintAddDailyCorr': 'Dodaj wpis do dziennika korespondencji',
  'hintRefreshReport': 'Odśwież dane',
  'hintExpandGroups': 'Rozwiń',
  'hintCollapseGroups': 'Zwiń',
  'wDocViewer_hintInboxAsRead': 'Zaznacz wiadomość jako przeczytaną',
  //'wDocViewer_hintFilterChecked': 'Fitruj wg zaznaczenia',
  //'wDocViewer_hintFilterUncorrect': 'Fitruj wg dopasowania',
  'hintDocFilter': 'Filtruj dokumenty',
  'hintSortAsc': 'Sortuj rosnąco',
  'hintSortDesc': 'Sortuj malejąco',
  'hintSortBy': 'Sortuj według ...',
  'hintMinimizeWindow': 'Minimalizuj okno',
  'hintMaximizeWindow': 'Maksymalizuj okno',
  'hintReload': 'Odśwież',
  'hintAddDocStandard': 'Dodaj nowy dokument i przekaż do OCR',
  'hintAddDocFromMail': 'Dodaj nowy dokument',
  'hintAddDocWithoutOCR': 'Dodaj nowy dokument',
  'hintAddDocMore': 'Więcej opcji ...',
  //'hintAddDocFromTemplate': 'Dodaj dokument z szablonu',
  //'hintAddDocOcrType': 'Czy dokumenty dodać do OCR',
  //'hintAddSboPH': 'Dodaj nowego klienta',
  //'hintAlerts': 'Komunikaty użytkownika',
  // 'hintApprByMe': 'Dokumenty zatwierdzone przeze mnie',
  // 'hintApprbyOther': 'Dokumenty zatwierdzone poza mną',
  // 'hintApprove': 'Katalog zatwierdzeń',
  // 'hintApproveButton': 'Autoryzuj dokument',
  'hintApproveHistButton': 'Historia autoryzacji',
  // 'hintApprWait': 'Dokumenty do zatwierdzenia',
  // 'hintCancel': 'Anuluj',
  // 'hintClearSBOPH': 'Anuluj wybór klienta',
  // 'hintClearSearchOptions': 'Czyść parametry wyszukiwarki',
  // 'hintCollapseGroup': 'Zwiń wszystkie grupy',
  // 'hintCommentsButton': 'Pokaż/ukryj/dodaj komentarze',
  // 'hintCutPageButton': 'Wytnij stronę',
  // 'hintDailyCorr': 'Dzienniki korespondencji',
  // 'hintDailyCorrAddButton': 'Dodaj do schowka',
  // 'hintDailyCorrAddNewButton': 'Dodaj do nowego wpisu dziennika',
  // 'hintDeleted': 'Dokumenty w koszu',
  // 'hintDelPageButton': 'Usuń dokument',
  // 'hintDissByMe': 'Dokumenty odrzucone przeze mnie',
  // 'hintDissByOther': 'Dokumenty odrzucone poza mną',
  // 'hintDocCheck': 'Dokumenty do  sprawdzenia',
  // 'hintDocCreateName': 'Automatyczne generowanie nazwy dokumentu',
  // 'hintDocEditPermission': 'Edycja uprawnień',
  // 'hintDocEntered': 'Wprowadzone dokumenty',
  // 'hintDocOcr': 'Dokumenty w rozpoznaniu',
  // 'hintEditButton': 'Edycja',
  // 'hintEditDocSchema': 'Podgląd / edycja schematu zatwierdzania',
  // 'hintEditSboPH': 'Edytuj klienta',
  'hintExpandGroup': 'Rozwiń wszystkie grupy',
  // 'hintFavAddButton': 'Dodaj do ulubionych',
  // 'hintFavDelButton': 'Usuń z ulubionych',
  // 'hintFavourites': 'Dokumenty ulubione',
  // 'hintFavouritesClear': 'Wyczyść folder dokumentów ulubionych',
  // 'hintFound': 'Wynik wyszukiwania',
  // 'hintHistoryButton': 'Historia zmian na dokumencie',
  // 'hintImportButton': 'Import z pliku',
  // 'hintLogin': 'Zaloguj',
  'hintLogout': 'Wyloguj się ze SPUMA',
  'hintNextPage': 'Następna strona',
  'hintOcrLogsButton': 'Logi usługi OCR',
  //'hintOK': 'Zatwierdź',
  //'hintOptionsButton': 'Pokaż opcje',
  //'hintPastePageButton': 'Wklej stronę',
  'hintPrevPage': 'Poprzednia strona',
  // 'hintPrintDocButton': 'Drukuj dokument',
  'hintProfile': 'Ustawienia użytkownika',
  // 'hintPublishButton': 'Publikuj zmiany',
  // 'hintRecentDailyCorr': 'Dokumenty dodane do schowka',
  // 'hintRecents': 'Dokumenty ostatnio przeglądane',
  // // 'hintRecentsClear': 'Czyszczenie historii przeglądania',
  // 'hintReloadButton': 'Anuluj / Odśwież',
  // 'hintReplaceButton': 'Zamień zawartość',
  // 'hintReports': 'Raporty interaktywne',
  // 'hintRotateLeft': 'Obróć w lewo',
  // 'hintRotateRight': 'Obróć w prawo',
  // 'hintSaveAsTemplate': 'Zapisz jako szablon',
  // 'hintSaveDocButton': 'Zapisz dokument na dysku',
  // 'hintSBOCreateCode': 'Nadaj kod',
  'hintSearchAdvanced': 'Zaawansowane opcje wyszukiwania',
  'hintSearchAdvancedClose': 'Zamknij opcje wyszukiwania',
  'hintSearchKeywords': 'WYSZUKAJ',
  // 'hintSecretary': 'Sekretariat',
  // 'hintSendToWFButton': 'Wyślij w obieg',
  // 'hintShowAttachments': 'Załączniki',
  // 'hintShowEdit': 'Operacje',
  // 'hintShowOptions': 'Pokaż opcje',
  // 'hintTemplates': 'Szablony',
  // 'hintTextRegionsButton': 'Pokaż/ukryj regiony OCR',
  // 'hintToOCRButton': 'Do rozpoznania',
  // 'hintTrackingAddButton': 'Dodaj do obserwowanych',
  // 'hintTrackingDelButton': 'Usuń z obserwowanych',
  // 'hintWholePage': 'Na całą stronę',

  // Przyciski
  'wDocViewer_btnFilterLabel': 'Filtr:',
  'wDocViewer_btnFilterAll': 'Wszystkie',
  'wDocViewer_btnFilterChecked': 'Tylko zaznaczone',
  'wDocViewer_btnFilterUnmatched': 'Tylko niepasujące',

  //Labelki
  'lblERPRefChoose': 'Wybierz połączony obiekt',


  'strUnableToSaveFile': 'Nie można zapisać pliku',
  'rbvSearchAll': 'Wszystko',
  'rbvSearchChoosed': 'Wybrane',
  'strAdd': 'Dodaj',
  'strAdded': 'dodano',
  'strAddedAfter': 'Dodano po:',
  'strAddedBefore': 'Dodano przed:',
  'strAdditionalFields1': 'Dodatkowe - {0}',
  'strAlerts': 'Alarmy',
  'strAlertsLoadInfo': 'Pobieranie alarmów ...',
  'strApprByMe': 'Zatwierdzone przeze mnie',
  'strApprByOther': 'Zatwierdzone poza mną',
  'strApprovalFor': '(za {0})',
  'strApprove': 'Zatwierdzanie',
  'strApproveBtn': 'Zatwierdź',
  'strApproveHistBtn': 'Historia',
  'strApprWait': 'Do zatwierdzenia',
  'strAskClearRights': 'Czy wyczyścić uprawnienia do dokumentu?',
  'strAskCopySchema2Rights': 'Czy skopiować schemat jako uprawnienia do dokumentu?',
  'strAskOverwriteRights': 'Czy nadpisać obecnie skonfigurowane uprawnienia?',
  'strAttachmentsData': 'Pobieranie załączników ...',
  'strAttDel': 'Usuń załącznik',
  'strAttribsSO': 'Atrybuty',
  'strAuthCustom': 'Własny',
  'strAuthMethodType': 'Typ autoryzacji',
  'strAuthNoAuth': 'Bez autoryzacji',
  'strAuthSchemaID': 'Schemat',
  'strAuthSO': 'Autoryzacje',
  'strAuthUserLimit': 'Limit autoryzacji',
  'strAutoPublish': 'Automatyczna publikacja',
  'strBindDocuments': 'Powiązane dokumenty',
  'strCannotMoveDocuments': 'Nie można przenosić z folderów wstępnego wprowadzania',
  'strCannotMoveDocumentsComp': 'Nie można przenosić katalogu pomiędzy firmami',
  'strCatalogAdd': 'Dodaj katalog',
  'strCatalogChange': 'Zmień nazwę',
  'strCatalogDel': 'Usuń katalog',
  'strChangePassword': 'Zmiana hasła',
  'strCheck': 'Zanzacz',
  'strCheckAtLeast1Item': 'Zaznacz min. 1 pozycję',
  'strClassID': 'Klasa',
  'strClassSO': 'Typ',
  'strClassUnknown': 'Nieokreślony',
  'strClearFolderAskInfo': 'Wyczyścić zawartość katalogu?',
  'strClearSearchOptions': 'Ustaw domyślne',
  'strCompanies': 'Repozytorium',
  'strCompanyDir': 'katalog firmy',
  'strCompanyID': 'Firma',
  'strCompanySO': 'Firma',
  'strDailyCorr': 'Dzienniki korespondencji',
  'strDataUpdate': 'Aktualizacja danych ...',
  'strDel': 'Usuń',
  'strDelCheckedLinesAsk': 'Czy na pewno chcesz usunąć znaznaczone pozycje?',
  'strDeleted': 'Kosz',
  'strDeleted2': 'skasowano',
  'strDeleteDailyCorr': 'Czy na pewno chcesz skasować zaznaczone wpisy dziennika korespondencji?',
  'strDescription': 'Opis',
  'strDirectoryID': 'Katalog',
  'strDirectoryListUpdateInfo': 'Aktualizacja listy katalogów ...',
  'strDissByMe': 'Odrzucone przeze mnie',
  'strDissByOther': 'Odrzucone poza mną',
  'strDocAttachAskInfo': 'Czy na pewno chcesz podpiąć zaznaczony dokument?',
  'strDocCheck': 'DO SPRAWDZENIA',
  'strDocDate': 'Data dokumentu',
  'strDocDateFrom': 'Data dokumentu od:',
  'strDocDateTo': 'Data dokumentu do:',
  'strIMAP': 'Skrzynka',
  'strIMAP_Inbox': 'Przychodzące',
  'strDocEntered': 'Wczytane',
  'strDocFromFile': '(Z pliku)',
  'strDocFromFileNOOCR': '(Z pliku - bez OCR)',
  'strDocNum': 'Numer',
  'strDocOcr': 'Rozpoznawane',
  'strDocType': 'Typ dokumentu',
  'strDocument': 'dokument',
  'strDocumentAlreadyRemoved': 'Dokument już został usuniety',
  'strDocumentApprovedInfo': 'Dokument został wstępnie zatwierdzony',
  'strDocumentDiffCompany': 'Podpinanie możliwe tylko w obrębie tej samej firmy',
  'strDocumentID': 'Dokument',
  'strDocumentNoApprovedInfo': 'Nie można zatwierdzić dokumentu',
  'strDocumentNoRejectedInfo': 'Nie można odrzucić dokumentu',
  'strDocumentNoReserveInfo': 'Wystąpił błąd podczas próby zablokowania/zwolnienia dokumentu',
  'strDocumentRejectedInfo': 'Dokument został wstępnie odrzucony',
  'strDocumentReserveInfo': 'Dokument został wstępnie zablokowany/zwolniony',
  'strDocumentUpdateAskInfo': 'Dokument {0} został zmodyfikowany. Czy zapisać aktualne dane?',
  'strDocUpdateInfo': 'Aktualizacja dokumentu ...',
  'strEmpty': '(pusty)',
  'strEmptyRTF': 'Nowy ( edytowalny )',
  'strEnterDate': 'Data recepcja',
  'strEntry': 'Wejście',
  'strERPRef': 'powiązanie erp',
  'strErrPrms': 'Brak wartości parametrów {0}',
  'strException': 'Wyjątek',
  'strExit': 'Wyjście',
  'strExternalId': 'Zewnętrzne id',
  'strFavourites': 'Ulubione',
  'strFetchDocumentsInfo': 'Pobieranie dokumentów ...',
  'strField_AuthSchema': 'Schemat autoryzacji',
  'strField_ClassName': 'Klasa',
  'strField_Company': 'Firma',
  'strField_CreateDate': 'Data dodania',
  'strField_Description': 'Opis',
  'strField_DocDate': 'Data dokumentu',
  'strField_DocNum': 'Numer',
  'strField_EnterDate': 'Data recepcja',
  'strField_Name': 'Nazwa',
  'strField_UpdateDate': 'Data aktualizacji',
  'strField_User': 'Użytkownik',
  'strFileEmpty': 'Brak danych !!!',
  'strFileTypes': 'Wszystkie skany|*.bmp;*.jpg;*.png;*.tif;*.pdf|Pliki bmp|*.bmp|Pliki jpg|*.jpg|Pliki png|*.png|Pliki tif|*.tif|Pliki tiff|*.tiff|Pliki pdf|*.pdf',
  'strFileTypesAll': 'Wszystkie skany|*.bmp;*.jpg;*.png;*.tif;*.pdf|Inne obsługiwane typy|*.rtf;*.txt;*.html|Pliki pdf|*.pdf|Pliki bmp|*.bmp|Pliki jpg|*.jpg|Pliki png|*.png|Pliki tif|*.tif|Pliki tiff|*.tiff|Pliki RTF|*.rtf|Pliki TXT|*.txt|Pliki HTML|*.html|Wszystkie pliki|*.*',
  'strFilter_AuthSchema': 'Schematy',
  'strFilter_AuthStatus': 'Status autoryzacji',
  'strFilter_Class': 'Klasy',
  'strFilter_Company': 'Firma',
  'strFilter_Last5Days': 'Ostatnie 5 dni',
  'strFilter_LastMonth': 'Z ostatniego miesiąca',
  'strFilter_Other': 'Inne',
  'strFilter_ToDay': 'Dzisiejsze',
  'strFilter_User': 'Użytkownik',
  'strFindLineValuesInfo': 'Wyszukiwanie zapamietanych wartości',
  'strFirstSaveDataInfo': 'Zapisz zmiany przed wykonaniem tej operacji',
  'strFolderAddedInfo': 'Dodanie katalogu',
  'strFolderAddedInfo2': 'Dodanie katalogu {0}',
  'strFolderDelAskInfo': 'Czy chcesz usunąć katalog {0}',
  'strFolderDelInfo': 'Usuwanie katalogu ...',
  'strFolderUpdateInfo': 'Aktualizacja katalogu ...',
  'strFound': 'Wynik wyszukiwania',
  'strGroupLineAddAsk': 'Dodanie linii możliwe tylko w trybie rozgrupowanym. Czy rozgrupować linie i dodać nową?',
  'strHide': 'Schowaj',
  'strIncorrectData': 'Wprowadz poprawne dane',
  'strLineOwnerDescription': 'Dodano {0} przez {1}',
  'strLines': 'linie',
  'strList': 'Lista',
  'strLogout': 'WYLOGUJ',
  'strMessageSent': 'Wysłano wiadomość',
  'strModified': 'zmodyfikowano',
  'strMoveDocToCmpAskInfo': 'Czy na pewno chcesz umieścić dokument w katalogu firmy?',
  'strMoveDocumentAskInfo': 'Czy na pewno chcesz przenieść zaznaczony dokument {0}?',
  'strMsgUpdateInfo': 'Aktualizacja wiadomości ...',
  'strName': 'Nazwa',
  'strNeedSelectRow': 'W trybie przenoszenia wymagane jest zaznaczenie wiersza źródłowego',
  'strNone': 'brak',
  'strNoPagesToPrint': 'Brak stron do wydrukowania',
  'strNoResults': 'Brak wyników',
  'strNoRights4DocMoveInfo': 'Nie można przenosić dokumentu. Brak uprawnień',
  'strNoRightsInfo': 'Brak uprawnień',
  'strOther': 'Inna',
  'strOtherSO': 'Pozostałe',
  'strOwnerSO': 'Właściciel',
  'strPage': 'STRONA',
  'strPages': 'strony',
  'strPermissions': 'uprawnienia',
  'strPrintEnterDate': 'Data wpływu dokumentu',
  'strRead': '(odczytał)',
  'strRecentDailyCorr': 'Schowek',
  'strRecents': 'Ostatnio używane',
  'strRecipients': 'odbiorcy schematu',
  'strReloadAskInfo': 'Operacje spowoduje anulowanie wprowadzonych zmian – kontynuować?',
  'strRemoveDailyCorrFail': 'Wpis {0} nie może zostać usunięty',
  'strRemoveDocumentAskInfo': 'Czy chcesz usunąć dokument',
  'strRemovingDocumentInfo': 'Trwa usuwanie dokumentu ...',
  'strReplacement': '{0} za {1}',
  'strReports': 'Raporty',
  'strResourceChanging': 'Zmiana zasobu może spowodować dysfunkcje procesu wyszukiwania. Czy kontynuować?',
  'strSaveChangesInfo': 'Zapisywanie zmian ...',
  'strSBOServiceError': 'Brak połączenia z SAP Business One\n\n\n\n',
  'strSchemaName': 'Zastosowany schemat OCR',
  'strSearchInComments': 'Komentarze',
  'strSearchInDescr': 'Opis',
  'strSearchInKeywords': 'Slowa kluczowe',
  'strSearchInNo': 'Numer',
  'strSecretary': 'Sekretariat',
  'strSendDataInfo': 'Wysyłanie danych ...',
  'strSerchInName': 'Nazwa',
  'strSignatureNotFound': 'Brak sygnatury',
  'strSort': 'Sortuj',
  'strSortAsc': 'Sortuj rosnąco',
  'strSortDesc': 'Sortuj malejąco',
  'strSpecial': 'Specjalne',
  'strSuggestDocNameInfo': 'Generowanie nowej nazwy',
  'strSuggestOcrMapInfo': 'Sugerowanie mapowania kolumn OCR',
  'strTemplateID': 'Szablon',
  'strTemplates': 'Szablony',
  'strTmpWriteMode_AllowAll': 'Pełna edycja',
  'strTmpWriteMode_AllowEditContent': 'Dozwolona edycja zawartości',
  'strTmpWriteMode_DenyAll': 'Tylko do odczytu',
  'strToday': 'dziś',
  'strTrash': 'kosz',
  'strUncheck': 'Odznacz',
  'strUndefined': 'Nieokreślony',
  'strUnknown': 'Nieznany',
  'strUnsavedDateInfo': 'Istnieją zmienione elementy.\nCzy opublikować zmiany?',
  'strUserAuth': 'autoryzacje',
  'strUserDataUpdate': 'Aktualizacja danych użytkownika ...',
  'strUserID': 'Kreator',
  'strWaitClasses': 'Pobieranie typów ...',
  'strWaitCompanies': 'Pobieranie firm ...',
  'strWaitCompareSchemas': 'Dopasowywanie schematów ...',
  'strWaitConfig': 'Pobieranie konfiguracji',
  'strWaitConfirms': 'Pobieranie zatwierdzeń ...',
  'strWaitCustomProcess': 'Pobieranie procesów UI',
  'strWaitDailyClass': 'Pobieranie dzienników korespondencji',
  'strWaitData': 'Pobieranie danych ...',
  'strWaitDefinitions': 'Pobieranie definicji',
  'strWaitDictionaries': 'Pobieranie słowników ...',
  'strWaitDicts': 'Pobieranie słowników',
  'strWaitFoundSchemas': 'Szukanie schematów ...',
  'strWaitMessages': 'Pobieranie wiadomości ...',
  'strWaitOCRSchemas': 'Pobieranie schematów rozpoznawania ...',
  'strWaitProcess': 'Wykonywanie procesu UI {0}->{1}',
  'strWaitSchemas': 'Pobieranie schematów ...',
  'strWaitSearch': 'Wyszukiwanie ...',
  'strWaitSendingMessage': 'Wysyłanie wiadmości ...',
  'strWaitSession': 'Pobieranie sesji ...',
  'strWaitTemplates': 'Pobieranie szablonów',
  'strWaitUser': 'Pobieranie danych uzytkownika ...',
  'strWaitUsers': 'Pobieranie użytkowników ...',
  'strWaitUsersGroup': 'Pobieranie grup ...',
  'strZoom': 'Zoom',
  'sys_createdate': 'Zarejestrowany',
  'sys_description': 'Opis',
  'sys_operation': 'Operacja',
  'sys_operationdate': 'Data wpisu',
  'sys_operationjustdate': 'Data',
  'sys_operationjusttime': 'Czas',
  'sys_usercode': 'Użytkownik',
  'tblHdrCDate': 'DATA DODANIA',
  'tblHdrDate': 'DATA',
  'tblHdrDescr': 'OPIS',
  'tblHdrName': 'NAZWA',
  'tblHdrNo': 'NUMER',
  'tblHdrUDate': 'DATA AKTUALIZACJI',
  'wApprovals_All': 'Wszyscy',
  'wApprovals_Clear': 'Czyść',
  'wApprovals_ClearAsk': 'Czy na pewno chcesz usunąć historię autoryzacji?. Usunięcie spowoduje powrót dokumentu do procesu autoryzacji.',
  'wApprovals_Comment': 'Komentarz',
  'wApprovals_Date': 'Data',
  'wApprovals_History': 'Historia',
  'wApprovals_OneFrom': 'Jeden z',
  'wApprovals_Title': 'Zatwierdzenia',
  'wApprovals_XFrom': 'z',
  'wApproveWnd_Approve': 'Zatwierdź',
  'wApproveWnd_Reject': 'Odrzuć',
  'wApproveWnd_Release': 'Zwolnij',
  'wApproveWnd_Reserve': 'Rezerwuj',
  'wApproveWnd_Title': 'Autoryzacja dokumentu',
  'wCaptchaInput_Label': 'Przepisz kod z obrazka',
  'wCaptchaInput_Title': 'Captcha',
  'wColumnMapDefinitions_cmbCopy': 'Kopiuj',
  'wColumnMapDefinitions_cmbDontCopy': 'Nie kopiuj',
  'wColumnMapDefinitions_cmbRemember': 'Zapamiętaj',
  'wColumnMapDefinitions_cmbSearch': 'Wyszukaj',
  'wColumnMapDefinitions_colClass': 'Kolumna klasy',
  'wColumnMapDefinitions_colOperation': 'Operacja',
  'wColumnMapDefinitions_colSchema': 'Kolumna schematu',
  'wColumnMapDefinitions_NoRelation': 'Brak zdefiniowanej relacji',
  'wColumnMapDefinitions_Title': 'Mapowanie kolumn',
  'wCommentAdp_ClickToAddComments': '(Kliknij aby dodać komentarz)',
  'wConfiguration_Port': 'Port',
  'wConfiguration_Server': 'Serwer',
  'wConfiguration_Title': 'Konfiguracja',
  'wCreateEditableDocument_Choose': 'Wybierz',
  'wCreateEditableDocument_Create': 'Utwórz',
  'wCreateEditableDocument_EmptyDoc': 'Pusty dokument',
  'wCreateEditableDocument_FileTypes': 'Obsługiwane typy plików|*.pdf;*.rtf;*.txt;*.html|Pliki pdf|*.pdf|Pliki RTF|*.rtf|Pliki TXT|*.txt|Pliki HTML|*.html|Wszystkie pliki|*.*',
  'wCreateEditableDocument_Name': 'Nazwa',
  'wCreateEditableDocument_NoPages': 'Brak stron',
  'wCreateEditableDocument_ReadFromFile': 'Wczytaj z pliku',
  'wCreateEditableDocument_ReadFromTemplate': 'Wczytaj z szablonu',
  'wCreateEditableDocument_Template': 'Szablon',
  'wCreateEditableDocument_Title': 'Dokument edytowalny',
  'wCreateEmptyDocument_Name': 'Nazwa:',
  'wCreateEmptyDocument_Template': 'Szablon:',
  'wDirectoryName_Name': 'Nazwa',
  'wDirectoryName_Title': 'Nazwa katalogu',
  'wDocumentAdp_Company': 'Firma:',
  'wDocumentAdp_NoRights': '(brak uprawnień)',
  'wDocumentAdp_Published': 'Opublikował:',
  'wDocumentModifications_colClass': 'Klasa',
  'wDocumentModifications_colField': 'Pole',
  'wDocumentModifications_colNewValue': 'Nowa wartość',
  'wDocumentModifications_colOldValue': 'Stara wartość',
  'wDocumentModifications_colOperation': 'Operacja',
  'wDocumentModifications_ShowOld': 'Podgląd sprzed zmian',
  'wDocumentModifications_Title': 'Modyfikacje dokumentu',
  'wDocumentName_Title': 'Nazwa dokumentu',
  'wDocumentPreview_DocPreviewInfo': 'Podgląd {0}. Zmiana {1} o {2} przez {3}',
  'wDocumentPreview_Title': 'Podgląd dokumentu',
  'wDocumentPrint_cmbDontPrint': 'Nie drukuj',
  'wDocumentPrint_cmbPrintLastPage': 'Na osobnej stronie',
  'wDocumentPrint_cmbPrintXPage': 'Na stronie',
  'wDocumentPrint_EcoPrint': 'Na jednej stronie',
  'wDocumentPrint_PrintAuthorizations': 'Drukuj autoryzacje',
  'wDocumentPrint_PrintComments': 'Historia komentarzy',
  'wDocumentPrint_PrintCommentsLayout': 'Podgląd komentarzy',
  'wDocumentPrint_PrintingInfo': 'Drukowanie ...',
  'wDocumentPrint_PrintLines': 'Linie',
  'wDocumentPrint_prntArea': 'Obszar',
  'wDocumentPrint_prntComment': 'Komentarz',
  'wDocumentPrint_prntStatus': 'Status',
  'wDocumentPrint_prntTime': 'Czas operacacji',
  'wDocumentPrint_prntUser': 'Użytkownik',
  'wDocumentPrint_Range': 'Zakres - od',
  'wDocumentPrint_RangeTo': 'do',
  'wDocumentPrint_Title': 'Opcje wydruku',
  'wDocViewer_chbHideInvalid': 'Pokaż niepasujące',
  'wDocViewer_chbHideUncheck': 'Ukryj niezaznaczone',
  'wDocViewer_ChoiceDstField': 'Przed wyborem zaznacz pole docelowe na dokumencie',
  'wDocViewer_Company': 'Firma:',
  'wDocViewer_Date': 'Data',
  'wDocViewer_Descr': 'Opis:',
  'wDocViewer_DocDate': 'Data dok.:',
  'wDocViewer_DocDates': 'S: {0}  Z: {1}',
  'wDocViewer_DocDeleteAsk': 'Pozycje dokumentu mogą zostać uszkodzone lub skasowane. Czy chcesz kontynuować?',
  'wDocViewer_DocType': 'Typ:',
  'wDocViewer_EnterDate': 'Wpłynął:',
  'wDocViewer_CreateDate': 'Utworzono:',
  'wDocViewer_errDocumentInOCR': 'Dokument w fazie rozponawania',
  'wDocViewer_errDocumentIsEmpty': 'Pusty dokument',
  'wDocViewer_hintAddComment': 'Dodaj komentarz',
  'wDocViewer_hintAddEntry': 'Skomentuj',
  'wDocViewer_hintAddLine': 'Dodaj nową linię',
  'wDocViewer_hintCheck': 'Zaznacz',
  'wDocViewer_hintClearSel': 'Czyść zaznaczenie',
  'wDocViewer_hintCopyToLines': 'Kopiuj do linii',
  'wDocViewer_hintDeleteComment': 'Usuń komentarz',
  'wDocViewer_hintDeleteLine': 'Usuń zaznaczone linie',
  'wDocViewer_hintReadFromFile': 'Wczytaj z pliku',
  'wDocViewer_hintReverse': 'Odwróć zaznaczenie',
  'wDocViewer_hintShowHideOcrLines': 'Pokaż/ukryj linie mapowania OCR',
  'wDocViewer_hintUncheck': 'Odznacz',
  'wDocViewer_hintWriteToFile': 'Zapisz do pliku',
  'wDocViewer_msgReceiptients': 'Wysłano',
  'wDocViewer_msgSubject': 'Temat',
  'wDocViewer_msgTo': 'Do',
  'wDocViewer_Name': 'Nazwa:',
  'wDocViewer_No': 'Numer:',
  'wDocViewer_noDocument': 'Brak dokumentu',
  'wDocViewer_OCRCopyToLinesResult': 'Dodano {0} pozycji. Nadpisano {1} pozycji. Błędne mapowania {2}',
  'wDocViewer_OCRSchemaAll': 'Wszystkie',
  'wDocViewer_OCRSchemaSugested': 'Sugerowane',
  'wDocViewer_OCRSynchronize': 'Synchronizacja danych',
  'wDocViewer_OpType': 'Typ operacji',
  'wDocViewer_Owner': 'Właściciel:',
  'wDocViewer_pendingOCR': 'Dokument oczekuje na ponowne wysłanie do OCR',
  'wDocViewer_replyMessage': 'Odpowiedz',
  'wDocViewer_Schema': 'Schemat:',
  'wDocViewer_sendEMail': 'Wyślij e-mail',
  'wDocViewer_sendInternalMessage': 'Wyślij wiadomość wewnętrzną',
  'wDocViewer_sendOCRSchemaToAltab': 'Wyślij do ALTAB',
  'wDocViewer_sendToOCR': 'Wyślij ponownie do OCR',
  'wDocViewer_setDefaultOCRSchema': 'Ustaw domyślny',
  'wDocViewer_synchronizeOCRSchema': 'Synchronizuj',
  'wDocViewer_tabComments': 'Komentarze',
  'wDocViewer_tabData': 'Dane podstawowe dokumentu',
  'wDocViewer_tabERP': 'Obiekty ERP',
  'wDocViewer_tabInbox': 'Wiadomości',
  'wDocViewer_tabLines': 'Linie dokumentu',
  'wDocViewer_tabLMsgIn': 'Poczta przychodząca',
  'wDocViewer_tabLMsgOut': 'Poczta wysłana',
  'wDocViewer_tabOCR': 'Dane OCR',
  'wDocViewer_TmpWriteMode': 'Edycja dok.',
  'wDocViewer_userFieldsCode': 'Kod',
  'wDocViewer_userFieldsDescr': 'Opis',
  'wDocViewer_userFieldsDocDate': 'Data dokumentu',
  'wDocViewer_userFieldsDocName': 'Nazwa dokumentu',
  'wDocViewer_userFieldsDocNo': 'Numer dokumentu',
  'wDocViewer_userFieldsEnterDate': 'Data wpływu',
  'wDocViewer_userFieldsName': 'Nazwa',
  'wDocViewer_userFieldsOwnerCode': 'Kod właściciela',
  'wDocViewer_userFieldsOwnerName': 'Nazwa właściciela',
  'wDownloadDocument_BusyText': 'Pobieranie zasobów ...',
  'wDownloadDocument_CheckAtLeastOne': 'Zaznacz min jeden zasób',
  'wDownloadDocument_Resources': 'Zasoby',
  'wDownloadDocument_Title': 'Pobierz dokument',
  'wEditAuthSchema_AddAtLeastOne': 'Dodaj min 1 odbiorcę',
  'wEditAuthSchema_AddInfo': 'Dodatkowe informacje',
  'wEditAuthSchema_CopyToCustom': 'Edytuj',
  'wEditAuthSchema_CopyToRights': 'Kopiuj do uprawnień',
  'wEditAuthSchema_Limit': 'Limit',
  'wEditAuthSchema_Reciepients': 'Odbiorcy',
  'wEditAuthSchema_Title': 'Schemat zatwierdzania',
  'wEditAuthSchema_Type': 'Typ',
  'wEditAuthSchema_XFrom': '(N) z',
  'wEditDailyCorrespondence_Title': 'Wpis dziennika korespondencji',
  'wEditDocument_ChooseDataField': 'Wybierz pole danych',
  'wEditParameters_Title': 'Edycja parametrów',
  'wEditPermissions_CopyFromSchema': 'Kopiuj ze schematu',
  'wEditPermissions_History': 'Nadrzędne',
  'wEditPermissions_Title': 'Uprawnienia',
  'wExObjectRefAdp_Header': 'Obiekt {0} typu {1}',
  'wExObjectRefAdp_Subject': 'Temat',
  'wFilterWnd_Title': 'Filtracja wyników',
  'wImportFileParams_ColDelims': 'Znak rozdzielenia',
  'wImportFileParams_ColSpecials': 'Identyfikator tekstu',
  'wImportFileParams_FRowIsCols': '1 wiersz zawiera nagłówki kolumn',
  'wImportFileParams_Title': 'Parametry importu',
  'wLoginWnd_Login': 'Login:',
  'wLoginWnd_Password': 'Hasło:',
  'wLoginWnd_Title': 'Logowanie',
  'wImapLoginWnd_Login': 'Użytkownik:',
  'wImapLoginWnd_Password': 'Hasło:',
  'wImapLoginWnd_Title': 'Logowanie do Imap',
  'wImapLoginWnd_Remember': 'Zapamiętaj',
  'wmarkFind': 'Wyszukaj',
  'wMessageAdp_Sender': 'Nadawca',
  'wMessageAdp_Subject': 'Temat',
  'wMessageWnd_Title': 'Komunikat',
  'wMultiSelectPanel_selectAll': '(Wszystko)',
  'wOcrProcessLogs_colCreatedAt': 'Utworzono',
  'wOcrProcessLogs_colIsRead': 'Nowy',
  'wOcrProcessLogs_colMessage': 'Komunikat',
  'wOcrProcessLogs_colOrder': 'Wpis',
  'wOcrProcessLogs_Title': 'Logi ocr',
  'wPanelConfiguration_Title': 'Konfiguracja panelu',
  'wParentPermissions_IncludeDocument': 'Uwzględnij dokument',
  'wParentPermissions_Title': 'Uprawnienia nadrzędne',
  'wReportPreview_Title': 'Report',
  'wRequestComment_Ask4Comment': 'Prośba o komentarz',
  'wRequestComment_Ask4CommentSubject': 'Prośba o komentarz',
  'wRequestComment_Ask4CommentSubjectText': 'Prośba o komentarz',
  'wRequestComment_AtLeastOne': 'Zaznacz min jednego odbiorcę',
  'wRequestComment_ClassUnknown': 'nieokreślonej',
  'wRequestComment_Group': 'Grupa',
  'wRequestComment_Message': 'Komunikat',
  'wRequestComment_MessageText': 'Użytkownik {0} prosi o komentarz dot. dokumentu {1}({2}) klasy {3}',
  'wRequestComment_ShowAll': 'Pokaż wszystkich',
  'wRequestComment_Subject': 'Temat',
  'wRequestComment_Title': 'Wiadomość wewnętrzna',
  'wRequestComment_Users': 'Użytkownicy',
  'wSboBusinessPartner_address': 'Adress',
  'wSboBusinessPartner_clear': '(Czyść)',
  'wSboBusinessPartner_customerCode': 'Kod',
  'wSboBusinessPartner_errCustomerNotFound': 'Nie znaleziono PH',
  'wSboBusinessPartner_errNoDataToAnalyze': 'Brak treści do analizy',
  'wSboBusinessPartner_GetData': 'Pobieranie treści ...',
  'wSboBusinessPartner_GetData4Customer': 'Pobieranie danych dla',
  'wSboBusinessPartner_keywords': 'Dane partnera handlowego',
  'wSboBusinessPartner_ttclear': 'Wyczyść dane partnera handlowego',
  'wSboBusinessPartner_ttsearch': 'Wyszukaj partnera handlowego',
  'wSboBusinessPartner_ttedit': 'Edytuj  partnera handlowego',
  'wSboBusinessPartner_ttadd': 'Dodaj nowego partnera handlowego',
  'wSboBusinessPartner_name': 'Nazwa',
  'wSboBusinessPartnerEdit_BillingAddress': 'Adres faktury',
  'wSboBusinessPartnerEdit_City': 'Miasto',
  'wSboBusinessPartnerEdit_cmbCustomer': 'Odbiorca',
  'wSboBusinessPartnerEdit_cmbProspekt': 'Prospekt',
  'wSboBusinessPartnerEdit_cmbSupplier': 'Dostawca',
  'wSboBusinessPartnerEdit_Code': 'Kod',
  'wSboBusinessPartnerEdit_Comments': 'Uwagi',
  'wSboBusinessPartnerEdit_Country': 'Kraj',
  'wSboBusinessPartnerEdit_CustomerUpdate': 'Aktualizacja danych ph ...',
  'wSboBusinessPartnerEdit_DeliveryAddress': 'Adres dostawy',
  'wSboBusinessPartnerEdit_ForeignName': 'Nazwa obca',
  'wSboBusinessPartnerEdit_LicTradNum': 'NIP',
  'wSboBusinessPartnerEdit_Name': 'Nazwa',
  'wSboBusinessPartnerEdit_State': 'Województwo',
  'wSboBusinessPartnerEdit_Street': 'Ulica',
  'wSboBusinessPartnerEdit_Title': 'Edycja kontrahenta SBO',
  'wSboBusinessPartnerEdit_Type': 'Typ',
  'wSboBusinessPartnerEdit_ZipCode': 'Kod poczt.',
  'wSendEMail_ClearFormatting': 'Czyść formatowanie',
  'wSendEMail_EnterCorrectData': 'Wprowadz poprawne dane',
  'wSendEMail_Reciepients': 'Odbiorcy',
  'wSendEMail_Subject': 'Temat',
  'wSendEMail_Title': 'Wyślij dokument',
  'wSendReconSchemaRequest_Title': 'Wyślij prośbę o schemat rozpoznawania',
  'wTemplateName_Title': 'Template name',
  'wTemplateName_WriteMode': 'Edycja dok.',
  'wUserFieldDefinition_cmbDate': 'Data',
  'wUserFieldDefinition_cmbFloat': 'Liczba rzeczywista',
  'wUserFieldDefinition_cmbInteger': 'Liczba całkowita',
  'wUserFieldDefinition_cmbText': 'Dowolny tekst',
  'wUserFieldDefinition_Description': 'Opis',
  'wUserFieldDefinition_FieldId': 'Identyfikator',
  'wUserFieldDefinition_Title': 'Definicja pola użytkownika',
  'wUserFieldDefinition_Type': 'Typ',
  'wUserPanel_CommentsColor': 'Kolor komentarzy',
  'wUserPanel_Email': 'E-Mail',
  'wUserPanel_NewPass': 'Nowe hasło',
  'wUserPanel_NewPass2': 'Powtórz hasło',
  'wUserPanel_OldPass': 'Stare hasło',
  'wUserPanel_ReplacementInfo': 'W dniach od',
  'wUserPanel_ReplacementInfo2': 'do',
  'wUserPanel_ReplacementInfo3': 'zastępuje mnie',
  'wUserPanel_ReplacementInfo4': 'lub',
  'wUserPanel_SelectUser': '(brak)',
  'wUserPanel_SendInfoAbout': 'Przesyłaj powiadomienia o',
  'wUserPanel_SendInfoCheckAsRead': 'Zaznacz jako przeczytane',
  'wUserPanel_SendInfoSend': 'Wysyłaj',
  'wUserPanel_Signature': 'Podpis',
  'wUserPanel_Title': 'Ustawienia użytkownika',
  'wUserPanel_UserId': 'Identyfikator',
  'wUserPanel_Language': 'Język',
  'strDownload': 'Pobierz',
  'strOnLineData': 'DANE ONLINE',
  'wEditCustomAuth_msg1': 'Schemat typu "jeden z" nie może zawierać wielu poziomów.',
  'wEditCustomAuth_msg2': 'Błąd w definicji schematu własnego \r\n\r\n{0}',
  //menu entries
  'mnuTextRegionsOn': 'Pokaż regiony OCR',
  'mnuTextRegionsOff': 'Ukryj regiony OCR',
  'mnuPages': 'Edycja stron',
  'mnuPagesCut': 'Wytnij stronę',
  'mnuPagesPaste': 'Wklej stronę',
  'mnuPagesImport': 'Import z pliku',
  'mnuChangeContent': 'Zaktualizuj zawartość',
  'mnuAddAttachments': 'Dodaj załacznik z pliku',
  'mnuToOCR': 'Wyślij do rozpoznania',
  'mnuSaveAsTemplate': 'Zapisz jako szablon',
  'mnuSendToAuthorize': 'Wyślij w obieg',
  'mnuComments': 'Komentarze ...',
  'mnuCommentsShow': 'Pokaż komentarze',
  'mnuCommentsHide': 'Ukryj komentarze',
  'mnuCommentsAdd': 'Nowy komentarz',
  'mnuShowHistory': 'Pokaż historię zmian',
  'mnuSaveToDisk': 'Zapisz na dysku',
  'mnuPrint': 'Drukuj',
  'mnuDocumentRemove': 'Usuń dokument',
  'mnuDocumentFavOn': 'Dodaj do ulubionych ',
  'mnuDocumentFavOff': 'Usuń z ulubionych ',
  'mnuDocumentObservedOn': 'Dodaj do obserwowanych',
  'mnuDocumentObservedOff': 'Usuń z obserwowanych',
  'mnuAttachment': 'Załączniki ',
  'mnuCorrespondence': 'Dziennik korespondencji',
  'mnuCorrespondenceNew': 'Dodaj nowy wpis',
  'mnuCorrespondenceAdd': 'Dodaj do wpisu #',
  'mnuCorrespondenceItems': 'Wpisy',
  //tooltipy
  'ttSendToAuthorize': 'Wysyła dokument do autoryzacji',
  'ttPublish': 'Zatwierdza wprowadzone zmiany',
  'ttReload': 'Anuluje wprowadzone zmiany',
  'ttShowAttachments': 'Pokaż załączniki',
  'ttTextRegionsOff': 'Wyłącz podgląd regionów OCR',
  'ttTextRegionsOn': 'Włącz podgląd regionów OCR',
  'ttCommentsOff': 'Wyłącz podgląd komentarzy',
  'ttCommentsOn': 'Włącz podgląd komentarzy',
  'ttCommentsAdd': 'Dodaj nowy komentarz',
  'ttDocTabAttribute': 'Atrybuty dokumentu',
  'ttDocTabLines': 'Linie dokumentu',
  'ttDocTabMessages': 'Wiadomości',
  'ttDocTabERP': 'Dokumenty połączone',
  'ttDocTabOCR': 'Rozpoznane dane (OCR)',
  'ttDocTabComments': 'Komentarze do dokumentu',
  'ttFolderSecretary': 'Sekretariat',
  'ttFolderAlerts': 'Alarmy',
  'ttFolderAuthorizations': 'Dokumenty do autoryzacji',
  'ttFolderSpecial': 'Pozostałe foldery',
  'ttFolderRepository': 'Repozytorium dokumentów',
  //nowe
  'wDelFromDailyCorr': 'USUŃ WPIS Z DZIENNIKA',
  'strAlreadyExists': 'Wpis już istnieje',
  'strCantFindEntry': 'Brak wpisu dziennika {0}',
  'strNewFile': 'Nowy',
  'strNewWoOCR': 'Nowy (bez OCR)',
  'strNewToAfOCR': 'Nowy (do wczytanych)',

  'htmlTemplate': '<h1>[[NDOC]]</h1>',

  'strRES_IMAGEPDF': 'Plik PDF',
  'strRES_IMAGEPNG': 'Plik PNG',
  'strRES_PLAINTEXT': 'Plik tekstowy',
  'strRES_RTF': 'Plik formatowany',

  'ttShowDailyCorRefs': 'Pokaż wpisy dzienników korespondencji',
  'hintDeleteDailyCorRef': 'Usuń wpis',
  'strRemoveFromDailyCorr': 'Czy na pewno chcesz usunąć dokument z wpisu dziennika korespondencji?',
  'strRemoveFromAttachments': 'Czy na pewno chcesz usunąć dokument z załączników?',

  'strSessionLost': 'Sesja wygasła. Należy zalogować się ponownie',
  'strInvalidFTaxID': 'Podaj prawidłowy numer nip !!!',

  'strImportTPL': 'Brak uprawnień do dodania zasobów TPL. ',
  'strNewDocPath': 'Nie można utworzyć katalogu: {0}. Czy przypisać katalog domyślny dla firmy?.',

  'strLangEN': 'Angielski',
  'strLangPL': 'Polski',

  'strObserved': 'Obserowane',
  'strNumCopied': 'Skopiowano numer !!!',
  'wAboutWnd_Title': 'Informacje o SPUMA',

  'wAboutWnd_SpumaVer': 'Wersja aplikacji',
  'wAboutWnd_ModelVer': 'Wersja modelu danych',
  'wAboutWnd_DSVer': 'Wersja DataService',

  'mnuAbout': 'Informacje',
  'wAboutWnd_CopyR': '© 2018 Altab S.A. All Rights Reserved',
  'strWaitVersion': 'Sprawdzanie wersji',

  'strYesterday': 'Wczoraj',
  'strHourAgo': 'Godzinę temu',
  'strHour': 'godz.',
  'strDaysAgo': 'dni temu',

  'hintPrintReport': 'Drukuj raport',
  'hintCopyReport': 'Kopiuj dane',
  
  'wPrintReportTask_Title': 'Zadanie drukowania',

  'wPrintReportTask_col_startat': 'Rozpoczeto',
  'wPrintReportTask_col_rows': 'Wierszy',
  'wPrintReportTask_col_status': 'Status',
  'wPrintReportTask_col_options': 'Opcje',
  
  'wPrintReportTask_type': 'Typ operacji',
  'wPrintReportTask_addtask': 'Dodaj zadanie',

  'wPrintReportTask_type_rows': 'Tylko wiersze',
  'wPrintReportTask_type_wdoc': 'Wiersze i dokument',

  'wPrintReportTask_status_waiting': 'Oczekuje',
  'wPrintReportTask_status_preparing': 'Przygotowywany',
  'wPrintReportTask_status_error': 'Błąd',
  'wPrintReportTask_status_done': 'Gotowy',
  
  'wPrintReportTask_no_docidcol': 'Brak kolumny z referencją dokumentu',
  'wPrintReportTask_show': 'Podgląd',
  'wPrintReportTask_save': 'Zapisz',
  'wPrintReportTask_cnterr': 'Kontynuuj po błędzie',

  'strCannotMoveDirectory': 'Nie można przenosić folderu do dziecka',

  'lblSWeekday1': 'Pon', //'Monday',
  'lblSWeekday2': 'Wto', //'Tuesday',
  'lblSWeekday3': 'Śro', //'Wednesday',
  'lblSWeekday4': 'Czw', //'Thursday',
  'lblSWeekday5': 'Pią', //'Friday',
  'lblSWeekday6': 'Sob', //'Saturday',
  'lblSWeekday7': 'Nie', //'Sunday',

  'lblMonth1': 'Styczeń',
  'lblMonth2': 'Luty',
  'lblMonth3': 'Marzec',
  'lblMonth4': 'Kwiecień',
  'lblMonth5': 'Maj',
  'lblMonth6': 'Czerwiec',
  'lblMonth7': 'Lipiec',
  'lblMonth8': 'Sierpień',
  'lblMonth9': 'Wrzesień',
  'lblMonth10': 'Październik',
  'lblMonth11': 'Listopad',
  'lblMonth12': 'Grudzień',

  'lblSMonth1': 'Sty',
  'lblSMonth2': 'Lut',
  'lblSMonth3': 'Marz',
  'lblSMonth4': 'Kwi',
  'lblSMonth5': 'Maj',
  'lblSMonth6': 'Cze',
  'lblSMonth7': 'Lip',
  'lblSMonth8': 'Sie',
  'lblSMonth9': 'Wrz',
  'lblSMonth10': 'Paź',
  'lblSMonth11': 'Lis',
  'lblSMonth12': 'Gru',

  'wUserPanel_ClearMsgs': 'Odznacz nieprzeczytane',
  'strCheckUnreadCompleted': 'Potwierdzono {0} wiadomości',

  'mBoxVW_Col_ReciveDate': 'Data otrzymania',
  'mBoxVW_Col_From': 'Od',
  'mBoxVW_Col_Subject': 'Temat',
  'mBoxVW_Col_ShortForm': 'Podgląd',
  'mBoxVW_Col_Attachments': 'Załączników',
  'mBoxVW_Col_Documents': 'Dokumentów',

  'wMailBoxPreview_Subject': 'Temat',
  'wMailBoxPreview_From': 'Od',
  'wMailBoxPreview_To': 'Do',

  'wAddNewDocFromMail_Button': 'Nowy dokument',
  'wAddNewDocFromMail_Title': 'Nowy dokument z mail',
  'wAddNewDocCol_Sel': 'Wybierz',
  'wAddNewDocCol_Type': 'Typ',
  'wAddNewDocCol_Name': 'Nazwa',
  'wAddNewDocFromMail_Atts': 'Załączniki',

  'wAddNewDocType_Ctx': 'Treść',
  'wAddNewDocType_Att': 'Załącznik',

  'hintMarkAsSeen': 'Oznacz jako przeczytane',
  'hintUnMarkSeen': 'Oznacz jako nieprzeczytane',

  'wDocViewer_hintDivide': 'Rozpisz',
  'strCompanyChanged': 'Zmiana danych firmy może spowodować problemy z danymi PH. Dane zostaną wymazane',

  'hintShowUnRead': 'Ukryj przeczytane',
  'hintShowUnBind': 'Ukryj podpięte',
  'wAddNewDocCol_Action': 'Akcja',

  'wAddNewDocCol_ActSepDoc': 'Oddzielny dokument', //(bez OCR - do sprawdzonych)  
  'wAddNewDocCol_ActBegin': 'Dołącz na początku', 
  'wAddNewDocCol_ActEnd': 'Dołącz na końcu', 

  'wAddNewDocCol_ActOCR': 'OCR', //(Domyślnie dla załączników obrazu)
  'wAddNewDocCol_ActAffOCR': 'do sprawdzonych bez OCR', //(domyślnie dla całej reszty załączników)
  'wAddNewDocCol_ActBefOCR': 'do wczytanych',
  'strNeedSelAction': 'Wymagane wybranie akcji dla zaznaczonego elementu',
  'strRefIMAPInfo': 'Aktualizacja folderów imap',
  'wReportViewer_ShowDocuments': "Pokaż dokumenty",
  'wReportViewer_CopyDocNum' : "Zapamiętaj numer dziennika",

  'strEDOC': 'Dokumenty elektroniczne',
  'EDOCVW_InvoiceReferenceNumber': 'Numer FV',
  'EDOCVW_InvoicingDate': 'Data FV',
  'EDOCVW_SubjectBy': 'Nadawca',
  'EDOCVW_SubjectTo': 'Adresat',
  'EDOCVW_Net': 'Kwota netto',
  'EDOCVW_Currency': 'Waluta',
  
  'EDOCVW_KsefReferenceNumber': 'Numer KSeF',
  'EDOCVW_InvoiceHash': 'Suma kontrolna',
  
  'strEDocAlreadyBind': 'Do dokumentu KSef powstał już dokument w SPUMA. Czy dodać kolejny?',
  'strEDocNewDoc': 'Czy dodać nowy dokument?'
};


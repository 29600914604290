import { WFAuthSchema, WFAuthMethodType } from './WFAuthSchema';
import { WFDocumentAuthRecipient, WFDocAuthObjType } from './WFDocumentAuthRecipient';
import { WFDocumentAuthLock } from './WFDocumentAuthLock';
import { IWFDocumentAuthVariable, WFDocumentAuthVarSchema, WFAuthVarSchemaRecipient } from './WFDocumentAuthVariable';
import { WFAuthStatus } from './WFDocument';
import { WFProcessAction } from './WFProcessAction';
import { WFSystem } from './WFSystem';
import { WFSchemaRecipient, WFObjType } from './WFSchemaRecipient';
import { IWFObject } from './IWFObject';
import { WFUserGroup } from './WFUserGroup';

export type _ExtAct = (rc: WFDocumentAuthRecipient) => void;

export class WFDocumentAuthSchema {
    private m_id: number;
    private m_name: string;
    private m_type: WFAuthMethodType;
    private m_userlimit: number;
    private m_authschema_id: number;
    private m_recipients: Array<WFDocumentAuthRecipient>;
    private m_addinfo: string;
    private m_locks: Array<WFDocumentAuthLock>;
    private m_instruction_int_id: number;
    private m_varstates: Map<number, IWFDocumentAuthVariable>;

    //


    public static Create(rcps: Array<WFSchemaRecipient>,
        nm: string, mtp: WFAuthMethodType, usrlm: number, authshmid: number, addinfo: string,
        sys: WFSystem): WFDocumentAuthSchema {
        let ret: WFDocumentAuthSchema = new WFDocumentAuthSchema({
            id: 0,
            name: nm,
            type: mtp,
            userlimit: usrlm,
            authschema_id: authshmid,
            recipients: [],
            addinfo: (addinfo == null) ? '' : IWFObject.CutString(addinfo, 4000),
            locks: [],
            instruction_int_id: 0,
            varstates: []
        });
        ret.CloneRecipients(rcps, ret.m_recipients, sys);
        return ret;
    }

    public static Create2(varstate: WFDocumentAuthVarSchema, sys: WFSystem): WFDocumentAuthSchema {
        let ii: number;
        let dar: WFDocumentAuthRecipient;

        let ret: WFDocumentAuthSchema = new WFDocumentAuthSchema({
            id: 0,
            name: '',
            type: varstate.SchemaType,
            userlimit: varstate.AuthUserLimit,
            authschema_id: 0,
            addinfo: '',
            locks: [],
            instruction_int_id: 0,
            varstates: [],
            recipients: []
        });

        let trcps: Array<WFAuthVarSchemaRecipient> = varstate.Recipients.slice(0);
        trcps.sort((s1, s2) => s1.Order - s2.Order);

        for (ii = 0; ii < trcps.length; ii++) {
            let rc: WFAuthVarSchemaRecipient = trcps[ii];
            switch (rc.ObjType) {
                case WFObjType.AUTHSCHEMA:
                    let ashm: WFAuthSchema = sys.AllSchemas.get(rc.ObjID);

                    let dshm: WFDocumentAuthSchema = WFDocumentAuthSchema.Create(ashm.Recipients,
                        ashm.Name,
                        ashm.Type,
                        ashm.AuthUserLimit,
                        ashm.ID,
                        ashm.AdditionalInfo,
                        sys);

                    dar = WFDocumentAuthRecipient.Create2(dshm, rc.Order, '', 0);
                    ret.m_recipients.push(dar);
                    break;

                case WFObjType.USER:
                    dar = WFDocumentAuthRecipient.Create(rc.ObjID, rc.Order, '', 0);
                    ret.m_recipients.push(dar);
                    break;

                case WFObjType.USERGROUP:
                    let grp: WFUserGroup = sys.AllUserGroups.get(rc.ObjID);
                    for (let uid of grp.Users) {
                        dar = WFDocumentAuthRecipient.Create(uid, rc.Order, '', 0);
                        ret.m_recipients.push(dar);
                    }
                    break;
            }
        }

        return ret;
    }

    public static Create3(nm: string, mtp: WFAuthMethodType, usrlm: number, authshmid: number, addinf: string): WFDocumentAuthSchema {
        return new WFDocumentAuthSchema({
            id: 0,
            name: nm,
            type: mtp,
            userlimit: usrlm,
            authschema_id: authshmid,
            recipients: [],
            addinfo: addinf,
            locks: [],
            instruction_int_id: 0,
            varstates: []
        });
    }

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_type = obj.type;
        this.m_userlimit = obj.userlimit;
        this.m_authschema_id = obj.authschema_id;

        this.m_recipients = new Array<WFDocumentAuthRecipient>();
        for (let rcp of obj.recipients) this.m_recipients.push(new WFDocumentAuthRecipient(rcp));

        this.m_addinfo = obj.addinfo;

        this.m_locks = new Array<WFDocumentAuthLock>();
        for (let lck of obj.locks) this.m_locks.push(new WFDocumentAuthLock(lck));

        this.m_instruction_int_id = obj.instruction_int_id;

        this.m_varstates = new Map<number, IWFDocumentAuthVariable>();
        for (let vst of obj.varstates) this.m_varstates.set(vst.key, IWFDocumentAuthVariable.CreateFromJson(vst.value));
    }

    //

    private CloneRecipients(trcps2: Array<WFSchemaRecipient>, dst: Array<WFDocumentAuthRecipient>, sys: WFSystem): void {
        let dar: WFDocumentAuthRecipient;
        let trcps: Array<WFSchemaRecipient> = trcps2.slice(0);
        trcps.sort((s1, s2) => s1.Order - s2.Order);

        for (let rc of trcps) {
            let instID = rc.Order + 1;
            switch (rc.ObjType) {
                case WFObjType.AUTHSCHEMA:
                    let ashm: WFAuthSchema = sys.AllSchemas.get(rc.ObjID);
                    let dshm: WFDocumentAuthSchema = WFDocumentAuthSchema.Create(ashm.Recipients,
                        ashm.Name,
                        ashm.Type,
                        ashm.AuthUserLimit,
                        ashm.ID,
                        ashm.AdditionalInfo,
                        sys);

                    dar = WFDocumentAuthRecipient.Create2(dshm, rc.Order, rc.AdditionalInfo, instID); //0
                    dst.push(dar);
                    break;

                case WFObjType.USER:
                    dar = WFDocumentAuthRecipient.Create(rc.ObjID, rc.Order, rc.AdditionalInfo, instID);
                    dst.push(dar);
                    break;

                case WFObjType.USERGROUP:
                    let grp: WFUserGroup = sys.AllUserGroups.get(rc.ObjID);
                    for (let uid of grp.Users) {
                        dar = WFDocumentAuthRecipient.Create(uid, rc.Order, rc.AdditionalInfo, instID);
                        dst.push(dar);
                    }
                    break;
            }
        }
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Type(): WFAuthMethodType {
        return this.m_type;
    }

    //

    public get Userlimit(): number {
        return this.m_userlimit;
    }

    //

    public get AuthSchemaID(): number {
        return this.m_authschema_id;
    }

    //

    public get InstructionInternalID(): number {
        return this.m_instruction_int_id;
    }

    //

    public GetRecipientsCount(): number {
        return this.m_recipients.length;
    }

    public GetRecipient(id: number): WFDocumentAuthRecipient {
        return this.m_recipients[id];
    }

    //

    public GetStatus(): WFAuthStatus {
        let ast: WFAuthStatus;

        switch (this.m_type) {
            case WFAuthMethodType.AUTH_ALLOF:
                let sret = WFAuthStatus.APPROVED;

                for (let rc of this.m_recipients) {
                    ast = WFAuthStatus.WAITING;
                    switch (rc.ObjType) {
                        case WFDocAuthObjType.DOCAUTHSCHEMA:
                            ast = rc.SubSchema.GetStatus();
                            break;

                        case WFDocAuthObjType.USER:
                            ast = rc.Status;
                            break;
                    }

                    if (ast === WFAuthStatus.DISAPPROVED) return WFAuthStatus.DISAPPROVED;
                    if (ast === WFAuthStatus.WAITING) sret = WFAuthStatus.WAITING;
                }

                return sret;

            case WFAuthMethodType.AUTH_NOFF:
                let cc = 0;

                for (let rc of this.m_recipients) {
                    ast = WFAuthStatus.WAITING;
                    switch (rc.ObjType) {
                        case WFDocAuthObjType.DOCAUTHSCHEMA:
                            ast = rc.SubSchema.GetStatus();
                            break;

                        case WFDocAuthObjType.USER:
                            ast = rc.Status;
                            break;
                    }

                    if (ast === WFAuthStatus.DISAPPROVED) return WFAuthStatus.DISAPPROVED;
                    if (ast === WFAuthStatus.APPROVED) cc++;
                }

                if (cc >= this.m_userlimit) return WFAuthStatus.APPROVED;
                break;

            case WFAuthMethodType.AUTH_ONEOF:
                for (let rc of this.m_recipients) {
                    switch (rc.ObjType) {
                        case WFDocAuthObjType.DOCAUTHSCHEMA:
                            ast = rc.SubSchema.GetStatus();
                            if (ast !== WFAuthStatus.WAITING) return ast;
                            break;

                        case WFDocAuthObjType.USER:
                            if (rc.Status !== WFAuthStatus.WAITING) return rc.Status;
                            break;
                    }
                }
                break;

            case WFAuthMethodType.AUTH_CUSTOM:
                if (this.m_instruction_int_id === WFProcessAction.APPROVEPOINT) return WFAuthStatus.APPROVED;
                if (this.m_instruction_int_id === WFProcessAction.DISAPPROVEPOINT) return WFAuthStatus.DISAPPROVED;

                /*for (let rc of this.m_recipients) {
                    ast = WFAuthStatus.WAITING;
                    switch (rc.ObjType) {
                        case WFDocAuthObjType.DOCAUTHSCHEMA:
                            ast = rc.SubSchema.GetStatus();
                            break;

                        case WFDocAuthObjType.USER:
                            ast = rc.Status;
                            break;
                    }

                    if (ast === WFAuthStatus.DISAPPROVED) return WFAuthStatus.DISAPPROVED;
                }*/

                break;
        }

        return WFAuthStatus.WAITING;
    }

    //

    public CmpRecipients(shm: WFAuthSchema, sys: WFSystem): boolean {
        return this.CmpRecipients2(shm.Type, shm.AuthUserLimit, shm.Recipients, shm.ID, sys);
    }

    //

    private IndexOfUser(uid: number, order: number): number {
        for (let ii = 0; ii < this.m_recipients.length; ii++) {
            let arc = this.m_recipients[ii];
            if (arc.ObjType === WFDocAuthObjType.USER) {
                if (arc.ObjID === uid && arc.Order === order) return ii;
            }
        }
        return -1;
    }

    //

    public CmpRecipients2(tp: WFAuthMethodType, usrlimit: number, rcps: Array<WFSchemaRecipient>, schmid: number,
        sys: WFSystem): boolean {
        if (this.m_type === tp && this.m_userlimit === usrlimit && this.m_authschema_id === schmid) {
            let ret = true;

            for (let rc of rcps) {
                switch (rc.ObjType) {
                    case WFObjType.AUTHSCHEMA:
                        let shm = sys.AllSchemas.get(rc.ObjID);

                        let found = false;
                        for (let arc of this.m_recipients) {
                            if (arc.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
                                if (arc.SubSchema.CmpRecipients2(shm.Type, shm.AuthUserLimit, shm.Recipients, shm.ID, sys)) {
                                    found = true;
                                    break;
                                }
                            }
                        }

                        if (!found) ret = false;
                        break;

                    case WFObjType.USER:
                        if (this.IndexOfUser(rc.ObjID, rc.Order) < 0) ret = false;
                        break;

                    case WFObjType.USERGROUP:
                        let ugr = sys.AllUserGroups.get(rc.ObjID);
                        for (let uid of ugr.Users) {
                            if (this.IndexOfUser(uid, rc.Order) < 0) {
                                ret = false;
                                break;
                            }
                        }
                        break;
                }

                if (!ret) break;
            }

            return ret;
        }

        return false;
    }

    //

    public FindAuthRecipient(user_id: number): WFDocumentAuthRecipient {
        return this.FindAuthRecipient2(user_id, true, WFAuthStatus.WAITING);
    }

    public FindAuthRecipient2(user_id: number, visi: boolean, sts: WFAuthStatus): WFDocumentAuthRecipient {
        for (let rc of this.m_recipients) {
            if (rc.ObjType === WFDocAuthObjType.USER) {
                if (rc.ObjID === user_id && rc.Visibility === visi && rc.Status === sts) return rc;
            } else {
                let src: WFDocumentAuthRecipient = rc.SubSchema.FindAuthRecipient2(user_id, visi, sts);
                if (src != null) return src;
            }
        }

        return null;
    }

    /*public bool RemoveWaitingRecipient(int user_id)
    {
        bool ret = false;

        int ii= 0;
        while (ii < m_recipients.Count)
        {
            WFDocumentAuthRecipient rc = m_recipients[ii];
            if (rc.ObjType == WFDocAuthObjType.USER)
            {
                if (rc.Status == WFAuthStatus.WAITING && rc.ObjID == user_id)
                {
                    m_recipients.RemoveAt(ii);
                    ret = true;
                    continue;
                }
            }
            else
            {
                if (rc.SubSchema.RemoveWaitingRecipient(user_id))
                {
                    ret = true;
                    if (rc.SubSchema.GetRecipientsCount() == 0)
                    {
                        m_recipients.RemoveAt(ii);                            
                        continue;
                    }
                }
            }

            ii++;
        }

        return ret;
    }*/

    public FindAuthSchema(rcp: WFDocumentAuthRecipient): WFDocumentAuthSchema {
        for (let rc of this.m_recipients) {
            if (rc === rcp) return this;

            if (rc.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
                let subs: WFDocumentAuthSchema = rc.SubSchema.FindAuthSchema(rcp);
                if (subs != null) return subs;
            }
        }

        return null;
    }

    public FindAdditionalInfo(user_id: number): string {
        for (let rc of this.m_recipients) {
            if (rc.ObjType === WFDocAuthObjType.USER) {
                if (rc.ObjID === user_id && rc.Visibility && rc.Status === WFAuthStatus.WAITING) {
                    return (IWFObject.IsNullOrEmpty(rc.AdditionalInfo)) ? '' : rc.AdditionalInfo;
                }
            } else {
                let src = rc.SubSchema.FindAdditionalInfo(user_id);
                if (src != null) {
                    if (src.length > 0) return src;
                    if (!IWFObject.IsNullOrEmpty(rc.SubSchema.AdditionalInfo)) return rc.SubSchema.AdditionalInfo;
                    if (!IWFObject.IsNullOrEmpty(rc.AdditionalInfo)) return rc.AdditionalInfo;
                    return '';
                }
            }
        }

        return null;
    }

    public UserExists(user_id: number): boolean {
        for (let rc of this.m_recipients) {
            if (rc.ObjType === WFDocAuthObjType.USER) {
                if (rc.ObjID === user_id) return true;
            } else {
                if (rc.SubSchema.UserExists(user_id)) return true;
            }
        }

        return false;
    }

    public ExtractAllUsers(act: _ExtAct): void
    {
        for(let rc of this.m_recipients)
        {
            if (rc.ObjType == WFDocAuthObjType.USER)
            {
                act(rc);
            }
            else
            {
                rc.SubSchema.ExtractAllUsers(act);
            }
        }
    }

    /*public int ExtractUsers(WFDocumentAuthRecipient tofind, List<WFDocumentAuthRecipient> dst)
    {
        int ii, ii2;

        int[] levels = new int[m_recipients.Count];
        int levels_c = 0;

        for (ii = 0; ii < m_recipients.Count; ii++)
        {
            WFDocumentAuthRecipient rc = m_recipients[ii];
            if (Array.IndexOf(levels, rc.Order, 0, levels_c) < 0)
            {
                levels[levels_c] = rc.Order;
                levels_c++;
            }
        }

        //sortowanie poziomow
        Array.Sort(levels, 0, levels_c);

        bool doexit = false;
        for (ii = 0; (ii < levels_c) && (!doexit); ii++)
        {
            int actlv = levels[ii];

            //level
            for (ii2 = 0; ii2 < m_recipients.Count; ii2++)
            {
                WFDocumentAuthRecipient rc = m_recipients[ii2];
                if (rc.Order == actlv)
                {
                    switch (rc.ObjType)
                    {
                        case WFDocAuthObjType.DOCAUTHSCHEMA:
                            int res = rc.SubSchema.ExtractUsers(tofind, dst);
                            if (res != 0) doexit = true;
                            break;

                        case WFDocAuthObjType.USER:
                            dst.Add(rc);
                            break;
                    }

                    if (rc == tofind) doexit = true;
                }
            }
        }

        return (doexit) ? -1 : 0;
    }*/

    public GetApprovedCount(sts: WFAuthStatus): number {
        let cc = 0;

        for (let rc of this.m_recipients) {
            if (rc.ObjType === WFDocAuthObjType.USER) {
                if (rc.Status === sts) cc++;
            } else {
                cc += rc.SubSchema.GetApprovedCount(sts);
            }
        }

        return cc;
    }

    //

    public get AdditionalInfo(): string {
        return this.m_addinfo;
    }

    //

    public CanLock(uid: number): boolean {
        if (this.m_type === WFAuthMethodType.AUTH_ONEOF) {
            if (this.m_locks.length > 0) {
                let llck: WFDocumentAuthLock = this.m_locks[this.m_locks.length - 1];
                if (llck.UnLockAt == null) return false;
            }

            for (let rc of this.m_recipients) {
                if (rc.ObjType === WFDocAuthObjType.USER && rc.ObjID === uid && rc.Visibility) return true;
            }
        }

        return false;
    }

    public LockSchema(uid: number): boolean {
        if (this.CanLock(uid)) {
            this.m_locks.push(WFDocumentAuthLock.Create(uid));
            return true;
        }
        return false;
    }

    //

    public CanUnLock(uid: number): boolean {
        if (this.m_type === WFAuthMethodType.AUTH_ONEOF) {
            if (this.m_locks.length > 0) {
                let llck: WFDocumentAuthLock = this.m_locks[this.m_locks.length - 1];
                if (llck.UserID === uid && llck.UnLockAt == null) return true;
            }
        }

        return false;
    }

    public UnLockSchema(uid: number): boolean {
        if (this.CanUnLock(uid)) {
            let llck: WFDocumentAuthLock = this.m_locks[this.m_locks.length - 1];
            llck.UnLockAt = new Date();
            return true;
        }

        return false;
    }

    public FindUnLock(): WFDocumentAuthLock {
        if (this.m_locks.length > 0) {
            let llck: WFDocumentAuthLock = this.m_locks[this.m_locks.length - 1];
            if (llck.UnLockAt == null) return llck;
        }

        return null;
    }

    //ver 4.3

    public SetVisibility(vis: boolean): void {
        let ii: number;
        for (ii = 0; ii < this.m_recipients.length; ii++) {
            let rcp = this.m_recipients[ii];
            rcp.Visibility = vis;
            if (rcp.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
                rcp.SubSchema.SetVisibility(vis);
            }
        }
    }

    public MergeVariables(vars: Map<number, IWFDocumentAuthVariable>): void {
        for (let varr of Array.from(vars.entries())) {
            let vr: IWFDocumentAuthVariable = varr[1];
            this.m_varstates.set(varr[0], vr);
        }
    }

    public ToObject(): any {
        let recipients = new Array<any>();
        for (let rcp of this.m_recipients) recipients.push(rcp.ToObject());

        let locks = new Array<any>();
        for (let lck of this.m_locks) locks.push(lck.ToObject());

        let varstates = new Array<any>();
        for (let vst of Array.from(this.m_varstates.entries())) varstates.push({ key: vst[0], value: vst[1].ToObject() });

        return {
            id: this.m_id,
            name: this.m_name,
            type: this.m_type,
            userlimit: this.m_userlimit,
            authschema_id: this.m_authschema_id,
            recipients: recipients,
            addinfo: this.m_addinfo,
            locks: locks,
            instruction_int_id: this.m_instruction_int_id,
            varstates: varstates
        };
    }
}

import { Md5 } from 'ts-md5/dist/md5';
import { EventEmitter } from '@angular/core';

export enum WFResourceType {
    RES_IMAGEPNG,
    RES_IMAGEPDF,
    RES_PLAINTEXT,
    RES_RTF,
    RES_OTHER
}

export abstract class IWFObject {
    public static CutString(sval: string, mlen: number): string {
        if (sval != null) {
            sval = sval.trim();
            if (sval.length > mlen) sval = sval.substr(0, mlen);
        }
        return sval;
    }

    public static IsNullOrEmpty(str: string): boolean {
        return (str == null || str.length === 0 || str.trim() === '');
    }

    public static Format(format: string, ...args: any[]): string {
        //let args = arguments;
        return format.replace(/{(\d+)}/g, function (match, number) {
            let aid = Number.parseInt(number);
            return (typeof args[aid] !== 'undefined') ? args[aid] : match;
        });
    }

    public static StrToDbl(val: string): number {
        let ret = 0;
        let ii: number;
        if (val != null) {
            let dot: number = val.indexOf('.');
            let coma: number = val.indexOf(',');
            if (dot > coma) {
                if (coma > 0)
                    val = val.replace(',', '');
            } else {
                if (dot < coma) {
                    if (dot > 0)
                        val = val.replace('.', '');
                    val = val.replace(',', '.');
                }
            }
            let val2 = '';
            for (ii = 0; ii < val.length; ii++) {
                if ((val[ii] === '0') || (val[ii] === '1') || (val[ii] === '2') || (val[ii] === '3') || (val[ii] === '4') || (val[ii] === '5') || (val[ii] === '6') || (val[ii] === '7') || (val[ii] === '8') || (val[ii] === '9') || (val[ii] === '.') || (val[ii] === '-')) {
                    val2 += val[ii];
                }
            }
            if (val2.length > 0) ret = IWFObject.ParseFloat(val2);
        }
        return ret;
    }

    public static GetHashString(str: string): string {
        return <string>Md5.hashAsciiStr(str);
    }

    public static ElementAt<T>(iter: Iterator<T>, id: number): T {
        id++;
        let ret: IteratorResult<T>;
        for (let ii = 0; ii < id; ii++) {
            ret = iter.next();
        }
        return ret.value;
    }

    public static ParseDate(obj: Object): Date {
        if (obj != null) {
            if (obj instanceof Date) return <Date>obj;
            if (typeof obj === 'string') {
                let str: string = <string>obj;
                if (str.length > 0) {
                    let vv = str.split(/\D/);
                    let dt = new Date(IWFObject.ParseInt(vv[0]),
                        IWFObject.ParseInt(vv[1]) - 1,
                        IWFObject.ParseInt(vv[2]),
                        IWFObject.ParseInt(vv[3]),
                        IWFObject.ParseInt(vv[4]),
                        IWFObject.ParseInt(vv[5]));
                    return dt;
                }
            }
        }
        return null;
    }

    public static ParseShortDate(str: string): Date {
        if (str != null && str.length > 9) {
            let day = IWFObject.ParseInt(str.substr(0, 2));
            let month = IWFObject.ParseInt(str.substr(3, 2));
            let year = IWFObject.ParseInt(str.substr(6, 4));
            return new Date(year, month - 1, day);
        }
        return null;
    }

    public static PadZeroStr(nval: number): string {
        if (nval < 10) return '0' + nval.toString();
        return nval.toString();
    }

    public static DateToString(dta: Date): string {
        if (dta != null) {
            return IWFObject.Format('{0}-{1}-{2}T{3}:{4}:{5}',
                dta.getFullYear(),
                IWFObject.PadZeroStr(dta.getMonth() + 1),
                IWFObject.PadZeroStr(dta.getDate()),
                IWFObject.PadZeroStr(dta.getHours()),
                IWFObject.PadZeroStr(dta.getMinutes()),
                IWFObject.PadZeroStr(dta.getSeconds()));
        }
        return null;
    }

    public static ToHexByte(num: number): string {
        return ('0' + num.toString(16)).slice(-2);
    }

    public static ParseColor(obj: any): string {
        return '#' + IWFObject.ToHexByte(obj.R) + IWFObject.ToHexByte(obj.G) + IWFObject.ToHexByte(obj.B);
    }

    public static StringToColor(str: string): any {
        return {
            A: 255,
            R: Number.parseInt(str.substr(1, 2), 16),
            G: Number.parseInt(str.substr(3, 2), 16),
            B: Number.parseInt(str.substr(5, 2), 16)
        };
    }

    public static StringCompare(str1: string, str2: string): number {
        return str1.localeCompare(str2);
    }

    public static DateCompare(dta1: Date, dta2: Date): number {
        let tm1 = (dta1 == null) ? 0 : dta1.getTime();
        let tm2 = (dta2 == null) ? 0 : dta2.getTime();
        if (tm1 < tm2) return -1;
        if (tm1 > tm2) return 1;
        return 0;
    }

    public static ExtractValue(str: string): string {
        if (str != null) {
            let soff = str.indexOf('<!>');
            if (soff === 0) return '';
            return (soff > 0) ? str.substr(0, soff) : str;
        }
        return null;
    }

    public static ParseInt(str: string): number {
        let ret = Number.parseInt(str);
        if (isNaN(ret)) throw new Error();
        return ret;
    }

    public static ParseIntFromProp(str: string): number
    {
        if (IWFObject.IsNullOrEmpty(str)) return 0;
        let soff = str.indexOf('<!>');
        if(soff > 0) str = str.substr(0, soff);
        let ret = Number.parseInt(str);
        if (isNaN(ret)) throw new Error();
        return ret;
    }

    public static ParseFloat(str: string): number {
        let ret = Number.parseFloat(str);
        if (isNaN(ret)) throw new Error();
        return ret;
    }

    public static ParseDateStr(str: string): Date {
        let vstr= str.split('-');
        if(vstr.length!= 3) throw new Error();

        let day= IWFObject.ParseInt(vstr[0]);
        let month= IWFObject.ParseInt(vstr[1]);
        let year= IWFObject.ParseInt(vstr[2]);
        
        let ret= Date.parse(month+'/'+day+'/'+year);
        if (isNaN(ret)) throw new Error();
        return new Date(ret);
    }

    public static SafeUnsubscribe<T>(evh: EventEmitter<T>): void {
        try {
            if (evh.observers.length > 0) evh.observers.splice(0, evh.observers.length);
        } catch (ex) {
        }
    }

    public static Round(val: number, dec: number): number {
        return Number(Math.round(Number.parseFloat(val.toString() + 'e' + (dec | 0).toString())) + 'e-' + (dec | 0).toString());
    }

    /*public static EncryptPassword2(val: string, key: string): string {
        if (val.length > 0) {
            let inp = val; //new Buffer(val, 'utf-8');
            let enckey = key; //new Buffer(key, 'utf-8');
            let ret = '';
            for (let ii = 0; ii < inp.length; ii++) { //inp.byteLength
                let keyoff = ii % enckey.length;
                let aval = inp.charCodeAt(ii) ^ enckey.charCodeAt(keyoff);
                ret += String.fromCharCode(aval);
            }
            return btoa(ret);
        }
        return val;
    }*/

    private static ByteArrayToString(bytes: Uint8Array): string {
        let binary = '';
        let len = bytes.byteLength;
        for (let ii = 0; ii < len; ii++) {
          binary += String.fromCharCode(bytes[ii]);
        }
        return window.btoa(binary);
    }
    
    private static StringToUint8Array(str: string): Uint8Array {
        let arr: Array<number> = [];
        for (let ii = 0; ii < str.length; ii++) {
          arr.push(str.charCodeAt(ii));
        }
        return new Uint8Array(arr);
    }
    
    private static EncryptPassword2(val: string, key: string): string {
        if (val.length > 0) {
          let inp: Uint8Array = IWFObject.StringToUint8Array(val);
          let enckey: Uint8Array = IWFObject.StringToUint8Array(key);
          for (let ii = 0; ii < inp.length; ii++) {
            let keyoff = ii % enckey.length;
            let aval = inp[ii] ^ enckey[keyoff];
            inp[ii] = aval;
          }
          return IWFObject.ByteArrayToString(inp);
        }
        return val;
    }

    private static PUBLICKEY1 = 'js83mw=\'43mxopw=2ie8qm\'23yiw3628973';
    private static PUBLICKEY2 = '[]=2783nms0wef]=-3jdfhwuewef8729348';
    private static PUBLICKEY3 = '92kdj934lerelrtf9345834rewfddsfewf34r][34r34r';

    public static EncryptPassword(val: string, dta: Date): string {
        val = IWFObject.EncryptPassword2(val, IWFObject.PUBLICKEY1);
        val = IWFObject.EncryptPassword2(val, IWFObject.GetHashString(IWFObject.Format('{0}{1}{2}', dta.getDate(), dta.getMonth() + 1, dta.getFullYear())));
        val = IWFObject.EncryptPassword2(val, IWFObject.PUBLICKEY2);
        val = IWFObject.EncryptPassword2(val, IWFObject.GetHashString(IWFObject.Format('{0}{1}{2}', dta.getDate(), dta.getMonth() + 1, dta.getFullYear())));
        val = IWFObject.EncryptPassword2(val, IWFObject.PUBLICKEY3);
        return IWFObject.EncryptPassword2(val, IWFObject.GetHashString(IWFObject.Format('{0}{1}{2}', dta.getMonth() + 1, dta.getDate(), dta.getFullYear())));
    }

    public static RemoveHRefs(ctx: string): string {
        ctx= ctx.replace(/href\s*=\s*"[^\s]+"/gi, 'href="javascript:false"');
        ctx= ctx.replace(/href\s*=\s*[^\s^>]+>/gi, 'href="javascript:false">');
        return ctx;
    }

    public static SortMap(mp: Map<string,string>): Map<string,string> {
        let sdc= Array.from(mp.entries()).sort((a,b)=> a[1].localeCompare(b[1]));
        let ret= new Map<string,string>();
        for(let dc of sdc)
            ret.set(dc[0], dc[1]);
        return ret;
    }
}
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { _OnError } from '../adapters/WFDocumentAdp';
import { WFMailFolderAdp } from '../adapters/WFMailFolderAdp';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { CacheService } from '../data/cache.service';
import { DataService, MailMsgPreview } from '../data/data.service';
import { EventAdp } from '../data/eventadp';
import { BUTTONSTYPE, GlobalService } from '../global/global.service';
import { IWFObject, WFDocument } from '../model';
import { StringDataService } from '../stringdata/stringdata.service';

interface RowSpec {
    selected: boolean;
    type: string;
    name: string;
    att: any;
    action: string; 
}

@Component({
  selector: 'app-add-new-doc-from-mail',
  templateUrl: './add-new-doc-from-mail.component.html',
  styleUrls: ['./add-new-doc-from-mail.component.css']
})

export class AddNewDocFromMailComponent {
  private m_foldername: string;
  private m_mailuniqid: number;
  private m_msg: MailMsgPreview;
  private m_prnt: WFMailFolderAdp;
  public m_rows: Array<RowSpec>;

  @Output() public onClosed: EventEmitter<Array<WFDocument>>;

  public m_busy_str: string;
  private m_onerrorevh: EventAdp<_OnError>;

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) { 

    this.m_foldername= null;
    this.m_mailuniqid= 0;
    this.m_msg= null;
    this.m_rows= new Array<RowSpec>();
    this.m_prnt= null;

    this.onClosed = new EventEmitter<Array<WFDocument>>();
    this.m_busy_str = '';

    const self= this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });
  }

  public SetPrms(msg:MailMsgPreview, fnm: string, mailuid: number, prnt: WFMailFolderAdp): void {
    this.m_msg= msg;
    this.m_foldername= fnm;
    this.m_mailuniqid= mailuid;
    this.m_prnt= prnt;

    this.m_rows.push({
      selected: false,
      type: this.m_strdata_srv.getStr('wAddNewDocType_Ctx'),
      name: '',
      att: null,
      action: '' 
    });

    for(let att of msg.attachments) {
      let off= att.filename.lastIndexOf('.');
      let ext= (off > 0) ? att.filename.substring(off+1).toUpperCase() : '';

      this.m_rows.push({
        selected: true,
        type: this.m_strdata_srv.getStr('wAddNewDocType_Att'),
        name: att.filename,
        att: att,
        action: (['BMP', 'JPG', 'PNG', 'TIF', 'TIFF'].indexOf(ext) >= 0) ? 'OCR' : 'AOCR' 
      });  
    }
  }
  
  public OKButton_Click(): void {
    try {
      let seq= new Array<object>();

      for(let rw of this.m_rows) {
        if(rw.selected) {
          if(IWFObject.IsNullOrEmpty(rw.action)) {
            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNeedSelAction'));
            return;
          }

          seq.push({ contentid: (rw.att==null) ? null : rw.att.contentid, action: rw.action });
        }
        //  seq.push((rw.att==null) ? null : rw.att.contentid);
      }

      if(seq.length > 0) {
        this.m_busy_str= this.m_strdata_srv.getStr('strDocUpdateInfo');
        const self= this;
        this.m_data_srv.createDocumentFromMail(this.m_cache_srv.SessionID, 
                                              this.m_foldername, 
                                              this.m_mailuniqid,                                             
                                              seq,  this.m_prnt.Login, this.m_prnt.GetEncPassword(),
                                              this.m_onerrorevh,
                                              (docs)=> {
                                                self.m_busy_str= '';
                                                self.onClosed.emit(docs);  
                                              });

      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCheckAtLeast1Item'));
      }
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(null);
  }

  /*public MoveUp(rw:RowSpec): void {
    let off= this.m_rows.indexOf(rw);
    if(off > 0) {
      let offb= off - 1;
      let tmp= this.m_rows[offb];
      this.m_rows[offb]= this.m_rows[off];
      this.m_rows[off]= tmp;
    }
  }

  public MoveDown(rw:RowSpec): void {
    let off= this.m_rows.indexOf(rw);
    if(off >= 0 && off < this.m_rows.length - 1) {
      let offb= off + 1;
      let tmp= this.m_rows[offb];
      this.m_rows[offb]= this.m_rows[off];
      this.m_rows[off]= tmp;
    }
  }*/

  public FillComboBox(cmb: ComboboxadpComponent, rw: RowSpec): void {
    try {
      if(rw.att== null) {  
        cmb.AddValidValue('SDOC', this.m_strdata_srv.getStr('wAddNewDocCol_ActSepDoc'));
        cmb.AddValidValue('TBEG', this.m_strdata_srv.getStr('wAddNewDocCol_ActBegin'));
        cmb.AddValidValue('TEND', this.m_strdata_srv.getStr('wAddNewDocCol_ActEnd'));        
      } else {
        cmb.AddValidValue('OCR', this.m_strdata_srv.getStr('wAddNewDocCol_ActOCR'));
        cmb.AddValidValue('AOCR', this.m_strdata_srv.getStr('wAddNewDocCol_ActAffOCR'));
        cmb.AddValidValue('BOCR', this.m_strdata_srv.getStr('wAddNewDocCol_ActBefOCR'));   
      }
    } catch (ex) {
        this.m_global_srv.manageException(ex);
    }
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-busy-indicator',
  template:
  '<div class="BUSYIND">' +
  '<table style="width: 100%; height: 100%;">' +
  '<tr><td style="text-align: center; vertical-align: middle;">' +
  '<img src="assets/img/loading.gif" alt=""/>' +
  '<br/>' +
  '<label class="FNT_TXT_LIGHT_NORMAL">{{busy_text}}</label>' +
  '</td></tr>' +
  '</table>' +
  '</div>'
})

export class BusyIndicatorComponent {
  @Input() busy_text: string;

  constructor() { }
}

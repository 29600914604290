import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, CodeName, CodeCodeName, BusinessPartnerAddress, BusinessPartner } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { SbobusinesspartnereditComponent } from '../sbobusinesspartneredit/sbobusinesspartneredit.component';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';
import { WFCompany, IWFObject, WFOcrTaskMode, WFReconSchema, WFDocumentOcrResults, WFOcrFldType } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, FetchMatchReconSchemaCompleted, FetchKeywordsCompleted, _OnError } from '../adapters/WFDocumentAdp';
import { WFReconSchemaAdp, FetchPreviewCompleted } from '../adapters/WFReconSchemaAdp';
import { EventAdp } from '../data/eventadp';

const VECTORDELIM = '<!>';

export type _GetOcrResults = () => WFDocumentOcrResults;

@Component({
  selector: 'app-sbobusinesspartner',
  templateUrl: './sbobusinesspartner.component.html',
  styleUrls: ['./sbobusinesspartner.component.scss']
})

export class SbobusinesspartnerComponent implements AfterViewInit {
  public m_enabled: boolean;
  public m_keywords: string;

  @ViewChild('m_cardcode', {static: false}) private m_cardcode: ComboboxadpComponent;

  public m_cardname: string;
  public m_street: string;
  public m_zipcode: string;
  public m_city: string;
  public m_country: string;

  private m_doc: IWFCompanyObjAdp;
  public m_busy_text: string;
  private m_onerrorevh: EventAdp<_OnError>;
  
  private m_addkwords: string;
  private m_addkwords_shm: string;

  private m_fetchmatchrs: FetchMatchReconSchemaCompleted;
  private m_fetchkwordsevh: FetchKeywordsCompleted;
  private m_fetchschemadta: FetchPreviewCompleted;

  private m_getocrrs: _GetOcrResults;
  private m_initialized: boolean;
  private m_setafterinit: string;
  //

  @Output() afterInit: EventEmitter<SbobusinesspartnerComponent>;

  //

  @Output() CardCodeChange: EventEmitter<string>;

  public set CardCode(value: string) {
    if(this.m_initialized) {
      this._setCardCode(value);
    } else {
      this.m_setafterinit= value;
    }
  }

  @Input() public get CardCode(): string {
    return this.m_cardcode.SelectedValue;
  }

  @Input()
  public set IsEnabled(nval: boolean) {
    this.m_enabled = nval;
  }

  public get IsEnabled(): boolean {
    return this.m_enabled;
  }

  //
  //
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_initialized= false;
    this.m_setafterinit= null;

    this.afterInit = new EventEmitter<SbobusinesspartnerComponent>();
    this.CardCodeChange = new EventEmitter<string>();
    this.m_enabled = true;
    this.m_keywords = '';
    this.m_cardname = '';
    this.m_street = '';
    this.m_zipcode = '';
    this.m_city = '';
    this.m_doc = null;
    this.m_busy_text = '';

    this.m_addkwords = null;
    this.m_addkwords_shm = null;
    this.m_getocrrs = null;

    const self = this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_text = ''; });
    
    this.m_fetchmatchrs = (snd, shmcodes) => {
      self.OnFetchMatchScriptCompleted(snd, shmcodes);
    };

    this.m_fetchkwordsevh = (snd, kwords) => {
      self.OnFetchKeywordsCompleted(snd, kwords);
    };

    this.m_fetchschemadta = (snd, hdr, lns) => {
      self.OnFetchSchemaDataCompleted(snd, hdr, lns);
    };
  }

  //

  private _setCardCode(value: string): void {
    try {
      if (this.m_doc == null) this.afterInit.emit(this);

      if (IWFObject.IsNullOrEmpty(value)) {
        if (!IWFObject.IsNullOrEmpty(this.m_cardcode.SelectedValue)) 
          this.ClearKeyWords();
        //this.ClearBPFinder();
      } else {
        let ccvec: Array<string> = value.split(VECTORDELIM);
        if (ccvec.length === 6) {
          let bps: BusinessPartner = {
            CardCode: ccvec[0],
            CardType: 'S',
            CardName: ccvec[1],
            CardFName: ccvec[1],
            FTaxID: '',
            FreeText: '',
            Delivery: null,
            Billing: {
              Street: ccvec[2],
              Country: { Code: '', Name: ccvec[5] },
              State: null,
              ZipCode: ccvec[4],
              City: ccvec[3]
            }
          };
          
          this.SetBPInfo(bps);
        } else {
          this.LoadBPInfo((ccvec.length > 0) ? ccvec[0] : value, false);
        }
      }
      
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }    
  }

  ngAfterViewInit() {
    if (this.m_doc == null) this.afterInit.emit(this);
    this.BindBPFEvents(); 
    this.m_initialized= true;
    if(this.m_setafterinit!= null) {
      this._setCardCode(this.m_setafterinit);
      this.m_setafterinit= null;
    }
  }

  public SetSource(doc: IWFCompanyObjAdp, getocrrs: _GetOcrResults): void {
    this.m_doc = doc;
    this.m_getocrrs = getocrrs;
  }

  private get Company(): WFCompany {
    return (this.m_doc.CompanyID > 0) ? this.m_cache_srv.Companies.get(this.m_doc.CompanyID) : IWFObject.ElementAt(this.m_cache_srv.Companies.values(), 0);
  }

  //

  private ManageException(ex): void {
    this.m_global_srv.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strSBOServiceError'));
  }

  private GetAllCardCodes(dbname: string): void {
    this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
    const self = this;
    this.m_data_srv.getAllCardCodes(this.m_cache_srv.SessionID, this.Company.BusinessPartnerDB, dbname, this.m_onerrorevh,
      (arr) => {
        self.GetAllCardCodesCompleted(arr);
      });
  }

  private AddValidValue(ccode: string, cname: string): void {
    this.m_cardcode.AddValidValue(ccode, (IWFObject.IsNullOrEmpty(cname)) ? ccode : IWFObject.Format('{0} - {1}', ccode, cname));
  }

  private GetAllCardCodesCompleted(ret: Array<CodeName>): void {
    this.m_busy_text = '';

    try {
      this.m_cardcode.ClearValidValues();
      this.m_cardcode.AddValidValue('', this.m_strdata_srv.getStr('strUndefined'));

      ret= ret.sort((a,b)=> a.Name.localeCompare(b.Name));
      for (let ii = 0; ii < ret.length; ii++) {
        let cn: CodeName = ret[ii];
        this.AddValidValue(cn.Code, cn.Name);
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  private ClearBPFinder(): void {
    if (this.m_enabled) {
      if (this.m_doc.CompanyID > 0) {
        let dbname: string = this.m_cache_srv.Companies.get(this.m_doc.CompanyID).DBName;
        this.GetAllCardCodes(dbname);
      }
    } else {
      this.m_cardcode.ClearValidValues();
      this.m_cardcode.AddValidValue('', this.m_strdata_srv.getStr('strUndefined'));
    }
  }

  public ClearKeyWords(): void {
    try {
      if (this.m_enabled) {
        this.m_keywords = '';
        this.m_cardcode.SelectedValue = '';
        this.ClearBPFinder();
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  //

  private BindBPFEvents(): void {
    const self = this;
    this.m_cardcode.SelectedValueChange.subscribe(() => {
      self.CardCodeChanged();
    });
  }

  private UnBindBPFEvents(): void {
    IWFObject.SafeUnsubscribe(this.m_cardcode.SelectedValueChange);
  }

  private GetBP(dbname: string, ccode: string, actccode: boolean): void {
    this.m_busy_text = IWFObject.Format(this.m_strdata_srv.getStr('wSboBusinessPartner_GetData4Customer') + ' {0}', ccode);
    const self = this;
    this.m_data_srv.getBP(this.m_cache_srv.SessionID, this.Company.BusinessPartnerDB, dbname, ccode, this.m_onerrorevh,
      (bp) => {
        self.GetBPCompleted(bp, dbname, ccode, actccode);
      });
  }

  private GetBPCompleted(bps: BusinessPartner, dbname: string, ccode: string, actccode: boolean): void {
    this.m_busy_text = '';
    try {
      this.SetBPInfo(bps);
      if (actccode) this.SetCardCodeValue(bps);
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  private SetBPInfo(bps: BusinessPartner): void {
    this.FillBPF(bps);
    if (this.m_cardcode.ValidValuesCount === 0) {
      this.UnBindBPFEvents();
      this.m_cardcode.AddValidValue('', this.m_strdata_srv.getStr('strUndefined'));
      this.AddValidValue(bps.CardCode, bps.CardName);
      this.m_cardcode.SelectedValue = bps.CardCode;
      this.BindBPFEvents();
    }
  }

  private SetCardCodeValue(bps: BusinessPartner): void {
    let vve = new Array<string>(6);
    vve[0] = bps.CardCode;
    vve[1] = bps.CardName;
    if (bps.Billing != null) {
      vve[2] = bps.Billing.Street;
      vve[3] = bps.Billing.City;
      vve[4] = bps.Billing.ZipCode;
      if (bps.Billing.Country != null) {
        vve[5] = bps.Billing.Country.Name;
      }
    }

    let jstr = vve.join(VECTORDELIM);
    this.CardCode = jstr;
    this.CardCodeChange.emit(jstr);
  }

  private FillBPF(res: BusinessPartner): void {
    this.m_cardname = res.CardName;
    //ToolTipService.SetToolTip(m_cardname, res.CardName);
    if (res.Billing != null) {
      this.m_street = res.Billing.Street;
      //ToolTipService.SetToolTip(m_street, res.Billing.Street);
      this.m_city = res.Billing.City;
      //ToolTipService.SetToolTip(m_city, res.Billing.City);
      this.m_zipcode = res.Billing.ZipCode;
      //ToolTipService.SetToolTip(m_zipcode, res.Billing.ZipCode);
      if (res.Billing.Country != null) {
        this.m_country = res.Billing.Country.Name;
        //ToolTipService.SetToolTip(m_country, res.Billing.Country.Name);
      }
    }
  }

  private LoadBPInfo(ccode: string, actccode: boolean): void {
    if (this.m_doc.CompanyID > 0) {
      let dbname = this.m_cache_srv.Companies.get(this.m_doc.CompanyID).DBName;
      this.GetBP(dbname, ccode, actccode);
    }
  }

  public CardCodeChanged(): void {
    try {
      if (IWFObject.IsNullOrEmpty(this.m_cardcode.SelectedValue)) {
        this.m_cardname = this.m_street = this.m_city = this.m_country = this.m_zipcode = '';
        //ToolTipService.SetToolTip(m_cardname, "");
        //ToolTipService.SetToolTip(m_street, "");
        //ToolTipService.SetToolTip(m_city, "");
        //ToolTipService.SetToolTip(m_country, "");
        //ToolTipService.SetToolTip(m_zipcode, "");

        this.CardCode = '';
        this.CardCodeChange.emit('');
      } else {
        let ccode: string = this.m_cardcode.SelectedValue;
        this.LoadBPInfo(ccode, true);
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  //

  public AddNewBusinessPartner(): void {
    try {
      if (this.m_enabled) {
        const self = this;
        const wnd = this.m_wnd_srv.showControl(SbobusinesspartnereditComponent);
        let ins: SbobusinesspartnereditComponent = <SbobusinesspartnereditComponent>wnd.instance;
        ins.SetPrms(this.m_doc, '', (this.m_getocrrs == null) ? null : this.m_getocrrs());

        ins.onClosed.subscribe((status) => {
          wnd.destroy();
          if (status) {
            //this.Company.BusinessPartnerDB
            self.AddNewBusinessPartnerClosed(ins.BusinessPartner);
          }
        });
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  private AddNewBusinessPartnerClosed(bp: BusinessPartner): void {
    try {
      this.AddValidValue(bp.CardCode, bp.CardName);
      this.m_cardcode.SelectedValue = bp.CardCode;
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  //

  public EditBusinessPartner(): void {
    try {
      if ((this.m_enabled) && (!IWFObject.IsNullOrEmpty(this.m_cardcode.SelectedValue))) {
        const self = this;
        const wnd = this.m_wnd_srv.showControl(SbobusinesspartnereditComponent);
        let ins: SbobusinesspartnereditComponent = <SbobusinesspartnereditComponent>wnd.instance;
        ins.SetPrms(this.m_doc, this.m_cardcode.SelectedValue, (this.m_getocrrs == null) ? null : this.m_getocrrs());

        ins.onClosed.subscribe((status) => {
          wnd.destroy();
          if (status) {
            //this.Company.BusinessPartnerDB
            self.EditBusinessPartnerClosed(ins.BusinessPartner);
          }
        });
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  private EditBusinessPartnerClosed(bp: BusinessPartner): void {
    try {
      this.FillBPF(bp);
      this.SetCardCodeValue(bp);
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  //

  public SearchByKeywords(): void {
    let ii: number;
    try {
      if (this.m_enabled) {
        //WFDocumentAdp doc = m_doc as WFDocumentAdp;
        if (!(this.m_doc instanceof WFDocumentAdp)) {
          this.m_addkwords = '';
          this.FindBPByKeywords();
        } else {
          let doc: WFDocumentAdp = <WFDocumentAdp>this.m_doc;

          if (this.m_cache_srv.Config.OcrTaskMode === WFOcrTaskMode.EX_DISK) {
            if (IWFObject.IsNullOrEmpty(doc.ReconSchemaCode)) {
              if (this.m_addkwords == null) {
                this.m_busy_text = this.m_strdata_srv.getStr('strWaitCompareSchemas');

                doc.OnFetchMatchReconSchemaCompleted = this.m_fetchmatchrs;
                doc.FetchMatchReconSchemas();
              } else {
                this.FindBPByKeywords();
              }
            } else {
              if (doc.ReconSchemaCode === this.m_addkwords_shm) {
                this.FindBPByKeywords();
              } else {
                this.m_busy_text = this.m_strdata_srv.getStr('strWaitFoundSchemas');
                this.m_addkwords_shm = doc.ReconSchemaCode;
                //pobranie danych dla schematu bh
                let shm: WFReconSchema = this.m_cache_srv.ReconSchemas.get(this.m_addkwords_shm);
                let adp: WFReconSchemaAdp = new WFReconSchemaAdp(shm, doc);
                adp.OnFetchPreviewCompleted = this.m_fetchschemadta;
                adp.FetchPreview();
              }
            }
          } else {
            if (this.m_addkwords == null) {
              let bld = '';
              for (ii = 0; ii < doc.GetOcrResultsCount(); ii++) {
                let rss: WFDocumentOcrResults = doc.GetOcrResult(ii);
                for (let fld of rss.Fields) {
                  if (fld.Type !== WFOcrFldType.TABLE) {
                    if (bld.length > 0) bld += ';';
                    bld += fld.Name;
                    bld += ':';
                    bld += fld.GetScalarValue();
                  }
                }
              }

              if(doc.ClassID > 0) {
                let cls= this.m_cache_srv.Classes.get(doc.ClassID);
                if(bld.length > 0) bld += ';';
                bld += 'SPUMACLASSUID:'+cls.GlobalID;
              }

              this.m_addkwords = bld;
            }

            this.FindBPByKeywords();
          }
        }
      }
      return;
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_text = '';
  }

  //

  private OnFetchMatchScriptCompleted(snd: WFDocumentAdp, shmcodes: Array<string>): void {
    try {
      if (shmcodes.length > 0) {
        this.m_addkwords_shm = shmcodes[0];
        //pobranie wyniku dla pierwszego schematu lub wybranego
        let shm: WFReconSchema = this.m_cache_srv.ReconSchemas.get(this.m_addkwords_shm);
        let adp: WFReconSchemaAdp = new WFReconSchemaAdp(shm, snd);
        adp.OnFetchPreviewCompleted = this.m_fetchschemadta;
        adp.FetchPreview();
      } else {
        this.m_addkwords = '';
        this.FindBPByKeywords();
      }
      return;
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_text = '';
  }

  //

  private FindBPByKeywords(): void {
    let nval = this.m_keywords.trim();
    if (nval.length > 0) {
      if (this.m_doc.CompanyID > 0) {
        let dbname = this.m_cache_srv.Companies.get(this.m_doc.CompanyID).DBName;
        this.FindBP(dbname, nval);
      }
    } else {
      if (this.m_doc instanceof WFDocumentAdp) {
        let doc: WFDocumentAdp = <WFDocumentAdp>this.m_doc;
        this.m_busy_text = this.m_strdata_srv.getStr('wSboBusinessPartner_GetData');
        doc.OnFetchKeywordsCompleted = this.m_fetchkwordsevh;
        doc.FetchKeywords();
      }
    }
  }

  //

  private OnFetchKeywordsCompleted(snd: WFDocumentAdp, kwords: string): void {
    try {
      if (IWFObject.IsNullOrEmpty(kwords)) {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wSboBusinessPartner_errNoDataToAnalyze'));
      } else {
        if (this.m_doc.CompanyID > 0) {
          if (kwords.length > 4000) kwords = kwords.substr(0, 4000);
          let dbname = this.m_cache_srv.Companies.get(this.m_doc.CompanyID).DBName;
          this.FindBP(dbname, kwords);
          return;
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }

  //

  private FindBP(dbname: string, kwords: string): void {
    this.m_busy_text = this.m_strdata_srv.getStr('strWaitSearch');
    //new object[]{ dbname, kwords }

    const self = this;
    this.m_data_srv.findBP(this.m_cache_srv.SessionID, this.Company.BusinessPartnerDB, dbname, kwords, this.m_addkwords, this.m_onerrorevh, (codes) => {
      self.FindBPCompleted(codes);
    });
  }

  private FindBPCompleted(ret: Array<CodeName>): void {
    this.m_busy_text = '';

    try {

      if (ret.length > 0) {
        this.m_cardcode.ClearValidValues();
        this.m_cardcode.AddValidValue('', this.m_strdata_srv.getStr('strUndefined'));

        ret= ret.sort((a,b)=> a.Name.localeCompare(b.Name));
        for (let ii = 0; ii < ret.length; ii++) {
          let cn: CodeName = ret[ii];
          this.AddValidValue(cn.Code, cn.Name);
        }
        this.m_cardcode.SelectedValue = ret[0].Code;
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wSboBusinessPartner_errCustomerNotFound'));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private OnFetchSchemaDataCompleted(snd: WFReconSchemaAdp, header: Array<[string, Array<object>]>, lines: Array<[string, Array<object>]>): void {
    try {
      let bld = '';
      for (let ii = 0; ii < header.length; ii++) {
        let iv = header[ii];
        if (ii > 0) bld += ';';
        bld += iv[0];
        bld += ':';
        if (iv[1].length > 0) bld += iv[1][0].toString();
      }

      if (this.m_doc instanceof WFDocumentAdp) {
        let doc: WFDocumentAdp = <WFDocumentAdp>this.m_doc;
        if(doc.ClassID > 0) {
          let cls= this.m_cache_srv.Classes.get(doc.ClassID);
          if(bld.length > 0) bld += ';';
          bld += 'SPUMACLASSUID:'+cls.GlobalID;
        }
      }

      this.m_addkwords = bld;
      this.FindBPByKeywords();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }
}

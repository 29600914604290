import { CommonModule, registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, Compiler, CompilerFactory, COMPILER_OPTIONS, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { JitCompilerFactory } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbCalendar, NgbCalendarGregorian } from '@ng-bootstrap/ng-bootstrap';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { CookieService } from 'ngx-cookie-service';
import { InlineSVGModule } from 'ng-inline-svg';
import { QuillModule } from 'ngx-quill';
import { AboutWndComponent } from './about-wnd/about-wnd.component';
import { AddNewDocMenuComponent } from './add-new-doc-menu/add-new-doc-menu.component';
import { AlertWndComponent } from './alert-wnd/alert-wnd.component';
import { AppComponent } from './app.component';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ApproveWndComponent } from './approve-wnd/approve-wnd.component';
import { AttachmentsPopupComponent } from './attachments-popup/attachments-popup.component';
import { AutoCompleteTextBoxPopupComponent } from './auto-complete-text-box-popup/auto-complete-text-box-popup.component';
import { AutoCompleteTextBoxComponent } from './auto-complete-text-box/auto-complete-text-box.component';
import { AutofocusDirective } from './autofocus.directive';
import { BusyIndicatorComponent } from './busy-indicator/busy-indicator.component';
import { ChildPopupComponent } from './child-window/child-popup.component';
import { ChildWindowContainerDirective } from './child-window/child-window-container.directive';
import { ChildWindowContainerService } from './child-window/child-window-container.service';
import { ChildWindowComponent } from './child-window/child-window.component';
import { ColumnMapDefinitionsComponent } from './column-map-definitions/column-map-definitions.component';
import { ColumnValueSelectorComponent } from './column-value-selector/column-value-selector.component';
import { ComboboxadpComponent } from './comboboxadp/comboboxadp.component';
import { DailyCorRefsPopupComponent } from './daily-cor-refs-popup/daily-cor-refs-popup.component';
import { CacheService } from './data/cache.service';
import { DataService } from './data/data.service';
import { DatepickeradpPopupComponent } from './datepickeradp-popup/datepickeradp-popup.component';
import { DatepickeradpComponent } from './datepickeradp/datepickeradp.component';
import { DirectoryNameComponent } from './directory-name/directory-name.component';
import { DocumentModificationsComponent } from './document-modifications/document-modifications.component';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { DocumentPrintComponent } from './document-print/document-print.component';
import { DocumentadpComponent } from './documentadp/documentadp.component';
import { DocviewerComponent } from './docviewer/docviewer.component';
import { DownloadDocumentComponent } from './download-document/download-document.component';
import { EditDailyCorrespondenceComponent } from './edit-daily-correspondence/edit-daily-correspondence.component';
import { EditauthschemaComponent } from './editauthschema/editauthschema.component';
import { EditpermissionsComponent } from './editpermissions/editpermissions.component';
import { FilterWndComponent } from './filter-wnd/filter-wnd.component';
import { GlobalService } from './global/global.service';
import { ImageButtonComponent } from './image-button/image-button.component';
import { ImportFileParamsComponent } from './import-file-params/import-file-params.component';
import { LoginWndComponent } from './login-wnd/login-wnd.component';
import { LongTextAdpPopupComponent } from './long-text-adp-popup/long-text-adp-popup.component';
import { LongTextAdpComponent } from './long-text-adp/long-text-adp.component';
import { MultiselectpanelComponent } from './multiselectpanel/multiselectpanel.component';
import { OcrProcessLogsComponent } from './ocr-process-logs/ocr-process-logs.component';
import { ParentpermissionsComponent } from './parentpermissions/parentpermissions.component';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { RequestCommentComponent } from './request-comment/request-comment.component';
import { ResizeCommentDirective } from './resize-comment.directive';
import { SbobusinesspartnerComponent } from './sbobusinesspartner/sbobusinesspartner.component';
import { SbobusinesspartnereditComponent } from './sbobusinesspartneredit/sbobusinesspartneredit.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { StringDataPipe } from './stringdata/stringdata.pipe';
import { StringDataService } from './stringdata/stringdata.service';
import { TemplateNameComponent } from './template-name/template-name.component';
import { ElementExtenderDirective } from './tree-view/element-extender.directive';
import { TreeViewComponent } from './tree-view/tree-view.component';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { PrintreporttaskComponent } from './printreporttask/printreporttask.component';
import { MailBoxViewerComponent } from './mail-box-viewer/mail-box-viewer.component';
import { SafeHtmlPipe } from './safehtml.pipe';
import { AddNewDocFromMailComponent } from './add-new-doc-from-mail/add-new-doc-from-mail.component';
import { ImapLoginComponent } from './imap-login/imap-login.component';
import { EDocViewerComponent } from './edoc-viewer/edoc-viewer.component';


export function createCompiler(fn: CompilerFactory): Compiler {
  return fn.createCompiler();
  //return new JitCompilerFactory().createCompiler();
}

export function initConfig(global_srv: GlobalService) {
  registerLocaleData(localePl, 'pl');
  return () => global_srv.LoadConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    StringDataPipe,
    LoginWndComponent,
    BusyIndicatorComponent,
    ChildWindowComponent,
    ChildWindowContainerDirective,
    AlertWndComponent,
    TreeViewComponent,
    ImageButtonComponent,
    MultiselectpanelComponent,
    ComboboxadpComponent,
    DocumentadpComponent,
    DocviewerComponent,
    SbobusinesspartnerComponent,
    SbobusinesspartnereditComponent,
    ApproveWndComponent,
    ApprovalsComponent,
    EditauthschemaComponent,
    EditpermissionsComponent,
    ParentpermissionsComponent,
    SendEmailComponent,
    RequestCommentComponent,
    ColumnMapDefinitionsComponent,
    ColumnValueSelectorComponent,
    ChildPopupComponent,
    ReportViewerComponent,
    EditDailyCorrespondenceComponent,
    AddNewDocMenuComponent,
    TemplateNameComponent,
    DirectoryNameComponent,
    UserPanelComponent,
    DownloadDocumentComponent,
    DocumentModificationsComponent,
    DocumentPreviewComponent,
    AttachmentsPopupComponent,
    DocumentPrintComponent,
    ElementExtenderDirective,
    ResizeCommentDirective,
    DailyCorRefsPopupComponent,
    ImportFileParamsComponent,
    AutoCompleteTextBoxComponent,
    AutoCompleteTextBoxPopupComponent,
    FilterWndComponent,
    DatepickeradpComponent,
    DatepickeradpPopupComponent,
    LongTextAdpComponent,
    LongTextAdpPopupComponent,
    AutofocusDirective,
    AboutWndComponent,
    OcrProcessLogsComponent,
    PrintreporttaskComponent,
    MailBoxViewerComponent,
    SafeHtmlPipe,
    AddNewDocFromMailComponent,
    ImapLoginComponent,
    EDocViewerComponent
  ],
  imports: [
    NgbModule,//.forRoot(),
    BrowserModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    ButtonsModule,
    DropDownsModule,
    InputsModule,
    DateInputsModule,
    HttpClientModule,
    InlineSVGModule.forRoot({baseUrl:'assets/img/'}),
    MatMenuModule,
    MatTooltipModule,
    GridModule,
    QuillModule.forRoot()
  ],
  providers: [
    {provide : LOCALE_ID, useValue: 'en-US' },
    StringDataService,
    DataService,
    GlobalService,
    ChildWindowContainerService,
    CacheService,
    CookieService,
    //{ provide: CookieOptions, useClass: BaseCookieOptions },
    { provide: COMPILER_OPTIONS, useValue: {}, multi: true },
    { provide: CompilerFactory, useClass: JitCompilerFactory, deps: [COMPILER_OPTIONS] },
    { provide: Compiler, useFactory: createCompiler, deps: [CompilerFactory] },
    { provide: APP_INITIALIZER, useFactory: initConfig, deps: [GlobalService], multi: true },
    { provide: NgbCalendar, useClass: NgbCalendarGregorian }
  ],
  entryComponents: [
    ChildWindowComponent,
    LoginWndComponent,
    AlertWndComponent,
    SbobusinesspartnereditComponent,
    ApproveWndComponent,
    ApprovalsComponent,
    EditauthschemaComponent,
    EditpermissionsComponent,
    ParentpermissionsComponent,
    SendEmailComponent,
    RequestCommentComponent,
    ColumnMapDefinitionsComponent,
    ColumnValueSelectorComponent,
    EditDailyCorrespondenceComponent,
    AddNewDocMenuComponent,
    TemplateNameComponent,
    DirectoryNameComponent,
    UserPanelComponent,
    DownloadDocumentComponent,
    DocumentModificationsComponent,
    DocumentPreviewComponent,
    AttachmentsPopupComponent,
    DocumentPrintComponent,
    DailyCorRefsPopupComponent,
    ImportFileParamsComponent,
    AutoCompleteTextBoxPopupComponent,
    FilterWndComponent,
    DatepickeradpPopupComponent,
    LongTextAdpPopupComponent,
    AboutWndComponent,
    OcrProcessLogsComponent,
    PrintreporttaskComponent,
    AddNewDocFromMailComponent,
    ImapLoginComponent,
    EDocViewerComponent
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}

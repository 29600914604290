import { WFOcrResultPage } from './WFOcrResultPage';
import { WFOcrField } from './WFOcrField';

export class WFDocumentOcrResults {
    private m_id: number;
    private m_schemacode: string;
    private m_title: string;
    private m_comments: string;
    private m_pages: Array<WFOcrResultPage>;
    private m_fields: Array<WFOcrField>;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_schemacode = obj.schemacode;
        this.m_title = obj.title;
        this.m_comments = obj.comments;
        this.m_pages = new Array<WFOcrResultPage>();
        for (let pg of obj.pages) this.m_pages.push(new WFOcrResultPage(pg));

        this.m_fields = new Array<WFOcrField>();
        for (let fd of obj.fields) this.m_fields.push(new WFOcrField(fd));
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get SchemaCode(): string {
        return this.m_schemacode;
    }

    //

    public get Title(): string {
        return this.m_title;
    }

    //

    public get Comments(): string {
        return this.m_comments;
    }

    //

    public get Pages(): Array<WFOcrResultPage> {
        return this.m_pages;
    }

    //

    public get Fields(): Array<WFOcrField> {
        return this.m_fields;
    }

    //

    public ToObject(): any {
        let pages = new Array<any>();
        for (let pg of this.m_pages) pages.push(pg.ToObject());

        let fields = new Array<any>();
        for (let fd of this.m_fields) fields.push(fd.ToObject());

        return {
            id: this.m_id,
            schemacode: this.m_schemacode,
            title: this.m_title,
            comments: this.m_comments,
            pages: pages,
            fields: fields
        };
    }
}

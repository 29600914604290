import { WFProcessVarColumn, WFProcessVarColType } from './WFProcessVarColumn';

export enum WFProcessVarType {
    TYPE_TEXT,
    TYPE_INT,
    TYPE_FLOAT,
    TYPE_SCHEMA,
    TYPE_TABLE,
    TYPE_DATE 
}

export class WFProcessVariable {
    public static readonly EVENTARGSINTID = -1;

    private static m_sysvariable: Array<WFProcessVariable> = null;

    //

    private m_int_id: number;
    private m_name: string;
    private m_description: string;
    private m_type: WFProcessVarType;
    private m_evalcode: string;
    private m_cols: Array<WFProcessVarColumn>;

    //

    public static GetSystemDefinitions(): Array<WFProcessVariable> {
        if (WFProcessVariable.m_sysvariable == null) {
            WFProcessVariable.m_sysvariable = new Array<WFProcessVariable>(1);

            let pvar = new WFProcessVariable({
                int_id: WFProcessVariable.EVENTARGSINTID,
                name: 'EVENTARGS',
                description: '',
                type: WFProcessVarType.TYPE_TABLE,
                evalcode: '',
                cols: []
            });
            pvar.Columns.push(new WFProcessVarColumn({ name: 'OBJECTID', type: WFProcessVarColType.TYPE_INT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'CLASSID', type: WFProcessVarColType.TYPE_INT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'USERID', type: WFProcessVarColType.TYPE_INT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'DOCSTATUS', type: WFProcessVarColType.TYPE_INT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'ITEMCODE', type: WFProcessVarColType.TYPE_TEXT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'ITEMVALUE', type: WFProcessVarColType.TYPE_TEXT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'LINEID', type: WFProcessVarColType.TYPE_INT }));
            pvar.Columns.push(new WFProcessVarColumn({ name: 'CLASSATTRIBS_ID', type: WFProcessVarColType.TYPE_INT }));
            WFProcessVariable.m_sysvariable[0] = pvar;
        }
        return WFProcessVariable.m_sysvariable;
    }

    //

    public static GetSystemDefinitionByID(id: number): WFProcessVariable {
        let ii: number;

        let sysdefs = WFProcessVariable.GetSystemDefinitions();
        for (ii = 0; ii < sysdefs.length; ii++) {
            let tst = sysdefs[ii];
            if (tst.InternalID === id) return tst;
        }

        return null;
    }

    //

    public static IsNameValid(str: string): boolean {
        let nstr: string = str.trim();
        if (!(new RegExp('^[a-zA-Z0-9_\\-\\.]+$')).test(nstr)) return false;
        return true;
    }

    //

    public static Create(intid: number, cd: string, dsc: string, tp: WFProcessVarType, _eval: string): WFProcessVariable {
        return new WFProcessVariable({
            int_id: intid,
            name: cd,
            description: dsc,
            type: tp,
            evalcode: eval,
            cols: []
        });
    }

    //

    constructor(obj: any) {
        this.m_int_id = obj.int_id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_type = obj.type;
        this.m_evalcode = obj.evalcode;
        this.m_cols = new Array<WFProcessVarColumn>();
        for (let pvc of obj.cols) this.m_cols.push(new WFProcessVarColumn(pvc));
    }

    //

    public get InternalID(): number {
        return this.m_int_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get Type(): WFProcessVarType {
        return this.m_type;
    }

    //

    public get EvalCode(): string {
        return this.m_evalcode;
    }

    //

    public get Columns(): Array<WFProcessVarColumn> {
        return this.m_cols;
    }

    /*public bool ContainsColumn(string name, WFProcessVarColType ctp)
    {
        int ii;
 
        if(!String.IsNullOrEmpty(name))
        {
            name= name.ToUpper();
            for(ii=0; ii< m_cols.Count; ii++)
            {
                WFProcessVarColumn col = m_cols[ii];
                if (col.Name == name && col.Type == ctp) return true;
            }
        }
 
        return false;
    }
 
    public WFProcessVarColumn AddColumn(string name, WFProcessVarColType ctp)
    {
        int ii;
 
        if (!String.IsNullOrEmpty(name))
        {
            name = name.ToUpper();
            for (ii = 0; ii < m_cols.Count; ii++)
            {
                WFProcessVarColumn col = m_cols[ii];
                if (col.Name == name) return null;
            }
 
            WFProcessVarColumn ret = new WFProcessVarColumn(name, ctp);
            m_cols.Add(ret);
            return ret;
        }
 
        return null;
    }*/
}
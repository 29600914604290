import { WFPageComment, WFCommentEntry, IWFObject, WFPermissionInfo, WFPropType, WFPrmType } from '../model/index';
import { WFDocumentAdp } from './WFDocumentAdp';
import { StringDataService } from '../stringdata/stringdata.service';

export type ModifyStatusChange = (snd: WFCommentAdp) => void;

export class WFCommentAdp {
    private m_comment: WFPageComment;
    private m_document: WFDocumentAdp;
    private m_ismodified: boolean;

    public OnModifyStatusChange: ModifyStatusChange;

    constructor(cmm: WFPageComment, doc: WFDocumentAdp, public m_strdata_srv: StringDataService) {
        this.m_comment = cmm;
        this.m_document = doc;
        this.m_ismodified = false;
        this.OnModifyStatusChange = null;
    }

    public get ID(): number {
        return this.m_comment.ID;
    }

    public get UserID(): number {
        return this.m_comment.UserID;
    }

    public set PosX(value: number) {
        if (this.m_comment.PosX !== value) {
            this.m_comment.PosX = value;
            this.SetModified(false);
        }
    }


    public get PosX(): number {
        return this.m_comment.PosX;
    }

    public set PosY(value: number) {
        if (this.m_comment.PosY !== value) {
            this.m_comment.PosY = value;
            this.SetModified(false);
        }
    }

    public get PosY(): number {
        return this.m_comment.PosY;
    }


    public set Width(value: number) {
        if (this.m_comment.Width !== value) {
            this.m_comment.Width = value;
            this.SetModified(false);
        }
    }
    public get Width(): number {
        return this.m_comment.Width;
    }

    public set Height(value: number) {
        if (this.m_comment.Height !== value) {
            this.m_comment.Height = value;
            this.SetModified(false);
        }
    }

    public get Height(): number {
        return this.m_comment.Height;
    }


    //

    public GetEntriesCount(): number {
        return this.m_comment.Entries.length;
    }

    //

    public GetEntry(id: number): WFCommentEntry {
        return this.m_comment.Entries[id];
    }

    //

    private SetModified(fevent: boolean): void {
        if (!this.m_ismodified) {
            this.m_ismodified = true;
            this.m_document.IsModified = true;
            if (this.OnModifyStatusChange != null) this.OnModifyStatusChange(this);
        } else {
            if (fevent && this.OnModifyStatusChange != null) this.OnModifyStatusChange(this);
        }
    }

    //

    public AddNewEntry(ctx: string): void {
        if (!IWFObject.IsNullOrEmpty(ctx)) {
            if ((this.m_comment.ID === 0) && (this.m_comment.Entries.length > 0)) {
                this.m_comment.Entries[0].Content = ctx;
            } else {
                this.m_comment.Entries.push(WFCommentEntry.Create(this.m_document.CacheService.User.ID, ctx));
            }

            this.SetModified(true);
        }
    }

    //

    public get IsNewComment(): boolean {
        return (this.m_comment.ID === 0);
    }

    //

    public get CanMoveResize(): boolean {
        if (this.m_document.CacheService.IsSuperUser ||
            this.m_comment.UserID === this.m_document.CacheService.User.ID) return true;

        //zbadaj uprawnienia
        let allow = false;
        let replusrs: Array<number> = this.m_document.CacheService.User.FindReplacements(this.m_document.CacheService.Users);

        let pinfs: Array<WFPermissionInfo> = this.m_document.ListPermissions(true, WFPropType.CMN_CANMOVE);
        for (let pinf of pinfs) {
            if ((pinf.UserID === this.m_document.CacheService.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0)) {
                allow = (pinf.Type === WFPrmType.CHANGE);
                break;
            }
        }

        return allow;
    }

    //SK
    public getLastUpdateInfo(): string[] {
      const today = new Date();
      let sorted =  this.m_comment.Entries.sort((lside: WFCommentEntry, rside: WFCommentEntry): number => {
        if (lside.CreatedAt < rside.CreatedAt) return 1;
        if (lside.CreatedAt > rside.CreatedAt) return -1;
        return 0;
      });
      const commdate =  sorted[0].CreatedAt;

      let cnt = sorted[0].Content;
      let diff = today.valueOf() - sorted[0].CreatedAt.valueOf();
      let diffMin = Math.ceil(diff / (1000 * 60 ));
      //let diffDays = Math.ceil(diff / (1000 * 3600 * 24));
      let diffDays = Math.floor((Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) - Date.UTC(commdate.getFullYear(), commdate.getMonth(), commdate.getDate()) ) /(1000 * 60 * 60 * 24))
      let retstr = '';
      //return [diff.toString() + ' ' + diffMin.toString() + ' ' + diffDays.toString(), cnt];

      if (diffDays <= 0) {
        if (diffMin < 60) {
          return [diffMin.toString() + ' min.', cnt];
        }
        if ((diffMin > 60) && (diffMin < 120)) {
          return [this.m_strdata_srv.getStr('strHourAgo'), cnt];
        }
        return [(diffMin * 60).toString() + ' ' + this.m_strdata_srv.getStr('strHour'), cnt];
      }
      if (diffDays === 1) {
        return [this.m_strdata_srv.getStr('strYesterday'), cnt];
      }
      if (diffDays > 1) {
        return  [diffDays.toString() + ' ' + this.m_strdata_srv.getStr('strDaysAgo') , cnt];
      }
      return ['', ''];
    }
}

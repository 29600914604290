import { WFClassAttrib } from './WFClassAttrib';
import { WFPermission, WFPropType, WFPrmType } from './WFPermission';
import { WFColumnFormat } from './WFColumnFormat';
import { WFPermissionInfo } from './WFDocument';
import { WFSystem } from './WFSystem';
import { WFGroupRole } from './WFUserGroup';
import { IWFObject } from './IWFObject';

export class WFDailyCorrespondenceClass {
    private m_id: number;
    private m_organization_id: number;
    private m_name: string;
    private m_description: string;
    private m_enabled: boolean;
    private m_reportctx: string;
    private m_createdat: Date;
    private m_updatedat: Date;
    private m_attribs: Array<WFClassAttrib>;
    private m_permissions: Array<WFPermission>;
    private m_reportprms: Array<WFClassAttrib>;
    private m_reportcolumnformats: Array<WFColumnFormat>;

    /*constructor(nm: string, orgid: number) {
        this.m_id = 0;
        this.Name = nm;
        this.OrganizationID = orgid;
        this.m_description = "";
        this.m_enabled = true;
        this.m_reportctx = "";
        this.m_createdat = this.m_updatedat = Date.Now;
        this.m_attribs = new List<WFClassAttrib>();
        this.m_permissions = new List<WFPermission>();
        this.m_reportprms = new List<WFClassAttrib>();
        this.m_reportcolumnformats = new List<WFColumnFormat>();
    }*/
    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_organization_id = obj.organization_id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_enabled = obj.enabled;
        this.m_reportctx = obj.reportctx;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        
        this.m_attribs = new Array<WFClassAttrib>();
        for (let atr of obj.attribs) this.m_attribs.push(new WFClassAttrib(atr));

        this.m_permissions = new Array<WFPermission>();
        for (let prm of obj.permissions) this.m_permissions.push(new WFPermission(prm));

        this.m_reportprms = new Array<WFClassAttrib>();
        for (let prm of obj.reportprms) this.m_reportprms.push(new WFClassAttrib(prm));

        this.m_reportcolumnformats = new Array<WFColumnFormat>();
        for (let rep of obj.reportcolumnformats) this.m_reportcolumnformats.push(new WFColumnFormat(rep));
    }

    public get ID(): number {
        return this.m_id;
    }

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get Enabled(): boolean {
        return this.m_enabled;
    }

    public get ReportCTX(): string {
        return this.m_reportctx;
    }

    public get Attributes(): Array<WFClassAttrib> {
        return this.m_attribs;
    }

    public get Permissions(): Array<WFPermission> {
        return this.m_permissions;
    }

    public get ReportParameters(): Array<WFClassAttrib> {
        return this.m_reportprms;
    }

    public get ReportColumnsFormat(): Array<WFColumnFormat> {
        return this.m_reportcolumnformats;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public ListPermissions(ptp: WFPropType, sys: WFSystem, user_id: number): Array<WFPermissionInfo> {
        let props: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        let adusr = true;
        sys.AllUserGroups.forEach(function (ugr, kv) {
            if ((ugr.GroupRole === WFGroupRole.CREATORS || ugr.GroupRole === WFGroupRole.SUPERUSERS) && ugr.Users.indexOf(user_id) >= 0) {
                for (let uid of ugr.Users) {
                    props.push(new WFPermissionInfo(uid, ugr.ID, WFPrmType.CHANGE));
                }
                adusr = false;
            }
        });

        if (adusr)
            props.push(new WFPermissionInfo(user_id, -1, WFPrmType.CHANGE));

        for (let prm of this.m_permissions) {
            if (prm.PropertyType === ptp)
                WFPermission.ActualizePermissionInfoList(props, prm, sys, null);
        }

        let ret: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        if (props.length > 0) {
            let ids: Array<number> = new Array(props.length);
            let ids_c = 0;
            for (let ii: number = props.length - 1; ii > -1; ii--) {
                let prmi: WFPermissionInfo = props[ii];
                if (ids.indexOf(prmi.UserID, 0) < 0) {
                    ret.push(prmi);
                    ids[ids_c++] = prmi.UserID;
                }
            }
        }
        return ret;
    }

    public ListPermissions2(atribid: number, sys: WFSystem, addcanedit: boolean, user_id: number): Array<WFPermissionInfo> {
        let props: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        if (addcanedit) {
            let adusr = true;
            sys.AllUserGroups.forEach(function (ugr, id) {
                if (ugr.GroupRole === WFGroupRole.CREATORS && ugr.Users.indexOf(user_id) >= 0) {
                    for (let uid of ugr.Users) {
                        props.push(new WFPermissionInfo(uid, ugr.ID, WFPrmType.CHANGE));
                    }
                    adusr = false;
                }
            });
            if (adusr)
                props.push(new WFPermissionInfo(user_id, -1, WFPrmType.CHANGE));
        }

        for (let prm of this.m_permissions) {
            if ((prm.PropertyType === WFPropType.DOC_CANEDIT && addcanedit) || (prm.PropertyType === WFPropType.DOC_CANEDITATTRIB && prm.AttribID === atribid))
                WFPermission.ActualizePermissionInfoList(props, prm, sys, null);
        }

        let ret: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        if (props.length > 0) {
            let ids: Array<number> = new Array(props.length);
            let ids_c = 0;
            for (let ii: number = props.length - 1; ii > -1; ii--) {
                let prmi: WFPermissionInfo = props[ii];
                if (ids.indexOf(prmi.UserID, 0) < 0) {
                    ret.push(prmi);
                    ids[ids_c++] = prmi.UserID;
                }
            }
        }
        return ret;
    }
}
import { IWFCustomInstruction, WFInstructionType } from './IWFCustomInstruction';

export enum WFScriptType {
    SQL
}

export class WFScriptopInstruction extends IWFCustomInstruction {
    private m_stype: WFScriptType;
    private m_content: string;
    private m_variable_id: number;

    //

    constructor(obj: any) {
        super(obj.int_id, obj.name, obj.description, WFInstructionType.SCRIPTOP);
        this.m_stype = obj.stype;
        this.m_content = obj.content;
        this.m_variable_id = obj.variable_id;
    }

    //

    public get OpType(): WFScriptType {
        return this.m_stype;
    }

    //

    public get Content(): string {
        return this.m_content;
    }

    //

    public get VariableID(): number {
        return this.m_variable_id;
    }
}

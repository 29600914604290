import { IWFObject } from './IWFObject';

export class WFCommentEntry {
    private m_id: number;
    private m_users_id: number;
    private m_content: string;
    private m_createdat: Date;

    //

    public static Create(usrid: number, content: string): WFCommentEntry {
        return new WFCommentEntry({ id: 0,
            users_id: usrid,
            content: content,
            createdat: new Date()
        });
    }

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_users_id = obj.users_id;
        this.m_content = obj.content;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get UserID(): number {
        return this.m_users_id;
    }

    //

    public set Content(value: string)
    {
        this.m_content = value.trim();
    }

    public get Content(): string {
        return this.m_content;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public ToObject(): any {
        return {
            id: this.m_id,
            users_id: this.m_users_id,
            content: this.m_content,
            createdat: IWFObject.DateToString(this.m_createdat)
        };
    }
}

import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';

@Component({
  selector: 'app-directory-name',
  templateUrl: './directory-name.component.html',
  styleUrls: ['./directory-name.component.scss']
})

export class DirectoryNameComponent {
  public m_busy_str: string;

  public m_dirname: string;
  public m_dirname_err: boolean;

  @Output() public onClosed: EventEmitter<boolean>;

  //

  public get DirName(): string {
    return this.m_dirname.trim();
  }

  //

  constructor(private m_global_srv: GlobalService) { 
    this.m_busy_str = '';

    this.m_dirname = '';
    this.m_dirname_err = false;

    this.onClosed = new EventEmitter<boolean>();
  }

  public SetPrms(ntitle: string): void {
    this.m_dirname = ntitle;
  }

  public OKButton_Click(): void {
    let nval = this.m_dirname.trim();
    if (nval.length > 0) {
      this.m_dirname_err = false;
    } else {
      this.m_dirname_err = true;
      return;
    }

    this.onClosed.emit(true);
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

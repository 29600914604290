import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { DataService, DownloadInfo } from '../data/data.service';
import { CacheService } from '../data/cache.service';
import { WFDocumentModification, IWFObject } from '../model';
import { WFModObjType } from '../model/WFModificationEntry';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';

interface ModificationEntry {
  intid: number;
  mod: WFDocumentModification;
  userinf: string;
  dateinf: string;
}

interface EntryInfo {
  image1: string;
  image1_info: string;
  image2: string;
  image2_info: string;
  field: string;
  oldvalue: string;
  newvalue: string;
}

@Component({
  selector: 'app-document-modifications',
  templateUrl: './document-modifications.component.html',
  styleUrls: ['./document-modifications.component.scss']
})

export class DocumentModificationsComponent {
  private static m_imgstates = ['cmn_uncheck', 'cmn_editrect', 'cmn_add'];
  private static m_imgobjects = ['cmn_type', 'cmn_user', 'cmn_link', 'appr_toapprove', 'cmn_lines', 'cmn_attribs'];

  public m_busy_str: string;
  private m_doc: WFDocumentAdp;

  public m_modifications: Array<ModificationEntry>;
  public m_selmodification: ModificationEntry;
  public m_entries: Array<EntryInfo>;

  @Output() public onClosed: EventEmitter<boolean>;

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_data_srv: DataService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_busy_str = '';
    this.m_doc = null;
    this.m_modifications = new Array<ModificationEntry>();
    this.m_selmodification = null;
    this.m_entries = new Array<EntryInfo>();
    this.onClosed = new EventEmitter<boolean>();
  }

  public SetPrms(doc: WFDocumentAdp): void {
    this.m_doc = doc;
    let len = this.m_doc.GetModificationsCount();
    for (let ii = 0; ii < len; ii++) {
      let md = this.m_doc.GetModification(ii);
      let uinf = '';
      if (md.UserID > 0) {
        let usr = (md.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(md.UserID);
        uinf = usr.FriendlyName;
      } else {
        uinf = 'SYS';
      }
      this.m_modifications.push({
        intid: ii,
        mod: md,
        userinf: uinf,
        dateinf: WFDocumentAdp.DateTimeToStr(md.CreatedAt, this.m_strdata_srv)
      });
    }
  }

  //

  private GetUserName(val: string): string {
    if (!IWFObject.IsNullOrEmpty(val)) {
      try {
        let uid = Number.parseInt(val);
        let usr = (uid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uid);
        return usr.FriendlyName;
      } catch (ex) {
      }
    }
    return this.m_strdata_srv.getStr('strUnknown');
  }

  private GetClassName(val: string): string {
    if (!IWFObject.IsNullOrEmpty(val)) {
      try {
        let clsid = Number.parseInt(val);
        if (clsid === 0) return this.m_strdata_srv.getStr('strUnknown');
        let cls = this.m_cache_srv.Classes.get(clsid);
        return cls.Name;
      } catch (ex) {
      }
    }
    return this.m_strdata_srv.getStr('strUnknown');
  }

  private GetCompanyName(val: string): string {
    if (!IWFObject.IsNullOrEmpty(val)) {
      try {
        let cmpid = Number.parseInt(val);
        if (cmpid === 0) return this.m_strdata_srv.getStr('strUnknown');
        let cmp = this.m_cache_srv.Companies.get(cmpid);
        return cmp.Company;
      } catch (ex) {
      }
    }
    return this.m_strdata_srv.getStr('strUnknown');
  }

  private GetDocumentName(val: string): string {
    if (!IWFObject.IsNullOrEmpty(val)) {
      try {
        let did = Number.parseInt(val);
        if (did === 0) return this.m_strdata_srv.getStr('strNone');
        if (this.m_cache_srv.ContainsDocument(did)) {
          let doc = this.m_cache_srv.GetDocument(did);
          return doc.Name;
        }
        return val;
      } catch (ex) {
      }
    }
    return this.m_strdata_srv.getStr('strUnknown');
  }

  private GetDirectoryName(val: string): string {
    if (!IWFObject.IsNullOrEmpty(val)) {
      try {
        let dirid = Number.parseInt(val);
        if (dirid < 0) return this.m_strdata_srv.getStr('strTrash');
        if (dirid === 0) return this.m_strdata_srv.getStr('strCompanyDir');
        let adp = this.m_global_srv.App.GetDirectory(dirid);
        return adp.DirName;
      } catch (ex) {
      }
    }
    return this.m_strdata_srv.getStr('strUnknown');
  }

  private GetAuthSchemaName(val: string): string {
    if (!IWFObject.IsNullOrEmpty(val)) {
      try {
        let shmid = Number.parseInt(val);
        if (shmid < 0) return this.m_strdata_srv.getStr('strUnknown');
        if (shmid === 0) return this.m_strdata_srv.getStr('strOwn');
        let shm = this.m_cache_srv.AuthSchemas.get(shmid);
        return shm.Name;
      } catch (ex) {
      }
    }
    return this.m_strdata_srv.getStr('strUnknown');
  }

  public SelectInstance(md: ModificationEntry): void {
    let ii: number;

    try {
      if (this.m_selmodification !== md) {
        this.m_entries.splice(0, this.m_entries.length);

        let len = md.mod.GetEntriesCount();
        let skip = new Array<string>();

        for (ii = 0; ii < len; ii++) {
          let ent = md.mod.GetEntry(ii);
          let entkey = IWFObject.Format('{0}_{1}', <number>ent.ObjType, ent.ObjId);
          if (skip.indexOf(entkey) >= 0) continue;

          let btnContent: string;
          let opcd: string;

          if (IWFObject.IsNullOrEmpty(ent.FieldCode)) {
            opcd = this.m_strdata_srv.getStr('strAdded');
            btnContent = DocumentModificationsComponent.m_imgstates[2];
          } else {
            if (ent.FieldCode.toUpperCase() === 'ID' && ent.NewValue == null) {
              opcd = this.m_strdata_srv.getStr('strDeleted2');
              btnContent = DocumentModificationsComponent.m_imgstates[0];
              skip.push(entkey);
            } else {
              opcd = this.m_strdata_srv.getStr('strModified');
              btnContent = DocumentModificationsComponent.m_imgstates[1];
            }
          }

          let btnContent2: string;
          let clsnm: string;

          switch (ent.ObjType) {
            case WFModObjType.PAGE:
              clsnm = this.m_strdata_srv.getStr('strPages');
              btnContent2 = DocumentModificationsComponent.m_imgobjects[0];
              break;
            case WFModObjType.PERMISSION:
              clsnm = this.m_strdata_srv.getStr('strPermissions');
              btnContent2 = DocumentModificationsComponent.m_imgobjects[1];
              break;
            case WFModObjType.REF:
              clsnm = this.m_strdata_srv.getStr('strERPRef');
              btnContent2 = DocumentModificationsComponent.m_imgobjects[2];
              break;
            case WFModObjType.USERAUTHSTATUS:
              clsnm = this.m_strdata_srv.getStr('strUserAuth');
              btnContent2 = DocumentModificationsComponent.m_imgobjects[3];
              break;
            case WFModObjType.LINE:
              clsnm = this.m_strdata_srv.getStr('strLines');
              btnContent2 = DocumentModificationsComponent.m_imgobjects[4];
              break;
            default:
              clsnm = this.m_strdata_srv.getStr('strDocument');
              btnContent2 = DocumentModificationsComponent.m_imgobjects[5];
              break;
          }

          let fcd = '';
          let oldvv = '';
          let newvv = '';

          if (!IWFObject.IsNullOrEmpty(ent.FieldCode)) {

            if (ent.FieldCode[0] === '_') {
              fcd = IWFObject.Format(this.m_strdata_srv.getStr('strAdditionalFields1'), ent.FieldCode.substr(1));
              oldvv = ent.OldValue;
              newvv = ent.NewValue;
            } else {
              switch (ent.FieldCode) {
                case 'UserID':
                  fcd = this.m_strdata_srv.getStr('strUserID'); //"Kreator";
                  oldvv = this.GetUserName(ent.OldValue);
                  newvv = this.GetUserName(ent.NewValue);
                  break;
                case 'ClassID':
                  fcd = this.m_strdata_srv.getStr('strClassID'); //"Klasa";
                  oldvv = this.GetClassName(ent.OldValue);
                  newvv = this.GetClassName(ent.NewValue);
                  break;
                case 'CompanyID':
                  fcd = this.m_strdata_srv.getStr('strCompanyID'); //"Firma";
                  oldvv = this.GetCompanyName(ent.OldValue);
                  newvv = this.GetCompanyName(ent.NewValue);
                  break;
                case 'DocumentID':
                  fcd = this.m_strdata_srv.getStr('strDocumentID'); //"Dokument";
                  oldvv = this.GetDocumentName(ent.OldValue);
                  newvv = this.GetDocumentName(ent.NewValue);
                  break;
                case 'DirectoryID':
                  fcd = this.m_strdata_srv.getStr('strDirectoryID'); //"Katalog";
                  oldvv = this.GetDirectoryName(ent.OldValue);
                  newvv = this.GetDirectoryName(ent.NewValue);
                  break;
                case 'Name':
                  fcd = this.m_strdata_srv.getStr('strName'); //"Nazwa";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'Description':
                  fcd = this.m_strdata_srv.getStr('strDescription'); //"Opis";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'DocDate':
                  fcd = this.m_strdata_srv.getStr('strDocDate'); //"Data dokumentu";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'EnterDate':
                  fcd = this.m_strdata_srv.getStr('strEnterDate'); //"Data recepcja";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'DocNum':
                  fcd = this.m_strdata_srv.getStr('strDocNum'); //"Numer";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'ExternalId':
                  fcd = this.m_strdata_srv.getStr('strExternalId'); //"Zewnętrzne id";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'TemplateID':
                  fcd = this.m_strdata_srv.getStr('strTemplateID'); //"Szablon";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'DocType':
                  fcd = this.m_strdata_srv.getStr('strDocType'); //"Typ dokumentu";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'AuthSchemaID':
                  fcd = this.m_strdata_srv.getStr('strAuthSchemaID'); //"Schemat";
                  oldvv = this.GetAuthSchemaName(ent.OldValue);
                  newvv = this.GetAuthSchemaName(ent.NewValue);
                  break;
                case 'AuthMethodType':
                  fcd = this.m_strdata_srv.getStr('strAuthMethodType'); //"Typ autoryzacji";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                case 'AuthUserLimit':
                  fcd = this.m_strdata_srv.getStr('strAuthUserLimit'); //"Limit autoryzacji";
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
                default:
                  fcd = ent.FieldCode;
                  oldvv = ent.OldValue;
                  newvv = ent.NewValue;
                  break;
              }
            }
          }

          this.m_entries.push({
            image1: btnContent,
            image1_info: opcd,
            image2: btnContent2,
            image2_info: clsnm,
            field: fcd,
            oldvalue: oldvv,
            newvalue: newvv
          });
        }

        this.m_selmodification = md;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public Preview(): void {
    try {
      if (this.m_selmodification != null) {
        let ndoc = this.m_doc.RebuildDocument(this.m_selmodification.intid);
        
        const wnd = this.m_wnd_srv.showControl(DocumentPreviewComponent);
        wnd.instance.SetPrms(ndoc, this.m_selmodification.mod);
        wnd.instance.onClosed.subscribe(() => {
            wnd.destroy();
        });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

import { WFMessage, WFMessageRecipient } from '../model/index';
import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';
import { _OnError } from './WFDocumentAdp';


export class WFMessageAdp {
    private m_msg: WFMessage;

    //

    constructor(msg: WFMessage) {
        this.m_msg = msg;
    }

    public get Subject(): string {
        return this.m_msg.Subject;
    }

    //

    public get Content(): string {
        return this.m_msg.Content;
    }

    //

    public get UserID(): number {
        return this.m_msg.UserID;
    }

    //

    public get CreatedAt(): Date {
        return this.m_msg.CreatedAt;
    }

    //

    public GetRecipientsCount(): number {
        return this.m_msg.Recipients.length;
    }

    //

    public GetRecipient(id: number): WFMessageRecipient {
        return this.m_msg.Recipients[id];
    }

    //

    public IsRead(conn: CacheService): boolean {
        for (let mrc of this.m_msg.Recipients) {
            if (mrc.UsersID === conn.User.ID) return mrc.IsRead;
        }
        return this.m_msg.IsRead;
    }

    //

    public ModifyIsRead(csrv: CacheService, nstate: boolean, onerr: EventAdp<_OnError>, onsucc: (sts: boolean) => void): boolean {
        let rcp: WFMessageRecipient = null;

        for (let mrc of this.m_msg.Recipients) {
            if (mrc.UsersID === csrv.User.ID) {
                rcp = mrc;
                break;
            }
        }

        if (rcp != null) {
            if (rcp.IsRead !== nstate) {
                rcp.IsRead = nstate;

                const self = this;
                csrv.DataService.modifyMessage(csrv.SessionID, this.m_msg, onerr, (msg) => {
                    self.m_msg = msg;
                    onsucc(nstate);
                });
                return true;
            }
        }
        return false;
    }

    //

    /*private void GetMessagesCompleted(object sender, GetMessagesCompletedEventArgs e)
    {
        int ii;

        try
        {
            Stream mstr = CrossUtils.GetResponseStream(e.Result);
            using (mstr)
            {
                if (m_conn.IsStreamValid(mstr))
                {
                    int len = IWFObject.DeserializeInt(mstr);
                    if (len > 0)
                    {
                        m_msg = WFMessage.BinaryDeserialize(mstr);
                        //wtorna aktualizacja wiadmosci i wyslanie
                        bool doact = false;

                        WFMessageRecipient srcp = (WFMessageRecipient)e.UserState;
                        for (ii = 0; ii < m_msg.Recipients.Count; ii++)
                        {
                            WFMessageRecipient trcp = m_msg.Recipients[ii];
                            if (trcp.UsersID == srcp.UsersID)
                            {
                                trcp.IsRead = srcp.IsRead;
                                doact= true;
                                break;
                            }
                        }

                        if (doact)
                        {
#if USEWCF
                            ModelServiceClient serv = (ModelServiceClient)sender;
#else
                            ModelServiceClient2 serv = (ModelServiceClient2)sender;
#endif
                            MemoryStream rq = new MemoryStream();
                            using (rq)
                            {
                                IWFObject.Serialize(rq, m_conn.SessionID);
                                m_msg.BinarySerialize(rq);
                                rq.Position = 0;
                                serv.ModifyMessageAsync(CrossUtils.GetRequestStream(rq));
                            }
                        }
                    }
                }
                else
                {
                    m_conn.GetNewSessionID();
                }
            }
        }
        catch (Exception ex)
        {
            MessageWnd.Show(ex);
        }
    }*/
}



export enum WFModObjType {
    DOCUMENT,
    PAGE,
    REF,
    PERMISSION,
    USERAUTHSTATUS,
    LINE
}

export class WFModificationEntry {
    private m_objtype: WFModObjType;
    private m_objid: number;
    private m_fieldcode: string;
    private m_oldvalue: string;
    private m_newvalue: string;

    //

    constructor(obj: any) {
        this.m_objtype = obj.objtype;
        this.m_objid = obj.objid;
        this.m_fieldcode = obj.fieldcode;
        this.m_oldvalue = obj.oldvalue;
        this.m_newvalue = obj.newvalue;
    }

    //

    public get ObjType(): WFModObjType {
        return this.m_objtype;
    }

    public get ObjId(): number {
        return this.m_objid;
    }

    public get FieldCode(): string {
        return this.m_fieldcode;
    }

    public get OldValue(): string {
        return this.m_oldvalue;
    }

    public get NewValue(): string {
        return this.m_newvalue;
    }

    //

    public ToObject(): any {
        return {
            objtype: this.m_objtype,
            objid: this.m_objid,
            fieldcode: this.m_fieldcode,
            oldvalue: this.m_oldvalue,
            newvalue: this.m_newvalue
        };
    }
}

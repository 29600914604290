import { WFDirectory, WFPropType, WFCompany, WFPermissionInfo, WFPrmType, IWFObject } from '../model/index';
import { DocumentList } from '../data/documentlist';
import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';
import { _OnError } from './WFDocumentAdp';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { BUTTONSTYPE } from '../global/global.service';

export type PublishDirectory = (snd: WFDirectoryAdp, status: boolean) => void;
export type RemoveDirectory = (snd: WFDirectoryAdp, status: boolean) => void;

export class WFDirectoryAdp {
    private m_dir: WFDirectory;
    private m_totaldocs: number;
    private m_is_child: boolean;
    private m_docs: DocumentList;
    private m_ismodified: boolean;
    private m_conn: CacheService;

    //

    public OnPublishDirectory: PublishDirectory;
    public OnRemoveDirectory: RemoveDirectory;

    private m_OnError: EventAdp<_OnError>;

    //

    constructor(dir: WFDirectory, conn: CacheService) {
        this.m_dir = dir;
        this.m_conn = conn;
        this.m_totaldocs = 0;
        this.m_is_child = true;
        this.m_docs = new DocumentList();
        this.m_ismodified = (dir.ID === 0);
        this.OnPublishDirectory = null;
        this.OnRemoveDirectory = null;
        this.m_OnError = new EventAdp<_OnError>();
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    //

    public get ID(): number {
        return this.m_dir.ID;
    }

    //

    public set DirName(nval: string) {
        nval = nval.trim();
        if (nval.length > 0) {
            if (nval !== this.m_dir.Name) {
                this.m_dir.Name = nval;
                this.IsModified = true;
            }
        }
    }

    public get DirName(): string {
        return this.m_dir.Name;
    }

    //

    public set isChild(nval: boolean) {
        this.m_is_child = nval;
    }

    public get isChild(): boolean {
        return this.m_is_child;
    }

    //

    public set TotalDocs(nval: number) {
        this.m_totaldocs = nval;
    }

    public get TotalDocs(): number {
        return this.m_totaldocs;
    }

    //

    public set CompanyID(nval: number) {
        if (this.m_dir.CompanyID !== nval) {
            this.m_dir.CompanyID = nval;
            this.IsModified = true;
        }
    }

    public get CompanyID(): number {
        return this.m_dir.CompanyID;
    }

    //

    public set DirectoryID(nval: number) {
        if (this.m_dir.DirectoryID !== nval) {
            this.m_dir.DirectoryID = nval;
            this.IsModified = true;
        }
    }

    public get DirectoryID(): number {
        return this.m_dir.DirectoryID;
    }

    //

    public set IsModified(nval: boolean) {
        this.m_ismodified = nval;
        //LayoutRoot.Background = (m_ismodified) ? m_modifiedbr : null;
    }

    public get IsModified(): boolean {
        return this.m_ismodified;
    }

    //

    public get Documents(): DocumentList {
        return this.m_docs;
    }

    //

    private CanEditDir(ptp: WFPropType): boolean {
        if (this.m_conn.IsSuperUser) return true;

        let allow = false;
        let replusrs: Array<number> = this.m_conn.User.FindReplacements(this.m_conn.Users);

        //zbadaj uprawnienia
        let cmp: WFCompany = this.m_conn.Companies.get(this.m_dir.CompanyID);

        let pinfs: Array<WFPermissionInfo> = cmp.ListPermissions(ptp, this.m_conn.AppSystem);
        for (let pinf of pinfs) {
            if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0)) {
                allow = (pinf.Type === WFPrmType.CHANGE);
                break;
            }
        }

        return allow;
    }

    public get CanCreateDirectory(): boolean {
        return this.CanEditDir(WFPropType.DIR_CANCREATE);
    }

    //

    public get CanEditDirectory(): boolean {
        return this.CanEditDir(WFPropType.DIR_CANCHANGE);
    }

    //

    public get CanMoveDirectory(): boolean {
        return this.CanEditDir(WFPropType.DIR_CANMOVE);
    }

    //

    public Publish(): void {
        if (this.m_ismodified) {
            const self = this;
            this.m_conn.DataService.modifyDirectory(this.m_conn.SessionID, this.m_dir, false, this.m_OnError,
                (dir) => {
                    self.ModifyDirectoryCompleted(dir);
                });
        }
    }

    private ModifyDirectoryCompleted(dir: WFDirectory): void {
        try {
            this.m_dir = dir;
            this.IsModified = false;
            this.OnPublishDirectory(this, true);
            return;
        } catch (ex) {
            this.m_conn.GlobalService.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, IWFObject.Format(this.m_conn.StringService.getStr('errFolderCannotModify'), this.m_dir.Name, ex.message));
        }

        this.OnPublishDirectory(this, false);
    }

    //

    public Remove(): void {
        if (this.m_dir.ID > 0) {
            const self = this;
            this.m_conn.DataService.removeDirectory(this.m_conn.SessionID, this.m_dir, this.m_OnError,
                (dir) => {
                    self.RemoveDirectoryCompleted(dir);
                });
        }
    }

    //

    private RemoveDirectoryCompleted(dir: WFDirectory): void {
        try {

            this.m_dir = dir;
            this.IsModified = false;
            this.OnRemoveDirectory(this, true);
            return;
        } catch (ex) {
            this.m_conn.GlobalService.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, IWFObject.Format(this.m_conn.StringService.getStr('errFolderCannotDelete'), this.m_dir.Name, ex.message));
        }

        this.OnRemoveDirectory(this, false);
    }

}
import { EVENTTYPE } from './WFMessage';
import { IWFObject } from './IWFObject';

export enum PrmType {
    TYPE_ALLOWFROMALL,
    TYPE_DENYFROMALL,
    TYPE_ALLOWFROM
}

export enum FavoriteType {
    TYPE_FAVORITE,
    TYPE_OBSERVED
}

export enum VisibleFieldType {
    NAME,
    DESCRIPTION,
    DOCNUM,
    CREATE_DATE,
    UPDATE_DATE,
    DOC_DATE,
    ENTER_DATE,
    CLASSNAME,
    AUTHSCHEMANAME,
    USERNAME,
    COMPANYNAME
}

export enum UILanguage {
    EN,
    PL
}

export class FavoriteDocument {
    public DocumentID: number;
    public Type: FavoriteType;

    constructor(docid: number, ftp: FavoriteType) {
        this.DocumentID = docid;
        this.Type = ftp;
    }
}

export enum DocumentInterfaceType
{
    KSEF
}

export class DocumentInterfaceConfig {
    private m_name: string;
    private m_type: DocumentInterfaceType;
    private m_company_id: number;
    private m_userid: string;
    private m_password: string;
    private m_synctout: number;

    constructor(obj:any) {
        this.m_name= obj.Name;
        this.m_type= obj.Type;
        this.m_company_id= obj.CompanyID;
        this.m_userid= obj.UserID;
        this.m_password= obj.Password;   
        this.m_synctout= obj.SyncTOut;
    }

    public get Name(): string
    {
        return this.m_name;
    }

    public get Type(): DocumentInterfaceType
    {
        return this.m_type;
    }

    public get CompanyID(): number
    {
        return this.m_company_id;
    }

    public get UserID(): string
    {
        return this.m_userid;
    }

    public get Password(): string
    {
        return this.m_password;
    }

    public get SyncTOut(): number
    {
        return this.m_synctout;
    }

    public ToObject(): any {
        return {
            Name: this.m_name,
            Type: this.m_type,
            CompanyID: this.m_company_id,
            UserID: this.m_userid,
            Password: this.m_password,
            SyncTOut: this.m_synctout  
        };
    }
}


export class WFUser {
    private m_id: number;
    private m_name: string;
    private m_isadmin: boolean;

    //wersja 3
    private m_smtp_address: string;
    private m_smtp_port: number;
    private m_smtp_user: string;
    private m_smtp_password: string;
    private m_email_reply: string;
    private m_email_template: string;
    //wersja 4
    private m_cmp_permtype: PrmType;
    private m_cmp_permissions: Array<number>;
    private m_usr_permtype: PrmType;
    private m_usr_permissions: Array<number>;
    private m_cls_permtype: PrmType;
    private m_cls_permissions: Array<number>;
    private m_commentcolor: string;
    private m_favoritedocuments: Array<FavoriteDocument>;
    //25.06.2012
    private m_authlevel: number;
    private m_restrictevents: Array<EVENTTYPE>;
    //
    private m_iscreator: boolean;
    private m_plainpass: string;
    private m_createdat: Date;
    private m_updatedat: Date;

    private m_issys: boolean;
    private m_username: string;
    private m_recentmax: number;

    private m_markasread: Array<EVENTTYPE>;

    private m_replacement: boolean;
    private m_replacement_start: Date;
    private m_replacement_end: Date;
    private m_replacement_userid: number;
    private m_replacement_userid2: number;
    private m_islocked: boolean;

    private m_field1: VisibleFieldType;
    private m_field2: VisibleFieldType;
    private m_field3: VisibleFieldType;
    private m_field4: VisibleFieldType;

    private m_organization_id: number;
    private m_dailycorrclass_id: number;

    private m_signature: string;
    private m_lang: UILanguage;

    private m_imap_address: string;
    private m_imap_port: number;
    private m_imap_user: string;
    private m_imap_password: string;
    private m_imap_ssl: boolean;

    private m_imap_vflds: string;
    private m_docinterfaceconfigs: Array<DocumentInterfaceConfig>; 
    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_isadmin = obj.isadmin;

        //wersja 3
        this.m_smtp_address = obj.smtp_address;
        this.m_smtp_port = obj.smtp_port;
        this.m_smtp_user = obj.smtp_user;
        this.m_smtp_password = obj.smtp_password;
        this.m_email_reply = obj.email_reply;
        this.m_email_template = obj.email_template;

        //wersja 4
        this.m_cmp_permtype = obj.cmp_permtype;
        this.m_cmp_permissions = obj.cmp_permissions;
        this.m_usr_permtype = obj.usr_permtype;
        this.m_usr_permissions = obj.usr_permissions;
        this.m_cls_permtype = obj.cls_permtype;
        this.m_cls_permissions = obj.cls_permissions;
        this.m_commentcolor = IWFObject.ParseColor(obj.commentcolor);

        this.m_favoritedocuments = new Array<FavoriteDocument>();
        for (let fdc of obj.favoritedocuments) this.m_favoritedocuments.push(new FavoriteDocument(fdc.DocumentID, fdc.Type));

        //25.06.2012
        this.m_authlevel = obj.authlevel;
        this.m_restrictevents = new Array<EVENTTYPE>();
        for (let iv of obj.restrictevents) this.m_restrictevents.push(iv);

        //
        this.m_iscreator = obj.iscreator;
        this.m_plainpass = obj.plainpass;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);

        this.m_issys = obj.issys;
        this.m_username = obj.username;
        this.m_recentmax = obj.recentmax;

        this.m_markasread = new Array<EVENTTYPE>();
        for (let iv of obj.markasread) this.m_markasread.push(iv);

        this.m_replacement = obj.replacement;
        this.m_replacement_start = IWFObject.ParseDate(obj.replacement_start);
        this.m_replacement_end = IWFObject.ParseDate(obj.replacement_end);
        this.m_replacement_userid = obj.replacement_userid;
        this.m_replacement_userid2 = obj.replacement_userid2;
        this.m_islocked = obj.islocked;

        this.m_field1 = obj.field1;
        this.m_field2 = obj.field2;
        this.m_field3 = obj.field3;
        this.m_field4 = obj.field4;

        this.m_organization_id = obj.organization_id;
        this.m_dailycorrclass_id = obj.dailycorrclass_id;

        this.m_signature = (obj.hassig) ? obj.signature : null;
        this.m_lang = <UILanguage>obj.lang;

        this.m_imap_address= obj.imap_address;
        this.m_imap_port= obj.imap_port;
        this.m_imap_user= obj.imap_user;
        this.m_imap_password= obj.imap_password;
        this.m_imap_ssl= obj.imap_ssl;

        this.m_imap_vflds= obj.imap_vflds;

        this.m_docinterfaceconfigs = new Array<DocumentInterfaceConfig>();
        for (let din of obj.docinterfaceconfigs) this.m_docinterfaceconfigs.push(new DocumentInterfaceConfig(din));

    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    public set Name(value: string) {
        let nval = IWFObject.CutString(value, 100);
        let rx = new RegExp('^[0-9a-zA-Z_.-]+$', 'i');
        if (!rx.test(nval)) throw new Error();
        this.m_name = nval;
    }

    public get FriendlyName(): string {
        if(IWFObject.IsNullOrEmpty(this.m_username)) return this.m_name;
        return IWFObject.Format("{0} ({1})", this.m_username, this.m_name);
    }

    //

    public get IsAdmin(): boolean {
        return this.m_isadmin;
    }

    //

    public get SMTP_Address(): string {
        return this.m_smtp_address;
    }

    //

    public get SMTP_Port(): number {
        return this.m_smtp_port;
    }

    //

    public get SMTP_User(): string {
        return this.m_smtp_user;
    }

    //

    public get SMTP_Password(): string {
        //if(m_smtp_password!= null) return DecryptPassword(m_smtp_password, ENC_XORKEY1);
        return '';
    }

    //

    public get EMailReply(): string {
        return this.m_email_reply;
    }

    public set EMailReply(value: string) {
        if (value == null) {
            this.m_email_reply = null;
        } else {
            let nval = IWFObject.CutString(value, 255);
            let rx = new RegExp('^[0-9a-zA-Z._-]+@[0-9a-zA-Z._-]+$', 'i');
            if (!rx.test(nval)) throw new Error();
            this.m_email_reply = nval;
        }
    }

    //

    public get EMailTemplate(): string {
        return this.m_email_template;
    }

    public set EMailTemplate(value: string) {
        this.m_email_template = (value == null) ? null : value.trim();
    }

    //wersja 4

    public get CompanyPermissionType(): PrmType {
        return this.m_cmp_permtype;
    }

    //

    public get CompanyPermissions(): Array<number> {
        return this.m_cmp_permissions;
    }

    //

    public get UserPermissionType(): PrmType {
        return this.m_usr_permtype;
    }

    //

    public get UserPermissions(): Array<number> {
        return this.m_usr_permissions;
    }

    //

    public get DocClassPermissionType(): PrmType {
        return this.m_cls_permtype;
    }

    //

    public get DocClassPermissions(): Array<number> {
        return this.m_cls_permissions;
    }

    //

    public get CommentColor(): string {
        return this.m_commentcolor;
    }

    public set CommentColor(value: string) {
        this.m_commentcolor = value;
    }

    //

    public get FavoriteDocuments(): Array<FavoriteDocument> {
        return this.m_favoritedocuments;
    }

    //

    public get AuthLevel(): number {
        return this.m_authlevel;
    }

    //

    public CanSendEvent(tp: EVENTTYPE): boolean {
        return (this.m_restrictevents.indexOf(tp) < 0);
    }

    public GetEventRestriction(tp: EVENTTYPE): boolean {
        return (this.m_restrictevents.indexOf(tp) >= 0);
    }

    public SetEventRestriction(tp: EVENTTYPE, cs: boolean): void {
        let off = this.m_restrictevents.indexOf(tp);
        if (cs) {
            if (off >= 0) this.m_restrictevents.splice(off, 1);
        } else {
            if (off < 0) this.m_restrictevents.push(tp);
        }
    }

    //

    public MarkAsRead(tp: EVENTTYPE): boolean {
        return (this.m_markasread.indexOf(tp) >= 0);
    }

    public GetMarkAsRead(tp: EVENTTYPE): boolean {
        return (this.m_markasread.indexOf(tp) >= 0);
    }

    public SetMarkAsRead(tp: EVENTTYPE, cs: boolean): void {
        let off = this.m_markasread.indexOf(tp);
        if (cs) {
            if (off < 0) this.m_markasread.push(tp);
        } else {
            if (off >= 0) this.m_markasread.splice(off, 1);
        }
    }

    //

    public get IsCreator(): boolean {
        return this.m_iscreator;
    }

    //

    public get IsSys(): boolean {
        return this.m_issys;
    }

    //

    public get UserName(): string {
        return this.m_username;
    }

    //

    public get RecentMax(): number {
        return this.m_recentmax;
    }

    //

    public get Replacement(): boolean {
        return this.m_replacement;
    }

    public set Replacement(value: boolean) {
        this.m_replacement = value;
    }

    //

    public get ReplacementStart(): Date {
        return this.m_replacement_start;
    }

    public set ReplacementStart(value: Date) {
        this.m_replacement_start = value;
    }

    //

    public get ReplacementEnd(): Date {
        return this.m_replacement_end;
    }

    public set ReplacementEnd(value: Date) {
        this.m_replacement_end = value;
    }

    //

    public get ReplacementUserID(): number {
        return this.m_replacement_userid;
    }

    public set ReplacementUserID(value: number) {
        if (value < 0) throw new Error();
        this.m_replacement_userid = value;
    }

    //

    public get ReplacementUserID2(): number {
        return this.m_replacement_userid2;
    }

    public set ReplacementUserID2(value: number) {
        if (value < 0) throw new Error();
        this.m_replacement_userid2 = value;
    }

    //

    public get IsLocked(): boolean {
        return this.m_islocked;
    }

    //
    //funkja wyszukujaca zastepstwa
    //

    public FindReplacements(usrs: Map<number, WFUser>): Array<number> {
        let ret = new Array<number>();
        let tnow = new Date();

        usrs.forEach((usr, id) => {
            if ((usr.ID !== this.m_id) && (usr.Replacement) && (tnow >= usr.ReplacementStart) && (tnow <= usr.ReplacementEnd) &&
                (usr.ReplacementUserID === this.m_id || usr.ReplacementUserID2 === this.m_id)) {
                ret.push(usr.ID);
            }
        });

        return ret;
    }

    //

    public get Field1Type(): VisibleFieldType {
        return this.m_field1;
    }

    public get Field2Type(): VisibleFieldType {
        return this.m_field2;
    }

    public get Field3Type(): VisibleFieldType {
        return this.m_field3;
    }

    public get Field4Type(): VisibleFieldType {
        return this.m_field4;
    }

    //

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    //

    public get DailyCorrClassID(): number {
        return this.m_dailycorrclass_id;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    //

    public get SignatureB64(): string {
        return this.m_signature;
    }

    //

    public get Language(): UILanguage {
        return this.m_lang;
    }

    //

    public set LanguageStr(nval: string) {
        switch(nval.toUpperCase()) {
            case 'PL': 
                this.m_lang = UILanguage.PL; 
                break;
            default:
                this.m_lang = UILanguage.EN;
                break;
        }
    }

    public get LanguageStr(): string {
        switch(this.m_lang)
        {
            case UILanguage.PL: return 'PL';
        }
        return 'EN';
    }


    public get IMAP_Address(): string
    {
            return this.m_imap_address;
    }

    //

    public get IMAP_Port(): number
    {
            return this.m_imap_port;
    }

    //

    public get IMAP_User(): string
    {
            return this.m_imap_user;
    }

    //

    public get IMAP_Password(): string
    {
        return this.m_imap_password;
    }

    //

    public get IMAP_SSL(): boolean
    {
            return this.m_imap_ssl;
    }

    //

    public get IMAP_VisibleFolders(): string
    {
        return this.m_imap_vflds;
    }

    //

    public get DocumentInterfaceConfigs(): Array<DocumentInterfaceConfig>
    {
        return this.m_docinterfaceconfigs;
    }

    //

    public ToObject(): any {
        let docinterfaceconfigs= new Array<any>();
        for(let din of this.m_docinterfaceconfigs) docinterfaceconfigs.push(din.ToObject());

        let obj: any = {
            id: this.m_id,
            name: this.m_name,
            isadmin: this.m_isadmin,
            smtp_address: this.m_smtp_address,
            smtp_port: this.m_smtp_port,
            smtp_user: this.m_smtp_user,
            smtp_password: this.m_smtp_password,
            email_reply: this.m_email_reply,
            email_template: this.m_email_template,
            cmp_permtype: this.m_cmp_permtype,
            cmp_permissions: this.m_cmp_permissions,
            usr_permtype: this.m_usr_permtype,
            usr_permissions: this.m_usr_permissions,
            cls_permtype: this.m_cls_permtype,
            cls_permissions: this.m_cls_permissions,
            commentcolor: IWFObject.StringToColor(this.m_commentcolor),
            favoritedocuments: this.m_favoritedocuments,
            authlevel: this.m_authlevel,
            restrictevents: this.m_restrictevents,
            iscreator: this.m_iscreator,
            plainpass: this.m_plainpass,
            createdat: IWFObject.DateToString(this.m_createdat),
            updatedat: IWFObject.DateToString(this.m_updatedat),
            issys: this.m_issys,
            username: this.m_username,
            recentmax: this.m_recentmax,
            markasread: this.m_markasread,
            replacement: this.m_replacement,
            replacement_start: IWFObject.DateToString(this.m_replacement_start),
            replacement_end: IWFObject.DateToString(this.m_replacement_end),
            replacement_userid: this.m_replacement_userid,
            replacement_userid2: this.m_replacement_userid2,
            islocked: this.m_islocked,
            field1: this.m_field1,
            field2: this.m_field2,
            field3: this.m_field3,
            field4: this.m_field4,
            organization_id: this.m_organization_id,
            dailycorrclass_id: this.m_dailycorrclass_id,
            lang: this.m_lang,
            imap_address: this.m_imap_address,
            imap_port: this.m_imap_port,
            imap_user: this.m_imap_user,
            imap_password: this.m_imap_password,
            imap_ssl: this.m_imap_ssl,
            imap_vflds: this.m_imap_vflds,
            docinterfaceconfigs: docinterfaceconfigs
        };

        if (this.m_signature == null) {
            obj.hassig = false;
        } else {
            obj.hassig = true;
            obj.signature = this.m_signature;
        }

        return obj;
    }
}

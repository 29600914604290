import { Directive, Renderer2, ViewContainerRef } from '@angular/core';
import { ChildWindowContainerService } from './child-window-container.service';

@Directive({
    selector: '[appChildWindowContainer]'
})

export class ChildWindowContainerDirective {
    constructor(container: ViewContainerRef, renderer: Renderer2, childwndsrv: ChildWindowContainerService) {
        childwndsrv.bindToContainer(container, renderer);
    }
}
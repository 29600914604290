import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-button',
  templateUrl: './image-button.component.html',
  styleUrls: ['./image-button.component.scss']
})

export class ImageButtonComponent {
  @Input() maskfile: string;

  public m_neweventsvis: boolean;
  public m_newevents: number;
  public m_iconwidth: number;
  public m_iconheight: number;
  public m_tooltip: string;

  @Input()
  public set NewEventsVis(nval: boolean) {
    this.m_neweventsvis = nval;
  }

  public get NewEventsVis(): boolean {
    return this.m_neweventsvis;
  }

  @Input()
  public set NewEvents(nval: number) {
    this.m_newevents = nval;
  }

  public get NewEvents(): number {
    return this.m_newevents;
  }

  @Input()
  public set IconWidth(nval: number) {
    this.m_iconwidth = (nval >= 0) ? nval : 0;
  }


  public get IconWidth(): number {
    return this.m_iconwidth;
  }

  @Input()
  public set IconHeight(nval: number) {
    this.m_iconheight = (nval >= 0) ? nval : 0;
  }

  public get IconHeight(): number {
    return this.m_iconheight;
  }

  @Input()
  public set ToolTip(tip: string) {
    this.m_tooltip = tip;
  }

  public get ToolTip(): string {
    return this.m_tooltip;
  }


  //

  constructor() {
    this.maskfile = '';
    this.m_neweventsvis = false;
    this.m_newevents = 0;
    this.m_iconwidth = 0;
    this.m_iconheight = 0;
    this.m_tooltip = '';
  }
}

import { Component, EventEmitter, AfterViewInit, Output, ViewChild, HostListener, ElementRef } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, InvoiceHeader } from '../data/data.service';
import { BUTTONSTYPE, GlobalService, WNDRESULT } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { GridComponent, SelectionEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, DataResult, GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { EventAdp } from '../data/eventadp';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { DatePipe } from '@angular/common';
import { ComboIValueDesc, TabOperation } from '../app.component';
import { IWFObject, WFDocStatus, WFDocument } from '../model';
//import { AddNewDocFromMailComponent } from '../add-new-doc-from-mail/add-new-doc-from-mail.component';
import { DocumentList } from '../data/documentlist';
import { CreateDocumentFromEDoc, ListEDocs, PreviewEDoc, WFDocumentInterfaceAdp } from '../adapters/WFDocumentInterfacAdp';

interface EDocumentSpec {
  uniqueid: string;
  edoc: InvoiceHeader;
  SubjectBy: string;
  SubjectTo: string;
  doclist: DocumentList;
}

@Component({
  selector: 'app-edoc-viewer',
  templateUrl: './edoc-viewer.component.html',
  styleUrls: ['./edoc-viewer.component.scss']
})

export class EDocViewerComponent implements AfterViewInit {
  public m_busy_text: string;

  private m_docinterface: WFDocumentInterfaceAdp;
  
  private m_onlistedoc: ListEDocs;
  private m_onpreviewedoc: PreviewEDoc;
  private m_oncreatenewdoc: CreateDocumentFromEDoc;

  public m_groups: Array<GroupDescriptor>;
  public m_sort: Array<SortDescriptor>;
  public m_filter: CompositeFilterDescriptor;

  public m_rawdata: Array<EDocumentSpec>;
  public m_griddta: DataResult;

  public m_grheight: number;

  @Output() afterInit: EventEmitter<EDocViewerComponent>;
  @ViewChild('m_dataview', {static: false}) private m_dataview: GridComponent;
  
  private m_onerrorevh: EventAdp<_OnError>;
  public m_pagesc_info: string;
  public m_pages: Array<ComboIValueDesc>;
  public m_actual_page: ComboIValueDesc;

  public m_pagekeywords: string;
  public m_pagekeywords_err: boolean;

  private m_previewuniqueid: string;
  public m_previewdoc_html: string;

  public m_selected: Array<number>;
  public m_only_unbind: boolean;

  @Output() selectEDoc: EventEmitter<EDocViewerComponent>;

  //

  public set EDocInterface(value: WFDocumentInterfaceAdp) {
    this.SetEDocInterface(value);
  }

  public get EDocInterface(): WFDocumentInterfaceAdp {
    return this.m_docinterface;
  }

  public get Only_UnBind(): boolean {
    return this.m_only_unbind;
  }

  public set Only_UnBind(nval: boolean) {
    this.m_only_unbind= nval;
  }

  constructor(protected m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_busy_text = '';
    this.m_docinterface = null;

    const self = this;
    this.m_onlistedoc = (snd, page, dta, count) => { self.OnFetchEDocs(snd, page, dta, count); };
    this.m_onpreviewedoc= (snd, uniqueid, htmlctx) => { self.OnPreviewEDoc(snd, uniqueid, htmlctx); };
    this.m_oncreatenewdoc= (snd, uniqueid, doc) => { self.OnCreateNewDocument(snd, uniqueid, doc); };

    this.afterInit = new EventEmitter<EDocViewerComponent>();

    this.m_groups = new Array<GroupDescriptor>();
    this.m_sort = new Array<SortDescriptor>();
    this.m_filter = null;

    this.m_rawdata = null;
    this.m_griddta = null;

    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { 
      self.m_busy_text = ''; 
    });

    this.m_grheight = 0;
    this.m_pagesc_info= '';
    this.m_pages= new Array<ComboIValueDesc>();
    this.m_actual_page= null;

    this.m_pagekeywords= '';
    this.m_pagekeywords_err= false;

    this.m_previewuniqueid= '';
    this.m_previewdoc_html= '';

    this.m_selected= new Array<number>();

    this.m_only_unbind= true;

    this.selectEDoc = new EventEmitter<EDocViewerComponent>();
  }

  ngAfterViewInit() {
    this.afterInit.emit(this);
    this.onResize(null);
  }

  private BindToGrid(): void {
    this.m_griddta = process(this.m_rawdata, {
        group: this.m_groups,
        sort: this.m_sort,
        filter: this.m_filter
    });
  }

  public groupChange(groups: Array<GroupDescriptor>): void {
      this.m_groups = groups;
      this.BindToGrid();
  }

  public sortChange(sort: Array<SortDescriptor>): void {
      this.m_sort = sort;
      this.BindToGrid();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
      this.m_filter = filter;
      this.BindToGrid();
  }
 
  public selectionChange(evh: SelectionEvent): void {
    try {
      if(evh.selectedRows.length > 0) {
        let rw= evh.selectedRows[0];
        let doc= <EDocumentSpec>rw.dataItem;
        this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');        
        this.m_docinterface.PreviewEDocument(this.m_cache_srv, doc.uniqueid);
      }
      return;     
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_text = '';
  }

  public SetEDocInterface(value: WFDocumentInterfaceAdp): void {
    if (this.m_docinterface !== value) {
        if (this.m_docinterface != null) {
          this.m_docinterface.OnError.unsubscribe2(this.m_onerrorevh);
          this.m_docinterface.OnListEDocs = null;
          this.m_docinterface.OnPreviewEDoc= null;
          this.m_docinterface.OnCreateDocumentFromEDoc= null;
        }

        this.m_docinterface = value;
        this.m_rawdata = null;
        this.m_griddta = null;
        this.m_groups.splice(0, this.m_groups.length);
        this.m_sort.splice(0, this.m_sort.length);
        this.m_filter = null;
        this.m_only_unbind= true;

        if (this.m_docinterface != null) {
            this.m_docinterface.OnListEDocs = this.m_onlistedoc;
            this.m_docinterface.OnPreviewEDoc= this.m_onpreviewedoc;
            this.m_docinterface.OnCreateDocumentFromEDoc= this.m_oncreatenewdoc;
            this.m_docinterface.OnError.subscribe2(this.m_onerrorevh);
            this.RefreshData();
        }
    } else {
        this.RefreshData();
    }

    this.onResize(null);
  }

  private ClearPreview(): void {
    this.m_previewuniqueid= '';
    this.m_selected.splice(0, this.m_selected.length);
    this.selectEDoc.emit(this);
  }

  private listEDocs(page:number, kwords: string): void { 
    /*if(this.m_mailfolder.PasswordRequired) {      
      const self = this;

      let loginwnd = this.m_wnd_srv.showControl(ImapLoginComponent);
      loginwnd.instance.SetPrms(this.m_mailfolder.Login);
      loginwnd.instance.onClosed.subscribe((ret) => {
          if(ret) {
            try {
              self.m_busy_text = self.m_strdata_srv.getStr('strUserDataUpdate');
              self.m_mailfolder.FetchMails(self.m_cache_srv, page, kwords, 
                                        loginwnd.instance.Login,
                                        loginwnd.instance.Pass, 
                                        loginwnd.instance.Remember,
                                        self.m_only_unread,
                                        self.m_only_unbind);
            } catch(ex) {
              self.m_global_srv.manageException(ex);
            }
          }
          loginwnd.destroy();
          loginwnd = null;
      });

      return;
    }*/

    this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
    this.m_docinterface.ListEDocuments(this.m_cache_srv, page, kwords, this.m_only_unbind);
  }

  public RefreshData(): void {    
    this.m_pagekeywords= '';
    this.m_pagekeywords_err= false;

    this.ClearPreview();
    this.listEDocs(0, this.m_pagekeywords);  
  }

  private OnFetchEDocs(snd: WFDocumentInterfaceAdp, page: number, dta: Array<[InvoiceHeader,Array<number>]>, count: number): void {
  
    try {
        //console.log(dta);
        
        if (dta == null || dta.length === 0) {
            this.m_griddta = null;            
        } else {            
            //let dtap = new DatePipe('en-US');         

            this.m_rawdata = new Array<EDocumentSpec>();
            for(let edcv of dta) {
                let edc= edcv[0];

                let dlst= new DocumentList();
                if(edcv[1]!= null) dlst.Add2(edcv[1]);
                
                this.m_rawdata.push({
                    uniqueid: edc.KsefReferenceNumber,
                    SubjectBy: (!IWFObject.IsNullOrEmpty(edc.SubjectBy_Name)) ? IWFObject.Format("{0} ({1})", edc.SubjectBy_NIP, edc.SubjectBy_Name) : edc.SubjectBy_NIP,
                    SubjectTo: (!IWFObject.IsNullOrEmpty(edc.SubjectTo_Name)) ? IWFObject.Format("{0} ({1})", edc.SubjectTo_NIP, edc.SubjectTo_Name) : edc.SubjectTo_NIP,
                    //reciveDate: dtap.transform(ml.date, 'dd-MM-yyyy hh:mm:ss'),
                    edoc: edc,
                    doclist: dlst
                });
            }

            this.BindToGrid();
        }

        if(page== 0) {
            this.m_pages.splice(0, this.m_pages.length);

            if(count > 0) {
                let perpage= 100;

                let pagesc= (count / perpage) | 0;
                if((count % perpage) > 0) pagesc++;
        
                for(let ii= 0; ii< pagesc; ii++) {    
                    this.m_pages.push({
                        val: ii,
                        desc: (ii + 1).toString()
                    });
                }
        
                this.m_actual_page= this.m_pages[0];
                this.m_pagesc_info= IWFObject.Format('/{0}', pagesc);
            } else {    
                this.m_actual_page= null;
                this.m_pagesc_info= '';
            }
        }
    } catch (ex) {
        this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }


  private GroupsOP(pth: string, arr: Array<any>, exp: boolean): void {
      for (let ii = 0; ii < arr.length; ii++) {
          let pth2 = pth + ii.toString();
          
          if (exp)
              this.m_dataview.expandGroup(pth2);
          else
              this.m_dataview.collapseGroup(pth2);

          let sobj = arr[ii];
          if('items' in sobj) this.GroupsOP(pth2 + '_', sobj.items, exp);            
      }
  }

  public ExpandGroups(): void {
      try {
          if (this.m_groups.length > 0) {
              this.GroupsOP('', this.m_griddta.data, true);
          }
      } catch (ex) {
          this.m_global_srv.manageException(ex);
      }
  }

  public CollapseGroups(): void {
      try {
          if (this.m_groups.length > 0) {
              this.GroupsOP('', this.m_griddta.data, false);
          }
      } catch (ex) {
          this.m_global_srv.manageException(ex);
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.m_grheight = window.innerHeight - (44 + 35);
  }

  public ClickPrevPage(): void {
    try {
      if(this.m_actual_page!= null && this.m_actual_page.val > 0) {
        this.ClearPreview();

        let pii= this.m_actual_page.val - 1;
        this.listEDocs(pii, this.m_pagekeywords);
        this.m_actual_page= this.m_pages[pii];
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }   
  }

  public ClickNextPage(): void {
    try {
        if(this.m_actual_page!= null && (this.m_actual_page.val + 1) < this.m_pages.length) {
          this.ClearPreview();

          let pii= this.m_actual_page.val + 1;
          this.listEDocs(pii, this.m_pagekeywords);
          this.m_actual_page= this.m_pages[pii];
        }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public PageChanged(nval: ComboIValueDesc): void {
    try {   
      this.ClearPreview();   
      this.listEDocs(nval.val, this.m_pagekeywords);
      this.m_actual_page = nval;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickFindOnPage(): void {
    try {  
        this.ClearPreview();    
        let kv= this.m_pagekeywords.trim();
        this.listEDocs(0, kv);
    } catch (ex) {
        this.m_global_srv.manageException(ex);
    }
  }

  private OnPreviewEDoc(snd: WFDocumentInterfaceAdp, uniqueid: string, htmlctx: string): void {  
    try {
      this.m_busy_text = '';
      this.m_previewuniqueid= uniqueid;
      this.m_previewdoc_html= htmlctx;

      let spc= this.m_rawdata.find((t)=> t.uniqueid== uniqueid);
      this.m_global_srv.App.ShowDocumentList(spc.doclist, this.m_docinterface);      
      this.selectEDoc.emit(this);
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public AddDocument(): void {  
    try {
      if(this.m_previewuniqueid!= '') {
        let spc= this.m_rawdata.find((t)=> t.uniqueid== this.m_previewuniqueid);
        const _self= this;
        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr((spc.doclist.Count > 0) ? 'strEDocAlreadyBind' : 'strEDocNewDoc'), (res: WNDRESULT)=> {
          if(res== WNDRESULT.OKYES)
            _self.AddDocument2();
        });      
      }

      /*const wnd = this.m_wnd_srv.showControl(AddNewDocFromMailComponent);
      const inst: AddNewDocFromMailComponent = wnd.instance;
      inst.SetPrms(this.m_previewmsg, this.m_mailfolder.Name, this.m_previewuniqueid, this.m_mailfolder);

      let spc= this.m_griddta.data.find((t)=> t.uniqueid == this.m_previewuniqueid);

      inst.onClosed.subscribe((ret) => {
        wnd.destroy();
        if(ret!= null) {             
          for(let doc of ret) {
            spc.mail.documents_id.push(doc.ID);
            spc.doclist.Add(doc.ID);
          }

          self.ShowDocArr(ret, 100, spc);
          //for(let dc of ret)
          //  self.ShowNewDocument(dc);
        }
      });*/

    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public AddDocument2(): void { 
    try {
      this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');        
      this.m_docinterface.CreateDocumentFromEDoc(this.m_cache_srv, this.m_previewuniqueid);
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ShowNewDocument(doc: WFDocument): void {
    try {
      let adp = new WFDocumentAdp(this.m_cache_srv, doc, true, false);
      this.m_cache_srv.AddDocument(adp);

      switch(adp.DocStatus) {
        case WFDocStatus.BEFOREOCR:
          this.m_global_srv.App.BeforeOcr.Add(adp.ID);
          break;
        case WFDocStatus.INOCR:
          this.m_global_srv.App.InOcr.Add(adp.ID);
          break;
        case WFDocStatus.AFTEROCR:
          this.m_global_srv.App.AfterOcr.Add(adp.ID);
          break;
      }

      this.m_global_srv.App.SelectDocument(adp, TabOperation.NEW);      
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnCreateNewDocument(snd: WFDocumentInterfaceAdp, uniqueid: string, doc: WFDocument): void { 
    try {
      this.m_busy_text = '';
      this.ShowNewDocument(doc);
      let fdpc= this.m_rawdata.find((t)=> t.uniqueid== uniqueid);
      if(fdpc!= null) {
        fdpc.doclist.Add(doc.ID);
        this.m_global_srv.App.ShowDocumentList(fdpc.doclist, this.m_docinterface);      
        this.selectEDoc.emit(this);
      }
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

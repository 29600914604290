import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { IWFObject } from '../model/index';
import { StringDataService } from '../stringdata/stringdata.service';

export interface ListItem {
  checked: boolean;
  val: string;
  desc: string;
  showdta: boolean;
  date: Date;
}

export enum MultiselectpanelType {
  VER3,
  VER2
}

@Component({
  selector: 'app-multiselectpanel',
  templateUrl: './multiselectpanel.component.html',
  styleUrls: ['./multiselectpanel.component.scss']
})

export class MultiselectpanelComponent {
  private static m_lastpanel: MultiselectpanelComponent = null;

  @Input() icon: string;
  @Input() caption: string;

  public m_type: MultiselectpanelType;
  public m_grppanel: boolean;
  public m_addvaluename: string;
  public m_option: string;
  public m_items: Array<ListItem>;
  public m_selall: boolean;
  public m_dateformat: string;

  @ViewChild('m_list', {static: false}) private m_list: ComboboxadpComponent;

  @Input()
  public set type(nval: MultiselectpanelType) {
    this.m_type = (typeof nval === 'string') ? IWFObject.ParseInt(<string>nval) : nval;
  }

  public get type(): MultiselectpanelType {
    return this.m_type;
  }

  //

  constructor(public m_strdata_srv: StringDataService  ) {
    this.icon = '';
    this.caption = '';
    this.m_type = MultiselectpanelType.VER3;
    this.m_grppanel = false;
    this.m_addvaluename = '';
    this.m_option = '0';
    this.m_items = new Array<ListItem>();
    this.m_selall = false;
    this.m_dateformat = 'dd-MM-yyyy';

  }

  public SelectPanel(): void {
    if (!this.m_grppanel) {
      if (MultiselectpanelComponent.m_lastpanel !== this) {
        if (MultiselectpanelComponent.m_lastpanel != null) MultiselectpanelComponent.m_lastpanel.m_grppanel = false;
        MultiselectpanelComponent.m_lastpanel = this;
      }
      this.m_grppanel = true;
    }
  }

  public GetSelectedOptionId(): number {
    return Number.parseInt(this.m_option);
  }

  public AddToList(): void {
    if (!IWFObject.IsNullOrEmpty(this.m_list.SelectedValue)) {
      for (let tst of this.m_items) {
        if (tst.val === this.m_list.SelectedValue) return;
      }
      this.m_items.push({
        checked: false,
        val: this.m_list.SelectedValue,
        desc: this.m_list.SelectedDescrition,
        showdta: false, date: null
      });
    }
  }

  public RemoveFromList(): void {
    let ii = 0;
    while (ii < this.m_items.length) {
      if (this.m_items[ii].checked) {
        this.m_items.splice(ii, 1);
        continue;
      }
      ii++;
    }
  }

  public CheckAll(): void {
    for (let tst of this.m_items) tst.checked = true;
  }

  public UnCheckAll(): void {
    for (let tst of this.m_items) tst.checked = false;
  }

  //

  public set AddValueName(nval: string) {
    this.m_addvaluename = nval;
  }

  public get AddValueName(): string {
    return this.m_addvaluename;
  }

  public ClearItems(): void {
    this.m_items.splice(0, this.m_items.length);
    this.m_option = '0';
    this.m_selall = false;
  }

  public AddItem(val: string, ischg: boolean, dta: Date): void {
    this.m_items.push({
      checked: ischg,
      val: this.m_items.length.toString(),
      desc: val,
      showdta: (dta != null),
      date: dta
    });
  }

  public ClearItemsList(): void {
    this.m_list.ClearValidValues();
  }

  public AddItemToList(value: string, desc: string): void {
    this.m_list.AddValidValue(value, desc);
  }

  public GetItemsCount(): number {
    return this.m_items.length;
  }

  public GetItem(id: number): ListItem {
    return this.m_items[id];
  }

  public SelectAllChange(): void {
    for (let tst of this.m_items) tst.checked = this.m_selall;
  }

  public SetItemSelectState(id: number, ischg: boolean): void {
    this.m_items[id].checked = ischg;
  }

  public SetAllItemSelectState(ischg: boolean): void {
    this.m_selall= ischg;
    this.SelectAllChange();
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, ViewChild, ElementRef } from '@angular/core';
import { LongTextAdpPopupComponent } from '../long-text-adp-popup/long-text-adp-popup.component';
import { GlobalService } from '../global/global.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';

@Component({
  selector: 'app-long-text-adp',
  templateUrl: './long-text-adp.component.html',
  styleUrls: ['./long-text-adp.component.scss']
})

export class LongTextAdpComponent {
  public m_value: string;
  private m_enabled: boolean;
  public m_shortdesc: string;
  private m_wnd: ComponentRef<LongTextAdpPopupComponent>;
  private m_document: IWFCompanyObjAdp;

  //
  @Output() SelectedValueChange: EventEmitter<string>;

  public set SelectedValue(nval: string) {
    if (nval == null) nval = '';
    this.SetValue(nval, false);
  }

  @Input() public get SelectedValue(): string {
    return this.m_value;
  }

  //

  @Input()
  public set IsEnabled(nval: boolean) {
    this.m_enabled = nval;
  }

  public get IsEnabled(): boolean {
    return this.m_enabled;
  }

  //

  public set document(nval: IWFCompanyObjAdp) {
    this.m_document = nval;
  }

  @Input() public get document(): IWFCompanyObjAdp {
    return this.m_document;
  }

  //

  @ViewChild('m_eleclk', {static: false}) private m_eleclk: ElementRef;
  //

  constructor(private m_global_srv: GlobalService,
    private m_wnd_srv: ChildWindowContainerService) {
    this.m_shortdesc = '';
    this.m_value = '';
    this.m_enabled = true;
    this.SelectedValueChange = new EventEmitter<string>();
    this.m_wnd = null;
    this.m_document = null;
  }

  private closeWnd(): void {
    if (this.m_wnd != null) {
      this.m_wnd.destroy();
      this.m_wnd = null;
    }
  }

  private SetValue(nval: string, emit: boolean): void {
    if (this.m_value != nval) {
      this.m_value = nval;
      this.m_shortdesc = nval;
      if(emit) this.SelectedValueChange.emit(nval);
    }
  }

  public OpenPopup(trg: any): void {
    try {
      this.closeWnd();
      
      const self = this;
      this.m_wnd = this.m_wnd_srv.showControl(LongTextAdpPopupComponent);
      this.m_wnd.instance.SetPrms(trg, this.m_value, !this.m_enabled, this.m_document);

      this.m_wnd.instance.closePopup.subscribe(() => {
        self.closeWnd();
      });

      this.m_wnd.instance.selectValue.subscribe((nval) => {
        self.closeWnd();
        self.SetValue(nval, true);
      });

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public Focus(): void {
    try {
      if(this.m_enabled) {
        this.OpenPopup(this.m_eleclk.nativeElement);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

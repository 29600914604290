//export type _MouseRightButtonDown = (snd: TreeViewItem) => void;
export class TreeViewItem {
    private m_header: string;
    private m_items: Array<TreeViewItem>;
    private m_icon: string;
    private m_expanded: boolean;
    private m_userobj: Object;
    private m_hint: string;
    private m_highlightcolor: string;
    private m_checkbox: boolean;
    private m_checkstate: boolean;

    //

    constructor() {
        this.m_header = '';
        this.m_items = new Array<TreeViewItem>();
        this.m_icon = '';
        this.m_expanded = false;
        this.m_userobj = null;
        this.m_hint = null;
        this.m_highlightcolor = '';
        this.m_checkbox = false;
        this.m_checkstate = false;
    }

    public set Header(nval: string) {
        this.m_header = nval;
    }
    public get Header(): string {
        return this.m_header;
    }

    public get Items(): Array<TreeViewItem> {
        return this.m_items;
    }

    public set Icon(nval: string) {
        this.m_icon = nval;
    }

    public get Icon(): string {
        return this.m_icon;
    }

    public set Expanded(nval: boolean) {
        this.m_expanded = nval;
    }

    public get Expanded(): boolean {
        return this.m_expanded;
    }

    public set UserObj(nobj: Object) {
        this.m_userobj = nobj;
    }

    public get UserObj(): Object {
        return this.m_userobj;
    }

    public get TotalDocs(): number {
        if (this.m_userobj == null) return 0;
        return ('TotalDocs' in this.m_userobj) ? this.m_userobj['TotalDocs'] : 0;
    }

    public set Hint(nval: string) {
        this.m_hint = nval;
    }

    public get Hint(): string {
        return this.m_hint;
    }
    public set HighlightColor(nval: string) {
        this.m_highlightcolor = nval;
    }

    public get HighlightColor(): string {
        return this.m_highlightcolor;
    }

    public set CheckBox(nval: boolean) {
        this.m_checkbox = nval;
    }

    public get CheckBox(): boolean {
        return this.m_checkbox;
    }

    public set CheckState(nval: boolean) {
        this.m_checkstate = nval;
    }

    public get CheckState(): boolean {
        return this.m_checkstate;
    }
}

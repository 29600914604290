export class WFMailMonitorCfg {
    private m_userid: number;
    private m_companyid: number;
    private m_inputpth: string;
    private m_monitoredpth: string;
    private m_archpath: string;
    private m_mailhost: string;
    private m_mailuser: string;
    private m_mailpass: string;
    private m_mailport: number;
    private m_usessl: boolean;
    private m_matchrx: string;

    //

    constructor(obj:any) {
        this.m_userid = obj.userid;
        this.m_companyid = obj.companyid;
        this.m_inputpth = obj.inputpth;
        this.m_monitoredpth = obj.monitoredpth;
        this.m_archpath = obj.archpath;
        this.m_mailhost = obj.mailhost;
        this.m_mailuser = obj.mailuser;
        this.m_mailpass = obj.mailpass;
        this.m_mailport = obj.mailport;
        this.m_usessl = obj.usessl;
        this.m_matchrx = obj.matchrx;
    }
    
    //

    public get UserID(): number {
        return this.m_userid;
    }

    public get CompanyID(): number {
        return this.m_companyid;
    }

    public get MonitoredPath(): string {
        return this.m_monitoredpth;
    }

    public get ArchPath(): string {
        return this.m_archpath;
    }

    public get MailHost(): string {
        return this.m_mailhost;
    }

    public get MailUser(): string {
        return this.m_mailuser;
    }

    public get MailPassword(): string {
        return this.m_mailpass;
    }

    public get MailPort(): number {
        return this.m_mailport;
    }

    public get UseSSL(): boolean {
        return this.m_usessl;
    }

    public get MatchRegex(): string {
        return this.m_matchrx;
    }
}
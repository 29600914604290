export enum WFInstructionType {
    LINEOP,
    SCRIPTOP,
    DIALOGWND,
    EXCALLOP
}

export abstract class IWFCustomInstruction {
    private m_int_id: number;
    private m_name: string;
    private m_description: string;
    private m_type: WFInstructionType;

    //

    constructor(intid: number, nm: string, desc: string, tp: WFInstructionType) {
        this.m_int_id = intid;
        this.m_name = nm;
        this.m_description = desc;
        this.m_type = tp;
    }

    //

    public get InternalID(): number {
        return this.m_int_id;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get Type(): WFInstructionType {
        return this.m_type;
    }
}

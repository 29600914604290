import { WFOcrTextRegion } from './WFOcrTextRegion';

export enum WFOcrPageType {
    Unknown,
    Delimeter,
    MeetsDocumentDefinition,
    Attachment
}

export class WFOcrResultPage {
    private m_id: number;
    private m_type: WFOcrPageType;
    private m_regions: Array<WFOcrTextRegion>;
    private m_imgwidth: number;
    private m_imgheight: number;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_type = obj.type;
        this.m_regions = new Array<WFOcrTextRegion>();
        for (let rg of obj.regions) this.m_regions.push(new WFOcrTextRegion(rg));
        this.m_imgwidth = obj.imgwidth;
        this.m_imgheight = obj.imgheight;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Type(): WFOcrPageType {
        return this.m_type;
    }

    //

    public get ImgWidth(): number {
        return this.m_imgwidth;
    }

    //

    public get ImgHeight(): number {
        return this.m_imgheight;
    }

    //

    public get Regions(): Array<WFOcrTextRegion> {
        return this.m_regions;
    }

    //

    public ToObject(): any {
        let regions = new Array<any>();
        for (let rg of this.m_regions) regions.push(rg.ToObject());

        return {
            id: this.m_id,
            type: this.m_type,
            regions: regions,
            imgwidth: this.m_imgwidth,
            imgheight: this.m_imgheight
        };
    }
}

import { WFSchemaRecipient } from './WFSchemaRecipient';
import { WFAuthPermission } from './WFAuthPermission';
import { WFProcessVariable } from './WFProcessVariable';
import { WFAuthInstruction, WFAuthInsType } from './WFAuthInstruction';
import { WFProcessAction } from './WFProcessAction';
import { WFAuthStatus } from './WFDocument';
import { IWFObject } from './IWFObject';

export enum WFAuthMethodType {
    AUTH_ALLOF,
    AUTH_ONEOF,
    AUTH_NOFF,
    AUTH_CUSTOM
}

export enum WFAuthEditMode {
    MODE_DENY,
    MODE_ALLOW,
    MODE_ASK
}

export enum WFAuthMode {
    MODE_IN_HEADER,
    MODE_IN_LINES
}

export enum WFCustomAuthValidationErrCode {
    ERR_OK,
    ERR_NOREF,
    ERR_INVALIDCAST,
    ERR_EMPTY,
    ERR_UNDEFINEDVAR,
    ERR_UNINITIALIZEDVAR,
    ERR_UNDEFINEDUSER,
    ERR_UNINITIALIZEDUSER,
    ERR_UNDEFINEDGROUP,
    ERR_UNINITIALIZEDGROUP,
    ERR_UNDEFINEDAUTHSCHEMA,
    ERR_UNINITIALIZEDAUTHSCHEMA,
    ERR_WRONGTYPE
}

export class WFAuthSchema {
    public static readonly OBJID_OWNER = 0;

    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_type: WFAuthMethodType;
    private m_userlimit: number;
    private m_recipients: Array<WFSchemaRecipient>;
    private m_createdat: Date;
    private m_updatedat: Date;

    private m_defeditmode: Array<WFAuthEditMode>;

    private m_hdreditmodex: Array<WFAuthPermission>;
    private m_lnseditmodex: Array<WFAuthPermission>;

    private m_addinfo: string;

    //ver 4.3

    private m_variables: Array<WFProcessVariable>;
    private m_instructions: Array<WFAuthInstruction>;
    private m_actions: Array<WFProcessAction>;

    private m_organization_id: number;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_type = obj.type;
        this.m_userlimit = obj.userlimit;
        this.m_recipients = new Array<WFSchemaRecipient>();
        for (let sr of obj.recipients) this.m_recipients.push(new WFSchemaRecipient(sr));

        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);

        this.m_defeditmode = new Array<WFAuthEditMode>();
        this.m_defeditmode.push(obj.defeditmode0);
        this.m_defeditmode.push(obj.defeditmode1);
        this.m_defeditmode.push(obj.defeditmode2);
        this.m_defeditmode.push(obj.defeditmode3);
        this.m_defeditmode.push(obj.defeditmode4);
        this.m_defeditmode.push(obj.defeditmode5);

        this.m_hdreditmodex = new Array<WFAuthPermission>();
        for (let prm of obj.hdreditmodex) this.m_hdreditmodex.push(new WFAuthPermission(prm));

        this.m_lnseditmodex = new Array<WFAuthPermission>();
        for (let prm of obj.lnseditmodex) this.m_lnseditmodex.push(new WFAuthPermission(prm));

        this.m_addinfo = obj.addinfo;

        //ver 4.3  
        this.m_variables = new Array<WFProcessVariable>();
        for (let vr of obj.variables) this.m_variables.push(new WFProcessVariable(vr));

        this.m_instructions = new Array<WFAuthInstruction>();
        for (let ins of obj.instructions) this.m_instructions.push(new WFAuthInstruction(ins))

        this.m_actions = new Array<WFProcessAction>();
        for (let act of obj.actions) this.m_actions.push(new WFProcessAction(act));

        this.m_organization_id = obj.organization_id;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get Type(): WFAuthMethodType {
        return this.m_type;
    }

    //

    public get AuthUserLimit(): number {
        return this.m_userlimit;
    }

    //

    public get Recipients(): Array<WFSchemaRecipient> {
        return this.m_recipients;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    //

    public GetDefaultEditMode(ast: WFAuthStatus, tp: WFAuthMode): WFAuthEditMode {
        return this.m_defeditmode[ast * 2 + tp];
    }

    public SetDefaultEditMode(ast: WFAuthStatus, tp: WFAuthMode, md: WFAuthEditMode): void {
        this.m_defeditmode[ast * 2 + tp] = md;
    }

    //

    public get HeaderExceptions(): Array<WFAuthPermission> {
        return this.m_hdreditmodex;
    }

    public get LinesExceptions(): Array<WFAuthPermission> {
        return this.m_lnseditmodex;
    }

    //

    public get AdditionalInfo(): string {
        return this.m_addinfo;
    }

    //

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    //

    public GetVariablesCount(): number {
        return this.m_variables.length;
    }

    public GetVariable(id: number): WFProcessVariable {
        return this.m_variables[id];
    }

    public GetVariableByID(id: number): WFProcessVariable {
        let ii: number;
        let tst: WFProcessVariable;

        if (id > 0) {
            for (ii = 0; ii < this.m_variables.length; ii++) {
                tst = this.m_variables[ii];
                if (tst.InternalID === id) return tst;
            }
        } else {
            let sysvars = WFProcessVariable.GetSystemDefinitions();
            for (ii = 0; ii < sysvars.length; ii++) {
                tst = sysvars[ii];
                if (tst.InternalID === id) return tst;
            }
        }

        return null;
    }

    public ClearVariables(): void {
        this.m_variables.splice(0, this.m_variables.length);
    }

    /*public WFProcessVariable AddVariable(string name, WFProcessVarType tp)
    {
        int ii;
 
        if (!WFProcessVariable.IsNameValid(name)) return null;
 
        WFProcessVariable test;
        string uname = name.ToUpper();
        for (ii = 0; ii < m_variables.Count; ii++)
        {
            test = m_variables[ii];
            if (test.Name.ToUpper() == uname) return null;
        }
 
        WFProcessVariable[] sysvars = WFProcessVariable.GetSystemDefinitions();
        for (ii = 0; ii < sysvars.Length; ii++)
        {
            test = sysvars[ii];
            if (test.Name.ToUpper() == uname) return null;
        }
 
        int maxid = 0;
        if (m_variables.Count > 0) maxid = m_variables[m_variables.Count - 1].InternalID;
        maxid++;
        WFProcessVariable vrr = new WFProcessVariable(maxid, name, "", tp, "");
        m_variables.Add(vrr);
 
        return vrr;
    }*/

    //

    public GetInstructionCount(): number {
        return this.m_instructions.length;
    }

    public GetInstruction(id: number): WFAuthInstruction {
        return this.m_instructions[id];
    }

    public GetInstructionByID(id: number): WFAuthInstruction {
        for (let ii = 0; ii < this.m_instructions.length; ii++) {
            let ins = this.m_instructions[ii];
            if (ins.InternalID === id) return ins;
        }
        return null;
    }

    public ClearInstructions(): void {
        this.m_actions.splice(0, this.m_actions.length);
        this.m_instructions.splice(0, this.m_instructions.length);
    }

    /*public WFAuthInstruction AddInstruction(string name, WFAuthInsType tp)
    {
        if (!WFAuthInstruction.IsNameValid(name)) return null;
 
        int maxid = 0;
        if (m_instructions.Count > 0) maxid = m_instructions[m_instructions.Count - 1].InternalID;
        maxid++;
        WFAuthInstruction ins = new WFAuthInstruction(maxid, name, "", tp, 0, WFAuthInsObjType.USER, "", 0);
        m_instructions.Add(ins);
 
        return ins;
    }
 
    public bool RemoveInstructionByID(int id)
    {
        WFAuthInstruction ins = GetInstructionByID(id);
        if (ins != null)
        {
            int ii = 0;
            while (ii < m_actions.Count)
            {
                WFProcessAction act = m_actions[ii];
                if (act.InsInternalID == id || act.InsInternalID2 == id)
                {
                    m_actions.RemoveAt(ii);
                }
                else
                {
                    ii++;
                }
            }
            m_instructions.Remove(ins);
            return true;
        }
        return false;
    }*/

    //

    public GetActionCount(): number {
        return this.m_actions.length;
    }

    public GetAction(id: number): WFProcessAction {
        return this.m_actions[id];
    }

    /*public WFProcessAction AddAction(WFAuthInstruction ins, WFAuthInstruction ins2)
    {
        int maxid = 0;
        if (m_actions.Count > 0) maxid = m_actions[m_actions.Count - 1].InternalID;
        maxid++;
 
        WFProcessAction act = new WFProcessAction(maxid,
                                            (ins == null) ? WFProcessAction.STARTPOINT : ins.InternalID,
                                            (ins2 == null) ? WFProcessAction.APPROVEPOINT : ins2.InternalID);
 
        m_actions.Add(act);
        return act;
    } */

    //

    private CheckInitialized(insintid: number, varid: number): WFProcessAction {
        let ii, ii2, cc: number;

        for (ii = 0; ii < this.m_actions.length; ii++) {
            let act = this.m_actions[ii];
            if (act.InsInternalID2 === insintid) {
                let ins = this.GetInstructionByID(act.InsInternalID);
                if (ins == null) return act;

                let checkinner = true;

                if (ins.Type === WFAuthInsType.PARAMETRIZED) {
                    cc = ins.GetParamsCount();
                    for (ii2 = 0; ii2 < cc; ii2++) {
                        let prm = ins.GetParam(ii2);
                        if (prm.VariableID === varid) {
                            checkinner = false;
                            break;
                        }
                    }
                }

                if (checkinner) {
                    let subact = this.CheckInitialized(ins.InternalID, varid);
                    if (subact != null) return subact;
                }
            }
        }

        return null;
    }

    /*public WFCustomAuthValidationResult ValidateCustomProcess()
    {
        int ii, ii2;
        bool doerr;
        WFProcessVariable var;
        WFProcessAction act;
 
        //sprawdzenie czy istnieja niepopiete instrukcje
        //sprawdz czy sa prawidlowe typy danych
        //sprzd czy istnieja zmienne
        if (m_instructions.Count > 0)
        {
            for (ii = 0; ii < m_instructions.Count; ii++)
            {
                WFAuthInstruction ins = m_instructions[ii];
                doerr = true;    
 
                for (ii2 = 0; ii2 < m_actions.Count; ii2++)
                {
                    act = m_actions[ii2];
                    if (act.InsInternalID2 == ins.InternalID)
                    {
                        doerr = false;
                        break;
                    }
                }
 
                if (doerr) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_NOREF, "", ins, null);
 
                //sprawdz czy istnieja typu zadeklarowane
                if (ins.Type == WFAuthInsType.PARAMETRIZED && ins.ObjType!= WFAuthInsObjType.USER && ins.ObjType!= WFAuthInsObjType.INTERNALUSER)
                {
                    return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_WRONGTYPE, "", ins, null);
                }
 
                switch(ins.ObjType)
                {
                    case WFAuthInsObjType.INTERNALUSER:
                        //sprawdz czy usr zostal zdefiniowany i zainicjowany
                        var = GetVariableByID(ins.ObjID);
                        if (var == null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNDEFINEDUSER, ins.ObjID.ToString(), ins, null);
                        if (var.Type != WFProcessVarType.TYPE_INT) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_WRONGTYPE, ins.ObjID.ToString(), ins, null);
                        //sledz inicjalizacje
                        if (String.IsNullOrEmpty(var.EvalCode))
                        {
                            act = CheckInitialized(ins.InternalID, ins.ObjID);
                            if (act != null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNINITIALIZEDUSER, ins.ObjID.ToString(), ins, act);
                        }
                        break;
 
                    //case WFAuthInsObjType.INTERNALUSERGROUP:
                    //    //sprawdz czy grupa zostala zdefiniowana i zainicjowana
                    //    var = GetVariableByID(ins.ObjID);
                    //    if (var == null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNDEFINEDGROUP, ins.ObjID.ToString(), ins, null);
                    //    if (var.Type != WFProcessVarType.TYPE_AINT && var.Type != WFProcessVarType.TYPE_INT) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_WRONGTYPE, ins.ObjID.ToString(), ins, null);
                    //    if (String.IsNullOrEmpty(var.EvalCode))
                    //    {
                    //        act = CheckInitialized(ins.InternalID, ins.ObjID);
                    //        if (act != null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNINITIALIZEDGROUP, ins.ObjID.ToString(), ins, act);
                    //    }
                    //    break;
 
                    case WFAuthInsObjType.INTERNALAUTHSCHEMA:
                        var = GetVariableByID(ins.ObjID);
                        if (var == null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNDEFINEDAUTHSCHEMA, ins.ObjID.ToString(), ins, null);
                        if (var.Type != WFProcessVarType.TYPE_SCHEMA) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_WRONGTYPE, ins.ObjID.ToString(), ins, null);
                        act = CheckInitialized(ins.InternalID, ins.ObjID);
                        if (act != null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNINITIALIZEDAUTHSCHEMA, ins.ObjID.ToString(), ins, act);                            
                        break;
 
                    case WFAuthInsObjType.INTERNALAUTHSCHEMA2:
                        //sprawdz czy schemat zostal zdefiniowany i zainicjowany
                        var = GetVariableByID(ins.ObjID);
                        if (var == null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNDEFINEDAUTHSCHEMA, ins.ObjID.ToString(), ins, null);
                        if (var.Type != WFProcessVarType.TYPE_INT) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_WRONGTYPE, ins.ObjID.ToString(), ins, null);
                        //sledz inicjalizacje
                        if (String.IsNullOrEmpty(var.EvalCode))
                        {
                            act = CheckInitialized(ins.InternalID, ins.ObjID);
                            if (act != null) return new WFCustomAuthValidationResult(WFCustomAuthValidationErrCode.ERR_UNINITIALIZEDAUTHSCHEMA, ins.ObjID.ToString(), ins, act);
                        }
                        break;
                }
            }
 
            return WFCustomAuthValidationResult.ERROK;
        }
 
        return WFCustomAuthValidationResult.ERREMPTY;
    }*/
}

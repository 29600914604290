
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { StringDataService } from '../stringdata/stringdata.service';
import { WFClass, WFCompany, WFUser, WFUserGroup, WFAuthSchema, WFReconSchema, WFOcrDefinition, WFInteractiveDictionary, WFDictionary, WFCustomProcess, WFDailyCorrespondenceClass, WFConfiguration, WFDirectory, WFDocument, WFFilterInfo, WFMessage, WFDocumentPage, WFBusinessPartnerDB, WFRelation, WFProcessVarState, WFDailyCorrespondence, IWFObject, WFResourceType, WFOcrProcessLog, DocumentInterfaceConfig } from '../model/index';
import { EventAdp } from './eventadp';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFSignature } from '../model/WFSignature';

export const DATASERVICE_ADR = 'http://localhost:4805';

const CMD_GETSESSIONID = 0;
const CMD_CHANGEUSERPASSWORD = 1;
const CMD_GETUSER = 2;
const CMD_GETCONFIGURATION = 3;
const CMD_GETCOMPANYLIST = 4;
const CMD_GETUSERLIST = 5;
const CMD_GETCLASSLIST = 6;
const CMD_GETAUTHSCHEMALIST = 7;
const CMD_GETUSERGROUPSLIST = 8;
const CMD_GETDEFINITIONSLIST = 9;
const CMD_GETDIRECTORIESLIST = 10;
const CMD_MODIFYDIRECTORY = 11;
const CMD_REMOVEDIRECTORY = 12;
const CMD_GETDOCUMENTS = 13;
const CMD_FINDDOCUMENTS = 14;
const CMD_SORTDOCUMENTS = 15;
const CMD_MODIFYDOCUMENT = 16;
const CMD_REMOVEDOCUMENT = 17;
const CMD_GETRESOURCES = 18;
const CMD_CREATEPAGESFROMFILE = 19;
const CMD_MODIFYRESOURCE = 20;
const CMD_GETRESOURCEPREVIEW = 21;
const CMD_GETPDFPREVIEW = 22;
const CMD_SENDDOCUMENTBYEMAIL = 23;
const CMD_GETUSERALERTS = 24;
const CMD_GETMESSAGES = 25;
const CMD_FINDMESSAGES = 26;
const CMD_MODIFYMESSAGE = 27;
const CMD_MODIFYUSER = 28;
const CMD_PREVIEWEXOBJECT = 29;
const CMD_SENDRECONSCHEMAREQUEST = 30;
const CMD_GETRECONSCHEMALIST = 31;
const CMD_GETMATCHRECONSCHEMACODES = 32;
const CMD_SYNCRECONSCHEMALIST = 33;
const CMD_EXECUTERECONSCHEMA = 34;
const CMD_GETINTERACTIVEDICTSLIST = 35;
const CMD_EXECUTEINTERACTIVEDICT = 36;

const CMD_SUGGESTDOCUNIQID = 37;
const CMD_GETFILTERINFO = 38;
const CMD_GETSIGNATUREINFO = 39;
const CMD_GETDICTIONARIESLIST = 40;

// lokalna baza ph
const CMD_BPDB_FINDBP = 41;
const CMD_BPDB_GETALLCARDCODES = 42;
const CMD_BPDB_GETBP = 43;
const CMD_BPDB_GETCOUNTRIES = 44;
const CMD_BPDB_GETSTATES = 45;
const CMD_BPDB_SUGGESTCARDCODE = 46;
const CMD_BPDB_REGISTERNEWBP = 47;
const CMD_BPDB_UPDATEEXISTBP = 48;

// triggery
const CMD_GETCUSTOMPROCESSLIST = 49;
const CMD_EXECUTESCRIPTOP = 50;

// wersja
const CMD_GETVERSION = 51;

// dzienniki korespondencji
const CMD_GETDAILYCORRESPONDENCECLASSES = 52;

const CMD_GETDAILYCORRESPONDENCES = 53;
const CMD_MODIFYDAILYCORRESPONDENCE = 54;
const CMD_REMOVEDAILYCORRESPONDENCE = 55;

const CMD_BPDB_GETBPONLINE = 56;
//
const CMD_FINDLINEVALUES = 57;
const CMD_SUGGESTOCRMAP = 58;

// DM 07022017
const CMD_CHECKNEWOCRLOGS = 59;
const CMD_GETOCRLOGS = 60;
const CMD_CONFIRMOCRLOGS = 61;

const CMD_PREPAREDOWNLOAD = 62;
const CMD_PREPAREPRINT = 63;

// DM 12.06.2018
const CMD_GETDAILYCORREFS = 64;

//DMod 09062020
const CMD_GETPRINTASYNCTASKS = 67;
const CMD_ADDPRINTASYNCTASKS = 68;

//
const CMD_CHECKUNREAD = 69;
const CMD_FETCHMAILS = 70; 
const CMD_PREVIEWMAIL = 71;
const CMD_PREPAREATTACHMENT = 72;
const CMD_CREATEDOCUMENTFROMMAIL = 73;

const CMD_MARKSEENFLAG = 74;
const CMD_REFMAILFOLDERS = 75;

//DMod 08022023
const CMD_LISTEDOCUMENTS = 76;
const CMD_PREVIEWEDOCUMENT = 77;
const CMD_CREATEDOCUMENTFROMEDOC = 78;

export interface RefObj {
  objtype: string;
  objid: string;
}

export interface AttValue {
  attid: number;
  attval: string;
}

export interface FindDocumentPrms {
  sid: string;
  companies: Array<number>;
  users: Array<number>;
  classes: Array<number>;
  schemas: Array<number>;
  directories: Array<number>;
  documents: Array<number>;
  fargs: Array<string>;
  createdatfrom: Date;
  createdatto: Date;
  docdatefrom: Date;
  docdateto: Date;
  flags: number;
  refobj: Array<RefObj>;
  attvalues: Array<AttValue>;
}

export interface UserAlert {
  key: string;
  sumparent: boolean;
  alert: boolean;
  docs: Array<number>;
}

export enum WFDataSource {
  INTERACTIVEDICT,
  DAILYCORRCLASS
}

export enum WFBasicDataType {
  DOUBLE,
  INT,
  DATETIME,
  STRING,
  DECIMAL
}

export interface EIDParam {
  pid: number;
  dtp: WFBasicDataType;
  val: any;
}

export interface ExecuteInteractiveDictPrms {
  sid: string;
  dts: WFDataSource;
  dict_id: number;
  dval: Array<EIDParam>;
  dbname: string;
}

export interface FindMessagesPrms {
  sid: string;
  dst_cmp: number;
  dst_usr: number;
  dst_doc: number;
  isread: number;
}

export interface GetResourcePreviewPrms {
  sid: string;
  mode: number;
  resid: number;
  pagenum: number;
  mwidth: number;
  mheight: number;
  quality: number;
}

export interface CodeName {
  Code: string;
  Name: string;
}

export interface CodeCodeName {
  Code1: string;
  Code2: string;
  Name: string;
}

export interface BusinessPartnerAddress {
  Street: string;
  Country: CodeName;
  State: CodeName;
  ZipCode: string;
  City: string;
}

export interface BusinessPartner {
  CardCode: string;
  CardType: string;
  CardName: string;
  CardFName: string;
  FTaxID: string;
  FreeText: string;
  Delivery: BusinessPartnerAddress;
  Billing: BusinessPartnerAddress;
}

export enum ColumnAlign {
  LEFT,
  CENTER,
  RIGHT
}

export enum ColumnType {
  STRING,
  INT,
  FLOAT,
  DATETIME
}

export interface ColumnInfo {
  label: string;
  align: ColumnAlign;
  type: ColumnType;
  format: string;
}

export interface StringDataTable {
  Columns: Array<ColumnInfo>;
  Rows: Array<Array<string>>;
}

export interface LineValuesSpec {
  code: string;
  values: Array<string>;
}

export interface ProcessVarState {
  varid: number;
  pvar: Object;
}

export interface DownloadInfo {
  fname: string;
  date: Date;
  signature: string;
  type: WFResourceType;
}

export interface PreparePrintPrms {
  docid: number;
  rangefrom: number;
  rangeto: number;
  mwidth: number;
  mheight: number;
  prnauth: boolean;
  cmpreview: boolean;
  cmhistory: boolean;
  cmlines: boolean;
  cmosinglepage: boolean;
  acthtml: string;
}

export interface DailyCorRefInfo {
  dailycorr_id: number;
  linkdate: Date;
  dailycorrclass_id: number;
  opdate: Date;
  clsname: string;
}

export enum PrintAsyncTaskStatus {
  WAITING,
  PREPARING,
  ERROR,
  DONE
}

export interface PrintAsyncTasksInfo {
  start: Date;
  rows: number;
  link: string;
  status: PrintAsyncTaskStatus;  
  lasterr: string;
  percent: number;
}

export interface MailMessageInfo {
  uniqueid: number;
  subject: string;
  preview: string;
  date: Date;
  from: string;
  attachments: number;
  documents_id: Array<number>;
  seen: boolean;
}

export interface MailMsgPreview {
  subject: string;
  html: string;
  txt: string;
  from: Array<string>;
  to: Array<string>;
  attachments: Array<any>;
}

export interface InvoiceHeader {
    InvoiceReferenceNumber: string;
    KsefReferenceNumber: string;
    InvoiceHash: string;
    InvoicingDate: string;
    AcquisitionTimestamp: string;
    SubjectBy_NIP: string;
    SubjectBy_Name: string;
    SubjectTo_NIP: string;
    SubjectTo_Name: string;
    Net: number;
    Vat: number;
    Gross: number;
    Currency: string;
}

@Injectable()
export class DataService {
  constructor(private m_http: HttpClient,
    private m_global_srv: GlobalService,
    private m_str_srv: StringDataService) {
  }

  public get ServiceURL(): string {
    let url= (this.m_global_srv.Config == null) ? DATASERVICE_ADR : this.m_global_srv.Config.DATASERVICE_ADR;
    if(/^[0-9]+$/g.test(url)) url= window.location.protocol+'//'+window.location.hostname+':'+url;
    return url;
  }

  private sendCmd(optp: number, obj: any, onerr: EventAdp<() => void>, onsucc: (dta: any) => void): void {
    const self = this;

    let hdrs = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let opts = { headers: hdrs };    
    let fncerr = () => {
      if (onerr != null) onerr.call();
    };

    this.m_http.post<any>(this.ServiceURL + '?OpType=' + optp, obj, opts).pipe(
      map(function (val) {
        let ret = val;//.json();
        if (ret.ErrCode !== 0) throw new Error(ret.ErrCode + ':' + ret.ErrMsg);
        return ret.Data;
      }))
      .subscribe(
        function (dta) {
          try {
            onsucc(dta);
          } catch (ex) {
            self.m_global_srv.manageException(ex, fncerr);
          }
        },
        function (err) {
          if (err instanceof HttpResponse) {
            self.m_global_srv.showInfo(BUTTONSTYPE.OK, self.m_str_srv.getStr('strDataServiceConnErr'), fncerr);
          } else {
            if (err.message.indexOf('100:') === 0)
              self.m_global_srv.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, self.m_str_srv.getStr('strSessionLost'), fncerr);
            else
              self.m_global_srv.manageException(err, fncerr);
          }
        }
      );
  }

  //

  public getSessionID(login: string, pass: string, onerr: EventAdp<() => void>, onsucc: (sid: string) => void): void {
    this.sendCmd(CMD_GETSESSIONID, { 'Login': login, 'Pass': pass }, onerr,
      function (dta) {
        onsucc(dta.SessionID);
      });
  }

  //

  public getClassList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFClass>) => void): void {
    this.sendCmd(CMD_GETCLASSLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFClass>();
        for (let cl of dta.clss) arr.push(new WFClass(cl));
        onsucc(arr);
      });
  }

  //

  public getCompanyList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFCompany>) => void): void {
    this.sendCmd(CMD_GETCOMPANYLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFCompany>();
        for (let cm of dta.cmps) arr.push(new WFCompany(cm));
        onsucc(arr);
      });
  }

  //

  public getUserList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFUser>) => void): void {
    this.sendCmd(CMD_GETUSERLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFUser>();
        for (let us of dta.usrs) arr.push(new WFUser(us));
        onsucc(arr);
      });
  }

  //

  public getUserGroupsList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFUserGroup>) => void): void {
    this.sendCmd(CMD_GETUSERGROUPSLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFUserGroup>();
        for (let ug of dta.grps) arr.push(new WFUserGroup(ug));
        onsucc(arr);
      });
  }

  //

  public getAuthSchemaList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFAuthSchema>) => void): void {
    this.sendCmd(CMD_GETAUTHSCHEMALIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFAuthSchema>();
        for (let sh of dta.shms) arr.push(new WFAuthSchema(sh));
        onsucc(arr);
      });
  }

  //

  public getReconSchemaList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFReconSchema>) => void): void {
    this.sendCmd(CMD_GETRECONSCHEMALIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFReconSchema>();
        for (let sh of dta.rss) arr.push(new WFReconSchema(sh));
        onsucc(arr);
      });
  }

  //

  public getDefinitionsList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFOcrDefinition>) => void): void {
    this.sendCmd(CMD_GETDEFINITIONSLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFOcrDefinition>();
        for (let sh of dta.defs) arr.push(new WFOcrDefinition(sh));
        onsucc(arr);
      });
  }

  //

  public getInteractiveDictsList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFInteractiveDictionary>) => void): void {
    this.sendCmd(CMD_GETINTERACTIVEDICTSLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFInteractiveDictionary>();
        for (let sh of dta.ids) arr.push(new WFInteractiveDictionary(sh));
        onsucc(arr);
      });
  }

  //

  public getDictionariesList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFDictionary>) => void): void {
    this.sendCmd(CMD_GETDICTIONARIESLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFDictionary>();
        for (let sh of dta.sds) arr.push(new WFDictionary(sh));
        onsucc(arr);
      });
  }

  //

  public getCustomProcessList(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFCustomProcess>) => void): void {
    this.sendCmd(CMD_GETCUSTOMPROCESSLIST, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFCustomProcess>();
        for (let sh of dta.prs) arr.push(new WFCustomProcess(sh));
        onsucc(arr);
      });
  }

  //

  public getDailyCorrespondenceClasses(sid: string, onerr: EventAdp<() => void>, onsucc: (cls: Array<WFDailyCorrespondenceClass>) => void): void {
    this.sendCmd(CMD_GETDAILYCORRESPONDENCECLASSES, { 'sid': sid }, onerr,
      function (dta) {
        let arr = new Array<WFDailyCorrespondenceClass>();
        for (let sh of dta.clss) arr.push(new WFDailyCorrespondenceClass(sh));
        onsucc(arr);
      });
  }

  //

  public getConfiguration(sid: string, onerr: EventAdp<() => void>, onsucc: (cfg: WFConfiguration) => void): void {
    this.sendCmd(CMD_GETCONFIGURATION, { 'sid': sid }, onerr,
      function (dta) {
        let ret = new WFConfiguration(dta.config);
        onsucc(ret);
      });
  }

  //

  public getUser(sid: string, onerr: EventAdp<() => void>, onsucc: (cfg: WFUser, mailfolders: Array<any>, edoccounts: Array<any>) => void): void {
    this.sendCmd(CMD_GETUSER, { 'sid': sid }, onerr,
      function (dta) {
        let ret = new WFUser(dta.user);
        onsucc(ret, dta.mailfolders, dta.edoccounts);
      });
  }

  //

  public getDirectoriesList(sid: string, onerr: EventAdp<() => void>, onsucc: (dirs: Array<WFDirectory>) => void): void {
    this.sendCmd(CMD_GETDIRECTORIESLIST, { 'sid': sid }, onerr,
      function (dta) {
        let ret = new Array<WFDirectory>();
        for (let dt of dta.dirs) ret.push(new WFDirectory(dt));
        onsucc(ret);
      });
  }

  //

  public findDocuments(prms: FindDocumentPrms, onerr: EventAdp<() => void>, onsucc: (rows: Array<any>) => void): void {
    this.sendCmd(CMD_FINDDOCUMENTS, prms, onerr,
      function (dta) { onsucc(dta.rows); });
  }

  //

  public getUserAlerts(sid: string, auto: boolean, onerr: EventAdp<() => void>, onsucc: (dirs: Array<UserAlert>) => void): void {
    this.sendCmd(CMD_GETUSERALERTS, { sid: sid, auto: auto }, onerr,
      function (dta) {
        let ret = new Array<UserAlert>();
        for (let alr of dta.alerts) ret.push({ key: alr.key, sumparent: alr.sumparent, alert: alr.alert, docs: alr.docs });
        onsucc(ret);
      });
  }

  //

  public getDocuments(sid: string, ids: Array<number>, addbyte: boolean, onerr: EventAdp<() => void>, onsucc: (dirs: Array<WFDocument>, ronly: Array<number>) => void): void {
    this.sendCmd(CMD_GETDOCUMENTS, { sid: sid, ids: ids, addbyte: addbyte }, onerr,
      function (dta) {
        let ret = new Array<WFDocument>();
        for (let doc of dta.docs) ret.push(new WFDocument(doc));
        onsucc(ret, dta.readonly);
      });
  }

  //

  public sortDocuments(sid: string, docs: Array<number>, sortcol: number, sortasc: boolean, onerr: EventAdp<() => void>, onsucc: (dirs: Array<number>) => void): void {
    this.sendCmd(CMD_SORTDOCUMENTS, { sid: sid, docs: docs, sortcol: sortcol, sortasc: sortasc }, onerr,
      function (dta) {
        onsucc(dta.ids);
      });
  }

  //

  public getFilterInfo(sid: string, ids: Array<number>, onerr: EventAdp<() => void>, onsucc: (filter: WFFilterInfo) => void): void {
    this.sendCmd(CMD_GETFILTERINFO, { sid: sid, ids: ids }, onerr,
      function (dta) {
        onsucc(new WFFilterInfo(dta.filter));
      });
  }

  //

  public checkNewOcrLogs(sid: string, docid: number, onerr: EventAdp<() => void>, onsucc: (nlogs: number) => void): void {
    this.sendCmd(CMD_CHECKNEWOCRLOGS, { sid: sid, docid: docid }, onerr,
      function (dta) {
        onsucc(dta.nlogs);
      });
  }

  //

  public executeInteractiveDict(prms: ExecuteInteractiveDictPrms, onerr: EventAdp<() => void>, onsucc: (dta: Array<[string, Array<Object>]>) => void): void {
    this.sendCmd(CMD_EXECUTEINTERACTIVEDICT, prms, onerr,
      function (dta) {
        let ret = new Array<[string, Array<Object>]>();
        for (let cl of dta.dta.cols) {
          ret.push([cl.colname, cl.rows]);
        }
        onsucc(ret);
      });
  }

  //

  public findMessages(prms: FindMessagesPrms, onerr: EventAdp<() => void>, onsucc: (msg_ids: Array<number>) => void): void {
    this.sendCmd(CMD_FINDMESSAGES, prms, onerr,
      function (dta) {
        onsucc(dta.msg_ids);
      });
  }

  //

  public getMessages(sid: string, ids: Array<number>, onerr: EventAdp<() => void>, onsucc: (dirs: Array<WFMessage>) => void): void {
    this.sendCmd(CMD_GETMESSAGES, { sid: sid, ids: ids }, onerr,
      function (dta) {
        let ret = new Array<WFMessage>();
        for (let msg of dta.msgs) ret.push(new WFMessage(msg));
        onsucc(ret);
      });
  }

  //

  public getResourcePreview(prms: GetResourcePreviewPrms, onerr: EventAdp<() => void>, onsucc: (dta: any) => void): void {
    this.sendCmd(CMD_GETRESOURCEPREVIEW, prms, onerr,
      function (dta) {
        onsucc(dta);
      });
  }

  //

  private static DeserializeCodeName(dser: any): CodeName {
    let isset: boolean = dser.isset;
    if (!isset) return null;
    let ret: CodeName = {
      Code: dser.code,
      Name: dser.name
    };
    return ret;
  }

  private static SerializeCodeName(cn: CodeName): any {
    if (cn == null) return { isset: false };
    return {
      isset: true,
      code: cn.Code,
      name: cn.Name
    };
  }

  public static DeserializeCodeCodeName(dser: any): CodeCodeName {
    let isset: boolean = dser.isset;
    if (!isset) return null;
    let ret: CodeCodeName = {
      Code1: dser.code1,
      Code2: dser.code2,
      Name: dser.name
    };
    return ret;
  }

  public static DeserializeBusinessPartnerAddress(dser: any): BusinessPartnerAddress {
    let isset: boolean = dser.isset;
    if (!isset) return null;
    let ret: BusinessPartnerAddress = {
      Street: dser.street,
      Country: DataService.DeserializeCodeName(dser.country),
      State: DataService.DeserializeCodeName(dser.state),
      ZipCode: dser.zipcode,
      City: dser.city
    };
    return ret;
  }

  public static SerializeBusinessPartnerAddress(adr: BusinessPartnerAddress): any {
    if (adr == null) return { isset: false };
    return {
      isset: true,
      street: adr.Street,
      country: DataService.SerializeCodeName(adr.Country),
      state: DataService.SerializeCodeName(adr.State),
      zipcode: adr.ZipCode,
      city: adr.City
    };
  }

  public static DeserializeBusinessPartner(dser: any): BusinessPartner {
    let ret: BusinessPartner = {
      CardCode: dser.cardcode,
      CardType: dser.cardtype,
      CardName: dser.cardname,
      CardFName: dser.cardfname,
      FTaxID: dser.ftaxid,
      FreeText: dser.freetext,
      Delivery: DataService.DeserializeBusinessPartnerAddress(dser.delivery),
      Billing: DataService.DeserializeBusinessPartnerAddress(dser.billing)
    };
    return ret;
  }

  public static SerializeBusinessPartner(bp: BusinessPartner): any {
    return {
      cardcode: bp.CardCode,
      cardtype: bp.CardType,
      cardname: bp.CardName,
      cardfname: bp.CardFName,
      ftaxid: bp.FTaxID,
      freetext: bp.FreeText,
      delivery: DataService.SerializeBusinessPartnerAddress(bp.Delivery),
      billing: DataService.SerializeBusinessPartnerAddress(bp.Billing)
    };
  }

  //

  public getAllCardCodes(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, onerr: EventAdp<() => void>, onsucc: (dirs: Array<CodeName>) => void): void {
    this.sendCmd(CMD_BPDB_GETALLCARDCODES, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname
    }, onerr,
      function (dta) {
        if (dta.result !== 0) throw new Error(dta.result.toString());
        let ret = new Array<CodeName>();
        for (let cn of dta.vec) ret.push(DataService.DeserializeCodeName(cn));
        onsucc(ret);
      });
  }

  //

  public getBP(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, ccode: string, onerr: EventAdp<() => void>, onsucc: (bp: BusinessPartner) => void): void {
    this.sendCmd(CMD_BPDB_GETBP, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname,
      cardcode: ccode
    }, onerr,
      function (dta) {
        if (dta.result !== 0) throw new Error(dta.result.toString());
        let ret: BusinessPartner = DataService.DeserializeBusinessPartner(dta.bp);
        onsucc(ret);
      });
  }

  //

  public findBP(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, keywords: string, addkwords: string, onerr: EventAdp<() => void>, onsucc: (codes: Array<CodeName>) => void): void {
    this.sendCmd(CMD_BPDB_FINDBP, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname,
      keywords: keywords,
      addkwords: addkwords
    }, onerr,
      function (dta) {
        if (dta.result !== 0) throw new Error(dta.result.toString());
        let ret = new Array<CodeName>();
        for (let cn of dta.vec) ret.push(DataService.DeserializeCodeName(cn));
        onsucc(ret);
      });
  }

  //

  public getCountries(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, onerr: EventAdp<() => void>, onsucc: (codes: Array<CodeName>) => void): void {
    this.sendCmd(CMD_BPDB_GETCOUNTRIES, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname
    }, onerr,
      function (dta) {
        if (dta.result !== 0) throw new Error(dta.result.toString());
        let ret = new Array<CodeName>();
        for (let cn of dta.vec) ret.push(DataService.DeserializeCodeName(cn));
        onsucc(ret);
      });
  }

  public getStates(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, onerr: EventAdp<() => void>, onsucc: (codes: Array<CodeCodeName>) => void): void {
    this.sendCmd(CMD_BPDB_GETSTATES, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname
    }, onerr,
      function (dta) {
        if (dta.result !== 0) throw new Error(dta.result.toString());
        let ret = new Array<CodeCodeName>();
        for (let cn of dta.sts) ret.push(DataService.DeserializeCodeCodeName(cn));
        onsucc(ret);
      });
  }

  public suggestCardCode(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, cardtype: string, onerr: EventAdp<() => void>, onsucc: (ccode: string) => void): void {
    this.sendCmd(CMD_BPDB_SUGGESTCARDCODE, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname,
      cardtype: cardtype
    }, onerr,
      function (dta) { onsucc(dta.sret); });
  }

  public registerNewBP(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, bp: BusinessPartner, onerr: EventAdp<() => void>, onsucc: (sret: string) => void): void {
    this.sendCmd(CMD_BPDB_REGISTERNEWBP, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname,
      bpd: DataService.SerializeBusinessPartner(bp)
    }, onerr,
      function (dta) { onsucc(dta.sret2); });
  }

  public updateExistBP(sid: string, bpdb: WFBusinessPartnerDB, dbname: string, bp: BusinessPartner, onerr: EventAdp<() => void>, onsucc: (sret: string) => void): void {
    this.sendCmd(CMD_BPDB_UPDATEEXISTBP, {
      sid: sid,
      bpdb: <number>bpdb,
      dbname: dbname,
      bpd2: DataService.SerializeBusinessPartner(bp)
    }, onerr,
      function (dta) { onsucc(dta.sret3); });
  }

  public getBPOnline(sid: string, searchtext: string, captcha: string, onerr: EventAdp<() => void>, onsucc: (bp: BusinessPartner) => void): void {
    this.sendCmd(CMD_BPDB_GETBPONLINE, {
      sid: sid,
      searchtext: searchtext,
      captcha: captcha
    }, onerr,
      function (dta) {
        if (dta.result !== 0) throw new Error(dta.result.toString());
        let ret: BusinessPartner = DataService.DeserializeBusinessPartner(dta.bptmp);
        onsucc(ret);
      });
  }

  //

  public getMatchReconSchemaCodes(sid: string, doc: WFDocument, onerr: EventAdp<() => void>, onsucc: (codes: Array<string>) => void): void {
    this.sendCmd(CMD_GETMATCHRECONSCHEMACODES, {
      sid: sid,
      doc: doc.ToObject()
    }, onerr,
      function (dta) { onsucc(dta.code); });
  }

  //

  public static DeserializeDataColumns(dta: any): Array<[string, Array<object>]> {
    let ii: number, ii2: number;

    let cols: Array<any> = dta.cols;
    let ret = new Array<[string, Array<object>]>(cols.length);

    if (cols.length > 0) {
      //int rowslen = IWFObject.DeserializeInt(str);

      for (ii = 0; ii < cols.length; ii++) {
        let cl = cols[ii];
        let colid: string = cl.colname;
        let dtp: WFBasicDataType = <WFBasicDataType>cl.type;
        let rows: Array<object> = cl.rows;
        //object[] values = new object[rowslen];

        /*switch (dtp)
        {
            case WFBasicDataType.DOUBLE:
                for (ii2 = 0; ii2 < rowslen; ii2++)
                {
                    double dval = IWFObject.DeserializeDouble(str);
                    values[ii2] = dval;
                }
                break;

            case WFBasicDataType.INT:
                for (ii2 = 0; ii2 < rowslen; ii2++)
                {
                    int ival = IWFObject.DeserializeInt(str);
                    values[ii2] = ival;
                }
                break;

            case WFBasicDataType.DATETIME:
                for (ii2 = 0; ii2 < rowslen; ii2++)
                {
                    DateTime dtval = IWFObject.DeserializeDateTime(str);
                    values[ii2] = dtval;
                }
                break;

            default:
                for (ii2 = 0; ii2 < rowslen; ii2++)
                {
                    values[ii2] = IWFObject.DeserializeString(str);
                }
                break;
        }*/

        ret[ii] = [colid, rows];
      }

    }

    return ret;
  }

  public executeReconSchema(sid: string, rsh: WFReconSchema, res: Array<number>, onerr: EventAdp<() => void>, onsucc: (hdr: Array<[string, Array<object>]>, lns: Array<[string, Array<object>]>) => void): void {
    this.sendCmd(CMD_EXECUTERECONSCHEMA, {
      sid: sid,
      rsh: rsh.ToObject(),
      res: res
    }, onerr,
      function (dta) {
        let hdr = DataService.DeserializeDataColumns(dta.hdr);
        let lns = DataService.DeserializeDataColumns(dta.lns);
        onsucc(hdr, lns);
      });
  }

  //

  public createPagesFromFile(sid: string, fname: string, filebuff: string, onerr: EventAdp<() => void>, onsucc: (pages: Array<WFDocumentPage>) => void): void {
    this.sendCmd(CMD_CREATEPAGESFROMFILE, {
      sid: sid,
      fname: fname,
      filebuff: filebuff
    }, onerr,
      function (dta) {
        let ret = new Array<WFDocumentPage>();
        for (let pg of dta.pages) ret.push(new WFDocumentPage(pg));
        onsucc(ret);
      });
  }

  //

  public modifyDocument(sid: string, doc: WFDocument, onerr: EventAdp<() => void>, onsucc: (doc: WFDocument) => void): void {
    this.sendCmd(CMD_MODIFYDOCUMENT, {
      sid: sid,
      doc: doc.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFDocument(dta.doc);
        onsucc(ret);
      });
  }

  public removeDocument(sid: string, doc: WFDocument, onerr: EventAdp<() => void>, onsucc: (doc: WFDocument) => void): void {
    this.sendCmd(CMD_REMOVEDOCUMENT, {
      sid: sid,
      doc: doc.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFDocument(dta.doc);
        onsucc(ret);
      });
  }

  public suggestDocUniqID(sid: string, cmpid: number, clsid: number, onerr: EventAdp<() => void>, onsucc: (retdid: number) => void): void {
    this.sendCmd(CMD_SUGGESTDOCUNIQID, {
      sid: sid,
      cmpid: cmpid,
      clsid: clsid
    }, onerr,
      function (dta) {
        onsucc(dta.retdid);
      });
  }

  public sendDocumentByEMail(sid: string, docid: number, subject: string, to_email: Array<string>, content: string, onerr: EventAdp<() => void>, onsucc: (iret: number) => void): void {
    this.sendCmd(CMD_SENDDOCUMENTBYEMAIL, {
      sid: sid,
      docid: docid,
      subject: subject,
      to_email: to_email,
      content: content
    }, onerr,
      function (dta) {
        onsucc(dta.iret);
      });
  }

  public modifyMessage(sid: string, msg: WFMessage, onerr: EventAdp<() => void>, onsucc: (msg: WFMessage) => void): void {
    this.sendCmd(CMD_MODIFYMESSAGE, {
      sid: sid,
      res: msg.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFMessage(dta.res);
        onsucc(ret);
      });
  }

  public previewExObject(sid: string, refid: number, onerr: EventAdp<() => void>, onsucc: (header: Array<[string, string]>, tables: Array<StringDataTable>) => void): void {
    this.sendCmd(CMD_PREVIEWEXOBJECT, {
      sid: sid,
      refid: refid
    }, onerr,
      function (dta) {
        let header = new Array<[string, string]>();
        for (let hdr of dta.header) header.push([hdr.name, hdr.value]);

        let tbls = new Array<StringDataTable>();
        for (let tbl of dta.tables) {
          let rows = new Array<Array<string>>();

          for (let rw of tbl.rows) {
            let nrw = new Array<string>();
            for (let ii = 0; ii < tbl.columns.length; ii++) nrw.push(rw['C' + ii]);
            rows.push(nrw);
          }

          let cols = new Array<ColumnInfo>();
          for (let cl of tbl.columns) {
            let lbl = <string>cl;
            let align = ColumnAlign.LEFT;
            let type = ColumnType.STRING;
            let format = '';

            let splittb = lbl.split('$$');
            if (splittb.length > 1) {
              lbl = splittb[0];
              let splitformat = splittb[1].split(';');
              if (splitformat.length > 1) {
                switch (splitformat[0].trim().toUpperCase()) {
                  case 'R': align = ColumnAlign.RIGHT; break;
                  case 'C': align = ColumnAlign.CENTER; break;
                  default: align = ColumnAlign.LEFT; break;
                }

                switch (splitformat[1].trim().toUpperCase()) {
                  case 'I': type = ColumnType.INT; break;
                  case 'F': type = ColumnType.FLOAT; break;
                  case 'D': type = ColumnType.DATETIME; break;
                  default: type = ColumnType.STRING; break;
                }

                if (splitformat.length > 2) {
                  format = splitformat[2].trim();
                }
              }
            }

            cols.push({ align: align, type: type, format: format, label: lbl });
          }

          tbls.push({ Columns: cols, Rows: rows });
        }

        onsucc(header, tbls);
      });
  }

  public suggestOcrMap(sid: string, docid: number, classid: number, ccode: string, schemacode: string, onerr: EventAdp<() => void>, onsucc: (ret: Array<WFRelation>) => void): void {
    this.sendCmd(CMD_SUGGESTOCRMAP, {
      sid: sid,
      docid: docid,
      classid: classid,
      ccode: ccode,
      schemacode: schemacode
    }, onerr,
      function (dta) {
        let ret = new Array<WFRelation>();
        for (let rl of dta.ret) ret.push(new WFRelation(rl));
        onsucc(ret);
      });
  }

  public findLineValues(sid: string, docid: number, classid: number, ccode: string, lines: Array<LineValuesSpec>, onerr: EventAdp<() => void>, onsucc: (ret: Map<string, Array<[string, string]>>) => void): void {
    this.sendCmd(CMD_FINDLINEVALUES, {
      sid: sid,
      docid: docid,
      classid: classid,
      ccode: ccode,
      lines: lines
    }, onerr,
      function (dta) {
        let ret = new Map<string, Array<[string, string]>>();
        for (let rl of dta.ret) {
          let tab = new Array<[string, string]>();
          for (let kv of rl.lst) tab.push([kv.Key, kv.Value]);
          ret.set(rl.attrcode, tab);
        }
        onsucc(ret);
      });
  }

  public executeScriptOp(sid: string, procid: number, intid: number, states: Array<ProcessVarState>, dbname: string, onerr: EventAdp<() => void>, onsucc: (ret: WFProcessVarState) => void): void {
    this.sendCmd(CMD_EXECUTESCRIPTOP, {
      sid: sid,
      procid: procid,
      intid: intid,
      states: states,
      dbname: dbname
    }, onerr,
      function (dta) {
        let ret = WFProcessVarState.Deserialize(dta.ret);
        onsucc(ret);
      });
  }

  public modifyDailyCorrespondence(sid: string, dcr: WFDailyCorrespondence, onerr: EventAdp<() => void>, onsucc: (ret: WFDailyCorrespondence) => void): void {
    this.sendCmd(CMD_MODIFYDAILYCORRESPONDENCE, {
      sid: sid,
      dcr: dcr.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFDailyCorrespondence(dta.dcr);
        onsucc(ret);
      });
  }

  public removeDailyCorrespondence(sid: string, dcr: WFDailyCorrespondence, onerr: EventAdp<() => void>, onsucc: (ret: WFDailyCorrespondence) => void): void {
    this.sendCmd(CMD_REMOVEDAILYCORRESPONDENCE, {
      sid: sid,
      dcr: dcr.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFDailyCorrespondence(dta.dcr);
        onsucc(ret);
      });
  }

  public getDailyCorrespondences(sid: string, ids: Array<number>, onerr: EventAdp<() => void>, onsucc: (dsc: Array<WFDailyCorrespondence>) => void): void {
    this.sendCmd(CMD_GETDAILYCORRESPONDENCES, {
      sid: sid,
      ids: ids
    }, onerr,
      function (dta) {
        let ret = new Array<WFDailyCorrespondence>();
        for (let dc of dta.dcs) ret.push(new WFDailyCorrespondence(dc));
        onsucc(ret);
      });
  }

  public modifyDirectory(sid: string, dir: WFDirectory, findbefadd: boolean, onerr: EventAdp<() => void>, onsucc: (dir: WFDirectory) => void): void {
    this.sendCmd(CMD_MODIFYDIRECTORY, {
      sid: sid,
      dir: dir.ToObject(),
      findbefadd: findbefadd
    }, onerr,
      function (dta) {
        let ret = new WFDirectory(dta.dir);
        onsucc(ret);
      });
  }

  public removeDirectory(sid: string, dir: WFDirectory, onerr: EventAdp<() => void>, onsucc: (dir: WFDirectory) => void): void {
    this.sendCmd(CMD_REMOVEDIRECTORY, {
      sid: sid,
      dir: dir.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFDirectory(dta.dir);
        onsucc(ret);
      });
  }

  public changeUserPassword(sid: string, arg1: string, arg2: string, onerr: EventAdp<() => void>, onsucc: (res: boolean) => void): void {
    this.sendCmd(CMD_CHANGEUSERPASSWORD, {
      sid: sid,
      arg1: arg1,
      arg2: arg2
    }, onerr,
      function (dta) {
        onsucc(dta.result);
      });
  }

  public modifyUser(sid: string, res: WFUser, onerr: EventAdp<() => void>, onsucc: (msg: WFUser) => void): void {
    this.sendCmd(CMD_MODIFYUSER, {
      sid: sid,
      res: res.ToObject()
    }, onerr,
      function (dta) {
        let ret = new WFUser(dta.res);
        onsucc(ret);
      });
  }

  public prepareDownload(sid: string, resids: Array<number>, onerr: EventAdp<() => void>, onsucc: (inf: Array<DownloadInfo>) => void): void {
    this.sendCmd(CMD_PREPAREDOWNLOAD, {
      sid: sid,
      resids: resids
    }, onerr,
      function (dta) {
        let ret = new Array<DownloadInfo>();
        for (let din of dta.result) ret.push({ fname: din.fname, date: IWFObject.ParseDate(din.date), signature: din.signature, type: <WFResourceType>din.type });
        onsucc(ret);
      });
  }

  public preparePrint(sid: string, prms: PreparePrintPrms, onerr: EventAdp<() => void>, onsucc: (signature: string, preview: Array<any>) => void): void {
    this.sendCmd(CMD_PREPAREPRINT, {
      sid: sid,
      prms: prms
    }, onerr,
      function (dta) {
        onsucc(dta.ret.signature, dta.ret.preview);
      });
  }

  public getDailyCorRefs(sid: string, docid: number, onerr: EventAdp<() => void>, onsucc: (links: Array<DailyCorRefInfo>) => void): void {
    this.sendCmd(CMD_GETDAILYCORREFS, {
      sid: sid,
      docid: docid
    }, onerr,
      function (dta) {
        let ret = new Array<DailyCorRefInfo>();
        for (let lnk of dta.links) ret.push({ 
          dailycorr_id: lnk.dailycorr_id, 
          linkdate: IWFObject.ParseDate(lnk.linkdate), 
          dailycorrclass_id: lnk.dailycorrclass_id, 
          opdate: IWFObject.ParseDate(lnk.opdate), 
          clsname: lnk.clsname });

        onsucc(ret);
      });
  }

  public getSignatureInfo(sig: string, onerr: EventAdp<() => void>, onsucc: (sessid: string, uname: string, sig: WFSignature) => void): void {
    this.sendCmd(CMD_GETSIGNATUREINFO, {
      sig: sig
    }, onerr,
      function (dta) {
        if(dta.result > 0)
          onsucc(dta.sessid, dta.uname, new WFSignature(dta.osig));
        else
          onsucc(null, null, null);
      });
  }

  public getVersion(onerr: EventAdp<() => void>, onsucc: (model_ver: string, ds_ver: string) => void): void {
    this.sendCmd(CMD_GETVERSION, { }, onerr,
      function (dta) {
        onsucc(dta.model_ver, dta.ds_ver);
      });
  }

  public getOcrLogs(sid: string, docid: number, top: number, onerr: EventAdp<() => void>, onsucc: (links: Array<WFOcrProcessLog>) => void): void {
    this.sendCmd(CMD_GETOCRLOGS, {
      sid: sid,
      docid: docid,
      top: top
    }, onerr,
      function (dta) {
        let ret = new Array<WFOcrProcessLog>();
        for (let lnk of dta.ret) ret.push(new WFOcrProcessLog(lnk));
        onsucc(ret);
      });
  }

  public confirmOcrLogs(sid: string, docid: number, ids: Array<number>,  onerr: EventAdp<() => void>, onsucc: (dlogs: number) => void): void {
    this.sendCmd(CMD_CONFIRMOCRLOGS, {
      sid: sid,
      docid: docid,
      ids: ids
    }, onerr,
      function (dta) {
        onsucc(dta.dlogs);
      });
  }

  public getPrintAsyncTasks(sid: string, taskid: string, onerr: EventAdp<() => void>, onsucc: (tasks: Array<PrintAsyncTasksInfo>) => void): void {
    this.sendCmd(CMD_GETPRINTASYNCTASKS, {
      sid: sid,
      taskid: taskid
    }, onerr,
      function (dta) {
        let ret= new Array<PrintAsyncTasksInfo>();
        for(let tsk of dta.tasks)
          ret.push({
            start: IWFObject.ParseDate(tsk.start),
            rows:  tsk.rows,
            link: tsk.link,
            status: tsk.status,
            lasterr: tsk.lasterr,
            percent: tsk.percent 
          });
        onsucc(ret);
      });
  }

  public addPrintAsyncTasks(sid: string, taskid: string, report: any, colformat: any, type: number, docidcol: string, cntonerror: boolean, onerr: EventAdp<() => void>, onsucc: (task: PrintAsyncTasksInfo) => void): void {
    this.sendCmd(CMD_ADDPRINTASYNCTASKS, {
      sid: sid,
      taskid: taskid,
      report: report,
      colformat: colformat,
      type: type,
      docidcol: docidcol,
      cntonerror: cntonerror
    }, onerr, 
    function (dta) {
      let tsk= dta.task;
      onsucc({
        start: IWFObject.ParseDate(tsk.start),
        rows:  tsk.rows,
        link: tsk.link,
        status: tsk.status,
        lasterr: tsk.lasterr,
        percent: tsk.percent   
      });
    });
  }

  public checkUnread(sid: string, onerr: EventAdp<() => void>, onsucc: (add: number) => void): void {
    this.sendCmd(CMD_CHECKUNREAD, {
      sid: sid
    }, onerr, 
    function (dta) {
      onsucc(dta.aff);
    });
  }

  public fetchMails(sid: string, foldername: string, page: number, perpage: number, keywords: string, 
                    login:string, encpass: string, remember: boolean, only_unread: boolean, only_unbind: boolean,
                  onerr: EventAdp<() => void>, onsucc: (mails: Array<MailMessageInfo>, count: number, unread:number) => void): void {
    this.sendCmd(CMD_FETCHMAILS, {
      sid: sid,
      foldername: foldername,
      page: page,
      perpage: perpage,
      keywords: keywords,
      login: login,
      encpass: encpass,
      remember: remember,
      only_unread: only_unread,
      only_unbind: only_unbind
    }, onerr, 
    function (dta) {
      let mails= new Array<MailMessageInfo>();
      for(let msg of dta.ret) {
        mails.push({
          uniqueid: msg.uniqueid,
          subject: msg.subject,
          preview: msg.preview,
          date: IWFObject.ParseDate(msg.date),
          from: msg.from,
          attachments: msg.attachments,
          documents_id: (msg.documents_id) ? msg.documents_id : [],
          seen: msg.seen 
        });
      }

      onsucc(mails, dta.count, dta.unread);
    });
  }

  public previewMail(sid: string, foldername: string, uniqueid: number, 
                    login:string, encpass: string,
                    onerr: EventAdp<() => void>, onsucc: (msg: MailMsgPreview) => void): void {
    this.sendCmd(CMD_PREVIEWMAIL, {
      sid: sid,
      foldername: foldername,
      uniqueid: uniqueid,
      login: login,
      encpass: encpass
    }, onerr, 
    function (dta) {      
      let msg= dta.message;
      onsucc({
        subject: msg.subject,
        html: msg.html,
        txt: msg.txt,
        from: msg.from,
        to: msg.to,
        attachments: msg.attachments   
      });

      /*let mails= new Array<MailMessageInfo>();
      for(let msg of dta.ret) {
        mails.push({
          uniqueid: msg.uniqueid,
          subject: msg.subject,
          preview: msg.preview,
          date: IWFObject.ParseDate(msg.date),
          from: msg.from 
        });
      }

      onsucc(mails, dta.count);*/
    });
  }

  public prepareAttachment(sid: string, foldername: string, uniqueid: number, contentid: string, 
                            login:string, encpass: string,
                            onerr: EventAdp<() => void>, onsucc: (sig: string) => void): void {
    this.sendCmd(CMD_PREPAREATTACHMENT, {
      sid: sid,
      foldername: foldername,
      uniqueid: uniqueid,
      contentid: contentid,
      login: login,
      encpass: encpass
    }, onerr, 
    function (dta) {
      onsucc(dta.signature);
    });
  }

  public createDocumentFromMail(sid: string, foldername: string, uniqueid: number, contentids: Array<object>, 
                                  login:string, encpass: string,
                                  onerr: EventAdp<() => void>, onsucc: (docs: Array<WFDocument>) => void): void {
    this.sendCmd(CMD_CREATEDOCUMENTFROMMAIL, {
      sid: sid,
      foldername: foldername,
      uniqueid: uniqueid,
      contentids: contentids,
      login: login,
      encpass: encpass
    }, onerr, 
    function (dta) {
      let ret= new Array<WFDocument>();      
      for(let dc of dta.docs)
        ret.push(new WFDocument(dc));

      onsucc(ret);
    });
  }

  public markSeenFlag(sid: string, foldername: string, uniqueids: Array<number>, seen: boolean, 
                      login:string, encpass: string,
                      onerr: EventAdp<() => void>, onsucc: () => void): void {
    this.sendCmd(CMD_MARKSEENFLAG, {
      sid: sid,
      foldername: foldername,
      uniqueids: uniqueids,
      seen: seen,
      login: login,
      encpass: encpass
    }, onerr, 
    function () {
      onsucc();
    });
  }

  public refMailFolders(sid: string, onerr: EventAdp<() => void>, onsucc: (mailfolders: Array<any>) => void): void {
    this.sendCmd(CMD_REFMAILFOLDERS, {
      sid: sid
    }, onerr, 
    function (dta) {
        onsucc(dta.mailfolders);
    });
  }

  public listEDocuments(sid: string, pagenum: number, config: DocumentInterfaceConfig, keywords: string, only_unbind: boolean, onerr: EventAdp<() => void>, onsucc: (rows: Array<[InvoiceHeader,Array<number>]>, rowcount: number) => void): void {
    this.sendCmd(CMD_LISTEDOCUMENTS, {
        sid: sid,
        pagenum: pagenum,
        config: config.ToObject(),
        keywords: keywords,
        only_unbind: only_unbind
    }, onerr, 
    function (dta) {
      let rows= new Array<[InvoiceHeader,Array<number>]>();
      if(dta.rowcount > 0) {
        
        for(let rw of dta.rows) {
          //bez konwersji
          let fdc= dta.docs.find((t)=> t.key== rw.KsefReferenceNumber);
          rows.push([rw,(fdc) ? fdc.values : null]);
          /*rows.push({

          });*/
        }
      }

      onsucc(rows, dta.rowcount);
    });
  }

  public previewEDocument(sid: string, config: DocumentInterfaceConfig, uniqueid: string, onerr: EventAdp<() => void>, onsucc: (htmlctx: string) => void): void {
    this.sendCmd(CMD_PREVIEWEDOCUMENT, {
        sid: sid,
        config: config.ToObject(),
        uniqueid: uniqueid
    }, onerr, 
    function (dta) {
      onsucc(dta.htmlctx);
    });
  }

  public createDocumentFromEDoc(sid: string, config: DocumentInterfaceConfig, uniqueid: string, onerr: EventAdp<() => void>, onsucc: (doc: WFDocument) => void): void {
    this.sendCmd(CMD_CREATEDOCUMENTFROMEDOC, {
        sid: sid,
        config: config.ToObject(),
        uniqueid: uniqueid
    }, onerr, 
    function (dta) {
      onsucc(new WFDocument(dta.doc));
    });
  }
}

import { WFCommentEntry } from './WFCommentEntry';

export class WFPageComment {
    private m_id: number;
    private m_users_id: number;
    private m_pos_x: number;
    private m_pos_y: number;
    private m_width: number;
    private m_height: number;
    private m_entries: Array<WFCommentEntry>;

    //

    public static Create(usrid: number, rposx: number, rposy: number, rwidth: number, rheight: number): WFPageComment {
        return new WFPageComment({
            id: 0,
            users_id: usrid,
            pos_x: rposx,
            pos_y: rposy,
            width: rwidth,
            height: rheight,
            entries: []
        });
    }

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_users_id = obj.users_id;
        this.m_pos_x = obj.pos_x;
        this.m_pos_y = obj.pos_y;
        this.m_width = obj.width;
        this.m_height = obj.height;
        this.m_entries = new Array<WFCommentEntry>();
        for (let ent of obj.entries) this.m_entries.push(new WFCommentEntry(ent));
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get UserID(): number {
        return this.m_users_id;
    }

    //

    public set PosX(value: number) {
        this.m_pos_x = value;
    }

    public get PosX(): number {
        return this.m_pos_x;
    }

    //

    public set PosY(value: number) {
        this.m_pos_y = value;
    }

    public get PosY(): number {
        return this.m_pos_y;
    }

    //

    public set Width(value: number) {
        this.m_width = value;
    }

    public get Width(): number {
        return this.m_width;
    }

    //

    public set Height(value: number) {
        this.m_height = value;
    }

    public get Height(): number {
        return this.m_height;
    }

    //

    public get Entries(): Array<WFCommentEntry> {
        return this.m_entries;
    }

    //

    public ToObject(): any {
        let entries = new Array<any>();
        for (let ent of this.m_entries) entries.push(ent.ToObject());

        return {
            id: this.m_id,
            users_id: this.m_users_id,
            pos_x: this.m_pos_x,
            pos_y: this.m_pos_y,
            width: this.m_width,
            height: this.m_height,
            entries: entries
        };
    }
}
import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { WFMessage } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { _OnError, WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent {
  public m_busy_str: string;
  private m_onerrorevh: EventAdp<_OnError>;

  public m_recipiens_iserror: boolean;
  public m_recipiens: string;
  public m_subject_iserror: boolean;
  public m_subject: string;
  public m_content_iserror: boolean;
  public m_content: string;

  @Output() public onClosed: EventEmitter<boolean>;

  public m_doc: WFDocumentAdp;

  // 

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {

    this.m_busy_str = '';

    this.m_recipiens_iserror = false;
    this.m_recipiens = '';
    this.m_subject_iserror = false;
    this.m_subject = '';
    this.m_content_iserror = false;
    this.m_content = '';
    this.m_doc = null;

    this.onClosed = new EventEmitter<boolean>();

    const self = this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });
  }

  public SetPrms(doc: WFDocumentAdp): void {
    this.m_doc = doc;
  }

  public ClearFormat(): void {

  }

  public BoldFormat(): void {

  }

  public ItalicFormat(): void {

  }

  public SendDocument(): void {
    let ii: number;

    try {
      let dosend = true;

      let subject = this.m_subject.trim();
      if (subject.length > 0) {
        this.m_subject_iserror = false;
      } else {
        this.m_subject_iserror = true;
        dosend = false;
      }

      let recipiens = this.m_recipiens.trim();

      let arecipiens = recipiens.split(';');
      let _arecipiens = new Array<string>();

      let rx: RegExp = new RegExp('^[0-9a-zA-Z._-]+@[0-9a-zA-Z._-]+$');

      if (arecipiens.length > 0) {
        this.m_recipiens_iserror = false;
        for (ii = 0; ii < arecipiens.length; ii++) {
          let email = arecipiens[ii].trim();
          if (rx.test(email)) {
            _arecipiens.push(email);
          } else {
            this.m_recipiens_iserror = true;
            dosend = false;
            break;
          }
        }
      } else {
        this.m_recipiens_iserror = true;
        dosend = false;
      }

      if (dosend) {
        let html = this.m_content;
        /*BlockCollection blcks = m_content.Blocks;

        for (ii = 0; ii < blcks.Count; ii++)
        {
            Paragraph pr = (Paragraph)blcks.ElementAt(ii);

            switch (pr.TextAlignment)
            {
                case TextAlignment.Left: html += "<p align=\"left\">"; break;
                case TextAlignment.Center: html += "<p align=\"center\">"; break;
                case TextAlignment.Right: html += "<p align=\"right\">"; break;
                case TextAlignment.Justify: html += "<p align=\"justify\">"; break;
            }

            html += GetLine(pr.Inlines); 
            html += "</p>";
        }*/

        const self = this;
        this.m_data_srv.sendDocumentByEMail(this.m_cache_srv.SessionID, this.m_doc.ID, subject, _arecipiens, html, this.m_onerrorevh, (iret) => {
          self.SendDocumentByEMailCompleted(iret);
        });
        this.m_busy_str = this.m_strdata_srv.getStr('strWaitSendingMessage');
      } else {
        this.m_global_srv.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wSendEMail_EnterCorrectData'));
      }

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private SendDocumentByEMailCompleted(iret: number): void {
    try {
      switch (iret) {
        case 0:
          this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strMessageSent'));
          this.onClosed.emit(true);
          break;
        case 100:
          //todo
          break;

        default:
          this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('errMessageSending'));
          break;
      }
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_str = '';
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

import { Injectable } from '@angular/core';
import { DataService, FindDocumentPrms } from '../data/data.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService } from '../global/global.service';
import { IWFObject, WFDocStatus, WFClass, WFCompany, WFUser, WFUserGroup, WFAuthSchema, WFReconSchema, WFOcrDefinition, WFSystem, WFConfiguration, WFCompanyDict, WFCustomProcess, WFDictionary, WFGroupRole, WFInteractiveDictionary, WFDailyCorrespondenceClass, WFDocument, WFDirectory } from '../model/index';
import { WFInteractiveDictAdp } from '../adapters/WFInteractiveDictAdp';
import { WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { WFMessageAdp } from '../adapters/WFMessageAdp';
import { WFDailyCorrespondenceClassAdp } from '../adapters/WFDailyCorrespondenceClassAdp';
import { DocumentList } from './documentlist';
import { StringDataService } from '../stringdata/stringdata.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { FDOC_DOCSTATUS_TEMPLATE } from '../app.component';
import { WFMailFolderAdp } from '../adapters/WFMailFolderAdp';
import { WFDirectoryAdp } from '../adapters/WFDirectoryAdp';
import { WFDocumentInterfaceAdp } from '../adapters/WFDocumentInterfacAdp';

@Injectable()
export class CacheService {
  private m_sid: string;
  private m_user: WFUser;
  private m_mailfolders: Array<WFMailFolderAdp>; 
  private m_docinterfacecfgs: Array<WFDocumentInterfaceAdp>;

  //loginwnd
  private m_users: Map<number, WFUser>;
  private m_reconschemas: Map<string, WFReconSchema>;
  private m_definitions: Map<string, WFOcrDefinition>;
  private m_dicts: Map<number, WFInteractiveDictAdp>;

  private m_doc_cache: Map<number, WFDocumentAdp>;
  private m_msg_cache: Map<number, WFMessageAdp>;

  private m_system: WFSystem;

  private m_cfg: WFConfiguration;
  private m_companydcs: Map<number, WFCompanyDict>;
  private m_processes: Map<number, WFCustomProcess>;

  private m_dailycorrclasses: Map<number, WFDailyCorrespondenceClassAdp>;

  private m_nuniqid: Array<[number, number, number]>;

  private m_model_ver: string;
  private m_ds_ver: string;

  private m_directories: Map<number, WFDirectoryAdp>;

  private m_infobarstate: boolean;
  private m_selected_tab: number;

  //

  constructor(private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_str_srv: StringDataService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_sid = '';
    this.m_user = null;
    this.m_mailfolders = new Array<WFMailFolderAdp>();
    this.m_docinterfacecfgs = new Array<WFDocumentInterfaceAdp>();

    this.m_users = new Map<number, WFUser>();
    this.m_reconschemas = new Map<string, WFReconSchema>();
    this.m_definitions = new Map<string, WFOcrDefinition>();
    this.m_dicts = new Map<number, WFInteractiveDictAdp>();

    this.m_doc_cache = new Map<number, WFDocumentAdp>();
    this.m_msg_cache = new Map<number, WFMessageAdp>();

    this.m_system = new WFSystem(
      new Map<number, WFClass>(),
      new Map<number, WFUser>(),
      new Map<number, WFCompany>(),
      new Map<number, WFAuthSchema>(),
      new Map<number, WFUserGroup>(),
      new Map<number, WFDictionary>(),
      null);

    this.m_cfg = null;
    this.m_companydcs = new Map<number, WFCompanyDict>();
    this.m_processes = new Map<number, WFCustomProcess>();

    this.m_dailycorrclasses = new Map<number, WFDailyCorrespondenceClassAdp>();
    this.m_nuniqid = new Array<[number, number, number]>();

    this.m_model_ver = "";
    this.m_ds_ver = "";

    this.m_directories= new Map<number, WFDirectoryAdp>();

    this.m_infobarstate= false;
    this.m_selected_tab= 0;
  }

  //

  public get ModelVersion(): string {
    return this.m_model_ver;
  }

  public get DataServiceVersion(): string {
    return this.m_ds_ver;
  }

  public get DataService(): DataService {
    return this.m_data_srv;
  }

  public get GlobalService(): GlobalService {
    return this.m_global_srv;
  }

  public get StringService(): StringDataService {
    return this.m_str_srv;
  }

  public get WndService(): ChildWindowContainerService {
    return this.m_wnd_srv;
  }

  //

  public get SessionID(): string {
    return this.m_sid;
  }

  public set User(value: WFUser) {
    if (this.m_user !== value)
      this.m_user = value;
  }

  public get User(): WFUser {
    return this.m_user;
  }

  public get Classes(): Map<number, WFClass> {
    return this.m_system.AllClasses;
  }

  public get Companies(): Map<number, WFCompany> {
    return this.m_system.AllCompanies;
  }

  public get Users(): Map<number, WFUser> {
    return this.m_users;
  }

  public get UserGroups(): Map<number, WFUserGroup> {
    return this.m_system.AllUserGroups;
  }

  public get AuthSchemas(): Map<number, WFAuthSchema> {
    return this.m_system.AllSchemas;
  }

  public get AppSystem(): WFSystem {
    return this.m_system;
  }

  public get DailyCorrepondenceClasses(): Map<number, WFDailyCorrespondenceClassAdp> {
    return this.m_dailycorrclasses;
  }

  public get Directories(): Map<number, WFDirectoryAdp> {
    return this.m_directories;
  }

  public GetDocument(docid: number): WFDocumentAdp {
    return this.m_doc_cache.get(docid);
  }

  public AddDocument(adp: WFDocumentAdp): void {
    this.m_doc_cache.set(adp.ID, adp);
  }

  public ClearDocuments(exceptions: Array<WFDocumentAdp>): void {
    let ndcache: Map<number, WFDocumentAdp> = new Map<number, WFDocumentAdp>();

    for (let adp of Array.from(this.m_doc_cache.values())) {
      if (adp.DocStatus === WFDocStatus.TEMPLATE) {
        ndcache.set(adp.ID, adp);
        continue;
      }
      if (exceptions != null) {
        if (exceptions.indexOf(adp) >= 0) ndcache.set(adp.ID, adp);
      }
    }

    this.m_doc_cache = ndcache;
  }

  public RemoveDocument(docid: number): void {
    this.m_doc_cache.delete(docid);
  }

  public ContainsDocument(docid: number): boolean {
    return this.m_doc_cache.has(docid);
  }

  public GetDocumentsCount(): number {
    return this.m_doc_cache.size;
  }

  public GetDocumentByOff(off: number): WFDocumentAdp {
    return IWFObject.ElementAt(this.m_doc_cache.values(), off);
  }

  /*public get Fileinfolist(): Array<FileInfo> {
    if (this.m_fileinfolist == null)
      this.m_fileinfolist = new List<FileInfo>();
    return this.m_fileinfolist;
  }

  public set Fileinfolist(value: List<FileInfo>) {
    this.m_fileinfolist = value;
  }*/

  public get MsgCache(): Map<number, WFMessageAdp> {
    return this.m_msg_cache;
  }

  public get ReconSchemas(): Map<string, WFReconSchema> {
    return this.m_reconschemas;
  }

  public get Definitions(): Map<string, WFOcrDefinition> {
    return this.m_definitions;
  }

  public get Dictionaries(): Map<number, WFInteractiveDictAdp> {
    return this.m_dicts;
  }

  public get StaticDictionaries(): Map<number, WFDictionary> {
    return this.m_system.AllDictionaries;
  }

  public get Config(): WFConfiguration {
    return this.m_cfg;
  }

  public get CompanyDicts(): Map<number, WFCompanyDict> {
    return this.m_companydcs;
  }

  public get CustomProcesses(): Map<number, WFCustomProcess> {
    return this.m_processes;
  }

  public get IsSuperUser(): boolean {
    for (let ugr of Array.from(this.m_system.AllUserGroups.values())) {
      if (ugr.GroupRole === WFGroupRole.SUPERUSERS) {
        if (ugr.Users.indexOf(this.m_user.ID) >= 0)
          return true;
      }
    }
    return false;
  }

  public get NewUniqIDCache(): Array<[number, number, number]> {
    return this.m_nuniqid;
  }

  public get UserMailFolders(): Array<WFMailFolderAdp> {
    return this.m_mailfolders;
  }

  public get DocumentInterfaceConfigs(): Array<WFDocumentInterfaceAdp> {
    return this.m_docinterfacecfgs;
  }

  public get Infobarstate(): boolean {
    return this.m_infobarstate;
  }

  public set Infobarstate(nval: boolean) {
      this.m_infobarstate= nval;
  }

  public get Selected_tab(): number {
      return this.m_selected_tab;
  }

  public set Selected_tab(nval: number) {
      this.m_selected_tab= nval;
  }

  //

  public LoadAll(sid: string, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_nuniqid.splice(0, this.m_nuniqid.length);
      this.m_sid = sid;
      const self = this;
      onstep('strWaitVersion');
      this.m_data_srv.getVersion(onerr,
        (mver, dsver) => {
          self.GetVersionCompleted(mver, dsver, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetVersionCompleted(model_ver: string, ds_ver: string, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_model_ver = model_ver;
      this.m_ds_ver = ds_ver;
      const self = this;
      onstep('strWaitClasses');
      this.m_data_srv.getClassList(this.m_sid, onerr,
        (clss) => {
          self.GetClassListCompleted(clss, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetClassListCompleted(clss: Array<WFClass>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_system.AllClasses.clear();

      for (let cl of clss) this.m_system.AllClasses.set(cl.ID, cl);

      onstep('strWaitCompanies');
      const self = this;
      this.m_data_srv.getCompanyList(this.m_sid, onerr,
        (cmps) => {
          self.GetCompanyListCompleted(cmps, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetCompanyListCompleted(cmps: Array<WFCompany>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_system.AllCompanies.clear();
      this.m_companydcs.clear();

      for (let cm of cmps) {
        this.m_system.AllCompanies.set(cm.ID, cm);
        for (let ii2 = 0; ii2 < cm.Dictionaries.length; ii2++) {
          let cdc: WFCompanyDict = cm.Dictionaries[ii2];
          this.m_companydcs.set(cdc.ID, cdc);
        }
      }

      onstep('strWaitUsers');
      const self = this;
      this.m_data_srv.getUserList(this.m_sid, onerr,
        (usrs) => {
          self.GetUserListCompleted(usrs, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetUserListCompleted(usrs: Array<WFUser>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_users.clear();
      this.m_system.AllUsers.clear();

      for (let us of usrs) {
        this.m_users.set(us.ID, us);
        this.m_system.AllUsers.set(us.ID, us);
      }

      onstep('strWaitUsersGroup');
      const self = this;
      this.m_data_srv.getUserGroupsList(this.m_sid, onerr,
        (grps) => {
          self.GetUserGroupsListCompleted(grps, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetUserGroupsListCompleted(grps: Array<WFUserGroup>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_system.AllUserGroups.clear();

      for (let ug of grps) this.m_system.AllUserGroups.set(ug.ID, ug);

      onstep('strWaitSchemas');
      const self = this;
      this.m_data_srv.getAuthSchemaList(this.m_sid, onerr,
        (shms) => {
          self.GetAuthSchemaListCompleted(shms, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetAuthSchemaListCompleted(shms: Array<WFAuthSchema>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_system.AllSchemas.clear();

      for (let sh of shms) this.m_system.AllSchemas.set(sh.ID, sh);

      onstep('strWaitOCRSchemas');
      const self = this;
      this.m_data_srv.getReconSchemaList(this.m_sid, onerr,
        (rshms) => {
          self.GetReconSchemaListCompleted(rshms, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetReconSchemaListCompleted(shms: Array<WFReconSchema>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_reconschemas.clear();

      for (let sh of shms) this.m_reconschemas.set(sh.Code, sh);

      onstep('strWaitDefinitions');
      const self = this;
      this.m_data_srv.getDefinitionsList(this.m_sid, onerr,
        (defs) => {
          self.GetDefinitionsListCompleted(defs, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetDefinitionsListCompleted(shms: Array<WFOcrDefinition>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_definitions.clear();

      for (let sh of shms) this.m_definitions.set(sh.Code, sh);

      onstep('strWaitDicts');
      const self = this;
      this.m_data_srv.getInteractiveDictsList(this.m_sid, onerr,
        (dicts) => {
          self.GetInteractiveDictsListCompleted(dicts, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetInteractiveDictsListCompleted(shms: Array<WFInteractiveDictionary>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_dicts.clear();

      for (let sh of shms) this.m_dicts.set(sh.ID, new WFInteractiveDictAdp(sh));

      const self = this;
      this.m_data_srv.getDictionariesList(this.m_sid, onerr,
        (dicts) => {
          self.GetDictionariesListCompleted(dicts, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetDictionariesListCompleted(shms: Array<WFDictionary>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_system.AllDictionaries.clear();

      for (let sh of shms) this.m_system.AllDictionaries.set(sh.ID, sh);

      onstep('strWaitCustomProcess');
      const self = this;
      this.m_data_srv.getCustomProcessList(this.m_sid, onerr,
        (dicts) => {
          self.GetCustomProcessListCompleted(dicts, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetCustomProcessListCompleted(shms: Array<WFCustomProcess>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_processes.clear();

      for (let sh of shms) this.m_processes.set(sh.ID, sh);

      onstep('strWaitDailyClass');
      const self = this;
      this.m_data_srv.getDailyCorrespondenceClasses(this.m_sid, onerr,
        (clss) => {
          self.GetDailyCorrespondenceClassesCompleted(clss, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetDailyCorrespondenceClassesCompleted(shms: Array<WFDailyCorrespondenceClass>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_dailycorrclasses.clear();

      for (let sh of shms) this.m_dailycorrclasses.set(sh.ID, new WFDailyCorrespondenceClassAdp(this, sh));

      onstep('strWaitConfig');
      const self = this;
      this.m_data_srv.getConfiguration(this.m_sid, onerr,
        (cfg) => {
          self.GetConfigurationCompleted(cfg, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetConfigurationCompleted(cfg: WFConfiguration, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_cfg = cfg;

      this.m_system = new WFSystem(
        this.m_system.AllClasses,
        this.m_system.AllUsers,
        this.m_system.AllCompanies,
        this.m_system.AllSchemas,
        this.m_system.AllUserGroups,
        this.m_system.AllDictionaries, cfg);

      onstep('strWaitUser');
      const self = this;
      this.m_data_srv.getUser(this.m_sid, onerr,
        (usr, mailfolders, edoccounts) => {
          self.GetUserCompleted(usr, mailfolders, edoccounts, onstep, onerr, onsucc);
        });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetUserCompleted(usr: WFUser, mailfolders: Array<any>, edoccounts:Array<any>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_user = usr;
      this.m_infobarstate= false;
      this.m_selected_tab= 0;
      
      this.m_mailfolders.splice(0, this.m_mailfolders.length);
      for(let mfld of mailfolders) {
        this.m_mailfolders.push(new WFMailFolderAdp(mfld, usr));
      }

      this.m_docinterfacecfgs.splice(0, this.m_docinterfacecfgs.length);
      for(let ii=0; ii< usr.DocumentInterfaceConfigs.length; ii++) {
        let cfg= usr.DocumentInterfaceConfigs[ii];
        let frow= edoccounts.find((t)=> t.int_id == ii);
        this.m_docinterfacecfgs.push(new WFDocumentInterfaceAdp(cfg, (frow) ? frow.count : 0));
      }

      this.m_system.AllUsers.set(usr.ID, usr);

      //onsucc();
      let prms: FindDocumentPrms = {
        sid: this.m_sid,
        companies: [],
        users: [],
        classes: [],
        schemas: [],
        directories: [],
        documents: [],
        fargs: [],
        createdatfrom: null,
        createdatto: null,
        docdatefrom: null,
        docdateto: null,
        flags: FDOC_DOCSTATUS_TEMPLATE,
        refobj: [],
        attvalues: []
      };

      onstep('strWaitTemplates');
      const self = this;
      this.m_data_srv.findDocuments(prms, onerr, (ret) => {
        self.FindDocumentsCompleted(ret, onstep, onerr, onsucc);
      });
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private FindDocumentsCompleted(rows: Array<any>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void): void {
    try {
      this.m_doc_cache.clear();
      this.m_msg_cache.clear();

      if (rows.length > 0) {
        let docids = new Array<number>(rows.length);
        for (let ii = 0; ii < rows.length; ii++) {
          let obj = rows[ii];
          docids[ii] = obj.C0;
        }

        const self = this;
        this.m_data_srv.getDocuments(this.m_sid, docids, false, onerr, (ret, ronly) => {
          self.GetDocumentsCompleted(ret, onstep, onerr, onsucc, ronly);
        });
      } else {
        onsucc();
      }

    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  private GetDocumentsCompleted(docs: Array<WFDocument>, onstep: (stp: string) => void, onerr: EventAdp<() => void>, onsucc: () => void, ronly: Array<number>): void {
    try {
      for (let doc of docs) {
        let adp = new WFDocumentAdp(this, doc, true, ronly.includes(doc.ID));
        this.m_doc_cache.set(doc.ID, adp);
      }

      onsucc();
    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  }

  public RefreshMailFolders(onerr: EventAdp<() => void>, ondone: () => void): void {
    try {

      this.m_data_srv.refMailFolders(this.m_sid, onerr, (mailfolders: Array<any>) => {
        for(let mf of mailfolders) {
          let fmf= this.m_mailfolders.find((t)=> t.Name== mf.fullname);
          if(fmf!= null) fmf.Update(mf);
        }        
        ondone();
      });

    } catch (ex) {
      onerr.call();
      this.m_global_srv.manageException(ex);
    }
  } 
}

import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { EVENTTYPE, IWFObject, WFUser } from '../model';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { DataService } from '../data/data.service';
import { _OnError } from '../adapters/WFDocumentAdp';

interface ColorEntry {
  label: string;
  color: string;
}

interface EventTypeEntry {
  label: string;
  send: boolean;
  caread: boolean;
}

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss']
})

export class UserPanelComponent implements AfterViewInit {
  public m_busy_str: string;

  public m_availablecolors: Array<ColorEntry>;
  public m_eventtype: Array<EventTypeEntry>;

  public m_name: string;
  public m_name_err: boolean;
  public m_email: string;
  public m_email_err: boolean;
  public m_emailtpl: string;
  public m_emailtpl_err: boolean;
  public m_commentcolor: ColorEntry;
  public m_oldpass: string;
  public m_oldpass_err: boolean;
  public m_newpass: string;
  public m_newpass_err: boolean;
  public m_newpass2: string;
  public m_newpass2_err: boolean;

  public m_replacement: boolean;
  public m_replacement_start: Date;
  public m_replacement_start_err: boolean;
  public m_replacement_end: Date;
  public m_replacement_end_err: boolean;
  public m_replacement_user1: string;
  public m_replacement_user1_err: boolean;
  public m_replacement_user2: string;
  public m_replacement_user2_err: boolean;
  public m_language: string;

  public m_dateformat: string;
  private m_othercolor: ColorEntry;
  private m_onerrorevh: EventAdp<_OnError>;

  private m_refafclose: boolean;

  @ViewChild('m_replacement_user1_cmb', {static: false}) private m_replacement_user1_cmb: ComboboxadpComponent;
  @ViewChild('m_replacement_user2_cmb', {static: false}) private m_replacement_user2_cmb: ComboboxadpComponent;
  @ViewChild('m_language_cmb', {static: false}) private m_language_cmb: ComboboxadpComponent;
  @Output() public onClosed: EventEmitter<boolean>;

  //
  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_data_srv: DataService) {

    this.m_busy_str = '';
    let usr = this.m_cache_srv.User;

    this.m_availablecolors = [
      { label: this.m_strdata_srv.getStr('clBlack'), color: '#000000' },
      { label: this.m_strdata_srv.getStr('clBlue'), color: '#0000FF' },
      { label: this.m_strdata_srv.getStr('clBrown'), color: '#A52A2A' },
      { label: this.m_strdata_srv.getStr('clCyan'), color: '#00FFFF' },
      { label: this.m_strdata_srv.getStr('clDarkGray'), color: '#A9A9A9' },
      { label: this.m_strdata_srv.getStr('clGray'), color: '#808080' },
      { label: this.m_strdata_srv.getStr('clGreen'), color: '#008000' },
      { label: this.m_strdata_srv.getStr('clLightGray'), color: '#D3D3D3' },
      { label: this.m_strdata_srv.getStr('clMagenta'), color: '#FF00FF' },
      { label: this.m_strdata_srv.getStr('clOrange'), color: '#FFA500' },
      { label: this.m_strdata_srv.getStr('clPurple'), color: '#800080' },
      { label: this.m_strdata_srv.getStr('clRed'), color: '#FF0000' },
      { label: this.m_strdata_srv.getStr('clWhite'), color: '#FFFFFF' },
      { label: this.m_strdata_srv.getStr('clYellow'), color: '#FFFF00' }
    ];

    this.m_othercolor = null;
    let add = true;
    for (let cl of this.m_availablecolors) {
      if (cl.color.toUpperCase() === usr.CommentColor.toUpperCase()) {
        add = false;
        break;
      }
    }

    if (add) {
      this.m_othercolor = { label: this.m_strdata_srv.getStr('clOther'), color: usr.CommentColor };
      this.m_availablecolors.push(this.m_othercolor);
    }

    let entries: Array<[EVENTTYPE, string]> = [
      [EVENTTYPE.NONE, this.m_strdata_srv.getStr('eventTypeNone')],
      [EVENTTYPE.NEEDAUTH, this.m_strdata_srv.getStr('eventTypeNeedAuth')],
      [EVENTTYPE.APPROVE, this.m_strdata_srv.getStr('eventTypeApprove')],
      [EVENTTYPE.REJECT, this.m_strdata_srv.getStr('eventTypeReject')],
      [EVENTTYPE.NEEDCOMMENT, this.m_strdata_srv.getStr('eventTypeNeedComment')],
      [EVENTTYPE.COMMENTED, this.m_strdata_srv.getStr('eventTypeCommented')],
      [EVENTTYPE.DOCCHANGED, this.m_strdata_srv.getStr('eventTypeDocChanged')],
      [EVENTTYPE.APPROVEDBYALL, this.m_strdata_srv.getStr('eventTypeApprovedByAll')]
    ];

    this.m_eventtype = new Array<EventTypeEntry>(entries.length);
    for (let ent of entries) this.m_eventtype[<number>ent[0]] = {
      label: ent[1],
      send: usr.CanSendEvent(ent[0]),
      caread: usr.MarkAsRead(ent[0])
    };

    this.m_name = usr.Name;
    this.m_name_err = false;
    this.m_email = usr.EMailReply;
    this.m_email_err = false;
    this.m_emailtpl = usr.EMailTemplate;
    this.m_emailtpl_err = false;
    this.m_commentcolor = this.m_availablecolors.find((t) => t.color.toUpperCase() === usr.CommentColor.toUpperCase());
    this.m_oldpass = '';
    this.m_oldpass_err = false;
    this.m_newpass = '';
    this.m_newpass_err = false;
    this.m_newpass2 = '';
    this.m_newpass2_err = false;

    this.m_replacement = usr.Replacement;
    this.m_replacement_start = usr.ReplacementStart;
    this.m_replacement_start_err = false;
    this.m_replacement_end = usr.ReplacementEnd;
    this.m_replacement_end_err = false;
    this.m_replacement_user1 = (usr.ReplacementUserID > 0) ? usr.ReplacementUserID.toString() : '';
    this.m_replacement_user1_err = false;
    this.m_replacement_user2 = (usr.ReplacementUserID2 > 0) ? usr.ReplacementUserID2.toString() : '';
    this.m_replacement_user2_err = false;
    this.m_dateformat = 'dd-MM-yyyy';
    this.m_language = usr.LanguageStr;

    this.onClosed = new EventEmitter<boolean>();

    this.m_onerrorevh = new EventAdp<_OnError>();
    const self = this;
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });

    this.m_refafclose= false;
  }

  public get RefreshAfterClose(): boolean {
    return this.m_refafclose;
  }

  public ngAfterViewInit(): void {
    try {
      this.m_replacement_user1_cmb.AddValidValue('', this.m_strdata_srv.getStr('wUserPanel_SelectUser'));
      this.m_replacement_user2_cmb.AddValidValue('', this.m_strdata_srv.getStr('wUserPanel_SelectUser'));

      let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
      for (let selusr of susr) {
        if (selusr.ID !== this.m_cache_srv.User.ID) {
          let sval = selusr.ID.toString();
          let dsc = selusr.FriendlyName;// (IWFObject.IsNullOrEmpty(selusr.UserName)) ? selusr.Name : selusr.UserName;
          this.m_replacement_user1_cmb.AddValidValue(sval, dsc);
          this.m_replacement_user2_cmb.AddValidValue(sval, dsc);
        }
      }
      this.m_language_cmb.AddValidValue('EN', this.m_strdata_srv.getStr('strLangEN'));
      this.m_language_cmb.AddValidValue('PL', this.m_strdata_srv.getStr('strLangPL'));
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public btColor_Click(): void {
    let ncol = '#' + IWFObject.ToHexByte(Math.floor(Math.random() * 255)) + IWFObject.ToHexByte(Math.floor(Math.random() * 255)) + IWFObject.ToHexByte(Math.floor(Math.random() * 255));
    if (this.m_othercolor == null) {
      this.m_othercolor = { label: this.m_strdata_srv.getStr('clOther'), color: ncol };
      this.m_availablecolors.push(this.m_othercolor);
    } else {
      this.m_othercolor.color = ncol;
    }
    this.m_commentcolor = this.m_othercolor;
  }

  public OKButton_Click(): void {
    try {
      const self = this;
      let usr = new WFUser(this.m_cache_srv.User.ToObject());

      let changed = false;
      let errcount = 0;

      let tmp = this.m_name.trim();
      if (usr.Name !== tmp) {
        try {
          usr.Name = tmp;
          this.m_name_err = false;
          changed = true;
        } catch (ex) {
          this.m_name_err = true;
          errcount++;
        }
      }

      //

      tmp = this.m_email.trim();
      if (usr.EMailReply !== tmp) {
        try {
          usr.EMailReply = tmp;
          this.m_email_err = false;
          changed = true;
        } catch (ex) {
          this.m_email_err = true;
          errcount++;
        }
      }

      //

      tmp = this.m_emailtpl.trim();
      if (usr.EMailTemplate !== tmp) {
        try {
          usr.EMailTemplate = tmp;
          this.m_emailtpl_err = false;
          changed = true;
        } catch (ex) {
          this.m_emailtpl_err = true;
          errcount++;
        }
      }

      //

      if (usr.CommentColor !== this.m_commentcolor.color) {
        usr.CommentColor = this.m_commentcolor.color;
        changed = true;
      }

      //zmiana hasla
      this.m_oldpass_err = this.m_newpass_err = this.m_newpass2_err = false;

      if (this.m_oldpass.length > 0) {
        if ((this.m_newpass.length > 0) &&
          (this.m_newpass2.length > 0)) {
          if (this.m_newpass === this.m_newpass2) {
            this.m_busy_str = this.m_strdata_srv.getStr('strChangePassword');

            let stamp = new Date();
            this.m_data_srv.changeUserPassword(this.m_cache_srv.SessionID,
              IWFObject.EncryptPassword(this.m_oldpass, stamp),
              IWFObject.EncryptPassword(this.m_newpass, stamp), this.m_onerrorevh, (ret) => {
                self.ChangeUserPasswordCompleted(ret, usr);
              });
            return;
          }
        }

        this.m_oldpass_err = this.m_newpass_err = this.m_newpass2_err = true;
        errcount++;
      }

      //
      let nrep: boolean;

      if (this.m_replacement) {
        if (this.m_replacement_start == null) {
          this.m_replacement_start_err = true;
          errcount++;
        } else {
          this.m_replacement_start_err = false;
        }

        if (this.m_replacement_end == null) {
          this.m_replacement_end_err = true;
          errcount++;
        } else {
          this.m_replacement_end_err = false;
        }

        //

        if (this.m_replacement_start != null && this.m_replacement_end != null) {
          if (this.m_replacement_end < this.m_replacement_start) {
            this.m_replacement_start_err = this.m_replacement_end_err = true;
            errcount++;
          } else {
            this.m_replacement_start_err = this.m_replacement_end_err = false;
            if (usr.ReplacementStart !== this.m_replacement_start) {
              usr.ReplacementStart = this.m_replacement_start;
              changed = true;
            }

            if (usr.ReplacementEnd !== this.m_replacement_end) {
              usr.ReplacementEnd = this.m_replacement_end;
              changed = true;
            }
          }
        }

        let nval: number;
        if (IWFObject.IsNullOrEmpty(this.m_replacement_user1)) {
          this.m_replacement_user1_err = true;
          errcount++;
        } else {
          this.m_replacement_user1_err = false;
          nval = Number.parseInt(this.m_replacement_user1);
          if (usr.ReplacementUserID !== nval) {
            usr.ReplacementUserID = nval;
            changed = true;
          }
        }

        if (IWFObject.IsNullOrEmpty(this.m_replacement_user2)) {
          nval = 0;
        } else {
          nval = Number.parseInt(this.m_replacement_user2);
        }

        if (nval !== usr.ReplacementUserID2) {
          usr.ReplacementUserID2 = nval;
          changed = true;
        }

        nrep = true;
      } else {
        nrep = false;
      }

      if (nrep !== usr.Replacement) {
        usr.Replacement = nrep;
        changed = true;
      }

      if (this.m_language != usr.LanguageStr) {
        usr.LanguageStr = this.m_language;
        changed = true;
      }

      //

      if (errcount > 0) {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('errWrongDataInForm'));
        return;
      }

      //

      for (let ii = 0; ii < this.m_eventtype.length; ii++) {
        let tp = <EVENTTYPE>ii;

        if (this.m_eventtype[ii].send) {
          if (!usr.CanSendEvent(tp)) {
            usr.SetEventRestriction(tp, true);
            changed = true;
          }
        } else {
          if (usr.CanSendEvent(tp)) {
            usr.SetEventRestriction(tp, false);
            changed = true;
          }
        }

        if (this.m_eventtype[ii].caread) {
          if (!usr.MarkAsRead(tp)) {
            usr.SetMarkAsRead(tp, true);
            changed = true;
          }
        } else {
          if (usr.MarkAsRead(tp)) {
            usr.SetMarkAsRead(tp, false);
            changed = true;
          }
        }
      }

      if (changed) {
        this.m_busy_str = this.m_strdata_srv.getStr('strDataUpdate');
        this.m_data_srv.modifyUser(this.m_cache_srv.SessionID, usr, this.m_onerrorevh, (ret) => {
          self.ModifyUserCompleted(ret);
        });
        return;
      }

      this.onClosed.emit(true);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ChangeUserPasswordCompleted(ret: boolean, usr: WFUser): void {
    try {
      if (ret) {
        const self = this;
        this.m_busy_str = this.m_strdata_srv.getStr('strDataUpdate');
        this.m_data_srv.modifyUser(this.m_cache_srv.SessionID, usr, this.m_onerrorevh, (rett) => {
          self.ModifyUserCompleted(rett);
        });
        return;
      }
      this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('errCannotChangePassword'));
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_str = '';
  }

  private ModifyUserCompleted(res: WFUser): void {
    try {
      this.m_cache_srv.User = res;
      this.onClosed.emit(true);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_str = '';
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  //

  public CheckUnread_Click(): void {
    try {
      const self = this;
      this.m_busy_str = this.m_strdata_srv.getStr('strDataUpdate');

      this.m_data_srv.checkUnread(this.m_cache_srv.SessionID, this.m_onerrorevh, (aff) => {
        self.CheckUnreadCompleted(aff);
      });
      
      return;

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_str = '';
  }

  private CheckUnreadCompleted(aff)
  {
    this.m_refafclose= true;
    this.m_cache_srv.MsgCache.clear();
    this.m_global_srv.showInfo(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('strCheckUnreadCompleted'), aff));
    this.m_busy_str = '';
  }
  
}

import { WFObjType } from './WFSchemaRecipient';
import { WFAuthMethodType } from './WFAuthSchema';
import { WFSchemaRecipient } from './WFSchemaRecipient';

export enum WFDocAuthVarType {
    TYPE_BASIC,
    TYPE_SCHEMA
}

export abstract class IWFDocumentAuthVariable {
    protected m_type: WFDocAuthVarType;

    constructor(tp: WFDocAuthVarType) {
        this.m_type = tp;
    }

    public get Type(): WFDocAuthVarType {
        return this.m_type;
    }

    public static CreateFromJson(obj: any): IWFDocumentAuthVariable {
        let vtp: WFDocAuthVarType = obj.type;
        switch (vtp) {
            case WFDocAuthVarType.TYPE_BASIC:
                return new WFDocumentAuthVarBasic(obj);
            case WFDocAuthVarType.TYPE_SCHEMA:
                return new WFDocumentAuthVarSchema(obj);
        }
        return null;
    }

    public abstract ToObject(): any;
}

export class WFDocumentAuthVarBasic extends IWFDocumentAuthVariable {
    private m_values: Array<string>;

    public static Create(): WFDocumentAuthVarBasic {
        return new WFDocumentAuthVarBasic({
            values: []
        });
    }

    constructor(obj: any) {
        super(WFDocAuthVarType.TYPE_BASIC);
        this.m_values = obj.values;
    }

    public get Values(): Array<string> {
        return this.m_values;
    }

    public ToObject(): any {
        return {
            type: this.m_type,
            values: this.m_values
        };
    }
}

export class WFAuthVarSchemaRecipient {
    private m_obj_id: number;
    private m_obj_type: WFObjType;
    private m_order: number;

    //

    public static Create(src: WFAuthVarSchemaRecipient): WFAuthVarSchemaRecipient {
        return new WFAuthVarSchemaRecipient({
            obj_id: src.m_obj_id,
            obj_type: src.m_obj_type,
            order: src.m_order
        });
    }

    public static Create2(src: WFSchemaRecipient): WFAuthVarSchemaRecipient {
        return new WFAuthVarSchemaRecipient({
            obj_id: src.ObjID,
            obj_type: src.ObjType,
            order: src.Order
        });
    }

    constructor(obj: any) {
        this.m_obj_id = obj.obj_id;
        this.m_obj_type = obj.obj_type;
        this.m_order = obj.order;
    }

    //

    public get ObjID(): number {
        return this.m_obj_id;
    }

    //

    public get ObjType(): WFObjType {
        return this.m_obj_type;
    }

    //

    public get Order(): number {
        return this.m_order;
    }

    //

    public ToObject(): any {
        return {
            obj_id: this.m_obj_id,
            obj_type: this.m_obj_type,
            order: this.m_order
        };
    }
}

export class WFDocumentAuthVarSchema extends IWFDocumentAuthVariable {
    private m_shmtype: WFAuthMethodType;
    private m_userlimit: number;
    private m_recipients: Array<WFAuthVarSchemaRecipient>;

    //

    public static Create(): WFDocumentAuthVarSchema {
        return new WFDocumentAuthVarSchema({
            shmtype: WFAuthMethodType.AUTH_ONEOF,
            userlimit: 1,
            recipients: []
        });
    }

    constructor(obj: any) {
        super(WFDocAuthVarType.TYPE_SCHEMA);
        this.m_shmtype = obj.shmtype;
        this.m_userlimit = obj.userlimit;
        this.m_recipients = new Array<WFAuthVarSchemaRecipient>();
        for (let rc of obj.recipients) this.m_recipients.push(new WFAuthVarSchemaRecipient(rc));
    }

    //

    public set SchemaType(value: WFAuthMethodType) {
        this.m_shmtype = value;
    }

    public get SchemaType(): WFAuthMethodType {
        return this.m_shmtype;
    }

    //

    public set AuthUserLimit(value: number) {
        if (value < 1) throw new Error();
        this.m_userlimit = value;
    }

    public get AuthUserLimit(): number {
        return this.m_userlimit;
    }

    //

    public get Recipients(): Array<WFAuthVarSchemaRecipient> {
        return this.m_recipients;
    }

    //

    public ToObject(): any {
        let recipients = new Array<any>();
        for (let rc of this.m_recipients) recipients.push(rc.ToObject());

        return {
            type: this.m_type,
            shmtype: this.m_shmtype,
            userlimit: this.m_userlimit,
            recipients: recipients
        };
    }
}


import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { AttachmentsData } from '../app.component';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { AddNewDocMenuComponent } from '../add-new-doc-menu/add-new-doc-menu.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { GlobalService } from '../global/global.service';

@Component({
  selector: 'app-attachments-popup',
  templateUrl: './attachments-popup.component.html',
  styleUrls: ['./attachments-popup.component.scss']
})

export class AttachmentsPopupComponent {
  public m_refelement: any;
  public m_attachments: AttachmentsData;

  @Output() closePopup: EventEmitter<void>;
  @Output() selectAttachment: EventEmitter<WFDocumentAdp>;
  @Output() removeAttachment: EventEmitter<WFDocumentAdp>;

  //

  constructor(private m_wnd_srv: ChildWindowContainerService,
    private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService) {
    this.m_refelement = null;
    this.m_attachments = { atts: [], invdc: [], prns: [] };
    this.closePopup = new EventEmitter<void>();
    this.selectAttachment = new EventEmitter<WFDocumentAdp>();
    this.removeAttachment = new EventEmitter<WFDocumentAdp>();
  }

  public SetPrms(refelement: any, atts: AttachmentsData): void {
    this.m_refelement = refelement;
    this.m_attachments = atts;
  }

  public ShowMenu(trg: Object, doc: WFDocumentAdp): boolean {
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(AddNewDocMenuComponent);
      const inst: AddNewDocMenuComponent = wnd.instance;
      inst.SetPrms(trg);

      inst.AddEntry(this.m_strdata_srv.getStr('strAttDel'), 0, 'cmn_minus.svg');

      inst.closePopup.subscribe(() => {
        wnd.destroy();
      });

      inst.runCommand.subscribe((ret) => {
        wnd.destroy();
        self.removeAttachment.emit(doc);
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    return false;
  }
}

export enum WFOcrFldType {
    TEXT,
    NUMBER,
    DATETIME,
    CURRENCY,
    TABLE
}

export class WFOcrColumn {
    private m_id: number;
    private m_name: string;
    private m_type: WFOcrFldType;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_type = obj.type;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Type(): WFOcrFldType {
        return this.m_type;
    }

    //

    public ToObject(): any {
        return {
            id: this.m_id,
            name: this.m_name,
            type: this.m_type
        };
    }
}
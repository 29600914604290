import { WFCompanyDict } from './WFCompanyDict';
import { WFPermission, WFPrmObjType, WFPropType } from './WFPermission';
import { WFPermissionInfo } from './WFDocument';
import { WFSystem } from './WFSystem';
import { WFUserGroup } from './WFUserGroup';
import { IWFObject } from './IWFObject';

export enum WFBusinessPartnerDB {
    INTERNAL,
    EXTERNAL_SOAP
}

export class WFCompany {
    private m_id: number;
    private m_company: string;
    private m_dbname: string;
    private m_createdat: Date;
    private m_updatedat: Date;
    //private m_def_directories: Map<number, number>;
    private m_directories_id: number; //domyslny id folderu
    private m_dicts: Array<WFCompanyDict>;

    private m_perms: Array<WFPermission>;
    private m_organization_id: number;

    private m_businesspartnerdb: WFBusinessPartnerDB;

    private m_global_id: string;
    private m_taxid: string;

    //
    private m_allowedclasses: Array<number>;
    
    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_company = obj.company;
        this.m_dbname = obj.dbname;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);

        //this.m_def_directories = new Map<number, number>();
        //for (let kv of obj.def_directories) this.m_def_directories.set(kv.key, kv.value);

        this.m_directories_id = obj.directories_id; //domyslny id folderu
        this.m_dicts = new Array<WFCompanyDict>();
        for (let dc of obj.dicts) this.m_dicts.push(new WFCompanyDict(dc));

        this.m_perms = new Array<WFPermission>();
        for (let prm of obj.perms) this.m_perms.push(new WFPermission(prm));

        this.m_organization_id = obj.organization_id;
        this.m_businesspartnerdb = obj.businesspartnerdb;

        this.m_global_id = obj.global_id;
        this.m_taxid = obj.taxid;
        this.m_allowedclasses = obj.allowedclasses;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Company(): string {
        return this.m_company;
    }

    //

    public get DBName(): string {
        return this.m_dbname;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    //

    /*public get DefDirectories(): Map<number, number> {
        return this.m_def_directories;
    }*/

    //

    public get DirectoryID(): number {
        return this.m_directories_id;
    }

    //

    public get Dictionaries(): Array<WFCompanyDict> {
        return this.m_dicts;
    }

    //

    public get Permissions(): Array<WFPermission> {
        return this.m_perms;
    }

    private ActualizePermissionInfoList(lst: Array<WFPermissionInfo>, prm: WFPermission, sys: WFSystem): void {
        switch (prm.ObjType) {
            case WFPrmObjType.USER:
                lst.push(new WFPermissionInfo(prm.ObjID, -1, prm.PermissionType));
                break;
            case WFPrmObjType.USERGROUP:
                if (prm.ObjID > 0) {
                    let ugr: WFUserGroup = sys.AllUserGroups.get(prm.ObjID);
                    for (let uid of ugr.Users) {
                        lst.push(new WFPermissionInfo(uid, prm.ObjID, prm.PermissionType));
                    }
                } else {
                    for (let usr of Array.from(sys.AllUsers)) {
                        lst.push(new WFPermissionInfo(usr[1].ID, WFUserGroup.ALLUSERS, prm.PermissionType));
                    }
                }
                break;
        }
    }

    public ListPermissions(ptp: WFPropType, sys: WFSystem): Array<WFPermissionInfo> {
        let props = new Array<WFPermissionInfo>();

        for (let prm of this.m_perms) {
            if (prm.PropertyType === ptp) this.ActualizePermissionInfoList(props, prm, sys);
        }

        //niwelacja globalna
        let ret = new Array<WFPermissionInfo>();
        if (props.length > 0) {
            let ids = new Array<number>(props.length);
            let ids_c = 0;

            for (let ii = props.length - 1; ii > -1; ii--) {
                let prmi: WFPermissionInfo = props[ii];
                if (ids.indexOf(prmi.UserID, 0) < 0) {
                    ret.push(prmi);
                    ids[ids_c++] = prmi.UserID;
                }
            }
        }

        return ret;
    }

    //

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    public get BusinessPartnerDB(): WFBusinessPartnerDB {
        return this.m_businesspartnerdb;
    }

    public get GlobalID(): string
    {
        return this.m_global_id;
    }

    public get TaxID(): string
    {
        return this.m_taxid;
    }

    public get AllowedClasses(): Array<number>
    {
        return this.m_allowedclasses;
    }
}

import { WFProcessVarType } from './WFProcessVariable';
import { WFClassAttrib, WFFieldType } from '.';

export enum WFProcessVarColType {
    TYPE_TEXT,
    TYPE_INT,
    TYPE_FLOAT,
    TYPE_DATE
}

export class WFProcessVarColumn {
    private m_name: string;
    private m_type: WFProcessVarColType;

    //

    constructor(obj: any) {
        this.m_name = obj.name;
        this.m_type = obj.type;
    }

    public static Create(src: WFProcessVarColumn): WFProcessVarColumn {
        return new WFProcessVarColumn({
            name: src.m_name,
            type: src.m_type
        });
    }

    public static Create2(nm: string, tp: WFProcessVarColType): WFProcessVarColumn {
        return new WFProcessVarColumn({
            name: nm,
            type: tp
        });
    }

    public static Create3(atr: WFClassAttrib): WFProcessVarColumn {
        let type: WFProcessVarColType;
        switch (atr.Type) {
            case WFFieldType.TYPE_FLOAT:
                type = WFProcessVarColType.TYPE_FLOAT;
                break;
            case WFFieldType.TYPE_INT:
                type = WFProcessVarColType.TYPE_INT;
                break;
            case WFFieldType.TYPE_DATETIME:
                type = WFProcessVarColType.TYPE_DATE;
                break;
            default:
                type = WFProcessVarColType.TYPE_TEXT;
                break;
        }

        return new WFProcessVarColumn({
            name: atr.Name,
            type: type
        });
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    public get Type(): WFProcessVarColType {
        return this.m_type;
    }

    public get VarType(): WFProcessVarType {
        switch (this.m_type) {
            case WFProcessVarColType.TYPE_FLOAT: return WFProcessVarType.TYPE_FLOAT;
            case WFProcessVarColType.TYPE_INT: return WFProcessVarType.TYPE_INT;
            case WFProcessVarColType.TYPE_DATE: return WFProcessVarType.TYPE_DATE;
        }
        return WFProcessVarType.TYPE_TEXT;
    }

    public ToObject(): Object {
        return {
            name: this.m_name,
            type: <number>this.m_type
        };
    }
}
import { Directive, ElementRef, Input, ViewContainerRef } from '@angular/core';
import { UpperMap } from './model';
import { Component } from '@angular/compiler/src/core';

@Directive({
  selector: '[appAutofocus]'
})

export class AutofocusDirective {
  private m_affield: string;

  //
  
  get affield(): string {
    return this.m_affield;
  }

  @Input('affield')
  set affield(val: string) {   
    this.m_affield= val.toUpperCase();
  }

  //

  @Input('affieldmap')
  set affieldmap(mp: Map<string,ViewContainerRef>) {
    mp.set(this.m_affield, this.m_ele);
  }

  constructor(private m_ele: ViewContainerRef) {   
    
  }
}

import { WFProcessVarColumn, WFProcessVarColType } from './WFProcessVarColumn';
import { IWFObject } from './IWFObject';
import { WFProcessVariable, WFProcessVarType } from './WFProcessVariable';

export class WFProcessVarState {
    private m_name: string;
    private m_columns: Array<WFProcessVarColumn>;
    private m_data: Array<Array<Object>>;
    private m_rowcount: number;

    //

    constructor(nm: string, cols: Array<WFProcessVarColumn>, rows: number) {
        let ii: number;
        this.m_name = nm;
        this.m_columns = cols;
        this.m_data = new Array(cols.length);
        for (ii = 0; ii < this.m_columns.length; ii++)
            this.m_data[ii] = new Array<Object>();
        this.m_rowcount = rows;
    }

    public static Create(def: WFProcessVariable): WFProcessVarState {
        let ii: number;
        let columns: Array<WFProcessVarColumn>;

        if (def.Type === WFProcessVarType.TYPE_TABLE) {
            columns = new Array(def.Columns.length);
            for (ii = 0; ii < def.Columns.length; ii++) {
                columns[ii] = WFProcessVarColumn.Create(def.Columns[ii]);
            }
        } else {
            let coltp: WFProcessVarColType = WFProcessVarColType.TYPE_TEXT;
            switch (def.Type) {
                case WFProcessVarType.TYPE_FLOAT:
                    coltp = WFProcessVarColType.TYPE_FLOAT;
                    break;
                case WFProcessVarType.TYPE_INT:
                    coltp = WFProcessVarColType.TYPE_INT;
                    break;
                case WFProcessVarType.TYPE_DATE:
                    coltp = WFProcessVarColType.TYPE_DATE;
                    break;
            }
            columns = new Array(1);
            columns[0] = WFProcessVarColumn.Create2('VALUE', coltp);
        }

        return new WFProcessVarState(def.Name, columns, 0);
    }

    public static Deserialize(obj: any): WFProcessVarState {
        let ii, ii2: number;
        let isnull: boolean;

        let nm: string = obj.name;

        let cols = new Array<WFProcessVarColumn>();
        for (let cl of obj.columns) {
            cols.push(new WFProcessVarColumn(cl));
        }

        let rowcc: number = obj.rowcc;

        let rid = 0;
        let ret = new WFProcessVarState(nm, cols, rowcc); // obj.rows.length);
        for (ii = 0; ii < cols.length; ii++) {
            let vcl: WFProcessVarColumn = cols[ii];
            let col: Array<Object> = ret.m_data[ii];
            switch (vcl.Type) {
                case WFProcessVarColType.TYPE_FLOAT:
                    for (ii2 = 0; ii2 < rowcc; ii2++) {
                        isnull = <boolean>obj.rows[rid++];
                        col.push((isnull) ? null : obj.rows[rid++]);
                    }
                    break;
                case WFProcessVarColType.TYPE_INT:
                    for (ii2 = 0; ii2 < rowcc; ii2++) {
                        isnull = <boolean>obj.rows[rid++];
                        col.push((isnull) ? null : obj.rows[rid++]);
                    }
                    break;
                case WFProcessVarColType.TYPE_DATE:
                    for (ii2 = 0; ii2 < rowcc; ii2++) {
                        isnull = <boolean>obj.rows[rid++];
                        col.push((isnull) ? null : IWFObject.ParseDate(obj.rows[rid++]));
                    }
                    break;
                case WFProcessVarColType.TYPE_TEXT:
                    for (ii2 = 0; ii2 < rowcc; ii2++) {
                        col.push(obj.rows[rid++]);
                    }
                    break;
            }
        }

        return ret;
    }

    public Serialize(): Object {
        let ii, ii2;
        let obj;

        let cols = new Array<Object>();
        for (let cl of this.m_columns) {
            cols.push(cl.ToObject());
        }

        let rows = new Array<Object>();
        for (ii = 0; ii < this.m_columns.length; ii++) {
            let vcl: WFProcessVarColumn = this.m_columns[ii];
            let col: Array<Object> = this.m_data[ii];
            switch (vcl.Type) {
                case WFProcessVarColType.TYPE_FLOAT:
                    for (ii2 = 0; ii2 < this.m_rowcount; ii2++) {
                        obj = col[ii2];
                        if (obj == null) {
                            rows.push(true);
                        } else {
                            rows.push(false);
                            rows.push(<number>obj);
                        }
                    }
                    break;
                case WFProcessVarColType.TYPE_INT:
                    for (ii2 = 0; ii2 < this.m_rowcount; ii2++) {
                        obj = col[ii2];
                        if (obj == null) {
                            rows.push(true);
                        } else {
                            rows.push(false);
                            rows.push(<number>obj);
                        }
                    }
                    break;
                case WFProcessVarColType.TYPE_DATE:
                    for (ii2 = 0; ii2 < this.m_rowcount; ii2++) {
                        obj = col[ii2];
                        if (obj == null) {
                            rows.push(true);
                        } else {
                            rows.push(false);
                            rows.push(IWFObject.DateToString(<Date>obj));
                        }
                    }
                    break;
                case WFProcessVarColType.TYPE_TEXT:
                    for (ii2 = 0; ii2 < this.m_rowcount; ii2++) {
                        rows.push(col[ii2]);
                    }
                    break;
            }
        }

        return {
            name: this.m_name,
            columns: cols,
            rowcc: this.m_rowcount,
            rows: rows
        };
    }

    /*public static Create2(nm: string, cols: Array<WFProcessVarColumn>): WFProcessVarState {
        let ii: number;
        
        let ret= new WFProcessVarState(nm, cols, 0);
        this.m_name = nm;
        this.m_columns = cols;
        this.m_data = new Array(this.m_columns.length);
        for (ii = 0; ii < this.m_columns.length; ii++)
            this.m_data[ii] = new List<Object>();
        this.m_rowcount = 0;
    }

    public static Create3(nm: string, cols: Array<WFProcessVarColumn>, rows: number): WFProcessVarState {
        let ii: number;

        this.m_name = nm;
        this.m_columns = cols;
        this.m_data = new Array(cols.length);
        for (ii = 0; ii < this.m_columns.length; ii++)
            this.m_data[ii] = new List<Object>();
        this.m_rowcount = rows;
    }*/

    //

    public get Name(): string {
        return this.m_name;
    }

    public get Columns(): Array<WFProcessVarColumn> {
        return this.m_columns;
    }

    public Clear(): void {
        let ii: number;
        for (ii = 0; ii < this.m_columns.length; ii++) {
            this.m_data[ii].splice(0, this.m_data[ii].length);
        }
        this.m_rowcount = 0;
    }

    public get RowCount(): number {
        return this.m_rowcount;
    }

    public AddLine(num: number): void {
        let ii: number, ii2: number;
        if (num > 0) {
            for (ii = 0; ii < this.m_columns.length; ii++) {
                let col: Array<Object> = this.m_data[ii];
                for (ii2 = 0; ii2 < num; ii2++)
                    col.push(null);
            }
            this.m_rowcount += num;
        }
    }

    private CheckValueType(dsttype: WFProcessVarColType, val: Object): Object {
        if (val != null) {
            switch (dsttype) {
                case WFProcessVarColType.TYPE_INT:
                    if (typeof val === 'number')
                        return <number>val;
                    return IWFObject.ParseInt(val.toString());

                case WFProcessVarColType.TYPE_FLOAT:
                    if (typeof val === 'number')
                        return <number>val;
                    return IWFObject.StrToDbl(val.toString());

                case WFProcessVarColType.TYPE_DATE:
                    if (val instanceof Date)
                        return <Date>val;
                    return IWFObject.ParseShortDate(val.toString());

                case WFProcessVarColType.TYPE_TEXT:
                    return val.toString();
            }
        }
        return null;
    }
    public SetValue(colid: number, rowid: number, value: Object): void {
        let col: WFProcessVarColumn = this.m_columns[colid];
        this.m_data[colid][rowid] = this.CheckValueType(col.Type, value);
    }
    private FindColDefinition(cname: string): number {
        let ii: number;
        cname = cname.trim();
        cname = cname.toUpperCase();
        for (ii = 0; ii < this.m_columns.length; ii++) {
            let col: WFProcessVarColumn = this.m_columns[ii];
            if (col.Name === cname)
                return ii;
        }
        return -1;
    }
    public SetValue2(cname: string, rowid: number, value: Object): void {
        let colid: number = this.FindColDefinition(cname);
        if (colid >= 0)
            this.m_data[colid][rowid] = this.CheckValueType(this.m_columns[colid].Type, value);
    }
    public SetScalar(value: Object): void {
        let ii: number;
        if (this.m_rowcount === 0) {
            for (ii = 0; ii < this.m_columns.length; ii++) {
                let col: Array<Object> = this.m_data[ii];
                col.push(null);
            }
            this.m_rowcount = 1;
        }
        this.m_data[0][0] = this.CheckValueType(this.m_columns[0].Type, value);
    }
    public GetValue(colid: number, rowid: number): Object {
        return this.m_data[colid][rowid];
    }
    public GetValue2(cname: string, rowid: number): Object {
        let colid: number = this.FindColDefinition(cname);
        return (colid >= 0) ? this.m_data[colid][rowid] : null;
    }
    public GetScalar(): Object {
        if (this.m_rowcount > 0)
            return this.m_data[0][0];
        return null;
    }

    public static PrepareReplaceTab(fields: Array<string>, cstates: Map<number, WFProcessVarState>): Array<[string, string]> {
        let ii, ii2: number;
        let obj: Object;
        let uname: string;
        let count = 0;
        let vstates = new Array<WFProcessVarState>();
        let st: WFProcessVarState;

        for (st of Array.from(cstates.values())) {
            uname = st.Name.toUpperCase();
            let vstrid = IWFObject.Format('${0}', uname);
            for (ii = 0; ii < fields.length; ii++) {
                if (fields[ii].indexOf(vstrid) >= 0) {
                    vstates.push(st);
                    count += st.Columns.length + 2;
                    break;
                }
            }
        }

        if (vstates.length === 0) return new Array<[string, string]>();

        vstates.sort((a, b) => b.Name.length - a.Name.length);

        let ret = new Array<[string, string]>(count);
        count = 0;

        for (ii = 0; ii < vstates.length; ii++) {
            st = vstates[ii];
            uname = st.Name.toUpperCase();
            for (ii2 = 0; ii2 < st.Columns.length; ii2++) {
                obj = null;
                if (st.RowCount > 0)
                    obj = st.GetValue(ii2, 0);
                let pcol: WFProcessVarColumn = st.Columns[ii2];
                ret[count++] = [IWFObject.Format('${0}[{1}]', uname, pcol.Name.toUpperCase()),
                (obj == null) ? '(null)' : obj.toString()];
            }
            ret[count++] = [IWFObject.Format('COUNT(${0})', uname), st.RowCount.toString()];
            obj = null;
            if (st.RowCount > 0)
                obj = st.GetValue(0, 0);

            ret[count++] = [IWFObject.Format('${0}', uname), (obj == null) ? '(null)' : obj.toString()];
        }

        return ret;
    }
    /*public static CreateFromDataTable(name: string, dta: DataTable): WFProcessVarState {
        var ii: number, rid;
        var btp: WFProcessVarColType;
        var rcols: WFProcessVarColumn[] = new Array(dta.Columns.Count);
        for (; ii < dta.Columns.Count; ii++) {
            var dcol: DataColumn = dta.Columns[ii];
            if (dcol.DataType == number) {
                btp = WFProcessVarColType.TYPE_INT;
            }
            else {
                if (dcol.DataType == number || dcol.DataType == number) {
                    btp = WFProcessVarColType.TYPE_FLOAT;
                }
                else {
                    btp = WFProcessVarColType.TYPE_TEXT;
                }
            }
            rcols[ii] = new WFProcessVarColumn(dcol.ColumnName, btp);
        }
        var retstate: WFProcessVarState = new WFProcessVarState((name != null) ? name : dta.TableName, rcols);
        if (dta.Rows.Count > 0) {
            retstate.AddLine(dta.Rows.Count);
            for (; rid < dta.Rows.Count; rid++) {
                var dr: DataRow = dta.Rows[rid];
                for (; ii < dta.Columns.Count; ii++) {
                    var oval: Object = dr[ii];
                    if (oval != null && oval != DBNull.Value)
                        retstate.SetValue(ii, rid, oval);
                }
            }
        }
        return retstate;
    } */
}
import { Component, OnInit, EventEmitter, Output } from '@angular/core';

interface EntrySpec {
  label: string;
  id: number;
  image: string;
}

@Component({
  selector: 'app-add-new-doc-menu',
  templateUrl: './add-new-doc-menu.component.html',
  styleUrls: ['./add-new-doc-menu.component.scss']
})

export class AddNewDocMenuComponent {
  public m_refelement: any;
  public m_entries: Array<EntrySpec>;

  @Output() closePopup: EventEmitter<void>;
  @Output() runCommand: EventEmitter<number>;

  //

  constructor() {

    this.m_refelement = null;
    this.closePopup = new EventEmitter<void>();
    this.runCommand = new EventEmitter<number>();
    this.m_entries = new Array<EntrySpec>();
  }

  public SetPrms(refelement: any): void {
    this.m_refelement = refelement;
  }

   public AddEntry(lbl: string, id: number, img: string): void {
     this.m_entries.push({ label: lbl, id: id, image: img });
   }

  public selectEntry(ent: EntrySpec): void {
    this.runCommand.emit(ent.id);
  }

  public GetList(templates: boolean ): Array<EntrySpec> {
    if (templates) {
      return this.m_entries.filter(entry => entry.id > 0 );
    } else {
      return this.m_entries.filter(entry => entry.id <= 0 );
    }
  }

}

import { Component, OnInit, ElementRef, TemplateRef, HostListener, Input, Output, EventEmitter, ViewChild, AfterViewInit, NgZone, Renderer2 } from '@angular/core';
import { ChildWindowContainerService } from './child-window-container.service';

export interface ButtonDef {
  id: number;
  desc: string;
}

@Component({
  selector: 'app-child-window',
  templateUrl: './child-window.component.html',
  styleUrls: ['./child-window.component.scss']
})

export class ChildWindowComponent implements AfterViewInit {
  public m_zorder: number;
  public m_title: string;

  get title(): string {
    return this.m_title;
  }

  @Input('title')
  set title(val: string) {
    this.m_title = val.toUpperCase();
  }

  @Input() buttons: Array<ButtonDef>;

  @Input() can_ok: boolean;
  @Input() can_cancel: boolean;
  @Input() busy_text: string;
  @Input() oktext: string;
  @Input() okicon: string;
  @Input() oktooltip: string;


  //

  public m_width: number;
  public m_height: number;

  get width(): number {
    return this.m_width;
  }

  @Input('width')
  set width(val: number) {
    if (val < 100) val = 100;
    this.m_width = val;
  }

  get height(): number {
    return this.m_height;
  }

  @Input('height')
  set height(val: number) {
    if (val < 100) val = 100;
    this.m_height = val;
  }

  public contentTemplate: TemplateRef<any>;

  public m_left: number;
  public m_top: number;
  public m_moving: boolean;
  public m_startpos: [number, number];

  @ViewChild('m_bck_layer', {static: false}) private m_bck_layer: ElementRef;
  @ViewChild('m_popup', {static: false}) private m_popup: ElementRef;
  //

  @Output() clickOk: EventEmitter<void>;
  @Output() clickCancel: EventEmitter<void>;

  @Output() clickButton: EventEmitter<number>;

  //

  constructor(private childwndsrv: ChildWindowContainerService,
              private m_zone: NgZone,
              private m_render: Renderer2) {
    this.m_zorder = childwndsrv.getNewLayer();

    this.title = 'INFO';
    this.buttons = new Array<ButtonDef>();
    this.can_ok = true;
    this.oktext = '';
    this.okicon = 'cmn_checkrect';
    this.can_cancel = true;
    this.busy_text = '';
    this.oktooltip = '';
    this.m_width = 400;
    this.m_height = 200;
    this.clickOk = new EventEmitter<void>();
    this.clickCancel = new EventEmitter<void>();
    this.clickButton = new EventEmitter<number>();
    this.contentTemplate = null;
    this.m_left = 0;
    this.m_top = 0;
    this.m_moving = false;
    this.m_startpos = null;
    // ComponentFactoryResolver w celu pobrania instancji dynamicznie
  }
  
  public ngAfterViewInit(): void {
    let left= (this.m_bck_layer.nativeElement.clientWidth / 2) - (this.m_width / 2);
    let top= (this.m_bck_layer.nativeElement.clientHeight / 2) - (this.m_height / 2);
    
    this.m_left = left;
    this.m_top = top;
    this.m_render.setStyle(this.m_popup.nativeElement, 'left', left + 'px');
    this.m_render.setStyle(this.m_popup.nativeElement, 'top', top + 'px');
  }

  public onMouseDown(event: MouseEvent): void {
    this.m_moving = true;
    this.m_startpos = [event.offsetX, event.offsetY];
    this.m_zone.runOutsideAngular(() => {
      window.document.addEventListener('mousemove', this.onMouseMove.bind(this));
    });
  }

  public onMouseMove(event: MouseEvent): void {
    if (this.m_moving) {
      event.preventDefault();
      let left = event.clientX - this.m_startpos[0];
      let top = event.clientY - this.m_startpos[1];

      if (left < this.m_bck_layer.nativeElement.clientLeft) {
        left = this.m_bck_layer.nativeElement.clientLeft;
      } else {
        if ((left + this.m_width) > this.m_bck_layer.nativeElement.clientWidth) left = this.m_bck_layer.nativeElement.clientWidth - this.m_width;
      }

      if (top < this.m_bck_layer.nativeElement.clientTop) {
        top = this.m_bck_layer.nativeElement.clientTop;
      } else {
        if ((top + this.m_height) > this.m_bck_layer.nativeElement.clientHeight) top = this.m_bck_layer.nativeElement.clientHeight - this.m_height;
      }

      this.m_left = left;
      this.m_top = top;
      this.m_render.setStyle(this.m_popup.nativeElement, 'left', left + 'px');
      this.m_render.setStyle(this.m_popup.nativeElement, 'top', top + 'px');
    }
  }

  public onMouseUp(): void {
    this.m_moving = false;
    window.document.removeEventListener('mousemove', this.onMouseMove);
  }
}

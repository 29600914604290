export class WFDocumentRef {
    private m_id: number;
    private m_company_id: number;
    private m_objecttype: string;
    private m_objectid: string;

    private m_header: Map<string, string>;
    private m_columns: Array<string>;
    private m_lines: Array<Map<number, string>>;

    //

    public static Create(company_id: number, objecttype: string, objectid: string): WFDocumentRef {
        return new WFDocumentRef({
            id: 0,
            company_id: company_id,
            objecttype: objecttype,
            objectid: objectid,
            header: [],
            columns: [],
            lines: []
        });
    }

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_company_id = obj.company_id;
        this.m_objecttype = obj.objecttype;
        this.m_objectid = obj.objectid;

        this.m_header = new Map<string, string>();
        for (let kv of obj.header) this.m_header.set(kv.key, kv.value);

        this.m_columns = obj.columns;

        this.m_lines = new Array<Map<number, string>>();
        for (let ln of obj.lines) {
            let mp = new Map<number, string>();
            for (let kv of ln) mp.set(kv.key, kv.value);
            this.m_lines.push(mp);
        }
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public set CompanyID(nval: number) {
        this.m_company_id= nval;
    }

    public get CompanyID(): number {
        return this.m_company_id;
    }

    //

    public set ObjectType(nval: string) {
        this.m_objecttype= nval;
    }

    public get ObjectType(): string {
        return this.m_objecttype;
    }

    //

    public set ObjectID(nval: string) {
        this.m_objectid= nval;
    }

    public get ObjectID(): string {
        return this.m_objectid;
    }

    //

    public ClearPreview(): void {
        this.m_header.clear();
        this.m_columns.splice(0, this.m_columns.length);
        this.m_lines.splice(0, this.m_lines.length);
    }

    //

    public get Header(): Map<string, string> {
        return this.m_header;
    }

    //

    public get ColumnsCount(): number {
        return this.m_columns.length;
    }

    public GetColumn(id: number): string {
        return this.m_columns[id];
    }

    public AddColumn(nm: string): number {
        let ret = this.m_columns.length;
        this.m_columns.push(nm);
        return ret;
    }

    //

    public get RowsCount(): number {
        return this.m_lines.length;
    }

    public AddRows(cc: number): number {
        for (let ii = 0; ii < cc; ii++) {
            this.m_lines.push(new Map<number, string>());
        }
        return this.m_lines.length - 1;
    }

    public SetValue(row: number, col: number, nval: string): void {
        let rr = this.m_lines[row];
        rr.set(col, nval);
    }

    public GetValue(row: number, col: number): string {
        let rr = this.m_lines[row];
        if (rr.has(col)) return rr.get(col);
        return '';
    }

    public ToObject(): any {
        let header = new Array<any>();
        for (let kv of Array.from(this.m_header.entries())) header.push({ key: kv[0], value: [1] });

        let lines = new Array<any>();
        for (let ln of this.m_lines) {
            let sln = new Array<any>();
            for (let kv of Array.from(ln.entries())) {
                sln.push({ key: kv[0], value: kv[1] });
            }
            lines.push(sln);
        }

        return {
            id: this.m_id,
            company_id: this.m_company_id,
            objecttype: this.m_objecttype,
            objectid: this.m_objectid,
            header: header,
            columns: this.m_columns,
            lines: lines
        };
    }
}

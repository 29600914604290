import { WFUser, WFClass, WFAuthMode, WFDocument, WFAuthSchema, WFUserGroup, WFDocumentLine, WFDocumentLineRef, WFDocStatus, WFAuthMethodType, WFSchemaRecipient, WFClassAttrib, WFCustomProcess, IWFCustomInstruction, WFProcessVarState, WFProcessAction, WFAuthPermission, IWFObject, WFRelation, WFGroupRole, WFPermissionInfo, WFAuthStatus, WFAuthEditMode, WFDocumentAuthSchema, WFDocumentAuthRecipient, WFDocAuthObjType, WFProcessVariable, WFInstructionType, WFLineopInstruction, WFScriptopInstruction, WFDialogWndInstruction, WFExcallopInstruction, WFFieldType, WFPrmType, WFAuthSeq, WFPropType, WFAuthPrmObjType, WFObjType, WFTmpWriteMode, PrmCopyMethodType, WFPrmObjType, WFPermission, WFDocumentModification, WFDocumentOcrResults, WFDocumentPage, WFDocumentRef, WFMessage, IWFDocumentAuthVariable, WFDocumentOcrMap, WFLineOpType, WFScriptType, WFExcallOpType, WFCompanyDict, WFDictionary, WFPrmDocState } from '../model/index';
import { DocumentList, SORT_PROPERTY } from '../data/documentlist';
import { IWFCompanyObjAdp } from './IWFCompanyObjAdp';
import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';
import { WFMessageAdp } from './WFMessageAdp';
import { GlobalService, BUTTONSTYPE, WNDRESULT } from '../global/global.service';
import { FDOC_DOCSTATUS_INSYSTEM, FDOC_DOCSTATUS_BEFOREOCR, FDOC_DOCSTATUS_INOCR, FDOC_DOCSTATUS_AFTEROCR, FDOC_DOCSTATUS_TEMPLATE, ComboValueDesc } from '../app.component';
import { FindDocumentPrms, FindMessagesPrms, DailyCorRefInfo } from '../data/data.service';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, GetResourcePreviewPrms, LineValuesSpec, ProcessVarState } from '../data/data.service';
import { ALR_ICONTYPE, AlertWndComponent } from '../alert-wnd/alert-wnd.component';
import { WFProcessVarColumn, WFProcessVarColType } from '../model/WFProcessVarColumn';
import { DatePipe } from '@angular/common';
import { WFInteractiveDictAdp } from './WFInteractiveDictAdp';

export type _OnError = () => void;
export type LinePropertyValueChange = (snd: WFDocumentLineAdp, prop: string) => void;
export type LinePropertyValidation = (snd: WFDocumentLineAdp, prop: string, status: boolean) => void;

export const SYSPERMFIELDS: Array<number> = [
    WFAuthPermission.STDATR_NAME,
    WFAuthPermission.STDATR_DESCRIPTION,
    WFAuthPermission.STDATR_DOCNUM,
    WFAuthPermission.STDATR_CLASS,
    WFAuthPermission.STDATR_AUTHSCHEMA,
    WFAuthPermission.STDATR_DOCDATE,
    WFAuthPermission.STDATR_ENTERDATE
];

export class WFDocumentLineAdp {
    private m_parent: WFDocumentAdp;
    private m_linedta: WFDocumentLine;
    private m_invalidcols: Array<string>;

    public OnPropertyValueChange: LinePropertyValueChange;
    public OnPropertyValidation: LinePropertyValidation;

    constructor(pr: WFDocumentAdp, line: WFDocumentLine) {
        this.m_parent = pr;
        this.m_linedta = line;
        this.m_invalidcols = new Array<string>();
        this.OnPropertyValueChange = null;
        this.OnPropertyValidation = null;
    }

    public get Parent(): WFDocumentAdp {
        return this.m_parent;
    }

    public get UserID(): number {
        return this.m_linedta.UserID;
    }

    public get CreatedAt(): Date {
        return this.m_linedta.CreatedAt;
    }

    public SetValue(sval: string, cls: WFClass, col: WFClassAttrib, firechg: boolean= true): void {
        let colnm: string = col.Name.toUpperCase();
        let off = this.m_invalidcols.indexOf(colnm);
        if (off >= 0)
            this.m_invalidcols.splice(off, 1);
        if (this.m_linedta.has(colnm)) {
            let oldval: string = this.m_linedta.get(colnm);
            if (oldval === sval)
                return;
            if (sval.length > 0) {
                this.m_linedta.set(colnm, sval);                
            } else this.m_linedta.delete(colnm);
        } else {
            if (sval.length > 0) {
                this.m_linedta.set(colnm, sval);
            } else 
                return;
        }
        
        this.m_parent.LineValueChanged(cls, col, this.m_linedta, sval, firechg);
        
        if (this.OnPropertyValueChange != null)
            this.OnPropertyValueChange(this, colnm);

        if (this.OnPropertyValidation != null)
            this.OnPropertyValidation(this, colnm, true);               
    }

    public SetInvalidValue(sval: string, col: WFClassAttrib): void {
        let colnm: string = col.Name.toUpperCase();
        if (this.m_invalidcols.indexOf(colnm) < 0)
            this.m_invalidcols.push(colnm);
        if (this.OnPropertyValidation == null)
            throw new Error();
        if (sval.length > 0)
            this.m_linedta.set(colnm, sval);
        else this.m_linedta.delete(colnm);
        this.OnPropertyValidation(this, colnm, false);
    }

    public set(key: string, value: string): void {
        key = key.toUpperCase();
        let sval: string = value.trim();
        let cls: WFClass = null;
        let col: WFClassAttrib = null;

        if (this.m_parent.ClassID > 0) {
            cls = this.m_parent.CacheService.Classes.get(this.m_parent.ClassID);
            for (let cl of this.m_parent.AllColumns) {
                if (cl.Name.toUpperCase() === key) {
                    col = cl;
                    if (this.m_parent.CanEditLines(col, this) !== WFPrmType.CHANGE) return;

                    let sret: string = this.m_parent.IsStrValid(sval, col);
                    if (sret == null) {
                        this.SetInvalidValue(sval, col);
                        if(IWFObject.IsNullOrEmpty(sval)) this.m_parent.IsModified= true;
                        return;
                    }
                    sval = sret;
                    break;
                }
            }
        }
        this.SetValue(sval, cls, col);
    }

    public get(key: string): string {
        key = key.toUpperCase();
        if (this.m_linedta.has(key)) 
        {
            let val= this.m_linedta.get(key);  
            return val;
        } 
        return null;
    }

    public get InvalidColumns(): Array<string> {
        return this.m_invalidcols;
    }

    public SetLineDataFromMapDataRow(row: Array<object>, fcolsatr: Array<string>, fcols: Array<number>, fcols_c: number): boolean {

        let ii, cid: number;
        let ret = true;

        //console.log('SetLineDataFromMapDataRow',row,fcols,fcols_c);

        this.m_invalidcols.splice(0, this.m_invalidcols.length);
        let allatrs: Map<string, WFClassAttrib> = new Map<string, WFClassAttrib>();
        let cls: WFClass = null;
        if (this.m_parent.ClassID > 0) {
            cls = this.m_parent.CacheService.Classes.get(this.m_parent.ClassID);
            for (let cl of this.m_parent.AllColumns) {
                if (!allatrs.has(cl.Name))
                    allatrs.set(cl.Name, cl);
            }
        }

        for (ii = 0; ii < fcols_c; ii++) {
            cid = fcols[ii];
            if (row[cid] != null) {
                let scid: string = fcolsatr[ii];
                if (allatrs.has(scid)) {
                    let col: WFClassAttrib = allatrs.get(scid);
                    if (this.m_parent.CanEditLines(col, this) === WFPrmType.CHANGE) {
                        let sval: string = row[cid].toString();
                        let sret: string = this.m_parent.IsStrValid(sval, col);
                        if (sret == null) {
                            let colnm: string = col.Name.toUpperCase();
                            this.m_invalidcols.push(colnm);
                            if (sval.length > 0)
                                this.m_linedta.set(colnm, sval);
                            else
                                this.m_linedta.delete(colnm);

                            if (this.OnPropertyValidation != null)
                                this.OnPropertyValidation(this, colnm, false);

                            ret = false;
                        } else {
                            this.SetValue(sret, cls, col, false);
                        }
                    } else {
                        ret = false;
                    }
                }
            }
        }
        return ret;
    }

    public GetRefsCount(): number {
        return this.m_linedta.Refs.length;
    }
    public GetRef(ii: number): WFDocumentLineRef {
        return this.m_linedta.Refs[ii];
    }

    public AddLineRef(ocrresultid: number, linenum: number): WFDocumentLineRef {
        let ii: number;

        for (ii = 0; ii < this.m_linedta.Refs.length; ii++) {
            let kv: WFDocumentLineRef = this.m_linedta.Refs[ii];
            if (kv.DocumentOcrResultID === ocrresultid && kv.OcrLineNum === linenum)
                return null;
        }

        let rf: WFDocumentLineRef = WFDocumentLineRef.Create(ocrresultid, linenum);
        this.m_linedta.Refs.push(rf);
        return rf;
    }

    public get IsEmpty(): boolean {
        return (Array.from(this.m_linedta.entries()).findIndex((tst) => tst[1] !== '') < 0);
    }
}

export enum TempCtxType {
    UNSET,
    OTHER,
    HTML
}

export enum StdDocField {
    NAME,
    DESCRIPTION,
    DOCNUM,
    CLASS,
    AUTHSCHEMA,
    DOCDATE,
    ENTERDATE
}

class TriggerArgs {
    public Process: WFCustomProcess;
    public Instruction: IWFCustomInstruction;
    public VarStates: Map<number, WFProcessVarState>;

    constructor(cpr: WFCustomProcess, ins: IWFCustomInstruction, cstates: Map<number, WFProcessVarState>) {
        this.Process = cpr;
        this.Instruction = ins;
        this.VarStates = cstates;
    }
}

class FindVisibleSchemaResult {
    public Recipient: WFDocumentAuthRecipient;
    public Schema: WFDocumentAuthSchema;
    constructor(rc: WFDocumentAuthRecipient, sch: WFDocumentAuthSchema) {
        this.Recipient = rc;
        this.Schema = sch;
    }
}

export interface AuthorizationInfo {
    user_id: number;
    user_name: string;
    auth_time: Date;
    auth_info: string;
}

export type LoadDocument = (snd: WFDocumentAdp, status: boolean, uobj: Object) => void;
export type PublishDocument = (snd: WFDocumentAdp, status: boolean) => void;
export type FetchAttachmentsCompleted = (snd: WFDocumentAdp, docs: Array<WFDocumentAdp>, prns: Array<WFDocumentAdp>, invdocs: Array<WFDocumentAdp>) => void;
export type FetchMessagesCompleted = (snd: WFDocumentAdp, docs: Array<WFMessageAdp>) => void;
export type PageModified = (snd: WFDocumentAdp) => void;
export type RemoveDocument = (snd: WFDocumentAdp, status: boolean, oldid: number) => void;
export type FetchKeywordsCompleted = (snd: WFDocumentAdp, kwords: string) => void;
export type FetchMatchReconSchemaCompleted = (snd: WFDocumentAdp, shmcodes: Array<string>) => void;
export type SuggestNewDocNameCompleted = (snd: WFDocumentAdp, newname: string) => void;
export type FindLineValuesCompleted = (snd: WFDocumentAdp, ret: Map<string, Array<[string, string]>>, ustate: Object) => void;
export type SuggestOcrMapCompleted = (snd: WFDocumentAdp, ret: Array<WFRelation>, ustate: Object) => void;

export type PropertyValueChange = (snd: WFDocumentAdp, prop: string) => void;
export type LinesModified = (snd: WFDocumentAdp) => void;
export type FireTriggerCompleted = (snd: WFDocumentAdp, sndprc: WFCustomProcess, docontinue: boolean, cstates: Map<number, WFProcessVarState>) => void;

export class WFDocumentAdp extends IWFCompanyObjAdp {
    private m_doc: WFDocument;
    private m_attachments: DocumentList;
    private m_parents: DocumentList;
    private m_tmp_async: WFDocumentAdp[];
    private m_messages: number[];
    private m_authschemaid: number;
    private m_srcauthschemaid: number;
    private m_authrecipients: Array<WFSchemaRecipient>;
    private m_authmthtype: WFAuthMethodType;
    private m_authusrlimit: number;
    private m_addinfo: string;
    private m_docstatus: WFDocStatus;
    private m_class_id: number;
    private m_matchschemas: string[];
    private m_keywords: string;

    public OnLoadDocument: LoadDocument;
    public OnPublishDocument: PublishDocument;
    public OnFetchAttachmentsCompleted: FetchAttachmentsCompleted;
    public OnFetchMessagesCompleted: FetchMessagesCompleted;
    public OnPageModified: PageModified;
    public OnRemoveDocument: RemoveDocument;
    public OnFetchKeywordsCompleted: FetchKeywordsCompleted;
    public OnFetchMatchReconSchemaCompleted: FetchMatchReconSchemaCompleted;
    public OnSuggestNewDocName: SuggestNewDocNameCompleted;
    public OnFindLineValuesCompleted: FindLineValuesCompleted;
    public OnSuggestOcrMapCompleted: SuggestOcrMapCompleted;

    private m_tmp_filename: string;
    private m_tmp_document_b64: string;
    private m_tmp_resources_id: number;
    private m_tmp_type: TempCtxType;
    private m_tmp_upddate: Date;
    private m_lines: Array<WFDocumentLineAdp>;

    public OnPropertyValueChange: PropertyValueChange;

    private m_initjs: boolean;
    private m_allattribs: Array<WFClassAttrib>;
    private m_allcolumns: Array<WFClassAttrib>;

    public OnLinesModified: LinesModified;
    public OnFireTriggerCompleted: FireTriggerCompleted;
    private m_OnError: EventAdp<_OnError>;

    //
    private m_acttrigger: TriggerArgs;
    private m_newocrlogs: number;

    private m_dailycorrefs: Array<DailyCorRefInfo>;
    private m_importedpgids: Array<WFDocumentPage>;

    private m_readonly: boolean;
    private m_infobarstate: boolean;
    private m_selected_tab: number;

    //

    public static DateTimeToStr(dta: Date, strdat: StringDataService): string {
        let dateprf: string = (dta.getDate() === Date.now()) ? strdat.getStr('strToday') : dta.toLocaleDateString();
        return IWFObject.Format('{0} {1}', dateprf, dta.toLocaleTimeString());
    }

    public static NullableToDate(dta: Date): Date {
        return (dta == null) ? new Date() : dta;
    }

    private ReloadLocals(): void {
        this.m_authrecipients = this.LoadRecipients();
        this.m_authschemaid = this.m_doc.AuthSchemaID;
        this.m_srcauthschemaid = 0;
        this.m_authmthtype = this.m_doc.AuthMethodType;
        this.m_authusrlimit = this.m_doc.AuthUserLimit;
        this.m_addinfo = this.m_doc.AdditionalInfo;
        this.m_docstatus = this.m_doc.DocStatus;
        this.m_class_id = this.m_doc.ClassID;
        this.m_attachments = new DocumentList();
        for (let ii = 0; ii < this.m_doc.AttachmentsID.length; ii++) {
            this.m_attachments.Add(this.m_doc.AttachmentsID[ii]);
        }
        const self = this;
        this.m_attachments.FetchCompleted = (sender, docs) => {
            self.FetchCompleted(sender, docs);
        };
        this.m_parents = null;
        this.m_tmp_async = null;
        this.m_importedpgids.splice(0, this.m_importedpgids.length);
    }

    private AddAllowedAttribs(src: Array<WFClassAttrib>, replusrs: Array<number>, dst: Array<WFClassAttrib>, stage: WFPrmDocState, authschmid: number): void {
        let ii: number;
        let isown = this.IsCreator();
        for (ii = 0; ii < src.length; ii++) {
            let doadd = true;
            let atr: WFClassAttrib = src[ii];
            let pinfs: Array<WFPermissionInfo> = this.m_doc.ListPermissions2(true, atr.ID, this.m_conn.AppSystem, /*(this.m_doc.DocStatus === WFDocStatus.INSYSTEM),*/ stage, authschmid);

            for (let inf of pinfs) {
                if ((inf.Type === WFPrmType.DISABLE) && ((inf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(inf.UserID) >= 0) || (inf.UserID === WFAuthSchema.OBJID_OWNER && isown))) {
                    doadd = false;
                    break;
                }
            }
            if (doadd)
                dst.push(atr);
        }
    }

    private RefreshAttribs(): void {
        let ii: number;
        this.m_allattribs.splice(0, this.m_allattribs.length);
        this.m_allcolumns.splice(0, this.m_allcolumns.length);
        if (this.m_doc.ClassID > 0) {
            let clspath: Array<WFClass> = new Array<WFClass>();
            let actcls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
            while (actcls != null) {
                clspath.push(actcls);
                actcls = (actcls.BaseClassID > 0) ? this.m_conn.Classes.get(actcls.BaseClassID) : null;
            }
            if (this.m_conn.IsSuperUser) {
                for (ii = clspath.length - 1; ii > -1; ii--) {
                    let cl: WFClass = clspath[ii];
                    this.m_allattribs = this.m_allattribs.concat(cl.Attributes);
                    this.m_allcolumns = this.m_allcolumns.concat(cl.Columns);
                }
            } else {
                let stage: WFPrmDocState;
                let authschmid = 0;

                if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {
                    stage = WFPrmDocState.APPROVEDNOAUTH;

                    if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                        let authst: WFAuthStatus = this.m_doc.GetStatus();

                        let lshm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
                        authschmid = lshm.AuthSchemaID;

                        if (authst === WFAuthStatus.WAITING) {
                            stage = WFPrmDocState.INAUTH;
                        } else {
                            if (authst === WFAuthStatus.DISAPPROVED) stage = WFPrmDocState.DISAPPROVED;
                        }
                    }
                } else {
                    stage = WFPrmDocState.BEFOREAUTH;
                }

                let replusrs: number[] = this.m_conn.User.FindReplacements(this.m_conn.Users);
                for (ii = clspath.length - 1; ii > -1; ii--) {
                    let cl: WFClass = clspath[ii];
                    this.AddAllowedAttribs(cl.Attributes, replusrs, this.m_allattribs, stage, authschmid);
                    this.AddAllowedAttribs(cl.Columns, replusrs, this.m_allcolumns, stage, authschmid);
                }
            }
            if (this.m_allattribs.length > 0)
                WFClassAttrib.SortArribs(this.m_allattribs);
            if (this.m_allcolumns.length > 0)
                WFClassAttrib.SortArribs(this.m_allcolumns);
        }
    }

    constructor(csrv: CacheService, doc: WFDocument, autoeval: boolean, readonly: boolean) {
        super(csrv, (doc.ID === 0), doc.Properties);
        this.m_doc = doc;
        this.m_messages = null;
        this.m_keywords = null;
        this.m_matchschemas = null;
        this.m_tmp_filename = '';
        this.m_tmp_document_b64 = null;
        this.m_tmp_resources_id = 0;
        this.m_tmp_type = TempCtxType.UNSET;
        this.m_tmp_upddate = new Date();
        this.m_lines = new Array<WFDocumentLineAdp>();
        this.m_initjs = true;
        this.m_allattribs = new Array<WFClassAttrib>();
        this.m_allcolumns = new Array<WFClassAttrib>();
        this.m_acttrigger = null;
        this.m_newocrlogs = 0;
        this.m_dailycorrefs = new Array<DailyCorRefInfo>();
        this.m_importedpgids = new Array<WFDocumentPage>();
        this.m_readonly= readonly;
        this.m_infobarstate= false;
        this.m_selected_tab= 0;

        this.OnLoadDocument = null;
        this.OnPublishDocument = null;
        this.OnFetchAttachmentsCompleted = null;
        this.OnFetchMessagesCompleted = null;
        this.OnPageModified = null;
        this.OnRemoveDocument = null;
        this.OnFetchKeywordsCompleted = null;
        this.OnFetchMatchReconSchemaCompleted = null;
        this.OnSuggestNewDocName = null;
        this.OnFindLineValuesCompleted = null;
        this.OnSuggestOcrMapCompleted = null;
        this.OnPropertyValueChange = null;
        this.OnLinesModified = null;
        this.OnFireTriggerCompleted = null;
        this.m_OnError = new EventAdp<_OnError>();

        this.ReloadLines();
        this.ReloadLocals();
        this.RefreshAttribs();
        if (autoeval && this.m_doc.ID === 0 && this.m_doc.TemplateID > 0 && this.m_doc.ClassID > 0) {
            let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
            this.EvalPropertyValueChanged(cls, null);
        }
    }

    public get ReadOnly(): boolean {
        return this.m_readonly;
    }

    public get Infobarstate(): boolean {
        return this.m_infobarstate;
    }
    
    public set Infobarstate(nval: boolean) {
        this.m_infobarstate= nval;
    }
    
    public get Selected_tab(): number {
        return this.m_selected_tab;
    }

    public set Selected_tab(nval: number) {
        this.m_selected_tab= nval;
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    public CreateTemplate(): WFDocument {
        let ret: WFDocument = WFDocument.Create2(this.m_doc);
        ret.TemplateID = this.m_doc.ID;
        ret.DocDate = ret.EnterDate = new Date();
        ret.DocNum = ret.Name = ret.Description = '';
        return ret;
    }

    private ReloadLines(): void {
        this.m_lines.splice(0, this.m_lines.length);
        for (let ii = 0; ii < this.m_doc.Lines.length; ii++) {
            this.m_lines.push(new WFDocumentLineAdp(this, this.m_doc.Lines[ii]));
        }
    }

    public get ID(): number {
        return this.m_doc.ID;
    }

    public set UserID(value: number) {
        if (this.m_doc.UserID !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_doc.UserID = value;
            this.PropertyValueChanged('UserID');
        }
    }

    public get UserID(): number {
        return this.m_doc.UserID;
    }

    public set ClassID(value: number) {
        if (this.m_doc.ClassID !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_CLASS))
                throw new Error();
            this.m_doc.ClassID = value;
            this.m_authschemaid = WFDocument.SCHM_NOAUTH;
            this.m_srcauthschemaid = 0;
            this.m_authmthtype = WFAuthMethodType.AUTH_ALLOF;
            this.m_authusrlimit = 1;
            this.m_addinfo = '';
            this.RefreshAttribs();
            if (this.m_doc.ClassID > 0) {
                let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
                if (cls.DefaultSchema > 0) {
                    this.m_authschemaid = cls.DefaultSchema;
                    this.CopyPermissions();
                }
            }
            this.PropertyValueChanged('ClassID');
        }
    }

    public get ClassID(): number {
        return this.m_doc.ClassID;
    }

    public set CompanyID(value: number) {
        if (this.m_doc.CompanyID !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_doc.CompanyID = value;
            this.PropertyValueChanged('CompanyID');
        }
    }

    public get CompanyID(): number {
        return this.m_doc.CompanyID;
    }

    public ContainsAttachment(doc_id: number): boolean {
        return (this.m_doc.AttachmentsID.indexOf(doc_id) >= 0);
    }
    public AddAttachment(doc_id: number): void {
        if (this.m_doc.AttachmentsID.indexOf(doc_id) < 0) {
            this.m_doc.AttachmentsID.push(doc_id);
            this.m_attachments.Add(doc_id);
            this.IsModified = true;
        }
    }
    public RemoveAttachment(doc_id: number): void {
        let off = this.m_doc.AttachmentsID.indexOf(doc_id);
        if (off >= 0) {
            this.m_doc.AttachmentsID.splice(off, 1);
            this.m_attachments.Remove(doc_id);
            this.IsModified = true;
        }
    }
    public set DirectoryID(value: number) {
        if (this.m_doc.DirectoryID !== value) {
            this.m_doc.SetDirectoryID(value, this.m_conn.User.ID);
            this.PropertyValueChanged('DirectoryID');
        }
    }
    public get DirectoryID(): number {
        return this.m_doc.DirectoryID;
    }
    public set Name(value: string) {
        if (this.m_doc.Name !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_NAME))
                throw new Error();
            this.m_doc.Name = value;
            this.PropertyValueChanged('Name');
            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, 'Name', value, -1, 0);
        }
    }

    public get Name(): string {
        return this.m_doc.Name;
    }

    public set Description(value: string) {
        if (this.m_doc.Description !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_DESCRIPTION))
                throw new Error();
            this.m_doc.Description = value;
            this.PropertyValueChanged('Description');
            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, 'Description', value, -1, 0);
        }
    }

    public get Description(): string {
        return this.m_doc.Description;
    }

    public set DocDate(dta: Date) {
        if (this.m_doc.DocDate !== dta) {
            if (dta== null || (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_DOCDATE)))
                throw new Error();
            this.m_doc.DocDate = dta;
            this.PropertyValueChanged('DocDate');
            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, 'DocDate', (dta != null) ? dta.toLocaleDateString() : '', -1, 0);
        }
    }

    public get DocDate(): Date {
        return this.m_doc.DocDate;
    }

    public set EnterDate(dta: Date) {
        if (this.m_doc.EnterDate !== dta) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_ENTERDATE))
                throw new Error();
            this.m_doc.EnterDate = dta;
            this.PropertyValueChanged('EnterDate');
            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, 'EnterDate', (dta != null) ? dta.toLocaleDateString() : '', -1, 0);
        }
    }

    public get EnterDate(): Date {
        return this.m_doc.EnterDate;
    }

    public set DocNum(value: string) {
        if (this.m_doc.DocNum !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_DOCNUM))
                throw new Error();
            this.m_doc.DocNum = value;
            this.PropertyValueChanged('DocNum');
            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, 'DocNum', value, -1, 0);
        }
    }

    public get DocNum(): string {
        return this.m_doc.DocNum;
    }

    public get CreatedAt(): Date {
        return this.m_doc.CreatedAt;
    }

    public get UpdatedAt(): Date {
        return this.m_doc.UpdatedAt;
    }

    public get TemplateID(): number {
        return this.m_doc.TemplateID;
    }

    public set TemplateWriteMode(value: WFTmpWriteMode) {
        if (this.m_doc.TemplateWriteMode !== value) {
            if (this.m_doc.ID > 0 && !this.CanChangeTmpWriteMode)
                throw new Error();
            this.m_doc.TemplateWriteMode = value;
            this.PropertyValueChanged('TemplateWriteMode');
            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, 'TemplateWriteMode', (<number>value).toString(), -1, 0);
        }
    }

    public get TemplateWriteMode(): WFTmpWriteMode {
        return this.m_doc.TemplateWriteMode;
    }

    public AddPage(pg: WFDocumentPage): void {
        if (this.m_doc.ID > 0 && !this.CanAddPage && !this.CanEditPages)
            throw new Error();
        this.m_doc.Pages.push(pg);
        this.IsModified = true;
        this.m_keywords = null;
        this.m_importedpgids.push(pg);

        if (this.OnPageModified != null)
            this.OnPageModified(this);
    }
    public AddPageRange(pgs: Array<WFDocumentPage>): void {
        if (pgs.length > 0) {
            if (this.m_doc.ID > 0 && !this.CanAddPage && !this.CanEditPages)
                throw new Error();
            for (let ii = 0; ii < pgs.length; ii++) {
                let pg = pgs[ii];
                this.m_doc.Pages.push(pg);
                this.m_importedpgids.push(pg);
            }
            this.IsModified = true;
            this.m_keywords = null;
            if (this.OnPageModified != null)
                this.OnPageModified(this);
        }
    }
    public GetPagesCount(): number {
        return this.m_doc.Pages.length;
    }

    public GetPage(id: number): WFDocumentPage {
        return this.m_doc.Pages[id];
    }

    public RemovePage(id: number): void {
        if (this.m_doc.ID > 0 && !this.CanAddPage && !this.CanEditPages)
            throw new Error();
        let pg = this.m_doc.Pages[id];
        this.m_doc.Pages.splice(id, 1);
        this.IsModified = true;
        this.m_keywords = null;
        let off = this.m_importedpgids.indexOf(pg);
        if (off >= 0) this.m_importedpgids.splice(off, 1);

        if (this.OnPageModified != null)
            this.OnPageModified(this);
    }

    public ClearPages(): void {
        if (this.m_doc.ID > 0 && !this.CanAddPage && !this.CanEditPages)
            throw new Error();
        this.m_doc.Pages.splice(0, this.m_doc.Pages.length);
        this.IsModified = true;
        this.m_keywords = null;
        this.m_importedpgids.splice(0, this.m_importedpgids.length);

        if (this.OnPageModified != null)
            this.OnPageModified(this);
    }
    public GetRefsCount(): number {
        return this.m_doc.Refs.length;
    }
    public GetRef(id: number): WFDocumentRef {
        return this.m_doc.Refs[id];
    }
    public get ApprovalForUserID(): number {
        if (this.m_doc.CheckApprove(this.m_conn.User.ID))
            return this.m_conn.User.ID;
        let usrs: number[] = this.m_conn.User.FindReplacements(this.m_conn.Users);
        return this.m_doc.CheckApprove2(usrs);
    }
    public get CanApprove(): boolean {
        if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH && this.m_doc.DocStatus === WFDocStatus.INSYSTEM && this.m_doc.DirectoryID >= 0) {
            if (this.ApprovalForUserID > 0)
                return true;
        }
        return false;
    }
    public Approve(comment: string, prms: Map<number, IWFDocumentAuthVariable>): boolean {
        let apuid: number = this.ApprovalForUserID;
        if (apuid > 0) {
            let ret: boolean = this.m_doc.ChangeStatus(apuid, this.m_conn.User.ID, true, comment, prms, this.m_conn.AppSystem);
            if (ret) this.IsModified = true;
            return ret;
        }
        return false;
    }
    public Disapprove(comment: string): boolean {
        let apuid: number = this.ApprovalForUserID;
        if (apuid > 0) {
            let ret: boolean = this.m_doc.ChangeStatus(apuid, this.m_conn.User.ID, false, comment, null, this.m_conn.AppSystem);
            if (ret) this.IsModified = true;
            return ret;
        }
        return false;
    }
    public get IsApprovedByMe(): boolean {
        let ashm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (ashm != null) {
            let rcp: WFDocumentAuthRecipient = ashm.FindAuthRecipient2(this.m_conn.User.ID, false, WFAuthStatus.APPROVED);
            if (rcp != null)
                return true;
            if (this.m_conn.User.ID === this.m_doc.UserID) {
                rcp = ashm.FindAuthRecipient2(WFAuthSchema.OBJID_OWNER, false, WFAuthStatus.APPROVED);
                if (rcp != null)
                    return true;
            }
        }
        return false;
    }
    public get IsApprovedByOther(): boolean {
        let ashm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (ashm != null) {
            let rcp: WFDocumentAuthRecipient = ashm.FindAuthRecipient2(this.m_conn.User.ID, false, WFAuthStatus.APPROVED);
            if (rcp != null) return false;
            if (this.m_conn.User.ID === this.m_doc.UserID) {
                rcp = ashm.FindAuthRecipient2(WFAuthSchema.OBJID_OWNER, false, WFAuthStatus.APPROVED);
                if (rcp != null) return false;
            }
            rcp = ashm.FindAuthRecipient2(this.m_conn.User.ID, false, WFAuthStatus.WAITING);
            if (rcp != null) return true;
            if (this.m_conn.User.ID === this.m_doc.UserID) {
                rcp = ashm.FindAuthRecipient2(WFAuthSchema.OBJID_OWNER, false, WFAuthStatus.WAITING);
                if (rcp != null) return true;
            }
        }
        return false;
    }
    public get IsRejectedByMe(): boolean {
        let ashm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (ashm != null) {
            let rcp: WFDocumentAuthRecipient = ashm.FindAuthRecipient2(this.m_conn.User.ID, false, WFAuthStatus.DISAPPROVED);
            if (rcp != null) return true;
            if (this.m_conn.User.ID === this.m_doc.UserID) {
                rcp = ashm.FindAuthRecipient2(WFAuthSchema.OBJID_OWNER, false, WFAuthStatus.DISAPPROVED);
                if (rcp != null) return true;
            }
        }
        return false;
    }
    public get IsRejectedByOther(): boolean {
        let ashm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (ashm != null) {
            let rcp: WFDocumentAuthRecipient = ashm.FindAuthRecipient2(this.m_conn.User.ID, false, WFAuthStatus.DISAPPROVED);
            if (rcp != null) return false;
            if (this.m_conn.User.ID === this.m_doc.UserID) {
                rcp = ashm.FindAuthRecipient2(WFAuthSchema.OBJID_OWNER, false, WFAuthStatus.DISAPPROVED);
                if (rcp != null) return false;
            }
            if (ashm.GetApprovedCount(WFAuthStatus.DISAPPROVED) > 0) {
                rcp = ashm.FindAuthRecipient2(this.m_conn.User.ID, false, WFAuthStatus.WAITING);
                if (rcp != null) return true;
                if (this.m_conn.User.ID === this.m_doc.UserID) {
                    rcp = ashm.FindAuthRecipient2(WFAuthSchema.OBJID_OWNER, false, WFAuthStatus.WAITING);
                    if (rcp != null) return true;
                }
            }
        }
        return false;
    }

    public get AuthPathsCount(): number {
        return this.m_doc.GetAuthPathsCount();
    }
    public GetAuthPath(id: number): WFDocumentAuthSchema {
        return this.m_doc.GetAuthPath(id);
    }

    public get AuthStatus(): WFAuthStatus {
        try {
            return this.m_doc.GetStatus();
        } catch (err) {
        }
        return WFAuthStatus.WAITING;
    }

    private IsDiffRecipients(uids: Array<WFSchemaRecipient>): boolean {
        let actrcps: Array<WFSchemaRecipient> = this.m_authrecipients;
        if (actrcps.length === uids.length) {
            for (let ii = 0; ii < actrcps.length; ii++) {
                let uid: WFSchemaRecipient = actrcps[ii];
                let doexit = true;
                for (let ii2 = 0; ii2 < uids.length; ii2++) {
                    let uid2: WFSchemaRecipient = uids[ii2];
                    if (uid.ObjType === uid2.ObjType && uid.ObjID === uid2.ObjID && uid.Order === uid2.Order) {
                        doexit = false;
                        break;
                    }
                }
                if (doexit)
                    return true;
            }
            return false;
        }
        return true;
    }

    private ExtractUsersAndGroups(rcps: Array<WFSchemaRecipient>, retusr: Array<number>, retgrp: Array<number>): void {
        for (let rc of rcps) {
            switch (rc.ObjType) {
                case WFObjType.AUTHSCHEMA:
                    let shm: WFAuthSchema = this.m_conn.AuthSchemas.get(rc.ObjID);
                    if (shm.ID !== rc.ObjID)
                        throw new Error();
                    this.ExtractUsersAndGroups(shm.Recipients, retusr, retgrp);
                    break;
                case WFObjType.USERGROUP:
                    if (retgrp.indexOf(rc.ObjID) < 0)
                        retgrp.push(rc.ObjID);
                    break;
                case WFObjType.USER:
                    let uuid: number = (rc.ObjID === WFAuthSchema.OBJID_OWNER) ? this.m_doc.UserID : rc.ObjID;
                    if (retusr.indexOf(uuid) < 0)
                        retusr.push(uuid);
                    break;
            }
        }
    }

    public ExtractUsersAndGroupsFromSchema(retusr: Array<number>, retgrp: Array<number>): void {
        let rcps: Array<WFSchemaRecipient>;
        if (this.m_authschemaid > 0) {
            let shm: WFAuthSchema = this.m_conn.AuthSchemas.get(this.m_authschemaid);
            if (shm.ID !== this.m_authschemaid)
                throw new Error();
            rcps = shm.Recipients;
        } else {
            rcps = this.m_authrecipients;
        }
        this.ExtractUsersAndGroups(rcps, retusr, retgrp);
    }

    private FillPermissions(): void {
        this.m_doc.Permissions.splice(0, this.m_doc.Permissions.length);

        let usrs: Array<number> = new Array<number>();
        let grps: Array<number> = new Array<number>();
        let rcps: Array<WFSchemaRecipient>;
        if (this.m_authschemaid > 0) {
            let shm: WFAuthSchema = this.m_conn.AuthSchemas.get(this.m_authschemaid);
            if (shm.ID !== this.m_authschemaid)
                throw new Error();
            rcps = shm.Recipients;
        } else {
            rcps = this.m_authrecipients;
        }
        this.ExtractUsersAndGroups(rcps, usrs, grps);

        for (let gid of grps) {
            this.m_doc.Permissions.push(WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, gid, WFPrmObjType.USERGROUP));
        }

        for (let uid of usrs) {
            this.m_doc.Permissions.push(WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, uid, WFPrmObjType.USER));
        }

        this.IsModified = true;
    }

    private CopyPermissions(): void {
        let tp: PrmCopyMethodType = PrmCopyMethodType.COPY_ASKUSR;
        if (this.m_doc.ClassID > 0) {
            let cl: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
            tp = cl.PermissionCopyMethodType;
        }

        const self = this;
        switch (tp) {
            case PrmCopyMethodType.COPY_NEVER:
                if (this.m_doc.Permissions.length > 0) {
                    this.m_conn.GlobalService.showWarning(BUTTONSTYPE.YESNO, this.m_conn.StringService.getStr('strAskClearRights'),
                        (wndres) => {
                            self.CopyClearPermAskClosed(wndres);
                        });
                }
                break;
            case PrmCopyMethodType.COPY_ASKUSR:
                this.m_conn.GlobalService.showWarning(BUTTONSTYPE.YESNO, this.m_conn.StringService.getStr('strAskCopySchema2Rights'),
                    (wndres) => {
                        self.CopyPermAskClosed(wndres);
                    });
                break;
            case PrmCopyMethodType.COPY_ALWAYS:
                if (this.m_doc.Permissions.length > 0) {
                    this.m_conn.GlobalService.showWarning(BUTTONSTYPE.YESNO, this.m_conn.StringService.getStr('strAskOverwriteRights'),
                        (wndres) => {
                            self.CopyPermAskClosed(wndres);
                        });
                } else {
                    this.FillPermissions();
                }
                break;
        }
    }

    private CopyClearPermAskClosed(wndres: WNDRESULT): void {
        try {
            if (wndres === WNDRESULT.OKYES) {
                this.m_doc.Permissions.splice(0, this.m_doc.Permissions.length);
                this.IsModified = true;
            }
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

    }

    private CopyPermAskClosed(wndres: WNDRESULT): void {
        try {
            if (wndres === WNDRESULT.OKYES) this.FillPermissions();
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }
    }

    public set AuthSchemaID(value: number) {
        if (this.m_authschemaid !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA))
                throw new Error();
            this.m_authschemaid = value;
            this.m_authrecipients.splice(0, this.m_authrecipients.length);
            if (this.m_authschemaid > 0)
                this.CopyPermissions();
            this.IsModified = true;
        }
    }

    public get AuthSchemaID(): number {
        return this.m_authschemaid;
    }

    public set SrcAuthSchemaID(value: number) {
        this.m_srcauthschemaid = value;
    }

    public get SrcAuthSchemaID(): number {
        return this.m_srcauthschemaid;
    }

    public SetRecipients(uids: Array<WFSchemaRecipient>): void {
        if (this.IsDiffRecipients(uids)) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_authrecipients = uids;
            this.IsModified = true;
            this.CopyPermissions();
        }
    }

    private LoadRecipients(): Array<WFSchemaRecipient> {
        let ii: number;
        let ret: Array<WFSchemaRecipient> = new Array<WFSchemaRecipient>();
        let cc: number = this.m_doc.GetAuthPathsCount();
        if (cc > 0) {
            let lshm: WFDocumentAuthSchema = this.m_doc.GetAuthPath(cc - 1);
            cc = lshm.GetRecipientsCount();
            for (ii = 0; ii < cc; ii++) {
                let arc: WFDocumentAuthRecipient = lshm.GetRecipient(ii);
                let shrc: WFSchemaRecipient = null;
                switch (arc.ObjType) {
                    case WFDocAuthObjType.DOCAUTHSCHEMA:
                        shrc = WFSchemaRecipient.Create(arc.SubSchema.AuthSchemaID, WFObjType.AUTHSCHEMA);
                        break;
                    case WFDocAuthObjType.USER:
                        shrc = WFSchemaRecipient.Create(arc.ObjID, WFObjType.USER);
                        break;
                }
                if (shrc != null) {
                    shrc.Order = arc.Order;
                    ret.push(shrc);
                }
            }
        }
        return ret;
    }
    public CloneRecipients(): Array<WFSchemaRecipient> {
        let ret: Array<WFSchemaRecipient>;
        if (this.m_authschemaid === WFDocument.SCHM_CUSTOM) {
            ret = new Array<WFSchemaRecipient>();
            for (let src of this.m_authrecipients) {
                ret.push(WFSchemaRecipient.Create2(src));
            }
        } else {
            ret = this.LoadRecipients();
        }
        return ret;
    }

    private IsDiffPermissions(prms: Array<WFPermission>): boolean {
        if (prms.length === this.m_doc.Permissions.length) {
            for (let npr of prms) {
                if (npr.ID === 0) return true;
                let doexit = true;
                for (let opr of this.m_doc.Permissions) {
                    if (opr.PropertyType === npr.PropertyType && npr.ID === opr.ID && npr.ObjID === opr.ObjID && npr.ObjType === opr.ObjType && npr.PermissionType === opr.PermissionType) {
                        doexit = false;
                        break;
                    }
                }
                if (doexit) return true;
            }
            return false;
        }
        return true;
    }

    public SetPermissions(prms: Array<WFPermission>): void {
        let prms2: Array<WFPermission> = new Array<WFPermission>();

        for (let npr of prms) {
            let doadd = true;
            for (let npr2 of prms2) {
                if (npr2.ObjType === npr.ObjType && npr2.ObjID === npr.ObjID && npr2.PermissionType === npr.PermissionType && npr2.PropertyType === npr.PropertyType) {
                    doadd = false;
                    break;
                }
            }

            if (doadd)
                prms2.push(npr);
        }

        if (this.IsDiffPermissions(prms2)) {
            if (!this.CanChangePermissions)
                throw new Error();
            this.m_doc.Permissions.splice(0, this.m_doc.Permissions.length);
            for (let dp of prms2) {
                this.m_doc.Permissions.push(dp);
            }
            this.IsModified = true;
        }
    }
    public ClonePermissions(): Array<WFPermission> {
        let ret: Array<WFPermission> = new Array<WFPermission>();
        for (let dprm of this.m_doc.Permissions) {
            let nprm: WFPermission = new WFPermission(dprm.ToObject());
            ret.push(nprm);
        }
        return ret;
    }
    public GetPermissionsCount(): number {
        return this.m_doc.Permissions.length;
    }
    public GetPermission(id: number): WFPermission {
        return this.m_doc.Permissions[id];
    }
    public set AuthMethodType(value: WFAuthMethodType) {
        if (this.m_authmthtype !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA))
                throw new Error();
            this.m_authmthtype = value;
            this.PropertyValueChanged('AuthMethodType');
        }
    }
    public get AuthMethodType(): WFAuthMethodType {
        return this.m_authmthtype;
    }
    public set AuthUserLimit(value: number) {
        if (this.m_authusrlimit !== value) {
            if (value < 1)
                throw new Error();
            if (this.m_doc.ID > 0 && !this.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA))
                throw new Error();
            this.m_authusrlimit = value;
            this.PropertyValueChanged('AuthUserLimit');
        }
    }
    public get AuthUserLimit(): number {
        return this.m_authusrlimit;
    }
    public set AdditionalInfo(value: string) {
        if (this.m_addinfo !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_addinfo = value;
            this.PropertyValueChanged('AdditionalInfo');
        }
    }
    public get AdditionalInfo(): string {
        return this.m_addinfo;
    }
    public set DocStatus(value: WFDocStatus) {
        if (value !== this.m_docstatus) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_docstatus = value;
            this.PropertyValueChanged('DocStatus');
        }
    }
    public get DocStatus(): WFDocStatus {
        return this.m_docstatus;
    }
    public set ReconSchemaCode(value: string) {
        if (value !== this.m_doc.ReconSchemasCode) {
            if (!this.CanChangePermissions)
                throw new Error();
            this.m_doc.ReconSchemasCode = value;
            this.PropertyValueChanged('ReconSchemaCode');
        }
    }
    public get ReconSchemaCode(): string {
        return this.m_doc.ReconSchemasCode;
    }
    public GetOcrResultsCount(): number {
        return this.m_doc.OcrResults.length;
    }
    public GetOcrResult(id: number): WFDocumentOcrResults {
        return this.m_doc.OcrResults[id];
    }
    public set OcrCode(value: string) {
        if (this.m_doc.OcrCode !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_doc.OcrCode = value;
            this.PropertyValueChanged('OcrCode');
        }
    }
    public get OcrCode(): string {
        return this.m_doc.OcrCode;
    }
    public set DefinitionCode(value: string) {
        if (this.m_doc.DefinitionCode !== value) {
            if (this.m_doc.ID > 0 && !this.CanEdit(null))
                throw new Error();
            this.m_doc.DefinitionCode = value;
            this.PropertyValueChanged('DefinitionCode');
        }
    }
    public get DefinitionCode(): string {
        return this.m_doc.DefinitionCode;
    }
    public get AllAttributes(): Array<WFClassAttrib> {
        return this.m_allattribs;
    }
    public get AllColumns(): Array<WFClassAttrib> {
        return this.m_allcolumns;
    }

    /*public static DATEFORMATS: string[] = ["d",
        "d-M-yy",
        "d-MM-yy",
        "d-M-yyyy",
        "d-MM-yyyy",
        "dd-M-yy",
        "dd-MM-yy",
        "dd-M-yyyy",
        "dd-MM-yyyy",
        "d/M/yy",
        "d/MM/yy",
        "d/M/yyyy",
        "d/MM/yyyy",
        "dd/M/yy",
        "dd/MM/yy",
        "dd/M/yyyy",
        "dd/MM/yyyy",
        "yy-M-d",
        "yy-MM-d",
        "yyyy-M-d",
        "yyyy-MM-d",
        "yy-M-dd",
        "yy-MM-dd",
        "yyyy-M-dd",
        "yyyy-MM-dd",
        "yy/M/d",
        "yy/MM/d",
        "yyyy/M/d",
        "yyyy/MM/d",
        "yy/M/dd",
        "yy/MM/dd",
        "yyyy/M/dd",
        "yyyy/MM/dd"];*/

    protected SetValue(key: string, value: string, checkprm: boolean): void {
        let atrs: Array<WFClassAttrib> = (this.m_doc.ClassID > 0) ? this.m_allattribs : null;
        let sval: string = super._SetValue(key, value, checkprm, atrs);
        if (sval != null) {
            key = key.toUpperCase();
            this.PropertyValueChanged(IWFObject.Format('_{0}', key));

            let atrid= 0;
            if (atrs != null) {
                for (let att of atrs) {
                    if (att.Name.toUpperCase() === key) {
                        atrid= att.ID;
                        break;
                    }
                }
            }

            this.FireTrigger(WFProcessAction.EVENT_VALIDATED, key, sval, -1, atrid);
        } else {
            if(IWFObject.IsNullOrEmpty(value)) this.IsModified = true;
        }
    }

    public EvalExCode(code: string, lid: number, rtp: WFFieldType): Object {
        //if (this.m_doc.ClassID > 0) {
        //let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);

        let evalrt: Object = this.m_doc.EvalScript(code, this.m_conn.AppSystem, lid); // HtmlPage.Window.Eval(code);
        if (evalrt != null) {
            let sval: string;
            switch (rtp) {
                case WFFieldType.TYPE_FLOAT:
                    let faval = 0;
                    if (typeof evalrt === 'number') {
                        faval = <number>evalrt;
                    } else {
                        sval = evalrt.toString();
                        if (sval.length > 0) {
                            sval = sval.replace(',', '.');
                            faval = IWFObject.ParseFloat(sval);
                        }
                    }
                    return faval;
                case WFFieldType.TYPE_INT:
                    let iaval = 0;
                    if (typeof evalrt === 'number') {
                        iaval = <number>evalrt;
                    } else {
                        sval = evalrt.toString();
                        if (sval.length > 0)
                            iaval = IWFObject.ParseInt(sval);
                    }
                    return iaval;
                case WFFieldType.TYPE_DATETIME:
                    let daval: Date = null;
                    if (evalrt instanceof Date) {
                        daval = <Date>evalrt;
                    } else {
                        sval = evalrt.toString();
                        if (sval.length > 0)
                            daval = new Date(Date.parse(sval));
                    }
                    return daval;
                default:
                    return evalrt.toString();
            }
        }
        //}
        return null;
    }

    private EvalColumn(cls: WFClass, cl: WFClassAttrib, from: number, to:number): void {
        let ii: number;
        let ln: WFDocumentLineAdp;

        let datep = new DatePipe('en-US');
        let evalins = this.m_doc.PrepareScript(cl.EvalCode, this.m_conn.AppSystem);
        let lidoff = evalins.findIndex((tst) => (tst.indexOf('var LID') >= 0));

        if(to > this.m_lines.length) to= this.m_lines.length;
        for (ii = from; ii < to; ii++) {
            ln = this.m_lines[ii];
            //HtmlPage.Window.SetProperty("LID", ii);

            evalins[lidoff] = this.m_doc.GetJSProperty('LID', ii);          
            let evalrt: Object = eval(evalins.join('\n')); //HtmlPage.Window.Eval(cl.EvalCode);
            let soval: string = ln.get(cl.Name);
            if (soval == null) soval = '';

            if (evalrt == null) {
                if (!IWFObject.IsNullOrEmpty(soval)) ln.SetValue('', cls, cl);
            } else {
                let sval: string;
                let chg: boolean;
                switch (cl.Type) {
                    case WFFieldType.TYPE_FLOAT:
                        let faval = 0;
                        if (typeof evalrt === 'number') {
                            faval = <number>evalrt;
                        } else {
                            sval = evalrt.toString();
                            if (sval.length > 0) {
                                sval = sval.replace(',', '.');
                                faval = IWFObject.ParseFloat(sval);
                            }
                        }
                        let fbval = 0;
                        if (soval.length > 0)
                            fbval = IWFObject.ParseFloat(soval);
                        chg = (faval !== fbval);
                        sval = faval.toString().replace(',', '.');
                        break;
                    case WFFieldType.TYPE_INT:
                        let iaval = 0;
                        if (typeof evalrt === 'number') {
                            iaval = <number>evalrt | 0;
                        } else {
                            sval = evalrt.toString();
                            if (sval.length > 0)
                                iaval = IWFObject.ParseInt(sval);
                        }
                        let ibval = 0;
                        if (soval.length > 0)
                            ibval = IWFObject.ParseInt(soval);
                        chg = (iaval !== ibval);
                        sval = iaval.toString();
                        break;
                    case WFFieldType.TYPE_DATETIME:
                        let daval: Date = null;
                        if (evalrt instanceof Date) {
                            daval = <Date>evalrt;
                        } else {
                            sval = evalrt.toString();
                            if (sval.length > 0)
                                daval = new Date(Date.parse(sval));
                        }
                        let dbval: Date = null;
                        if (soval.length > 0)
                            dbval = new Date(Date.parse(soval));
                        chg = (daval !== dbval);
                        sval = datep.transform(daval, 'dd-MM-yyyy');
                        break;
                    default:
                        sval = evalrt.toString();
                        chg = (soval !== sval);
                        break;
                }

                if (chg) {              
                    ln.SetValue(sval, cls, cl);                    
                }            
            }
        }
    }

    public LineValueChanged(cls: WFClass, sndcol: WFClassAttrib, sender: WFDocumentLine, sval: string, firechg: boolean= true): void {
        let upname: string = sndcol.Name.toUpperCase();
        let lid: number = this.m_doc.Lines.indexOf(sender);

        if(firechg) {
            for (let cl of this.m_allcolumns) {
                if (cl !== sndcol) {
                    if (!IWFObject.IsNullOrEmpty(cl.EvalCode)) {
                        if (cl.EvalCode.indexOf(upname) >= 0) {
                            this.EvalColumn(cls, cl, lid, lid + 1);
                        }
                    }
                }
            }

            this.EvalPropertyValueChanged(cls, upname, lid, lid + 1);
        }        

        this.IsModified = true; 
        this.FireTrigger(WFProcessAction.EVENT_VALIDATED, upname, sval, lid, sndcol.ID);
    }

    /*private RefreshJSFields(cls: WFClass, evcd: string, lid: number): EvalJSPrms {
        let ii: number, ii2;
        let ln: WFDocumentLineAdp;
        let sval: string;
        let usr: WFUser = (this.m_doc.UserID === this.m_conn.User.ID) ? this.m_conn.User : this.m_conn.Users.get(this.m_doc.UserID);

        let args = new Array<string>();
        let values = new Array<Object>();

        args.push('USERID'); values.push(this.m_doc.UserID);
        HtmlPage.Window.SetProperty("USER", usr.Name);
        HtmlPage.Window.SetProperty("USERNAME", usr.UserName);
        HtmlPage.Window.SetProperty("USEREMAIL", usr.EMailReply);
        HtmlPage.Window.SetProperty("CLASSID", this.m_doc.ClassID);
        HtmlPage.Window.SetProperty("COMPANYID", this.m_doc.CompanyID);
        HtmlPage.Window.SetProperty("NAME", this.m_doc.Name);
        HtmlPage.Window.SetProperty("DESCRIPTION", this.m_doc.Description);
        HtmlPage.Window.SetProperty("DOCNUM", this.m_doc.DocNum);
        HtmlPage.Window.SetProperty("DOCDATE", this.m_doc.DocDate);

        for(let satr of this.m_allattribs) {
            let uatrnm: string = satr.Name.toUpperCase();
            let pval: Object = null;
            switch (satr.Type) {
                case WFFieldType.TYPE_FLOAT:
                    pval = (this.m_doc.Properties.ContainsKey(uatrnm)) ? number.Parse(this.m_doc.Properties.get(uatrnm), NumberStyles.Float, CultureInfo.InvariantCulture.NumberFormat) : <number>0;
                    break;
                case WFFieldType.TYPE_INT:
                    pval = (this.m_doc.Properties.ContainsKey(uatrnm)) ? number.Parse(this.m_doc.Properties.get(uatrnm)) : <number>0;
                    break;
                case WFFieldType.TYPE_DATETIME:
                    pval = (this.m_doc.Properties.ContainsKey(uatrnm)) ? Date.ParseExact(this.m_doc.Properties.get(uatrnm), WFDocumentAdp.DATEFORMATS, CultureInfo.InvariantCulture, DateTimeStyles.None) : Date.MinValue;
                    break;
                default:
                    pval = (this.m_doc.Properties.ContainsKey(uatrnm)) ? this.m_doc.Properties.get(uatrnm) : "";
                    break;
            }
            HtmlPage.Window.SetProperty(String.Format("_{0}", uatrnm), pval);
        });
        let lines: Object[]
        [];
        if (this.m_allcolumns.Count > 0 && (evcd.IndexOf("L[") >= 0 || evcd.IndexOf("L.") >= 0 || evcd.IndexOf("lsum") >= 0)) {
            lines = new Array(this.m_lines.Count);
            for (; ii < this.m_lines.Count; ii++) {
                lines[ii] = new Array(this.m_allcolumns.Count);
            }
            for (; ii < this.m_allcolumns.Count; ii++) {
                let col: WFClassAttrib = this.m_allcolumns[ii];
                HtmlPage.Window.SetProperty(col.Name.ToUpper(), ii);
                switch (col.Type) {
                    case WFFieldType.TYPE_INT:
                        for (; ii2 < this.m_lines.Count; ii2++) {
                            ln = this.m_lines[ii2];
                            sval = ln.get(col.Name);
                            lines[ii2][ii] = (!String.IsNullOrEmpty(sval)) ? number.Parse(sval) : <number>0;
                        }
                        break;
                    case WFFieldType.TYPE_FLOAT:
                        for (; ii2 < this.m_lines.Count; ii2++) {
                            ln = this.m_lines[ii2];
                            sval = ln.get(col.Name);
                            lines[ii2][ii] = (!String.IsNullOrEmpty(sval)) ? number.Parse(sval, NumberStyles.Float, CultureInfo.InvariantCulture.NumberFormat) : <number>0;
                        }
                        break;
                    case WFFieldType.TYPE_DATETIME:
                        for (; ii2 < this.m_lines.Count; ii2++) {
                            ln = this.m_lines[ii2];
                            sval = ln.get(col.Name);
                            lines[ii2][ii] = (!String.IsNullOrEmpty(sval)) ? Date.ParseExact(sval, WFDocumentAdp.DATEFORMATS, CultureInfo.InvariantCulture, DateTimeStyles.None) : Date.MinValue;
                        }
                        break;
                    default:
                        for (; ii2 < this.m_lines.Count; ii2++) {
                            ln = this.m_lines[ii2];
                            sval = ln.get(col.Name);
                            lines[ii2][ii] = (!String.IsNullOrEmpty(sval)) ? sval : "";
                        }
                        break;
                }
            }
        }
        else {
            lines = null;
        }
        HtmlPage.Window.SetProperty("L", lines);
        HtmlPage.Window.SetProperty("LID", lid);
        if (this.m_initjs) {
            let bld: StringBuilder = new StringBuilder();
            bld.Append("function lsum(lcnd,lop) { ");
            bld.Append("var ret= 0; ");
            bld.Append("if(lcnd=='') { ");
            bld.Append("for(var X=0; X< L.length; X++) ret+= eval(lop); ");
            bld.Append("} else { ");
            bld.Append("for(var X=0; X< L.length; X++) { ");
            bld.Append("if(eval(lcnd)) ret+=eval(lop); ");
            bld.Append("}} ");
            bld.Append("return ret; ");
            bld.Append("} ");
            if (!String.IsNullOrEmpty(m_conn.Config.JSFunctions)) {
                bld.AppendLine(m_conn.Config.JSFunctions);
            }
            HtmlPage.Window.Eval(bld.ToString());
            this.m_initjs = false;
        }

        return null;
    }*/

    private EvalPropertyValueChanged(cls: WFClass, upname: string, from: number=0, to: number=0): void {
        let chpname: boolean = !IWFObject.IsNullOrEmpty(upname);
        let datep = new DatePipe('en-US');

        for (let atr of this.m_allattribs) {
            if (!IWFObject.IsNullOrEmpty(atr.EvalCode)) {
                if (chpname) {
                    if (atr.EvalCode.indexOf(upname) < 0)
                        continue;
                }

                let evalrt: Object = this.m_doc.EvalScript(atr.EvalCode, this.m_conn.AppSystem, -1);
                let uatrnm: string = atr.Name.toUpperCase();
                let soval: string = (this.m_doc.Properties.has(uatrnm)) ? this.m_doc.Properties.get(uatrnm) : '';
                if (evalrt == null) {
                    if (!IWFObject.IsNullOrEmpty(soval)) {
                        this.m_doc.Properties.delete(uatrnm);
                        this.PropertyValueChanged2(IWFObject.Format('_{0}', uatrnm), cls);
                    }
                } else {
                    let sval: string;
                    let chg: boolean;
                    switch (atr.Type) {
                        case WFFieldType.TYPE_FLOAT:
                            let faval = 0;
                            if (typeof evalrt === 'number') {
                                faval = <number>evalrt;
                            } else {
                                sval = evalrt.toString();
                                if (sval.length > 0) {
                                    sval = sval.replace(',', '.');
                                    faval = IWFObject.ParseFloat(sval);
                                }
                            }
                            let fbval = 0;
                            if (soval.length > 0)
                                fbval = IWFObject.ParseFloat(soval);
                            chg = (faval !== fbval);
                            sval = faval.toString().replace(',', '.');
                            break;
                        case WFFieldType.TYPE_INT:
                            let iaval = 0;
                            if (typeof evalrt === 'number') {
                                iaval = <number>evalrt | 0;
                            } else {
                                sval = evalrt.toString();
                                if (sval.length > 0)
                                    iaval = IWFObject.ParseInt(sval);
                            }
                            let ibval = 0;
                            if (soval.length > 0)
                                ibval = IWFObject.ParseInt(soval);
                            chg = (iaval !== ibval);
                            sval = iaval.toString();
                            break;
                        case WFFieldType.TYPE_DATETIME:
                            let daval: Date = null;
                            if (evalrt instanceof Date) {
                                daval = <Date>evalrt;
                            } else {
                                sval = evalrt.toString();
                                if (sval.length > 0)
                                    daval = new Date(Date.parse(sval));
                            }
                            let dbval: Date = null;
                            if (soval.length > 0)
                                dbval = new Date(Date.parse(soval));
                            chg = (daval !== dbval);
                            sval = datep.transform(daval, 'dd-MM-yyyy');
                            break;
                        default:
                            sval = evalrt.toString();
                            chg = (soval !== sval);
                            break;
                    }

                    if (chg) {
                        this.m_doc.Properties.set(uatrnm, sval);
                        this.PropertyValueChanged2(IWFObject.Format('_{0}', uatrnm), cls);
                    }
                }
            }
        }

        for (let col of this.m_allcolumns) {
            if (!IWFObject.IsNullOrEmpty(col.EvalCode)) {
                if (chpname) {
                    if (col.EvalCode.indexOf(upname) < 0)
                        continue;
                }

                if(from < to)
                    this.EvalColumn(cls, col, from, to);
                else
                    this.EvalColumn(cls, col, 0, this.m_lines.length);
            }
        }
    }


    private PropertyValueChanged2(pname: string, cls: WFClass): void {
        let upname: string = pname.toUpperCase();
        this.EvalPropertyValueChanged(cls, upname);
        if (this.OnPropertyValueChange != null)
            this.OnPropertyValueChange(this, pname);
    }
    private PropertyValueChanged(pname: string): void {
        if (this.m_doc.ClassID > 0) {
            let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
            let upname: string = pname.toUpperCase();
            if (upname === 'CLASSID')
                upname = null;
            this.EvalPropertyValueChanged(cls, upname);
        }
        this.IsModified = true;
        if (this.OnPropertyValueChange != null)
            this.OnPropertyValueChange(this, pname);
    }

    public get CanLockAuthSchema(): boolean {
        return this.m_doc.CanLockAuthSchema(this.m_conn.User.ID);
    }

    public LockAuthSchema(): boolean {
        let ret: boolean = this.m_doc.LockAuthSchema(this.m_conn.User.ID, this.m_conn.AppSystem);
        if (ret) this.IsModified = true;
        return ret;
    }

    public get CanUnLockAuthSchema(): boolean {
        return this.m_doc.CanUnLockAuthSchema(this.m_conn.User.ID);
    }

    public UnLockAuthSchema(): boolean {
        let ret: boolean = this.m_doc.UnLockAuthSchema(this.m_conn.User.ID, this.m_conn.AppSystem);
        if (ret) this.IsModified = true;
        return ret;
    }

    public Reload(uobj: Object): void {
        const self = this;
        this.m_conn.DataService.getDocuments(this.m_conn.SessionID, [this.m_doc.ID], false, this.m_OnError,
            (docs, ronly) => {
                self.GetDocumentsCompleted(docs, uobj, ronly);
            });
    }

    private GetDocumentsCompleted(docs: Array<WFDocument>, uobj: Object, ronly: Array<number>): void {
        try {
            this.m_doc = docs[0];
            this.m_readonly= ronly.includes(this.m_doc.ID);
            this.m_props = this.m_doc.Properties;
            this.m_tmp_filename = '';
            this.m_tmp_document_b64 = null;
            this.m_tmp_resources_id = 0;
            this.m_tmp_type = TempCtxType.UNSET;
            this.ReloadLines();
            this.ReloadLocals();
            this.RefreshAttribs();
            this.IsModified = false;
            const self = this;
            if (this.m_doc.DocStatus === WFDocStatus.BEFOREOCR || this.m_doc.DocStatus === WFDocStatus.INOCR || this.m_doc.DocStatus === WFDocStatus.AFTEROCR) {
                this.m_conn.DataService.checkNewOcrLogs(this.m_conn.SessionID, this.m_doc.ID, this.m_OnError,
                    (nlogs) => {
                        self.CheckNewOcrLogsCompleted(nlogs, uobj);
                    });
            } else {
                this.m_conn.DataService.getDailyCorRefs(this.m_conn.SessionID, this.m_doc.ID, this.m_OnError,
                    (links) => {
                        self.GetDailyCorRefsCompleted(links, uobj);
                    });
                //if (this.OnLoadDocument != null) this.OnLoadDocument(this, true, uobj);
            }
            return;

        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        if (this.OnLoadDocument != null) this.OnLoadDocument(this, false, uobj);
    }

    private CheckNewOcrLogsCompleted(nlogs: number, uobj: Object): void {
        this.m_newocrlogs = nlogs;
        const self = this;
        this.m_conn.DataService.getDailyCorRefs(this.m_conn.SessionID, this.m_doc.ID, this.m_OnError,
            (links) => {
                self.GetDailyCorRefsCompleted(links, uobj);
            });
        //if (this.OnLoadDocument != null) this.OnLoadDocument(this, true, uobj);
    }

    private GetDailyCorRefsCompleted(links: Array<DailyCorRefInfo>, uobj: Object): void {
        this.m_dailycorrefs = links;
        if (this.OnLoadDocument != null) this.OnLoadDocument(this, true, uobj);
    }

    public get CanClearAuthStatus(): boolean {
        let lshm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (lshm != null) {
            let disap: boolean = (lshm.GetApprovedCount(WFAuthStatus.DISAPPROVED) > 0);
            if (disap) {
                if (this.IsCreator()) return true;

                for (let gr of Array.from(this.m_conn.UserGroups.values())) {
                    if (gr.GroupRole === WFGroupRole.CREATORS) {
                        if (gr.Users.indexOf(this.m_doc.UserID) >= 0 && gr.Users.indexOf(this.m_conn.User.ID) >= 0) return true;
                    }
                }
            }
        }
        return false;
    }

    public ClearAuthStatus(): void {
        if (this.CanClearAuthStatus) {
            this.m_doc.CloneLastAuthPath(this.m_conn.AppSystem);
            this.IsModified = true;
        }
    }

    public CanView(): boolean {
        if (this.m_conn.IsSuperUser) return true;

        let iscreator = false;
        if (this.IsCreator()) {
            iscreator = true;
        } else {
            for (let gr of Array.from(this.m_conn.UserGroups.values())) {
                if (gr.GroupRole === WFGroupRole.CREATORS) {
                    if (gr.Users.indexOf(this.m_doc.UserID) >= 0 && gr.Users.indexOf(this.m_conn.User.ID) >= 0) {
                        iscreator = true;
                        break;
                    }
                }
            }
        }

        let visible = true;
        if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {
            visible = this.m_doc.CheckVisibility(this.m_conn.User, this.m_conn.AppSystem);
        } else {
            visible = iscreator;
        }

        return visible;
    }


    private FindVisibleSubSchema(shm: WFDocumentAuthSchema, uid: number): WFDocumentAuthSchema {
        let ii: number;
        let cc: number = shm.GetRecipientsCount();
        for (ii = 0; ii < cc; ii++) {
            let arc: WFDocumentAuthRecipient = shm.GetRecipient(ii);
            switch (arc.ObjType) {
                case WFDocAuthObjType.DOCAUTHSCHEMA:
                    let rsh: WFDocumentAuthSchema = this.FindVisibleSubSchema(arc.SubSchema, uid);
                    if (rsh != null)
                        return rsh;
                    break;
                case WFDocAuthObjType.USER:
                    if (arc.ObjID === uid && arc.Visibility)
                        return shm;
                    break;
            }
        }
        return null;
    }

    private FindVisibleSchema(shm: WFDocumentAuthSchema, uid: number): FindVisibleSchemaResult {
        let ii: number;
        let cc: number = shm.GetRecipientsCount();
        for (ii = 0; ii < cc; ii++) {
            let arc: WFDocumentAuthRecipient = shm.GetRecipient(ii);
            switch (arc.ObjType) {
                case WFDocAuthObjType.DOCAUTHSCHEMA:
                    let rsh: WFDocumentAuthSchema = this.FindVisibleSubSchema(arc.SubSchema, uid);
                    if (rsh != null)
                        return new FindVisibleSchemaResult(arc, rsh);
                    break;
                case WFDocAuthObjType.USER:
                    if (arc.ObjID === uid && arc.Visibility)
                        return new FindVisibleSchemaResult(arc, shm);
                    break;
            }
        }
        return null;
    }

    private CanEdit2(atrid: number, mode: WFAuthMode): boolean {
        let allow = false;
        let iscreator = false;
        let isown: boolean;

        if (this.IsCreator()) {
            iscreator = true;
            isown = true;
        } else {
            isown = false;
            for (let gr of Array.from(this.m_conn.UserGroups.values())) {
                if (gr.GroupRole === WFGroupRole.CREATORS) {
                    if (gr.Users.indexOf(this.m_doc.UserID) >= 0 && gr.Users.indexOf(this.m_conn.User.ID) >= 0) {
                        iscreator = true;
                        break;
                    }
                }
            }
        }


        let pinfs: Array<WFPermissionInfo>;
        let replusrs: number[] = this.m_conn.User.FindReplacements(this.m_conn.Users);
        if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {

            let stage: WFPrmDocState = WFPrmDocState.APPROVEDNOAUTH;
            let authschmid = 0;

            if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                let authst: WFAuthStatus = this.m_doc.GetStatus();
                let lshm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
                authschmid = lshm.AuthSchemaID;

                if (authst === WFAuthStatus.WAITING) {
                    if (mode == WFAuthMode.MODE_IN_LINES) allow = true;
                    //wylicz widocznosc
                    stage = WFPrmDocState.INAUTH;
                    //allow = (this.m_doc.GetDefaultEditMode(authst, mode) === WFAuthEditMode.MODE_DENY) ? false : true;
                    //

                    if (atrid > 0 || SYSPERMFIELDS.indexOf(atrid) >= 0) {
                        pinfs = this.m_doc.ListPermissions2(true, atrid, this.m_conn.AppSystem, stage, authschmid);
                    } else {
                        pinfs = this.m_doc.ListPermissions(true, WFPropType.DOC_CANEDIT, this.m_conn.AppSystem, stage, authschmid);
                    }

                    for (let pinf of pinfs) {
                        if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                            allow = (pinf.Type === WFPrmType.CHANGE);
                            break;
                        }
                    }

                    //

                    let appuserid = this.m_conn.User.ID;
                    if (!this.m_doc.CheckApprove(this.m_conn.User.ID)) {
                        for (let uid of replusrs) {
                            if (this.m_doc.CheckApprove(uid)) {
                                appuserid = uid;
                                break;
                            }
                        }
                    }

                    let schmid = 0;
                    let intinsid = 0;
                    if (lshm != null) {
                        let aschm: FindVisibleSchemaResult = this.FindVisibleSchema(lshm, appuserid);
                        if (aschm == null) {
                            if (appuserid === this.m_doc.UserID)
                                aschm = this.FindVisibleSchema(lshm, WFAuthSchema.OBJID_OWNER);
                        }
                        if (aschm != null) {
                            schmid = aschm.Schema.AuthSchemaID;
                            intinsid = aschm.Recipient.InstructionInternalID;
                        }
                    }

                    let prmset: Array<WFAuthPermission> = (mode === WFAuthMode.MODE_IN_HEADER) ? this.m_doc.HeaderExceptions : this.m_doc.LinesExceptions;
                    if (prmset.length > 0) {
                        let sq: WFAuthSeq = WFAuthSeq.WAITING;

                        for (let prm of prmset) {
                            if ((prm.Property === WFPropType.DOC_CANEDIT) && (prm.AttribsID === 0 || prm.AttribsID === atrid) && (prm.AuthSeq === WFAuthSeq.ALWAYS || prm.AuthSeq === sq)) {
                                switch (prm.ObjType) {
                                    case WFAuthPrmObjType.USER:
                                        if (prm.ObjID === WFAuthSchema.OBJID_OWNER) {
                                            if (isown)
                                                allow = prm.Allow;
                                        } else {
                                            if (prm.ObjID === appuserid)
                                                allow = prm.Allow;
                                        }
                                        break;
                                    case WFAuthPrmObjType.USERGROUP:
                                        if (prm.ObjID > 0) {
                                            let ugr: WFUserGroup = this.m_conn.UserGroups.get(prm.ObjID);
                                            if (ugr.Users.indexOf(appuserid) >= 0)
                                                allow = prm.Allow;
                                        } else {
                                            allow = prm.Allow;
                                        }
                                        break;
                                    case WFAuthPrmObjType.AUTHSCHEMA:
                                        if (prm.ObjID === schmid)
                                            allow = prm.Allow;
                                        break;
                                    case WFAuthPrmObjType.AUTHINSTRUCTION:
                                        if (prm.ObjID === intinsid || prm.ObjID === 0)
                                            allow = prm.Allow;
                                        break;
                                }
                            }
                        }
                    }
                    
                    return allow;
                }

                if (authst === WFAuthStatus.DISAPPROVED) {
                    stage = WFPrmDocState.DISAPPROVED;
                    allow = iscreator;
                }

                
                //authsts
            }

            //

            if (atrid > 0 || SYSPERMFIELDS.indexOf(atrid) >= 0) {
                pinfs = this.m_doc.ListPermissions2(true, atrid, this.m_conn.AppSystem, stage, authschmid);
            } else {
                pinfs = this.m_doc.ListPermissions(true, WFPropType.DOC_CANEDIT, this.m_conn.AppSystem, stage, authschmid);                
            }

            for (let pinf of pinfs) {
                if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                    allow = (pinf.Type === WFPrmType.CHANGE);
                    break;
                }
            }


        } else {

            allow = iscreator;
            if (atrid > 0) {
                pinfs = this.m_doc.ListPermissions2(true, atrid, this.m_conn.AppSystem, WFPrmDocState.BEFOREAUTH, this.m_doc.AuthSchemaID);
                for (let pinf of pinfs) {
                    if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                        allow = (pinf.Type === WFPrmType.CHANGE);
                        break;
                    }
                }
            }
        }

        return allow;
    }

    public CanEdit3(atrid: number): boolean {
        if ((this.m_doc.ID > 0) && (this.m_doc.DocStatus !== WFDocStatus.INOCR) && (this.m_doc.DirectoryID >= 0)) {
            
            if (this.m_conn.IsSuperUser) 
                return true;            

            let ret= this.CanEdit2(atrid, WFAuthMode.MODE_IN_HEADER);           
            return ret;
        }
        return false;
    }

    public CanEdit(atr: WFClassAttrib): boolean {        
        return this.CanEdit3((atr == null) ? 0 : atr.ID);
    }

    private CheckPermissions(ptp: WFPropType, replusrs: number[], stage: WFPrmDocState, authschmid: number, isown: boolean): WFPrmType {

        let pinfs = this.m_doc.ListPermissions(true, ptp, this.m_conn.AppSystem, stage, authschmid);
        for (let pinf of pinfs) {
            if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                return pinf.Type;
            }
        }

        return null;
    }

    public CanEditLines(col: WFClassAttrib, line: WFDocumentLineAdp): WFPrmType {
        if ((this.m_doc.ID > 0) && (this.m_doc.DocStatus !== WFDocStatus.INOCR) && (this.m_doc.DirectoryID >= 0)) {
            if (this.m_conn.IsSuperUser) return WFPrmType.CHANGE;

            let allow: WFPrmType = (this.CanEdit2((col == null) ? 0 : col.ID, WFAuthMode.MODE_IN_LINES)) ? WFPrmType.CHANGE : WFPrmType.VIEW;

            //if (col == null && line == null) console.log('allow '+allow);

            if (line != null && allow === WFPrmType.CHANGE && line.UserID !== this.m_conn.User.ID && this.m_docstatus === WFDocStatus.INSYSTEM) {

                let iscreator = false;
                let isown: boolean;
                if (this.IsCreator()) {
                    iscreator = true;
                    isown = true;
                } else {
                    isown = false;
                    for (let gr of Array.from(this.m_conn.UserGroups.values())) {
                        if (gr.GroupRole === WFGroupRole.CREATORS) {
                            if (gr.Users.indexOf(this.m_doc.UserID) >= 0 && gr.Users.indexOf(this.m_conn.User.ID) >= 0) {
                                iscreator = true;
                                break;
                            }
                        }
                    }
                }

                //let pinfs: Array<WFPermissionInfo>;
                let replusrs: number[] = this.m_conn.User.FindReplacements(this.m_conn.Users);
                allow = WFPrmType.VIEW;

                let stage: WFPrmDocState = WFPrmDocState.APPROVEDNOAUTH;
                let authschmid = 0;
                let sallow: WFPrmType = null;

                if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                    let authst: WFAuthStatus = this.m_doc.GetStatus();
                    let lshm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
                    authschmid = lshm.AuthSchemaID;

                    if (authst === WFAuthStatus.WAITING) {
                        stage = WFPrmDocState.INAUTH;

                        sallow = this.CheckPermissions(WFPropType.DOC_CANEDITLINES, replusrs, stage, authschmid, isown);
                        if (sallow != null) allow = sallow;

                        let appuserid: number = this.m_conn.User.ID;
                        if (!this.m_doc.CheckApprove(this.m_conn.User.ID)) {
                            for (let uid of replusrs) {
                                if (this.m_doc.CheckApprove(uid)) {
                                    appuserid = uid;
                                    break;
                                }
                            }
                        }

                        let schmid = 0;
                        let intinsid = 0;
                        if (lshm != null) {
                            let aschm: FindVisibleSchemaResult = this.FindVisibleSchema(lshm, appuserid);
                            if (aschm == null) {
                                if (appuserid === this.m_doc.UserID)
                                    aschm = this.FindVisibleSchema(lshm, WFAuthSchema.OBJID_OWNER);
                            }
                            if (aschm != null) {
                                schmid = aschm.Schema.AuthSchemaID;
                                intinsid = aschm.Recipient.InstructionInternalID;
                            }
                        }


                        let prmset: Array<WFAuthPermission> = this.m_doc.HeaderExceptions;
                        if (prmset.length > 0) {
                            let sq: WFAuthSeq = WFAuthSeq.WAITING;
                            for (let prm of prmset) {
                                if ((prm.Property === WFPropType.DOC_CANEDITLINES) && (prm.AuthSeq === WFAuthSeq.ALWAYS || prm.AuthSeq === sq)) {
                                    switch (prm.ObjType) {
                                        case WFAuthPrmObjType.USER:
                                            if (prm.ObjID === WFAuthSchema.OBJID_OWNER) {
                                                if (isown)
                                                    allow = (prm.Allow) ? WFPrmType.CHANGE : WFPrmType.VIEW;
                                            } else {
                                                if (prm.ObjID === appuserid)
                                                    allow = (prm.Allow) ? WFPrmType.CHANGE : WFPrmType.VIEW;
                                            }
                                            break;
                                        case WFAuthPrmObjType.USERGROUP:
                                            if (prm.ObjID > 0) {
                                                let ugr: WFUserGroup = this.m_conn.UserGroups.get(prm.ObjID);
                                                if (ugr.Users.indexOf(appuserid) >= 0)
                                                    allow = (prm.Allow) ? WFPrmType.CHANGE : WFPrmType.VIEW;
                                            } else {
                                                allow = (prm.Allow) ? WFPrmType.CHANGE : WFPrmType.VIEW;
                                            }
                                            break;
                                        case WFAuthPrmObjType.AUTHSCHEMA:
                                            if (prm.ObjID === schmid)
                                                allow = (prm.Allow) ? WFPrmType.CHANGE : WFPrmType.VIEW;
                                            break;
                                        case WFAuthPrmObjType.AUTHINSTRUCTION:
                                            if (prm.ObjID === intinsid || prm.ObjID === 0)
                                                allow = (prm.Allow) ? WFPrmType.CHANGE : WFPrmType.VIEW;
                                            break;
                                    }
                                }
                            }
                        }

                        return allow;
                    }

                    if (authst === WFAuthStatus.DISAPPROVED) stage = WFPrmDocState.DISAPPROVED;
                }

                sallow = this.CheckPermissions(WFPropType.DOC_CANEDITLINES, replusrs, stage, authschmid, isown);
                if (sallow != null) allow = sallow;

                /*pinfs = this.m_doc.ListPermissions(true, WFPropType.DOC_CANEDITLINES, this.m_conn.AppSystem, stage, authschmid);
                for (let pinf of pinfs) {
                    if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                        allow = pinf.Type;
                        break;
                    }
                }*/
            }

            return allow;
        }
        return WFPrmType.VIEW;
    }

    public get CanChangePermissions(): boolean {
        if (this.m_doc.ID === 0)
            return false;
        if ((this.m_doc.DocStatus !== WFDocStatus.INOCR) && (this.m_doc.DirectoryID >= 0)) {
            if (this.CanEditBeforeSystem || this.m_conn.IsSuperUser)
                return true;
            return this.CanEdit2(0, WFAuthMode.MODE_IN_HEADER);
        }
        return false;
    }

    private IsCreator(): boolean {
        if (this.m_doc.UserID === this.m_conn.User.ID)
            return true;
        let reptbl: number[] = this.m_conn.User.FindReplacements(this.m_conn.Users);
        return (reptbl.indexOf(this.m_doc.UserID) >= 0);
    }
    /*
    public get CanChangeCtx(): WFTmpWriteMode {
        if (this.IsCreator()) {
            if (this.m_doc.DocStatus == WFDocStatus.TEMPLATE || this.m_doc.TemplateID == 0)
                return WFTmpWriteMode.ALLOWALL;
        }
        if (this.m_doc.TemplateID > 0) {
            let dc: WFDocumentAdp = m_conn.GetDocument(this.m_doc.TemplateID);
            if (dc != null)
                return dc.TemplateWriteMode;
        }
        return WFTmpWriteMode.DENYALL;
    }*/
    public get CanChangeTmpWriteMode(): boolean {
        return (this.IsCreator() && this.m_docstatus === WFDocStatus.TEMPLATE);
    }

    public get CanMove(): boolean {
        if ((this.m_doc.ID > 0) && (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) && (this.m_doc.DirectoryID >= 0)) {
            if (this.m_conn.IsSuperUser)
                return true;

            let allow = false;

            if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {
                let iscreator = false;
                let isown: boolean;
                if (this.IsCreator()) {
                    iscreator = true;
                    isown = true;
                } else {
                    isown = false;
                    for (let gr of Array.from(this.m_conn.UserGroups.values())) {
                        if (gr.GroupRole === WFGroupRole.CREATORS) {
                            if (gr.Users.indexOf(this.m_doc.UserID) >= 0 && gr.Users.indexOf(this.m_conn.User.ID) >= 0) {
                                iscreator = true;
                                break;
                            }
                        }
                    }
                }

                let pinfs: Array<WFPermissionInfo>;
                let replusrs: number[] = this.m_conn.User.FindReplacements(this.m_conn.Users);

                let stage: WFPrmDocState = WFPrmDocState.APPROVEDNOAUTH;
                let authschmid = 0;
                let sallow: WFPrmType

                if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                    let authst = this.m_doc.GetStatus();
                    let lshm = this.m_doc.GetLastAuthSchema();
                    authschmid = lshm.AuthSchemaID;

                    if (authst === WFAuthStatus.WAITING) {
                        stage = WFPrmDocState.INAUTH;
                        //allow = (this.m_doc.GetDefaultEditMode(authst, WFAuthMode.MODE_IN_HEADER) === WFAuthEditMode.MODE_DENY) ? false : true;
                        //
                        sallow = this.CheckPermissions(WFPropType.DOC_CANMOVE, replusrs, stage, authschmid, isown);
                        if (sallow != null) allow = (sallow === WFPrmType.CHANGE);
                        //
                        let appuserid = this.m_conn.User.ID;
                        if (!this.m_doc.CheckApprove(this.m_conn.User.ID)) {
                            for (let uid of replusrs) {
                                if (this.m_doc.CheckApprove(uid)) {
                                    appuserid = uid;
                                    break;
                                }
                            }
                        }

                        let schmid = 0;
                        let intinsid = 0;
                        if (lshm != null) {
                            let aschm = this.FindVisibleSchema(lshm, appuserid);
                            if (aschm == null) {
                                if (appuserid === this.m_doc.UserID)
                                    aschm = this.FindVisibleSchema(lshm, WFAuthSchema.OBJID_OWNER);
                            }
                            if (aschm != null) {
                                schmid = aschm.Schema.AuthSchemaID;
                                intinsid = aschm.Recipient.InstructionInternalID;
                            }
                        }

                        let prmset = this.m_doc.HeaderExceptions;
                        if (prmset.length > 0) {
                            let sq: WFAuthSeq = WFAuthSeq.WAITING;
                            for (let prm of prmset) {
                                if ((prm.Property === WFPropType.DOC_CANMOVE) && (prm.AuthSeq === WFAuthSeq.ALWAYS || prm.AuthSeq === sq)) {
                                    switch (prm.ObjType) {
                                        case WFAuthPrmObjType.USER:
                                            if (prm.ObjID === WFAuthSchema.OBJID_OWNER) {
                                                if (isown)
                                                    allow = prm.Allow;
                                            } else {
                                                if (prm.ObjID === appuserid)
                                                    allow = prm.Allow;
                                            }
                                            break;
                                        case WFAuthPrmObjType.USERGROUP:
                                            if (prm.ObjID > 0) {
                                                let ugr = this.m_conn.UserGroups.get(prm.ObjID);
                                                if (ugr.Users.indexOf(appuserid) >= 0)
                                                    allow = prm.Allow;
                                            } else {
                                                allow = prm.Allow;
                                            }
                                            break;
                                        case WFAuthPrmObjType.AUTHSCHEMA:
                                            if (prm.ObjID === schmid)
                                                allow = prm.Allow;
                                            break;
                                        case WFAuthPrmObjType.AUTHINSTRUCTION:
                                            if (prm.ObjID === intinsid || prm.ObjID === 0)
                                                allow = prm.Allow;
                                            break;
                                    }
                                }
                            }
                        }

                        return allow;
                    }

                    if (authst === WFAuthStatus.DISAPPROVED) stage = WFPrmDocState.DISAPPROVED;
                }

                sallow = this.CheckPermissions(WFPropType.DOC_CANMOVE, replusrs, stage, authschmid, isown);
                if (sallow != null) allow = (sallow === WFPrmType.CHANGE);

                /*pinfs = this.m_doc.ListPermissions(true, WFPropType.DOC_CANMOVE, this.m_conn.AppSystem, stage, authschmid);
                for (let pinf of pinfs) {
                    if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                        allow = (pinf.Type === WFPrmType.CHANGE);
                        break;
                    }
                }*/
            } else {
                allow = false;
            }

            return allow;
        }

        return false;
    }

    public get CanEditBeforeSystem(): boolean {
        if (this.m_conn.IsSuperUser)
            return true;
        if (!this.IsCreator()) {
            for (let grp of Array.from(this.m_conn.UserGroups.values())) {
                if (grp.GroupRole === WFGroupRole.CREATORS) {
                    if (grp.Users.indexOf(this.m_doc.UserID) >= 0 && grp.Users.indexOf(this.m_conn.User.ID) >= 0)
                        return true;
                }
            }
            return false;
        }
        return true;
    }

    public get CanEditPages(): boolean {
        if (this.m_doc.DocStatus === WFDocStatus.BEFOREOCR || this.m_doc.DocStatus === WFDocStatus.AFTEROCR) {
            return this.CanEditBeforeSystem;
        }
        return false;
    }

    public get CanEditContext(): boolean {
        return this.CanEdit2(0, WFAuthMode.MODE_IN_HEADER);
    }

    public get CanReplaceResource(): boolean {
        if (this.m_doc.DocStatus !== WFDocStatus.BEFOREOCR &&
            this.m_doc.DocStatus !== WFDocStatus.INOCR &&
            this.m_doc.CanChangeResource &&
            ((this.m_doc.IsTPL && this.m_conn.User.IsAdmin) || !this.m_doc.IsTPL)) {

            let res_id = -1;
            for (let pg of this.m_doc.Pages) {
                if (res_id < 0) {
                    res_id = pg.ResourcesID;
                } else {
                    if (res_id !== pg.ResourcesID) return false;
                }
            }

            return this.CanEdit(null);
        }
        return false;
    }

    public get CanAddPage(): boolean {
        if ((this.m_doc.ID > 0) && (this.m_doc.DocStatus === WFDocStatus.INSYSTEM)) {
            if (this.m_conn.IsSuperUser) return true;

            let stage: WFPrmDocState = WFPrmDocState.APPROVEDNOAUTH;
            let authschmid = 0;

            let allow = false;
            let isown: boolean;
            if (this.IsCreator()) {
                isown = true;
            } else {
                isown = false;
            }

            let replusrs = this.m_conn.User.FindReplacements(this.m_conn.Users);
            let sallow: WFPrmType;

            if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                let authst = this.m_doc.GetStatus();
                let lshm = this.m_doc.GetLastAuthSchema();
                authschmid = lshm.AuthSchemaID;

                if (authst === WFAuthStatus.WAITING) {
                    stage = WFPrmDocState.INAUTH;
                    //allow = (this.m_doc.GetDefaultEditMode(authst, WFAuthMode.MODE_IN_HEADER) === WFAuthEditMode.MODE_DENY) ? false : true;
                    //
                    sallow = this.CheckPermissions(WFPropType.DOC_CANADDPAGE, replusrs, stage, authschmid, isown);
                    if (sallow != null) allow = (sallow === WFPrmType.CHANGE);
                    //
                    let appuserid = this.m_conn.User.ID;
                    if (!this.m_doc.CheckApprove(this.m_conn.User.ID)) {
                        for (let uid of replusrs) {
                            if (this.m_doc.CheckApprove(uid)) {
                                appuserid = uid;
                                break;
                            }
                        }
                    }

                    let schmid = 0;
                    let intinsid = 0;
                    if (lshm != null) {
                        let aschm = this.FindVisibleSchema(lshm, appuserid);
                        if (aschm == null) {
                            if (appuserid === this.m_doc.UserID)
                                aschm = this.FindVisibleSchema(lshm, WFAuthSchema.OBJID_OWNER);
                        }
                        if (aschm != null) {
                            schmid = aschm.Schema.AuthSchemaID;
                            intinsid = aschm.Recipient.InstructionInternalID;
                        }
                    }

                    let prmset = this.m_doc.HeaderExceptions;
                    if (prmset.length > 0) {
                        let sq: WFAuthSeq = WFAuthSeq.WAITING;
                        for (let prm of prmset) {
                            if ((prm.Property === WFPropType.DOC_CANADDPAGE) && (prm.AuthSeq === WFAuthSeq.ALWAYS || prm.AuthSeq === sq)) {
                                switch (prm.ObjType) {
                                    case WFAuthPrmObjType.USER:
                                        if (prm.ObjID === WFAuthSchema.OBJID_OWNER) {
                                            if (isown)
                                                allow = prm.Allow;
                                        } else {
                                            if (prm.ObjID === appuserid)
                                                allow = prm.Allow;
                                        }
                                        break;
                                    case WFAuthPrmObjType.USERGROUP:
                                        if (prm.ObjID > 0) {
                                            let ugr = this.m_conn.UserGroups.get(prm.ObjID);
                                            if (ugr.Users.indexOf(appuserid) >= 0)
                                                allow = prm.Allow;
                                        } else {
                                            allow = prm.Allow;
                                        }
                                        break;
                                    case WFAuthPrmObjType.AUTHSCHEMA:
                                        if (prm.ObjID === schmid)
                                            allow = prm.Allow;
                                        break;
                                    case WFAuthPrmObjType.AUTHINSTRUCTION:
                                        if (prm.ObjID === intinsid || prm.ObjID === 0)
                                            allow = prm.Allow;
                                        break;
                                }
                            }
                        }
                    }

                    return allow;
                }

                if (authst === WFAuthStatus.DISAPPROVED) stage = WFPrmDocState.DISAPPROVED;
            }

            sallow = this.CheckPermissions(WFPropType.DOC_CANADDPAGE, replusrs, stage, authschmid, isown);
            if (sallow != null) allow = (sallow === WFPrmType.CHANGE);

            /*let pinfs = this.m_doc.ListPermissions(true, WFPropType.DOC_CANADDPAGE, this.m_conn.AppSystem, stage, authschmid);
            for (let pinf of pinfs) {
                if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0) || (pinf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                    allow = (pinf.Type === WFPrmType.CHANGE);
                    break;
                }
            }*/

            return allow;
        }
        return false;
    }

    public SetTmpHtmlDocument(barr: string): void {
        this.m_tmp_upddate = new Date();
        this.m_tmp_filename = IWFObject.Format('mod{0}.html', this.m_doc.Pages[0].ResourcesID);
        this.m_tmp_document_b64 = barr;
        this.m_tmp_resources_id = 0;
        this.m_tmp_type = TempCtxType.HTML;
        this.IsModified = true;
    }

    public SetTmpOtherDocument(barr: string, fname: string): void {
        this.m_tmp_upddate = new Date();
        this.m_tmp_filename = fname;
        this.m_tmp_document_b64 = barr;
        this.m_tmp_resources_id = 0;
        this.m_tmp_type = TempCtxType.OTHER;
        this.IsModified = true;
    }

    public get TmpType(): TempCtxType {
        return this.m_tmp_type;
    }
    public get TmpExtension(): string {
        let off: number = this.m_tmp_filename.lastIndexOf('.');
        return (off > 0) ? this.m_tmp_filename.substr(off + 1) : 'dta';
    }
    public get TmpUpdateTime(): Date {
        return this.m_tmp_upddate;
    }
    public get TmpFileName(): string {
        return this.m_tmp_filename;
    }
    public get TmpDocumentB64(): string {
        return this.m_tmp_document_b64;
    }
    public get TmpDocumentB64Len(): number {
        return atob(this.m_tmp_document_b64).length;
    }
    public get IsEditable(): boolean {
        return this.m_doc.IsEditable;
    }
    public GetLinesCount(): number {
        return this.m_lines.length;
    }
    public GetLine(id: number): WFDocumentLineAdp {
        return this.m_lines[id];
    }

    private EvalLines(from:number, to:number): void {
        let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
        for (let prp of this.m_allattribs) {
            if (IWFObject.IsNullOrEmpty(prp.EvalCode)) continue;

            if (prp.EvalCode.indexOf("L[") >= 0 || prp.EvalCode.indexOf("lsum") >= 0) {
                this.EvalPropertyValueChanged(cls, null, from, to);
                return;
            }
        }

        if(from < to) {            
            for (let col of this.m_allcolumns) {
                if (!IWFObject.IsNullOrEmpty(col.EvalCode)) {
                    this.EvalColumn(cls, col, from, to);
                }
            }
        }
    }

    public AddLine(cc: number, firechg: boolean= true): void {
        if (cc > 0 && this.m_doc.ClassID > 0) {
            for (let ii = 0; ii < cc; ii++) {
                let vv: WFDocumentLine = WFDocumentLine.Create(this.m_conn.User.ID);
                this.m_doc.Lines.push(vv);
                this.m_lines.push(new WFDocumentLineAdp(this, vv));
            }

            if(firechg) this.EvalLines(this.m_lines.length - cc, this.m_lines.length);
            this.IsModified = true;
        }
    }

    public RemoveLine(id: number): boolean {
        if (this.CanEditLines(null, this.m_lines[id]) === WFPrmType.CHANGE) {
            this.m_doc.Lines.splice(id, 1);
            this.m_lines.splice(id, 1);
            this.EvalLines(0, 0);
            this.IsModified = true;
            return true;
        }
        return false;
    }
    public LineIndexOf(adp: WFDocumentLineAdp): number {
        return this.m_lines.indexOf(adp);
    }
    public GetModificationsCount(): number {
        return this.m_doc.GetModificationsCount();
    }
    public GetModification(id: number): WFDocumentModification {
        return this.m_doc.GetModification(id);
    }
    public RebuildDocument(id: number): WFDocumentAdp {
        let doc: WFDocument = this.m_doc.RebuildDocument(id);
        return new WFDocumentAdp(this.m_conn, doc, false, this.m_readonly);
    }
    /*public Clone(): WFDocumentAdp {
        return new WFDocumentAdp(this.m_doc, m_conn, false);
    }*/
    public get IsSchemaChanged(): boolean {
        return (this.m_doc.AuthSchemaID !== this.m_authschemaid) || (this.m_authmthtype !== this.m_doc.AuthMethodType) || (this.IsDiffRecipients(this.LoadRecipients()));
    }

    private CorrectDocStatus(): void {
        if (this.m_docstatus === WFDocStatus.INSYSTEM && this.m_doc.DocStatus !== WFDocStatus.INSYSTEM) this.m_docstatus = this.m_doc.DocStatus;
    }

    public Publish(): void {
        if (this.m_ismodified) {
            if (this.m_doc.ClassID > 0) {
                let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
                if (cls.AuthMandatory && this.m_authschemaid < 0 && this.m_doc.DocStatus === WFDocStatus.AFTEROCR && this.m_docstatus === WFDocStatus.INSYSTEM) {
                    this.m_conn.GlobalService.showWarning(BUTTONSTYPE.OK, this.m_conn.StringService.getStr('errCannotChangeDocStatus'));
                    this.CorrectDocStatus();
                    if (this.OnPublishDocument != null)
                        this.OnPublishDocument(this, false);
                    return;
                }

                //sprawdz puste linie
                if (this.m_lines.length > 0) {
                    let lid = this.m_lines.length - 1;
                    let lln = this.m_lines[lid];
                    if (lln.UserID === this.m_conn.User.ID && lln.IsEmpty) this.RemoveLine(lid);
                }
            }

            if (this.IsSchemaChanged) {
                let ret: boolean;
                switch (this.m_authschemaid) {
                    case WFDocument.SCHM_NOAUTH:
                        ret = this.m_doc.SetNoAuthSchema();
                        break;
                    case WFDocument.SCHM_CUSTOM:
                        if (this.m_authrecipients.length === 0) {
                            this.m_conn.GlobalService.showWarning(BUTTONSTYPE.OK, this.m_conn.StringService.getStr('errCannotChangeSchemaUndefined'));
                            this.CorrectDocStatus();
                            if (this.OnPublishDocument != null)
                                this.OnPublishDocument(this, false);
                            return;
                        }
                        let srcshm: WFAuthSchema = (this.m_srcauthschemaid > 0) ? this.m_conn.AuthSchemas.get(this.m_srcauthschemaid) : null;
                        ret = this.m_doc.SetAuthCustomSchema(this.m_authmthtype, this.m_authrecipients, this.m_authusrlimit, this.m_addinfo, srcshm, this.m_conn.AppSystem);
                        break;
                    default:
                        let shm: WFAuthSchema = this.m_conn.AuthSchemas.get(this.m_authschemaid);
                        ret = this.m_doc.SetAuthSchema(shm, this.m_conn.AppSystem);
                        break;
                }
                if (!ret) {
                    this.m_conn.GlobalService.showWarning(BUTTONSTYPE.OK, this.m_conn.StringService.getStr('errCannotChangeSchemaApproved'));
                    this.CorrectDocStatus();
                    if (this.OnPublishDocument != null)
                        this.OnPublishDocument(this, false);
                    return;
                }
            }
            //let serv: ModelServiceClient2 = new ModelServiceClient2();
            if (this.m_tmp_document_b64 != null && this.m_tmp_resources_id === 0) {
                const self = this;
                this.m_conn.DataService.createPagesFromFile(this.m_conn.SessionID, this.m_tmp_filename, this.m_tmp_document_b64, this.m_OnError,
                    (pages) => {
                        self.CreatePagesFromFileCompleted(pages);
                    });

                return;
            }
            this.ModifyDocument();
        }
    }

    private ModifyDocument(): void {
        let ndc = new WFDocument(this.m_doc.ToObject());
        ndc.DocStatus = this.m_docstatus;
        const self = this;

        let echerr = new EventAdp<_OnError>();
        echerr.subscribe(() => {
            self.CorrectDocStatus();
        });
        echerr.subscribe2(this.m_OnError);

        this.m_conn.DataService.modifyDocument(this.m_conn.SessionID, ndc, echerr, (doc) => {
            self.ModifyDocumentCompleted(doc);
        });
    }

    public ListPermissions(includedoc: boolean, ptp: WFPropType): Array<WFPermissionInfo> {
        let stage: WFPrmDocState;
        let authschmid = 0;

        if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {
            stage = WFPrmDocState.APPROVEDNOAUTH;
            if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                let authst: WFAuthStatus = this.m_doc.GetStatus();
                let lshm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
                authschmid = lshm.AuthSchemaID;
                if (authst === WFAuthStatus.WAITING) {
                    stage = WFPrmDocState.INAUTH;
                } else {
                    if (authst === WFAuthStatus.DISAPPROVED) stage = WFPrmDocState.DISAPPROVED;
                }
            }
        } else {
            stage = WFPrmDocState.BEFOREAUTH;
        }

        return this.m_doc.ListPermissions(includedoc, ptp, this.m_conn.AppSystem, stage, authschmid);
    }

    private CreatePagesFromFileCompleted(pages: Array<WFDocumentPage>): void {
        let ii: number;

        try {
            if (pages.length > 0) {
                for (ii = 0; ii < pages.length; ii++) {
                    let npg: WFDocumentPage = pages[ii];
                    this.m_tmp_resources_id = npg.ResourcesID;
                    if (ii < this.m_doc.Pages.length) {
                        this.m_doc.Pages[ii].ResourcesID = npg.ResourcesID;
                    } else {
                        this.m_doc.Pages.push(npg);
                    }
                }
                while (this.m_doc.Pages.length > pages.length) {
                    this.m_doc.Pages.splice(pages.length, 1);
                }
            }
            this.ModifyDocument();
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        this.CorrectDocStatus();
        if (this.OnPublishDocument != null)
            this.OnPublishDocument(this, false);
    }

    private ModifyDocumentCompleted(doc: WFDocument): void {
        try {

            /*if (m_conn.Fileinfolist.Count > 0) {
                let fi: FileInfo = m_conn.Fileinfolist[0];
                m_conn.Fileinfolist.RemoveAt(0);
                let dexp: DocumentExplorer = __as__<DocumentExplorer>(App.Current.RootVisual, DocumentExplorer);
                dexp.AddNewFromFi(fi);
                return
            }
            if (m_conn.fiListinitalCount > 1) {
                m_conn.fiListinitalCount = 0;
                let dexp: DocumentExplorer = __as__<DocumentExplorer>(App.Current.RootVisual, DocumentExplorer);
                dexp.ReloadInputDocuments();
                return
            }
            m_conn.fiListinitalCount = 0;*/

            if(this.m_messages!= null) {                 
                for (let ii = 0; ii < this.m_messages.length; ii++) {
                    this.m_conn.MsgCache.delete(this.m_messages[ii]);
                }
            }

            this.m_doc = doc;
            this.m_props = this.m_doc.Properties;
            this.m_tmp_filename = '';
            this.m_tmp_document_b64 = null;
            this.m_tmp_resources_id = 0;
            this.m_tmp_type = TempCtxType.UNSET;
            this.m_messages = null;
            this.ReloadLines();
            this.ReloadLocals();
            this.RefreshAttribs();
            this.IsModified = false;
            if (this.OnPublishDocument != null)
                this.OnPublishDocument(this, true);
            /*if (this.m_acttrigger != null) {
                let args: TriggerArgs = this.m_acttrigger;
                this.m_acttrigger = null;
                this.ExecuteLineOpInstruction2(args, 0);
            }*/
            return;

        } catch (ex) {
            this.m_conn.GlobalService.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, IWFObject.Format(this.m_conn.StringService.getStr('errDocCannotBeModified'), this.m_doc.Name, ex.message));
        }

        this.CorrectDocStatus();
        if (this.OnPublishDocument != null)
            this.OnPublishDocument(this, false);

        /*if (this.m_acttrigger != null) {
            let args: TriggerArgs = this.m_acttrigger;
            this.m_acttrigger = null;
            this.ExecuteLineOpInstruction2(args, 1);
        }*/
    }

    public FetchAttachments(): void {
        if (this.m_parents == null) {
            let flgs = 0;
            if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {
                flgs = flgs | FDOC_DOCSTATUS_INSYSTEM;
            } else {
                flgs = flgs | FDOC_DOCSTATUS_BEFOREOCR;
                flgs = flgs | FDOC_DOCSTATUS_INOCR;
                flgs = flgs | FDOC_DOCSTATUS_AFTEROCR;
                flgs = flgs | FDOC_DOCSTATUS_TEMPLATE;
            }

            let prms: FindDocumentPrms = {
                sid: this.m_conn.SessionID,
                companies: [],
                users: [],
                classes: [],
                schemas: [],
                directories: [],
                documents: [this.m_doc.ID],
                fargs: [],
                createdatfrom: null,
                createdatto: null,
                docdatefrom: null,
                docdateto: null,
                flags: flgs,
                refobj: [],
                attvalues: []
            };

            const self = this;
            this.m_conn.DataService.findDocuments(prms, this.m_OnError, (rows) => {
                self.FindDocumentsCompleted(rows);
            });
        } else {
            this.m_parents.OnError.subscribe2(this.m_OnError);
            this.m_parents.FetchDocuments(this.m_conn, SORT_PROPERTY.BY_DOCNAME, true, 0, this.m_parents.Count);
        }
    }

    private FindDocumentsCompleted(rows: Array<any>): void {
        let ii: number;
        try {
            this.m_parents = new DocumentList();
            for (ii = 0; ii < rows.length; ii++) {
                let did: number = rows[ii].C0;
                this.m_parents.Add(did);
            }
            const self = this;
            this.m_parents.FetchCompleted = (sender, docs) => {
                self.FetchParentsCompleted(sender, docs);
            };
            this.m_parents.OnError.subscribe2(this.m_OnError);
            this.m_parents.FetchDocuments(this.m_conn, SORT_PROPERTY.BY_DOCNAME, true, 0, this.m_parents.Count);
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }
    }

    private FetchParentsCompleted(sender: DocumentList, docs: WFDocumentAdp[]): void {
        try {
            this.m_tmp_async = docs;
            this.m_attachments.OnError.subscribe2(this.m_OnError);
            this.m_attachments.FetchDocuments(this.m_conn, SORT_PROPERTY.BY_DOCNAME, true, 0, this.m_attachments.Count);
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }
    }

    private FetchCompleted(sender: DocumentList, docs: WFDocumentAdp[]): void {
        try {
            if (this.OnFetchAttachmentsCompleted != null) {
                let invdc: Array<WFDocumentAdp> = new Array<WFDocumentAdp>();
                if (this.m_doc.DocStatus === WFDocStatus.INSYSTEM) {
                    let nvec: Array<WFDocumentAdp> = new Array<WFDocumentAdp>();
                    for (let dc of docs) {
                        let addinv = true;
                        if (dc.UserID === this.m_conn.User.ID) {
                            nvec.push(dc);
                            addinv = false;
                        } else {
                            if (dc.DocStatus === WFDocStatus.INSYSTEM) {
                                if (dc.CanView()) {
                                    nvec.push(dc);
                                    addinv = false;
                                }
                            }
                        }
                        if (addinv)
                            invdc.push(dc);
                    }
                    docs = nvec;
                }
                this.OnFetchAttachmentsCompleted(this, docs, this.m_tmp_async, invdc);
            }
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }
    }

    public AddMessage(msgid: number): void {
        if (this.m_messages != null) {
            this.m_messages.push(msgid);
        }
    }

    public FetchMessages(forceupdate: boolean): void {
        if (this.m_messages == null || forceupdate) {
            if (this.m_doc.ID > 0) {
                let rq: FindMessagesPrms = {
                    sid: this.m_conn.SessionID,
                    dst_cmp: 0,
                    dst_usr: 0,
                    dst_doc: this.m_doc.ID,
                    isread: 0
                };

                const self = this;
                this.m_conn.DataService.findMessages(rq, this.m_OnError, (msg_ids) => {
                    self.FindMessagesCompleted(msg_ids);
                });
            } else {
                let zrv: WFMessageAdp[] = new Array(0);
                if (this.OnFetchMessagesCompleted != null)
                    this.OnFetchMessagesCompleted(this, zrv);
            }
        } else {
            this.FetchMessages2();
        }
    }

    private FetchMessages2(): void {
        let ii: number;
        let ret: Array<WFMessageAdp> = new Array<WFMessageAdp>(this.m_messages.length);
        let ret_c = 0;
        let tofetch = new Array<number>();
        for (ii = 0; ii < this.m_messages.length; ii++) {
            let mid: number = this.m_messages[ii];
            if (this.m_conn.MsgCache.has(mid)) {
                ret[ret_c++] = this.m_conn.MsgCache.get(mid);
            } else {
                tofetch.push(mid);
            }
        }
        if (ret_c === this.m_messages.length) {
            if (this.OnFetchMessagesCompleted != null)
                this.OnFetchMessagesCompleted(this, ret);
        } else {
            const self = this;
            this.m_conn.DataService.getMessages(this.m_conn.SessionID, tofetch, this.m_OnError, (msgs) => {
                self.GetMessagesCompleted(msgs);
            });
        }
    }

    private FindMessagesCompleted(msg_ids: Array<number>): void {
        try {
            this.m_messages = msg_ids;
            this.FetchMessages2();
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

    }

    private GetMessagesCompleted(msgs: Array<WFMessage>): void {
        let ii: number;
        try {
            for (ii = 0; ii < msgs.length; ii++) {
                let msg: WFMessage = msgs[ii];
                let adp: WFMessageAdp = new WFMessageAdp(msg);
                this.m_conn.MsgCache.set(msg.ID, adp);
            }
            let ret: Array<WFMessageAdp> = new Array<WFMessageAdp>(this.m_messages.length);
            for (ii = 0; ii < this.m_messages.length; ii++) {
                ret[ii] = this.m_conn.MsgCache.get(this.m_messages[ii]);
            }
            if (this.OnFetchMessagesCompleted != null)
                this.OnFetchMessagesCompleted(this, ret);
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }
    }

    public Remove(): void {
        if (this.m_doc.ID > 0) {
            const self = this;
            this.m_conn.DataService.removeDocument(this.m_conn.SessionID, this.m_doc, this.m_OnError, (doc) => {
                self.RemoveDocumentCompleted(doc);
            });
        } else {
            if (this.OnRemoveDocument != null)
                this.OnRemoveDocument(this, false, 0);
        }
    }

    private RemoveDocumentCompleted(doc: WFDocument): void {
        try {
            let oldid: number = this.m_doc.ID;
            this.m_doc = doc;
            this.m_props = this.m_doc.Properties;
            this.IsModified = false;
            if (this.OnRemoveDocument != null)
                this.OnRemoveDocument(this, true, oldid);
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        if (this.OnRemoveDocument != null)
            this.OnRemoveDocument(this, false, this.m_doc.ID);
    }

    public FetchKeywords(): void {
        if (this.m_keywords == null) {
            this.m_keywords = '';
            if (this.m_doc.Pages.length > 0) {
                let ii: number;
                let que: Array<number> = new Array<number>();

                for (ii = 0; ii < this.m_doc.Pages.length; ii++) {
                    let pg: WFDocumentPage = this.m_doc.Pages[ii];
                    if (que.indexOf(pg.ResourcesID) < 0)
                        que.push(pg.ResourcesID);
                }

                let rq: GetResourcePreviewPrms = {
                    sid: this.m_conn.SessionID,
                    mode: 1,
                    resid: que[0],
                    pagenum: 0,
                    mwidth: 0,
                    mheight: 0,
                    quality: 0
                };

                const self = this;
                this.m_conn.DataService.getResourcePreview(rq, this.m_OnError, (dta) => {
                    self.GetResourcePreviewCompleted(dta, que);
                });

                return;
            }
        }

        if (this.OnFetchKeywordsCompleted != null)
            this.OnFetchKeywordsCompleted(this, this.m_keywords);
    }

    private GetResourcePreviewCompleted(dta: any, que: Array<number>): void {
        try {

            let rtype: number = dta.type;
            this.m_keywords = IWFObject.Format('{0}\r\n{1}', this.m_keywords, dta.kwords);

            que.splice(0, 1);
            if (que.length > 0) {

                let rq: GetResourcePreviewPrms = {
                    sid: this.m_conn.SessionID,
                    mode: 1,
                    resid: que[0],
                    pagenum: 0,
                    mwidth: 0,
                    mheight: 0,
                    quality: 0
                };

                const self = this;
                this.m_conn.DataService.getResourcePreview(rq, this.m_OnError, (sdta) => {
                    self.GetResourcePreviewCompleted(sdta, que);
                });
            } else {
                if (this.OnFetchKeywordsCompleted != null)
                    this.OnFetchKeywordsCompleted(this, this.m_keywords);
            }

            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        this.m_keywords = null;
        if (this.OnFetchKeywordsCompleted != null)
            this.OnFetchKeywordsCompleted(this, null);
    }

    public FetchMatchReconSchemas(): void {
        if (this.m_matchschemas == null) {
            const self = this;
            this.m_conn.DataService.getMatchReconSchemaCodes(this.m_conn.SessionID, this.m_doc, this.m_OnError, (codes) => {
                self.GetMatchReconSchemaCodesCompleted(codes);
            });
            return;
        }
        if (this.OnFetchMatchReconSchemaCompleted != null)
            this.OnFetchMatchReconSchemaCompleted(this, this.m_matchschemas);
    }

    private GetMatchReconSchemaCodesCompleted(codes: Array<string>): void {
        try {
            this.m_matchschemas = codes;
            if (this.OnFetchMatchReconSchemaCompleted != null)
                this.OnFetchMatchReconSchemaCompleted(this, this.m_matchschemas);
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        this.m_keywords = null;
        if (this.OnFetchMatchReconSchemaCompleted != null)
            this.OnFetchMatchReconSchemaCompleted(this, null);
    }

    public SuggestNewDocName(): void {
        let initial: string = this.m_doc.PrepareNewDocName(this.m_conn.AppSystem);
        if (initial.indexOf('{UNIQID}', 0) >= 0) {
            if (this.m_doc.SysDocNum > 0 && this.m_class_id === this.m_doc.ClassID) {
                initial = initial.replace(/{UNIQID}/g, this.m_doc.SysDocNum.toString());
            } else {
                let fvec = this.m_conn.NewUniqIDCache.find((t) => (t[0] === this.m_doc.CompanyID && t[1] === this.m_doc.ClassID));
                if (fvec == null || fvec == undefined) {
                    const self = this;
                    this.m_conn.DataService.suggestDocUniqID(this.m_conn.SessionID, this.m_doc.CompanyID, this.m_doc.ClassID, this.m_OnError,
                        (uid) => {
                            self.SuggestDocUniqIDCompleted(uid, initial);
                        });

                    return;
                }

                initial = initial.replace(/{UNIQID}/g, fvec[2].toString());
            }
        }

        if (this.OnSuggestNewDocName != null) this.OnSuggestNewDocName(this, initial);
    }

    private SuggestDocUniqIDCompleted(uid: number, initial: string): void {
        try {
            if (uid > 0) {
                this.m_conn.NewUniqIDCache.push([this.m_doc.CompanyID, this.m_doc.ClassID, uid]);
                initial = initial.replace(/{UNIQID}/g, uid.toString());
                if (this.OnSuggestNewDocName != null) this.OnSuggestNewDocName(this, initial);
                return;
            }
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }
        if (this.OnSuggestNewDocName != null) this.OnSuggestNewDocName(this, null);
    }

    private ExtendStates(cstates: Map<number, WFProcessVarState>, vid: number, nstate: WFProcessVarState): Map<number, WFProcessVarState> {
        let ret: Map<number, WFProcessVarState> = new Map<number, WFProcessVarState>();
        for (let kv of Array.from(cstates.entries())) {
            if (kv[0] !== vid) ret.set(kv[0], kv[1]);
        }
        ret.set(vid, nstate);
        return ret;
    }

    private GetAttribsList(cls: WFClass, hdr: boolean): Array<WFClassAttrib> {
        let ret: Array<WFClassAttrib> = new Array<WFClassAttrib>();
        while (cls != null) {
            ret = ret.concat((hdr) ? cls.Attributes : cls.Columns);
            cls = (cls.BaseClassID > 0) ? this.m_conn.Classes.get(cls.BaseClassID) : null;
        }
        return ret;
    }

    private ExecuteLineOpInstruction(lopins: WFLineopInstruction, cpr: WFCustomProcess, cstates: Map<number, WFProcessVarState>): void {
        let ii, ii2, lid: number;
        let obj: Object;
        let def: WFProcessVariable;
        let pstate: WFProcessVarState;
        let firemodlines = false;
        let allattribs: Array<WFClassAttrib>;
        let allcolumns: Array<WFClassAttrib>;
        let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);

        switch (lopins.OpType) {
            case WFLineOpType.SETLINES:
            case WFLineOpType.ADDLINES:
                pstate = cstates.get(lopins.VariableID);
                let lidcol = -1;
                let srccolmap = new Array<number>(pstate.Columns.length);
                let attcolmap = new Array<WFClassAttrib>(pstate.Columns.length);
                let colmap_c = 0;
                allcolumns = this.GetAttribsList(cls, false);
                for (ii = 0; ii < pstate.Columns.length; ii++) {
                    let uname: string = pstate.Columns[ii].Name.toUpperCase();
                    if (uname === 'SYS_LINEID') {
                        lidcol = ii;
                    } else {
                        let atr: WFClassAttrib = null;
                        for (ii2 = 0; ii2 < allcolumns.length; ii2++) {
                            let tst: WFClassAttrib = allcolumns[ii2];
                            if (tst.Name.toUpperCase() === uname) {
                                atr = tst;
                                break;
                            }
                        }
                        if (atr != null) {
                            srccolmap[colmap_c] = ii;
                            attcolmap[colmap_c] = atr;
                            colmap_c++;
                        }
                    }
                }
                for (ii = 0; ii < pstate.RowCount; ii++) {
                    let ln: WFDocumentLineAdp = null;
                    if (lidcol >= 0) {
                        obj = pstate.GetValue(lidcol, ii);
                        if (obj != null) {
                            lid = Number.parseInt(obj.toString());
                            if ((lid < this.m_doc.Lines.length) && (lid >= 0))
                                ln = this.m_lines[lid];
                        }
                    }

                    if (ln == null) {
                        let srcln: WFDocumentLine = WFDocumentLine.Create(this.m_conn.User.ID);
                        this.m_doc.Lines.push(srcln);
                        ln = new WFDocumentLineAdp(this, srcln);
                        this.m_lines.push(ln);
                        firemodlines = true;
                    }

                    for (ii2 = 0; ii2 < colmap_c; ii2++) {
                        obj = pstate.GetValue(srccolmap[ii2], ii);
                        if (obj != null) {
                            let atr: WFClassAttrib = attcolmap[ii2];
                            let sval: string = obj.toString();
                            let sret: string = this.IsStrValid(sval, atr);
                            if (sret == null) {
                                ln.SetInvalidValue(sval, atr);
                            } else {
                                ln.SetValue(sret, cls, atr);
                            }
                            firemodlines = true;
                        }
                    }
                }
                if (firemodlines)
                    this.EvalLines(0, this.m_lines.length);
                break;
            case WFLineOpType.CLEARLINES:
                if (this.m_doc.Lines.length > 0) {
                    this.m_doc.Lines.splice(0, this.m_doc.Lines.length);
                    this.m_lines.splice(0, this.m_lines.length);
                    firemodlines = true;
                }
                break;
            case WFLineOpType.DELLINES:
                pstate = cstates.get(lopins.VariableID);
                let lids = new Array<number>();
                for (ii = 0; ii < pstate.RowCount; ii++) {
                    obj = pstate.GetValue2('SYS_LINEID', ii);
                    if (obj != null) {
                        lid = Number.parseInt(obj.toString());
                        if (lid < this.m_doc.Lines.length)
                            lids.push(lid);
                    }
                }
                if (lids.length > 0) {
                    lids.sort((a, b) => a - b); // Array<any>.Sort(lids, 0, lids_c);
                    for (ii = lids.length - 1; ii > -1; ii--) {
                        lid = lids[ii];
                        this.m_doc.Lines.splice(lid, 1);
                        this.m_lines.splice(lid, 1);
                    }
                    this.EvalLines(0, 0);
                    firemodlines = true;
                }
                break;
            case WFLineOpType.GETLINES:
                allcolumns = this.GetAttribsList(cls, false);
                let cols = new Array<WFProcessVarColumn>(allcolumns.length + 1);
                cols[0] = WFProcessVarColumn.Create2('SYS_LINEID', WFProcessVarColType.TYPE_INT);
                for (ii = 0; ii < allcolumns.length; ii++) {
                    cols[ii + 1] = WFProcessVarColumn.Create3(allcolumns[ii]);
                }
                def = cpr.GetVariableByID(lopins.VariableID);
                pstate = new WFProcessVarState(def.Name, cols, 0);
                if (this.m_doc.Lines.length > 0) {
                    pstate.AddLine(this.m_doc.Lines.length);
                    for (ii = 0; ii < this.m_doc.Lines.length; ii++) {
                        pstate.SetValue(0, ii, ii);
                    }
                    for (ii = 0; ii < allcolumns.length; ii++) {
                        let col: WFClassAttrib = allcolumns[ii];
                        let ucolnm: string = col.Name.toUpperCase();
                        let colid: number = ii + 1;
                        for (ii2 = 0; ii2 < this.m_doc.Lines.length; ii2++) {
                            let ln: WFDocumentLine = this.m_doc.Lines[ii2];
                            if (ln.has(ucolnm))
                                pstate.SetValue(colid, ii2, ln.get(ucolnm));
                        }
                    }
                }
                cstates = this.ExtendStates(cstates, lopins.VariableID, pstate);
                break;
            case WFLineOpType.GETHEADER:
                allattribs = this.GetAttribsList(cls, true);
                let colshdr: WFProcessVarColumn[] = new Array(allattribs.length + 10);
                colshdr[0] = WFProcessVarColumn.Create2('SYS_ID', WFProcessVarColType.TYPE_INT);
                colshdr[1] = WFProcessVarColumn.Create2('SYS_USERID', WFProcessVarColType.TYPE_INT);
                colshdr[2] = WFProcessVarColumn.Create2('SYS_CLASSID', WFProcessVarColType.TYPE_INT);
                colshdr[3] = WFProcessVarColumn.Create2('SYS_COMPANYID', WFProcessVarColType.TYPE_INT);
                colshdr[4] = WFProcessVarColumn.Create2('SYS_TEMPLATEID', WFProcessVarColType.TYPE_INT);
                colshdr[5] = WFProcessVarColumn.Create2('SYS_NAME', WFProcessVarColType.TYPE_TEXT);
                colshdr[6] = WFProcessVarColumn.Create2('SYS_DESCRIPTION', WFProcessVarColType.TYPE_TEXT);
                colshdr[7] = WFProcessVarColumn.Create2('SYS_DOCDATE', WFProcessVarColType.TYPE_DATE);
                colshdr[8] = WFProcessVarColumn.Create2('SYS_ENTERDATE', WFProcessVarColType.TYPE_DATE);
                colshdr[9] = WFProcessVarColumn.Create2('SYS_DOCNUM', WFProcessVarColType.TYPE_TEXT);
                for (ii = 0; ii < allattribs.length; ii++) {
                    colshdr[ii + 10] = WFProcessVarColumn.Create3(allattribs[ii]);
                }
                def = cpr.GetVariableByID(lopins.VariableID);
                pstate = new WFProcessVarState(def.Name, colshdr, 0);
                pstate.AddLine(1);
                pstate.SetValue(0, 0, this.m_doc.ID);
                pstate.SetValue(1, 0, this.m_doc.UserID);
                pstate.SetValue(2, 0, this.m_doc.ClassID);
                pstate.SetValue(3, 0, this.m_doc.CompanyID);
                pstate.SetValue(4, 0, this.m_doc.TemplateID);
                pstate.SetValue(5, 0, this.m_doc.Name);
                pstate.SetValue(6, 0, this.m_doc.Description);
                pstate.SetValue(7, 0, this.m_doc.DocDate);
                pstate.SetValue(8, 0, this.m_doc.EnterDate);
                pstate.SetValue(9, 0, this.m_doc.DocNum);
                for (ii = 0; ii < allattribs.length; ii++) {
                    let atr: WFClassAttrib = allattribs[ii];
                    let uatrnm: string = atr.Name.toUpperCase();
                    if (this.m_doc.Properties.has(uatrnm)) {
                        pstate.SetValue(ii + 10, 0, this.m_doc.Properties.get(uatrnm));
                    }
                }
                cstates = this.ExtendStates(cstates, lopins.VariableID, pstate);
                break;
            case WFLineOpType.SETHEADER:
                pstate = cstates.get(lopins.VariableID);
                if (pstate.RowCount > 0) {
                    for (ii = 0; ii < pstate.Columns.length; ii++) {
                        obj = pstate.GetValue(ii, 0);
                        if (obj != null) {
                            let pcol: WFProcessVarColumn = pstate.Columns[ii];
                            switch (pcol.Name.toUpperCase()) {
                                case 'SYS_CLASSID':
                                    this.ClassID = Number.parseInt(obj.toString());
                                    break;
                                case 'SYS_SCHEMAID':
                                    this.AuthSchemaID = Number.parseInt(obj.toString());
                                    break;
                                case 'SYS_NAME':
                                    this.Name = obj.toString();
                                    break;
                                case 'SYS_DESCRIPTION':
                                    this.Description = obj.toString();
                                    break;
                                case 'SYS_DOCDATE':
                                    this.DocDate = (obj instanceof Date) ? <Date>obj : new Date(obj.toString());
                                    break;
                                case 'SYS_ENTERDATE':
                                    this.EnterDate = (obj instanceof Date) ? <Date>obj : new Date(obj.toString());
                                    break;
                                case 'SYS_DOCNUM':
                                    this.DocNum = obj.toString();
                                    break;
                                case 'SYS_COMPANYID':
                                    this.CompanyID = Number.parseInt(obj.toString());
                                    break;
                                default:
                                    this.SetValue(pcol.Name, obj.toString(), false);
                                    break;
                            }
                        }
                    }
                }
                break;
            case WFLineOpType.SAVEDOC:
                let args: TriggerArgs = new TriggerArgs(cpr, lopins, cstates);
                if (this.m_ismodified) {
                    if (this.m_conn.GlobalService.App != null) {
                        this.m_acttrigger = args;
                        this.m_conn.GlobalService.App.PublishLocalDoc(this);
                        return;
                    }
                }
                this.ExecuteLineOpInstruction2(args, -1);
                return;
            
            case WFLineOpType.SETREFS:
            case WFLineOpType.ADDREFS:
                pstate = cstates.get(lopins.VariableID);
                let ridcol = -1;
                let cmpidcol = -1;
                let objtpcol = -1;
                let objidcol = -1;
                for (ii = 0; ii < pstate.Columns.length; ii++) {
                    let uname: string = pstate.Columns[ii].Name.toUpperCase();
                    switch(uname) {
                        case "SYS_REFID":
                            ridcol = ii;
                            break;
                        case "SYS_COMPANYID":
                            cmpidcol = ii;
                            break;
                        case "SYS_OBJECTTYPE":
                            objtpcol = ii;
                            break;
                        case "SYS_OBJECTID":
                            objidcol = ii;
                            break;
                    }
                }

                for (ii = 0; ii < pstate.RowCount; ii++) {
                    let ln: WFDocumentRef = null;
                    if (ridcol >= 0) {
                        obj = pstate.GetValue(ridcol, ii);
                        if (obj != null) {
                            let rid = Number.parseInt(obj.toString());
                            let foff= this.m_doc.Refs.findIndex((t)=> t.ID== rid);
                            if(foff >= 0) 
                                ln = this.m_doc.Refs[foff];                                
                        }
                    }

                    let setmod= false;
                    if (ln == null) {
                        let cmpid= this.m_doc.CompanyID;
                        if(cmpidcol >= 0) {
                            obj= pstate.GetValue(cmpidcol, ii);
                            if(obj!= null) cmpid= Number.parseInt(obj.toString());
                        } 

                        let objtp= '';
                        if(objtpcol >= 0) {
                            obj= pstate.GetValue(objtpcol, ii);
                            if(obj!= null) objtp= obj.toString();
                        }

                        let objid= '';
                        if(objidcol >= 0) {
                            obj= pstate.GetValue(objidcol, ii);
                            if(obj!= null) objid= obj.toString();
                        }

                        ln = WFDocumentRef.Create(cmpid, objtp, objid);
                        this.m_doc.Refs.push(ln); 
                        setmod= true;                      
                    } else {
                        if(cmpidcol >= 0) {
                            obj= pstate.GetValue(cmpidcol, ii);
                            ln.CompanyID= (obj!= null) ? Number.parseInt(obj.toString()) : this.m_doc.CompanyID;
                            setmod= true;
                        } 

                        if(objtpcol >= 0) {
                            obj= pstate.GetValue(objtpcol, ii);
                            ln.ObjectType= (obj!= null) ? obj.toString() : '';
                            setmod= true;
                        }

                        if(objidcol >= 0) {
                            obj= pstate.GetValue(objidcol, ii);
                            ln.ObjectID= (obj!= null) ? obj.toString() : '';
                            setmod= true;
                        }
                    }

                    if(setmod)
                        this.IsModified = true;                    
                }
                break;

            case WFLineOpType.GETREFS:
                let rcols= new Array<WFProcessVarColumn>(4);
                rcols[0] = WFProcessVarColumn.Create2('SYS_REFID', WFProcessVarColType.TYPE_INT);
                rcols[1] = WFProcessVarColumn.Create2('SYS_COMPANYID', WFProcessVarColType.TYPE_INT);
                rcols[2] = WFProcessVarColumn.Create2('SYS_OBJECTTYPE', WFProcessVarColType.TYPE_TEXT);
                rcols[3] = WFProcessVarColumn.Create2('SYS_OBJECTID', WFProcessVarColType.TYPE_TEXT);
                
                def = cpr.GetVariableByID(lopins.VariableID);
                pstate = new WFProcessVarState(def.Name, rcols, 0);
                if(this.m_doc.Refs.length > 0) {
                    pstate.AddLine(this.m_doc.Refs.length);
                    for(ii= 0; ii< this.m_doc.Refs.length; ii++) {
                        let ref= this.m_doc.Refs[ii];
                        pstate.SetValue(0, ii, ref.ID);
                        pstate.SetValue(1, ii, ref.CompanyID);
                        pstate.SetValue(2, ii, ref.ObjectType);
                        pstate.SetValue(3, ii, ref.ObjectID);
                    }
                }

                cstates = this.ExtendStates(cstates, lopins.VariableID, pstate);
                break;
           
            case WFLineOpType.CLEARREFS:
                if (this.m_doc.Refs.length > 0) {
                    this.m_doc.Refs.splice(0, this.m_doc.Refs.length);
                    this.IsModified = true;
                }
                break;
                
            case WFLineOpType.DELREFS:
                pstate = cstates.get(lopins.VariableID);
                
                let setmod= false;
                for (ii = 0; ii < pstate.RowCount; ii++) {
                    obj = pstate.GetValue2('SYS_REFID', ii);
                    if (obj != null) {
                        let rid = Number.parseInt(obj.toString());
                        let off= this.m_doc.Refs.findIndex((t)=> t.ID== rid);
                        if(off >= 0) {
                            this.m_doc.Refs.splice(off, 1);
                            setmod= true;
                        }
                    }
                }

                if(setmod)
                    this.IsModified= true;
               
                break;
        }
        if (firemodlines) {
            this.IsModified = true;
            if (this.OnLinesModified != null)
                this.OnLinesModified(this);
        }
        if (!this.FireAction(cpr, lopins.InternalID, cstates)) {
            this.TriggerCompleted(cpr, true, cstates);
        }
    }

    private ExecuteLineOpInstruction2(args: TriggerArgs, opret: number): void {
        let snd: WFLineopInstruction = <WFLineopInstruction>args.Instruction;

        let rethdrs = new Array<WFProcessVarColumn>(1);
        rethdrs[0] = WFProcessVarColumn.Create2('RESULT', WFProcessVarColType.TYPE_INT);

        let def: WFProcessVariable = args.Process.GetVariableByID(snd.VariableID);
        let pstate: WFProcessVarState = new WFProcessVarState(def.Name, rethdrs, 0);
        pstate.AddLine(1);
        pstate.SetValue(0, 0, opret);
        let nstates = this.ExtendStates(args.VarStates, snd.VariableID, pstate);
        if (!this.FireAction(args.Process, snd.InternalID, nstates)) {
            this.TriggerCompleted(args.Process, true, nstates);
        }
    }

    private ExecuteScriptOpInstruction(sopins: WFScriptopInstruction, cpr: WFCustomProcess, cstates: Map<number, WFProcessVarState>): void {
        if (sopins.OpType === WFScriptType.SQL) {

            let states = new Array<ProcessVarState>();
            let uctx = sopins.Content.toUpperCase();

            for (let kv of Array.from(cstates.entries())) {
                let st= kv[1];
                let unm= st.Name.toUpperCase();
                if(uctx.indexOf(IWFObject.Format('${0}', unm))>= 0 || uctx.indexOf(IWFObject.Format('#{0}', unm)) >= 0)
                    states.push({ varid: kv[0], pvar: st.Serialize() });
            }

            let trg = new TriggerArgs(cpr, sopins, cstates);
            let dbname = (this.m_doc.CompanyID > 0) ? this.m_conn.Companies.get(this.m_doc.CompanyID).DBName : '';

            const self = this;
            this.m_conn.DataService.executeScriptOp(this.m_conn.SessionID, cpr.ID, sopins.InternalID, states, dbname, this.m_OnError, (ret) => {
                self.ExecuteScriptOpCompleted(ret, trg);
            });

            return;
        }
        if (!this.FireAction(cpr, sopins.InternalID, cstates)) {
            this.TriggerCompleted(cpr, true, cstates);
        }
    }

    private ExecuteScriptOpCompleted(retstate: WFProcessVarState, args: TriggerArgs): void {
        try {
            let snd: WFScriptopInstruction = <WFScriptopInstruction>args.Instruction;
            let nstates: Map<number, WFProcessVarState> = this.ExtendStates(args.VarStates, snd.VariableID, retstate);
            if (!this.FireAction(args.Process, snd.InternalID, nstates)) {
                this.TriggerCompleted(args.Process, true, nstates);
            }
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        this.TriggerCompleted(null, true, null);
    }

    private ExecuteDialogWndInstruction(dlgins: WFDialogWndInstruction, cpr: WFCustomProcess, cstates: Map<number, WFProcessVarState>): void {
        let ii: number;
        let kv: [string, string];
        let reptbl: Array<[string, string]> = WFProcessVarState.PrepareReplaceTab([dlgins.Message, dlgins.Button1, dlgins.Button2, dlgins.Button3], cstates);
        let msg: string = dlgins.Message;

        if (!IWFObject.IsNullOrEmpty(msg)) {
            for (ii = 0; ii < reptbl.length; ii++) {
                kv = reptbl[ii];
                msg = msg.replace(kv[0], kv[1]);
            }
        }

        let btn1: string = dlgins.Button1;
        if (!IWFObject.IsNullOrEmpty(btn1)) {
            for (ii = 0; ii < reptbl.length; ii++) {
                kv = reptbl[ii];
                btn1 = btn1.replace(kv[0], kv[1]);
            }
        }

        let btn2: string = dlgins.Button2;
        if (!IWFObject.IsNullOrEmpty(btn2)) {
            for (ii = 0; ii < reptbl.length; ii++) {
                kv = reptbl[ii];
                btn2 = btn2.replace(kv[0], kv[1]);
            }
        }

        let btn3: string = dlgins.Button3;
        if (!IWFObject.IsNullOrEmpty(btn3)) {
            for (ii = 0; ii < reptbl.length; ii++) {
                kv = reptbl[ii];
                btn3 = btn3.replace(kv[0], kv[1]);
            }
        }

        if (this.m_conn.GlobalService.App != null) this.m_conn.GlobalService.App.BusyText = '';

        let trg = new TriggerArgs(cpr, dlgins, cstates);

        const wnd = this.m_conn.WndService.showControl(AlertWndComponent);
        const cmp = wnd.instance;
        cmp.icontype = ALR_ICONTYPE.INFORMATION;
        cmp.content = msg;
        cmp.can_cancel = true;

        if (!IWFObject.IsNullOrEmpty(btn1)) cmp.buttons.push({ id: 1, desc: btn1 });
        if (!IWFObject.IsNullOrEmpty(btn2)) cmp.buttons.push({ id: 2, desc: btn2 });
        if (!IWFObject.IsNullOrEmpty(btn3)) cmp.buttons.push({ id: 3, desc: btn3 });

        const self = this;
        cmp.clickButton.subscribe(function (btnid) {
            self.DialogWndClosed(btnid, trg);
            wnd.destroy();
        });
        cmp.clickCancel.subscribe(function () {
            wnd.destroy();
        });

        /*const self = this;
        this.m_conn.GlobalService.showInfo(BUTTONSTYPE.YESNOCANCEL, msg, (ret) => {
            self.DialogWndClosed(ret, trg);
        });*/
    }

    private DialogWndClosed(btnid: number, args: TriggerArgs): void {
        try {
            if (this.m_conn.GlobalService.App != null) this.m_conn.GlobalService.App.BusyText = '';

            /*let btnid = 0;
            switch (res) {
                case WNDRESULT.OKYES:
                    btnid = 1;
                    break;
                case WNDRESULT.NO:
                    btnid = 2;
                    break;
                case WNDRESULT.CANCEL:
                    btnid = 3;
                    break;
            }*/

            let dins: WFDialogWndInstruction = <WFDialogWndInstruction>args.Instruction;
            let nstates: Map<number, WFProcessVarState> = args.VarStates;
            if (dins.VariableID > 0) {
                let pvar: WFProcessVariable = args.Process.GetVariableByID(dins.VariableID);
                let pstate: WFProcessVarState = WFProcessVarState.Create(pvar);
                pstate.SetScalar(btnid);
                nstates = this.ExtendStates(nstates, dins.VariableID, pstate);
            }
            if (!this.FireAction(args.Process, dins.InternalID, nstates)) {
                this.TriggerCompleted(args.Process, true, nstates);
            }
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        this.TriggerCompleted(null, true, null);
    }

    private ExecuteExcallOpInstruction(excins: WFExcallopInstruction, cpr: WFCustomProcess, cstates: Map<number, WFProcessVarState>): void {
        if (excins.AssemblyType === WFExcallOpType.DNETEXASSEMBLY) {
            let states = new Array<ProcessVarState>();
            for (let kv of Array.from(cstates.entries())) {
                states.push({ varid: kv[0], pvar: kv[1].Serialize() });
            }

            let trg = new TriggerArgs(cpr, excins, cstates);
            let dbname = (this.m_doc.CompanyID > 0) ? this.m_conn.Companies.get(this.m_doc.CompanyID).DBName : '';

            const self = this;
            this.m_conn.DataService.executeScriptOp(this.m_conn.SessionID, cpr.ID, excins.InternalID, states, dbname, this.m_OnError, (ret) => {
                self.ExecuteExcallOpCompleted(ret, trg);
            });

            return;
        }
        if (!this.FireAction(cpr, excins.InternalID, cstates)) {
            this.TriggerCompleted(cpr, true, cstates);
        }
    }

    private ExecuteExcallOpCompleted(retstate: WFProcessVarState, args: TriggerArgs): void {
        try {
            let snd: WFExcallopInstruction = <WFExcallopInstruction>args.Instruction;
            let nstates: Map<number, WFProcessVarState> = this.ExtendStates(args.VarStates, snd.VariableID, retstate);
            if (!this.FireAction(args.Process, snd.InternalID, nstates)) {
                this.TriggerCompleted(args.Process, true, nstates);
            }
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        this.TriggerCompleted(null, true, null);
    }

    private FireAction(cpr: WFCustomProcess, actid: number, cstates: Map<number, WFProcessVarState>): boolean {
        let ii: number;
        let ret = false;
        let ilen: number = cpr.GetActionCount();
        for (ii = 0; ii < ilen; ii++) {
            let act: WFProcessAction = cpr.GetAction(ii);
            if (act.InsInternalID === actid) {
                if (act.Condition != null) {
                    if (!act.Condition.CheckConditions(this.m_doc, cpr, cstates, this.m_conn.AppSystem))
                        continue;
                }
                if (act.InsInternalID2 > 0) {
                    let cins: IWFCustomInstruction = cpr.GetInstructionByID(act.InsInternalID2);

                    if (this.m_conn.GlobalService.App != null) this.m_conn.GlobalService.App.BusyText = IWFObject.Format(this.m_conn.StringService.getStr('strWaitProcess'), cpr.Name, cins.Name);

                    switch (cins.Type) {
                        case WFInstructionType.LINEOP:
                            let lopins: WFLineopInstruction = <WFLineopInstruction>cins;
                            this.ExecuteLineOpInstruction(lopins, cpr, cstates);
                            break;
                        case WFInstructionType.SCRIPTOP:
                            let copins: WFScriptopInstruction = <WFScriptopInstruction>cins;
                            this.ExecuteScriptOpInstruction(copins, cpr, cstates);
                            break;
                        case WFInstructionType.DIALOGWND:
                            let dopins: WFDialogWndInstruction = <WFDialogWndInstruction>cins;
                            this.ExecuteDialogWndInstruction(dopins, cpr, cstates);
                            break;
                        case WFInstructionType.EXCALLOP:
                            let excins: WFExcallopInstruction = <WFExcallopInstruction>cins;
                            this.ExecuteExcallOpInstruction(excins, cpr, cstates);
                            break;
                    }
                    ret = true;
                } else {
                    if (act.InsInternalID2 === WFProcessAction.PROCESS_STOP || act.InsInternalID2 === WFProcessAction.APPROVEPOINT) {
                        this.TriggerCompleted(cpr, false, cstates);
                        return true;
                    }
                }
            }
        }
        return ret;
    }

    private TriggerCompleted(sndprc: WFCustomProcess, docontinue: boolean, cstates: Map<number, WFProcessVarState>): void {
        if (this.OnFireTriggerCompleted != null)
            this.OnFireTriggerCompleted(this, sndprc, docontinue, cstates);

        if (this.m_conn.GlobalService.App != null) this.m_conn.GlobalService.App.BusyText = '';
    }

    public FireTrigger(eventid: number, itemcode: string, itemval: string, lid: number, clsattr_id: number): void {
        let fired = false;
        if (this.m_doc.ID > 0) {
            let eventstartdef: WFProcessVariable = WFProcessVariable.GetSystemDefinitionByID(WFProcessVariable.EVENTARGSINTID);
            for (let cpr of Array.from(this.m_conn.CustomProcesses.values())) {
                if ((cpr.ClassID === this.m_doc.ClassID) || (cpr.ClassID === 0)) {
                    let states: Map<number, WFProcessVarState> = new Map<number, WFProcessVarState>();
                    let varstate: WFProcessVarState = WFProcessVarState.Create(eventstartdef);
                    varstate.AddLine(1);
                    varstate.SetValue2('OBJECTID', 0, this.m_doc.ID);
                    varstate.SetValue2('CLASSID', 0, this.m_doc.ClassID);
                    varstate.SetValue2('USERID', 0, this.m_doc.UserID);
                    varstate.SetValue2('DOCSTATUS', 0, <number>this.m_doc.DocStatus);
                    varstate.SetValue2('ITEMCODE', 0, itemcode);
                    varstate.SetValue2('ITEMVALUE', 0, itemval);
                    varstate.SetValue2('LINEID', 0, lid);
                    varstate.SetValue2('CLASSATTRIBS_ID', 0, clsattr_id);                    
                    states.set(WFProcessVariable.EVENTARGSINTID, varstate);
                    if (this.FireAction(cpr, eventid, states))
                        fired = true;
                }
            }
        }
        if (!fired) {
            this.TriggerCompleted(null, true, null);
        }
    }

    public FindLineValues(tmath: Map<string, Array<string>>, ustate: Object): void {
        let lines = new Array<LineValuesSpec>();
        for (let kv of Array.from(tmath.entries())) {
            lines.push({ code: kv[0], values: kv[1] });
        }

        const self = this;
        this.m_conn.DataService.findLineValues(this.m_conn.SessionID, this.m_doc.ID, this.m_doc.ClassID, this.m_doc.FindCardCode(this.m_conn.AppSystem), lines, this.m_OnError, (ret) => {
            this.OnFindLineValuesCompleted(self, ret, ustate);
        });
    }

    public SuggestOcrMap(schemacode: string, ustate: Object): void {
        const self = this;
        this.m_conn.DataService.suggestOcrMap(this.m_conn.SessionID, this.m_doc.ID, this.m_doc.ClassID, this.m_doc.FindCardCode(this.m_conn.AppSystem), schemacode,
            this.m_OnError, (ret) => {
                self.OnSuggestOcrMapCompleted(self, ret, ustate);
            });
    }

    public SetOcrMaps(rels: Array<WFRelation>, schemacode: string): void {
        let ccode: string = this.m_doc.FindCardCode(this.m_conn.AppSystem);
        this.m_doc.ClearOcrMaps(schemacode, ccode, this.m_conn.AppSystem);
        this.m_doc.AddOcrMaps(rels, schemacode, ccode, this.m_conn.AppSystem);
    }

    public FindOcrMaps(schemacode: string): Array<WFRelation> {
        let ccode: string = this.m_doc.FindCardCode(this.m_conn.AppSystem);
        let ret: Array<WFRelation> = new Array<WFRelation>();
        let mps: Array<WFDocumentOcrMap> = this.m_doc.FindOcrMaps(schemacode, ccode, this.m_conn.AppSystem);
        for (let mp of mps) ret.push(mp.Relation);
        return ret;
    }

    public GetGroupingCount(): number {
        return this.m_doc.Grouping.length;
    }
    public GetGrouping(id: number): number {
        return this.m_doc.Grouping[id];
    }
    public IsGrouping(atr: WFClassAttrib): boolean {
        return (this.m_doc.Grouping.indexOf(atr.ID) >= 0);
    }
    public AddGrouping(atr: WFClassAttrib): boolean {
        if (this.m_doc.Grouping.indexOf(atr.ID) < 0) {
            this.m_doc.Grouping.push(atr.ID);
            this.IsModified = true;
            return true;
        }
        return false;
    }
    public RemoveGrouping(atr: WFClassAttrib): boolean {
        let off = this.m_doc.Grouping.indexOf(atr.ID);
        if (off >= 0) {
            this.m_doc.Grouping.splice(off, 1);
            this.IsModified = true;
            return true;
        }
        return false;
    }
    public ClearGrouping(): void {
        if (this.m_doc.Grouping.length > 0) {
            this.m_doc.Grouping.splice(0, this.m_doc.Grouping.length);
            this.IsModified = true;
        }
    }
    public get NewOcrLogs(): number {
        return this.m_newocrlogs;
    }
    public get DailyCorRefs(): Array<DailyCorRefInfo> {
        return this.m_dailycorrefs;
    }
    public CanCutPage(pg: WFDocumentPage): boolean {
        return (this.m_importedpgids.indexOf(pg) >= 0);
    }

    private findDocAuthSchema(ashm: WFDocumentAuthSchema, authshm_id: number): WFDocumentAuthSchema {
        let ii: number;
        let rc = ashm.GetRecipientsCount();
        for (ii = 0; ii < rc; ii++) {
            let rcp = ashm.GetRecipient(ii);
            if (rcp.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
                if (rcp.ObjID === authshm_id) return rcp.SubSchema;
                let sret = this.findDocAuthSchema(rcp.SubSchema, authshm_id);
                if (sret != null) return sret;
            }
        }
        return null;
    }

    private createAuthInfo(rcp: WFDocumentAuthRecipient): AuthorizationInfo {
        let usr = (rcp.AuthUserID === this.m_conn.User.ID) ? this.m_conn.User : this.m_conn.Users.get(rcp.AuthUserID);
        return { user_id: rcp.AuthUserID, user_name: usr.Name, auth_info: rcp.AdditionalInfo, auth_time: rcp.Approved };
    }

    private addAuthInfofromSchema(dst: Array<AuthorizationInfo>, shm: WFDocumentAuthSchema): void {
        let ii: number;
        let rc = shm.GetRecipientsCount();
        for (ii = 0; ii < rc; ii++) {
            let rcp = shm.GetRecipient(ii);
            if (rcp.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
                this.addAuthInfofromSchema(dst, rcp.SubSchema);
            } else {
                if (rcp.Status === WFAuthStatus.APPROVED) dst.push(this.createAuthInfo(rcp));
            }
        }
    }

    private createAuthInfoVec(rcp: WFDocumentAuthRecipient): Array<AuthorizationInfo> {
        let ret = new Array<AuthorizationInfo>();

        if (rcp.Status === WFAuthStatus.APPROVED) {
            if (rcp.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
                this.addAuthInfofromSchema(ret, rcp.SubSchema);
            } else {
                ret.push(this.createAuthInfo(rcp));
            }
            return ret;
        }

        if (rcp.Status === WFAuthStatus.WAITING && rcp.ObjType === WFDocAuthObjType.DOCAUTHSCHEMA) {
            this.addAuthInfofromSchema(ret, rcp.SubSchema);
            return ret;
        }

        return ret;
    }

    public GetAuthorizationInfo(shm_id: number, level: number): Array<AuthorizationInfo> {
        let ii: number;

        let ashm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (ashm != null) {
            if (shm_id > 0 && shm_id !== ashm.AuthSchemaID) {
                ashm = this.findDocAuthSchema(ashm, shm_id);
                if (ashm == null) return null;
            }

            let rc = ashm.GetRecipientsCount();
            if (level < 0) {
                let alvl = -1;

                for (ii = 0; ii < rc; ii++) {
                    let rcp = ashm.GetRecipient(ii);
                    if (rcp.Status === WFAuthStatus.WAITING) {
                        alvl = ii;
                        break;
                    }
                }

                if (alvl < 0) return null;

                level = alvl + level;
                if (level < 0) level = 0;
            }

            if (level < rc) {
                let rcp = ashm.GetRecipient(level);
                return this.createAuthInfoVec(rcp);
            }
        }

        return null;
    }

    public GetAuthorizationInfo2(inst_id: number): Array<AuthorizationInfo> {
        let ii: number;

        let ashm: WFDocumentAuthSchema = this.m_doc.GetLastAuthSchema();
        if (ashm != null) {
            let rc = ashm.GetRecipientsCount();
            if (inst_id < 0) {
                let alvl = -1;

                for (ii = 0; ii < rc; ii++) {
                    let rcp = ashm.GetRecipient(ii);
                    if (rcp.Status === WFAuthStatus.WAITING) {
                        alvl = ii;
                        break;
                    }
                }

                if (alvl < 0) return null;

                inst_id = alvl + inst_id;
                if (inst_id < 0) inst_id = 0;

                if (inst_id < rc) {
                    let rcp = ashm.GetRecipient(inst_id);
                    return this.createAuthInfoVec(rcp);
                }
            } else {
                if (inst_id > 0) {
                    for (ii = 0; ii < rc; ii++) {
                        let rcp = ashm.GetRecipient(ii);
                        if (rcp.InstructionInternalID === inst_id) return this.createAuthInfoVec(rcp);
                    }
                } else {
                    let ret = new Array<AuthorizationInfo>();
                    let usr = (this.m_doc.UserID === this.m_conn.User.ID) ? this.m_conn.User : this.m_conn.Users.get(this.m_doc.UserID);
                    ret.push({ user_id: this.m_doc.UserID, user_name: usr.Name, auth_info: '', auth_time: this.m_doc.UpdatedAt });
                    return ret;
                }
            }
        }

        return null;
    }

    //

    public getEx(key: string): [string, string] {
        key = key.toUpperCase();
        if (this.m_props.has(key)) {
            let val = this.m_props.get(key);
            let desc = '';

            if (this.m_doc.ClassID > 0 && this.m_allattribs != null) {

                for (let att of this.m_allattribs) {
                    if (att.Name.toUpperCase() === key) {

                        switch (att.Type) {
                            case WFFieldType.TYPE_SYSDICT:
                                if (att.CompaniesDictsID.has(this.CompanyID)) {
                                    let did: number = att.CompaniesDictsID.get(this.CompanyID);
                                    let dc: WFCompanyDict = this.m_conn.CompanyDicts.get(did);
                                    if (dc.Values.has(val)) desc = dc.Values.get(val);
                                }
                                break;
                            case WFFieldType.TYPE_USRDICT:
                                if (att.ValidValues.has(val)) desc = att.ValidValues.get(val);
                                break;
                            case WFFieldType.TYPE_STATICDICT:
                                let sdc: WFDictionary = this.m_conn.AppSystem.AllDictionaries.get(att.DictionaryID);
                                if (sdc.ValidValues.has(val)) desc = sdc.ValidValues.get(val);
                                break;
                            case WFFieldType.TYPE_INTDICT:
                                //let idc: WFInteractiveDictAdp = this.m_conn.Dictionaries.get(att.DictionaryID);
                                let off = val.indexOf('<!>');
                                if (off > 0) {
                                    desc = val.substr(off + 3);
                                    val = val.substr(0, off);
                                }
                                break;
                        }

                        break;
                    }
                }

            }

            return [val, desc];
        }
        return null;
    }

    //

    private DateToStr(dta: Date, frm: string, dpipe: DatePipe): string {
        if (dta == null) return '';
        if (frm.length > 0) return dpipe.transform(dta, frm);
        return dpipe.transform(dta, 'dd-MM-yyyy');
    }

    private DictToStr(val: [string, string], frm: string): string {
        switch (frm.toUpperCase()) {
            case "CODE": return val[0];
            case "VALUE": return val[1];
        }
        return (IWFObject.IsNullOrEmpty(val[1])) ? val[0] : IWFObject.Format('{0} {1}', val[0], val[1]);
    }

    public PrepareDocPath(pth: string): string[] {
        let emptyvd: [string, string] = ['0', ''];
        let dpipe = new DatePipe('pl');

        let mths = pth.match(/{[^}]+}/g);
        if (mths != null) {
            for (let mt of mths) {
                let prop = mt.substr(1, mt.length - 2);
                let frm: string;

                let eoff = prop.indexOf('|');
                if (eoff > 0) {
                    frm = prop.substr(eoff + 1).trim();
                    prop = prop.substr(0, eoff).trim();
                } else {
                    frm = '';
                }

                let vd: [string, string] = emptyvd;
                let nval = '';
                prop = prop.toUpperCase();
                if (prop[0] == '_') {
                    prop = prop.substr(1);
                    let vex = this.getEx(prop);
                    if (vex != null) nval = this.DictToStr(vex, frm);
                } else {
                    switch (prop) {
                        case 'ID':
                            nval = this.m_doc.ID.toString();
                            break;
                        case 'USER':
                            if (this.m_doc.UserID > 0) {
                                let usr = (this.m_doc.UserID == this.m_conn.User.ID) ? this.m_conn.User : this.m_conn.Users.get(this.m_doc.UserID);
                                vd = [usr.ID.toString(), usr.Name];
                            }
                            nval = this.DictToStr(vd, frm);
                            break;
                        case 'CLASS':
                            if (this.m_doc.ClassID > 0) {
                                let cls = this.m_conn.Classes.get(this.m_doc.ClassID);
                                vd = [cls.ID.toString(), cls.Name];
                            }
                            nval = this.DictToStr(vd, frm);
                            break;
                        case 'COMPANY':
                            if (this.m_doc.CompanyID > 0) {
                                let cmp = this.m_conn.Companies.get(this.m_doc.CompanyID);
                                vd = [cmp.ID.toString(), cmp.Company];
                            }
                            nval = this.DictToStr(vd, frm);
                            break;
                        case 'TEMPLATEID':
                            nval = this.m_doc.TemplateID.toString();
                            break;
                        case 'NAME':
                            nval = this.m_doc.Name;
                            break;
                        case 'DESCRIPTION':
                            nval = this.m_doc.Description;
                            break;
                        case 'DOCDATE':
                            nval = this.DateToStr(this.m_doc.DocDate, frm, dpipe);
                            break;
                        case 'ENTERDATE':
                            nval = this.DateToStr(this.m_doc.EnterDate, frm, dpipe);
                            break;
                        case 'DOCNUM':
                            nval = this.m_doc.DocNum;
                            break;
                        case 'GETDATE':
                            nval = this.DateToStr(new Date(), frm, dpipe);
                            break;
                    }
                }
                pth = pth.replace(mt, nval);
            }
        }

        return pth.split('\\');
    }

    public EvalColumns(cols: Array<WFClassAttrib>, from: number, to: number): void {
        let cls: WFClass = this.m_conn.Classes.get(this.m_doc.ClassID);
        for (let col of cols) {            
            if (!IWFObject.IsNullOrEmpty(col.EvalCode)) {
                if(from < to)
                    this.EvalColumn(cls, col, from, to);
                else
                    this.EvalColumn(cls, col, 0, this.m_lines.length);
            }
        }
    }
}

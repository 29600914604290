import { WFFilterInfo, WFDocument, IWFObject } from '../model/index';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';

export enum SORT_PROPERTY {
    BY_DOCNAME,
    BY_DOCDESC,
    BY_DOCNUM,
    BY_DOCDATE,
    BY_CREATEDATE,
    BY_UPDATEDATE
}

export type _FetchCompleted = (sender: DocumentList, docs: Array<WFDocumentAdp>) => void;
export type _FetchFilterInfoCompleted = (sender: DocumentList, finf: WFFilterInfo) => void;

export class DocumentList {
    //private m_session: LoginWnd;
    private m_documents: Array<number>;
    private m_knownorder: boolean;
    private m_sortp: SORT_PROPERTY;
    private m_order: boolean;
    private m_actfltinf: WFFilterInfo;

    public FetchCompleted: _FetchCompleted;
    public FetchFilterInfoCompleted: _FetchFilterInfoCompleted;
    public m_OnError: EventAdp<_OnError>;

    constructor() {
        //this.m_session = sess;
        this.m_documents = new Array<number>();
        this.m_knownorder = false;
        this.m_sortp = SORT_PROPERTY.BY_CREATEDATE;
        this.m_order = false;
        this.m_actfltinf = null;
        this.FetchCompleted = null;
        this.FetchFilterInfoCompleted = null;
        this.m_OnError = new EventAdp<_OnError>();
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    public Add(docid: number): void {
        if (this.m_documents.indexOf(docid) < 0) {
            this.m_documents.push(docid);
            this.m_knownorder = false;
            this.m_actfltinf = null;
        }
    }

    public Add2(docsid: Array<number>): void {
        let added = false;
        for (let did of docsid) {
            if (this.m_documents.indexOf(did) < 0) {
                this.m_documents.push(did);
                added = true;
            }
        }
        if (added) {
            this.m_knownorder = false;
            this.m_actfltinf = null;
        }
    }

    public Remove(docid: number): boolean {
        let off = this.m_documents.indexOf(docid);
        if (off >= 0) {
            this.m_documents.splice(off, 1);
            this.m_knownorder = false;
            this.m_actfltinf = null;
            return true;
        }
        return false;
    }

    public Clear(): void {
        this.m_documents.splice(0, this.m_documents.length);
        this.m_knownorder = false;
        this.m_actfltinf = null;
    }

    public get Count(): number {
        return this.m_documents.length;
    }

    public get(key: number): number {
        return this.m_documents[key];
    }

    public Contains(docid: number): boolean {
        return (this.m_documents.indexOf(docid) >= 0);
    }

    public get IsKnownOrder(): boolean {
        return this.m_knownorder;
    }

    public get CurrentSortCol(): SORT_PROPERTY {
        return this.m_sortp;
    }

    public get CurrentOrder(): boolean {
        return this.m_order;
    }

    //

    private FetchDocuments2(conn: CacheService, start: number, len: number): void {
        let ii: number;
        let dest: number = start + len;
        if (dest > this.m_documents.length) {
            dest = this.m_documents.length;
            len = dest - start;
        }
        let ret: Array<WFDocumentAdp> = new Array<WFDocumentAdp>(len);
        let ret_c = 0;
        let tofetch: Array<number> = new Array<number>();

        for (ii = start; ii < dest; ii++) {
            let docid: number = this.m_documents[ii];
            if (conn.ContainsDocument(docid)) {
                ret[ret_c++] = conn.GetDocument(docid);
            } else {
                tofetch.push(docid);
            }
        }
        if (ret_c === len) {
            this.FetchCompleted(this, ret);
        } else {
            const self = this;
            conn.DataService.getDocuments(conn.SessionID, tofetch, true, this.m_OnError,
                (docs, ronly) => {
                    self.GetDocumentsCompleted(conn, docs, start, len, ronly);
                });
        }
    }

    //

    public FetchDocuments(
        conn: CacheService, col: SORT_PROPERTY,
        order: boolean,
        start: number, len: number): void {

        let ii: number;
        if ((col === this.m_sortp) && (order === this.m_order) && (this.m_knownorder)) {
            this.FetchDocuments2(conn, start, len);
        } else {
            this.m_sortp = col;
            this.m_order = order;
            let dolocalsort = true;
            for (ii = 0; ii < this.m_documents.length; ii++) {
                if (!conn.ContainsDocument(this.m_documents[ii])) {
                    dolocalsort = false;
                    break;
                }
            }
            if (dolocalsort) {
                const self = this;
                switch (this.m_sortp) {
                    case SORT_PROPERTY.BY_DOCNAME:
                        if (this.m_order)
                            this.m_documents.sort((docid1, docid2) => IWFObject.StringCompare(conn.GetDocument(docid1).Name, conn.GetDocument(docid2).Name));
                        else this.m_documents.sort((docid1, docid2) => IWFObject.StringCompare(conn.GetDocument(docid2).Name, conn.GetDocument(docid1).Name));
                        break;
                    case SORT_PROPERTY.BY_DOCDESC:
                        if (this.m_order)
                            this.m_documents.sort((docid1, docid2) => IWFObject.StringCompare(conn.GetDocument(docid1).Description, conn.GetDocument(docid2).Description));
                        else this.m_documents.sort((docid1, docid2) => IWFObject.StringCompare(conn.GetDocument(docid2).Description, conn.GetDocument(docid1).Description));
                        break;
                    case SORT_PROPERTY.BY_DOCNUM:
                        if (this.m_order)
                            this.m_documents.sort((docid1, docid2) => IWFObject.StringCompare(conn.GetDocument(docid1).DocNum, conn.GetDocument(docid2).DocNum));
                        else this.m_documents.sort((docid1, docid2) => IWFObject.StringCompare(conn.GetDocument(docid2).DocNum, conn.GetDocument(docid1).DocNum));
                        break;
                    case SORT_PROPERTY.BY_DOCDATE:
                        if (this.m_order)
                            this.m_documents.sort((docid1, docid2) => IWFObject.DateCompare(conn.GetDocument(docid1).DocDate, conn.GetDocument(docid2).DocDate));
                        else this.m_documents.sort((docid1, docid2) => IWFObject.DateCompare(conn.GetDocument(docid2).DocDate, conn.GetDocument(docid1).DocDate));
                        break;
                    case SORT_PROPERTY.BY_CREATEDATE:
                        if (this.m_order)
                            this.m_documents.sort((docid1, docid2) => IWFObject.DateCompare(conn.GetDocument(docid1).CreatedAt, conn.GetDocument(docid2).CreatedAt));
                        else this.m_documents.sort((docid1, docid2) => IWFObject.DateCompare(conn.GetDocument(docid2).CreatedAt, conn.GetDocument(docid1).CreatedAt));
                        break;
                    case SORT_PROPERTY.BY_UPDATEDATE:
                        if (this.m_order)
                            this.m_documents.sort((docid1, docid2) => IWFObject.DateCompare(conn.GetDocument(docid1).UpdatedAt, conn.GetDocument(docid2).UpdatedAt));
                        else this.m_documents.sort((docid1, docid2) => IWFObject.DateCompare(conn.GetDocument(docid2).UpdatedAt, conn.GetDocument(docid1).UpdatedAt));
                        break;
                }
                let ret: Array<WFDocumentAdp> = new Array<WFDocumentAdp>(len);
                let ret_c = 0;
                let dest: number = start + len;
                for (ii = start; ii < dest; ii++) {
                    ret[ret_c++] = conn.GetDocument(this.m_documents[ii]);
                }
                this.m_knownorder = true;
                this.FetchCompleted(this, ret);
            } else {
                const self = this;
                conn.DataService.sortDocuments(conn.SessionID, this.m_documents, <number>this.m_sortp, this.m_order, this.m_OnError,
                    (docs) => {
                        self.SortDocumentsCompleted(conn, docs, start, len);
                    });
            }
        }
    }

    private SortDocumentsCompleted(conn: CacheService, docs: Array<number>, start: number, len: number): void {
        try {
            this.m_documents = docs;
            this.m_knownorder = true;
            this.FetchDocuments2(conn, start, len);
        } catch (ex) {
            conn.GlobalService.manageException(ex);
        }
    }

    private GetDocumentsCompleted(conn: CacheService, docs: Array<WFDocument>, start: number, len: number, ronly: Array<number>): void {
        let ii: number;
        try {
            for (ii = 0; ii < docs.length; ii++) {
                let doc: WFDocument = docs[ii];
                let adp: WFDocumentAdp = new WFDocumentAdp(conn, doc, true, ronly.includes(doc.ID));
                conn.AddDocument(adp);
            }
            let ret: Array<WFDocumentAdp> = new Array<WFDocumentAdp>(len);
            let ret_c = 0;
            let dest = start + len;
            for (ii = start; ii < dest; ii++) {
                let docid = this.m_documents[ii];
                ret[ret_c++] = conn.GetDocument(docid);
            }
            this.FetchCompleted(this, ret);
        } catch (ex) {
            conn.GlobalService.manageException(ex);
        }
    }

    public FetchFilterInfo(conn: CacheService, force: boolean): void {
        let ii: number;
        if (this.m_documents.length > 0) {
            if (this.m_actfltinf == null || force) {
                const self = this;
                conn.DataService.getFilterInfo(conn.SessionID, this.m_documents, this.m_OnError,
                    (filter) => {
                        self.GetFilterInfoCompleted(conn, filter);
                    });
                return;
            }
        }

        if (this.FetchFilterInfoCompleted != null) this.FetchFilterInfoCompleted(this, this.m_actfltinf);
    }

    private GetFilterInfoCompleted(conn: CacheService, filter: WFFilterInfo): void {
        try {
            this.m_actfltinf = filter;
            if (this.FetchFilterInfoCompleted != null) this.FetchFilterInfoCompleted(this, this.m_actfltinf);
        } catch (ex) {
            conn.GlobalService.manageException(ex);
        }
    }
}
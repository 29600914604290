import { IWFObject } from './IWFObject';

export enum WFReconScriptType {
    SCRIPT_CSHARP,
    SCRIPT_BASIC,
    SCRIPT_MSSQL
}

export enum WFMatchScriptType {
    MATCH_REGEX,
    MATCH_CSHARP,
    MATCH_BASIC
}

export class WFReconSchema {
    private m_code: string;
    private m_description: string;
    private m_reconschemarequests_code: string;
    private m_type: WFReconScriptType;
    private m_header_script: string;
    private m_lines_script: string;
    private m_matchtype: WFMatchScriptType;
    private m_match_script: string;
    private m_updatedat: Date;
    private m_createdat: Date;

    //

    constructor(obj: any) {
        this.m_code = obj.code;
        this.m_description = obj.description;
        this.m_reconschemarequests_code = obj.reconschemarequests_code;
        this.m_type = obj.type;
        this.m_header_script = obj.header_script;
        this.m_lines_script = obj.lines_script;
        this.m_matchtype = obj.matchtype;
        this.m_match_script = obj.match_script;
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
    }

    //

    public get Code(): string {
        return this.m_code;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get ReconSchemaRequestsCode(): string {
        return this.m_reconschemarequests_code;
    }

    public get ScriptType(): WFReconScriptType {
        return this.m_type;
    }

    public get HeaderScript(): string {
        return this.m_header_script;
    }

    public get LinesScript(): string {
        return this.m_lines_script;
    }

    public get MatchScriptType(): WFMatchScriptType {
        return this.m_matchtype;
    }

    public get MatchScript(): string {
        return this.m_match_script;
    }
    public toString(): string {
        return this.m_code;
    }

    //

    public ToObject(): any {
        return {
            code: this.m_code,
            description: this.m_description,
            reconschemarequests_code: this.m_reconschemarequests_code,
            type: this.m_type,
            header_script: this.m_header_script,
            lines_script: this.m_lines_script,
            matchtype: this.m_matchtype,
            match_script: this.m_match_script,
            updatedat: IWFObject.DateToString(this.m_updatedat),
            createdat: IWFObject.DateToString(this.m_createdat)
        };
    }
}

import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, CodeName, CodeCodeName, BusinessPartnerAddress, BusinessPartner } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { IWFObject, WFPermission, WFPrmType, WFUserGroup, WFGroupRole, WFPropType, WFAuthSchema, WFPrmObjType, WFDocumentAuthVarSchema, WFAuthVarSchemaRecipient, WFObjType, WFSchemaRecipient, WFClass, WFAuthPermission, WFDocument, WFAuthMethodType } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { ParentpermissionsComponent } from '../parentpermissions/parentpermissions.component';

export interface EditPermissionsRowSpec {
  selected: boolean;
  permtype: ComboIValueDesc;
  objtype: ComboIValueDesc;
  objid_cmb: ComboboxadpComponent;
  prm: WFPermission;
}

@Component({
  selector: 'app-editpermissions',
  templateUrl: './editpermissions.component.html',
  styleUrls: ['./editpermissions.component.scss']
})

export class EditpermissionsComponent implements AfterViewInit {
  public m_busy_str: string;
  public m_permissions_cnt: boolean;
  public m_permissions: Array<EditPermissionsRowSpec>;
  public m_btnplus: boolean;
  public m_btnminus: boolean;
  public m_copyshm: boolean;
  public m_history: boolean;

  @ViewChild('m_groups', {static: false}) private m_groups: ComboboxadpComponent;

  public m_permtypes: Array<ComboIValueDesc>;
  public m_objtype: Array<ComboIValueDesc>;

  private m_doc: WFDocumentAdp;
  private m_moveprmmode: boolean;
  private m_movingline: number;
  private m_readonly: boolean;

  @Output() public onClosed: EventEmitter<boolean>;

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_busy_str = '';
    this.m_permissions_cnt = true;
    this.m_permissions = new Array<EditPermissionsRowSpec>();
    this.m_btnplus = true;
    this.m_btnminus = true;
    this.m_copyshm = true;
    this.m_history = true;

    this.m_permtypes = new Array<ComboIValueDesc>(3);
    this.m_permtypes[0] = { val: <number>WFPrmType.CHANGE, desc: this.m_strdata_srv.getStr('enumWFDocPrmTypeCHANGE') };
    this.m_permtypes[1] = { val: <number>WFPrmType.VIEW, desc: this.m_strdata_srv.getStr('enumWFDocPrmTypeVIEW') };
    this.m_permtypes[2] = { val: <number>WFPrmType.DISABLE, desc: this.m_strdata_srv.getStr('enumWFDocPrmTypeDISABLE') };

    this.m_objtype = new Array<ComboIValueDesc>(2);
    this.m_objtype[0] = { val: <number>WFPrmObjType.USER, desc: this.m_strdata_srv.getStr('enumWFDocObjTypeUSER') };
    this.m_objtype[1] = { val: <number>WFPrmObjType.USERGROUP, desc: this.m_strdata_srv.getStr('enumWFDocObjTypeUSERGROUP') };

    this.m_doc = null;
    this.m_moveprmmode = false;
    this.m_movingline = -1;
    this.m_readonly = false;

    this.onClosed = new EventEmitter<boolean>();
  }

  //

  public SetPrms(doc: WFDocumentAdp, ronly: boolean): void {
    this.m_doc = doc;
    this.m_readonly = ronly;
  }

  //

  public ngAfterViewInit(): void {
    try {
      let perm: Array<WFPermission> = this.m_doc.ClonePermissions();
      for (let pr of perm) {
        this.AddNewRow(pr);
      }

      this.m_groups.AddValidValue('0', this.m_strdata_srv.getStr('strEmpty'));
      let sgrp= Array.from(this.m_cache_srv.UserGroups.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
      for (let gr of sgrp) {
        this.m_groups.AddValidValue(gr.ID.toString(), IWFObject.Format('{0} - {1}', gr.Name.toUpperCase(), gr.Description.trim()));
      }

      this.m_groups.SelectedValue = '0';
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public OKButton_Click(): void {
    let ii: number;

    try {
      let prms: Array<WFPermission> = new Array<WFPermission>();
      for (ii = 0; ii < this.m_permissions.length; ii++) {
        let pr = this.m_permissions[ii];
        let dp: WFPermission = pr.prm;
        dp.PermissionType = <WFPrmType>pr.permtype.val;
        dp.ObjType = <WFPrmObjType>pr.objtype.val;
        dp.ObjID = IWFObject.ParseInt(pr.objid_cmb.SelectedValue);
        prms.push(dp);
      }

      this.m_doc.SetPermissions(prms);
      this.onClosed.emit(true);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public ClickAddNewRow(): void {
    let ii: number;

    try {
      if (this.m_doc.CanChangePermissions && !this.m_readonly) {
        let grid = IWFObject.ParseInt(this.m_groups.SelectedValue);
        if (grid > 0) {
          let gr: WFUserGroup = this.m_cache_srv.UserGroups.get(grid);
          for (ii = 0; ii < gr.Users.length; ii++) {
            this.AddNewRow(WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, gr.Users[ii], WFPrmObjType.USER));
          }
        } else {
          let objid = -1;
          let tp: WFPrmObjType = WFPrmObjType.USER;
          for (let usr of Array.from(this.m_cache_srv.Users.values())) {
            if (!usr.IsLocked) {
              objid = usr.ID;
              break;
            }
          }

          if (objid < 0) {
            tp = WFPrmObjType.USERGROUP;
            objid = (this.m_cache_srv.UserGroups.size > 0) ? IWFObject.ElementAt(this.m_cache_srv.UserGroups.values(), 0).ID : WFUserGroup.ALLUSERS;
          }

          if (objid > 0) this.AddNewRow(WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, objid, tp));
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickRemoveRows(): void {
    try {
      if (this.m_doc.CanChangePermissions &&
        this.m_permissions.length > 0 &&
        !this.m_readonly) {
        const self = this;
        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strDelCheckedLinesAsk'),
          (ret) => {
            self.RemoveRowsClosedAsk(ret);
          });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private RemoveRowsClosedAsk(ret: WNDRESULT): void {
    try {
      if (ret === WNDRESULT.OKYES) {
        let ii = 0;
        while (ii < this.m_permissions.length) {
          let pr = this.m_permissions[ii];
          if (pr.selected) {
            //usun wiersz
            this.m_permissions.splice(ii, 1);
          } else {
            ii++;
          }
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickCopySchema(): void {
    try {
      if (this.m_doc.CanChangePermissions && !this.m_readonly) {
        let usrid = new Array<number>();
        let grpid = new Array<number>();
        this.m_doc.ExtractUsersAndGroupsFromSchema(usrid, grpid);

        for (let gid of grpid) {
          this.AddNewRow(WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, gid, WFPrmObjType.USERGROUP));
        }

        for (let uid of usrid) {
          this.AddNewRow(WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, uid, WFPrmObjType.USER));
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickShowHistory(): void {
    try {
      const wnd = this.m_wnd_srv.showControl(ParentpermissionsComponent);
      const inst: ParentpermissionsComponent = <ParentpermissionsComponent>wnd.instance;
      inst.SetPrms(this.m_doc, false);
      inst.onClosed.subscribe((ret) => {
        wnd.destroy();
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private AddNewRow(prm: WFPermission): void {
    this.m_permissions.push({
      selected: false,
      permtype: this.m_permtypes.find((tst) => tst.val === prm.PermissionType),
      objtype: this.m_objtype.find((tst) => tst.val === prm.ObjType),
      objid_cmb: null,
      prm: prm
    });
  }

  public InitComboBox(cmb: ComboboxadpComponent, prm: EditPermissionsRowSpec): void {
    prm.objid_cmb = cmb;
    this.ObjTypeChanged(prm.objtype, prm);
  }

  public ObjTypeChanged(nval: ComboIValueDesc, prm: EditPermissionsRowSpec): void {
    prm.objtype = nval;

    try {
      prm.objid_cmb.ClearValidValues();
      let dp: WFPermission = prm.prm;
      let selval = '0';
      switch (<WFPrmObjType>prm.objtype.val) {
        case WFPrmObjType.USER:
          let sval = this.m_cache_srv.User.ID.toString();
          prm.objid_cmb.AddValidValue(sval, this.m_cache_srv.User.FriendlyName);
          if (dp.ObjID === this.m_cache_srv.User.ID && dp.ObjType === WFPrmObjType.USER) selval = sval;

          let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
          for (let usr of susr) {
            if (!usr.IsLocked) {
              prm.objid_cmb.AddValidValue(usr.ID.toString(), usr.FriendlyName);
              if (dp.ObjID === usr.ID &&
                dp.ObjType === WFPrmObjType.USER) selval = usr.ID.toString();
            }
          }
          break;

        case WFPrmObjType.USERGROUP:
          prm.objid_cmb.AddValidValue('0', this.m_strdata_srv.getStr('AllUserGroup'));

          let sugr= Array.from(this.m_cache_srv.UserGroups.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())))
          for (let grp of sugr) {
            if (grp.GroupRole !== WFGroupRole.SUPERUSERS) {
              let sid = grp.ID.toString();
              prm.objid_cmb.AddValidValue(sid, IWFObject.Format('{0} - {1}', grp.Name.toUpperCase(), grp.Description.trim()));
              if (dp.ObjID === grp.ID &&
                dp.ObjType === WFPrmObjType.USERGROUP) {
                selval = sid;
              }
            }
          }
          break;
      }

      prm.objid_cmb.SelectedValue = selval;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

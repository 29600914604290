export class EventAdp<T> {
    private m_handles: Array<T>;

    constructor() {
        this.m_handles = new Array<T>();
    }

    public subscribe(nval: T): boolean {
        if (this.m_handles.indexOf(nval) < 0) {
            this.m_handles.push(nval);
            return true;
        }
        return false;
    }

    public subscribe2(src: EventAdp<T>): void {
        for (let hdl of src.m_handles) {
            if (this.m_handles.indexOf(hdl) < 0) this.m_handles.push(hdl); 
        }
    }

    public unsubscribe(nval: T): boolean {
        let off = this.m_handles.indexOf(nval);
        if (off >= 0) {
            this.m_handles.splice(off, 1);
            return true;
        }
        return false;
    }

    public unsubscribe2(src: EventAdp<T>) {
        for (let hdl of src.m_handles) {
            let off = this.m_handles.indexOf(hdl);
            if (off >= 0) this.m_handles.splice(off, 1);
        }
    }

    public call(...args: any[]): void {
        for (let hdl of this.m_handles) {
           let ann = (hdl as any);
           let cll = (ann as (...args: any[]) => void);
           cll(args);           
        }
    }
}
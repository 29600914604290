import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { LocalDailyCorRefInfo } from '../app.component';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { AddNewDocMenuComponent } from '../add-new-doc-menu/add-new-doc-menu.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { GlobalService } from '../global/global.service';
import { DailyCorRefInfo } from '../data/data.service';


@Component({
  selector: 'app-daily-cor-refs-popup',
  templateUrl: './daily-cor-refs-popup.component.html',
  styleUrls: ['./daily-cor-refs-popup.component.scss']
})
export class DailyCorRefsPopupComponent {
  public m_refelement: any;
  public m_dailycorrefs: Array<LocalDailyCorRefInfo>;

  @Output() closePopup: EventEmitter<void>;
  @Output() selectDailyCorRef: EventEmitter<DailyCorRefInfo>;
  @Output() removeFromDailyCor: EventEmitter<DailyCorRefInfo>;

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService) { 

    this.m_refelement = null;
    this.m_dailycorrefs = new Array<LocalDailyCorRefInfo>();
    this.closePopup = new EventEmitter<void>();
    this.selectDailyCorRef = new EventEmitter<DailyCorRefInfo>();
    this.removeFromDailyCor = new EventEmitter<DailyCorRefInfo>();
  }

  public SetPrms(refelement: any, lnks: Array<LocalDailyCorRefInfo>): void {
    this.m_refelement = refelement;
    this.m_dailycorrefs = lnks;
  }

  public SelectEntry(lnk: LocalDailyCorRefInfo): void {
    if(lnk.canjump)
      this.selectDailyCorRef.emit(lnk.info);
  }
}

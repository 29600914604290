import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { DataService, DownloadInfo } from '../data/data.service';
import { CacheService } from '../data/cache.service';
import { DocviewerComponent } from '../docviewer/docviewer.component';
import { WFDocumentModification, WFUser, IWFObject } from '../model';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { DownloadDocumentComponent } from '../download-document/download-document.component';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})

export class DocumentPreviewComponent {
  public m_busy_str: string;
  private m_doc: WFDocumentAdp;
  public m_title: string;

  @Output() public onClosed: EventEmitter<boolean>;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_data_srv: DataService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_busy_str = '';
    this.m_title = '';
    this.m_doc = null;
    this.onClosed = new EventEmitter<boolean>();
  }

  public SetPrms(doc: WFDocumentAdp, md: WFDocumentModification): void {
    this.m_doc = doc;

    let usrcd = 'SYS';
    let dta: string;
    let usr: WFUser;

    if (md == null) {
      if (this.m_doc.UserID > 0) {
        usr = (this.m_doc.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(this.m_doc.UserID);
        usrcd = usr.FriendlyName;
      }

      dta = WFDocumentAdp.DateTimeToStr(this.m_doc.CreatedAt, this.m_strdata_srv);
      this.m_title = IWFObject.Format(this.m_strdata_srv.getStr('wDocumentPreview_DocPreviewInfo'), this.m_doc.Name, dta, this.m_doc.CreatedAt.toLocaleDateString(), usrcd);
    } else {
      if (md.UserID > 0) {
        usr = (md.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(md.UserID);
        usrcd = usr.FriendlyName;
      }

      dta = WFDocumentAdp.DateTimeToStr(md.CreatedAt, this.m_strdata_srv);
      this.m_title = IWFObject.Format(this.m_strdata_srv.getStr('wDocumentPreview_DocPreviewInfo'), this.m_doc.Name, dta, md.CreatedAt.toLocaleDateString(), usrcd);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public InitDocViewer(docvw: DocviewerComponent): void {
    try {
      docvw.ReadOnly = true;
      docvw.Document = this.m_doc;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public StoreDocument(): void {
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(DownloadDocumentComponent);
      wnd.instance.SetPrms(this.m_doc);
      wnd.instance.onClosed.subscribe(() => {
          wnd.destroy();
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

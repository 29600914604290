import { IWFObject } from './IWFObject';

export enum SENTSTATUS {
    WAITING,
    SENT,
    ERROR
}

export enum EVENTTYPE {
    NONE,
    NEEDAUTH,
    APPROVE,
    REJECT,
    NEEDCOMMENT,
    COMMENTED,
    DOCCHANGED,
    APPROVEDBYALL
}

export class WFMessageRecipient {
    private m_users_id: number;
    private m_email_adr: string;
    private m_isread: boolean;

    public static Create(usrid: number, email: string, isread: boolean): WFMessageRecipient {
        return new WFMessageRecipient({
            UsersID: usrid,
            EMailAdr: email,
            IsRead: isread
        });
    }

    constructor(obj: any) {
        this.m_users_id = obj.UsersID;
        this.m_email_adr = obj.EMailAdr;
        this.m_isread = obj.IsRead;
    }

    public get UsersID(): number {
        return this.m_users_id;
    }

    public get EMailAdr(): string {
        return this.m_email_adr;
    }

    public set IsRead(nval: boolean) {
        this.m_isread = nval;
    }

    public get IsRead(): boolean {
        return this.m_isread;
    }

    public ToObject(): any {
        return {
            UsersID: this.m_users_id,
            EMailAdr: this.m_email_adr,
            IsRead: this.m_isread
        };
    }
}

export class WFMessage {
    private m_id: number;
    private m_user_id: number;
    private m_dst_company_id: number;
    private m_dst_document_id: number;
    private m_dst_documents_authschemas_id: number;
    private m_subject: string;
    private m_content: string;
    private m_issent: SENTSTATUS;
    private m_att_document_id: number;
    private m_createdat: Date;
    private m_updatedat: Date;
    private m_eventtype: EVENTTYPE;
    private m_recipients: Array<WFMessageRecipient>;

    //

    public static Create(uid: number, sbj: string, ctx: string, dstcmpid: number, dstdocid: number, attdocid: number, evt: EVENTTYPE, dstdocschid: number): WFMessage {
        let dnow = new Date();
        return new WFMessage({
            id: 0,
            user_id: uid,
            dst_company_id: dstcmpid,
            dst_document_id: dstdocid,
            dst_documents_authschemas_id: dstdocschid,
            subject: IWFObject.CutString(sbj, 1024),
            content: ctx,
            issent: SENTSTATUS.WAITING,
            att_document_id: attdocid,
            createdat: dnow,
            updatedat: dnow,
            eventtype: evt,
            recipients: []
        });
    }

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_user_id = obj.user_id;
        this.m_dst_company_id = obj.dst_company_id;
        this.m_dst_document_id = obj.dst_document_id;
        this.m_dst_documents_authschemas_id = obj.dst_documents_authschemas_id;
        this.m_subject = obj.subject;
        this.m_content = obj.content;
        this.m_issent = obj.issent;
        this.m_att_document_id = obj.att_document_id;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_eventtype = obj.eventtype;
        this.m_recipients = new Array<WFMessageRecipient>();
        for (let rc of obj.recipients) this.m_recipients.push(new WFMessageRecipient(rc));
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get UserID(): number {
        return this.m_user_id;
    }

    //

    public get DstCompanyID(): number {
        return this.m_dst_company_id;
    }

    //

    public get DstDocumentID(): number {
        return this.m_dst_document_id;
    }

    //

    public get DstDocumentAuthSchemasID(): number {
        return this.m_dst_documents_authschemas_id;
    }

    //

    public get Subject(): string {
        return this.m_subject;
    }

    //

    public get Content(): string {
        return this.m_content;
    }

    //

    public get IsRead(): boolean {
        for (let mrc of this.m_recipients) {
            if (!mrc.IsRead) return false;
        }

        return true;
    }

    //

    public get IsSent(): SENTSTATUS {
        return this.m_issent;
    }

    //

    public get AttDocumentID(): number {
        return this.m_att_document_id;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    //

    public get EventType(): EVENTTYPE {
        return this.m_eventtype;
    }

    //

    public get Recipients(): Array<WFMessageRecipient> {
        return this.m_recipients;
    }

    //

    public ToObject(): any {
        let rcps = new Array<any>();
        for (let rc of this.m_recipients) rcps.push(rc.ToObject());

        return {
            id: this.m_id,
            user_id: this.m_user_id,
            dst_company_id: this.m_dst_company_id,
            dst_document_id: this.m_dst_document_id,
            dst_documents_authschemas_id: this.m_dst_documents_authschemas_id,
            subject: this.m_subject,
            content: this.m_content,
            issent: this.m_issent,
            att_document_id: this.m_att_document_id,
            createdat: IWFObject.DateToString(this.m_createdat),
            updatedat: IWFObject.DateToString(this.m_updatedat),
            eventtype: this.m_eventtype,
            recipients: rcps
        };
    }
}
import { IWFCustomInstruction, WFInstructionType } from './IWFCustomInstruction';

export class WFDialogWndInstruction extends IWFCustomInstruction {
    private m_message: string;
    private m_btn1: string;
    private m_btn2: string;
    private m_btn3: string;
    private m_variable_id: number;

    //

    constructor(obj: any) {
        super(obj.int_id, obj.name, obj.description, WFInstructionType.DIALOGWND);
        this.m_message = obj.message;
        this.m_btn1 = obj.btn1;
        this.m_btn2 = obj.btn2;
        this.m_btn3 = obj.btn3;
        this.m_variable_id = obj.variable_id;
    }

    //

    public get Message(): string {
        return this.m_message;
    }

    //

    public get Button1(): string {
        return this.m_btn1;
    }

    //

    public get Button2(): string {
        return this.m_btn2;
    }

    //

    public get Button3(): string {
        return this.m_btn3;
    }

    //

    public get VariableID(): number {
        return this.m_variable_id;
    }
}

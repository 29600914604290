import { IWFObject } from './IWFObject';

export enum WFFolderIconType {
    ICON_TYPE_A,
    ICON_TYPE_B,
    ICON_TYPE_C,
    ICON_TYPE_D,
    ICON_TYPE_E,
    ICON_TYPE_F,
    ICON_TYPE_G,
    ICON_TYPE_H
}

export class WFDirectory {
    private m_id: number;
    private m_company_id: number;
    private m_directory_id: number;
    private m_name: string;
    private m_description: string;
    private m_icontype: WFFolderIconType;
    private m_iconcolor: string;
    private m_updatedat: Date;
    private m_createdat: Date;

    //

    public static Create(name: string, description: string): WFDirectory {
        let dnow = new Date();
        return new WFDirectory({
            id: 0,
            company_id: 0, 
            directory_id: 0,
            name: name,
            description: description,
            icontype: <number>WFFolderIconType.ICON_TYPE_A,
            iconcolor: { A: 255, R: 0, G: 0, B: 0 },
            updatedat: dnow,
            createdat: dnow
        });
    }

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_company_id = obj.company_id;
        this.m_directory_id = obj.directory_id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_icontype = obj.icontype;
        this.m_iconcolor = IWFObject.ParseColor(obj.iconcolor);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public set CompanyID(nval: number) {
        this.m_company_id = nval;
    }

    public get CompanyID(): number {
        return this.m_company_id;
    }

    public set DirectoryID(nval: number) {
        this.m_directory_id = nval;
    }

    public get DirectoryID(): number {
        return this.m_directory_id;
    }

    public set Name(nval: string) {
        this.m_name = nval.substr(0, 100);
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get IconType(): WFFolderIconType {
        return this.m_icontype;
    }

    public get IconColor(): string {
        return this.m_iconcolor;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public toString(): string {
        return this.m_name;
    }

    public ToObject(): any {
        return {
            id: this.m_id,
            company_id: this.m_company_id,
            directory_id: this.m_directory_id,
            name: this.m_name,
            description: this.m_description,
            icontype: <number>this.m_icontype,
            iconcolor: IWFObject.StringToColor(this.m_iconcolor),
            updatedat: IWFObject.DateToString(this.m_updatedat),
            createdat: IWFObject.DateToString(this.m_createdat)
        };
    }
}
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { APP_VERSION } from '../app.component';
import { CacheService } from '../data/cache.service';

@Component({
  selector: 'app-about-wnd',
  templateUrl: './about-wnd.component.html',
  styleUrls: ['./about-wnd.component.scss']
})

export class AboutWndComponent {
  public m_spuma_ver: string;
  public m_model_ver: string;
  public m_ds_ver: string;

  @Output() clickCancel: EventEmitter<void>;

  constructor(private m_cache_srv: CacheService) { 
    this.clickCancel = new EventEmitter<void>();
    this.m_spuma_ver = APP_VERSION;
    this.m_model_ver = this.m_cache_srv.ModelVersion;
    this.m_ds_ver = this.m_cache_srv.DataServiceVersion;
  }
}

import { Component, OnInit, AfterViewInit, AfterViewChecked, Input, Output, EventEmitter, HostListener, ElementRef, ViewChild, ViewContainerRef, ComponentFactoryResolver, Type, ComponentRef, TemplateRef, EmbeddedViewRef, Renderer2, ContentChild, ChangeDetectionStrategy, NgZone, Compiler, NgModule, ModuleWithComponentFactories, RendererType2 } from '@angular/core';
import { WFDocumentAdp, WFDocumentLineAdp, FetchMessagesCompleted, PageModified, FetchMatchReconSchemaCompleted, SuggestNewDocNameCompleted, FindLineValuesCompleted, SuggestOcrMapCompleted, PropertyValueChange, LinesModified, FireTriggerCompleted, _OnError, LinePropertyValueChange, LinePropertyValidation, TempCtxType, AuthorizationInfo } from '../adapters/WFDocumentAdp';
import { WFDocument, WFDocumentOcrResults, IWFObject, WFClass, WFAuthSchema, WFCompany, WFPermission, WFUser, WFDocStatus, PrmType, WFClassVisibility, WFAuthStatus, WFTmpWriteMode, WFCheckFormat, WFAuthPermission, WFClassAttrib, WFRelation, WFCustomProcess, WFProcessVarState, WFFieldType, WFDictionary, WFDocumentPage, WFOcrTextRegion, WFOcrResultPage, WFPageComment, WFCommentEntry, WFDocumentAuthSchema, WFPrmType, WFDocumentLine, WFDocumentRef, WFOcrDefinition, WFOcrTaskMode, WFOcrField, WFOcrFldType, WFRelationType, WFProcessAction, WFFilterInfo, WFDirectory } from '../model/index';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, FindDocumentPrms, GetResourcePreviewPrms, StringDataTable, ColumnInfo, ColumnAlign, ColumnType, DownloadInfo } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE, INFOBARSTATEMODE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { WFMessageAdp } from '../adapters/WFMessageAdp';
import { WFCommentAdp } from '../adapters/WFCommentAdp';
import { IWFCompanyObjAdp, PropertyValidation } from '../adapters/IWFCompanyObjAdp';
import { Observable } from 'rxjs/Rx';
import { AlertWndComponent, ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { DatePipe, CommonModule } from '@angular/common';
import { WFDocumentValidValues } from '../adapters/WFDocumentValidValues';
import { SbobusinesspartnerComponent } from '../sbobusinesspartner/sbobusinesspartner.component';
import { EventAdp } from '../data/eventadp';
import { APPWNDRESULT, ApproveWndComponent } from '../approve-wnd/approve-wnd.component';
import { ApprovalsComponent } from '../approvals/approvals.component';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { EditauthschemaComponent } from '../editauthschema/editauthschema.component';
import { EditpermissionsComponent } from '../editpermissions/editpermissions.component';
import { ParentpermissionsComponent } from '../parentpermissions/parentpermissions.component';
import { SendEmailComponent } from '../send-email/send-email.component';
import { RequestCommentComponent } from '../request-comment/request-comment.component';
import { ColumnMapDefinitionsComponent } from '../column-map-definitions/column-map-definitions.component';
import { ColumnValueSelectorComponent } from '../column-value-selector/column-value-selector.component';
import { WFClassTrigger } from '../model/WFClassTrigger';
import { ImportFileParamsComponent } from '../import-file-params/import-file-params.component';
import { stringify } from 'querystring';
import { LongTextAdpComponent } from '../long-text-adp/long-text-adp.component';
import { DatepickeradpComponent } from '../datepickeradp/datepickeradp.component';
import { AutoCompleteTextBoxComponent } from '../auto-complete-text-box/auto-complete-text-box.component';
import { WFDirectoryAdp } from '../adapters/WFDirectoryAdp';
import { ServiceState } from '@progress/kendo-angular-common/dist/es2015/resize-sensor/resize.service';

const TAB_INFO = 0;
const TAB_LINES = 1;
const TAB_INBOX = 2;
const TAB_REFS = 3;
const TAB_OCR = 4;
const TAB_COMMENTS = 5;

const MAG_STEP = 0.1;
const MAG_MAX = 10.0;
const MAG_MIN = 0.1;

const DS_REPOSITION = 0;
const DS_RESIZINGTOP = 1;
const DS_RESIZINGRIGHT = 2;
const DS_RESIZINGBOTTOM = 4;
const DS_RESIZINGLEFT = 8;

const BORDERSIZE = 10;
const MINHEIGHT = 35;
const MINWIDTH = 100;

export enum CONTENTTYPE {
  TYPE_UNKNOWN,
  TYPE_PDF,
  TYPE_IMAGE,
  TYPE_TEXTHTML,
  TYPE_EDITABLE,
  TYPE_HTMLTEMPLATE,
  TYPE_HTML
}

export enum COMMENTSMODE {
  MODE_MINIMALIZED,
  MODE_MAXIMALIZED,
  MODE_HIDDEN,
}

export enum ADDMODE {
  ADD_MOVE,
  ADD_COMMENT,
  ADD_OCRREGION
}

export enum ADDCOMMENTMODE {
  NONE,
  WAITING,
  WAITING2,
  ADDING,
  MOVING,
  MOVING2
}

export enum ADDCOMMENTDEST {
  COMMENT,
  OCRREGION
}

export enum MOUSECAPTURESTATE {
  NONE,
  WAITING,
  CAPTURED
}

interface Point {
  x: number;
  y: number;
}

export enum CONVERT {
  NOCONV,
  TOINT
}

export interface PropertySpec {
  iseditable: boolean;
  iserror: boolean;
  ismandatory: boolean;
  conv: CONVERT;
}

export interface UserPropertySpec {
  label: string;
  attrib: WFClassAttrib;
  iseditable: boolean;
  iserror: boolean;
  dateval: Date;
  comboischb: boolean;
}

class ResourceCache {
  public ResID: number;
  public PageNum: number;
  public MWidth: number;
  public Cache: any;

  constructor(rid: number, pnm: number, mwh: number) {
    this.ResID = rid;
    this.PageNum = pnm;
    this.MWidth = mwh;
    this.Cache = null;
  }
}

interface TextRegionSpec {
  region: WFOcrTextRegion;
  visible: boolean;
}

interface CommentRegionSpec {
  region: WFCommentAdp;
  isexpanded: boolean;
  infoowner: string;
  background: string;
  allowmove: boolean;
}

interface CommentRowSpec {
  region: WFCommentAdp;
  infoowner: string;
}

interface CommentEntrySpec {
  entry: WFCommentEntry;
  infoowner: string;
  infodate: string;
  ownerinitials: string;
}

interface ColumnSpec {
  label: string;
  width: number;
  attrib: WFClassAttrib;
  colid: number;
  issum: boolean;
  sumstr: string;
  sumbck: string;
  comboischb: boolean;
  trigger: WFClassTrigger;
  hint: string;
}

interface LinePropSpec {
  iseditable: boolean;
  iserror: boolean;
  dateval: Date;
  backcolor: string;
}

interface LineSpec {
  isselected: boolean;
  line: WFDocumentLineAdp;
  isexpanded: boolean;
  lstatus: WFPrmType;
  colinfo: Array<LinePropSpec>;
  visible: boolean;
  addinfo: string;
  afmap: Map<string, ViewContainerRef>;
  expmap: Array<Array<[ColumnInfo, string]>>;
  backcolor: string;
}

interface MessageSpec {
  isread: boolean;
  isreadvis: boolean;
  msg: WFMessageAdp;
  subject: string;
  infodate: string;
  busytext: string;
}

interface ExRefSpec {
  subject: string;
  ref: WFDocumentRef;
  header: Array<[string, string]>;
  tables: Array<StringDataTable>;
}

interface DefinitionSpec {
  code: string;
  lang: string;
  desc: string;
  definition: WFOcrDefinition;
  ocrresults: WFDocumentOcrResults;
}

interface OcrSchemaLineSpec {
  isselected: boolean;
  cols: Array<string>;
}

interface TriggerSpec {
  label: string;
  hint: string;
  trigger: WFClassTrigger;
}

interface DirectorySpec {
  label: string;
  hint: string;
  company_id: number;
  directory_id: number;
}

//
//def component
export type CommentModeChanged = (sender: DocviewerComponent, oldstr: ADDMODE) => void;
export type ContentTypeChanged = (sender: DocviewerComponent, ctp: CONTENTTYPE) => void;
export type PageChanged = (sender: DocviewerComponent, cpg: number) => void;
export type ClassChanged = (sender: DocviewerComponent, class_id: number) => void;
export type SelectDirectory= (sender: DocviewerComponent, company_id: number, directory_id: number) => void;

//type SelectedValueChange = (nval: string) => void;

@Component({
  selector: 'app-docviewer',
  templateUrl: './docviewer.component.html',
  styleUrls: ['./docviewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DocviewerComponent implements AfterViewInit, AfterViewChecked {
  private static m_otherimgsrc = 'other_256.png';

  private static m_otherextimgsrc = [
    { key: ['AVI', 'MOV', 'MP3', 'MKV', 'FLC', 'SWF'], value: 'other_img_256.png' },
    { key: ['CFG', 'INI', 'INF', 'C', 'H'], value: 'other_cfg_256.png' },
    { key: ['EXE', 'COM', 'BAT', 'ZIP', 'RAR'], value: 'other_exec_256.png' },
    { key: ['DOC', 'DOCX'], value: 'winword_128.png' },
    { key: ['XLS', 'XLSX'], value: 'winexcel_128.png' }
  ];

  private static VALIDSUM = 'green';
  private static INVALIDSUM = 'red';

  private m_addcomment_start: Point;
  private m_addcomment_mode: ADDCOMMENTMODE;
  private m_addcomment_dest: ADDCOMMENTDEST;

  private m_scale: number;
  private m_cur_page: number;
  private m_cur_image_width: number;
  private m_cur_image_height: number;
  private m_cur_type: CONTENTTYPE;

  public m_document: WFDocumentAdp;

  private m_defcommentmode: COMMENTSMODE;

  public m_top_grd: boolean;
  public m_pagesc_info: string;
  public m_pagekeywords: string;
  public m_pagekeywords_err: boolean;

  public m_infobarstate: boolean;
  public m_infolbl: string;
  public m_infolbl2: string;
  public m_infolbl3: string;

  public m_authsts_brd: boolean;
  public m_authsts_pth_img: string;
  public m_authsts_pth_txt: string;
  public m_authsts_pth_col: string;

  public m_pagezoom: Array<ComboIValueDesc>;
  //public m_actual_pagezoom: ComboIValueDesc;
  public m_actual_pagezoom: number;

  public m_pages: Array<ComboIValueDesc>;
  public m_actual_page: ComboIValueDesc;

  public m_imgcontainer: boolean;
  public m_edittextctx: boolean;
  public m_edittextctx_html: string;
  public m_edittextctx_enable: boolean;
  public m_otherctx: boolean;
  public m_htmltplctx: boolean;
  public m_htmlctx: boolean;
  public m_previewhtml: string;
  private m_htmltplcmpref: ComponentRef<any>;

  public m_othericon: string;
  public m_otherinf: string;
  public m_othersavebtn: boolean;
  public m_otheruploadbtn: boolean;
  public m_otherlink: string;

  public m_textregions: boolean;
  public m_textregions_items: Array<TextRegionSpec>;

  public m_comments: boolean;
  public m_comments_items: Array<CommentRegionSpec>;
  public m_cmmsctx_items: Array<CommentRowSpec>;
  public m_entries_items: Array<CommentEntrySpec>;
  public m_newentry: string;
  public m_newentry_err: boolean;
  public m_cmmdelbtn: boolean;

  private m_curcmmadp: WFCommentAdp;

  public m_inf_props: Array<UserPropertySpec>;
  public m_inf_triggers: Array<TriggerSpec>;
  public m_inf_line_triggers: Array<TriggerSpec>;

  public m_inboxctx: Array<MessageSpec>;
  public m_outboxctx: Array<MessageSpec>;
  public m_msgctx: string;

  public m_refsctx: Array<ExRefSpec>;
  public m_selref: ExRefSpec;

  public m_refheaderctx: Array<any>;
  public m_reflinesctx: Array<any>;

  public m_shmsctx: Array<DefinitionSpec>;
  public m_selocr: DefinitionSpec;

  public m_shmheaderctx: Array<[string, string]>;
  public m_shmlinesctx_cols: Array<ColumnInfo>;
  public m_shmlinesctx: Array<OcrSchemaLineSpec>;

  //
  @Output() afterInit: EventEmitter<DocviewerComponent>;
  @ViewChild('m_imagectx', {static: false}) private m_imagectx: ElementRef;
  @ViewChild('m_image', {static: false}) private m_image: ElementRef;
  @ViewChild('m_newcmmregion', {static: false}) private m_newcmmregion: ElementRef;

  private m_cli_width: number;
  private m_cli_height: number;

  public m_selected_tab: number;

  public m_inf_shm_btn: boolean;
  public m_inf_prms_btn: boolean;
  public m_inf_name_btn: boolean;

  public m_image_width: number;
  public m_image_height: number;

  private m_readonly: boolean;
  public m_inf_cdate: string;
  public m_tmpwritemode_vis: boolean;

  public m_rotleft: boolean;
  public m_rotright: boolean;

  private m_onfetchmessages: FetchMessagesCompleted;
  private m_onpagemodified: PageModified;
  private m_onfetchmatchreconschemas: FetchMatchReconSchemaCompleted;
  private m_onsuggestdocname: SuggestNewDocNameCompleted;
  private m_onfindlinevalues: FindLineValuesCompleted;
  private m_onsuggestocrmap: SuggestOcrMapCompleted;

  private m_propvalchgevh: PropertyValueChange;
  private m_propvalidateevh: PropertyValidation;

  private m_linemodfiedevh: LinesModified;
  private m_triggercplevh: FireTriggerCompleted;

  private m_selclsevh: (nval: string) => void;
  public m_busy_text: string;
  //

  @ViewChild('m_inf_cls_cmb', {static: false}) private m_inf_cls_cmb: ComboboxadpComponent;
  @ViewChild('m_inf_shm_cmb', {static: false}) private m_inf_shm_cmb: ComboboxadpComponent;
  @ViewChild('m_inf_company_cmb', {static: false}) private m_inf_company_cmb: ComboboxadpComponent;
  @ViewChild('m_inf_owner_cmb', {static: false}) private m_inf_owner_cmb: ComboboxadpComponent;
  @ViewChild('m_tmpwritemode_cmb', {static: false}) private m_tmpwritemode_cmb: ComboboxadpComponent;

  public m_inf_shm: PropertySpec;
  public m_inf_company: PropertySpec;
  public m_inf_owner: PropertySpec;
  public m_tmpwritemode: PropertySpec;

  public m_inf_name: PropertySpec;
  public m_inf_desc: PropertySpec;
  public m_inf_docnum: PropertySpec;

  public m_inf_docdate: PropertySpec;
  public m_inf_enterdate: PropertySpec;

  public m_datep: DatePipe;
  private m_curdocintmap: Map<number, WFDocumentValidValues>;
  public m_dateformat: string;

  private m_onerrorevh: EventAdp<_OnError>;
  private m_prevrequest: boolean;

  public m_imgcontainer_img: string;

  private m_rescache: Array<ResourceCache>;
  private m_maxcachelen: number;
  public m_curr_rotate: number;

  public m_selocrrs: WFDocumentOcrResults;
  public m_selrow: MessageSpec;

  public m_textregionpage: WFOcrResultPage;
  private m_findtextregion: number;

  private m_commmove_comm: CommentRegionSpec;
  private m_commmove_state: MOUSECAPTURESTATE;
  private m_commmove_startpos: Point;
  private m_commmove_target: HTMLElement;
  private m_commmove_dragstatefl: number;
  private m_commmove_offset: Point;
  //SK
  public m_showauthicon: boolean;
  public m_selalllines: boolean;
  public m_selallOCRlines: boolean;

  public m_newcommentregion: boolean;
  public m_newcommentregion_pos: Point;
  public m_newcommentregion_size: Point;
  public m_newcommentregion_spos: Point;
  public m_imagectx_spos: Point;

  public m_columns: Array<ColumnSpec>;
  public m_tabbuttonlines: boolean;
  public m_checkkwords: string;
  public m_checkrskwords: string;

  public m_hideuncheck: boolean;
  public m_hidevalidval: boolean;

  public m_importbtn: boolean;
  public m_linesaddbtn: boolean;
  public m_linesdelbtn: boolean;
  public m_showhideocrlines: boolean;

  public m_lines: Array<LineSpec>;

  private m_linevalchgevh: LinePropertyValueChange;
  private m_linevalidateevh: LinePropertyValidation;

  //
  public OnCommentModeChanged: CommentModeChanged;

  //inbox
  public m_replybtn: boolean;
  public m_tabmsgactive: number;

  public m_sendtocentral: boolean;
  public m_syncrsh: boolean;
  public m_sendtoocr: boolean;

  public m_schemaname: string;

  private m_commentmousemovebnd: any;
  private m_newcommentmousemovebnd: any;

  public m_quill_modules: any;

  @ViewChild('m_filesel', {static: false}) private m_filesel: ElementRef;
  @ViewChild('m_htmltplcontainer', { read: ViewContainerRef, static: false }) private m_htmltplcontainer: ViewContainerRef;

  private m_actstyles: string;
  public m_afmap: Map<string, ViewContainerRef>;

  private m_afvchecked: [string, LineSpec];

  public m_dirpath: Array<DirectorySpec>;

  //

  public set AddCommentMode(value: ADDMODE) {
    let oldst: ADDMODE;
    if (this.m_addcomment_mode === ADDCOMMENTMODE.WAITING ||
      this.m_addcomment_mode === ADDCOMMENTMODE.WAITING2 ||
      this.m_addcomment_mode === ADDCOMMENTMODE.ADDING) {
      oldst = (this.m_addcomment_dest === ADDCOMMENTDEST.COMMENT) ? ADDMODE.ADD_COMMENT : ADDMODE.ADD_OCRREGION;
    } else {
      oldst = ADDMODE.ADD_MOVE;
    }

    if (oldst !== value) {
      switch (value) {
        case ADDMODE.ADD_COMMENT:
          this.m_addcomment_mode = ADDCOMMENTMODE.WAITING;
          this.m_addcomment_dest = ADDCOMMENTDEST.COMMENT;
          break;

        case ADDMODE.ADD_OCRREGION:
          this.m_addcomment_mode = ADDCOMMENTMODE.WAITING;
          this.m_addcomment_dest = ADDCOMMENTDEST.OCRREGION;
          break;

        default:
          this.m_addcomment_mode = ADDCOMMENTMODE.NONE;
          this.m_addcomment_dest = ADDCOMMENTDEST.COMMENT;
          break;
      }

      if (this.OnCommentModeChanged != null) this.OnCommentModeChanged(this, oldst);
    }
  }

  public get AddCommentMode(): ADDMODE {
    if (this.m_addcomment_mode === ADDCOMMENTMODE.WAITING ||
      this.m_addcomment_mode === ADDCOMMENTMODE.WAITING2 ||
      this.m_addcomment_mode === ADDCOMMENTMODE.ADDING) {
      return (this.m_addcomment_dest === ADDCOMMENTDEST.COMMENT) ? ADDMODE.ADD_COMMENT : ADDMODE.ADD_OCRREGION;
    }

    return ADDMODE.ADD_MOVE;
  }

  //

  public set DefCommentVisibilityMode(nval: COMMENTSMODE) {
    if (this.m_defcommentmode !== nval) {
      this.m_defcommentmode = nval;
      this.SetCommentVisibilityMode();
    }
  }

  public get DefCommentVisibilityMode(): COMMENTSMODE {
    return this.m_defcommentmode;
  }

  //

  public set DefTextRegionsVisibility(nval: boolean) {
    if (nval) {
      this.m_textregions = true;
      for (let ii = 0; ii < this.m_textregions_items.length; ii++) {
        let tadp: TextRegionSpec = this.m_textregions_items[ii];
        tadp.visible = true;
      }
    } else {
      this.ClearOCRDestination();
      this.m_textregions = false;
    }
  }

  public get DefTextRegionsVisibility(): boolean {
    return this.m_textregions;
  }

  //

  public set Document(ndoc: WFDocumentAdp) {
    let ii: number;

    if (this.m_document != null) {
      this.m_document.OnError.unsubscribe2(this.m_onerrorevh);

      this.m_document.OnPageModified = null;
      this.m_document.OnPropertyValueChange = null;
      this.m_document.OnPropertyValidation = null;
      this.m_document.OnLinesModified = null;
      this.m_document.OnFireTriggerCompleted = null;
      this.m_document.OnFetchMatchReconSchemaCompleted = null;
      this.m_document.OnSuggestNewDocName = null;
      this.m_document.OnFetchMessagesCompleted = null;
      this.m_document.OnFindLineValuesCompleted = null;
      this.m_document.OnSuggestOcrMapCompleted = null;

      let lcc = this.m_document.GetLinesCount();
      for (ii = 0; ii < lcc; ii++) {
        let ln: WFDocumentLineAdp = this.m_document.GetLine(ii);
        ln.OnPropertyValueChange = null;
        ln.OnPropertyValidation = null;
      }
    }

    this.SetEmptyDocument();
    this.m_document = ndoc;
    this.m_tmpwritemode_vis = false;
    this.m_showauthicon = false;
    this.m_selalllines = false;
    this.m_selallOCRlines = false;
    if (this.m_document == null) {
      this.m_inf_name.iseditable = this.m_inf_desc.iseditable = this.m_inf_docnum.iseditable = false;
      this.m_inf_name_btn = false;
      this.m_inf_docdate.iseditable = this.m_inf_enterdate.iseditable = false;
      this.m_inf_cls_cmb.IsEnabled = false;
      this.m_inf_company.iseditable = false;
      this.m_inf_shm_btn = false;
      this.m_inf_prms_btn = false;
      this.m_linesaddbtn = this.m_linesdelbtn = this.m_importbtn = this.m_showhideocrlines = false;
    } else {
      this.m_document.OnPageModified = this.m_onpagemodified;
      this.m_document.OnPropertyValueChange = this.m_propvalchgevh;
      this.m_document.OnPropertyValidation = this.m_propvalidateevh;
      this.m_document.OnLinesModified = this.m_linemodfiedevh;
      this.m_document.OnFireTriggerCompleted = this.m_triggercplevh;

      let pages_c = this.m_document.GetPagesCount();

      //this.m_selected_tab = TAB_INFO;

      let pinf = IWFObject.Format('{0} [{1}]',
        this.m_document.Name,
        //this.m_document.DocNum
        this.m_document.ID
        );

      if (this.m_document.ClassID > 0) {
        let cls: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
        pinf = IWFObject.Format('{1} {0}', pinf, cls.Name);
      }


      this.m_infolbl = pinf.toUpperCase();
      this.m_infolbl3 = WFDocumentAdp.NullableToDate(this.m_document.DocDate).toLocaleDateString();

      let apprights = this.m_document.ApprovalForUserID;
      if (apprights > 0 && apprights !== this.m_cache_srv.User.ID) {
        let appusr: WFUser = this.m_cache_srv.Users.get(apprights);
        this.m_infolbl2 = IWFObject.Format(this.m_strdata_srv.getStr('strApprovalFor'), appusr.Name).toUpperCase();
      }

      //
      //szczegoly
      //

      this.SetHeaderFieldsEditable();

      let editable = (this.m_document.CanEdit(null) && !this.m_readonly);
      this.m_inf_company.iseditable = ((editable) && (this.m_document.DocStatus !== WFDocStatus.INSYSTEM));
      this.m_inf_shm_btn = true;

      this.m_inf_prms_btn = true;
      this.m_linesaddbtn = this.m_linesdelbtn = this.m_importbtn = this.m_showhideocrlines = (this.m_document.CanEditLines(null, null) === WFPrmType.CHANGE && !this.m_readonly);

      this.m_inf_name.iserror = false;

      //opis
      //m_inf_desc.Document = m_document;
      this.m_inf_desc.iserror = false;

      //numer dokumentu
      this.m_inf_docnum.iserror = false;

      //data
      this.m_inf_docdate.iserror = false;

      //data wpływu
      this.m_inf_enterdate.iserror = false;

      this.m_inf_cdate = WFDocumentAdp.DateTimeToStr(this.m_document.CreatedAt, this.m_strdata_srv);

      //firma
      this.m_inf_company_cmb.ClearValidValues();
      if (editable) {
        if (this.m_cache_srv.User.CompanyPermissionType !== PrmType.TYPE_DENYFROMALL) {
          let scmps= Array.from(this.m_cache_srv.Companies.values()).sort((a,b)=> a.Company.localeCompare(b.Company));
          for (let cm of scmps) {
            if (this.m_cache_srv.User.CompanyPermissionType === PrmType.TYPE_ALLOWFROM && this.m_document.CompanyID !== cm.ID) {
              if (this.m_cache_srv.User.CompanyPermissions.indexOf(cm.ID) < 0) continue;
            }

            this.m_inf_company_cmb.AddValidValue(cm.ID.toString(), cm.Company);
          }
        }
      } else {
        let scmps= Array.from(this.m_cache_srv.Companies.values()).sort((a,b)=> a.Company.localeCompare(b.Company));
        for (let cm of scmps) {
          this.m_inf_company_cmb.AddValidValue(cm.ID.toString(), cm.Company);
        }
      }

      this.m_inf_company.iserror = false;

      //user
      this.m_inf_owner_cmb.ClearValidValues();
      this.m_inf_owner_cmb.AddValidValue(this.m_cache_srv.User.ID.toString(), this.m_cache_srv.User.FriendlyName);
      let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
      for (let us of susr) {
        this.m_inf_owner_cmb.AddValidValue(us.ID.toString(), us.FriendlyName);
      }

      this.m_inf_owner.iserror = false;

      //klasy
      this.RefreshClasses(editable, this.m_document.CompanyID);

      let len = this.m_document.GetRefsCount();
      for (ii = 0; ii < len; ii++) {
        let docref = this.m_document.GetRef(ii);
        //if (docref.ObjectType.length > 0) {
          //if (docref.ObjectType[0] !== '-') {
            /*ExObjectRefAdp adp = new ExObjectRefAdp(m_document.GetRef(ii), m_document.Session);
            adp.MouseLeftButtonDown += m_selexobjref;
            adp.OnFetchPreviewCompleted += m_onfetchrefprv;

            Border brd = new Border();
            brd.Margin = new Thickness(0, 0, 0, 1);

            brd.Background = (SolidColorBrush)Application.Current.Resources["BCK_NORMAL"];
            brd.Child = adp;
            brd.HorizontalAlignment = System.Windows.HorizontalAlignment.Stretch;*/

            this.m_refsctx.push({
              subject: IWFObject.Format(this.m_strdata_srv.getStr('wExObjectRefAdp_Header'), docref.ObjectID, docref.ObjectType),
              ref: docref,
              header: null,
              tables: new Array<StringDataTable>()
            });
          //}
        //}
      }

      //schemat autoryzacji
      if (this.m_document.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
        this.m_showauthicon = true;
      }

      if (this.m_document.AuthSchemaID !== WFDocument.SCHM_NOAUTH &&
        this.m_document.DocStatus === WFDocStatus.INSYSTEM) {
        this.m_authsts_brd = true;

        switch (this.m_document.AuthStatus) {
          case WFAuthStatus.APPROVED:
            this.m_authsts_pth_img = 'cmn_checkrect.svg';
            this.m_authsts_pth_txt = this.m_strdata_srv.getStr('enumWFAuthStatusAPPROVED').toUpperCase();
            this.m_authsts_pth_col = 'CL_STATAUTH'; //m_approved_fgr;
            //m_authsts_pth.IconWidth = 12;
            //m_authsts_pth.IconBackgroundPadding = new Thickness(3);
            break;
          case WFAuthStatus.DISAPPROVED:
            this.m_authsts_pth_img = 'cmn_uncheck.svg';
            this.m_authsts_pth_txt = this.m_strdata_srv.getStr('enumWFAuthStatusDISAPPROVED').toUpperCase();
            this.m_authsts_pth_col = 'CL_STATREJECT';
            //m_authsts_pth.IconWidth = 12;
            //m_authsts_pth.IconBackgroundPadding = new Thickness(3);
            break;
          case WFAuthStatus.WAITING:
            this.m_authsts_pth_img = 'cmn_questionrect.svg';
            this.m_authsts_pth_txt = this.m_strdata_srv.getStr('enumWFAuthStatusWAITING').toUpperCase();
            this.m_authsts_pth_col = 'CL_STATWAIT';
            //m_authsts_pth.IconWidth = 8;
            //m_authsts_pth.IconBackgroundPadding = new Thickness(6, 3, 6, 3);
            break;
        }
      }

      this.m_sendtoocr = false;

      if (this.m_cache_srv.Config.OcrTaskMode === WFOcrTaskMode.EX_DISK) {
        this.m_syncrsh = true;
        this.m_sendtocentral = true;
      } else {
        this.m_syncrsh = false;
        this.m_sendtocentral = false;
      }

      let occ = this.m_document.GetOcrResultsCount();
      if (occ > 0) {
        if (IWFObject.IsNullOrEmpty(this.m_document.DefinitionCode)) {
          this.m_selocrrs = this.m_document.GetOcrResult(0);
        } else {
          for (ii = 0; ii < occ; ii++) {
            let ors: WFDocumentOcrResults = this.m_document.GetOcrResult(ii);
            if (ors.SchemaCode === this.m_document.DefinitionCode) {
              this.m_selocrrs = ors;
              break;
            }
          }
        }
      }

      if (this.m_document.CanChangeTmpWriteMode && !this.m_readonly) {
        this.m_tmpwritemode_vis = true;

        if (this.m_tmpwritemode_cmb.ValidValuesCount === 0) {
          this.m_tmpwritemode_cmb.AddValidValue((WFTmpWriteMode.DENYALL).toString(), this.m_strdata_srv.getStr('strTmpWriteMode_DenyAll'));
          this.m_tmpwritemode_cmb.AddValidValue((WFTmpWriteMode.ALLOWALL).toString(), this.m_strdata_srv.getStr('strTmpWriteMode_AllowAll'));
          this.m_tmpwritemode_cmb.AddValidValue((WFTmpWriteMode.ALLOWONLYCONTENT).toString(), this.m_strdata_srv.getStr('strTmpWriteMode_AllowEditContent'));
        }

        this.m_tmpwritemode.iserror = false;
      }

      /*if (editable) {
        m_inf_desc.GotFocus += m_ocrdstgfocus;
        m_inf_docdate.GotFocus += m_ocrdstgfocus;
        m_inf_docnum.GotFocus += m_ocrdstgfocus;
        m_inf_enterdate.GotFocus += m_ocrdstgfocus;
        m_inf_name.GotFocus += m_ocrdstgfocus;
      }*/

      this.m_document.OnError.subscribe2(this.m_onerrorevh);

      this.RefreshPageInfo();
      this.FetchMessages(true);

      //DM 15062018
      if (this.m_cache_srv.Config.OcrTaskMode !== WFOcrTaskMode.EX_DISK &&
        this.m_cache_srv.Definitions.size > 0) {
        let cc = this.m_document.GetOcrResultsCount();
        if (cc > 0) {
          for (ii = 0; ii < cc; ii++) {
            let dor: WFDocumentOcrResults = this.m_document.GetOcrResult(ii);
            if (this.m_cache_srv.Definitions.has(dor.SchemaCode)) {
              let df: WFOcrDefinition = this.m_cache_srv.Definitions.get(dor.SchemaCode);

              this.m_shmsctx.push({
                code: df.Code,
                desc: df.Description,
                lang: df.Language,
                definition: df,
                ocrresults: dor
              });
            }
          }

          if (this.m_shmsctx.length > 0) {
            //schowsel = (m_shmsctx.Children.Count > 1);
            this.SelectReconSchema(this.m_shmsctx[0]);
          }
        }
      }

      //katalogi
      switch(this.m_document.DocStatus)
      {
        case WFDocStatus.BEFOREOCR:
          this.m_dirpath.push({
            label: this.m_strdata_srv.getStr('strDocEntered'),
            hint: '',
            company_id: -1,
            directory_id: 0
          });
          break;
        
        case WFDocStatus.INOCR:
          this.m_dirpath.push({
            label: this.m_strdata_srv.getStr('strDocOcr'),
            hint: '',
            company_id: -2,
            directory_id: 0
          });
          break;
        
        case WFDocStatus.AFTEROCR:
          this.m_dirpath.push({
            label: this.m_strdata_srv.getStr('strDocCheck'),
            hint: '',
            company_id: -3,
            directory_id: 0
          });
          break;

        case WFDocStatus.INSYSTEM:          
          if(this.m_document.CompanyID > 0)
          { 
              let cmp= this.m_cache_srv.Companies.get(this.m_document.CompanyID);

              this.m_dirpath.push({
                label: cmp.Company,
                hint: '',
                company_id: this.m_document.CompanyID,
                directory_id: 0
              });

              if(this.m_document.DirectoryID > 0)
              {
                let dirpath= new Array<WFDirectoryAdp>();

                let dirid= this.m_document.DirectoryID;
                while(dirid > 0) {
                  let dir= this.m_cache_srv.Directories.get(dirid);
                  dirpath.push(dir);
                  dirid= dir.DirectoryID;
                }

                for(ii= dirpath.length - 1; ii > -1; ii--) {
                  let dir= dirpath[ii];

                  this.m_dirpath.push({
                    label: dir.DirName,
                    hint: '',
                    company_id: this.m_document.CompanyID,
                    directory_id: dir.ID
                  });
                }

                this.m_dirpath= this.m_dirpath.reverse();
              }
          }

          break;
        case WFDocStatus.TEMPLATE:
          this.m_dirpath.push({
            label: this.m_strdata_srv.getStr('strTemplates'),
            hint: '',
            company_id: -4,
            directory_id: 0
          });
          break;
      }

      //DM 06052022 Czy aktualnym uzytkownikiem do autoryzacji nie jest wlasciciej, jezeli tak to autoryzacja
      if (this.m_document.AuthSchemaID !== WFDocument.SCHM_NOAUTH && 
          this.m_document.AuthStatus== WFAuthStatus.WAITING &&
          this.m_document.UserID== this.m_cache_srv.User.ID &&
          this.m_document.CanApprove &&
          this.m_document.AuthPathsCount > 0)
      {        
        let lshm:WFDocumentAuthSchema= this.m_document.GetAuthPath(this.m_document.AuthPathsCount - 1);
        if(lshm.GetApprovedCount(WFAuthStatus.APPROVED) === 0)
          this.ApproveDoc();
      }
    }
  }

  public get Document(): WFDocumentAdp {
    return this.m_document;
  }

  //

  public set Scale(value: number) {
    let ii: number;

    if ((value > MAG_MIN) &&
      (value < MAG_MAX) &&
      (value !== this.m_scale) &&
      (this.m_cur_page < this.m_document.GetPagesCount()) &&
      (this.m_busy_text === '')) {

      this.m_scale = value;
      let iscale: number = Math.floor(this.m_scale * 100.0);
      this.SetZoomInfo(iscale);

      let dwh = Math.floor(this.m_cur_image_width * this.m_scale);
      if (dwh > this.m_image.nativeElement.naturalWidth) {
        let pg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);
        if ((this.m_cur_type === CONTENTTYPE.TYPE_PDF) || (this.m_cur_type === CONTENTTYPE.TYPE_UNKNOWN) || (this.m_scale < 1.0)) {
          this.GetResourcePreview(pg.ResourcesID, pg.ResourcesPageNum, dwh, Math.floor(this.m_cur_image_height * this.m_scale));
          return;
        }
      }

      this.m_image_width = Math.floor(this.m_cur_image_width * this.m_scale);
      this.m_image_height = Math.floor(this.m_cur_image_height * this.m_scale);
    }
  }

  public get Scale(): number {
    return this.m_scale;
  }

  //

  public get CurPage(): number {
    return this.m_cur_page;
  }

  public set CurPage(value: number) {
    if ((value >= 0) && (value < this.m_document.GetPagesCount()) && (value !== this.m_cur_page)) {
      this.m_cur_page = value;
      this.SetCurrentPage();
    }
  }

  //

  public SetHeader(prop: string, val: object, ps: PropertySpec): void {
    try {
      if (this.m_document != null) {
        if(prop== 'CompanyID') {          
          let cmpid = IWFObject.ParseInt(val.toString());
          if(cmpid != this.m_document.CompanyID) {
            this.OnChangeCompany(cmpid);
          }
        }

        switch (ps.conv) {
          case CONVERT.TOINT:
            let nval = IWFObject.ParseInt(val.toString());
            if (this.m_document[prop] === nval) return;
            this.m_document[prop] = nval;
            break;
          default:
            if (this.m_document[prop] === val) return;
            this.m_document[prop] = val;
        }
      }
      ps.iserror = false;
    } catch (ex) {
      ps.iserror = true;
    }
  }

  public SetAttrib(prop: WFClassAttrib, val: object, ps: UserPropertySpec): void {
    try {
      if (this.m_document != null) {
        if (val instanceof Date) {
          let dta = <Date>val;
          this.m_document.set(prop.Name, this.m_datep.transform(dta, this.m_dateformat));
          ps.dateval = dta;
        } else {
          if (typeof val === 'boolean') {
            this.m_document.set(prop.Name, (<boolean>val) ? 'Y' : 'N');
          } else {
            this.m_document.set(prop.Name, (val == null) ? '' : val.toString());
          }
        }
      }
    } catch (ex) {
      let dbg = '';
    }
  }

  public ParseDate(ps: UserPropertySpec): Date {
    let ret: Date = null;
    if (ps.dateval == null) {
      try {
        let str: string = this.m_document.get(ps.attrib.Name);
        /*if (str != null && str.length > 9) {
          let day = IWFObject.ParseInt(str.substr(0, 2));
          let month = IWFObject.ParseInt(str.substr(3, 2));
          let year = IWFObject.ParseInt(str.substr(6, 4));
          ret = new Date(year, month - 1, day);
        }*/
        ret = IWFObject.ParseShortDate(str);
      } catch (ex) {
        ps.iserror = true;
      }
      ps.dateval = ret;
    } else {
      ret = ps.dateval;
    }
    return ret;
  }

  public ParseBoolean(str: string): boolean {
    if (str != null) return (str.toUpperCase() === 'Y');
    return false;
  }

  //

  public OnContentTypeChanged: ContentTypeChanged;
  public OnPageChanged: PageChanged;
  public OnClassChange: ClassChanged;
  public OnSelectDirectory: SelectDirectory;

  private SetContentType(nval: CONTENTTYPE): void {
    if (this.m_cur_type !== nval) {
      let oldtp: CONTENTTYPE = this.m_cur_type;
      this.m_cur_type = nval;
      if (this.OnContentTypeChanged != null) this.OnContentTypeChanged(this, oldtp);
    }
  }

  public get ContentType(): CONTENTTYPE {
    return this.m_cur_type;
  }

  public set TopBarVisibility(nval: boolean) {
    this.m_top_grd = nval;
  }

  public get TopBarVisibility() {
    return this.m_top_grd;
  }

  public set ReadOnly(nval: boolean) {
    this.m_readonly = nval;
  }

  public get ReadOnly(): boolean {
    return this.m_readonly;
  }


  //

  constructor(protected m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_zone: NgZone,
    private m_render: Renderer2,
    private m_wnd_srv: ChildWindowContainerService,
    private m_compiler: Compiler) {

    this.m_document = null;
    this.m_addcomment_mode = ADDCOMMENTMODE.NONE;
    this.m_addcomment_dest = ADDCOMMENTDEST.COMMENT;
    this.m_addcomment_start = null;
    this.afterInit = new EventEmitter<DocviewerComponent>();
    this.OnCommentModeChanged = null;
    this.OnContentTypeChanged = null;
    this.OnPageChanged = null;
    this.OnClassChange = null;
    this.OnSelectDirectory = null;
    this.m_defcommentmode = COMMENTSMODE.MODE_MINIMALIZED;

    this.m_top_grd = true;
    this.m_pagesc_info = '/0';
    this.m_pagekeywords = '';
    this.m_infobarstate = false;
    this.m_infolbl = '';
    this.m_infolbl2 = '';
    this.m_infolbl3 = '';

    this.m_authsts_brd = true;
    this.m_authsts_pth_img = 'cmn_check.svg';
    this.m_authsts_pth_txt = '';
    this.m_authsts_pth_col = 'CL_STATWAIT';

    this.m_pagezoom = new Array<ComboIValueDesc>();
    //this.m_actual_pagezoom = { val: 0, desc: '' };
    this.m_actual_pagezoom = 0;

    this.m_pages = new Array<ComboIValueDesc>();
    this.m_actual_page = { val: 0, desc: '' };

    this.m_imgcontainer = true;
    this.m_edittextctx = false;
    this.m_edittextctx_html = '';
    this.m_edittextctx_enable = false;
    this.m_otherctx = false;
    this.m_htmltplctx = false;
    this.m_htmlctx= false;
    this.m_previewhtml= '';
    this.m_htmltplcmpref = null;
    this.m_othericon = DocviewerComponent.m_otherimgsrc;
    this.m_otherinf = 'Description';
    this.m_othersavebtn = true;
    this.m_otheruploadbtn = true;
    this.m_otherlink = '';

    this.m_comments = false;
    this.m_comments_items = new Array<CommentRegionSpec>();
    this.m_cmmsctx_items = new Array<CommentRowSpec>();
    this.m_entries_items = new Array<CommentEntrySpec>();
    this.m_newentry = '';
    this.m_newentry_err = false;
    this.m_cmmdelbtn = false;

    this.m_textregions = false;
    this.m_textregions_items = new Array<any>();

    this.m_inboxctx = new Array<MessageSpec>();
    this.m_outboxctx = new Array<MessageSpec>();
    this.m_msgctx = '';
    this.m_refsctx = new Array<any>();
    this.m_refheaderctx = new Array<any>();
    this.m_reflinesctx = new Array<any>();

    this.m_shmsctx = new Array<DefinitionSpec>();
    this.m_shmheaderctx = new Array<[string, string]>();
    this.m_shmlinesctx_cols = new Array<ColumnInfo>();
    this.m_shmlinesctx = new Array<OcrSchemaLineSpec>();

    this.m_cli_width = 0;
    this.m_cli_height = 0;

    this.m_inf_shm_btn = true;
    this.m_inf_prms_btn = true;
    this.m_inf_name_btn = true;

    this.m_image_width = 256;
    this.m_image_height = 256;

    this.m_readonly = false;
    this.m_inf_cdate = '';
    this.m_tmpwritemode_vis = false;

    this.m_rotleft = true;
    this.m_rotright = true;
    this.m_busy_text = '';

    this.m_inf_props = new Array<UserPropertySpec>();
    this.m_inf_triggers = new Array<TriggerSpec>();
    this.m_inf_line_triggers = new Array<TriggerSpec>();

    this.m_datep = new DatePipe('en-US');
    this.m_dateformat = 'dd-MM-yyyy';
    this.m_curdocintmap = new Map<number, WFDocumentValidValues>();

    this.m_rescache = new Array<ResourceCache>();
    this.m_maxcachelen = 5;

    this.m_commmove_comm = null;
    this.m_commmove_state = MOUSECAPTURESTATE.NONE;
    this.m_commmove_startpos = null;
    this.m_commmove_target = null;
    this.m_commmove_dragstatefl = DS_REPOSITION;
    this.m_commmove_offset = null;

    this.m_newcommentregion = false;
    this.m_newcommentregion_pos = { x: 0, y: 0 };
    this.m_newcommentregion_size = { x: 0, y: 0 };
    this.m_newcommentregion_spos = null;
    this.m_imagectx_spos = null;
    this.m_tabbuttonlines = true;
    this.m_columns = new Array<ColumnSpec>();
    this.m_checkkwords = '';
    this.m_checkrskwords = '';

    this.m_hideuncheck = false;
    this.m_hidevalidval = false;

    this.m_importbtn = false;
    this.m_linesaddbtn = false;
    this.m_linesdelbtn = false;
    this.m_showhideocrlines = false;

    this.m_lines = new Array<LineSpec>();

    this.m_sendtocentral = true;
    this.m_syncrsh = true;
    this.m_sendtoocr = true;

    this.m_schemaname = '';
    this.m_commentmousemovebnd = null;
    this.m_newcommentmousemovebnd = null;

    this.m_quill_modules = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ header: 1 }, { header: 2 }],

        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [
          { color: [] },
          { background: [] }
        ],

        [{ font: [] }],
        [{ align: [] }],

        ['clean'],
        ['link', 'image']
      ]
    };
    this.m_afmap = new Map<string, ViewContainerRef>();
    this.m_afvchecked = null;
    this.m_dirpath= new Array<DirectorySpec>(); 

    //binds
    const self = this;
    this.m_onfetchmessages = (snd, msgs) => {
      self.OnFetchMessages(snd, msgs);
    };

    this.m_onpagemodified = (snd) => {
      self.OnPageModified(snd);
    };

    this.m_onfetchmatchreconschemas = (snd, codes) => {
      self.OnFetchMatchReconSchema(snd, codes);
    };

    this.m_onsuggestdocname = (snd, newname) => {
      self.OnSuggestNewDocName(snd, newname);
    };

    this.m_onfindlinevalues = (snd, ret, ustate) => {
      self.OnFindLineValuesCompleted(snd, ret, ustate);
    };

    this.m_onsuggestocrmap = (snd, ret, ustate) => {
      self.OnSuggestOcrMapCompleted(snd, ret, ustate);
    };

    this.m_propvalchgevh = (snd, prop) => {
      self.OnPropertyValueChange(snd, prop);
    };

    this.m_propvalidateevh = (snd, prop, status) => {
      self.OnPropertyValidation(snd, prop, status);
    };

    this.m_linemodfiedevh = (snd) => {
      self.OnDocumentLinesModified(snd);
    };

    this.m_triggercplevh = (snd, sndprc, docontinue, cstates) => {
      self.OnFireTriggerCompleted(snd, sndprc, docontinue, cstates);
    };

    this.m_selclsevh = (nval) => {
      self.OnChangeClass(nval);
    };

    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_text = ''; });

    this.m_linevalchgevh = (snd, prop) => {
      self.OnLinePropertyValueChange(snd, prop);
    };

    this.m_linevalidateevh = (snd, prop, status) => {
      self.OnLinePropertyValidate(snd, prop, status);
    };

    this.SetEmptyDocument();
  }

  ngAfterViewInit() {
    this.afterInit.emit(this);
  }

  ngAfterViewChecked() {
    this.m_cli_width = this.m_imagectx.nativeElement.clientWidth;
    this.m_cli_height = this.m_imagectx.nativeElement.clientHeight;
    if (this.m_afvchecked != null) {
      let fv = this.m_afvchecked;
      this.m_afvchecked = null;
      this.FocusField2(fv[0], fv[1]);
    }
  }

private get InfobarStateMode(): INFOBARSTATEMODE {
    return (this.m_global_srv.Config == null) ? INFOBARSTATEMODE.DOCUMENT : this.m_global_srv.Config.INFOBARMODE;
  }

  public get Infobarstate(): boolean {
    switch(this.InfobarStateMode)
    {
      case INFOBARSTATEMODE.DOCUMENT:
        if(this.m_document != null) return this.m_document.Infobarstate;
        break;
      case INFOBARSTATEMODE.SESSION:
        return this.m_cache_srv.Infobarstate;
    }
    
    return this.m_infobarstate;
  }

  public set Infobarstate(nval: boolean) {
    switch(this.InfobarStateMode)
    {
      case INFOBARSTATEMODE.DOCUMENT:
        if(this.m_document != null) {
          this.m_document.Infobarstate= nval;
          return;
        }
        break;
      case INFOBARSTATEMODE.SESSION:
        this.m_cache_srv.Infobarstate= nval;
        return;
    }
    
    this.m_infobarstate= nval;
  }

  public get Selected_tab(): number {
    switch(this.InfobarStateMode)
    {
      case INFOBARSTATEMODE.DOCUMENT:
        if(this.m_document != null) return this.m_document.Selected_tab;
        break;
      case INFOBARSTATEMODE.SESSION:
        return this.m_cache_srv.Selected_tab;
    }
    
    return this.m_selected_tab;
  }

  public set Selected_tab(nval: number) {
    switch(this.InfobarStateMode)
    {
      case INFOBARSTATEMODE.DOCUMENT:
        if(this.m_document != null) {
          this.m_document.Selected_tab= nval;
          return;
        }
        break;
      case INFOBARSTATEMODE.SESSION:
        this.m_cache_srv.Selected_tab= nval;
        return;
    }
    
    this.m_selected_tab= nval;
  }

  private SetEmptyDocument(): void {
    this.m_selected_tab = TAB_INFO;

    this.m_cur_page = 0;
    this.m_cur_image_width = 0;
    this.m_cur_image_height = 0;
    this.SetContentType(CONTENTTYPE.TYPE_UNKNOWN);
    this.m_scale = 1.0;

    //m_pages.SelectionChanged -= m_pagechgevh;
    this.m_pages.splice(0, this.m_pages.length);
    this.m_pagesc_info = '/0';

    //m_textctx.Visibility = Visibility.Collapsed;
    //this.m_textctx = false;

    //m_edittextctx.Visibility = Visibility.Collapsed;
    this.m_edittextctx = false;
    this.m_edittextctx_html = '';
    this.m_edittextctx_enable = false;
    this.m_otherctx = false;
    this.m_htmltplctx = false;
    this.m_htmlctx = false;
    this.m_previewhtml= '';
    if (this.m_htmltplcmpref != null) {
      this.m_htmltplcmpref.destroy();
      this.m_htmltplcmpref = null;
    }

    this.FreeImageSource();

    this.TopBarVisibility = false;

    this.m_comments_items.splice(0, this.m_comments_items.length);
    this.m_inf_props.splice(0, this.m_inf_props.length);
    this.m_inf_triggers.splice(0, this.m_inf_triggers.length);
    this.m_inf_line_triggers.splice(0, this.m_inf_line_triggers.length);
    this.m_textregions_items.splice(0, this.m_textregions_items.length);

    this.m_inboxctx.splice(0, this.m_inboxctx.length);
    this.m_outboxctx.splice(0, this.m_outboxctx.length);
    this.m_msgctx = '';
    this.m_refsctx.splice(0, this.m_refsctx.length);
    this.m_refheaderctx.splice(0, this.m_refheaderctx.length);
    this.m_reflinesctx.splice(0, this.m_reflinesctx.length);

    this.m_shmsctx.splice(0, this.m_shmsctx.length);
    this.m_shmheaderctx.splice(0, this.m_shmheaderctx.length);
    this.m_shmlinesctx_cols.splice(0, this.m_shmlinesctx_cols.length);
    this.m_shmlinesctx.splice(0, this.m_shmlinesctx.length);

    //wlasnosci dokumentu
    //TODO
    //this.m_prop_container = false;
    this.m_infolbl = this.m_strdata_srv.getStr('wDocViewer_noDocument').toUpperCase();
    this.m_infolbl3 = '';
    this.m_infolbl2 = '';

    //odepnij od zrodla danych
    this.m_inf_name = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };
    this.m_inf_desc = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };
    this.m_inf_docnum = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };
    this.m_inf_docdate = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };
    this.m_inf_enterdate = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.NOCONV };

    this.m_inf_shm = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };
    this.m_inf_company = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };
    this.m_inf_owner = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };
    this.m_tmpwritemode = { iseditable: true, iserror: false, ismandatory: true, conv: CONVERT.TOINT };

    //m_inf_desc.GotFocus -= m_ocrdstgfocus;
    //m_inf_docdate.GotFocus -= m_ocrdstgfocus;
    //m_inf_docnum.GotFocus -= m_ocrdstgfocus;
    //m_inf_enterdate.GotFocus -= m_ocrdstgfocus;
    //m_inf_name.GotFocus -= m_ocrdstgfocus;

    this.m_authsts_brd = false;
    //this.m_authsts_pth = false;
    //this.m_authsts_pth = '';

    //m_imagectx.VerticalScrollBarVisibility = ScrollBarVisibility.Hidden;
    //m_imagectx.HorizontalScrollBarVisibility = ScrollBarVisibility.Hidden;

    this.m_selocrrs = null;
    this.m_selrow = null;

    this.m_infobarstate = false;

    this.ClearDocumentLines();

    this.m_textregions = false;
    this.m_addcomment_mode = ADDCOMMENTMODE.NONE;
    this.m_addcomment_dest = ADDCOMMENTDEST.COMMENT;

    this.ClearOCRDestination();

    if (this.OnCommentModeChanged != null) {
      this.OnCommentModeChanged(this, ADDMODE.ADD_MOVE);
    }

    //m_lastresext = null;

    this.m_curcmmadp = null;
    this.RefreshCurEntries();

    this.m_cmmsctx_items.splice(0, this.m_cmmsctx_items.length - 1);
    this.m_curdocintmap.clear();

    this.m_prevrequest = true;

    //if (m_inf_prop_parent.Children.Count > 1) m_inf_prop_parent.Children.RemoveAt(1);

    this.m_imgcontainer_img = 'assets/img/other_256.png';
    this.m_curr_rotate = 0;
    this.m_textregionpage = null;
    this.m_pagekeywords_err = false;
    this.m_pagekeywords = '';
    this.m_findtextregion = 0;

    this.m_replybtn = false;
    this.m_tabmsgactive = 0;

    this.m_selref = null;
    this.m_selocr = null;

    this.m_actstyles = '';
    this.m_dirpath.splice(0, this.m_dirpath.length);
  }

  private SetCommentVisibilityMode(): void {
    let ii: number;
    switch (this.m_defcommentmode) {
      case COMMENTSMODE.MODE_MINIMALIZED:
        this.m_comments = true;
        for (ii = 0; ii < this.m_comments_items.length; ii++) {
          let adp: CommentRegionSpec = this.m_comments_items[ii];
          adp.isexpanded = false;
        }
        break;
      case COMMENTSMODE.MODE_MAXIMALIZED:
        this.m_comments = true;
        for (ii = 0; ii < this.m_comments_items.length; ii++) {
          let adp: CommentRegionSpec = this.m_comments_items[ii];
          adp.isexpanded = true;
        }
        break;
      case COMMENTSMODE.MODE_HIDDEN:
        this.m_comments = false;
        break;
    }
  }

  private ClearOCRDestination(): void {
    //TODO
    /*if (m_lastctrl != null)
    {
        m_lastctrl.Background = m_lastctrlbrd;
        m_lastctrl = null;
    }*/
  }

  private AddRotation(deg: number): void {
    let ii: number;

    if (this.m_document != null && !this.m_readonly) {
      if (this.m_cur_page < this.m_document.GetPagesCount() && this.m_document.CanEdit(null) && !this.m_readonly) {
        let cpg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);
        cpg.Rotation = cpg.Rotation + deg;
        this.m_document.IsModified = true;
        this.m_curr_rotate = cpg.Rotation;

        /*RotateTransform rtf = (RotateTransform)m_image.RenderTransform;
        rtf.Angle = cpg.Rotation;

        Rect rin = new Rect(0, 0, m_cur_image_width, m_cur_image_height);
        rin = rtf.TransformBounds(rin);

        m_image.Width = (float)m_cur_image_width * m_scale;
        m_image.Height = (float)m_cur_image_height * m_scale;

        m_imgcontainer.MinWidth = m_imgcontainer.MaxWidth = m_imgcontainer.Width = rin.Width * m_scale;// (float)m_cur_image_width * m_scale;
        m_imgcontainer.MinHeight = m_imgcontainer.MaxHeight = m_imgcontainer.Height = rin.Height * m_scale;// (float)m_cur_image_height * m_scale;

        double mww = (m_imgcontainer.MinWidth > m_imgcontainer.MinHeight) ? m_imgcontainer.MinWidth : m_imgcontainer.MinHeight;
        m_imgbrd.Width = mww;
        m_imgbrd.Height = mww;

        for (ii = 0; ii < m_textregions.Children.Count; ii++) {
          TextRegionAdp tba = (TextRegionAdp)m_textregions.Children[ii];
          tba.ParentWidth = m_imgcontainer.Width;
          tba.ParentHeight = m_imgcontainer.Height;
        }*/
      }
    }
  }

  public ClickRotateLeft(): void {
    try {
      this.AddRotation(-90);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickRotateRight(): void {
    try {
      this.AddRotation(90);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickAutoZoom(): void {
    try {
      let mnoznik = this.m_imagectx.nativeElement.clientWidth / this.m_cur_image_width;
      this.Scale = mnoznik;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickFindOnPage(): void {
    let ii: number;

    try {
      let kwords = this.m_pagekeywords.trim();
      if (kwords.length > 0) {
        kwords = kwords.toUpperCase();

        this.m_pagekeywords_err = false;
        this.AddCommentMode = ADDMODE.ADD_MOVE;

        this.m_textregions = true;
        for (ii = 0; ii < this.m_textregions_items.length; ii++) {
          let tadp: TextRegionSpec = this.m_textregions_items[ii];
          tadp.visible = false;
        }

        for (ii = this.m_findtextregion; ii < this.m_textregions_items.length; ii++) {
          let tadp: TextRegionSpec = this.m_textregions_items[ii];
          let txt = tadp.region.Text.toUpperCase();
          if (txt.indexOf(kwords, 0) >= 0) {
            tadp.visible = true;
            this.m_findtextregion = ii + 1;
            return;
          }
        }

        this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNoResults'));
        this.m_findtextregion = 0;
      } else {
        this.m_pagekeywords_err = true;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickPrevPage(): void {
    try {
      this.CurPage--;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickNextPage(): void {
    try {
      this.CurPage++;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ShowHideInfoPanel(): void {
    this.Infobarstate = !this.Infobarstate;
    this.m_cli_width = this.m_imagectx.nativeElement.clientWidth;
    this.m_cli_height = this.m_imagectx.nativeElement.clientHeight + ((this.Infobarstate) ? -335 : 335);
  }

  //

  private ShowApprovals(): void {
    const self = this;
    const wnd = this.m_wnd_srv.showControl(ApprovalsComponent);
    const inst: ApprovalsComponent = <ApprovalsComponent>wnd.instance;
    inst.SetPrms(this.m_document, null);
    inst.onClosed.subscribe((ret) => {
      wnd.destroy();
    });
  }

  private ApproveDoc(): void {
    if (this.m_document.AuthPathsCount > 0) {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(ApproveWndComponent);
      const inst: ApproveWndComponent = <ApproveWndComponent>wnd.instance;
      inst.SetPrms(this.m_document);
      inst.onClosed.subscribe((ret) => {        
        wnd.destroy();
        try {
          if(ret== APPWNDRESULT.APPROVE && inst.AutoSave && self.m_document.IsModified) {
            self.m_global_srv.App.PublishLocalDoc(self.m_document);
          }
        } catch(ex) {
          self.m_global_srv.manageException(ex);
        }
      });
    }
  }

  public ClickApprove1(): void {
    //this.m_options = !this.m_options;

    try {

      if (this.m_document != null) {
        if (this.m_document.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
          switch (this.m_document.AuthStatus) {
            case WFAuthStatus.APPROVED:
            case WFAuthStatus.DISAPPROVED:
              this.ShowApprovals();
              break;

            case WFAuthStatus.WAITING:
              if (this.m_document.CanApprove) {
                this.ApproveDoc();
              } else {
                this.ShowApprovals();
              }
              break;
          }
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickApprove2(): void {
    try {
      if (this.m_document != null) {
        if (this.m_document.CanApprove) this.ApproveDoc();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickApproveHist(): void {
    try {
      if (this.m_document != null) this.ShowApprovals();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public ZoomChanged(nval: ComboIValueDesc): void {
    try {
      this.Scale = nval.val / 100.0;
      this.m_actual_pagezoom = nval.val;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ZoomIn(): void {
    if (this.m_actual_pagezoom < 500) {
      try {
        this.m_actual_pagezoom += 40;
        this.Scale = this.m_actual_pagezoom / 100.0;
      } catch (ex) {
        this.m_global_srv.manageException(ex);
      }
    }
  }

  public ZoomOut(): void {
    if (this.m_actual_pagezoom > 40) {
      try {
        this.m_actual_pagezoom -= 40;
        this.Scale = this.m_actual_pagezoom / 100.0;
      } catch (ex) {
        this.m_global_srv.manageException(ex);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.m_cli_width = this.m_imagectx.nativeElement.clientWidth;
    this.m_cli_height = this.m_imagectx.nativeElement.clientHeight;
  }

  public get ActualLeftStyle(): string {
    return (this.m_image_width > this.m_cli_width) ? '0px' : 'calc(50% - ' + ((this.m_image_width / 2) | 0).toString() + 'px)';
  }

  public get ActualTopStyle(): string {
    return (this.m_image_height > this.m_cli_height) ? '0px' : 'calc(50% - ' + ((this.m_image_height / 2) | 0).toString() + 'px)';
  }

  public PageChanged(nval: ComboIValueDesc): void {
    try {
      this.m_cur_page = nval.val;
      this.SetCurrentPage();
      this.m_actual_page = nval;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public SelectTab(tabid: number): void {
    if (tabid === 3 && this.m_selref == null && this.m_refsctx.length > 0) {
      let nref = this.m_refsctx[0];
      this.SelectExObjRef(nref);
    }

    this.Selected_tab = tabid;
  }

  public EditCustomSchema(): void {
    try {
      if (this.m_document != null) {
        if (this.m_document.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
          const self = this;
          const wnd = this.m_wnd_srv.showControl(EditauthschemaComponent);
          const inst: EditauthschemaComponent = <EditauthschemaComponent>wnd.instance;
          inst.SetPrms(this.m_document, this.m_readonly);
          inst.onClosed.subscribe((ret) => {
            self.EditSchemaClosed(inst, ret);
            wnd.destroy();
          });
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private EditSchemaClosed(snd: EditauthschemaComponent, ret: boolean): void {
    try {
      if (ret) {
        //sprawdz zaznaczony
        //ClearBinding(m_inf_shm, ComboBoxAdp.SelectedValueProperty);

        //Binding bnd = new Binding("AuthSchemaID");
        //bnd.Mode = BindingMode.TwoWay;
        //bnd.Source = m_document;
        //bnd.NotifyOnValidationError = bnd.ValidatesOnExceptions = bnd.ValidatesOnNotifyDataErrors = true;

        //m_inf_shm.SetBinding(ComboBoxAdp.SelectedValueProperty, bnd);
        this.m_inf_shm.iseditable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA) && !this.m_readonly);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public EditPermissions(): void {
    try {
      if (this.m_document != null) {
        const self = this;
        if (this.m_document.CanChangePermissions) {
          const wnd = this.m_wnd_srv.showControl(EditpermissionsComponent);
          const inst: EditpermissionsComponent = <EditpermissionsComponent>wnd.instance;
          inst.SetPrms(this.m_document, this.m_readonly);
          inst.onClosed.subscribe((ret) => {
            wnd.destroy();
          });
        } else {
          const wnd = this.m_wnd_srv.showControl(ParentpermissionsComponent);
          const inst: ParentpermissionsComponent = <ParentpermissionsComponent>wnd.instance;
          inst.SetPrms(this.m_document, true);
          inst.onClosed.subscribe((ret) => {
            wnd.destroy();
          });
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private SetHeaderFieldsEditable(): void {
    let name_editable = true;

    if (this.m_document.DocStatus !== WFDocStatus.TEMPLATE) {
      if (this.m_document.ClassID > 0) {
        let cls: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
        if (cls.CheckDocNameFormat === WFCheckFormat.AUTONAME) name_editable = false;
      }

      if (name_editable) name_editable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_NAME) && !this.m_readonly);
    }

    this.m_inf_name.iseditable = name_editable;
    this.m_inf_name_btn = (this.m_document.DocStatus !== WFDocStatus.TEMPLATE) ? name_editable : false;

    let desc_editable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_DESCRIPTION) && !this.m_readonly);
    this.m_inf_desc.iseditable = desc_editable;

    let dnum_editable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_DOCNUM) && !this.m_readonly);
    this.m_inf_docnum.iseditable = dnum_editable;

    this.m_inf_shm.iseditable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA) && !this.m_readonly);
    this.m_inf_cls_cmb.IsEnabled = (this.m_document.CanEdit3(WFAuthPermission.STDATR_CLASS) && !this.m_readonly);
    this.m_inf_docdate.iseditable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_DOCDATE) && !this.m_readonly);
    this.m_inf_enterdate.iseditable = (this.m_document.CanEdit3(WFAuthPermission.STDATR_ENTERDATE) && !this.m_readonly);

    let cedt = (this.m_document.CanEdit(null) && !this.m_readonly);
    this.m_rotleft = this.m_rotright = cedt;
  }

  //
  public static IsComboBoxAsCheckBox(csrv: CacheService, adp: IWFCompanyObjAdp, att: WFClassAttrib): boolean {
    if (!att.AllowCustomValue) {
      let mp: Map<string, string> = null;

      switch (att.Type) {
        case WFFieldType.TYPE_SYSDICT:
          if (adp.CompanyID > 0) {
            let cmp: WFCompany = csrv.Companies.get(adp.CompanyID);
            if (att.CompaniesDictsID.has(adp.CompanyID)) {
              let dcid: number = att.CompaniesDictsID.get(adp.CompanyID);
              for (let tdc of cmp.Dictionaries) {
                if (tdc.ID === dcid) {
                  mp = tdc.Values;
                  break;
                }
              }
            }
          }
          break;

        case WFFieldType.TYPE_USRDICT:
          mp = att.ValidValues;
          break;

        case WFFieldType.TYPE_STATICDICT:
          if (csrv.StaticDictionaries.has(att.DictionaryID)) {
            let dcc: WFDictionary = csrv.StaticDictionaries.get(att.DictionaryID);
            mp = dcc.ValidValues;
          }
          break;
      }

      if (mp != null) {
        if (mp.size === 2 && (mp.has('Y') || mp.has('y')) && (mp.has('N') || mp.has('n'))) return true;
      }
    }

    return false;
  }

  private RefreshClasses(editable: boolean, cmp_id: number): void {
    IWFObject.SafeUnsubscribe(this.m_inf_cls_cmb.SelectedValueChange);

    let clsadded = new Array<number>();

    this.m_inf_cls_cmb.ClearValidValues();
    this.m_inf_cls_cmb.AddValidValue('0', this.m_strdata_srv.getStr('strClassUnknown'));
    this.m_inf_cls_cmb.ClearValue = '0';

    let cmp: WFCompany = this.m_cache_srv.Companies.get(cmp_id);

    if (editable) {
      if (this.m_cache_srv.User.DocClassPermissionType !== PrmType.TYPE_DENYFROMALL) {
        let scls= Array.from(this.m_cache_srv.Classes.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
        for (let cl of scls) {
          if (cl.Visibility === WFClassVisibility.VISIBLE) {
            if (this.m_cache_srv.User.DocClassPermissionType === PrmType.TYPE_ALLOWFROM && this.m_document.ClassID !== cl.ID) {
              if (this.m_cache_srv.User.DocClassPermissions.indexOf(cl.ID) < 0) continue;
            }

            if(cmp.AllowedClasses.length > 0) {
              if(cmp.AllowedClasses.indexOf(cl.ID) < 0) continue;              
            }

            this.m_inf_cls_cmb.AddValidValue(cl.ID.toString(), IWFObject.Format('{0} - {1}', cl.Name.toUpperCase(), cl.Description.trim()));
            clsadded.push(cl.ID);
          }
        }
      }
    } else {
      let scls= Array.from(this.m_cache_srv.Classes.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
      for (let cl of scls) {
        if (cl.Visibility === WFClassVisibility.VISIBLE) {
          this.m_inf_cls_cmb.AddValidValue(cl.ID.toString(), IWFObject.Format('{0} - {1}', cl.Name.toUpperCase(), cl.Description.trim()));
          clsadded.push(cl.ID);
        }
      }
    }

    //dodaj jesli wczesniej wybrano
    if (this.m_document.ClassID > 0 && clsadded.indexOf(this.m_document.ClassID) < 0) {
      let cl: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
      this.m_inf_cls_cmb.AddValidValue(cl.ID.toString(), IWFObject.Format('{0} - *{1}', cl.Name.toUpperCase(), cl.Description.trim()));
    }

    this.m_inf_cls_cmb.SelectedValue = this.m_document.ClassID.toString();

    this.ReloadClassAttribs();
    this.m_inf_cls_cmb.SelectedValueChange.subscribe(this.m_selclsevh);
  }

  private ReloadClassAttribs(): void {
    let ii: number;

    this.m_inf_props.splice(0, this.m_inf_props.length);
    this.m_inf_triggers.splice(0, this.m_inf_triggers.length);
    this.m_inf_line_triggers.splice(0, this.m_inf_line_triggers.length);
    this.m_inf_shm_cmb.ClearValidValues();

    let cls2: WFClass;

    let clsid: number = IWFObject.ParseInt(this.m_inf_cls_cmb.SelectedValue);
    if (clsid > 0) {
      cls2 = this.m_cache_srv.Classes.get(clsid);

      //

      for (ii = 0; ii < this.m_document.AllAttributes.length; ii++) {
        let att: WFClassAttrib = this.m_document.AllAttributes[ii];
        this.m_inf_props.push({
          label: IWFObject.Format('{0}:', (IWFObject.IsNullOrEmpty(att.Label)) ? att.Name : att.Label).toUpperCase(),
          attrib: att,
          iseditable: (this.m_document.CanEdit(att) && !this.m_readonly),
          iserror: false,
          dateval: null,
          comboischb: DocviewerComponent.IsComboBoxAsCheckBox(this.m_cache_srv, this.m_document, att)
        });
      }

      //wypelnij schematy
      let noauth = WFDocument.SCHM_NOAUTH.toString();
      this.m_inf_shm_cmb.ClearValue = noauth;
      this.m_inf_shm_cmb.AddValidValue(noauth, this.m_strdata_srv.getStr('strAuthNoAuth'));
      if (cls2.AllowCustomSchema) {
        this.m_inf_shm_cmb.AddValidValue(WFDocument.SCHM_CUSTOM.toString(), this.m_strdata_srv.getStr('strAuthCustom'));
      }

      let ashm= new Array<WFAuthSchema>();
      for (ii = 0; ii < cls2.AllowedSchemas.length; ii++) {
        let shmid = cls2.AllowedSchemas[ii];
        let sh: WFAuthSchema = this.m_cache_srv.AuthSchemas.get(shmid);
        ashm.push(sh);
        //this.m_inf_shm_cmb.AddValidValue(shmid.toString(), IWFObject.Format('{0} - {1}', sh.Name.toUpperCase(), sh.Description.trim()));
      }

      let sshm= ashm.sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
      for(let sh of sshm) {
        this.m_inf_shm_cmb.AddValidValue(sh.ID.toString(), IWFObject.Format('{0} - {1}', sh.Name.toUpperCase(), sh.Description.trim()));
      }

      this.ClearDocumentLines();
      if (this.m_document.AllColumns.length > 0) {
        this.m_tabbuttonlines = true;
        this.ReloadDocumentLines();
      } else {
        this.m_tabbuttonlines = false;
      }

      if (this.m_document.ID > 0) {
        //dodanie wyzwalaczy
        for (ii = 0; ii < cls2.Triggers.length; ii++) {
          let trg = cls2.Triggers[ii];
          if (trg.Position < 0) {
            let spc: TriggerSpec= {
              label: trg.Label,
              hint: (IWFObject.IsNullOrEmpty(trg.Description)) ? trg.Label : trg.Description,
              trigger: trg
            };

            switch(trg.Position)
            {
              case -1:
                this.m_inf_triggers.push(spc);
                break;
              case -2:
                this.m_inf_line_triggers.push(spc);
                break;
            }
          }
        }
      }
    } else {
      this.m_inf_shm_cmb.AddValidValue(WFDocument.SCHM_NOAUTH.toString(), this.m_strdata_srv.getStr('strAuthNoAuth'));
      this.m_inf_shm_cmb.AddValidValue(WFDocument.SCHM_CUSTOM.toString(), this.m_strdata_srv.getStr('strAuthCustom'));
      let sshm= Array.from(this.m_cache_srv.AuthSchemas.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
      for (let shm of sshm) {
        this.m_inf_shm_cmb.AddValidValue(shm.ID.toString(), IWFObject.Format('{0} - {1}', shm.Name.toUpperCase(), shm.Description.trim()));
      }

      /*m_infopanel.ColumnDefinitions[1].Width = new GridLength(0, GridUnitType.Pixel);*/

      this.ClearDocumentLines();
      this.m_tabbuttonlines = false;
    }

    this.m_inf_shm.iserror = false;
    this.SetHeaderFieldsEditable();

    //if (this.m_cur_type === CONTENTTYPE.TYPE_EDITABLE) this.SetupUserFields();

    /*if (m_inf_prop_parent.Children.Count > 1) m_inf_prop_parent.Children.RemoveAt(1);*/

    if (this.OnClassChange != null) this.OnClassChange(this, clsid);
  }

  private OnFetchMessages(sender: WFDocumentAdp, msgs: Array<WFMessageAdp>): void {
    let ii, ii2: number;
    let cc: number;

    try {
      this.m_document.OnFetchMessagesCompleted = null;

      this.m_inboxctx.splice(0, this.m_inboxctx.length);
      this.m_outboxctx.splice(0, this.m_outboxctx.length);
      this.m_replybtn = false;

      msgs.sort((msg1, msg2) => IWFObject.DateCompare(msg2.CreatedAt, msg1.CreatedAt));

      //wiadomosci
      for (ii = 0; ii < msgs.length; ii++) {
        let adp = msgs[ii];

        let found = false;
        cc = adp.GetRecipientsCount();
        for (ii2 = 0; ii2 < cc; ii2++) {
          let rc = adp.GetRecipient(ii2);
          if (rc.UsersID === this.m_cache_srv.User.ID) {
            found = true;
            break;
          }
        }

        let infodate: string;
        if (adp.UserID > 0) {
          let usr: WFUser = (adp.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(adp.UserID);
          infodate = IWFObject.Format('{0} {1}', usr.FriendlyName, WFDocumentAdp.DateTimeToStr(adp.CreatedAt, this.m_strdata_srv));
        } else {
          infodate = WFDocumentAdp.DateTimeToStr(adp.CreatedAt, this.m_strdata_srv);
        }

        let msg: MessageSpec = {
          isread: adp.IsRead(this.m_cache_srv),
          isreadvis: found,
          msg: adp,
          subject: adp.Subject.toUpperCase(),
          infodate: infodate,
          busytext: ''
        };

        if (found)
          this.m_inboxctx.push(msg);
        else
          this.m_outboxctx.push(msg);
      }

      this.SetCurrentPage();

      //DM 15062018
      if (this.m_cache_srv.Config.OcrTaskMode === WFOcrTaskMode.EX_DISK &&
        this.m_cache_srv.ReconSchemas.size > 0) {
        this.m_document.OnFetchMatchReconSchemaCompleted = this.m_onfetchmatchreconschemas;
        this.m_busy_text = this.m_strdata_srv.getStr('strWaitOCRSchemas');
        this.m_document.FetchMatchReconSchemas();
        return;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }

  private OnPageModified(sender: WFDocumentAdp): void {
    try {
      if (sender === this.m_document) {
        let pages_c = this.m_document.GetPagesCount();
        if (this.m_cur_page >= pages_c) {
          if (pages_c > 0) this.m_cur_page = pages_c - 1;
        }

        this.RefreshPageInfo();
        this.SetCurrentPage();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnFetchMatchReconSchema(sender: WFDocumentAdp, codes: Array<string>): void {
    let ii: number;

    try {
      //this.m_shmsctx.splice(0, this.m_shmsctx.length);

      //m_shmlinesctx.Children.Clear();
      //m_shmheaderctx.Children.Clear();

      /*if (codes.length > 0)
      {
          TextBlock tbx = new TextBlock();
          tbx.Text = App.GetString("wDocViewer_OCRSchemaSugested");
          tbx.Style = (Style)Application.Current.Resources["FNT_TXT_LIGHT_NORMAL"];
          tbx.Margin = new Thickness(5);

          Border brd2 = new Border();
          brd2.Background = null;
          brd2.Child = tbx;
          brd2.HorizontalAlignment = System.Windows.HorizontalAlignment.Stretch;

          m_shmsctx.Children.Add(brd2);

          for (ii = 0; ii < codes.Length; ii++)
          {
              string ccode = codes[ii];
              if (m_document.Session.ReconSchemas.ContainsKey(ccode))
              {
                  WFReconSchema shm = m_document.Session.ReconSchemas[ccode];

                  ReconSchemaAdp adp = new ReconSchemaAdp(shm, m_document);
                  adp.MouseLeftButtonDown += m_selreconshm;
                  adp.OnFetchPreviewCompleted += m_onfetchrshmprv;

                  Border brd = new Border();
                  brd.Background = null;
                  brd.Child = adp;
                  brd.HorizontalAlignment = System.Windows.HorizontalAlignment.Stretch;

                  m_shmsctx.Children.Add(brd);
              }
          }

          //

          tbx = new TextBlock();
          tbx.Text = App.GetString("wDocViewer_OCRSchemaAll");
          tbx.Style = (Style)Application.Current.Resources["FNT_TXT_LIGHT_NORMAL"];
          tbx.Margin = new Thickness(5);

          brd2 = new Border();
          brd2.Background = null;
          brd2.Child = tbx;
          brd2.HorizontalAlignment = System.Windows.HorizontalAlignment.Stretch;

          m_shmsctx.Children.Add(brd2);
      }

      //

      foreach (WFReconSchema shm in m_document.Session.ReconSchemas.Values)
      {
          if (!codes.Contains(shm.Code))
          {
              ReconSchemaAdp adp = new ReconSchemaAdp(shm, m_document);
              adp.MouseLeftButtonDown += m_selreconshm;
              adp.OnFetchPreviewCompleted += m_onfetchrshmprv;

              Border brd = new Border();
              brd.Background = null;
              brd.Child = adp;
              brd.HorizontalAlignment = System.Windows.HorizontalAlignment.Stretch;

              m_shmsctx.Children.Add(brd);
          }
      }*/
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }

  private OnSuggestNewDocName(adp: WFDocumentAdp, newname: string): void {
    try {
      if (newname != null) this.m_document.Name = newname;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_text = '';
  }

  private OnFindLineValuesCompleted(snd: WFDocumentAdp, ret: Map<string, Array<[string, string]>>, ustate: object): void {
    let ii: number;

    try {
      this.m_busy_text = '';

      let ovec = <object[]>ustate;
      let lines = <WFDocumentLineAdp[]>ovec[3];

      if (ret != null) {
        for (let kv2 of Array.from(ret.entries())) {
          let attrcode = kv2[0];
          let rpls = kv2[1];

          for (ii = 0; ii < lines.length; ii++) {
            let line = lines[ii];
            let oval = line.get(attrcode);
            if (!IWFObject.IsNullOrEmpty(oval)) {
              oval = oval.toUpperCase();
              for (let kv of rpls) {
                if (oval == kv[0].toUpperCase()) {
                  line.set(attrcode, kv[1]);
                  break;
                }
              }
            }
          }
        }

        this.m_global_srv.showInfo(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('wDocViewer_OCRCopyToLinesResult'), ovec[0], ovec[1], ovec[2]));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnSuggestOcrMapCompleted(snd: WFDocumentAdp, ret: Array<WFRelation>, ustate: object): void {
    let ii: number;

    try {

      const self = this;
      const wnd = this.m_wnd_srv.showControl(ColumnMapDefinitionsComponent);
      const inst: ColumnMapDefinitionsComponent = <ColumnMapDefinitionsComponent>wnd.instance;
      inst.SetPrms(this.m_document.AllColumns);

      //ColumnMapDefinitions dlg = new ColumnMapDefinitions(m_document.AllColumns);// cls.Columns);
      //dlg.DataContext = ustate;

      let fret = (ret != null && ret.length > 0);
      let lrels: Array<WFRelation> = (fret) ? null : this.m_document.FindOcrMaps(this.m_selocrrs.SchemaCode);
      let frl: WFRelation;
      let uattribcode: string;

      for (ii = 0; ii < this.m_shmlinesctx_cols.length; ii++) {
        let shmcol = this.m_shmlinesctx_cols[ii].label;
        let ushmcol = shmcol.toUpperCase();
        let atrid = -1;

        //szukaj w wynikach funckji
        if (fret) {
          frl = null;
          for (let rl of ret) {
            if (rl.OcrName.toUpperCase() === ushmcol) {
              frl = rl;
              break;
            }
          }

          if (frl != null) {
            uattribcode = frl.AttribCode.toUpperCase();
            for (let atr of this.m_document.AllColumns) {
              if (atr.Name.toUpperCase() === uattribcode) {
                atrid = atr.ID;
                break;
              }
            }
            inst.AddRelation(shmcol, atrid, frl.MapType);
            continue;
          }
        }

        //szukaj w wynikach lokalnych mapowan
        if (lrels != null) {
          if (lrels.length > 0) {
            frl = null;
            for (let rl of lrels) {
              if (rl.OcrName.toUpperCase() === ushmcol) {
                frl = rl;
                break;
              }
            }

            if (frl != null) {
              uattribcode = frl.AttribCode.toUpperCase();
              for (let atr of this.m_document.AllColumns) {
                if (atr.Name.toUpperCase() === uattribcode) {
                  atrid = atr.ID;
                  break;
                }
              }
              inst.AddRelation(shmcol, atrid, frl.MapType);
              continue;
            }
          }
        }

        //szukaj po nazwie kolumny
        for (let atr of this.m_document.AllColumns) {
          if (!IWFObject.IsNullOrEmpty(atr.ReconSchemaFieldCode)) {
            if (atr.ReconSchemaFieldCode.toUpperCase() === ushmcol) {
              atrid = atr.ID;
              break;
            }
          }
        }

        inst.AddRelation(shmcol, atrid, WFRelationType.COPY);
      }

      inst.onClosed.subscribe((dlgret) => {
        wnd.destroy();
        if (dlgret) self.ColumnMapDefClosed(inst, ustate);
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_text = '';
  }

  private ColumnMapDefClosed(snd: ColumnMapDefinitionsComponent, ustate: object): void {
    let ii, ii2: number;

    try {

      let selrows = <Array<[number, Array<object>]>>ustate;

      //kolumny wyszukiwania
      let fcols = new Array<number>(snd.RelationsCount);
      let fcolsatr = new Array<string>(snd.RelationsCount);
      let fcols_c = 0;

      //kolumny kopiowania
      let ccols = new Array<number>(snd.RelationsCount);
      let ccolsatr = new Array<string>(snd.RelationsCount);
      let ccols_c = 0;

      //kolumny zapamietania
      let rcols = new Array<number>(snd.RelationsCount);
      let rcolsatr = new Array<string>(snd.RelationsCount);
      let rcols_c = 0;

      //reszta kolumn
      let nrels = new Array<WFRelation>();

      for (ii = 0; ii < snd.RelationsCount; ii++) {
        let rtp: WFRelationType = snd.GetRelationType(ii);
        let atr: WFClassAttrib = snd.GetClassAttrib(ii);
        if (atr != null) {
          switch (rtp) {
            case WFRelationType.COPY:
              ccols[ccols_c] = ii;
              ccolsatr[ccols_c] = atr.Name;
              ccols_c++;
              break;
            case WFRelationType.INDEX:
              fcols[fcols_c] = ii;
              fcolsatr[fcols_c] = atr.Name;
              fcols_c++;
              break;
            case WFRelationType.REMEMBER:
              rcols[rcols_c] = ii;
              rcolsatr[rcols_c] = atr.Name;
              rcols_c++;
              ccols[ccols_c] = ii;
              ccolsatr[ccols_c] = atr.Name;
              ccols_c++;
              break;
          }
          nrels.push(WFRelation.Create(snd.GetSchemaColumn(ii), atr.Name, rtp));
        }
      }

      //kopiowanie
      let repcc = 0;
      let addcc = 0;
      let errcc = 0;

      let addlines = new Array<WFDocumentLineAdp>();

      for (let kv of selrows) {
        try {
          let lid = -1;
          let ln: WFDocumentLineAdp;

          if (fcols_c > 0) {
            let lcc = this.m_document.GetLinesCount();
            for (ii = 0; ii < lcc; ii++) {
              ln = this.m_document.GetLine(ii);

              let match = true;

              for (ii2 = 0; ii2 < fcols_c; ii2++) {
                //wyszukaj po indeksach
                let lside = ln.get(fcolsatr[ii2]);
                if (lside == null) {
                  lside = '';
                } else {
                  lside = lside.trim().toUpperCase();
                }

                let _rside = kv[1][fcols[ii2]];
                let rside = (_rside == null) ? '' : _rside.toString().trim().toUpperCase();

                if (lside !== rside) {
                  match = false;
                  break;
                }
              }

              if (match) {
                lid = ii;
                break;
              }
            }
          }

          if (lid < 0) {
            lid = this.m_document.GetLinesCount();
            this.m_document.AddLine(1, false);
            addcc++;
            ln = this.m_document.GetLine(lid);
            //ln.OnPropertyValueChange += m_linevalchgevh;
            //ln.OnPropertyValidation += m_linevalidateevh;
            if(fcols_c > 0) {
              if (!ln.SetLineDataFromMapDataRow(kv[1], fcolsatr, fcols, fcols_c)) errcc++;
            }
          } else {
            ln = this.m_document.GetLine(lid);
            repcc++;
          }

          if(ccols_c > 0) {
            if (!ln.SetLineDataFromMapDataRow(kv[1], ccolsatr, ccols, ccols_c)) errcc++;
          }

          //dodanie referencji
          let rf = ln.AddLineRef(this.m_selocrrs.ID, kv[0]);
          if (rcols_c > 0) {
            for (ii2 = 0; ii2 < rcols_c; ii2++) {
              rf.Remember.push([rcolsatr[ii2], snd.GetSchemaColumn(rcols[ii2])]);
            }
            addlines.push(ln);
          }
        } catch (Exception) {
          errcc++;
        }
      }

      //przelicz pozostale kolumny
      let cols= new Array<WFClassAttrib>();
      for(let col of this.m_document.AllColumns) {
        let add= true;

        for(ii=0; ii< fcols_c; ii++) {
          if(fcolsatr[ii]== col.Name) {
            add= false;
            break;
          }
        }

        if(add) {
          for(ii=0; ii< ccols_c; ii++) {
            if(ccolsatr[ii]== col.Name) {
              add= false;
              break;
            }
          }
  
          if(add) cols.push(col);
        }
      }
      
      if(cols.length > 0)
        this.m_document.EvalColumns(cols, 0, 0);

      //
    
      this.ClearDocumentLines();
      if (this.m_document.AllColumns.length > 0) this.ReloadDocumentLines();
      this.m_document.SetOcrMaps(nrels, (this.m_selocrrs == null) ? '' : this.m_selocrrs.SchemaCode);

      if (rcols_c > 0) {
        //wykonanie zapytania korekty
        let tmath = new Map<string, Array<string>>();
        for (ii = 0; ii < rcols_c; ii++) {
          let vals = new Array<string>();
          let cid = rcols[ii];
          for (let kv of selrows) {
            let oval = kv[1][cid];
            let sval = (oval == null) ? '' : oval.toString();
            sval = sval.trim();
            if (vals.indexOf(sval) < 0) vals.push(sval);
          }
          tmath.set(rcolsatr[ii], vals);
        }

        this.m_busy_text = this.m_strdata_srv.getStr('strFindLineValuesInfo');
        this.m_document.OnFindLineValuesCompleted = this.m_onfindlinevalues;
        this.m_document.FindLineValues(tmath, [addcc, repcc, errcc, addlines]);
      } else {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('wDocViewer_OCRCopyToLinesResult'), addcc, repcc, errcc));
      }

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnPropertyValueChange(snd: WFDocumentAdp, prop: string): void {
    let ii: number;

    try {
      let uprop = prop.toUpperCase();

      /*if (this.m_cur_type === CONTENTTYPE.TYPE_EDITABLE) {
        m_edittextctx.UserFieldValueChanged -= m_usrfldchgevh;
        this.SetupUserFields();
        m_edittextctx.UserFieldValueChanged += m_usrfldchgevh;
      }*/

      if (this.m_document.ClassID > 0 && this.m_document.DocStatus !== WFDocStatus.TEMPLATE && uprop !== 'NAME') {
        let cls: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
        if (cls.CheckDocNameFormat === WFCheckFormat.AUTONAME) this.SuggestNewDocName();
      }

      this.RefreshInfoRow2();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnPropertyValidation(snd: IWFCompanyObjAdp, prop: string, status: boolean): void {
    let ii: number;

    try {
      for (ii = 0; ii < this.m_inf_props.length; ii++) {
        let ps = this.m_inf_props[ii];
        if (ps.attrib.Name.toUpperCase() === prop) {
          ps.iserror = !status;
          break;
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnDocumentLinesModified(snd: WFDocumentAdp): void {
    try {
      this.ClearDocumentLines();
      if (this.m_document.AllColumns.length > 0) this.ReloadDocumentLines();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnFireTriggerCompleted(snd: WFDocumentAdp, sndprc: WFCustomProcess, docontinue: boolean, cstates: Map<number, WFProcessVarState>): void {
    try {

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private OnChangeClass(nval: string): void {
    try {
      if (!IWFObject.IsNullOrEmpty(nval)) {
        let clsid = IWFObject.ParseInt(nval);
        if (this.m_document.GetLinesCount() > 0) {
          const self = this;
          //console.log('show warrning');
          this.m_global_srv.showMsg(ALR_ICONTYPE.WARNING, BUTTONSTYPE.YESNO, this.m_strdata_srv.getStr('wDocViewer_DocDeleteAsk'),
            (res) => {
              self.LineDelAskClosed(res, clsid);
            });
        } else {
          this.m_document.ClassID = clsid;
          this.ReloadClassAttribs();
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private LineDelAskClosed(res: WNDRESULT, clsid: number): void {
    try {
      if (res === WNDRESULT.OKYES) {
        this.m_document.ClassID = clsid;
        this.ReloadClassAttribs();
      } else {
        IWFObject.SafeUnsubscribe(this.m_inf_cls_cmb.SelectedValueChange);
        this.m_inf_cls_cmb.SelectedValue = this.m_document.ClassID.toString();
        this.m_inf_cls_cmb.SelectedValueChange.subscribe(this.m_selclsevh);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public static FillComboBox(adp: IWFCompanyObjAdp, curdocintmap: Map<number, WFDocumentValidValues>, csrv: CacheService,
    cmb: ComboboxadpComponent, att: WFClassAttrib): void {
    switch (att.Type) {
      case WFFieldType.TYPE_SYSDICT:
        if (adp.CompanyID > 0) {
          let cmp: WFCompany = csrv.Companies.get(adp.CompanyID);
          if (att.CompaniesDictsID.has(adp.CompanyID)) {
            let dcid: number = att.CompaniesDictsID.get(adp.CompanyID);
            for (let tdc of cmp.Dictionaries) {
              if (tdc.ID === dcid) {
                cmb.SetValidValues(tdc.Values);
                break;
              }
            }
          }
        }
        break;
      case WFFieldType.TYPE_USRDICT:
        cmb.SetValidValues(att.ValidValues);
        break;

      case WFFieldType.TYPE_INTDICT:
        if (csrv.Dictionaries.has(att.DictionaryID)) {
          let dvv: WFDocumentValidValues = adp.GetInteractiveDictDocMapper(curdocintmap, att.DictionaryID);
          cmb.SetValidValues2(dvv);
        }
        break;

      case WFFieldType.TYPE_STATICDICT:
        if (csrv.StaticDictionaries.has(att.DictionaryID)) {
          let dcc: WFDictionary = csrv.StaticDictionaries.get(att.DictionaryID);
          cmb.SetValidValues(dcc.ValidValues);
        }
        break;
    }
  }

  public FillComboBox(cmb: ComboboxadpComponent, att: WFClassAttrib): void {
    try {
      DocviewerComponent.FillComboBox(this.m_document, this.m_curdocintmap, this.m_cache_srv, cmb, att);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public FillSboBusinessPartner(cmp: SbobusinesspartnerComponent, att: WFClassAttrib): void {
    const self = this;
    cmp.SetSource(this.m_document, () => self.m_selocrrs);
  }

  //

  private RefreshPageInfo(): void {
    let ii: number;
    let pages_c: number = this.m_document.GetPagesCount();

    this.m_pages.splice(0, this.m_pages.length);
    for (ii = 0; ii < pages_c; ii++) {
      this.m_pages.push({
        val: ii,
        desc: (ii + 1).toString()
      });
    }
    this.m_actual_page = this.m_pages[this.m_cur_page];
    this.m_pagesc_info = IWFObject.Format('/ {0}', pages_c);
  }

  private FetchMessages(refresh: boolean): void {
    //pobieranie danych
    this.m_document.OnFetchMessagesCompleted = this.m_onfetchmessages;
    this.m_busy_text = this.m_strdata_srv.getStr('strWaitMessages');
    this.m_document.FetchMessages(refresh);
  }

  //

  private SetResourceInfo(fname: string, ext: string, utm: Date, dlen: number, nuri: string, res_id: number): void {
    let ii: number;

    this.m_otherctx = true;
    this.SetContentType(CONTENTTYPE.TYPE_UNKNOWN);
    //CrossUtils.GetBlocks(m_textctx).Clear();
    this.FreeImageSource();
    this.m_edittextctx_html = '';
    this.m_edittextctx_enable = false;

    this.TopBarVisibility = (res_id > 0) ? (this.m_document.GetPagesCount() > 1) : false;
    this.m_othericon = DocviewerComponent.m_otherimgsrc;

    let uext = ext.toUpperCase();
    for (ii = 0; ii < DocviewerComponent.m_otherextimgsrc.length; ii++) {
      let kv = DocviewerComponent.m_otherextimgsrc[ii];
      if (kv.key.indexOf(uext) >= 0) {
        this.m_othericon = kv.value;
        break;
      }
    }

    let spf: string;
    if (dlen > 1048576) {
      spf = 'MB';
      dlen = dlen / 1048576;
    } else {
      if (dlen > 1024) {
        spf = 'KB';
        dlen = dlen / 1024;
      } else {
        spf = 'B';
      }
    }

    this.m_otherinf = IWFObject.Format('{0} {1} ({2} {3})', utm.toLocaleDateString(), utm.toLocaleTimeString(), dlen.toFixed(2), spf);
    this.m_otheruploadbtn = this.m_document.CanReplaceResource;

    this.m_otherlink = '';
    if (res_id > 0) {
      this.m_busy_text = this.m_strdata_srv.getStr('wDownloadDocument_BusyText');
      const self = this;
      this.m_data_srv.prepareDownload(this.m_cache_srv.SessionID, [res_id], this.m_onerrorevh, (dinf) => {
        self.PrepareDownloadCompleted(dinf);
      });
    } else {
      this.m_othersavebtn = false;
    }

    /*if (IWFObject.IsNullOrEmpty(nuri)) {
      this.m_othersavebtn = false;
    } else {
      let off = nuri.lastIndexOf('/');
      if (off > 0) nuri = nuri.substr(0, off);
      m_othersavebtn.TargetName = "_blank";
      m_othersavebtn.NavigateUri = new Uri(String.Format("{0}/ExResources/{1}", nuri, fname), UriKind.Absolute);
    }*/
  }

  public DownloadLocalResource(): void {
    try {
      let blob = new Blob([atob(this.m_document.TmpDocumentB64)], { type: 'application/octet-stream' });
      let slnk = document.createElement('a');
      let issaf = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
      if (issaf) slnk.setAttribute('target', '_blank');
      slnk.setAttribute('href', URL.createObjectURL(blob));
      slnk.setAttribute('download', this.m_document.TmpFileName);
      slnk.style.visibility = 'hidden';
      document.body.appendChild(slnk);
      slnk.click();
      document.body.removeChild(slnk);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private PrepareDownloadCompleted(dinf: Array<DownloadInfo>): void {
    if (dinf.length > 0) {
      let inf = dinf[0];
      this.m_othersavebtn = true;
      this.m_otherlink = this.m_data_srv.ServiceURL + '/GETRES/' + inf.signature + '/' + inf.fname;
      this.m_busy_text = '';
    }
  }

  /*private SetupUserFields(): void {
    int ii;

    m_edittextctx.ClearUnusedFields();

    string fcd = String.Format("_{0}", FIELD_DOCNAME);
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsDocName"), EdtDocFieldType.TEXT, m_document.Name, null, null, EdtFldViewMode.PARENT);

    fcd = String.Format("_{0}", FIELD_DOCDESC);
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsDescr"), EdtDocFieldType.TEXT, m_document.Description, null, null, EdtFldViewMode.PARENT);

    fcd = String.Format("_{0}", FIELD_DOCNUM);
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsDocNo"), EdtDocFieldType.TEXT, m_document.DocNum, null, null, EdtFldViewMode.PARENT);

    fcd = String.Format("_{0}", FIELD_DOCDATE);
    string strval = (m_document.DocDate == null) ? "" : m_document.DocDate.Value.ToShortDateString();
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsDocDate"), EdtDocFieldType.DATETIME, strval, null, null, EdtFldViewMode.PARENT);

    fcd = String.Format("_{0}", FIELD_DOCENTERDATE);
    strval = (m_document.EnterDate == null) ? "" : m_document.EnterDate.Value.ToShortDateString();
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsEnterDate"), EdtDocFieldType.DATETIME, strval, null, null, EdtFldViewMode.PARENT);

    WFUser usr = (m_document.UserID == m_document.Session.User.ID) ? m_document.Session.User : m_document.Session.Users[m_document.UserID];
    fcd = String.Format("_{0}", FIELD_OWNERCODE);
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsOwnerCode"), EdtDocFieldType.TEXT, usr.Name, null, null, EdtFldViewMode.DISABLED);

    fcd = String.Format("_{0}", FIELD_OWNERNAME);
    m_edittextctx.UpdateField(fcd, App.GetString("wDocViewer_userFieldsOwnerName"), EdtDocFieldType.TEXT, usr.UserName, null, null, EdtFldViewMode.DISABLED);
    if (m_document.ClassID > 0)
    {
        for (ii = 0; ii < m_document.AllAttributes.Count; ii++)
        {
            WFClassAttrib atr = m_document.AllAttributes[ii];

            string sval = m_document[atr.Name];
            if (sval == null) sval = "";

            if (atr.Type == WFFieldType.TYPE_CUSTOM)
            {
                string[] vsval = sval.Split(new string[] { "<!>" }, StringSplitOptions.None);

                string ustr = atr.Name.ToUpper();
                fcd = String.Format("^{0}_CODE", ustr);
                string dsc = (String.IsNullOrEmpty(atr.Description)) ? ustr : atr.Description;
                string desc = String.Format("{0} {1}", App.GetString("wDocViewer_userFieldsCode"), dsc);
                m_edittextctx.UpdateField(fcd, desc, EdtDocFieldType.TEXT, (vsval.Length > 0) ? vsval[0] : "", null, null, EdtFldViewMode.DISABLED);

                fcd = String.Format("^{0}_NAME", ustr);
                desc = String.Format("{0} {1}", App.GetString("wDocViewer_userFieldsName"), dsc);
                m_edittextctx.UpdateField(fcd, desc, EdtDocFieldType.TEXT, (vsval.Length > 1) ? vsval[1] : "", null, null, EdtFldViewMode.DISABLED);
            }
            else
            {
                fcd = String.Format("^{0}", atr.Name.ToUpper());

                EdtFldViewMode edtmd = (m_document.CanEdit(atr) && !m_readonly) ? EdtFldViewMode.ENABLED : EdtFldViewMode.DISABLED;

                switch (atr.Type)
                {
                    case WFFieldType.TYPE_DATETIME:
                        if (sval.Length > 0) sval = DateTime.ParseExact(sval, WFDocumentAdp.DATEFORMATS, CultureInfo.InvariantCulture, DateTimeStyles.None).ToShortDateString();
                        m_edittextctx.UpdateField(fcd, atr.Description, EdtDocFieldType.DATETIME, sval, null, null, edtmd);
                        break;
                    case WFFieldType.TYPE_FLOAT:
                        m_edittextctx.UpdateField(fcd, atr.Description, EdtDocFieldType.FLOAT, sval, null, null, edtmd);
                        break;
                    case WFFieldType.TYPE_INT:
                        m_edittextctx.UpdateField(fcd, atr.Description, EdtDocFieldType.INT, sval, null, null, edtmd);
                        break;
                    case WFFieldType.TYPE_SYSDICT:
                        EdtDocFieldType tp = EdtDocFieldType.TEXT;
                        Dictionary<string, string> vv = null;

                        if (atr.CompaniesDictsID.ContainsKey(m_document.CompanyID))
                        {
                            int did = atr.CompaniesDictsID[m_document.CompanyID];
                            WFCompany cmp = m_document.Session.Companies[m_document.CompanyID];
                            foreach (WFCompanyDict dc in cmp.Dictionaries)
                            {
                                if (dc.ID == did)
                                {
                                    tp = (atr.AllowCustomValue) ? EdtDocFieldType.FCOLLECTION : EdtDocFieldType.COLLECTION;
                                    vv = dc.Values;
                                    break;
                                }
                            }
                        }

                        m_edittextctx.UpdateField(fcd, atr.Description, tp, sval, vv, null, edtmd);
                        break;
                    case WFFieldType.TYPE_TEXT:
                        m_edittextctx.UpdateField(fcd, atr.Description, EdtDocFieldType.TEXT, sval, null, null, edtmd);
                        break;
                    case WFFieldType.TYPE_USRDICT:
                        m_edittextctx.UpdateField(fcd, atr.Description, (atr.AllowCustomValue) ? EdtDocFieldType.FCOLLECTION : EdtDocFieldType.COLLECTION, sval, atr.ValidValues, null, edtmd);
                        break;
                    case WFFieldType.TYPE_INTDICT:
                        if (m_document.Session.Dictionaries.ContainsKey(atr.DictionaryID))
                        {
                            WFDocumentValidValues dvv = m_document.GetInteractiveDictDocMapper(m_curdocintmap, atr.DictionaryID);
                            m_edittextctx.UpdateField(fcd, atr.Description, (atr.AllowCustomValue) ? EdtDocFieldType.FCOLLECTION : EdtDocFieldType.COLLECTION, sval, null, dvv, edtmd);
                        }
                        break;
                    case WFFieldType.TYPE_STATICDICT:
                        if (m_document.Session.StaticDictionaries.ContainsKey(atr.DictionaryID))
                        {
                            WFDictionary dcc = m_document.Session.StaticDictionaries[atr.DictionaryID];
                            m_edittextctx.UpdateField(fcd, atr.Description, (atr.AllowCustomValue) ? EdtDocFieldType.FCOLLECTION : EdtDocFieldType.COLLECTION, sval, dcc.ValidValues, null, edtmd);
                        }
                        break;
                }
            }
        }
    }
  }*/

  private FreeImageSource(): void {
    //TODO
  }

  private SetCurrentPage(): void {
    let ii: number;

    this.m_curcmmadp = null;
    this.RefreshCurEntries();
    this.m_cmmsctx_items.splice(0, this.m_cmmsctx_items.length);


    let pages_c: number = this.m_document.GetPagesCount();
    if (this.m_document.TmpDocumentB64 == null) {
      this.SetContentType(CONTENTTYPE.TYPE_UNKNOWN);

      if (this.m_cur_page < pages_c) {
        let pg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);
        this.m_curr_rotate = pg.Rotation;

        this.m_comments_items.splice(0, this.m_comments_items.length);

        for (ii = 0; ii < pg.Comments.length; ii++) {
          let cmm: WFPageComment = pg.Comments[ii];
          let cadp: WFCommentAdp = new WFCommentAdp(cmm, this.m_document, this.m_strdata_srv);

          let usr: WFUser = (cmm.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(cmm.UserID);

          this.m_cmmsctx_items.push({
            region: cadp,
            infoowner: usr.FriendlyName // (usr.UserName !== '') ? usr.UserName : usr.Name
          }); //AddCommentAdp2(cadp);

          let adp: CommentRegionSpec = {
            region: cadp,
            isexpanded: true,
            infoowner: usr.FriendlyName,
            background: usr.CommentColor,
            allowmove: cadp.CanMoveResize
          };

          this.m_comments_items.push(adp);
        }

        this.FillPageTextRegions();

        let mww = this.m_imagectx.nativeElement.clientWidth;
        let mhh = this.m_imagectx.nativeElement.clientHeight;

        if (this.m_prevrequest) {
          if (this.m_cache_srv.Config.InitPrevScale > 0) {
            let scale = this.m_cache_srv.Config.InitPrevScale / 100;
            mww = Math.floor(mww * scale);
            mhh = Math.floor(mhh * scale);
          }
        }

        this.GetResourcePreview(pg.ResourcesID, pg.ResourcesPageNum, mww, mhh);
      } else {
        this.FreeImageSource();
      }

      this.SetCommentVisibilityMode();

      this.m_actual_page = this.m_pages[this.m_cur_page];
    } else {
      this.TopBarVisibility = false;

      //this.m_textctx = Visibility.Collapsed;
      this.m_imgcontainer = false;
      this.m_otherctx = false;
      this.m_edittextctx = false;
      this.m_htmltplctx = false;
      this.m_htmlctx= false;
      if (this.m_htmltplcmpref != null) {
        this.m_htmltplcmpref.destroy();
        this.m_htmltplcmpref = null;
      }

      this.FreeImageSource();
      //CrossUtils.GetBlocks(m_textctx).Clear();

      switch (this.m_document.TmpType) {
        case TempCtxType.OTHER:
          this.m_otherctx = true;
          this.SetContentType(CONTENTTYPE.TYPE_UNKNOWN);
          this.m_edittextctx_html = '';
          this.m_edittextctx_enable = false;
          //this.m_lastresext = m_document.TmpExtension;

          this.SetResourceInfo(this.m_document.TmpFileName,
            this.m_document.TmpExtension,
            this.m_document.TmpUpdateTime,
            this.m_document.TmpDocumentB64Len, null, 0);
          break;

        case TempCtxType.HTML:
          this.m_edittextctx = true;
          this.SetContentType(CONTENTTYPE.TYPE_EDITABLE);
          //this.SetupViewMode();
          this.m_edittextctx_html = decodeURIComponent(escape(atob(this.m_document.TmpDocumentB64)));
          this.m_edittextctx_enable = this.m_document.CanEditContext;
          break;
      }

      if (this.m_cur_page < pages_c) {
        let pg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);
        for (ii = 0; ii < pg.Comments.length; ii++) {
          let cmm: WFPageComment = pg.Comments[ii];
          let usr: WFUser = (cmm.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(cmm.UserID);
          let cadp: WFCommentAdp = new WFCommentAdp(cmm, this.m_document, this.m_strdata_srv);
          this.m_cmmsctx_items.push({
            region: cadp,
            infoowner: usr.FriendlyName
          }); //AddCommentAdp2(cadp);
        }
      }

      //this.SetupUserFields();
    }

    this.m_prevrequest = false;
    this.m_findtextregion = 0;

    if (this.OnPageChanged != null)
      this.OnPageChanged(this, this.m_cur_page);
  }

  //

  private GetResourcePreview(resid: number, pagenum: number, mwidth: number, mheight: number): void {
    let nrch: ResourceCache = null;

    if (this.m_maxcachelen > 0) {
      let fch: ResourceCache = null;
      let ii = 0;

      while (ii < this.m_rescache.length) {
        let rch: ResourceCache = this.m_rescache[ii];
        if (rch.ResID === resid && rch.PageNum === pagenum) {
          this.m_rescache.splice(ii, 1);
          if (rch.MWidth >= mwidth) fch = rch;
          break;
        }
        ii++;
      }

      nrch = new ResourceCache(resid, pagenum, mwidth);
      if (fch != null) {
        this.GetResourcePreviewCompleted(fch.Cache, nrch);
        return;
      }
    }

    let rq: GetResourcePreviewPrms = {
      sid: this.m_cache_srv.SessionID,
      mode: 0,
      resid: resid,
      pagenum: pagenum,
      mwidth: mwidth,
      mheight: 0,
      quality: 30
    };

    const self = this;
    this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
    this.m_data_srv.getResourcePreview(rq, this.m_onerrorevh, (dta) => {
      self.GetResourcePreviewCompleted(dta, nrch);
    });
  }

  //
  private FocusField2(ufid: string, ln: LineSpec): boolean {
    let mp: Map<string, ViewContainerRef>;
    mp = (ln == null) ? this.m_afmap : ln.afmap;

    if (mp.has(ufid)) {
      let ele = mp.get(ufid);
      ele.element.nativeElement.focus();

      //let aele = <any>ele;      
      if (ele.injector) {
        let cobj= ele.injector.get(ComboboxadpComponent, null);
        if (cobj != null) { 
          let cmp = <ComboboxadpComponent>cobj; 
          cmp.Focus();
          return true;
        }

        let lobj= ele.injector.get(LongTextAdpComponent, null);
        if (lobj != null) { 
          let cmp = <LongTextAdpComponent>lobj; 
          cmp.Focus();
          return true;
        }

        let dobj= ele.injector.get(DatepickeradpComponent, null);
        if (dobj != null) {
          let cmp = <DatepickeradpComponent>dobj; 
          cmp.Focus();
          return true;
        }

        let aobj= ele.injector.get(AutoCompleteTextBoxComponent, null);
        if (aobj != null) {
          let cmp = <AutoCompleteTextBoxComponent>aobj;
          cmp.Focus();
          return true;
        }
      }

      return true;
    }

    return false;
  }

  private CreateHtmlTplPreview(dta: string): void {
    const self = this;
    const cmpClass = class RuntimeComponent {
      myjson = JSON;
      Doc = self.m_document;
      Lines = self.m_lines;
      Columns = self.m_columns;
      HasUser = (lista: Array<AuthorizationInfo>, userid: number): boolean => {
        if (lista && lista.length > 0) {
          for (let itm of lista) {
            if (itm.user_id === userid) {
              return true;
            }
          }
        }
        return false;
      };
      GetSignatureImg = (uid: number): string => {
        let usr: WFUser = null;
        if (uid === self.m_cache_srv.User.ID) {
          usr = self.m_cache_srv.User;
        } else {
          if (!self.m_cache_srv.Users.has(uid)) return '';
          usr = self.m_cache_srv.Users.get(uid);
        }
        return (usr.SignatureB64 == null) ? '' : 'data:image/jpeg;base64,' + usr.SignatureB64;
      };
      Slownie = (liczba: number): string => {
        let wynik = '';
        let znak = '';
        let grosze = '';
        let currency = '';
        const jednosci = ['', ' jeden', ' dwa', ' trzy', ' cztery', ' pięć', ' sześć', ' siedem', ' osiem', ' dziewięć'];
        const nascie = ['', ' jedenaście', ' dwanaście', ' trzynaście', ' czternaście', ' piętnaście', ' szesnaście', ' siedemnaście', ' osiemnaście', ' dziewietnaście'];
        const dziesiatki = ['', ' dziesięć', ' dwadzieścia', ' trzydzieści', ' czterdzieści', ' pięćdziesiąt', ' sześćdziesiąt', ' siedemdziesiąt', ' osiemdziesiąt', ' dziewięćdziesiąt'];
        const setki = ['', ' sto', ' dwieście', ' trzysta', ' czterysta', ' pięćset', ' sześćset', ' siedemset', ' osiemset', ' dziewięćset'];
        const grupy = [
          ['', '', ''],
          [' tysiąc', ' tysiące', ' tysięcy'],
          [' milion', ' miliony', ' milionów'],
          [' miliard', ' miliardy', ' miliardów'],
          [' bilion', ' biliony', ' bilionów'],
          [' biliard', ' biliardy', ' biliardów'],
          [' trylion', ' tryliony', ' trylionów']];

        if (!isNaN(liczba)) {

          currency = ' zł.';
          const dpart = Math.round((Math.abs(liczba) - Math.floor(Math.abs(liczba))) * 100);
          grosze = ' ' + dpart.toString() + '/100';
          if (liczba === 0) {
            wynik = 'zero';
          }
          if (liczba < 0) {
            znak = 'minus';
            liczba = -liczba;
          }

          let g = 0;
          while (liczba > 0) {
            const s = Math.floor((liczba % 1000) / 100);
            let n = 0;
            let d = Math.floor((liczba % 100) / 10);
            let j = Math.floor(liczba % 10);
            if (d === 1 && j > 0) {
              n = j;
              d = 0;
              j = 0;
            }
            let k = 2;
            if ((j === 1) && ((s + d + n) === 0)) {
              k = 0;
            }
            if (j === 2 || j === 3 || j === 4) {
              k = 1;
            }
            if (s + d + n + j > 0) {
              wynik = setki[s] + dziesiatki[d] + nascie[n] + jednosci[j] + grupy[g][k] + wynik;
            }
            g++;
            liczba = Math.floor(liczba / 1000);
          }
        }

        return znak + wynik + currency + grosze;
      };
      FocusField = (ln: LineSpec, fid: string): boolean => {
        fid = fid.toUpperCase();

        let ntabid = (ln == null) ? 0 : 1;
        if (self.Infobarstate !== true || self.Selected_tab !== ntabid) {
          self.m_afvchecked = [fid, ln];
          self.Infobarstate = true;
          self.Selected_tab = ntabid;
          return true;
        }

        //let sz= self.m_afmap.size;
        //self.m_global_srv.showInfo(BUTTONSTYPE.OK, IWFObject.Format('{0} - {1}', lid, fid));
        self.m_afvchecked = null;
        return self.FocusField2(fid, ln);
      };
    };

    /*let metadata = {
      selector: `app-dynamic`,
      template: dta
    };*/

    //wyciecie stylów
    let strstyles = '';

    let styles = dta.match(/<style scoped>([^<]+)<\/style>/g);
    if (styles != null) {
      for (let stl of styles) {
        let off = stl.indexOf('>') + 1;
        let loff = stl.lastIndexOf('<') - 1;
        if (strstyles.length > 0) strstyles += ' ';
        strstyles += stl.substr(off, loff - off).trim();
      }
    }

    this.m_actstyles = strstyles;
    const decoratedCmp = Component({ template: dta })(cmpClass);

    //@NgModule({ imports: [CommonModule], declarations: [decoratedCmp] })
    //class RuntimeComponentModule { }
    const tmpModule = NgModule({ imports: [CommonModule], declarations: [decoratedCmp] })(class {
    });

    let module: ModuleWithComponentFactories<any> = this.m_compiler.compileModuleAndAllComponentsSync(tmpModule);//RuntimeComponentModule);
    let fact = module.componentFactories.find(f => f.componentType === decoratedCmp);

    if (this.m_htmltplcmpref != null) {
      this.m_htmltplcmpref.destroy();
      this.m_htmltplcmpref = null;
    }

    this.m_htmltplcmpref = this.m_htmltplcontainer.createComponent(fact);
  }

  //

  private CreateHtmlPreview(dta: string): void {
  
  }

  private GetResourcePreviewCompleted(dta: any, rch: ResourceCache): void {
    let ii: number;

    try {
      if (this.m_maxcachelen > 0) {
        //obsluga cache
        rch.Cache = dta;
        this.m_rescache.push(rch);
        if (this.m_rescache.length > this.m_maxcachelen) {
          this.m_rescache.splice(0, 1);
        }
      }

      let panels: boolean;
      let curpg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);

      let cxtp: number = dta.type;
      if (cxtp < 0) {
        this.m_htmltplctx = false;
        this.m_htmlctx = false;
        this.m_previewhtml= '';
        this.m_edittextctx = false;
        this.m_imgcontainer = false;

        this.m_otherctx = true;
        panels = false;
        this.SetContentType(CONTENTTYPE.TYPE_UNKNOWN);
        //CrossUtils.GetBlocks(m_textctx).Clear();
        this.FreeImageSource();
        this.m_edittextctx_html = '';
        this.m_edittextctx_enable = false;

        let fname: string = dta.fname;
        let ext: string = dta.extt;
        //this.m_lastresext = ext;

        let utm: Date = IWFObject.ParseDate(dta.updateat);
        let dlen: number = dta.llength;

        this.SetResourceInfo(fname, ext, utm, dlen, '', curpg.ResourcesID);
      } else {
        //m_otherctx.Visibility = Visibility.Collapsed;

        switch (cxtp) {
          case 0:
            panels = false;
            this.m_imgcontainer = false;
            //m_textctx.Visibility = Visibility.Collapsed;
            this.m_edittextctx = false;
            this.m_htmltplctx = false;
            this.m_htmlctx= false;
            this.m_otherctx = false;

            this.SetContentType(CONTENTTYPE.TYPE_UNKNOWN);
            //CrossUtils.GetBlocks(m_textctx).Clear();
            this.FreeImageSource();
            this.m_edittextctx_html = '';
            this.m_edittextctx_enable = false;
            this.m_previewhtml= '';
            break;

          case 1:
          case 2:
            //pdf,bmp
            panels = true;
            //m_textctx.Visibility = Visibility.Collapsed;
            this.m_edittextctx = false;
            this.m_htmltplctx = false;
            this.m_htmlctx= false;
            this.m_imgcontainer = true;
            this.m_otherctx = false;

            this.m_cur_image_width = dta.width;
            this.m_cur_image_height = dta.height;
            this.m_scale = dta.scale - (dta.scale * 0.02);

            this.FreeImageSource();

            this.m_imgcontainer_img = 'data:image/jpeg;base64,' + dta.image;
            //m_sanitizer.bypassSecurityTrustResourceUrl(

            /*BitmapImage img = new BitmapImage();
            img.CreateOptions = BitmapCreateOptions.IgnoreImageCache;
            MemoryStream mstr2= CrossUtils.GetMemoryStream(e.Result, off);
            using(mstr2)
            {
                img.SetSource(mstr2);
            }
            m_image.Source = img;

            m_image.RenderTransformOrigin = new Point(0.5, 0.5);
            RotateTransform rtf = new RotateTransform();
            rtf.Angle = curpg.Rotation;
            m_image.RenderTransform = rtf;

            Rect rin = new Rect(0, 0, img.PixelWidth, img.PixelHeight);
            rin = rtf.TransformBounds(rin);*/

            this.m_image_width = this.m_cur_image_width * this.m_scale;
            this.m_image_height = this.m_cur_image_height * this.m_scale;

            switch (cxtp) {
              case 1:
                this.SetContentType(CONTENTTYPE.TYPE_PDF);
                //m_imgcontainer.MinWidth = m_imgcontainer.MaxWidth = m_imgcontainer.Width = rin.Width;
                //m_imgcontainer.MinHeight = m_imgcontainer.MaxHeight = m_imgcontainer.Height = rin.Height;
                break;

              case 2:
                this.SetContentType(CONTENTTYPE.TYPE_IMAGE);
                /*if (m_scale < 1.0)
                {
                    m_imgcontainer.MinWidth = m_imgcontainer.MaxWidth = m_imgcontainer.Width = rin.Width;
                    m_imgcontainer.MinHeight = m_imgcontainer.MaxHeight = m_imgcontainer.Height = rin.Height;
                }
                else
                {
                    m_imgcontainer.MinWidth = m_imgcontainer.MaxWidth = m_imgcontainer.Width = rin.Width * m_scale;
                    m_imgcontainer.MinHeight = m_imgcontainer.MaxHeight = m_imgcontainer.Height = rin.Height * m_scale;
                }*/
                break;
            }

            /*double mww = (m_imgcontainer.MinWidth > m_imgcontainer.MinHeight) ? m_imgcontainer.MinWidth : m_imgcontainer.MinHeight;
            m_imgbrd.Width = mww;
            m_imgbrd.Height = mww;*/

            //CrossUtils.GetBlocks(m_textctx).Clear();
            this.m_edittextctx_html = '';
            this.m_edittextctx_enable = false;
            this.m_previewhtml= '';
            break;

          /*case 3:
            //tekst
            panels = false;
            m_textctx.Visibility = Visibility.Visible;
            m_edittextctx.Visibility = Visibility.Collapsed;
            m_imagectx.Visibility = Visibility.Collapsed;

            ContentType = CONTENTTYPE.TYPE_TEXTHTML;
            FreeImageSource();
            m_edittextctx.Clear();
            m_textctx.IsReadOnly = !(m_document.CanEdit(null) && !m_readonly);
            m_textctx.Selection.Text = IWFObject.DeserializeString(mstr);
            break;*/

          case 3:
            //edytowalny dokument
            panels = false;
           
            this.m_edittextctx = true;
            this.m_htmltplctx = false;
            this.m_htmlctx= false;
            this.m_imgcontainer = false;
            this.m_otherctx = false;

            this.SetContentType(CONTENTTYPE.TYPE_EDITABLE);
            this.FreeImageSource();

            //this.SetupViewMode();
            this.m_edittextctx_html = dta.image;
            this.m_edittextctx_enable = this.m_document.CanEditContext;
            this.m_previewhtml= '';
            //this.SetupUserFields();
            break;

          case 5: 
            //dokument nieedytowalny - jako szablon
            panels = false;

            this.m_edittextctx = false;
            this.m_htmltplctx = true;
            this.m_htmlctx= false;
            this.m_imgcontainer = false;
            this.m_otherctx = false;

            this.SetContentType(CONTENTTYPE.TYPE_HTMLTEMPLATE);
            this.FreeImageSource();

            //this.SetupViewMode();

            this.CreateHtmlTplPreview(dta.image);
            //this.SetupUserFields();

            this.m_previewhtml= '';
            break;

          case 6:
            //DM 06112020
            //podglad wiadomosci email
            panels = false;

            this.m_edittextctx = false;
            this.m_htmltplctx = false;
            this.m_htmlctx= true;
            this.m_imgcontainer = false;
            this.m_otherctx = false;

            this.SetContentType(CONTENTTYPE.TYPE_HTML);
            this.FreeImageSource();

            this.m_previewhtml= IWFObject.RemoveHRefs(dta.image);
            break;

          default:
            panels = false;
            break;
        }

        let iscale = Math.floor(this.m_scale * 100.0);
        this.SetZoomInfo(iscale);
      }

      if (!panels && this.m_document.GetPagesCount() > 1) panels = true;
      this.TopBarVisibility = panels;

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }

  //

  private SetZoomInfo(sel: number): void {
    let start = 25;
    let step = 15;
    let selid = -1;
    let cbi: ComboIValueDesc;

    this.m_pagezoom.splice(0, this.m_pagezoom.length);

    while (start < 300) {
      let actrow = this.m_pagezoom.length;

      cbi = { val: start, desc: start.toString() + '%' };
      this.m_pagezoom.push(cbi);

      if (start === sel) {
        selid = actrow;
      } else {
        if (sel > start && sel < (start + step)) {
          selid = this.m_pagezoom.length;
          cbi = { val: sel, desc: sel.toString() + '%' };
          this.m_pagezoom.push(cbi);
        }
      }

      start += step;
    }

    if (selid < 0) {
      selid = this.m_pagezoom.length;
      cbi = { val: sel, desc: sel.toString() + '%' };
      this.m_pagezoom.push(cbi);
    }

    this.m_actual_pagezoom = this.m_pagezoom[selid].val;
  }

  //

  private FillPageTextRegions(): void {
    this.m_textregions_items.splice(0, this.m_textregions_items.length);
    if (this.m_selocrrs != null) {
      if (this.m_cur_page < this.m_selocrrs.Pages.length) {
        this.m_textregionpage = this.m_selocrrs.Pages[this.m_cur_page];
        for (let trg of this.m_textregionpage.Regions) {
          this.m_textregions_items.push({ region: trg, visible: true });
        }
      }
    }
  }

  public GetTextRegionStyle(trg: TextRegionSpec): any {
    return {
      'left': (((trg.region.PosX / this.m_textregionpage.ImgWidth) * this.m_image_width) | 0).toString() + 'px',
      'top': ((((trg.region.PosY - 4) / this.m_textregionpage.ImgHeight) * this.m_image_height) | 0).toString() + 'px',
      'width': ((((trg.region.Width + 8) / this.m_textregionpage.ImgWidth) * this.m_image_width) | 0).toString() + 'px',
      'height': ((((trg.region.Height + 8) / this.m_textregionpage.ImgHeight) * this.m_image_height) | 0).toString() + 'px'
    };
  }

  public GetCommentRegionStyle(cmm: CommentRegionSpec): any {
    return {
      'left': ((cmm.region.PosX * this.m_scale) | 0).toString() + 'px',
      'top': ((cmm.region.PosY * this.m_scale) | 0).toString() + 'px',
      'width': (cmm.isexpanded) ? ((cmm.region.Width * this.m_scale) | 0).toString() + 'px' : '16px',
      'height': (cmm.isexpanded) ? ((cmm.region.Height * this.m_scale) | 0).toString() + 'px' : '16px'
    };
  }

  public FormatCommentDate(dta: Date): string {
    let str = IWFObject.Format('{0} godz.{1}', this.m_datep.transform(dta, 'yyyy-MM-dd'), this.m_datep.transform(dta, 'HH:mm'));
    return str;
  }

  public EraseComment(cmm: CommentRegionSpec): void {
    try {
      if (!this.m_readonly) {
        this.DeleteComment(cmm.region);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public AddNewComment(): void {
    try {
      if (this.m_document != null && !this.m_readonly) {
        let pages_c = this.m_document.GetPagesCount();
        if (this.m_cur_page < pages_c) {
          let pg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);

          let pw: number, ph: number;
          if (this.m_document.TmpDocumentB64 == null) {
            ph = this.m_cur_image_width * 0.15;
            pw = this.m_cur_image_height * 0.15;
          } else {
            pw = 300.0;
            ph = 200.0;
          }

          let pc: WFPageComment = WFPageComment.Create(this.m_cache_srv.User.ID, 0, 0, pw, ph);
          pg.Comments.push(pc);

          let cadp: WFCommentAdp = new WFCommentAdp(pc, this.m_document, this.m_strdata_srv);
          this.m_cmmsctx_items.push({
            region: cadp,
            infoowner: this.m_cache_srv.User.FriendlyName
          });

          if (this.m_document.TmpDocumentB64 == null) {
            let adp: CommentRegionSpec = {
              region: cadp,
              isexpanded: true,
              infoowner: this.m_cache_srv.User.FriendlyName,
              background: this.m_cache_srv.User.CommentColor,
              allowmove: cadp.CanMoveResize
            };

            this.m_comments_items.push(adp);
          }

          this.m_curcmmadp = cadp;
          this.RefreshCurEntries();

          this.m_document.IsModified = true;
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private DeleteComment(cma: WFCommentAdp): void {
    for (let ii = 0; ii < this.m_cmmsctx_items.length; ii++) {
      if (this.m_cmmsctx_items[ii].region === cma) {
        let pg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);
        if (pg.Comments[ii].ID === 0) //-2
        {
          this.m_comments_items.splice(ii, 1);
          this.m_cmmsctx_items.splice(ii, 1);
          pg.Comments.splice(ii, 1);
          if (cma === this.m_curcmmadp) {
            this.m_curcmmadp = null;
            this.RefreshCurEntries();
          }
          return;
        }

        break;
      }
    }

    this.m_global_srv.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, this.m_strdata_srv.getStr('errCannotDelComment'));
  }

  public RemoveSelectedComment(): void {
    try {
      if (this.m_curcmmadp != null && !this.m_readonly) {
        this.DeleteComment(this.m_curcmmadp);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private RefreshCurEntries(): void {
    this.m_entries_items.splice(0, this.m_entries_items.length);
    this.m_newentry = '';
    this.m_newentry_err = false;
    if (this.m_curcmmadp != null) {
      this.m_newentry = ((this.m_curcmmadp.IsNewComment) && (this.m_curcmmadp.GetEntriesCount() > 0)) ? this.m_curcmmadp.GetEntry(0).Content : '';

      let ecount: number = this.m_curcmmadp.GetEntriesCount();
      for (let ii = 0; ii < ecount; ii++) {
        let cen: WFCommentEntry = this.m_curcmmadp.GetEntry(ii);
        let usr = (cen.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(cen.UserID);

        let uname = 'XX';
        let initialsar = usr.UserName.toUpperCase().split(/\W+/);
        if (initialsar.length > 0) {

          uname = initialsar[0][0] + 'X';
          if (initialsar.length > 1) {
            uname = initialsar[0][0] + initialsar[1][0];
          }
        }

        this.m_entries_items.push({
          entry: cen,
          infodate: this.FormatCommentDate(cen.CreatedAt),
          infoowner: usr.Name.toUpperCase(),
          ownerinitials: uname
        });
      }

      if (this.m_curcmmadp.ID === 0) {
        this.m_cmmdelbtn = true;
        return;
      }
    }

    this.m_cmmdelbtn = false;
  }

  public SelectComment(cme: CommentRowSpec): void {
    try {
      if (this.m_curcmmadp !== cme.region) {
        this.m_curcmmadp = cme.region;
        this.RefreshCurEntries();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public AddNewEntry(): void {
    try {
      let nctx = this.m_newentry.trim();
      if (this.m_curcmmadp != null && nctx.length > 0) {
        this.m_curcmmadp.AddNewEntry(nctx);
        this.RefreshCurEntries();
        this.m_newentry_err = false;
      } else {
        this.m_newentry_err = true;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //zmiana pozycji
  private ShowComment(sender: CommentRegionSpec): void {
    this.Infobarstate = true;
    if (this.m_curcmmadp !== sender.region) {
      this.m_curcmmadp = sender.region;
      this.RefreshCurEntries();
      this.SelectTab(5);
    }
  }

  public AddNewCommentEntry(): void {
    this.Infobarstate = true;
    this.SelectTab(5);
    this.AddNewComment();
  }

  //this.m_commmove_comm = null;
  //this.m_commmove_state = MOUSECAPTURESTATE.NONE;
  //this.m_commmove_startpos = null;

  public CommentMouseDown(sender: CommentRegionSpec, event): void {
    try {
      this.m_commmove_comm = sender;
      this.m_commmove_state = MOUSECAPTURESTATE.WAITING;
      this.m_commmove_target = event.currentTarget;

      if (sender.allowmove) {
        //offset globalny
        let offx = 0;
        let offy = 0;
        let pr = this.m_image.nativeElement;
        while (true) {
          offx += pr.offsetLeft - pr.scrollLeft;
          offy += pr.offsetTop - pr.scrollTop;
          if (pr.offsetParent == null) break;
          pr = pr.offsetParent;
        }

        this.m_commmove_offset = { x: offx, y: offy };

        //ustal obszar
        this.m_commmove_dragstatefl = DS_REPOSITION;

        let height = sender.region.Height * this.m_scale;
        if (event.offsetY < BORDERSIZE) {
          this.m_commmove_dragstatefl |= DS_RESIZINGTOP;
        } else {
          if (event.offsetY > (height - BORDERSIZE)) this.m_commmove_dragstatefl |= DS_RESIZINGBOTTOM;
        }

        let width = sender.region.Width * this.m_scale;
        if (event.offsetX < BORDERSIZE) {
          this.m_commmove_dragstatefl |= DS_RESIZINGLEFT;
        } else {
          if (event.offsetX > (width - BORDERSIZE)) this.m_commmove_dragstatefl |= DS_RESIZINGRIGHT;
        }

        this.m_commmove_startpos = { x: event.offsetX, y: event.offsetY };
        this.m_commentmousemovebnd = this.CommentMouseMove.bind(this);
        const self = this;
        this.m_zone.runOutsideAngular(() => {
          window.document.addEventListener('mousemove', self.m_commentmousemovebnd);
        });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CommentMouseUp(): void {
    try {
      if (this.m_commmove_comm != null) {
        if (this.m_commmove_comm.allowmove) window.document.removeEventListener('mousemove', this.m_commentmousemovebnd);

        if (this.m_commmove_state === MOUSECAPTURESTATE.WAITING) {
          this.ShowComment(this.m_commmove_comm);
        }

        this.m_commmove_comm = null;
        this.m_commmove_state = MOUSECAPTURESTATE.NONE;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private CommentMouseMove(event): void {
    try {
      if (this.m_commmove_comm != null) {
        event.preventDefault();

        this.m_commmove_state = MOUSECAPTURESTATE.CAPTURED;
        let revsc = 1.0 / this.m_scale;

        let tx = event.clientX - this.m_commmove_offset.x;
        let ty = event.clientY - this.m_commmove_offset.y;
        let width = this.m_commmove_comm.region.Width * this.m_scale;
        let height = this.m_commmove_comm.region.Height * this.m_scale;

        if (this.m_commmove_dragstatefl === DS_REPOSITION) {
          let left = tx - this.m_commmove_startpos.x;
          let top = ty - this.m_commmove_startpos.y;

          if (left < 0) {
            left = 0;
          } else {
            if ((left + width) > this.m_image_width) left = this.m_image_width - width;
          }

          if (top < 0) {
            top = 0;
          } else {
            if ((top + height) > this.m_image_height) top = this.m_image_height - height;
          }

          this.m_commmove_comm.region.PosX = left * revsc;
          this.m_commmove_comm.region.PosY = top * revsc;
          this.m_render.setStyle(this.m_commmove_target, 'left', left + 'px');
          this.m_render.setStyle(this.m_commmove_target, 'top', top + 'px');
        } else {
          let tmp: number;

          let aposx: number = this.m_commmove_comm.region.PosX * this.m_scale;
          let aposy: number = this.m_commmove_comm.region.PosY * this.m_scale;

          if ((this.m_commmove_dragstatefl & DS_RESIZINGTOP) > 0) {
            tmp = (ty < 0) ? 0 : height + (aposy - ty);
            if (tmp > MINHEIGHT) {
              this.m_commmove_comm.region.PosY = ty * revsc;
              this.m_commmove_comm.region.Height = tmp * revsc;
              this.m_render.setStyle(this.m_commmove_target, 'top', ty + 'px');
              this.m_render.setStyle(this.m_commmove_target, 'height', tmp + 'px');
            }
          } else {
            if ((this.m_commmove_dragstatefl & DS_RESIZINGBOTTOM) > 0) {
              tmp = ((ty > this.m_image_height) ? this.m_image_height : ty) - aposy;
              if (tmp > MINHEIGHT) {
                //m_infoborder.Height = m_infoborder.MinHeight = m_infoborder.MaxHeight = tmp;
                this.m_commmove_comm.region.Height = tmp * revsc;
                this.m_render.setStyle(this.m_commmove_target, 'height', tmp + 'px');
              }
            }
          }

          if ((this.m_commmove_dragstatefl & DS_RESIZINGLEFT) > 0) {
            tmp = (tx < 0) ? 0 : width + (aposx - tx);
            if (tmp > MINWIDTH) {
              //m_infoborder.Width = m_infoborder.MinWidth = m_infoborder.MaxWidth = tmp;
              //ttr.X = pp.X;
              this.m_commmove_comm.region.PosX = tx * revsc;
              this.m_commmove_comm.region.Width = tmp * revsc;
              this.m_render.setStyle(this.m_commmove_target, 'left', tx + 'px');
              this.m_render.setStyle(this.m_commmove_target, 'width', tmp + 'px');
            }
          } else {
            if ((this.m_commmove_dragstatefl & DS_RESIZINGRIGHT) > 0) {
              tmp = ((tx > this.m_image_width) ? this.m_image_width : tx) - aposx;
              if (tmp > MINWIDTH) {
                //m_infoborder.Width = m_infoborder.MinWidth = m_infoborder.MaxWidth = tmp;
                //m_comment.Width = (float)m_infoborder.Width * revsc;
                this.m_commmove_comm.region.Width = tmp * revsc;
                this.m_render.setStyle(this.m_commmove_target, 'width', tmp + 'px');
              }
            }
          }
        }
      }
    } catch (ex) {
    }
  }

  public MouseDown(event): void {
    if (this.m_document != null) {
      if (this.m_cur_page < this.m_document.GetPagesCount()) {

        let rect = this.m_imagectx.nativeElement.getBoundingClientRect();

        let corrx= (this.m_image_width > this.m_cli_width) ? 0 : ((rect.width / 2) - (this.m_image_width / 2)) | 0;
        let corry= (this.m_image_height > this.m_cli_height) ? 0 : ((rect.height / 2) - (this.m_image_height / 2)) | 0;

        let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        let offx = rect.left + scrollLeft - this.m_imagectx.nativeElement.scrollLeft + corrx;
        let offy = rect.top + scrollTop - this.m_imagectx.nativeElement.scrollTop + corry;
        this.m_imagectx_spos = { x: offx, y: offy };

        this.m_addcomment_mode = (this.m_addcomment_mode === ADDCOMMENTMODE.WAITING) ? ADDCOMMENTMODE.WAITING2 : ADDCOMMENTMODE.MOVING;
        this.m_newcommentregion_spos = { x: event.clientX - offx, y: event.clientY - offy };

        this.m_newcommentmousemovebnd = this.NewCommentMouseMove.bind(this);
        const self = this;
        this.m_zone.runOutsideAngular(() => {
          window.document.addEventListener('mousemove', self.m_newcommentmousemovebnd);
        });
      }
    }
  }

  public MouseUp(): void {
    let x1, y1, ax1, ay1: number;

    this.CommentMouseUp();

    try {
      window.document.removeEventListener('mousemove', this.m_newcommentmousemovebnd);

      //sprawdz zanzczenie
      switch (this.m_addcomment_mode) {
        case ADDCOMMENTMODE.ADDING:
          let revsc = 1.0 / this.m_scale;

          let pg: WFDocumentPage = this.m_document.GetPage(this.m_cur_page);

          if (this.m_addcomment_dest === ADDCOMMENTDEST.COMMENT) {
            let ncom: WFPageComment = WFPageComment.Create(this.m_cache_srv.User.ID, this.m_newcommentregion_pos.x * revsc,
              this.m_newcommentregion_pos.y * revsc,
              this.m_newcommentregion_size.x * revsc,
              this.m_newcommentregion_size.y * revsc);
            pg.Comments.push(ncom);

            this.m_comments = true;
            let cadp: WFCommentAdp = new WFCommentAdp(ncom, this.m_document, this.m_strdata_srv);
            //AddCommentAdp2(cadp);

            this.m_cmmsctx_items.push({
              region: cadp,
              infoowner: this.m_cache_srv.User.FriendlyName
            });

            //CommentAdp adp = new CommentAdp(cadp);
            let adp: CommentRegionSpec = {
              region: cadp,
              isexpanded: true,
              infoowner: this.m_cache_srv.User.FriendlyName,
              background: this.m_cache_srv.User.CommentColor,
              allowmove: cadp.CanMoveResize
            };

            this.m_comments_items.push(adp);

            this.ShowComment(adp);

            this.m_document.IsModified = true;
          } else {
            //pobranie wynikow ocr z zaznaczonego obszaru
            x1 = this.m_newcommentregion_pos.x;
            y1 = this.m_newcommentregion_pos.y;
            let x2 = x1 + this.m_newcommentregion_size.x;
            let y2 = y1 + this.m_newcommentregion_size.y;

            let strstr = '';

            for (let adp of this.m_textregions_items) {
              ax1 = (adp.region.PosX / this.m_textregionpage.ImgWidth) * this.m_image_width;
              ay1 = ((adp.region.PosY - 4) / this.m_textregionpage.ImgHeight) * this.m_image_height;
              let ax2 = ax1 + ((adp.region.Width + 8) / this.m_textregionpage.ImgWidth) * this.m_image_width;
              let ay2 = ay1 + ((adp.region.Height + 8) / this.m_textregionpage.ImgHeight) * this.m_image_height;

              //punkt regionu w obszaze zaznaczenia
              if (((ax1 >= x1) && (ax1 <= x2) && (ay1 <= y2) && (ay2 >= y1)) ||
                ((ax2 >= x1) && (ax2 <= x2) && (ay1 <= y2) && (ay2 >= y1)) ||
                ((ay1 >= y1) && (ay1 <= y2) && (ax1 <= x2) && (ax2 >= x1)) ||
                ((ay2 >= y1) && (ay2 <= y2) && (ax1 <= x2) && (ax2 >= x1))) {
                let str = adp.region.Text.trim();
                if (str.length > 0) {
                  if (strstr.length > 0) strstr = strstr + ' ';
                  strstr = strstr + str;
                }
              }
            }

            if (strstr.length > 0) this.CopyToClipboard(strstr);
            //console.log(strbld);
          }

          this.m_newcommentregion = false;
          break;

        case ADDCOMMENTMODE.WAITING2:
          //znajdz region do skopiowania
          x1 = this.m_newcommentregion_spos.x;
          y1 = this.m_newcommentregion_spos.y;

          for (let adp of this.m_textregions_items) {
            ax1 = (adp.region.PosX / this.m_textregionpage.ImgWidth) * this.m_image_width;
            ay1 = ((adp.region.PosY - 4) / this.m_textregionpage.ImgHeight) * this.m_image_height;
            let ax2 = ax1 + ((adp.region.Width + 8) / this.m_textregionpage.ImgWidth) * this.m_image_width;
            let ay2 = ay1 + ((adp.region.Height + 8) / this.m_textregionpage.ImgHeight) * this.m_image_height;

            //punkt regionu w obszaze zaznaczenia
            if ((x1 >= ax1) && (y1 >= ay1) && (x1 <= ax2) && (y1 <= ay2)) {
              this.CopyToClipboard(adp.region.Text.trim());
              break;
            }
          }

          break;

        //case ADDCOMMENTMODE.MOVING2:
        //m_imgcontainer.ReleaseMouseCapture();
        //break;
      }

      let oldmd: ADDMODE = this.AddCommentMode;
      this.m_addcomment_mode = ADDCOMMENTMODE.NONE;
      if (this.OnCommentModeChanged != null) {
        if (this.AddCommentMode !== oldmd) this.OnCommentModeChanged(this, oldmd);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private CopyToClipboard(strstr: string): void {
    //wiocha, ale działa
    let tbx = document.createElement('textarea');
    tbx.style.position = 'fixed';
    tbx.style.left = '0';
    tbx.style.top = '0';
    tbx.style.opacity = '0';
    tbx.value = strstr;

    document.body.appendChild(tbx);
    tbx.focus();
    tbx.select();

    document.execCommand('copy');
    document.body.removeChild(tbx);
  }

  private NewCommentMouseMove(event): void {
    try {
      event.preventDefault();

      let mww: number, mhh: number;
      if (this.m_addcomment_dest === ADDCOMMENTDEST.COMMENT) {
        mww = MINWIDTH;
        mhh = MINHEIGHT;
      } else {
        mww = mhh = 5;
      }

      let pos_x = event.clientX - this.m_imagectx_spos.x;
      let pos_y = event.clientY - this.m_imagectx_spos.y;

      if (this.m_addcomment_mode === ADDCOMMENTMODE.WAITING2) {
        let lx = this.m_newcommentregion_spos.x - pos_x;
        let ly = this.m_newcommentregion_spos.y - pos_y;

        if (Math.abs(lx) >= mww && Math.abs(ly) >= mhh) {
          this.m_addcomment_mode = ADDCOMMENTMODE.ADDING;
          this.m_zone.run(() => {
            this.m_newcommentregion = true;
          });
        }
      }

      if (this.m_addcomment_mode === ADDCOMMENTMODE.ADDING) {
        let left = this.m_newcommentregion_spos.x;
        let top = this.m_newcommentregion_spos.y;
        let width = pos_x - left;
        let height = pos_y - top;

        if (width < 0) {
          left = pos_x;//event.clientX;
          width = this.m_newcommentregion_spos.x - left;
        }

        if (height < 0) {
          top = pos_y;//event.clientY;
          height = this.m_newcommentregion_spos.y - top;
        }

        if (width < mww) width = mww;
        if (height < mhh) height = mhh;

        //console.log( left + 'px ' + top + 'px ' + width + 'px ' + height + 'px');

        this.m_render.setStyle(this.m_newcmmregion.nativeElement, 'left', left + 'px');
        this.m_render.setStyle(this.m_newcmmregion.nativeElement, 'top', top + 'px');
        this.m_render.setStyle(this.m_newcmmregion.nativeElement, 'width', width + 'px');
        this.m_render.setStyle(this.m_newcmmregion.nativeElement, 'height', height + 'px');

        this.m_newcommentregion_pos = { x: left, y: top };
        this.m_newcommentregion_size = { x: width, y: height };
      }
    } catch (ex) {
    }
  }

  //

  private SuggestNewDocName(): void {
    this.m_busy_text = this.m_strdata_srv.getStr('strSuggestDocNameInfo');
    this.m_document.OnSuggestNewDocName = this.m_onsuggestdocname;
    this.m_document.SuggestNewDocName();
  }

  public ClickSuggestDocName(): void {
    try {
      if (this.m_document != null) {
        if (this.m_document.CanEdit(null) && !this.m_readonly) {
          this.SuggestNewDocName();
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //linie

  private ClearDocumentLines(): void {
    this.m_columns.splice(0, this.m_columns.length);

    this.m_lines.splice(0, this.m_lines.length);
    //m_linesgr.Children.Clear();
    //m_linesgr.RowDefinitions.Clear();
    //m_linesgr.ColumnDefinitions.Clear();
    //m_linesbind.Clear();
    this.m_checkkwords = '';

    //
    //m_adderrow.Children.Clear();
    //m_adderrow.RowDefinitions.Clear();
    //m_adderrow.ColumnDefinitions.Clear();
    //
    //m_hdrrow.Children.Clear();
    //m_hdrrow.RowDefinitions.Clear();
    //m_hdrrow.ColumnDefinitions.Clear();
  }

  //

  private CreateColumnDef(atr: WFClassAttrib): number {
    let ret: number;
    if (atr.DefaultWidth > 0) {
      ret = atr.DefaultWidth;
    } else {
      if(atr.DefaultWidth < 0) {
        ret= 0;
      } else {
        switch (atr.Type) {
          case WFFieldType.TYPE_DATETIME:
            ret = 110;
            break;
          case WFFieldType.TYPE_FLOAT:
            ret = 100;
            break;
          case WFFieldType.TYPE_INT:
            ret = 100;
            break;
          case WFFieldType.TYPE_SYSDICT:
            ret = 200;
            break;
          case WFFieldType.TYPE_USRDICT:
            ret = 200;
            break;
          case WFFieldType.TYPE_INTDICT:
            ret = 200;
            break;
          case WFFieldType.TYPE_STATICDICT:
            ret = 200;
            break;
          default:
            ret = 250;
            break;
        }
      }
    }

    return ret;
  }

  private AddLinesColumn(atr: WFClassAttrib, icolid: number): void {
    let ischb: boolean = DocviewerComponent.IsComboBoxAsCheckBox(this.m_cache_srv, this.m_document, atr);
    let colw: number = (ischb) ? 20 : this.CreateColumnDef(atr);

    let colid = this.m_columns.length;
    let issum= ((atr.Type === WFFieldType.TYPE_FLOAT || atr.Type === WFFieldType.TYPE_INT) && (atr.AdderVisibility));

    this.m_columns.push({
      attrib: atr,
      label: (ischb) ? '#' : ((IWFObject.IsNullOrEmpty(atr.Label)) ? atr.Name : atr.Label),
      width: colw,
      colid: icolid,
      issum: issum,
      sumstr: '###',
      sumbck: '',
      comboischb: ischb,
      trigger: null,
      hint: atr.Description
    });

    //Border cl = CreateHeaderCell((String.IsNullOrEmpty(atr.Label)) ? atr.Name : atr.Label, atr);
    //if(addevh) cl.MouseLeftButtonDown += m_opencolppevh;
    //m_hdrrow.Children.Add(cl);
    //Grid.SetRow(cl, 0);
    //Grid.SetColumn(cl, colid);//ii + 1);
    //if (!String.IsNullOrEmpty(atr.Description)) ToolTipService.SetToolTip(cl, atr.Description);
  }

  private AddTriggerColumn(trg: WFClassTrigger): void {
    this.m_columns.push({
      label: '',
      width: 60,
      attrib: null,
      colid: -3,
      issum: false,
      sumstr: null,
      sumbck: '',
      comboischb: false,
      trigger: trg,
      hint: (IWFObject.IsNullOrEmpty(trg.Description)) ? trg.Label : trg.Description
    });
  }

  private setLineStyle(style: string, lnspc: LineSpec): void {
    //reset
    lnspc.backcolor= 'transparent';
    for(let cinf of lnspc.colinfo)
      cinf.backcolor= 'transparent';

    if(style.length > 0) {
      let strv= style.split(';');
      for(let sub of strv) {
        sub= sub.trim();
        if(sub.length > 0) {
          let off= sub.indexOf('=');
          if(off < 0) {
            lnspc.backcolor= sub;
          } else {          
            let cname= sub.substring(0, off).trim().toUpperCase();
            let cval= sub.substring(off+1).trim();
            for(let cinf of this.m_columns) {
              if((cinf.attrib!= null) && (cinf.attrib.Name.toUpperCase()== cname)) {
                lnspc.colinfo[cinf.colid].backcolor= cval;
                break;
              }
            }
          }
        }
      }
    } 
  }

  public SetLineAttrib(val: Object, lnspc: LineSpec, colspc: ColumnSpec): void {
    try {
      if (val instanceof Date) {
        let dta = <Date>val;
        lnspc.line.set(colspc.attrib.Name, this.m_datep.transform(dta, this.m_dateformat));
        lnspc.colinfo[colspc.colid].dateval = dta;
      } else {
        if (typeof val === 'boolean') {
          lnspc.line.set(colspc.attrib.Name, (<boolean>val) ? 'Y' : 'N');
        } else {
          let strval= (val == null) ? '' : val.toString();          
          lnspc.line.set(colspc.attrib.Name, strval);                 
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public GetLineAttrib(lnspc: LineSpec, colspc: ColumnSpec): string {
    let val= lnspc.line.get(colspc.attrib.Name);
    let strval= (val== null) ? '' : val.toString();

    if(lnspc.afmap.has(colspc.attrib.Name)) {
      let ele= lnspc.afmap.get(colspc.attrib.Name).element.nativeElement;
      if(ele.value!= strval) ele.value= strval;
    }
    
    return strval;
  }

  public ParseLineDate(lnspc: LineSpec, colspc: ColumnSpec): Date {
    let ret: Date = null;
    let ps: LinePropSpec = lnspc.colinfo[colspc.colid];
    if (ps.dateval == null) {
      try {
        let str: string = lnspc.line.get(colspc.attrib.Name);
        if (str != null && str.length > 9) {
          let day = IWFObject.ParseInt(str.substr(0, 2));
          let month = IWFObject.ParseInt(str.substr(3, 2));
          let year = IWFObject.ParseInt(str.substr(6, 4));
          ret = new Date(year, month - 1, day);
        }
      } catch (ex) {
        ps.iserror = true;
      }
      ps.dateval = ret;
    } else {
      ret = ps.dateval;
    }
    return ret;
  }

  //
  private OnLinePropertyValueChange(snd: WFDocumentLineAdp, prop: string): void {
    let ii: number;
    try {
      //aktualizacja wszytkich linii
      /*for (ii = 0; ii < m_linesbind.Count; ii++) {
        if (m_document.GetLine(ii) == snd) {
          CtrlBindPrms[] vv = m_linesbind[ii];
          foreach(CtrlBindPrms bnd in vv)
          {
            bnd.UpdateTarget();
          }
          break;
        }
      }

      if (m_bindctrls != null) {
        for (ii = 0; ii < m_bindctrls.Length; ii++) {
          m_bindctrls[ii].UpdateTarget();
        }
      }*/

      if(prop.toUpperCase()== "$STYLE") {
        for(let ln of this.m_lines) {
          if(ln.line == snd) {
            let val= snd.get(prop);
            let strval= (val == null) ? '' : val.toString();  
            this.setLineStyle(strval, ln); 
            break;
          }
        }
      }

      this.RefreshInfoRow2();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private OnLinePropertyValidate(snd: WFDocumentLineAdp, prop: string, isok: boolean): void {
    let ii: number;

    try {
      prop = prop.toUpperCase();

      let colid = -1;
      for (ii = 0; ii < this.m_document.AllColumns.length; ii++) {
        if (this.m_document.AllColumns[ii].Name.toUpperCase() === prop) {
          colid = ii;
          break;
        }
      }

      if (colid >= 0) {
        for (ii = 0; ii < this.m_lines.length; ii++) {
          let ln = this.m_lines[ii];
          if (ln.line === snd) {
            ln.colinfo[colid].iserror = !isok;
            break;
          }
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private AddDocumentLine(adp: WFDocumentLineAdp, cmp: WFCompany, lid: number, cols: Array<WFClassAttrib>): void {
    let ii, ii2: number;

    let lstatus: WFPrmType = this.m_document.CanEditLines(null, adp);
    if (this.m_readonly && lstatus === WFPrmType.CHANGE) lstatus = WFPrmType.VIEW;

    let colinf = new Array<LinePropSpec>(cols.length);
    let style= null;

    for (ii = 0; ii < cols.length; ii++) {
      let att: WFClassAttrib = cols[ii];
      colinf[ii] = {
        iseditable: (this.m_document.CanEditLines(att, adp) === WFPrmType.CHANGE),
        iserror: false,
        dateval: null,
        backcolor: 'transparent'
      };

      if(att.Name.toUpperCase()== "$STYLE")
        style= adp.get(att.Name);
    }

    let inf = '';
    if (adp.UserID > 0) {
      let usr: WFUser = (adp.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(adp.UserID);
      inf = IWFObject.Format(this.m_strdata_srv.getStr('strLineOwnerDescription'), WFDocumentAdp.DateTimeToStr(adp.CreatedAt, this.m_strdata_srv), usr.UserName);
    }

    let exmp = new Array<Array<[ColumnInfo, string]>>();

    let cc = this.m_document.GetOcrResultsCount();
    for (ii = 0; ii < adp.GetRefsCount(); ii++) {
      let ref = adp.GetRef(ii);
      let tbl: WFOcrField = null;

      for (ii2 = 0; ii2 < cc; ii2++) {
        let tor = this.m_document.GetOcrResult(ii2);
        if (tor.ID == ref.DocumentOcrResultID && tor.Fields.length > 0) {
          tbl = tor.Fields.find((t) => t.Type == WFOcrFldType.TABLE);
          break;
        }
      }

      if (tbl != null && tbl != undefined) {
        if (ref.OcrLineNum < tbl.GetRowsCount()) {
          let cc2 = tbl.GetColumnsCount();
          let arrmp = new Array<[ColumnInfo, string]>(cc2);
          for (ii2 = 0; ii2 < cc2; ii2++) {
            let cl = tbl.GetColumn(ii2);
            let ctp = ColumnType.STRING;
            let aln = ColumnAlign.LEFT;
            switch (cl.Type) {
              case WFOcrFldType.NUMBER:
              case WFOcrFldType.CURRENCY:
                ctp = ColumnType.INT;
                aln = ColumnAlign.RIGHT;
                break;
              case WFOcrFldType.DATETIME:
                ctp = ColumnType.DATETIME;
                break;
            }

            arrmp[ii2] = [
              { label: cl.Name, type: ctp, format: null, align: aln },
              tbl.GetValue(cl.Name, ref.OcrLineNum)];
          }
          exmp.push(arrmp);
        }
      }
    }

    let nln:LineSpec= { isselected: false, line: adp, isexpanded: false, lstatus: lstatus, colinfo: colinf, visible: true, addinfo: inf, afmap: new Map<string, ViewContainerRef>(), expmap: exmp, backcolor: 'transparent' };
    this.m_lines.push(nln);

    if(!IWFObject.IsNullOrEmpty(style))
      this.setLineStyle(style, nln);
  }

  private ReloadDocumentLines(): void {
    let ii: number;

    //bez grupowania
    this.m_columns.push({ label: '#', width: 20, attrib: null, colid: -1, issum: false, sumstr: null, sumbck: '', comboischb: false, trigger: null, hint: '' });

    let mthtrg = new Map<number, WFClassTrigger>();

    if (this.m_document.ClassID > 0) {
      let cls2: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
      for (ii = 0; ii < cls2.Triggers.length; ii++) {
        let trg = cls2.Triggers[ii];
        if (trg.Position === 0) {
          this.AddTriggerColumn(trg);
          continue;
        }

        if (trg.Position > 0) mthtrg.set(trg.Position, trg);
      }
    }

    for (ii = 0; ii < this.m_document.AllColumns.length; ii++) {
      let atr: WFClassAttrib = this.m_document.AllColumns[ii];
      this.AddLinesColumn(atr, ii);
      if (mthtrg.has(atr.ID)) this.AddTriggerColumn(mthtrg.get(atr.ID));
    }

    //techniczna
    this.m_columns.push({ label: '', width: 20, attrib: null, colid: -2, issum: false, sumstr: null, sumbck: '', comboischb: false, trigger: null, hint: '' });

    let len: number = this.m_document.GetLinesCount();
    let cmp: WFCompany = (this.m_document.CompanyID > 0) ? this.m_cache_srv.Companies.get(this.m_document.CompanyID) : null;

    this.m_lines.splice(0, this.m_lines.length);
    for (ii = 0; ii < len; ii++) {
      let adp: WFDocumentLineAdp = this.m_document.GetLine(ii);
      adp.OnPropertyValueChange = this.m_linevalchgevh;
      adp.OnPropertyValidation = this.m_linevalidateevh;
      this.AddDocumentLine(adp, cmp, ii, this.m_document.AllColumns);
    }

    this.RefreshInfoRow2();

    this.UncheckHideLines();
  }

  //

  private UncheckHideLines(): void {
    this.m_hideuncheck = false;
    this.m_hidevalidval = false;
  }

  //

  private CheckLineVisibilityState(ln: LineSpec, hideunch: boolean, hideinv: boolean): void {
    let ii: number;
    let vis: boolean;
    if (hideunch && !ln.isselected) {
      vis = false;
    } else {
      if (hideinv) {
        vis = false;

        let cmbcc = 0;

        for (ii = 0; ii < this.m_columns.length; ii++) {
          let cl: ColumnSpec = this.m_columns[ii];
          if ((cl.attrib != null) && (cl.attrib.Type > 3)) {
            cmbcc++;
            if (ln.colinfo[cl.colid].iserror) {
              vis = true;
              break;
            }           
          
            let ucd= cl.attrib.Name.toUpperCase();
            if(ln.afmap.has(ucd)) {
              let ele = <any>ln.afmap.get(ucd);
              if (ele.injector) {
                let inst= ele.injector.get(ComboboxadpComponent, null);
                if (inst != null) {
                  let cmp = <ComboboxadpComponent>inst;// ele._lContainer[0][0];
                  if (cmp.ValueOutOfRange) {
                    vis = true;
                    break;
                  }
                }
              }
            }
          }
        }

        if (cmbcc === 0) vis = true;
      } else {
        vis = true;
      }
    }

    ln.visible = vis;
  }


  public ClearSelSchemaLines(): void {
    try {
      for (let ii = 0; ii < this.m_shmlinesctx.length; ii++) {
        let ln = this.m_shmlinesctx[ii];
        ln.isselected = false;
      }
      this.m_checkrskwords = '';
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ChangeOCRAllLinesSelected(): void {
    try {
      this.m_selallOCRlines = !this.m_selallOCRlines;
      for (let ii = 0; ii < this.m_shmlinesctx.length; ii++) {
        let ln = this.m_shmlinesctx[ii];
        ln.isselected = this.m_selallOCRlines;
      }
      this.m_checkrskwords = '';

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ChangeAllLinesSelected(): void {
    try {
      if (this.m_document.ClassID > 0) {
        this.UncheckHideLines();
        this.m_selalllines = !this.m_selalllines;
        for (let ln of this.m_lines) {
          if (ln.lstatus === WFPrmType.CHANGE) ln.isselected = this.m_selalllines;
          this.CheckLineVisibilityState(ln, false, false);
        }
        this.m_checkkwords = '';
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }


  // public ClearSelLines(): void {
  //   try {
  //     if (this.m_document.ClassID > 0) {
  //       this.UncheckHideLines();
  //       for (let ln of this.m_lines) {
  //         if (ln.lstatus === WFPrmType.CHANGE) ln.isselected = false;
  //         this.CheckLineVisibilityState(ln, false, false);
  //       }
  //       this.m_checkkwords = '';
  //     }
  //   } catch (ex) {
  //     this.m_global_srv.manageException(ex);
  //   }
  // }

  private SetLinesStates(ischeck: boolean): void {
    let ii: number;

    let len = this.m_document.GetLinesCount();
    let sellns: WFDocumentLineAdp[];

    let chrx = this.m_checkkwords.trim();
    if (chrx.length > 0) {
      let cls: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
      sellns = new Array<WFDocumentLineAdp>();

      let rx = new RegExp(chrx, 'i');

      for (ii = 0; ii < len; ii++) {
        let ln: WFDocumentLineAdp = this.m_document.GetLine(ii);
        for (let atr of cls.Columns) {
          let val = ln.get(atr.Name);
          if (val != null) {
            if (rx.test(val)) {
              sellns.push(ln);
              break;
            }
          }
        }
      }
    } else {
      sellns = null;
    }

    for (let ln of this.m_lines) {
      if (ln.lstatus === WFPrmType.CHANGE && ln.visible) {
        if (sellns != null) {
          if (sellns.indexOf(ln.line) < 0) continue;
        }
        ln.isselected = ischeck;
      }
    }
  }

  public CheckLines(): void {
    try {
      if (this.m_document.ClassID > 0) this.SetLinesStates(true);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public UncheckLines(): void {
    try {
      if (this.m_document.ClassID > 0) this.SetLinesStates(false);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ReverseLines(): void {
    try {
      if (this.m_document.ClassID > 0) {
        for (let ln of this.m_lines) {
          if (ln.lstatus === WFPrmType.CHANGE) ln.isselected = !ln.isselected;
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //
  // public ClickHideUnchecked(): void {
  //   try {
  //     if (this.m_document.ClassID > 0) {
  //       for (let ln of this.m_lines) {
  //         this.CheckLineVisibilityState(ln, true, this.m_hideuncheck);
  //       }
  //     }
  //   } catch (ex) {
  //     this.m_global_srv.manageException(ex);
  //   }
  // }

  // public ClickHideValid(): void {
  //   try {
  //     if (this.m_document.ClassID > 0) {
  //       for (let ln of this.m_lines) {
  //         this.CheckLineVisibilityState(ln, this.m_hidevalidval, true);
  //       }
  //     }
  //   } catch (ex) {
  //     this.m_global_srv.manageException(ex);
  //   }
  // }


  //

  public WriteToFile(): void {
    try {
      let lcc = this.m_document.GetLinesCount();

      if ((this.m_document.ClassID > 0) &&
        (this.m_lines.length > 0) &&
        lcc > 0) {

        let ii, jj: number;
        let strstr = '';

        //Kolumny
        let cols = new Array<string>(this.m_document.AllColumns.length);
        for (ii = 0; ii < this.m_document.AllColumns.length; ii++) {
          let cnm = this.m_document.AllColumns[ii].Name;
          cols[ii] = cnm.toUpperCase();
          if (ii > 0) strstr += ';';
          strstr += cnm;
        }

        strstr += '\r\n';

        //Wiersze
        for (ii = 0; ii < lcc; ii++) {
          let lineadp = this.m_document.GetLine(ii);
          for (jj = 0; jj < cols.length; jj++) {
            if (jj > 0) strstr += ';';
            let val = lineadp.get(cols[jj]);
            if (!IWFObject.IsNullOrEmpty(val)) {
              let off = val.indexOf('<!>');
              if (off > 0) val = val.substr(0, off);
              strstr += val;
            }
          }
          strstr += '\r\n';
        }

        let blob = new Blob(['\ufeff' + strstr], { type: 'text/csv;charset=utf-8;' });
        let slnk = document.createElement('a');
        let issaf = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (issaf) slnk.setAttribute('target', '_blank');

        slnk.setAttribute('href', URL.createObjectURL(blob));
        slnk.setAttribute('download', this.m_document.Name + '.csv');
        slnk.style.visibility = 'hidden';
        document.body.appendChild(slnk);
        slnk.click();
        document.body.removeChild(slnk);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ReadFromFile(): void {
    try {
      if ((this.m_document.ClassID > 0) &&
        (this.m_document.CanEditLines(null, null) === WFPrmType.CHANGE) &&
        !this.m_readonly &&
        (this.m_document.AllColumns.length > 0)) {
        this.m_filesel.nativeElement.click();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SelectFileChange(event: any): void {
    try {
      let fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        let fl: File = fileList[0];

        const self = this;
        let rdr = new FileReader();
        rdr.onloadend = (res) => {
          let str: string = (<any>res.srcElement).result;
          if (str.length > 0) self.ImportFromCsv(str);
        };

        rdr.readAsText(fl);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ImportFromCsv(dta: string): void {
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(ImportFileParamsComponent);
      wnd.instance.SetPrms(dta);
      wnd.instance.onClosed.subscribe((ret) => {
        wnd.destroy();
        if (ret) self.ImportFilePrmsClosed(wnd.instance);
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ImportFilePrmsClosed(snd: ImportFileParamsComponent): void {
    let ii, off, off2: number;

    try {
      let fch = snd.ColumnsSpecial;
      let delim = snd.ColumnsDelimiter;
      let adelim = IWFObject.Format('{0}{1}', fch, delim);
      let fdelim: string;
      let cname: string;

      let cols = new Array<string>();
      let rows = new Array<[number, Object[]]>();
      let rfline = snd.FirstRowIsColumn;

      let lines = snd.FileContent.split('\n');
      for (let ln of lines) {
        ln = ln.trim();
        if (ln.length > 0) {
          off = off2 = 0;

          fdelim = delim;
          if (fch.length > 0) {
            if (ln.substr(0, fch.length) === fch) {
              fdelim = adelim;
              off2 = fch.length;
            }
          }

          if (rfline) {
            while ((off = ln.indexOf(fdelim, off2)) >= 0) {
              cname = ln.substr(off2, off - off2);
              cols.push(cname);
              off2 = off + fdelim.length;

              fdelim = delim;
              if ((fch.length > 0) && (off2 + fch.length <= ln.length)) {
                if (ln.substr(off2, fch.length) === fch) {
                  fdelim = adelim;
                  off2 += fch.length;
                }
              }
            }

            if (fdelim === delim) {
              cols.push(ln.substr(off2, ln.length - off2));
            } else {
              cols.push(ln.substr(off2, ln.length - (off2 + fch.length)));
            }
            rfline = false;
          } else {
            let colcc = 0;

            if (cols.length === 0) {
              while ((off = ln.indexOf(fdelim, off2)) >= 0) {
                cols.push(IWFObject.Format('COL{0}', colcc));
                off2 = off + fdelim.length;
                colcc++;
                fdelim = delim;
                if ((fch.length > 0) && (off2 + fch.length <= ln.length)) {
                  if (ln.substr(off2, fch.length) === fch) {
                    fdelim = adelim;
                    off2 += fch.length;
                  }
                }
              }
              cols.push(IWFObject.Format('COL{0}', colcc));
              off = off2 = colcc = 0;
            }

            let row = new Array<Object>(cols.length);

            while (((off = ln.indexOf(fdelim, off2)) >= 0) && (colcc < cols.length)) {
              let val = ln.substr(off2, off - off2);
              row[colcc] = val;
              off2 = off + fdelim.length;
              colcc++;
              fdelim = delim;
              if ((fch.length > 0) && (off2 + fch.length <= ln.length)) {
                if (ln.substr(off2, fch.length) === fch) {
                  fdelim = adelim;
                  off2 += fch.length;
                }
              }
            }

            if (colcc < cols.length) {
              if (fdelim === delim) {
                row[colcc++] = ln.substr(off2, ln.length - off2);
              } else {
                row[colcc++] = ln.substr(off2, ln.length - (off2 + fch.length));
              }
            }

            rows.push([rows.length, row]);
          }
        }
      }

      if (cols.length > 0 && rows.length > 0) {
        const wnd = this.m_wnd_srv.showControl(ColumnMapDefinitionsComponent);
        wnd.instance.SetPrms(this.m_document.AllColumns);

        for (ii = 0; ii < cols.length; ii++) {
          let shmcol = cols[ii];
          let ushmcol = shmcol.toUpperCase();

          let atrid = -1;
          for (let atr of this.m_document.AllColumns) {
            if (atr.Name.toUpperCase() === ushmcol) {
              atrid = atr.ID;
              break;
            } else {
              if (!IWFObject.IsNullOrEmpty(atr.ReconSchemaFieldCode)) {
                if (atr.ReconSchemaFieldCode.toUpperCase() === ushmcol) {
                  atrid = atr.ID;
                  break;
                }
              }
            }
          }

          wnd.instance.AddRelation(shmcol, atrid, WFRelationType.COPY);
        }

        const self = this;
        wnd.instance.onClosed.subscribe((ret) => {
          wnd.destroy();
          if (ret) self.ColumnMapDefClosed(wnd.instance, rows);
        });
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strFileEmpty'));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //
  private AddNewLineToEnd(): void {
    let lid = this.m_document.GetLinesCount();
    this.m_document.AddLine(1);

    let adp: WFDocumentLineAdp = this.m_document.GetLine(lid);
    adp.OnPropertyValueChange = this.m_linevalchgevh;
    adp.OnPropertyValidation = this.m_linevalidateevh;

    let cmp: WFCompany = this.m_cache_srv.Companies.get(this.m_document.CompanyID);
    this.AddDocumentLine(adp, cmp, lid, this.m_document.AllColumns);

    this.RefreshInfoRow2();
  }

  public AddNewLine(): void {
    try {
      if ((this.m_document.ClassID > 0) &&
        (this.m_document.CanEditLines(null, null) === WFPrmType.CHANGE && !this.m_readonly) &&
        (this.m_document.AllColumns.length > 0)) {
        /*if (m_document.GetGroupingCount() > 0)
        {
            MessageWnd ask = new MessageWnd(App.GetString("strGroupLineAddAsk"), ICONTYPE.WARNING, BUTTONSTYPE.YESNO);
            ask.Closed += new EventHandler(AddNewLineClosed);
            ask.Show();
        }
        else
        {*/
        this.AddNewLineToEnd();
        //}
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public RemoveSelectedLines(): void {
    try {
      if ((this.m_document.ClassID > 0) &&
        (this.m_lines.length > 0) &&
        (this.m_document.CanEditLines(null, null) === WFPrmType.CHANGE && !this.m_readonly)) {

        const self = this;
        this.m_global_srv.showMsg(ALR_ICONTYPE.WARNING, BUTTONSTYPE.YESNO, this.m_strdata_srv.getStr('strDelCheckedLinesAsk'),
          (res) => {
            self.RemoveSelectedLineClosed(res);
          });

        /*MessageWnd ask = new MessageWnd(App.GetString("strDelCheckedLinesAsk"), ICONTYPE.WARNING, BUTTONSTYPE.YESNO);
        ask.Closed += new EventHandler(RemoveSelectedLineClosed);
        ask.Show();*/
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private RemoveSelectedLineClosed(res: WNDRESULT): void {
    try {

      if (res === WNDRESULT.OKYES) {
        /*if (m_document.GetGroupingCount() > 0)
        {
            ii = 0;
            while (ii < m_linesgr.Children.Count)
            {
                Grid grd = m_linesgr.Children[ii] as Grid;
                if (grd != null)
                {
                    if (RemoveInnerLines(grd) == 0)
                    {
                        m_linesgr.Children.RemoveAt(ii);
                        continue;
                    }
                }
                ii++;
            }
        }
        else
        {
          RemoveSelectedLineFromGrid(m_linesgr);
        }*/

        let ii = 0;
        while (ii < this.m_lines.length) {
          let ls: LineSpec = this.m_lines[ii];
          if (ls.isselected && ls.lstatus === WFPrmType.CHANGE) {
            if (this.m_document.RemoveLine(ii)) {
              this.m_lines.splice(ii, 1);
              continue;
            }
          }
          ii++;
        }

        this.RefreshInfoRow2();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }



  private RefreshInfoRow2(): void {
    let ii;

    //console.log('RefreshInfoRow2');

    for (let cl of this.m_columns) {
      //console.log(JSON.stringify(cl));
      if (cl.issum) {
        let sum = 0.0;

        for (let ln of this.m_lines) {
          let sval = ln.line.get(cl.attrib.Name);
          if (!IWFObject.IsNullOrEmpty(sval)) {            
            let sret= IWFObject.StrToDbl(sval);            
            sum += sret;
          }
        }

        if (cl.attrib.Type === WFFieldType.TYPE_INT) {
          cl.sumstr = (sum | 0).toString();
        } else {
          cl.sumstr = IWFObject.Round(sum, 2).toString();
        }

        cl.sumbck = '';
        if (cl.attrib.CompareClassAttribID > 0) {
          for (let hatr of this.m_document.AllAttributes) {
            if (hatr.ID === cl.attrib.CompareClassAttribID) {
              let hdrsval = this.m_document.get(hatr.Name);
              let hdrsum = (IWFObject.IsNullOrEmpty(hdrsval)) ? 0 : IWFObject.StrToDbl(hdrsval);
              cl.sumbck = (sum === hdrsum || 
                          IWFObject.Round(sum, 6) == IWFObject.Round(hdrsum, 6)) ? DocviewerComponent.VALIDSUM : DocviewerComponent.INVALIDSUM;
              break;
            }
          }
        }
      }
    }
  }

  public ShowHideOcrLines(): void {
    if (this.m_lines.length > 0) {
      let nval = !this.m_lines[0].isexpanded;
      for (let ln of this.m_lines) ln.isexpanded = nval;
    }
  }

  //inbox
  public TabMessagesIn_MouseLeftButtonDown(): void {
    if (this.m_tabmsgactive !== 0) this.m_tabmsgactive = 0;
  }

  public TabMessagesOut_MouseLeftButtonDown(): void {
    if (this.m_tabmsgactive !== 1) this.m_tabmsgactive = 1;
  }

  public SendByEMail(): void {
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(SendEmailComponent);
      const inst: SendEmailComponent = <SendEmailComponent>wnd.instance;
      inst.SetPrms(this.m_document);
      inst.onClosed.subscribe((ret) => {
        wnd.destroy();
        if (ret) self.FetchMessages(true);
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ReplyMessage(): void {
    try {
      if (this.m_selrow != null) {
        const self = this;
        const wnd = this.m_wnd_srv.showControl(RequestCommentComponent);
        const inst: RequestCommentComponent = <RequestCommentComponent>wnd.instance;
        inst.SetPrms(this.m_document, this.m_selrow.msg);
        inst.onClosed.subscribe((ret) => {
          wnd.destroy();
          if (ret) self.FetchMessages(true);
        });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public RequestComment(): void {
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(RequestCommentComponent);
      const inst: RequestCommentComponent = <RequestCommentComponent>wnd.instance;
      inst.SetPrms(this.m_document, null);
      inst.onClosed.subscribe((ret) => {
        wnd.destroy();
        if (ret) self.FetchMessages(true);
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SelectMessage(msg: MessageSpec): void {
    try {
      let ctx = '';
      ctx += IWFObject.Format(this.m_strdata_srv.getStr('wDocViewer_msgSubject') + ': {0}', msg.msg.Subject);
      ctx += '<br/>';
      ctx += IWFObject.Format(this.m_strdata_srv.getStr('wDocViewer_msgReceiptients') + ': {0}', WFDocumentAdp.DateTimeToStr(msg.msg.CreatedAt, this.m_strdata_srv));
      ctx += '<br/>';

      this.m_replybtn = false;

      let cc2 = msg.msg.GetRecipientsCount();
      if (cc2 > 0) {
        ctx += this.m_strdata_srv.getStr('wDocViewer_msgTo') + ': ';

        for (let ii2 = 0; ii2 < cc2; ii2++) {
          let rc = msg.msg.GetRecipient(ii2);
          if (ii2 > 0) ctx += ',';
          if (rc.UsersID > 0) {
            let usr: WFUser;
            if (rc.UsersID === this.m_cache_srv.User.ID) {
              usr = this.m_cache_srv.User;
              this.m_replybtn = true;
            } else {
              usr = this.m_cache_srv.Users.get(rc.UsersID);
            }
            ctx += usr.Name;
            if (rc.IsRead) ctx += this.m_strdata_srv.getStr('strRead');
          } else {
            ctx += rc.EMailAdr;
          }
        }

        ctx += '<br/>';
      }

      ctx += '-----------------------------------------------<br/>';
      ctx += msg.msg.Content;
      ctx += '<br/>';

      this.m_msgctx = ctx;
      this.m_selrow = msg;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public SelectExObjRef(nval): void {

    let ref: ExRefSpec = nval;
    let ii, ii2: number;

    try {
      if (ref.header == null) {
        if (ref.ref.Header.size > 0 || ref.ref.ColumnsCount > 0) {
          ref.header = Array.from(ref.ref.Header.entries());

          let cols = new Array<ColumnInfo>();
          for (ii = 0; ii < ref.ref.ColumnsCount; ii++) {
            cols.push({ align: ColumnAlign.LEFT, type: ColumnType.STRING, format: '', label: ref.ref.GetColumn(ii) });
          }

          let rows = new Array<Array<string>>();
          for (ii = 0; ii < ref.ref.RowsCount; ii++) {
            let rw = new Array<string>();
            for (ii2 = 0; ii2 < ref.ref.ColumnsCount; ii2++) {
              let str = ref.ref.GetValue(ii, ii2);
              rw.push((str == null) ? '' : str);
            }
            rows.push(rw);
          }

          ref.tables.push({ Columns: cols, Rows: rows });
        } else {
          this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
          const self = this;
          this.m_data_srv.previewExObject(this.m_cache_srv.SessionID, ref.ref.ID, this.m_onerrorevh, (hdr, tbls) => {
            ref.header = hdr;
            ref.tables = tbls;
            self.m_busy_text = '';
          });
        }
      }

      this.m_selref = ref;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ColumnInfoToStyle(ci: ColumnInfo, ishdr: boolean): any {
    let align: string;

    // if (ishdr) {
    //   align = 'center';
    // } else {
      switch (ci.align) {
        case ColumnAlign.RIGHT: align = 'right'; break;
        case ColumnAlign.CENTER: align = 'center'; break;
        default: align = 'left'; break;
      }
    //}


    let wh: number;
    switch (ci.type) {
      case ColumnType.INT:
      case ColumnType.FLOAT:
        wh = 60;
        break;
      case ColumnType.DATETIME:
        wh = 80;
        break;
      default:
        wh = 120;
          if (!ishdr)
           return { 'text-align': align, 'max-width': '300px', 'white-space':'nowrap', 'text-overflow':'ellipsis', 'overflow':'hidden' };         
        break;
    }

    return { 'text-align': align, 'width': wh + 'px' };
  }

  // //
  // public SumColumnWidth(): any {
  //   for (ii = 0; ii < ref.ref.ColumnsCount; ii++) {
  //     cols.push({ align: ColumnAlign.LEFT, type: ColumnType.STRING, format: '', label: ref.ref.GetColumn(ii) });
  //   }

  //   return { 'width': wh + 'px' };
  // }

  //

  private ClearOcrPreview(): void {
    this.m_shmheaderctx.splice(0, this.m_shmheaderctx.length);
    this.m_shmlinesctx_cols.splice(0, this.m_shmlinesctx_cols.length);
    this.m_shmlinesctx.splice(0, this.m_shmlinesctx.length);
  }

  private FieldValueToObj(tp: WFOcrFldType, sval: string): string {
    /*object val;
    if (String.IsNullOrEmpty(sval))
    {
        val = null;
    }
    else
    {
        switch (tp)
        {
            case WFOcrFldType.DATETIME:
                val = DateTime.ParseExact(sval, "dd-MM-yyyy", CultureInfo.InvariantCulture);
                break;
            case WFOcrFldType.NUMBER:
                val = Double.Parse(sval.Replace('.', ','), CultureInfo.InvariantCulture);
                break;
            default:
                sval= sval.Replace('\n', ' ');
                sval= sval.Replace('\r', ' ');
                val = sval.Replace('\t', ' ');
                break;
        }
    }*/
    return sval;
  }

  private CreateDocumentOcrResultPreview(rs: WFDocumentOcrResults, findhdr: boolean, sellines: Array<number>, cansel: boolean): void {
    let ii, ii2: number;
    let tbl: WFOcrField = null;

    //let hdr = new Array<[string, string]>();
    for (let fld of rs.Fields) {
      if (fld.Type === WFOcrFldType.TABLE &&
        fld.GetColumnsCount() > 0) {
        tbl = fld;
      } else {
        if (findhdr) {
          this.m_shmheaderctx.push([fld.Name, this.FieldValueToObj(fld.Type, fld.GetScalarValue())]);
        }
      }
    }

    //List<KeyValuePair<string, object[]>> lns = new List<KeyValuePair<string, object[]>>();
    if (tbl != null) {
      let cc = tbl.GetColumnsCount();

      for (ii = 0; ii < cc; ii++) {
        let cl = tbl.GetColumn(ii);

        let align: ColumnAlign;
        let type: ColumnType;
        switch (cl.Type) {
          case WFOcrFldType.CURRENCY:
          case WFOcrFldType.NUMBER:
            type = ColumnType.FLOAT;
            align = ColumnAlign.RIGHT;
            break;
          case WFOcrFldType.DATETIME:
            type = ColumnType.DATETIME;
            align = ColumnAlign.LEFT;
            break;
          default:
            type = ColumnType.STRING;
            align = ColumnAlign.LEFT;
            break;
        }

        this.m_shmlinesctx_cols.push({ align: align, type: type, format: '', label: cl.Name });
      }

      let rcc = tbl.GetRowsCount();
      for (ii = 0; ii < rcc; ii++) {

        if (sellines != null) {
          if (sellines.indexOf(ii) < 0) continue;
        }

        let cols = new Array<string>();
        for (ii2 = 0; ii2 < cc; ii2++) {
          let cl = tbl.GetColumn(ii2);
          cols.push(this.FieldValueToObj(cl.Type, tbl.GetValue(cl.Name, ii)));
        }

        this.m_shmlinesctx.push({ isselected: false, cols: cols });
      }
    }
  }

  public SelectReconSchema(defa: DefinitionSpec): void {
    try {
      if (defa !== this.m_selocr) {

        let cedt = (this.m_document.CanEdit(null) && !this.m_readonly);

        this.m_schemaname = IWFObject.Format('{0}: {1}', this.m_strdata_srv.getStr('strSchemaName').toLocaleUpperCase(), defa.definition.Code);
        this.ClearOcrPreview();
        if (defa.ocrresults == null) {
          //m_selocrrs = null;
          this.m_sendtoocr = cedt;
        } else {
          //m_selocrrs = defa.OcrResult;
          this.m_sendtoocr = false;
          this.CreateDocumentOcrResultPreview(defa.ocrresults, true, null, true);
        }

        this.FillPageTextRegions();

        this.m_selocr = defa;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //chyba wycofane
  public SetDefaultReconSchema(): void {

  }

  public SendToCentral(): void {

  }

  public SyncReconSchema(): void {

  }

  public SendToOCR(): void {
    try {
      if (this.m_document != null) {
        if (this.m_cache_srv.Config.OcrTaskMode === WFOcrTaskMode.EX_WEBSERVICE &&
          (this.m_document.DocStatus === WFDocStatus.AFTEROCR || this.m_document.DocStatus === WFDocStatus.BEFOREOCR)) {
          if ((this.m_selocr != null) && (this.m_selocr.ocrresults == null)) {
            this.m_document.OcrCode = '';
            this.m_document.DefinitionCode = this.m_selocr.definition.Code;
            this.m_document.DocStatus = WFDocStatus.INOCR;
            this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wDocViewer_pendingOCR'));
          }
        } else {
          this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wDocViewer_errDocumentInOCR'));
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  // public ClearSelSchemaLines(): void {
  //   try {
  //     for (let ii = 0; ii < this.m_shmlinesctx.length; ii++) {
  //       let ln = this.m_shmlinesctx[ii];
  //       ln.isselected = false;
  //     }
  //     this.m_checkrskwords = '';
  //   } catch (ex) {
  //     this.m_global_srv.manageException(ex);
  //   }
  // }

  private SetSchemaLinesStates(ischeck: boolean): void {
    let ii, ii2: number;
    let ln: OcrSchemaLineSpec;

    let chrx = this.m_checkrskwords.trim();
    if (chrx.length > 0) {
      let rx = new RegExp(chrx, 'i');
      for (ii = 0; ii < this.m_shmlinesctx.length; ii++) {
        ln = this.m_shmlinesctx[ii];
        for (ii2 = 0; ii2 < ln.cols.length; ii2++) {
          let strval = ln.cols[ii2];
          if (rx.test(strval)) {
            ln.isselected = ischeck;
            break;
          }
        }
      }
    } else {
      for (ii = 0; ii < this.m_shmlinesctx.length; ii++) {
        ln = this.m_shmlinesctx[ii];
        ln.isselected = ischeck;
      }
    }
  }

  public CheckSchemaLines(): void {
    try {
      this.SetSchemaLinesStates(true);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public UncheckSchemaLines(): void {
    try {
      this.SetSchemaLinesStates(false);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ReverseSchemaLines(): void {
    try {
      for (let ii = 0; ii < this.m_shmlinesctx.length; ii++) {
        let ln = this.m_shmlinesctx[ii];
        ln.isselected = !ln.isselected;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CopySelectedToLines(): void {
    let ii, ii2: number;

    try {
      if ((this.m_document.ClassID > 0) && (this.m_document.AllColumns.length > 0) && (this.m_document.CanEditLines(null, null) === WFPrmType.CHANGE && !this.m_readonly) &&
        (this.m_shmlinesctx_cols.length > 0) && (this.m_shmlinesctx.length > 0)) {
        let cls: WFClass = this.m_cache_srv.Classes.get(this.m_document.ClassID);
        if (cls.Columns.length > 0) {
          let selrows = new Array<[number, Array<string>]>();
          for (ii = 0; ii < this.m_shmlinesctx.length; ii++) {
            let ln = this.m_shmlinesctx[ii];
            if (ln.isselected) selrows.push([ii, ln.cols]);
          }

          //
          if (selrows.length > 0) {
            //wczytaj sugerowane ustawienia
            this.m_busy_text = this.m_strdata_srv.getStr('strSuggestOcrMapInfo');
            this.m_document.OnSuggestOcrMapCompleted = this.m_onsuggestocrmap;
            this.m_document.SuggestOcrMap(this.m_selocrrs.SchemaCode, selrows);
          } else {
            this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCheckAtLeast1Item'));
          }
        }
      }

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public OpenColumnPopup(col: ColumnSpec, event): void {
    try {
      if (col.attrib != null) {
        const self = this;
        const wnd = this.m_wnd_srv.showControl(ColumnValueSelectorComponent);
        const inst: ColumnValueSelectorComponent = <ColumnValueSelectorComponent>wnd.instance;

        let trg = event.target;
        if (trg.nodeName === 'SPAN') trg = trg.offsetParent;

        inst.SetPrms(trg, col.attrib, this.m_dateformat, this.m_document, this.m_curdocintmap);
        inst.closePopup.subscribe(() => {
          wnd.destroy();
        });

        inst.selectValue.subscribe(() => {
          wnd.destroy();
          self.SetColumnValue(col, inst.value, inst.valueData, inst.allRows);
        });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private SetColumnValue(col: ColumnSpec, nval: string, nvaldta: Date, allrows: boolean): void {
    try {
      if (col.attrib.Type === WFFieldType.TYPE_DATETIME) {
        for (let ln of this.m_lines) {
          if (allrows || ln.isselected) this.SetLineAttrib(nvaldta, ln, col);
        }
      } else {
        for (let ln of this.m_lines) {
          if (allrows || ln.isselected) this.SetLineAttrib(<Object>nval, ln, col);
        }
      }

      this.RefreshInfoRow2();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public OnTriggerClick(trg: WFClassTrigger, lid: number): void {
    try {
      if (this.m_document != null) this.m_document.FireTrigger(WFProcessAction.EVENT_CLICK, trg.Name, '', lid, 0);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public Editable_ContentChanged(evh: any): void {
    try {
      let stra;
      if(evh.html== null) {
        stra= '';
      } else {
        stra = evh.html.replace(/\s/g, '');
        //stra = stra.replace(/<(.|\n)*?>/g, '');
      }

      let strb = this.m_edittextctx_html.replace(/\s/g, '');
      //strb = strb.replace(/<(.|\n)*?>/g, '');
      if (stra !== strb) {
        let html= (evh.html== null) ? '' : evh.html;
        this.m_document.SetTmpHtmlDocument(btoa(unescape(encodeURIComponent(html))));
        this.m_edittextctx_html = html;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //SK
  public getLastUpdate4Comm(crs: CommentRowSpec): string[] {
    if (crs.region.GetEntriesCount() > 0) {
      return crs.region.getLastUpdateInfo();
    }
    return ['', ''];
  }

  public FilterLines(): void {
    try {
      if (this.m_document.ClassID > 0) {
        if (this.m_hidevalidval) {
          this.m_hideuncheck = false;
          this.m_hidevalidval = false;
        } else if (this.m_hideuncheck) {
          this.m_hideuncheck = false;
          this.m_hidevalidval = true;
        } else {
          this.m_hideuncheck = true;
          this.m_hidevalidval = false;
        }
        for (let ln of this.m_lines) {
          this.CheckLineVisibilityState(ln, this.m_hideuncheck, this.m_hidevalidval);
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public isColumnDisabled(cl: ColumnSpec, ln: LineSpec): boolean {
    if (cl.colid < 0) {
      return false;
    } else {
      if (ln.colinfo[cl.colid].iseditable) {
        return false;
      }
    }
    return true;
  }

  public hasRefs(): boolean {
    if (!this.m_refsctx || this.m_refsctx.length < 1) {
      return false;
    }
    return true;
  }

  public hasOcrs(): boolean {
    return (this.m_shmsctx.length > 0);
  }

  public SetTmpOtherDocument(barr: string, fname: string): void {
    try {
      this.m_document.SetTmpOtherDocument(barr, fname);
      if (this.m_cur_type === CONTENTTYPE.TYPE_HTMLTEMPLATE) {
        //aktualizacja zasobu
        this.CreateHtmlTplPreview(atob(barr));
      } else {
        this.SetResourceInfo(this.m_document.TmpFileName,
          this.m_document.TmpExtension,
          this.m_document.TmpUpdateTime,
          this.m_document.TmpDocumentB64Len, null, 0);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public CheckNewLine(cl: ColumnSpec, ln: LineSpec, event: KeyboardEvent): void {
    //dodatkowa walidacja
    let obj: any= event.target;
    this.SetLineAttrib(obj.value, ln, cl);    

    try {
      let off = this.m_lines.indexOf(ln);
      if ((off + 1) < this.m_lines.length) return;

      off = this.m_columns.indexOf(cl);
      if ((off + 1) < (this.m_columns.length - 2)) return;

      if (this.m_document.CanEditLines(null, null) === WFPrmType.CHANGE && !this.m_readonly && this.m_document.ClassID > 0) {
        let cls = this.m_cache_srv.Classes.get(this.m_document.ClassID);
        if (cls.AutoAddNewRow) this.AddNewLineToEnd();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public ChangeIsRead(msg: MessageSpec): void {
    try {
      let nstate = !msg.isread;
      let evh = new EventAdp<_OnError>();
      evh.subscribe(() => { msg.busytext = ''; });
      msg.busytext = this.m_strdata_srv.getStr('strDataUpdate');
      msg.msg.ModifyIsRead(this.m_cache_srv, nstate, evh, (sts) => {
        msg.isread = nstate;
        msg.busytext = '';
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public GetActualHtml(): [string, string] {
    if (this.m_cur_type === CONTENTTYPE.TYPE_HTMLTEMPLATE && this.m_htmltplcmpref != null) {
      return [this.m_htmltplcmpref.location.nativeElement.innerHTML, this.m_actstyles];
    }
    return null;
  }

  /*public TestAutoCpl(evh: Date): void {
    console.log(evh);
  }*/

  //

  public ClickDir(dir: DirectorySpec, evh: any): void {
    if(this.OnSelectDirectory!= null) this.OnSelectDirectory(this, dir.company_id, dir.directory_id);
    evh.stopPropagation();
  }

  public SumDivide(cl: ColumnSpec): void {
    try {

      let hdrsum= 0.0;
      
      for (let hatr of this.m_document.AllAttributes) {
        if (hatr.ID === cl.attrib.CompareClassAttribID) {
          let hdrsval = this.m_document.get(hatr.Name);
          hdrsum = (IWFObject.IsNullOrEmpty(hdrsval)) ? 0 : IWFObject.StrToDbl(hdrsval);
          break;
        }
      }

      if(hdrsum > 0)
      {
        //odejmij ilosci juz wporwadzone
        let ltofill= [];

        for (let ln of this.m_lines) {
          let sval = ln.line.get(cl.attrib.Name);
          if (IWFObject.IsNullOrEmpty(sval)) {
            ltofill.push(ln);            
          } else {            
            let sret= IWFObject.StrToDbl(sval);            
            hdrsum-= sret;
          }
        }

        if(ltofill.length > 0 && hdrsum > 0) {
          hdrsum= IWFObject.Round(hdrsum, 2);

          let parval= IWFObject.Round(hdrsum / ltofill.length, 2);
          let lii= ltofill.length - 1;

          for(let ii=0; ii< ltofill.length; ii++) {
            let ln= ltofill[ii];

            let selval= parval;
            if(selval > hdrsum) selval= hdrsum;  
            hdrsum= IWFObject.Round(hdrsum - selval, 2);

            //ostatnia pozycji
            if(ii== lii && hdrsum > 0) {
              //reszta do ostatniej
              ln.line.set(cl.attrib.Name, IWFObject.Round(selval + hdrsum,2).toString());
            } else {
              ln.line.set(cl.attrib.Name, selval.toString());
            } 
          }

          this.RefreshInfoRow2();
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //Zmiana firmy

  private OnChangeCompany(cmpid: number): void {    
    const self = this;
      
    for(let prop of this.m_inf_props) {
      if(prop.attrib!= null && prop.attrib.Type== WFFieldType.TYPE_CUSTOM) {
        if(!IWFObject.IsNullOrEmpty(this.m_document.get(prop.attrib.Name))) {

          this.m_global_srv.showMsg(ALR_ICONTYPE.WARNING, BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strCompanyChanged'),
          (res) => {
            if(res=== WNDRESULT.OKYES) 
              self.ClearCustomFieldData();
          });

          break;
        }
      }
    }

    let editable = (this.m_document.CanEdit(null) && !this.m_readonly);
    this.RefreshClasses(editable, cmpid);
  }

  private ClearCustomFieldData(): void {
    try {

      for(let prop of this.m_inf_props) {
        if(prop.attrib!= null && prop.attrib.Type== WFFieldType.TYPE_CUSTOM) {
          this.m_document.set(prop.attrib.Name, '');
        }
      }

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }


}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';

@Component({
  selector: 'app-long-text-adp-popup',
  templateUrl: './long-text-adp-popup.component.html',
  styleUrls: ['./long-text-adp-popup.component.scss']
})

export class LongTextAdpPopupComponent {
  public m_refelement: any;
  public m_value: string;
  public m_readonly: boolean;
  public m_document: IWFCompanyObjAdp;

  @Output() closePopup: EventEmitter<void>;
  @Output() selectValue: EventEmitter<string>;

  constructor() { 
    this.m_refelement = null;
    this.m_value= 'empty';
    this.closePopup = new EventEmitter<void>();
    this.selectValue = new EventEmitter<string>();
    this.m_readonly = false;
    this.m_document = null;
  }

  public SetPrms(refele: any, str: string, ronly: boolean, doc: IWFCompanyObjAdp): void {
    this.m_refelement = refele;
    this.m_value = str;
    this.m_readonly = ronly;
    this.m_document = doc;
  }
}

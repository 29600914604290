import { IWFObject } from './IWFObject';

export class WFOcrDefinition {
    private m_code: string;
    private m_ncode: string;
    private m_description: string;
    private m_fname: string;
    private m_lang: string;
    private m_createdat: Date;
    private m_updatedat: Date;

    //

    constructor(obj: any) {
        this.m_code = obj.code;
        this.m_ncode = obj.ncode;
        this.m_description = obj.description;
        this.m_fname = obj.fname;
        this.m_lang = obj.lang;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
    }

    //

    public get Code(): string {
        return this.m_ncode;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get FileName(): string {
        return this.m_fname;
    }

    public get Language(): string {
        return this.m_lang;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public toString(): string {
        return this.m_code;
    }
}
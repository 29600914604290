import { WFDailyCorrespondenceClass, WFClassAttrib, WFColumnFormat, WFPropType, WFPermissionInfo, WFPrmType, WFDailyCorrespondence, WFSystem, UpperMap, IWFObject } from '../model/index';
import { CacheService } from '../data/cache.service';
import { IWFDataFetchAdp } from './IWFDataFetchAdp';
import { WFDataSource } from '../data/data.service';
import { ReportViewerComponent } from '../report-viewer/report-viewer.component';
import { EventAdp } from '../data/eventadp';
import { _OnError } from './WFDocumentAdp';
import { WFDailyCorrespondenceAdp } from './WFDailyCorrespondenceAdp';
import { EditDailyCorrespondenceComponent } from '../edit-daily-correspondence/edit-daily-correspondence.component';

export class WFDailyCorrespondenceClassAdp extends IWFDataFetchAdp {
    private m_cls: WFDailyCorrespondenceClass;
    private m_conn: CacheService;

    //

    constructor(conn: CacheService, cls: WFDailyCorrespondenceClass) {
        super();
        this.m_conn = conn;
        this.m_cls = cls;
        if (this.m_cls.ReportParameters.length > 0) WFClassAttrib.SortArribs(this.m_cls.ReportParameters);
        if (this.m_cls.Attributes.length > 0) WFClassAttrib.SortArribs(this.m_cls.Attributes);
    }

    public get ID(): number {
        return this.m_cls.ID;
    }

    //

    public get Name(): string {
        return this.m_cls.Name;
    }

    //

    public get Description(): string {
        return this.m_cls.Description;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_cls.UpdatedAt;
    }

    //

    public get ReportLabel(): string {
        return this.m_cls.Name;
    }

    //

    public get Enabled(): boolean {
        return this.m_cls.Enabled;
    }

    //

    public get Parameters(): Array<WFClassAttrib> {
        return this.m_cls.ReportParameters;
    }

    //

    public get ColumnsFormat(): Array<WFColumnFormat> {
        return this.m_cls.ReportColumnsFormat;
    }

    //

    public get Attributes(): Array<WFClassAttrib> {
        return this.m_cls.Attributes;
    }

    //

    public get TaskID(): string {
        return IWFObject.Format('dcc{0}', this.m_cls.ID);
    }

    //

    protected FetchDataCompleted(dta: Array<[string, Array<object>]>, ustate: object): void {
        this.ConfirmFetchAction(dta, ustate);
    }

    //

    public FetchDictData(serv: CacheService, prms: Map<number, Object>, usecache: boolean, dbname: string, uval: Object = null): void {
        if (WFClassAttrib.CheckMandatory(this.m_cls.ReportParameters, prms)) {
            this.SendExecuteInteractiveDict(serv, prms, uval, WFDataSource.DAILYCORRCLASS, this.m_cls.ID, dbname);
        } else {
            this.ConfirmFetchAction(null, uval);
        }
    }

    //

    private CheckPermissions(ptp: WFPropType): boolean {
        let allow = false;
        let replusrs = this.m_conn.User.FindReplacements(this.m_conn.Users);

        //zbadaj uprawnienia
        let pinfs = this.m_cls.ListPermissions(ptp, this.m_conn.AppSystem, this.m_conn.User.ID);
        for (let pinf of pinfs) {
            if ((pinf.UserID === this.m_conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0)) {
                allow = (pinf.Type === WFPrmType.CHANGE);
                break;
            }
        }

        return allow;
    }

    //

    public get CanAddNewEntry(): boolean {
        if (this.m_conn.IsSuperUser) return true;
        return this.CheckPermissions(WFPropType.DAILY_ADDNEW);
    }

    //

    public ListPermissions(ptp: WFPropType): Array<WFPermissionInfo> {
        return this.m_cls.ListPermissions(ptp, this.m_conn.AppSystem, this.m_conn.User.ID);
    }

    public ListPermissions2(atribid: number, addcanedit: boolean): Array<WFPermissionInfo> {
        return this.m_cls.ListPermissions2(atribid, this.m_conn.AppSystem, addcanedit, this.m_conn.User.ID);
    }
}
import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { DataService, DownloadInfo } from '../data/data.service';
import { CacheService } from '../data/cache.service';
import { WFResourceType } from '../model';

interface DownloadEntry {
  filename: string;
  infodate: string;
  restype: string;
  link: string;
}

@Component({
  selector: 'app-download-document',
  templateUrl: './download-document.component.html',
  styleUrls: ['./download-document.component.scss']
})

export class DownloadDocumentComponent implements AfterViewInit {
  public m_busy_str: string;

  private m_doc: WFDocumentAdp;
  public m_resources: Array<DownloadEntry>;

  @Output() public onClosed: EventEmitter<boolean>;

  private m_onerrorevh: EventAdp<_OnError>;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_data_srv: DataService,
    private m_cache_srv: CacheService) {

    this.m_busy_str = '';
    this.m_doc = null;
    this.m_resources = new Array<DownloadEntry>();
    this.onClosed = new EventEmitter<boolean>();
    this.m_onerrorevh = new EventAdp<_OnError>();
    const self = this;
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });
  }

  //

  public ngAfterViewInit(): void {
    try {
      let pages_c = this.m_doc.GetPagesCount();
      let diffres = new Array<number>();

      for (let ii = 0; ii < pages_c; ii++) {
        let pg = this.m_doc.GetPage(ii);
        if (diffres.indexOf(pg.ResourcesID) < 0) diffres.push(pg.ResourcesID);
      }

      this.m_busy_str = this.m_strdata_srv.getStr('wDownloadDocument_BusyText');
      const self = this;
      this.m_data_srv.prepareDownload(this.m_cache_srv.SessionID, diffres, this.m_onerrorevh, (dinf) => {
        self.PrepareDownloadCompleted(dinf);
      });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private PrepareDownloadCompleted(dinf: Array<DownloadInfo>): void {
    for (let inf of dinf) {
      let rstrtype = '';
      switch (inf.type) {
        case WFResourceType.RES_IMAGEPDF:
          rstrtype = this.m_strdata_srv.getStr('strRES_IMAGEPDF');
          break;
        case WFResourceType.RES_IMAGEPNG:
          rstrtype = this.m_strdata_srv.getStr('strRES_IMAGEPNG');
          break;
        case WFResourceType.RES_PLAINTEXT:
          rstrtype = this.m_strdata_srv.getStr('strRES_PLAINTEXT');
          break;
        case WFResourceType.RES_RTF:
          rstrtype = this.m_strdata_srv.getStr('strRES_RTF');
          break;
      }

      this.m_resources.push({
        filename: inf.fname,
        infodate: WFDocumentAdp.DateTimeToStr(inf.date, this.m_strdata_srv),
        restype: rstrtype,
        link: this.m_data_srv.ServiceURL + '/GETRES/' + inf.signature + '/' + inf.fname
      });
    }
    this.m_busy_str = '';
  }

  //

  public SetPrms(doc: WFDocumentAdp): void {
    this.m_doc = doc;
  }

  //

  public OKButton_Click(): void {
    this.onClosed.emit(true);
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, PrintAsyncTasksInfo, PrintAsyncTaskStatus } from '../data/data.service';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { _OnError, WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { IWFDataFetchAdp } from '../adapters/IWFDataFetchAdp';
import { ComboIValueDesc } from '../app.component';
import { WFColumnTarget, IWFObject } from '../model';

export interface PrintAsyncTasksSpec {
  start: string;
  rows:  number;
  link: string;
  link2: string;
  status: string; 
  lasterr: string;
  rid: string;
}

@Component({
  selector: 'app-printreporttask',
  templateUrl: './printreporttask.component.html',
  styleUrls: ['./printreporttask.component.scss']
})
export class PrintreporttaskComponent implements AfterViewInit {
  public m_busy_str: string; 
  private m_onerrorevh: EventAdp<_OnError>;

  public m_tasks: Array<PrintAsyncTasksSpec>;

  public m_tasktypes: Array<ComboIValueDesc>;
  public m_tasktype: ComboIValueDesc;

  private m_statusdsc: Array<string>;
  private m_datasrc: IWFDataFetchAdp;

  private m_docid_col: string; 

  @Output() public onClosed: EventEmitter<boolean>;

  public m_cntonerr: boolean;

  //

  constructor(public m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) { 

    this.m_busy_str = '';

    this.onClosed = new EventEmitter<boolean>();
    this.m_tasks= new Array<PrintAsyncTasksSpec>();

    this.m_tasktypes = new Array<ComboIValueDesc>();
    this.m_tasktypes.push({ val: 0, desc: this.m_strdata_srv.getStr('wPrintReportTask_type_rows') });
    this.m_tasktypes.push({ val: 1, desc: this.m_strdata_srv.getStr('wPrintReportTask_type_wdoc') });
    this.m_tasktype= this.m_tasktypes[0];

    this.m_statusdsc= new Array<string>();
    this.m_statusdsc.push(this.m_strdata_srv.getStr('wPrintReportTask_status_waiting'));
    this.m_statusdsc.push(this.m_strdata_srv.getStr('wPrintReportTask_status_preparing'));
    this.m_statusdsc.push(this.m_strdata_srv.getStr('wPrintReportTask_status_error'));
    this.m_statusdsc.push(this.m_strdata_srv.getStr('wPrintReportTask_status_done'));

    this.m_datasrc= null;
    this.m_docid_col= null;

    const self = this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });

    this.m_cntonerr= true;
  }

  public SetPrms(ds:IWFDataFetchAdp): void {
    this.m_datasrc= ds;
    this.m_docid_col= null;
    for(let colfr of ds.ColumnsFormat) {
      if(colfr.Target== WFColumnTarget.DOCUMENT) {
        this.m_docid_col= colfr.ColumnCode;
        break;
      }  
    }
  }

  private FormatSpec(ts: PrintAsyncTasksInfo): PrintAsyncTasksSpec {
    let sts= this.m_statusdsc[<number>ts.status];
    if(ts.status== PrintAsyncTaskStatus.PREPARING) sts= IWFObject.Format('{0} {1}%', sts, ts.percent);
    
    return {
      start: WFDocumentAdp.DateTimeToStr(ts.start, this.m_strdata_srv),
      rows: ts.rows,
      link: (ts.status== PrintAsyncTaskStatus.DONE) ? this.m_data_srv.ServiceURL + '/GETRES/' + ts.link : null,
      link2: (ts.status== PrintAsyncTaskStatus.DONE) ? this.m_data_srv.ServiceURL + '/GETRES/' + ts.link+'/'+ts.link+'.pdf' : null,
      status: sts, 
      lasterr: (ts.status== PrintAsyncTaskStatus.ERROR) ? ts.lasterr : null,
      rid: ts.link  
    };
  }

  public Refresh(): void {
    try {
      this.m_busy_str = this.m_strdata_srv.getStr('strWaitData');
      const self = this;
      this.m_data_srv.getPrintAsyncTasks(this.m_cache_srv.SessionID, this.m_datasrc.TaskID, this.m_onerrorevh, (tsks) => {
          self.m_tasks.splice(0, self.m_tasks.length);
          for(let ts of tsks) {
            self.m_tasks.push(self.FormatSpec(ts));    
          }
          self.m_busy_str = '';
        });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ngAfterViewInit(): void {
    this.Refresh();
  }

  public OkButton_Click(): void {
    this.onClosed.emit(true);
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public AddNewTask(): void {
    try {
      if(this.m_tasktype.val== 1 && this.m_docid_col== null) {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wPrintReportTask_no_docidcol'));
        return;    
      }

      let cmap= [];
      for(let colfr of this.m_datasrc.ColumnsFormat) {
        cmap.push({ col: colfr.ColumnCode, lbl: colfr.Label, trg: <number>colfr.Target });
      }
      
      //dodanie mapowania kolumn systemowych
      for(let kv of this.m_datasrc.Cache) {
        let key= kv[0];
        if(cmap.indexOf(key) < 0) {
          let lbl= this.m_strdata_srv.getStr(key);
          if(!IWFObject.IsNullOrEmpty(lbl)) 
            cmap.push({ col: key, lbl: lbl, trg: <number>WFColumnTarget.NONE });
        }
      }

      this.m_busy_str = this.m_strdata_srv.getStr('strWaitData');
      const self = this;
      this.m_data_srv.addPrintAsyncTasks(this.m_cache_srv.SessionID, 
                                        this.m_datasrc.TaskID, 
                                        this.m_datasrc.Cache,
                                        cmap, 
                                        this.m_tasktype.val,
                                        this.m_docid_col,
                                        this.m_cntonerr,
                                        this.m_onerrorevh, (ts) => {
          self.m_tasks.push(self.FormatSpec(ts)); 
          self.m_busy_str = '';
        });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

import { WFReconSchema, WFDocumentPage } from '../model/index';
import { CacheService } from '../data/cache.service';
import { WFDocumentAdp } from '../adapters/WFDocumentAdp';

export type FetchPreviewCompleted = (snd: WFReconSchemaAdp, header: Array<[string, Array<object>]>, lines: Array<[string, Array<object>]>) => void;

export class WFReconSchemaAdp {
    private m_schema: WFReconSchema;
    private m_doc: WFDocumentAdp;
    private m_header: Array<[string, Array<object>]>;
    private m_lines: Array<[string, Array<object>]>;

    public OnFetchPreviewCompleted: FetchPreviewCompleted;

    //

    constructor(schema: WFReconSchema, doc: WFDocumentAdp) {
        this.m_schema = schema;
        this.m_doc = doc;
        this.m_header = null;
        this.m_lines = null;
        this.OnFetchPreviewCompleted = null;
    }

    public get ReconSchema(): WFReconSchema {
        return this.m_schema;
    }

    //
    
    public FetchPreview(): void {
        if (this.m_header == null) {
            let pgc = this.m_doc.GetPagesCount();
            let resids = new Array<number>(pgc);
            for (let ii = 0; ii < pgc; ii++) {
                let pg: WFDocumentPage = this.m_doc.GetPage(ii);
                if (resids.indexOf(pg.ResourcesID, 0) < 0) {
                    resids.push(pg.ResourcesID);
                }
            }

            const self = this;
            this.m_doc.CacheService.DataService.executeReconSchema(this.m_doc.CacheService.SessionID, this.m_schema, resids, this.m_doc.OnError, (hdr, lns) => {
                self.ExecuteReconSchemaCompleted(hdr, lns);
            });
        } else {
            this.OnFetchPreviewCompleted(this, this.m_header, this.m_lines);
        }
    }

    //

    private ExecuteReconSchemaCompleted(hdr: Array<[string, Array<object>]>, lns: Array<[string, Array<object>]>): void {
        try {
            this.m_header = hdr;
            this.m_lines = lns;
            this.OnFetchPreviewCompleted(this, this.m_header, this.m_lines);
            return;
        } catch (ex) {
            this.m_doc.CacheService.GlobalService.manageException(ex);
        }

        this.OnFetchPreviewCompleted(this, null, null);
    }
}
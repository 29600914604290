import { WFClass } from './WFClass';
import { WFUser } from './WFUser';
import { WFCompany } from './WFCompany';
import { WFAuthSchema } from './WFAuthSchema';
import { WFUserGroup } from './WFUserGroup';
import { WFDictionary } from './WFDictionary';
import { WFConfiguration } from './WFConfiguration';

export class WFSystem {
    private m_clss: Map<number, WFClass>;
    private m_usrs: Map<number, WFUser>;
    private m_cmps: Map<number, WFCompany>;
    private m_shms: Map<number, WFAuthSchema>;
    private m_grps: Map<number, WFUserGroup>;
    private m_dicts: Map<number, WFDictionary>;
    private m_cfgprofile: WFConfiguration;

    //

    constructor(clss: Map<number, WFClass>,
                usrs: Map<number, WFUser>,
                cmps: Map<number, WFCompany>,
                shms: Map<number, WFAuthSchema>,
                grps: Map<number, WFUserGroup>,
                dicts: Map<number, WFDictionary>,
                cfg: WFConfiguration) {
        this.m_clss = clss;
        this.m_usrs = usrs;
        this.m_cmps = cmps;
        this.m_shms = shms;
        this.m_grps = grps;
        this.m_dicts = dicts;
        this.m_cfgprofile = cfg;
    }

    //

    public get AllClasses(): Map<number, WFClass> {
        return this.m_clss;
    }

    public get AllUsers(): Map<number, WFUser> {
        return this.m_usrs;
    }

    public get AllCompanies(): Map<number, WFCompany> {
        return this.m_cmps;
    }

    public get AllSchemas(): Map<number, WFAuthSchema> {
        return this.m_shms;
    }

    public get AllUserGroups(): Map<number, WFUserGroup> {
        return this.m_grps;
    }

    public get AllDictionaries(): Map<number, WFDictionary> {
        return this.m_dicts;
    }

    public get Config(): WFConfiguration {
        return this.m_cfgprofile;
    }
}
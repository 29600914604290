import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, NgZone, ComponentRef, AfterViewInit } from '@angular/core';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { CacheService } from '../data/cache.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { AutoCompleteTextBoxPopupComponent } from '../auto-complete-text-box-popup/auto-complete-text-box-popup.component';
import { IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';
import { IWFObject } from '../model';

interface ComponentSpec {
  componentid: number;
  self: AutoCompleteTextBoxComponent;
}

@Component({
  selector: 'app-auto-complete-text-box',
  templateUrl: './auto-complete-text-box.component.html',
  styleUrls: ['./auto-complete-text-box.component.scss']
})

export class AutoCompleteTextBoxComponent implements OnInit, AfterViewInit {
  private static RefStruct = 'autocompletetextbox';
  private static ComponentID = 0;

  //

  public m_value: string;
  public m_readonly: boolean;
  public m_htmlctx: string;
  public m_cmpid: number;
  private m_doc: IWFCompanyObjAdp;
  private m_hrefid: number;
  private m_wnd: ComponentRef<AutoCompleteTextBoxPopupComponent>;
  private m_caretpos: number;
  private m_class: Array<string>;

  @ViewChild('m_textfld', {static: false}) private m_textfld: ElementRef;

  private m_enter: boolean;

  //

  public set value(nval: string) {
    if (this.m_value !== nval) {
      if (this.m_textfld != null) {
        this.m_textfld.nativeElement.innerHTML = this.prepareHtmlCtx(nval);
      }
      this.m_value = nval;
    }
  }

  @Input() public get value(): string {
    return this.m_value;
  }

  @Output() valueChange: EventEmitter<string>;

  //

  public set readonly(nval: boolean) {
    this.m_readonly = nval;
  }

  @Input() public get readonly(): boolean {
    return this.m_readonly;
  }

  public set document(nval: IWFCompanyObjAdp) {
    if (this.m_doc !== nval) {
      this.m_doc = nval;
      if (this.m_textfld != null) {
        this.m_textfld.nativeElement.innerHTML = this.prepareHtmlCtx(this.m_value);
      }
    }
  }

  @Input() public get document(): IWFCompanyObjAdp {
    return this.m_doc;
  }

  public set class(nval: Array<string>) {
    this.m_class = nval;
  }

  @Input() public get class(): Array<string> {
    return this.m_class;
  }

  //

  constructor(private m_globa_srv: GlobalService,
    private m_zone: NgZone,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_value = '';
    this.m_readonly = false;
    this.m_htmlctx = '';
    this.valueChange = new EventEmitter<string>();
    this.m_cmpid = AutoCompleteTextBoxComponent.ComponentID;
    AutoCompleteTextBoxComponent.ComponentID++;
    this.m_doc = null;
    this.m_hrefid = 0;
    this.m_wnd = null;
    this.m_caretpos = 0;
    this.m_class = new Array<string>();
    this.m_textfld = null;
    this.m_enter = false;
  }

  public ngOnInit(): void {
    let stru: Array<ComponentSpec>;
    if (AutoCompleteTextBoxComponent.RefStruct in window) {
      stru = window[AutoCompleteTextBoxComponent.RefStruct];
    } else {
      stru = new Array<ComponentSpec>();
    }
    stru.push({ componentid: this.m_cmpid, self: this });
    window[AutoCompleteTextBoxComponent.RefStruct] = stru;
  }

  public ngAfterViewInit(): void {
    if (!IWFObject.IsNullOrEmpty(this.m_value)) this.m_textfld.nativeElement.innerHTML = this.prepareHtmlCtx(this.m_value);
  }

  /*private getCaretCharacterOffsetWithin(node: any): number {
    var range = window.getSelection().getRangeAt(0),
        preCaretRange = range.cloneRange(),
        caretPosition,
        tmp = document.createElement("div");

    preCaretRange.selectNodeContents(node);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    tmp.appendChild(preCaretRange.cloneContents());
    caretPosition = tmp.innerHTML.length;
    return caretPosition;
 }*/

  private getCaretCharacterOffsetWithin(element: any): number {
    let caretOffset = 0;
    if (typeof window.getSelection() !== 'undefined') {
      let range = window.getSelection().getRangeAt(0);
      let preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
      return caretOffset;
    } /*else {
      console.log('else');
      if (typeof document.selection !== 'undefined' && document.selection.type !== 'Control') {
        let textRange = document.selection.createRange();
        let preCaretTextRange = document.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint('EndToEnd', textRange);
        caretOffset = preCaretTextRange.text.length;
      }
    }*/
    return caretOffset;
  }


  private findNodeAt(node: any, pos: number): [any, number, any] {
    let ii: number;
    let lch: any = null;

    for (ii = 0; (ii < node.childNodes.length) && (pos > 0); ii++) {
      let ch = node.childNodes[ii];

      if (ch.nodeType == 1) {
        if (ch.tagName == 'BR') {
          //pos -= 1;
          continue;
        }

        let ret = this.findNodeAt(ch, pos);
        if (ret[0] != null) return ret;
        pos = ret[1];
        lch = ch;
        continue;
      }

      let len = ch.textContent.length;
      if (pos > len) {
        pos -= len;
        lch = ch;
      } else {
        return [ch, pos, lch];
      }
    }

    return [null, pos, lch];
  }

  private setCursor(node: any, pos: number): boolean {
    if (document.createRange) {
      let range = document.createRange();
      if (pos < 0) {
        range.selectNodeContents(node);

        range.setStart(node, range.endOffset);
        range.setEnd(node, range.endOffset);

        let sel2 = window.getSelection();
        sel2.removeAllRanges();
        sel2.addRange(range);
      } else {


        let ret = this.findNodeAt(node, pos);

        pos = ret[1];
        let ch: any;
        if (ret[0] == null) {
          if (ret[2] == null) {
            ch = node;
          } else {
            ch = ret[2];
            if (pos > 0) pos -= 1;
          }
        } else {
          ch = ret[0];
        }

        range.selectNodeContents(ch);
        if (pos > range.endOffset) pos = range.endOffset;

        range.setStart(ch, pos);
        range.setEnd(ch, pos);

        let selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);


        /*for (let ch of node.childNodes) {
          let len = ch.textContent.length;
          //if (len == 0) len = 1;

          if (pos > len || ch.tagName == 'BR') {
            pos -= len;
          } else {
            //console.log(ch, pos);
            if (ch.firstChild != null) {
              if (ch.firstChild.tagName == 'BR') {
                if (pos > 0) pos -= 1;
              } else {
                ch = ch.firstChild;
              }
            }

            range.selectNodeContents(ch);
            if (pos > range.endOffset) pos = range.endOffset;

            console.log('pos=' + pos);

            range.setStart(ch, pos);
            range.setEnd(ch, pos);

            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            break;
          }
        }*/
      }
      return true;
    }

    if (node.createTextRange) {
      let textRange = node.createTextRange();
      textRange.collapse(true);
      textRange.moveEnd(pos);
      textRange.moveStart(pos);
      textRange.select();
      return true;
    }

    if (node.setSelectionRange) {
      node.setSelectionRange(pos, pos);
      return true;
    }

    return false;
  }

  private closeWnd(): void {
    if (this.m_wnd != null) {
      this.m_wnd.destroy();
      this.m_wnd = null;
    }
  }

  public openSelector(trg: any, dict_id: number): void {
    try {
      this.m_caretpos = this.getCaretCharacterOffsetWithin(trg.parentNode);
      //

      let cmp = this.m_cache_srv.Companies.get(this.m_doc.CompanyID);
      let dc = cmp.Dictionaries.find((tst) => tst.ID === dict_id);

      const self = this;
      this.m_wnd = this.m_wnd_srv.showControl(AutoCompleteTextBoxPopupComponent);
      this.m_wnd.instance.SetPrms(trg, dc.Values);

      this.m_wnd.instance.closePopup.subscribe(() => {
        self.closeWnd();
      });

      this.m_wnd.instance.selectValue.subscribe((nval) => {
        self.closeWnd();
        self.addValueAfter(trg, nval, dc.Values);
      });

    } catch (ex) {
      this.m_globa_srv.manageException(ex);
    }
  }

  private addValueAfter(trg: any, nval: string, dc: Map<string, string>): void {
    let ii: number;
    let divnd = trg.parentNode;
    let html = divnd.innerHTML;
    let atg = trg.outerHTML;
    let off = html.indexOf(atg);
    if (off >= 0) {

      let ntoken = html.substr(off + atg.length);
      if (ntoken.length > 0) {
        ntoken = ntoken.replace(/&nbsp;/g, ' ').trim();
        ntoken = ntoken.replace(/<br\/>/g, '\n');
        ntoken = ntoken.replace(/<br>/g, '\n');
        let off2 = -1;
        for (ii = 0; ii < ntoken.length; ii++) {
          let ch = ntoken[ii];
          if (/(\s|\,|\.|-|;|:|\n)/.test(ch)) {
            off2 = ii;
            break;
          }
        }

        if (off2 > 0) {
          let oval = ntoken.substr(0, off2);
          if (dc.has(oval)) ntoken = ntoken.substr(off2);
        } else {
          if (dc.has(ntoken)) ntoken = '';
        }
      }

      //let caretPos = this.getCaretCharacterOffsetWithin(divnd);

      //console.log(off, atg, html);
      let nhtml = html.substr(0, off + atg.length);
      nhtml += ' ' + nval;
      nhtml += ntoken;

      divnd.innerHTML = nhtml.replace(/\n/g, '<br/>');
      this.setCursor(divnd, this.m_caretpos);
      this.m_value = this.m_textfld.nativeElement.innerText; //.textContent;
    }
  }

  public runInZone(trg: any, dict_id: number): boolean {
    const self = this;
    this.m_zone.run(() => {
      self.openSelector(trg, dict_id);
    });
    return false;
  }

  private replaceWord(word: string): string {
    if (this.m_doc != null && !this.m_readonly) {
      if (this.m_doc.CompanyID > 0) {
        let cmp = this.m_cache_srv.Companies.get(this.m_doc.CompanyID);
        for (let dc of cmp.Dictionaries) {
          if (dc.DisplayAsTips && dc.Regex.source.length > 0 && dc.Regex.test(word)) {
            let nid = this.m_hrefid;
            this.m_hrefid++;
            return '<a href="' + nid + '" onclick="window[\'' + AutoCompleteTextBoxComponent.RefStruct + '\'].find(function(tst) { return tst.componentid==' + this.m_cmpid + '; }).self.runInZone(this,' + dc.ID + ')">' + word + '</a>';
          }
        }
      }
    }
    return word;
  }

  /*private prepareHtmlCtx(spl: string): string {
    spl = spl.replace(/\r\n/g, '\n');
    spl = spl.replace(/\r/g, '\n');

    let word = '';

    let html = '';
    this.m_hrefid = 0;
    let lid = spl.length - 1;

    for (let ii = 0; ii < spl.length; ii++) {
      let ch = spl[ii];
      if (/(\s|\,|\.|-|;|:|\n)/.test(ch)) {
        if (word.length > 0) {
          html += this.replaceWord(word);
          word = '';
        }
        //console.log('[spc]');
        if (ch === '\n') {
          //if (ii === lid) 
          //  ch= ' ';
          //else
          ch = '<br/>';
        }
        //if (ch === ' ' || ch.charCodeAt(0) === 160) ch = '&nbsp;';
        html += ch;
      } else {
        word += ch;
      }
    }

    if (word.length > 0) html += this.replaceWord(word);
    return html;
  }*/

  private prepareHtmlCtx(spl: string): string {
    spl = spl.replace(/<a[^>]+>/g, '');
    spl = spl.replace(/<\/a>/g, '');
    spl = spl.replace(/\n/g, '<br>');

    let word = '';
    let html = '';
    this.m_hrefid = 0;

    let intag = false;

    for (let ii = 0; ii < spl.length; ii++) {
      let ch = spl[ii];

      if (intag) {
        if (ch === '>') intag = false;
        html += ch;
        continue;
      }

      if (ch === '<') {
        if (word.length > 0) {
          html += this.replaceWord(word);
          word = '';
        }
        html += ch;

        intag = true;
        continue;
      }

      if (/(\s|\,|\.|-|:|&)/.test(ch)) {
        if (word.length > 0) {
          html += this.replaceWord(word);
          word = '';
        }
        //if(ch === ' ') ch= '&nbsp;'    
        html += ch;
      } else {
        word += ch;
      }
    }

    if (word.length > 0) html += this.replaceWord(word);

    return html;
  }

  public newValue(trg: any): void {
    this.closeWnd();
    let spl = trg.innerHTML;// trg.innerText; //.textContent;

    let caretPos = this.getCaretCharacterOffsetWithin(trg);
    if (this.m_enter) {
      caretPos += 1;
      this.m_enter = false;
    }

    let test = this.prepareHtmlCtx(spl);
    trg.innerHTML = test;// this.prepareHtmlCtx(spl);

    this.setCursor(trg, caretPos);
    this.m_value = trg.innerText;
  }

  public onBlur(): void {
    if (this.m_wnd == null) this.valueChange.emit(this.m_value);
  }

  public Focus(): void {
    try {
      if (!this.m_readonly) {
        this.m_textfld.nativeElement.focus();
      }
    } catch (ex) {
      this.m_globa_srv.manageException(ex);
    }
  }

  public catchEnter(trg: any): boolean {
    this.m_enter = true;
    return true;
  }
}

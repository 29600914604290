import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';
import { _OnError } from './WFDocumentAdp';
import { MailMessageInfo, MailMsgPreview } from '../data/data.service';
import { IWFObject, WFUser } from '../model';

export type FetchMails = (snd: WFMailFolderAdp, page: number, mails: Array<MailMessageInfo>, count: number) => void;

export class WFMailFolderAdp {
    public OnFetchMails: FetchMails;

    private m_OnError: EventAdp<_OnError>;

    private m_name: string;
    private m_count: number;
    private m_unread: number;
    private m_perpage: number;
    
    private m_login: string;
    private m_passreq: boolean;
    private m_password: string;

    //

    constructor(obj: any, usr: WFUser) {
        this.m_name= obj.fullname;
        this.m_count= obj.count; 
        this.m_unread= obj.unread;
        this.m_perpage= 100;
        this.OnFetchMails = null;
        this.m_OnError = new EventAdp<_OnError>(); 
        
        this.m_login= usr.IMAP_User;
        this.m_passreq= (IWFObject.IsNullOrEmpty(usr.IMAP_Password));
        this.m_password= null;
    }

    public Update(obj: any): void {
        this.m_count= obj.count; 
        this.m_unread= obj.unread;
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Count(): number {
        return this.m_count;
    }

    public get Unread(): number {
        return this.m_unread;
    }

    public get TotalDocs(): number {
        return this.m_unread;// this.m_count;
    }

    public get PerPage(): number {
        return this.m_perpage;
    }

    //

    public get Login(): string {
        return this.m_login;
    }

    public get PasswordRequired(): boolean {
        return this.m_passreq;
    }

    public GetEncPassword(): string {
        if(IWFObject.IsNullOrEmpty(this.m_password)) return '';

        let cdate = new Date();
        return IWFObject.EncryptPassword(this.m_password, cdate);
    }

    public FetchMails(serv: CacheService, page: number, keywords: string, 
                     login: string, password: string, remember: boolean,
                     only_unread: boolean, only_unbind: boolean): void {
        const self = this;
                        
        if(IWFObject.IsNullOrEmpty(login)) {
            login= this.m_login;
        }
        
        if(IWFObject.IsNullOrEmpty(password)) {
            password= this.m_password;
        } 

        let encpass: string;
        if(IWFObject.IsNullOrEmpty(password)) {
            encpass= null;
        } else {
            let cdate = new Date();
            encpass= IWFObject.EncryptPassword(password, cdate);
        }

        serv.DataService.fetchMails(serv.SessionID, this.m_name, page, this.m_perpage, keywords, 
                                    login, encpass, remember, only_unread, only_unbind,
        this.m_OnError, (mails, count, unread) => {
            self.FetchMailsCompleted(serv, page, mails, count, unread);
            self.m_login= login;
            self.m_password= password;
            self.m_passreq= false;
        });
    }

    private FetchMailsCompleted(serv: CacheService, page: number, mails: Array<MailMessageInfo>, count: number, unread:number): void {
        if(count != this.m_count) this.m_count= count;
        if(unread != this.m_unread) this.m_unread= unread;
        
        if (this.OnFetchMails != null)
            this.OnFetchMails(this, page, mails, count);
    }

    public DecUnread(): void {
        if(this.m_unread > 0) this.m_unread--;
    }

    public IncUnread(): void {
        if(this.m_unread < this.m_count) this.m_unread++;
    }
}
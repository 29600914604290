export enum WFObjType {
    USER,
    USERGROUP,
    AUTHSCHEMA
}

export class WFSchemaRecipient {
    private m_id: number;
    private m_obj_id: number;
    private m_obj_type: WFObjType;
    private m_order: number;
    private m_addinfo: string;

    //

    public static Create(obj_id: number, rc: WFObjType): WFSchemaRecipient {
        return new WFSchemaRecipient({
            id: 0,
            obj_id: obj_id,
            obj_type: rc,
            order: 0,
            addinfo: ''
        });
    }

    public static Create2(src: WFSchemaRecipient): WFSchemaRecipient {
        return new WFSchemaRecipient({
            id: 0,
            obj_id: src.m_obj_id,
            obj_type: src.m_obj_type,
            order: src.m_order,
            addinfo: src.m_addinfo
        });
    }

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_obj_id = obj.obj_id;
        this.m_obj_type = obj.obj_type;
        this.m_order = obj.order;
        this.m_addinfo = obj.addinfo;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public set ObjID(value: number) {
        if (value < 0) throw new Error();
        this.m_obj_id = value;
    }

    public get ObjID(): number {
        return this.m_obj_id;
    }

    //

    public set ObjType(value: WFObjType) {
        this.m_obj_type = value;
    }

    public get ObjType(): WFObjType {
        return this.m_obj_type;
    }

    //

    public set Order(value: number) {
        if (value < 0) throw new Error();
        this.m_order = value;
    }

    public get Order(): number {
        return this.m_order;
    }

    //

    public get AdditionalInfo(): string {
        return this.m_addinfo;
    }
}
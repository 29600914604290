export enum WFColumnTarget {
    NONE,
    SUBREPORT,
    DOCUMENT,
    DAILYCORRESPONDENCE
}

export enum WFColFrmObjType {
    DAILYCORRCLASS,
    INTERACTIVEDICT
}

export class WFColumnFormat {
    private m_id: number;
    private m_columncode: string;
    private m_label: string;
    private m_sortable: boolean;
    private m_target: WFColumnTarget;
    private m_targetclassid: number;
    private m_paramsmapping: Map<string, string>;

    private m_width: number;
    private m_group: number;
    private m_groupasc: boolean;

    private m_decscale: number;
    private m_alignright: boolean;
    private m_sumable: boolean;
    
    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_columncode = obj.columncode;
        this.m_label = obj.label;
        this.m_sortable = obj.sortable;
        this.m_target = obj.target;
        this.m_targetclassid = obj.targetclassid;
        this.m_paramsmapping = new Map<string, string>();
        for (let kv of obj.paramsmapping) this.m_paramsmapping.set(kv.key, kv.value);
        this.m_width= obj.width;
        this.m_group= obj.group;
        this.m_groupasc= obj.groupasc;
        this.m_decscale= obj.decscale;
        this.m_alignright= obj.alignright;
        this.m_sumable= obj.sumable;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public get ColumnCode(): string {
        return this.m_columncode;
    }

    public get Label(): string {
        return this.m_label;
    }

    public get Sortable(): boolean {
        return this.m_sortable;
    }

    public get Target(): WFColumnTarget {
        return this.m_target;
    }

    public get TargetClassID(): number {
        return this.m_targetclassid;
    }

    public get ParametersMapping(): Map<string, string> {
        return this.m_paramsmapping;
    }

    public get Width(): number {
        return this.m_width;
    }
        
    public get GroupOrder(): number {
        return this.m_group;
    }

    public get GroupAsc(): boolean {
        return this.m_groupasc;
    }

    public get DecimalScale(): number {
        return this.m_decscale;
    }

    public get AlignRight(): boolean {
        return this.m_alignright;
    }

    public get Sumable(): boolean {
        return this.m_sumable;
    }
}
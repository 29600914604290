export class WFOcrTextRegion {
    private m_text: string;
    private m_posx: number;
    private m_posy: number;
    private m_width: number;
    private m_height: number;

    //

    constructor(obj: any) {
        this.m_text = obj.text;
        this.m_posx = obj.posx;
        this.m_posy = obj.posy;
        this.m_width = obj.width;
        this.m_height = obj.height;
    }

    //

    public get Text(): string {
        return this.m_text;
    }

    public get PosX(): number {
        return this.m_posx;
    }

    public get PosY(): number {
        return this.m_posy;
    }

    public get Width(): number {
        return this.m_width;
    }

    public get Height(): number {
        return this.m_height;
    }

    //

    public ToObject(): any {
        return {
            text: this.m_text,
            posx: this.m_posx,
            posy: this.m_posy,
            width: this.m_width,
            height: this.m_height
        };
    }
}
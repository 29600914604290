import { IWFCustomInstruction, WFInstructionType } from './IWFCustomInstruction';

export enum WFLineOpType {
    GETLINES,
    SETLINES,
    CLEARLINES,
    ADDLINES,
    DELLINES,
    GETHEADER,
    SETHEADER,
    SAVEDOC,
    GETREFS,
    SETREFS,
    CLEARREFS,
    ADDREFS,
    DELREFS
}

export class WFLineopInstruction extends IWFCustomInstruction {
    private m_ltype: WFLineOpType;
    private m_variable_id: number;

    //

    constructor(obj: any) {
        super(obj.int_id, obj.name, obj.description, WFInstructionType.LINEOP);
        this.m_ltype = obj.ltype;
        this.m_variable_id = obj.variable_id;
    }


    //

    public get OpType(): WFLineOpType {
        return this.m_ltype;
    }

    //

    public get VariableID(): number {
        return this.m_variable_id;
    }
}
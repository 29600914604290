import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appElementExtender]'
})

export class ElementExtenderDirective {
  private m_ele: ElementRef;

  constructor(ele: ElementRef) { 
    this.m_ele = ele;
  }

  @Input() 
  public set UserObject(nval: Object) {
    this.m_ele.nativeElement.UserObject = nval;
  }
}

/**
 * New typescript file
 */

export const LANG_EN_MAP = {
  'strDataServiceConnErr': 'No connection with SPUMA data service',
  'strNeedLogin': 'Enter Username and password',
  'AllUserGroup': '(All users)',
  'askSaveAsTemplate': 'Do you want to save this document as template?',
  'btAdd': 'Add',
  'btCancel': 'Cancel',
  'btNewColor': 'Random',
  'btnLblAll': 'Change all rows',
  'btnLblSet': 'Set',
  'btNo': 'No',
  'btNowyWpis': 'New entry',
  'btOK': 'OK',
  'btPrint': 'Print',
  'btSave': 'Save',
  'btSend': 'Send',
  'btYes': 'Yes',
  'hintClear': 'Clear',
  'hintSearch': 'Search for documents',
  'cbvOtherApprovals': 'Find in approvals',
  'cbvOtherApproved': 'Approved',
  'cbvOtherCompanies': 'Find in companies',
  'cbvOtherRejected': 'Rejected',
  'cbvOtherTrash': 'Find in trash',
  'cbvOtherWait': 'Waiting',
  'clBlack': 'Black',
  'clBlue': 'Blue',
  'clBrown': 'Brown',
  'clCyan': 'Cyan',
  'clDarkGray': 'Dark Gray',
  'clGray': 'Gray',
  'clGreen': 'Green',
  'clLightGray': 'Light Gray',
  'clMagenta': 'Magenta',
  'clOrange': 'Orange',
  'clOther': 'Other',
  'clPurple': 'Purple',
  'clRed': 'Red',
  'clWhite': 'White',
  'clYellow': 'Yellow',
  'cmbClassesNone': 'None',
  'cmbSchemaManual': 'Custom',
  'cmbSchemasNone': 'None',
  'enumWFAuthStatusAPPROVED': 'Approved',
  'enumWFAuthStatusDISAPPROVED': 'Rejected',
  'enumWFAuthStatusWAITING': 'Waiting',
  'enumWFDocObjTypeUSER': 'User',
  'enumWFDocObjTypeUSERGROUP': 'Users group',
  'enumWFDocPrmTypeCHANGE': 'Change',
  'enumWFDocPrmTypeDISABLE': 'Disable',
  'enumWFDocPrmTypeVIEW': 'View',
  'enumWFObjTypeAUTHSCHEMA': 'Authorization schema',
  'enumWFObjTypeUSER': 'User',
  'enumWFObjTypeUSERGROUP': 'Users group',
  'errCannotChangeDocStatus': 'Changing status is prohibited. Define authorization schema first',
  'errCannotChangePassword': 'Insufficient  data for changing password.',
  'errCannotChangeSchemaApproved': 'Changing authorization schema is prohibited.\nDocument is already in authorization phase.',
  'errCannotChangeSchemaUndefined': 'Define authorization schema first.',
  'errCannotDelComment': 'You cannot remove comment',
  'errCannotGetCustomer': 'You cannot add BP:',
  'errCannotUpdateCustomer': 'You cannot modify BP:',
  'errDocCannotBeModified': 'You cannot modify document {0}.\n{1}',
  'errFolderCannotDelete': 'You cannot delete folder {0}.\n{1}',
  'errFolderCannotModify': 'You cannot modify folder {0}.\n{1}',
  'errHiddenAttachment': 'Can not open the attachment. The document is in the Repository or you do not have permissions.',
  'errMessageSending': 'An error occurred while trying to send a message.\nCheck SMTP configuration.',
  'errNoCurrentSession': 'Invalid session identifier',
  'errWrongDataInForm': 'The form contains incorrect data',
  'eventTypeApprove': 'Document approved',
  'eventTypeApprovedByAll': 'Document approved by all',
  'eventTypeCommented': 'Document commented',
  'eventTypeDocChanged': 'Document changed',
  'eventTypeNeedAuth': 'Authorization required',
  'eventTypeNeedComment': 'Comment required',
  'eventTypeNone': 'Other',
  'eventTypeReject': 'Document rejected',
  'fldAlerts': 'Alerts',
  'fldAuthByMe': 'Approved',
  'fldAuthByOther': 'Approved (others)',
  'fldAuthorizations': 'Approval',
  'fldAuthWait': 'Waiting for approval',
  'fldFavourites': 'Favourites',
  'fldObserved': 'Obserwowane',
  'fldRejectByMe': 'Rejected',
  'fldRejectByOther': 'Rejected (others)',
  'fldSearchResult': 'Search results',
  'fldSecretary': 'Inbox',
  'fldSecretaryChecked': 'Check Out',
  'fldSecretaryOCR': 'OCR Processing ...',
  'fldSecretaryReaded': 'Imported',
  'fldSecretaryTemp': 'Templates',
  'fldTrash': 'Trash',

  //Kendo Grid
  'kgrdgroupPanelEmpty': 'Drag a column header to group',
  'kgrdnoRecords': 'No records available.',
  'kgrdpagerFirstPage': 'First page',
  'kgrdpagerLastPage': 'Last page',
  'kgrdpagerPreviousPage': 'Previous page',
  'kgrdpagerNextPage': 'Next page',
  'kgrdpagerPage': 'Page',
  'kgrdpagerItemsPerPage': 'items per page',
  'kgrdpagerOf': 'of',
  'kgrdpagerItems': 'items',
  'kgrdfilterEqOperator': 'Is equal to',
  'kgrdfilterNotEqOperator': 'Is not equal to',
  'kgrdfilterIsNullOperator': 'Is null',
  'kgrdfilterIsNotNullOperator': 'Not null',
  'kgrdfilterIsEmptyOperator': 'Is empty',
  'kgrdfilterIsNotEmptyOperator': 'Is not empty',
  'kgrdfilterStartsWithOperator': 'Starts with',
  'kgrdfilterContainsOperator': 'Contains',
  'kgrdfilterNotContainsOperator': 'Does not contain',
  'kgrdfilterEndsWithOperator': 'Ends with',
  'kgrdfilterGteOperator': '>=',
  'kgrdfilterGtOperator': '>',
  'kgrdfilterLteOperator': '<=',
  'kgrdfilterLtOperator': '<',
  'kgrdfilterIsTrue': 'true',
  'kgrdfilterIsFalse': 'false',
  'kgrdfilterBooleanAll': 'all',
  'kgrdfilterAfterOrEqualOperator': 'after or equal',
  'kgrdfilterAfterOperator': 'after',
  'kgrdfilterBeforeOperator': 'before',
  'kgrdfilterBeforeOrEqualOperator': 'before or equal',
  'kgrdfilterFilterButton': 'Filter',
  'kgrdfilterClearButton': 'Clear',
  'kgrdfilterAndLogic': 'And',
  'kgrdfilterOrLogic': 'Or',


  // hints
  'hintEditAuthSchemaAdd': 'Add authorization step',
  'hintEditAuthSchemaDel': 'Remove authorization step',
  'hintEditPermissionAdd': 'Add permission',
  'hintEditPermissionDel': 'Remove permission',
  'hintAddDailyCorr': 'Add entry to mailroom',
  'hintRefreshReport': 'Refresh',
  'hintExpandGroups': 'Expand',
  'hintCollapseGroups': 'Collapse',
  'wDocViewer_hintInboxAsRead': 'Check as read',
  //'wDocViewer_hintFilterChecked': 'Fitruj wg zaznaczenia',
  //'wDocViewer_hintFilterUncorrect': 'Fitruj wg dopasowania',
  'hintDocFilter': 'Filters',
  'hintSortAsc': 'Ascending order',
  'hintSortDesc': 'Descending order',
  'hintSortBy': 'Sort by ...',
  'hintMinimizeWindow': 'Minimize window',
  'hintMaximizeWindow': 'Maximize window',
  'hintReload': 'Refresh',
  'hintAddDocStandard': 'Add & send to OCR',
  'hintAddDocFromMail': 'Add from mail',
  'hintAddDocWithoutOCR': 'Add new from file',
  'hintAddDocMore': 'More ...',
  // 'hintAddDocFromTemplate': 'Dodaj dokument z szablonu',
  // 'hintAddDocOcrType': 'Czy dokumenty dodać do OCR',
  // 'hintAddSboPH': 'Dodaj nowego klienta',
  // 'hintAlerts': 'User alerts',
  // 'hintApprByMe': 'Documents approved by me',
  // 'hintApprbyOther': 'Documents approved by others',
  // 'hintApprove': 'Approvals',
  // 'hintApproveButton': 'Documents approvals',
  'hintApproveHistButton': 'Approval history',
  // 'hintApprWait': 'Documents pending approval',
  // 'hintCancel': 'Anuluj',
  // 'hintClearSBOPH': 'Anuluj wybór klienta',
  // 'hintClearSearchOptions': 'Clear search options',
  // 'hintCollapseGroup': 'Zwiń wszystkie grupy',
  // 'hintCommentsButton': 'Show/hide/add comments',
  // 'hintCutPageButton': 'Cut page',
  // 'hintDailyCorr': 'Mailroom',
  // 'hintDailyCorrAddButton': 'Dodaj do tymczasowego dziennika',
  // 'hintDailyCorrAddNewButton': 'Dodaj do nowego wpisu dziennika',
  // 'hintDeleted': 'Deleted documents',
  // 'hintDelPageButton': 'Delete document',
  // 'hintDissByMe': 'Documents rejected by me',
  // 'hintDissByOther': 'Documents rejected by others',
  // 'hintDocCheck': 'Fill & Publish documents',
  // 'hintDocCreateName': 'Automatyczne generowanie nazwy dokumentu',
  // 'hintDocEditPermission': 'Edycja uprawnień',
  // 'hintDocEntered': 'Imported documents',
  // 'hintDocOcr': 'Documents in OCR',
  // 'hintEditButton': 'Edit',
  // 'hintEditDocSchema': 'Podgląd / edycja schematu zatwierdzania',
  // 'hintEditSboPH': 'Edytuj klienta',
  'hintExpandGroup': 'Rozwiń wszystkie grupy',
  // 'hintFavAddButton': 'Add to favourites',
  // 'hintFavDelButton': 'Remove from favourites',
  // 'hintFavourites': 'Favourite documents',
  // 'hintFavouritesClear': 'Clear folder',
  // 'hintFound': 'Search results',
  // 'hintHistoryButton': 'Change log',
  // 'hintImportButton': 'Import from file',
  // 'hintLogin': 'Zaloguj',
  'hintLogout': 'Logout',
  'hintNextPage': 'Następna strona',
  'hintOcrLogsButton': 'OCR service logs',
  // 'hintOK': 'Zatwierdź',
  // 'hintOptionsButton': 'Show options',
  // 'hintPastePageButton': 'Paste page',
  'hintPrevPage': 'Poprzednia strona',
  // 'hintPrintDocButton': 'Print page',
  'hintProfile': 'User settings',
  // 'hintPublishButton': 'Save',
  // 'hintRecentDailyCorr': 'Added to clipboard documents',
   //'hintRecents': 'Last used documents',
  // 'hintRecentsClear': 'Czyszczenie historii przeglądania',
  // 'hintReloadButton': 'Cancel / refresh',
  // 'hintReplaceButton': 'Replace from file',
  // 'hintReports': 'Interactive reports',
  // 'hintRotateLeft': 'Rotate left',
  // 'hintRotateRight': 'Rotate right',
  // 'hintSaveAsTemplate': 'Save as template',
  // 'hintSaveDocButton': 'Retrieve document from Repository',
  // 'hintSBOCreateCode': 'Create BP Code',
  'hintSearchAdvanced': 'Advanced search',
  'hintSearchAdvancedClose': 'Close advanced search',
  'hintSearchKeywords': 'Search',
  // 'hintSecretary': 'Repository',
  // 'hintSendToWFButton': 'Publish',
  // 'hintShowAttachments': 'Show attachments',
  // 'hintShowEdit': 'Show repository options',
  // 'hintShowOptions': 'Show options',
  // 'hintTemplates': 'Templates',
  // 'hintTextRegionsButton': 'Show/hide text regions',
  // 'hintToOCRButton': 'Send to OCR',
  // 'hintTrackingAddButton': 'Add to tracking',
  // 'hintTrackingDelButton': 'Remove from tracking',
  // 'hintWholePage': 'Na całą stronę',

  // Przyciski
  'wDocViewer_btnFilterLabel': 'Show:',
  'wDocViewer_btnFilterAll': 'All rows',
  'wDocViewer_btnFilterChecked': 'Checked only',
  'wDocViewer_btnFilterUnmatched': 'Unmatched only',

  //Labelki
  'lblERPRefChoose': 'Choose referenced object',


  'strUnableToSaveFile': 'Cannot save file',
  'rbvSearchAll': 'All',
  'rbvSearchChoosed': 'Choosed',
  'strAdd': 'Add',
  'strAdded': 'added',
  'strAddedAfter': 'Added after:',
  'strAddedBefore': 'Added before:',
  'strAdditionalFields1': 'Additional - {0}',
  'strAlerts': 'Alerts',
  'strAlertsLoadInfo': 'Loading alerts …',
  'strApprByMe': 'Approved',
  'strApprByOther': 'Approved (others)',
  'strApprovalFor': '(as {0})',
  'strApprove': 'Approvals',
  'strApproveBtn': 'Approve',
  'strApproveHistBtn': 'History',
  'strApprWait': 'Wait for approval',
  'strAskClearRights': 'Clear documents approvals?',
  'strAskCopySchema2Rights': 'Copy users from schema to custom rights?',
  'strAskOverwriteRights': 'Overwrite current rights?',
  'strAttachmentsData': 'Loading attachments …',
  'strAttDel': 'Remove attachment',
  'strAttribsSO': 'Attributes',
  'strAuthCustom': 'Custom',
  'strAuthMethodType': 'Autorization type',
  'strAuthNoAuth': 'None',
  'strAuthSchemaID': 'Schema',
  'strAuthSO': 'Authorization schemas',
  'strAuthUserLimit': 'Authorization user limit',
  'strAutoPublish': 'Auto. publish',
  'strBindDocuments': 'Link documents',
  'strCannotMoveDocuments': 'Cannot move documents from repository folder',
  'strCannotMoveDocumentsComp': 'Cannot move documents between comapnies',
  'strCatalogAdd': 'Add folder',
  'strCatalogChange': 'Change folder',
  'strCatalogDel': 'Delete folder',
  'strChangePassword': 'Change password',
  'strCheck': 'Check',
  'strCheckAtLeast1Item': 'Check at leat one item',
  'strClassID': 'Class',
  'strClassSO': 'Type',
  'strClassUnknown': 'Unknown',
  'strClearFolderAskInfo': 'Empty folder?',
  'strClearSearchOptions': 'Set defaults',
  'strCompanies': 'Repository',
  'strCompanyDir': 'company dir',
  'strCompanyID': 'Company',
  'strCompanySO': 'Company',
  'strDailyCorr': 'Mailroom',
  'strDataUpdate': 'Saving data …',
  'strDel': 'Remove',
  'strDelCheckedLinesAsk': 'Remove slected items?',
  'strDeleted': 'Trash',
  'strDeleted2': 'deleted',
  'strDeleteDailyCorr': 'Are you sure you want to delete this entry?',
  'strDescription': 'Descritpion',
  'strDirectoryID': 'Directory',
  'strDirectoryListUpdateInfo': 'Updating folder list …',
  'strDissByMe': 'Rejected',
  'strDissByOther': 'Rejected (others)',
  'strDocAttachAskInfo': 'Attach this document?',
  'strDocCheck': 'Fill & Publish',
  'strDocDate': 'Document date',
  'strDocDateFrom': 'Document date from:',
  'strDocDateTo': 'Document date to:',
  'strIMAP': 'Mailbox',
  'strIMAP_Inbox': 'Inbox',
  'strDocEntered': 'Imported',
  'strDocFromFile': '(From file)',
  'strDocFromFileNOOCR': '(From file - without OCR)',
  'strDocNum': 'Number',
  'strDocOcr': 'OCR Processing ...',
  'strDocType': 'Document type',
  'strDocument': 'document',
  'strDocumentAlreadyRemoved': 'Document removed',
  'strDocumentApprovedInfo': 'Document  was initially approved',
  'strDocumentDiffCompany': 'Binding only possible within the same company',
  'strDocumentID': 'Document',
  'strDocumentNoApprovedInfo': 'Cannot approve document',
  'strDocumentNoRejectedInfo': 'Cannot reject document',
  'strDocumentNoReserveInfo': 'An error occurred while trying to lock/release the document',
  'strDocumentRejectedInfo': 'Document  was initially rejected',
  'strDocumentReserveInfo': 'The document was initially locked/released',
  'strDocumentUpdateAskInfo': 'Document {0} has been modified. Save  the current data?',
  'strDocUpdateInfo': 'Updating document …',
  'strEmpty': '(empty)',
  'strEmptyRTF': 'New editable doc.',
  'strEnterDate': 'Enter date',
  'strEntry': 'Enter',
  'strERPRef': 'ext references',
  'strErrPrms': 'Check parmaters value {0}',
  'strException': 'Exception',
  'strExit': 'Exit',
  'strExternalId': 'External id',
  'strFavourites': 'Favourites',
  'strFetchDocumentsInfo': 'Load documents list',
  'strField_AuthSchema': 'Authorization schema',
  'strField_ClassName': 'Class',
  'strField_Company': 'Company',
  'strField_CreateDate': 'Create date',
  'strField_Description': 'Description',
  'strField_DocDate': 'Document date',
  'strField_DocNum': 'Number',
  'strField_EnterDate': 'Enter date',
  'strField_Name': 'Name',
  'strField_UpdateDate': 'Update date',
  'strField_User': 'User',
  'strFileEmpty': 'No data !!!',
  'strFileTypes': 'All scans|*.bmp;*.jpg;*.png;*.tif;*.pdf|Bmp files|*.bmp|Jpg files|*.jpg|Png files|*.png|Tif files|*.tif|Tiff files|*.tiff|Pdf files|*.pdf',
  'strFileTypesAll': 'All scans|*.bmp;*.jpg;*.png;*.tif;*.pdf|Other types|*.rtf;*.txt;*.html|Pdf files|*.pdf|Bmp files|*.bmp|Jpg files|*.jpg|Png files|*.png|Tif files|*.tif|Tiff files|*.tiff|RTF files|*.rtf|TXT files|*.txt|HTML Files|*.html|All files|*.*',
  'strFilter_AuthSchema': 'Schema',
  'strFilter_AuthStatus': 'Authorization status',
  'strFilter_Class': 'Document class',
  'strFilter_Company': 'Company',
  'strFilter_Last5Days': 'From last 5 days',
  'strFilter_LastMonth': 'From last month',
  'strFilter_Other': 'Other',
  'strFilter_ToDay': 'Today',
  'strFilter_User': 'User',
  'strFindLineValuesInfo': 'Search saved values',
  'strFirstSaveDataInfo': 'Save changes first!',
  'strFolderAddedInfo': 'New folder',
  'strFolderAddedInfo2': 'New folder {0}',
  'strFolderDelAskInfo': 'Remove this folder?',
  'strFolderDelInfo': 'Removing folder …',
  'strFolderUpdateInfo': 'Folder updating …',
  'strFound': 'Search results',
  'strGroupLineAddAsk': 'Adding lines only possible in ungrouped view. Will ungroup lines and add a new one?',
  'strHide': 'Schowaj',
  'strIncorrectData': 'Incorrect data',
  'strLineOwnerDescription': 'Created {0} by {1}',
  'strLines': 'lines',
  'strList': 'List',
  'strLogout': 'LOGOUT',
  'strMessageSent': 'Message is sent',
  'strModified': 'modified',
  'strMoveDocToCmpAskInfo': 'Are you sure you want to move the document in to repository?',
  'strMoveDocumentAskInfo': 'Move this document?',
  'strMsgUpdateInfo': 'Message updating …',
  'strName': 'Name',
  'strNeedSelectRow': 'In moving mode is required to check the source line',
  'strNone': 'none',
  'strNoPagesToPrint': 'No pages to print',
  'strNoResults': 'No results',
  'strNoRights4DocMoveInfo': 'Insufficient rights for moving this document.',
  'strNoRightsInfo': 'No rights',
  'strOther': 'Other',
  'strOtherSO': 'Miscellaneous',
  'strOwnerSO': 'Owner',
  'strPage': 'PAGE',
  'strPages': 'pages',
  'strPermissions': 'permissions',
  'strPrintEnterDate': 'Enter date',
  'strRead': '(read)',
  'strRecentDailyCorr': 'Clipboard',
  'strRecents': 'Recents',
  'strRecipients': 'schema recipients',
  'strReloadAskInfo': 'Do You want to discard the changes?',
  'strRemoveDailyCorrFail': 'Entry {0} can not be removed',
  'strRemoveDocumentAskInfo': 'Delete this dcoument?',
  'strRemovingDocumentInfo': 'Deleting document …',
  'strReplacement': '{0} for {1}',
  'strReports': 'Reports',
  'strResourceChanging': 'Change of resource may cause dysfunction of the search process. Are you sure?',
  'strSaveChangesInfo': 'Saving changes ….',
  'strSBOServiceError': 'Unable to connect with SAP Business One',
  'strSchemaName': 'Schema',
  'strSearchInComments': 'Comments',
  'strSearchInDescr': 'Description',
  'strSearchInKeywords': 'Keywords',
  'strSearchInNo': 'Number',
  'strSecretary': 'Inbox',
  'strSendDataInfo': 'Sending data …',
  'strSerchInName': 'Name',
  'strSignatureNotFound': 'Signature not found',
  'strSort': 'Sort:',
  'strSortAsc': 'Ascending',
  'strSortDesc': 'Descending',
  'strSpecial': 'Special',
  'strSuggestDocNameInfo': 'Generating a new name',
  'strSuggestOcrMapInfo': 'Suggesting column mapping OCR',
  'strTemplateID': 'Template',
  'strTemplates': 'Templates',
  'strTmpWriteMode_AllowAll': 'Full edit',
  'strTmpWriteMode_AllowEditContent': 'Only content of document',
  'strTmpWriteMode_DenyAll': 'Read only',
  'strToday': 'today',
  'strTrash': 'trash',
  'strUncheck': 'Uncheck',
  'strUndefined': 'Undefined',
  'strUnknown': 'Unknown',
  'strUnsavedDateInfo': 'Do you want to save the changed values?',
  'strUserAuth': 'authorizations',
  'strUserDataUpdate': 'Updating user profile …',
  'strUserID': 'Creator',
  'strWaitClasses': 'Loading types …',
  'strWaitCompanies': 'Loading companies …',
  'strWaitCompareSchemas': 'Matching authorization schemas …',
  'strWaitConfig': 'Loading configuration',
  'strWaitConfirms': 'Loading approvals …',
  'strWaitCustomProcess': 'Loading custom processes',
  'strWaitDailyClass': 'Loading mailrooms',
  'strWaitData': 'Loading data …',
  'strWaitDefinitions': 'Load definitions',
  'strWaitDictionaries': 'Loading dictionaries …',
  'strWaitDicts': 'Loading dictionaries',
  'strWaitFoundSchemas': 'Loading authorization schemas …',
  'strWaitMessages': 'Loading messages …',
  'strWaitOCRSchemas': 'Loading OCR schemas',
  'strWaitProcess': 'Executing UI process {0}->{1}',
  'strWaitSchemas': 'Loadin authorization schemas …',
  'strWaitSearch': 'Searching …',
  'strWaitSendingMessage': 'Sending message …',
  'strWaitSession': 'Loading session …',
  'strWaitTemplates': 'Loading templates',
  'strWaitUser': 'Loading user data …',
  'strWaitUsers': 'Loading users …',
  'strWaitUsersGroup': 'Loading users groups …',
  'strZoom': 'Zoom',
  'sys_createdate': 'Created at',
  'sys_description': 'Description',
  'sys_operation': 'Type',
  'sys_operationdate': 'Entry date',
  'sys_operationjustdate': 'Entry date',
  'sys_operationjusttime': 'Entry time',
  'sys_usercode': 'User',
  'tblHdrCDate': 'CREATE DATE',
  'tblHdrDate': 'DATE',
  'tblHdrDescr': 'DESCRIPTION',
  'tblHdrName': 'NAME',
  'tblHdrNo': 'NUMBER',
  'tblHdrUDate': 'UPDATE DATE',
  'wApprovals_All': 'Everyone',
  'wApprovals_Clear': 'Clear',
  'wApprovals_ClearAsk': 'Clearing will reset authorization process. Are You sure? ',
  'wApprovals_Comment': 'Comment',
  'wApprovals_Date': 'Date',
  'wApprovals_History': 'History',
  'wApprovals_OneFrom': 'One of',
  'wApprovals_Title': 'Authorizations',
  'wApprovals_XFrom': 'from',
  'wApproveWnd_Approve': 'Approve',
  'wApproveWnd_Reject': 'Reject',
  'wApproveWnd_Release': 'Release',
  'wApproveWnd_Reserve': 'Reserve',
  'wApproveWnd_Title': 'Document authorization',
  'wCaptchaInput_Label': 'Type code from image',
  'wCaptchaInput_Title': 'Captcha',
  'wColumnMapDefinitions_cmbCopy': 'Copy',
  'wColumnMapDefinitions_cmbDontCopy': 'Don\'t copy',
  'wColumnMapDefinitions_cmbRemember': 'Remember',
  'wColumnMapDefinitions_cmbSearch': 'Search',
  'wColumnMapDefinitions_colClass': 'Type column',
  'wColumnMapDefinitions_colOperation': 'Operation',
  'wColumnMapDefinitions_colSchema': 'Schema column',
  'wColumnMapDefinitions_NoRelation': 'Undefined relation!',
  'wColumnMapDefinitions_Title': 'Column mapping',
  'wCommentAdp_ClickToAddComments': '(click to add comment)',
  'wConfiguration_Port': 'Port',
  'wConfiguration_Server': 'Server',
  'wConfiguration_Title': 'Configuration',
  'wCreateEditableDocument_Choose': 'Choose',
  'wCreateEditableDocument_Create': 'Create',
  'wCreateEditableDocument_EmptyDoc': 'Empty document',
  'wCreateEditableDocument_FileTypes': 'Supported file types|*.pdf;*.rtf;*.txt;*.html|Pdf files|*.pdf|RTF files|*.rtf|TXT files|*.txt|HTML files|*.html|All file types|*.*',
  'wCreateEditableDocument_Name': 'Name',
  'wCreateEditableDocument_NoPages': 'No pages',
  'wCreateEditableDocument_ReadFromFile': 'Read from file',
  'wCreateEditableDocument_ReadFromTemplate': 'Read from template',
  'wCreateEditableDocument_Template': 'Template',
  'wCreateEditableDocument_Title': 'Editable document',
  'wCreateEmptyDocument_Name': 'Name',
  'wCreateEmptyDocument_Template': 'Template',
  'wDirectoryName_Name': 'Name',
  'wDirectoryName_Title': 'Folder',
  'wDocumentAdp_Company': 'Company:',
  'wDocumentAdp_NoRights': '(no rights)',
  'wDocumentAdp_Published': 'Publisher:',
  'wDocumentModifications_colClass': 'Type',
  'wDocumentModifications_colField': 'Filed',
  'wDocumentModifications_colNewValue': 'New value',
  'wDocumentModifications_colOldValue': 'Old value',
  'wDocumentModifications_colOperation': 'Operation',
  'wDocumentModifications_ShowOld': 'Previous instance',
  'wDocumentModifications_Title': 'Document changelog',
  'wDocumentName_Title': 'Nazwa dokumentu',
  'wDocumentPreview_DocPreviewInfo': 'Preview \'{0}\'. Changed  {1} {2} by {3}',
  'wDocumentPreview_Title': 'Preview',
  'wDocumentPrint_cmbDontPrint': 'Don\'t print',
  'wDocumentPrint_cmbPrintLastPage': 'Print on seperate page',
  'wDocumentPrint_cmbPrintXPage': 'Print on page',
  'wDocumentPrint_EcoPrint': 'Single page',
  'wDocumentPrint_PrintAuthorizations': 'Print authorizations',
  'wDocumentPrint_PrintComments': 'Comments',
  'wDocumentPrint_PrintCommentsLayout': 'Comments preview',
  'wDocumentPrint_PrintingInfo': 'Printing …',
  'wDocumentPrint_PrintLines': 'Lines',
  'wDocumentPrint_prntArea': 'Area',
  'wDocumentPrint_prntComment': 'Comment',
  'wDocumentPrint_prntStatus': 'Status',
  'wDocumentPrint_prntTime': 'Time',
  'wDocumentPrint_prntUser': 'User',
  'wDocumentPrint_Range': 'Range - from',
  'wDocumentPrint_RangeTo': 'to',
  'wDocumentPrint_Title': 'Printing options',
  'wDocViewer_chbHideInvalid': 'Show unmatched',
  'wDocViewer_chbHideUncheck': 'Hide unchecked',
  'wDocViewer_ChoiceDstField': 'Choice destination field before selecting region',
  'wDocViewer_Company': 'Company:',
  'wDocViewer_Date': 'Date',
  'wDocViewer_Descr': 'Description:',
  'wDocViewer_DocDate': 'Document date:',
  'wDocViewer_DocDates': 'S: {0}  Z: {1}',
  'wDocViewer_DocDeleteAsk': 'Are You sure You want to delete document?',
  'wDocViewer_DocType': 'Type:',
  'wDocViewer_EnterDate': 'Recieved on:',
  'wDocViewer_CreateDate': 'Created:',
  'wDocViewer_errDocumentInOCR': 'Document in OCR!',
  'wDocViewer_errDocumentIsEmpty': 'Document is empty!',
  'wDocViewer_hintAddComment': 'Add new comment',
  'wDocViewer_hintAddEntry': 'Comment',
  'wDocViewer_hintAddLine': 'Add new line',
  'wDocViewer_hintCheck': 'Check',
  'wDocViewer_hintClearSel': 'Clear selection',
  'wDocViewer_hintCopyToLines': 'Copy to lines',
  'wDocViewer_hintDeleteComment': 'Delete comment',
  'wDocViewer_hintDeleteLine': 'Delete lines',
  'wDocViewer_hintReadFromFile': 'Read from file',
  'wDocViewer_hintReverse': 'Reverse selection',
  'wDocViewer_hintShowHideOcrLines': 'Show/hide the lines mapping OCR',
  'wDocViewer_hintUncheck': 'Uncheck',
  'wDocViewer_hintWriteToFile': 'Write to file',
  'wDocViewer_msgReceiptients': 'Sent',
  'wDocViewer_msgSubject': 'Subject',
  'wDocViewer_msgTo': 'To',
  'wDocViewer_Name': 'Name:',
  'wDocViewer_No': 'Number:',
  'wDocViewer_noDocument': 'No document',
  'wDocViewer_OCRCopyToLinesResult': '{0} added, {1} overwriten , {2} errors',
  'wDocViewer_OCRSchemaAll': 'All',
  'wDocViewer_OCRSchemaSugested': 'Sugested',
  'wDocViewer_OCRSynchronize': 'Synchronize',
  'wDocViewer_OpType': 'Operation type',
  'wDocViewer_Owner': 'Owner:',
  'wDocViewer_pendingOCR': 'Document waiting',
  'wDocViewer_replyMessage': 'Reply',
  'wDocViewer_Schema': 'Schema:',
  'wDocViewer_sendEMail': 'Send email',
  'wDocViewer_sendInternalMessage': 'Internal message',
  'wDocViewer_sendOCRSchemaToAltab': 'Send to Altab',
  'wDocViewer_sendToOCR': 'Send again to OCR',
  'wDocViewer_setDefaultOCRSchema': 'Set as default',
  'wDocViewer_synchronizeOCRSchema': 'Synchronize',
  'wDocViewer_tabComments': 'Comments',
  'wDocViewer_tabData': 'Data',
  'wDocViewer_tabERP': 'ERP',
  'wDocViewer_tabInbox': 'Inbox',
  'wDocViewer_tabLines': 'Lines',
  'wDocViewer_tabLMsgIn': 'Inbox',
  'wDocViewer_tabLMsgOut': 'Outbox',
  'wDocViewer_tabOCR': 'OCR',
  'wDocViewer_TmpWriteMode': 'Edit mode',
  'wDocViewer_userFieldsCode': 'Code',
  'wDocViewer_userFieldsDescr': 'Description',
  'wDocViewer_userFieldsDocDate': 'Document date',
  'wDocViewer_userFieldsDocName': 'Document name',
  'wDocViewer_userFieldsDocNo': 'Number',
  'wDocViewer_userFieldsEnterDate': 'Recieved on',
  'wDocViewer_userFieldsName': 'Name',
  'wDocViewer_userFieldsOwnerCode': 'Owner code',
  'wDocViewer_userFieldsOwnerName': 'Owner name',
  'wDownloadDocument_BusyText': 'Loading resources …',
  'wDownloadDocument_CheckAtLeastOne': 'Check at leat one item',
  'wDownloadDocument_Resources': 'Resources',
  'wDownloadDocument_Title': 'Retrieve document',
  'wEditAuthSchema_AddAtLeastOne': 'Add at least one recipient',
  'wEditAuthSchema_AddInfo': 'Additional info',
  'wEditAuthSchema_CopyToCustom': 'Copy',
  'wEditAuthSchema_CopyToRights': 'Copy to custom rights',
  'wEditAuthSchema_Limit': 'Limit',
  'wEditAuthSchema_Reciepients': 'Recipients',
  'wEditAuthSchema_Title': 'Authorization schema',
  'wEditAuthSchema_Type': 'Type',
  'wEditAuthSchema_XFrom': '(N) from',
  'wEditDailyCorrespondence_Title': 'Mailroom entry',
  'wEditDocument_ChooseDataField': 'Choose data field',
  'wEditParameters_Title': 'Edit parameters',
  'wEditPermissions_CopyFromSchema': 'Copy from auth. Schema',
  'wEditPermissions_History': 'Parent',
  'wEditPermissions_Title': 'Permissions',
  'wExObjectRefAdp_Header': 'Object {0} type {1}',
  'wExObjectRefAdp_Subject': 'Subject',
  'wFilterWnd_Title': 'Filter results',
  'wImportFileParams_ColDelims': 'Delimiter',
  'wImportFileParams_ColSpecials': 'Text identifier',
  'wImportFileParams_FRowIsCols': 'Header in first row',
  'wImportFileParams_Title': 'Parameters',
  'wLoginWnd_Login': 'Login:',
  'wLoginWnd_Password': 'Pass:',
  'wLoginWnd_Title': 'Login',
  'wImapLoginWnd_Login': 'User:',
  'wImapLoginWnd_Password': 'Pass:',
  'wImapLoginWnd_Title': 'Login to Imap',
  'wImapLoginWnd_Remember': 'Remember',
  'wmarkFind': 'Search',
  'wMessageAdp_Sender': 'Sender',
  'wMessageAdp_Subject': 'Subject',
  'wMessageWnd_Title': 'Message',
  'wMultiSelectPanel_selectAll': '(All)',
  'wOcrProcessLogs_colCreatedAt': 'Created at',
  'wOcrProcessLogs_colIsRead': 'Is new',
  'wOcrProcessLogs_colMessage': 'Messsage',
  'wOcrProcessLogs_colOrder': 'Entry',
  'wOcrProcessLogs_Title': 'OCR errors',
  'wPanelConfiguration_Title': 'Panel configuration',
  'wParentPermissions_IncludeDocument': 'Include document',
  'wParentPermissions_Title': 'Parent permissions',
  'wReportPreview_Title': 'Report',
  'wRequestComment_Ask4Comment': 'Comment request',
  'wRequestComment_Ask4CommentSubject': 'Comment request',
  'wRequestComment_Ask4CommentSubjectText': 'Comment request',
  'wRequestComment_AtLeastOne': 'Check at leat one recipient',
  'wRequestComment_ClassUnknown': 'unknown',
  'wRequestComment_Group': 'Group',
  'wRequestComment_Message': 'Message',
  'wRequestComment_MessageText': 'User {0} asks for comment reg. document  {1}({2}) type {3}',
  'wRequestComment_ShowAll': 'Show all',
  'wRequestComment_Subject': 'Subject',
  'wRequestComment_Title': 'Internal message',
  'wRequestComment_Users': 'Users',
  'wSboBusinessPartner_address': 'Address',
  'wSboBusinessPartner_clear': 'Clear',
  'wSboBusinessPartner_customerCode': 'BP Code',
  'wSboBusinessPartner_errCustomerNotFound': 'BP not found',
  'wSboBusinessPartner_errNoDataToAnalyze': 'Insufficient data',
  'wSboBusinessPartner_GetData': 'Loading content …',
  'wSboBusinessPartner_GetData4Customer': 'Loading data for',
  'wSboBusinessPartner_keywords': 'Keywords',
  'wSboBusinessPartner_ttclear': 'Clear Business Partner data',
  'wSboBusinessPartner_ttsearch': 'Search for Business Partner',
  'wSboBusinessPartner_ttedit': 'Edit Business Partner',
  'wSboBusinessPartner_ttadd': 'Add new Business Partner',
  'wSboBusinessPartner_name': 'Name',
  'wSboBusinessPartnerEdit_BillingAddress': 'Billing address',
  'wSboBusinessPartnerEdit_City': 'City',
  'wSboBusinessPartnerEdit_cmbCustomer': 'Customer',
  'wSboBusinessPartnerEdit_cmbProspekt': 'Lead',
  'wSboBusinessPartnerEdit_cmbSupplier': 'Supplier',
  'wSboBusinessPartnerEdit_Code': 'Code',
  'wSboBusinessPartnerEdit_Comments': 'Remarks',
  'wSboBusinessPartnerEdit_Country': 'Country',
  'wSboBusinessPartnerEdit_CustomerUpdate': 'BP updating …',
  'wSboBusinessPartnerEdit_DeliveryAddress': 'Delivery address',
  'wSboBusinessPartnerEdit_ForeignName': 'Foreign name',
  'wSboBusinessPartnerEdit_LicTradNum': 'VAT number',
  'wSboBusinessPartnerEdit_Name': 'Name',
  'wSboBusinessPartnerEdit_State': 'County/State',
  'wSboBusinessPartnerEdit_Street': 'Street',
  'wSboBusinessPartnerEdit_Title': 'BP master data',
  'wSboBusinessPartnerEdit_Type': 'Type',
  'wSboBusinessPartnerEdit_ZipCode': 'Zip code',
  'wSendEMail_ClearFormatting': 'Clear formating',
  'wSendEMail_EnterCorrectData': 'Enter correct data',
  'wSendEMail_Reciepients': 'Recipients',
  'wSendEMail_Subject': 'Subject',
  'wSendEMail_Title': 'Send document',
  'wSendReconSchemaRequest_Title': 'Send request for an OCR schema',
  'wTemplateName_Title': 'Template name',
  'wTemplateName_WriteMode': 'Edit mode',
  'wUserFieldDefinition_cmbDate': 'Date',
  'wUserFieldDefinition_cmbFloat': 'Float',
  'wUserFieldDefinition_cmbInteger': 'Integer',
  'wUserFieldDefinition_cmbText': 'Text',
  'wUserFieldDefinition_Description': 'Description',
  'wUserFieldDefinition_FieldId': 'Field ID',
  'wUserFieldDefinition_Title': 'User field definition',
  'wUserFieldDefinition_Type': 'Type',
  'wUserPanel_CommentsColor': 'Color',
  'wUserPanel_Email': 'Email',
  'wUserPanel_NewPass': 'New pass.',
  'wUserPanel_NewPass2': 'Repeat',
  'wUserPanel_OldPass': 'Old Pass.',
  'wUserPanel_ReplacementInfo': 'From',
  'wUserPanel_ReplacementInfo2': 'to',
  'wUserPanel_ReplacementInfo3': 'acts on my behalf',
  'wUserPanel_ReplacementInfo4': 'or ',
  'wUserPanel_SelectUser': '(none)',
  'wUserPanel_SendInfoAbout': 'Send alerts about',
  'wUserPanel_SendInfoCheckAsRead': 'Mark as Read',
  'wUserPanel_SendInfoSend': 'Send',
  'wUserPanel_Signature': 'Signature',
  'wUserPanel_Title': 'User settings',
  'wUserPanel_UserId': 'Login',
  'wUserPanel_Language': 'Language',
  'strDownload': 'Download',
  'strOnLineData': 'ONLINE DATA',
  'wEditCustomAuth_msg1': 'The "One of" type schema can not contain many levels.',
  'wEditCustomAuth_msg2': 'Error in the custom schema definition \r\n\r\n{0}',
  //menu entries
  'mnuTextRegionsOn': 'Show OCR regions',
  'mnuTextRegionsOff': 'Hide OCR regions',
  'mnuPages': 'Edit',
  'mnuPagesCut': 'Cut page',
  'mnuPagesPaste': 'Paste page',
  'mnuPagesImport': 'Load from file',
  'mnuChangeContent': 'Update content',
  'mnuAddAttachments': 'Add att. (from file)',
  'mnuToOCR': 'Send to OCR',
  'mnuSaveAsTemplate': 'Save as template',
  'mnuSendToAuthorize': 'Send to repository',
  'mnuComments': 'Comments ...',
  'mnuCommentsShow': 'Show comments',
  'mnuCommentsHide': 'Hide comments',
  'mnuCommentsAdd': 'Add comment',
  'mnuShowHistory': 'Show history',
  'mnuSaveToDisk': 'Download content',
  'mnuPrint': 'Print',
  'mnuDocumentRemove': 'Delete document',
  'mnuDocumentFavOn': 'Add to favourites',
  'mnuDocumentFavOff': 'Remove from favourites',
  'mnuDocumentObservedOn': 'Start follow',
  'mnuDocumentObservedOff': 'Stop follow',
  'mnuAttachment': 'Attachments ',
  'mnuCorrespondence': 'Mailroom',
  'mnuCorrespondenceNew': 'New entry',
  'mnuCorrespondenceAdd': 'Add to entry #',
  'mnuCorrespondenceItems': 'Entries',
  //tooltipy
  'ttSendToAuthorize': 'Send the document for authorization',
  'ttPublish': 'Save all changes',
  'ttReload': 'Reload document and cancel all changes',
  'ttShowAttachments': 'Show attachments',
  'ttTextRegionsOff': 'Hide OCR regions',
  'ttTextRegionsOn': 'Show OCR regions',
  'ttCommentsOff': 'Hide comments on document preview',
  'ttCommentsOn': 'Show comments on document preview',
  'ttCommentsAdd': 'Add new comment',
  'ttDocTabAttribute': 'Document properties',
  'ttDocTabLines': 'Lines',
  'ttDocTabMessages': 'Messages',
  'ttDocTabERP': 'External references',
  'ttDocTabOCR': 'OCR data',
  'ttDocTabComments': 'Comments',
  'ttFolderSecretary': 'Inbox folder',
  'ttFolderAlerts': 'Alerts',
  'ttFolderAuthorizations': 'Authorizations',
  'ttFolderSpecial': 'Special',
  'ttFolderRepository': 'Documents repository',
  //nowe
  'wDelFromDailyCorr': 'Remove entry',
  'strAlreadyExists': 'The entry already exists',
  'strCantFindEntry': 'No entries for jornal  {0}',
  'strNewFile': 'File',
  'strNewWoOCR': 'File (without OCR)',
  'strNewToAfOCR': 'File (to Imported)',

  'htmlTemplate': '<h1>[[NDOC]]</h1>',

  'strRES_IMAGEPDF': 'PDF file',
  'strRES_IMAGEPNG': 'PNG file',
  'strRES_PLAINTEXT': 'TXT file',
  'strRES_RTF': 'RTF file',

  'ttShowDailyCorRefs': 'Show mailroom entries',
  'hintDeleteDailyCorRef': 'Remove entry',
  'strRemoveFromDailyCorr': 'Are you sure you want to remove the document from the mailroom entry?',
  'strRemoveFromAttachments': 'Are you sure you want to remove the document from the attachemnts?',

  'strSessionLost': 'The session has expired. Please relogin',
  'strInvalidFTaxID': 'Enter the correct VAT registration number  !!!',

  'strImportTPL': 'No rights to add TPL resources.',
  'strNewDocPath': 'The directory {0} cannot be created. Use the default directory?.',

  'strLangEN': 'English',
  'strLangPL': 'Polish',

  'strObserved': 'Observed',
  'strNumCopied': 'Copied !!!',
  'wAboutWnd_Title': 'About SPUMA',

  'wAboutWnd_SpumaVer': 'Application version',
  'wAboutWnd_ModelVer': 'Data model version',
  'wAboutWnd_DSVer': 'DataService version',

  'mnuAbout': 'About',
  'wAboutWnd_CopyR': '© 2018 Altab S.A. All Rights Reserved',
  'strWaitVersion': 'Checking version',

  'strYesterday': 'Yesterday',
  'strHourAgo': 'Hour ago',
  'strHour': 'hrs',
  'strDaysAgo': 'days ago',

  'hintPrintReport': 'Print report',
  'hintCopyReport': 'Copy data',

  'wPrintReportTask_Title': 'Printing task',

  'wPrintReportTask_col_startat': 'Start At',
  'wPrintReportTask_col_rows': 'Rows',
  'wPrintReportTask_col_status': 'Status',
  'wPrintReportTask_col_options': 'Options',
  
  'wPrintReportTask_type': 'Operation type',
  'wPrintReportTask_addtask': 'Add task',

  'wPrintReportTask_type_rows': 'Only rows',
  'wPrintReportTask_type_wdoc': 'Rows and doc',

  'wPrintReportTask_status_waiting': 'Waiting',
  'wPrintReportTask_status_preparing': 'Preparing',
  'wPrintReportTask_status_error': 'Error',
  'wPrintReportTask_status_done': 'Ready',

  'wPrintReportTask_no_docidcol': 'Document reference column not found',
  'wPrintReportTask_show': 'Preview',
  'wPrintReportTask_save': 'Save',
  'wPrintReportTask_cnterr': 'Continue on error',

  'strCannotMoveDirectory': 'Cannot move directory to own child',

  'lblSWeekday1': 'Mon', //'Monday',
  'lblSWeekday2': 'Tue', //'Tuesday',
  'lblSWeekday3': 'Wed', //'Wednesday',
  'lblSWeekday4': 'Thu', //'Thursday',
  'lblSWeekday5': 'Fri', //'Friday',
  'lblSWeekday6': 'Sat', //'Saturday',
  'lblSWeekday7': 'Sun', //'Sunday',

  'lblMonth1': 'January',
  'lblMonth2': 'February',
  'lblMonth3': 'March',
  'lblMonth4': 'April',
  'lblMonth5': 'May',
  'lblMonth6': 'June',
  'lblMonth7': 'July',
  'lblMonth8': 'August',
  'lblMonth9': 'September',
  'lblMonth10': 'October',
  'lblMonth11': 'November',
  'lblMonth12': 'December',

  'lblSMonth1': 'Jan',
  'lblSMonth2': 'Feb',
  'lblSMonth3': 'Mar',
  'lblSMonth4': 'Apr',
  'lblSMonth5': 'May',
  'lblSMonth6': 'Jun',
  'lblSMonth7': 'Jul',
  'lblSMonth8': 'Aug',
  'lblSMonth9': 'Sep',
  'lblSMonth10': 'Oct',
  'lblSMonth11': 'Nov',
  'lblSMonth12': 'Dec',

  'wUserPanel_ClearMsgs': 'Check unread',
  'strCheckUnreadCompleted': '{0} messages confirmed',

  'mBoxVW_Col_ReciveDate': 'Recive Date',
  'mBoxVW_Col_From': 'From',
  'mBoxVW_Col_Subject': 'Subject',
  'mBoxVW_Col_ShortForm': 'Preview',
  'mBoxVW_Col_Attachments': 'Attachments',
  'mBoxVW_Col_Documents': 'Documents',

  'wMailBoxPreview_Subject': 'Subject',
  'wMailBoxPreview_From': 'From',
  'wMailBoxPreview_To': 'To',

  'wAddNewDocFromMail_Button': 'New document',
  'wAddNewDocFromMail_Title': 'New document from mail',
  'wAddNewDocCol_Sel': 'Select',
  'wAddNewDocCol_Type': 'Type',
  'wAddNewDocCol_Name': 'Name',
  'wAddNewDocFromMail_Atts': 'Attachments',

  'wAddNewDocType_Ctx': 'Body',
  'wAddNewDocType_Att': 'Attachment',

  'hintMarkAsSeen': 'Mark as read',
  'hintUnMarkSeen': 'Unmark read',

  'wDocViewer_hintDivide': 'Divide',
  'strCompanyChanged': 'Changing company can cause problems with BP data. The BP field will be cleared',

  'hintShowUnRead': 'Hide unread',
  'hintShowUnBind': 'Hide unbind',
  'wAddNewDocCol_Action': 'Action',

  'wAddNewDocCol_ActSepDoc': 'Separated document', //(bez OCR - do sprawdzonych)  
  'wAddNewDocCol_ActBegin': 'Join to begin', 
  'wAddNewDocCol_ActEnd': 'Join to end', 

  'wAddNewDocCol_ActOCR': 'OCR', //(Domyślnie dla załączników obrazu)
  'wAddNewDocCol_ActAffOCR': 'After OCR', //(domyślnie dla całej reszty załączników)
  'wAddNewDocCol_ActBefOCR': 'Before OCR',
  'strNeedSelAction': 'Required to select an action for the selected item',
  'strRefIMAPInfo': 'Updating imap folders',
  'wReportViewer_ShowDocuments': "Show documents",
  'wReportViewer_CopyDocNum' : "Store mailroom entry number",

  'strEDOC': 'Electronic documents',
  'EDOCVW_InvoiceReferenceNumber': 'Inv. num',
  'EDOCVW_InvoicingDate': 'Inv. date',
  'EDOCVW_SubjectBy': 'Subject by',
  'EDOCVW_SubjectTo': 'Subject to',
  'EDOCVW_Net': 'Net total',
  'EDOCVW_Currency': 'Currency',
  
  'EDOCVW_KsefReferenceNumber': 'KSeF ID',
  'EDOCVW_InvoiceHash': 'Check sum',
  
  'strEDocAlreadyBind': 'A document in SPUMA has already been created for the KSef document. Add another?',
  'strEDocNewDoc': 'Should add a new document?'
};


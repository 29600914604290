import { WFPageComment } from './WFPageComment';

export class WFDocumentPage {
    private m_id: number;
    private m_resources_id: number;
    private m_resources_pagenum: number;
    private m_rotation: number;
    private m_comments: Array<WFPageComment>;

    //

    public static Create(resources_id: number, resources_pagenum: number): WFDocumentPage {
        return new WFDocumentPage({
            id: 0,
            resources_id: resources_id,
            resources_pagenum: resources_pagenum,
            comments: []
        });
    }

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_resources_id = obj.resources_id;
        this.m_resources_pagenum = obj.resources_pagenum;
        this.m_rotation = obj.rotation;
        this.m_comments = new Array<WFPageComment>();
        for (let pc of obj.comments) this.m_comments.push(new WFPageComment(pc));
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public set ResourcesID(nval: number) {
        this.m_resources_id = nval;
    }

    public get ResourcesID(): number {
        return this.m_resources_id;
    }

    //

    public get ResourcesPageNum(): number {
        return this.m_resources_pagenum;
    }

    //

    public set Rotation(value: number) {
        this.m_rotation = value;
    }

    public get Rotation(): number {
        return this.m_rotation;
    }

    //

    public get Comments(): Array<WFPageComment> {
        return this.m_comments;
    }

    //

    public ToObject(): any {
        let comments = new Array<any>();
        for (let pc of this.m_comments) comments.push(pc.ToObject());

        return {
            id: this.m_id,
            resources_id: this.m_resources_id,
            resources_pagenum: this.m_resources_pagenum,
            rotation: this.m_rotation,
            comments: comments
        };
    }
}
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data/data.service';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { StringDataService } from '../stringdata/stringdata.service';
import { Md5 } from 'ts-md5/dist/md5';

import { WFClass, WFCompany, WFUser, WFUserGroup, WFAuthSchema, WFReconSchema, IWFObject } from '../model/index';
import { CacheService } from '../data/cache.service';

import { _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { CookieService } from 'ngx-cookie-service';

//

@Component({
  selector: 'app-login-wnd',
  templateUrl: './login-wnd.component.html',
  styleUrls: ['./login-wnd.component.css']
})

export class LoginWndComponent {
  private static PUBLICKEY1 = 'js83mw=\'43mxopw=2ie8qm\'23yiw3628973';
  private static PUBLICKEY2 = '[]=2783nms0wef]=-3jdfhwuewef8729348';
  private static PUBLICKEY3 = '92kdj934lerelrtf9345834rewfddsfewf34r][34r34r';

  //

  public m_login_str: string;
  public m_pass_str: string;
  public m_busy_str: string;


  @Output() SessionReloaded: EventEmitter<void>;

  //

  private m_onerrorevh: EventAdp<_OnError>;

  //

  constructor(private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    public m_str_srv: StringDataService,
    private m_cache_srv: CacheService,
    private m_cookie_srv: CookieService) {

    let lastuser = this.m_cookie_srv.get('lastlogin');
    //console.log(lastuser);
    if (IWFObject.IsNullOrEmpty(lastuser)) {
      this.m_login_str = '';
    } else {
      this.m_login_str = lastuser;
    }
    // this.m_login_str = (lastuser) ? lastuser : '';
    this.m_pass_str = '';
    this.m_busy_str = '';

    this.SessionReloaded = new EventEmitter<void>();

    this.m_onerrorevh = new EventAdp<_OnError>();
    const self = this;
    this.m_onerrorevh.subscribe(() => self.onError());
  }

  /*private ByteArrayToString(bytes: Uint8Array): string {
    let binary = '';
    let len = bytes.byteLength;
    for (let ii = 0; ii < len; ii++) {
      binary += String.fromCharCode(bytes[ii]);
    }
    return window.btoa(binary);
  }

  private StringToUint8Array(str: string): Uint8Array {
    let arr: Array<number> = [];
    for (let ii = 0; ii < str.length; ii++) {
      arr.push(str.charCodeAt(ii));
    }
    return new Uint8Array(arr);
  }

  private EncryptPassword(val: string, key: string): string {
    if (val.length > 0) {
      let inp: Uint8Array = this.StringToUint8Array(val);
      let enckey: Uint8Array = this.StringToUint8Array(key);
      for (let ii = 0; ii < inp.length; ii++) {
        let keyoff = ii % enckey.length;
        let aval = inp[ii] ^ enckey[keyoff];
        inp[ii] = aval;
      }
      return this.ByteArrayToString(inp);
    }
    return val;
  }*/

  private onError() {
    //this nie dziala
    this.m_busy_str = '';
  }

  public parseAndLogon(): void {
    try {
      if (this.m_login_str.length >= 4 && this.m_pass_str.length >= 4) {
        let login = this.m_login_str.trim();
        let pass = this.m_pass_str.trim();

        let cdate = new Date();

        let epass= IWFObject.EncryptPassword(pass, cdate);
        /*let epass = this.EncryptPassword(pass, LoginWndComponent.PUBLICKEY1);
        epass = this.EncryptPassword(epass, Md5.hashAsciiStr(cdate.getDate() + '' + (cdate.getMonth() + 1) + '' + cdate.getFullYear()).toString());
        epass = this.EncryptPassword(epass, LoginWndComponent.PUBLICKEY2);
        epass = this.EncryptPassword(epass, Md5.hashAsciiStr(cdate.getDate() + '' + (cdate.getMonth() + 1) + '' + cdate.getFullYear()).toString());
        epass = this.EncryptPassword(epass, LoginWndComponent.PUBLICKEY3);
        epass = this.EncryptPassword(epass, Md5.hashAsciiStr((cdate.getMonth() + 1) + '' + cdate.getDate() + '' + cdate.getFullYear()).toString());
        */

        const self = this;

        this.m_busy_str = this.m_str_srv.getStr('strWaitSession');
        this.m_data_srv.getSessionID(login, epass, this.m_onerrorevh,
          (sid) => {
            //this.m_cookie_srv.put('lastlogin', this.m_login_str);
            this.m_cookie_srv.set('lastlogin', this.m_login_str);            
            self.GetSessionIDCompleted(sid);
          });

      } else {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_str_srv.getStr('strNeedLogin'));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private GetSessionIDCompleted(sid: string): void {
    try {
      if (sid == null || sid.length === 0) {
        this.m_busy_str = '';
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_str_srv.getStr('strIncorrectData'));
      } else {
        const self = this;
        this.m_cache_srv.LoadAll(sid,
          (msgid: string) => {
            self.m_busy_str = self.m_str_srv.getStr(msgid);
          },
          this.m_onerrorevh,
          () => {
            self.m_busy_str = '';
            self.SessionReloaded.emit();
          });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //


}

import { IWFObject } from './IWFObject';

//

export class WFDocumentLineRef {
    private m_documentocrresult_id: number;
    private m_ocrlinenum: number;
    private m_remember: Array<[string, string]>;

    //

    public static Create(documentocrresult_id: number, ocrlinenum: number): WFDocumentLineRef {
        return new WFDocumentLineRef({
            documentocrresult_id: documentocrresult_id,
            ocrlinenum: ocrlinenum,
            remember: []
        });
    }

    //

    constructor(obj: any) {
        this.m_documentocrresult_id = obj.documentocrresult_id;
        this.m_ocrlinenum = obj.ocrlinenum;
        this.m_remember = new Array<[string, string]>();
        for (let kv of obj.remember) this.m_remember.push([kv.key, kv.value]);
    }

    //

    public get DocumentOcrResultID(): number {
        return this.m_documentocrresult_id;
    }

    public get OcrLineNum(): number {
        return this.m_ocrlinenum;
    }

    public get Remember(): Array<[string, string]> {
        return this.m_remember;
    }

    public ToObject(): any {
        let remember = new Array<any>();
        for (let kv of this.m_remember) remember.push({ key: kv[0], value: kv[1] });

        return {
            documentocrresult_id: this.m_documentocrresult_id,
            ocrlinenum: this.m_ocrlinenum,
            remember: remember
        };
    }
}

//

export class UpperMap {
    private m_map: Map<string, string>;

    constructor() {
        this.m_map = new Map<string, string>();
    }

    public set(key: string, value: string) {
        return this.m_map.set(key.toUpperCase(), value);
    }

    public get(key: string): string {
        return this.m_map.get(key.toUpperCase());
    }

    public has(key: string): boolean {
        return this.m_map.has(key.toUpperCase());
    }

    public get size(): number {
        return this.m_map.size;
    }

    public clear(): void {
        this.m_map.clear();
    }

    public delete(key: string): boolean {
        return this.m_map.delete(key);
    }

    public entries(): IterableIterator<[string, string]> {
        return this.m_map.entries();
    }
}

//

export class WFDocumentLine extends UpperMap {
    private m_user_id: number;
    private m_refs: Array<WFDocumentLineRef>;
    private m_createdat: Date;

    //

    public static Create(uid: number): WFDocumentLine {
        return new WFDocumentLine({
            user_id: uid,
            createdat: new Date(),
            base: [],
            refs: []
        });
    }

    public static Create2(uid: number, cat: Date): WFDocumentLine {
        return new WFDocumentLine({
            user_id: uid,
            createdat: cat,
            base: [],
            refs: []
        });
    }

    //

    constructor(obj: any) {
        super();
        this.m_user_id = obj.user_id;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        for (let kv of obj.base) this.set(kv.key, kv.value);

        this.m_refs = new Array<WFDocumentLineRef>();
        for (let rf of obj.refs) this.m_refs.push(new WFDocumentLineRef(rf));
    }

    //

    public get UserID(): number {
        return this.m_user_id;
    }

    public get Refs(): Array<WFDocumentLineRef> {
        return this.m_refs;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public ToObject(): any {
        let base = new Array<any>();
        for (let kv of Array.from(this.entries())) base.push({ key: kv[0], value: kv[1] });

        let refs = new Array<any>();
        for (let rf of this.m_refs) refs.push(rf.ToObject());

        return {
            user_id: this.m_user_id,
            createdat: IWFObject.DateToString(this.m_createdat),
            base: base,
            refs: refs
        };
    }
}

import { WFDocument } from './WFDocument';
import { IWFProcessVariableContainer } from './IWFProcessVariableContainer';
import { WFProcessVarState } from './WFProcessVarState';
import { WFSystem } from './WFSystem';
import { WFProcessVariable, WFProcessVarType } from './WFProcessVariable';
import { IWFObject } from './IWFObject';

export enum WFProcessCndFunctType {
    CND_EQUAL,
    CND_NEQUAL,
    CND_GREATER,
    CND_GREATEREQ,
    CND_LESS,
    CND_LESSEQ,
    CND_RX,
    CND_NRX,
    CND_IN,
    CND_NOTIN
}

export enum WFProcessGroupCndType {
    CND_AND,
    CND_OR
}

export enum WFProcessCndType {
    TYPE_BASIC,
    TYPE_GROUP
}

export class WFProcessCndFunct {
    private m_int_id: number;
    private m_type: WFProcessCndType;
    //jesli basic
    private m_l_variable_id: number;
    private m_cnd_type: WFProcessCndFunctType;
    private m_r_variable_id: number;
    private m_r_const: string;
    //jesli grupa
    private m_grpop_type: WFProcessGroupCndType;
    private m_grpop_functs: Array<WFProcessCndFunct>;
    //
    private m_l_column: string;

    //

    constructor(obj: any) {
        this.m_int_id = obj.int_id;
        this.m_type = obj.type;
        this.m_l_variable_id = obj.l_variable_id;
        this.m_cnd_type = obj.cnd_type;
        this.m_r_variable_id = obj.r_variable_id;
        this.m_r_const = obj.r_const;
        this.m_grpop_type = obj.grpop_type;
        this.m_grpop_functs = new Array<WFProcessCndFunct>();
        for (let fnc of obj.grpop_functs) this.m_grpop_functs.push(new WFProcessCndFunct(fnc));

        this.m_l_column = obj.l_column;
    }

    //

    public get InternalID(): number {
        return this.m_int_id;
    }

    //

    public get Type(): WFProcessCndType {
        return this.m_type;
    }

    //

    public get LeftVariableID(): number {
        return this.m_l_variable_id;
    }

    //

    public get LeftColumn(): string {
        return this.m_l_column;
    }

    //

    public get ConditionType(): WFProcessCndFunctType {
        return this.m_cnd_type;
    }

    //

    public get RightVariableID(): number {
        return this.m_r_variable_id;
    }

    //

    public get ConstValue(): string {
        return this.m_r_const;
    }

    //

    public get GroupType(): WFProcessGroupCndType {
        return this.m_grpop_type;
    }

    //

    public get GetSubFunctCount(): number {
        return this.m_grpop_functs.length;
    }

    public GetSubFunct(id: number): WFProcessCndFunct {
        return this.m_grpop_functs[id];
    }

    public GetSubFunctByID(id: number): WFProcessCndFunct {
        let ii: number;

        for (ii = 0; ii < this.m_grpop_functs.length; ii++) {
            let fnc = this.m_grpop_functs[ii];
            if (fnc.InternalID === id) return fnc;
            fnc = fnc.GetSubFunctByID(id);
            if (fnc != null) return fnc;
        }

        return null;
    }

    //

    /*internal void AddSubFunct(WFProcessCndFunct fnc)
    {
        m_grpop_functs.Add(fnc);
    }
 
    internal int FindMaxInternalID(int inid)
    {
        int ii;
        if (m_int_id > inid) inid = m_int_id;
        for (ii = 0; ii < m_grpop_functs.Count; ii++)
        {
            int grnid = m_grpop_functs[ii].FindMaxInternalID(inid);
            if (grnid > inid) inid = grnid;
        }
        return inid;
    } */

    //

    private GetValue(varstate: WFProcessVarState, column: string): Object {
        if (IWFObject.IsNullOrEmpty(column)) return varstate.GetScalar();

        //specjalne
        switch (column) {
            case 'COUNT(*)': return varstate.RowCount;
        }

        return varstate.GetValue2(column, 0);
    }

    public CheckConditions(snd: WFDocument, cont: IWFProcessVariableContainer, states: Map<number, WFProcessVarState>, sys: WFSystem): boolean {
        let ii: number;

        if (this.m_type === WFProcessCndType.TYPE_BASIC) {
            let lvarstate: Object = null;
            let varstate: WFProcessVarState;
            let lvar: WFProcessVariable = cont.GetVariableByID(this.m_l_variable_id);
            //sprawdz czy zmienna zostala zainicjowana                
            if (states.has(this.m_l_variable_id)) {
                if (this.m_cnd_type === WFProcessCndFunctType.CND_EQUAL && this.m_r_const.toUpperCase() === 'NULL') return false;
                varstate = states.get(this.m_l_variable_id);
                lvarstate = this.GetValue(varstate, this.m_l_column);
            } else {
                if (this.m_cnd_type === WFProcessCndFunctType.CND_NEQUAL && this.m_r_const.toUpperCase() === 'NULL') return false;

                if (!IWFObject.IsNullOrEmpty(lvar.EvalCode)) {
                    let jsret: Object = snd.EvalScript(lvar.EvalCode, sys);
                    if (jsret != null) 
                    {
                        let str= jsret.toString();
                        lvarstate =  (lvar.Type == WFProcessVarType.TYPE_INT) ? IWFObject.ParseIntFromProp(str) : str;                    
                    }
                }
            }

            if (lvarstate == null) throw new Error(IWFObject.Format('Uninitialized left variable {0}', lvar.Name));

            let rvarstate: Object = null;
            varstate= null;

            if (this.m_r_variable_id === 0) {
                rvarstate = this.m_r_const;
            } else {
                let rvar: WFProcessVariable = cont.GetVariableByID(this.m_r_variable_id);

                if (states.has(this.m_r_variable_id)) {
                    varstate = states.get(this.m_r_variable_id);
                    rvarstate = this.GetValue(varstate, this.m_r_const);
                } else {
                    if (!IWFObject.IsNullOrEmpty(rvar.EvalCode)) {
                        let jsret: Object = snd.EvalScript(rvar.EvalCode, sys);
                        if (jsret != null) 
                        {
                            let str = jsret.toString();
                            rvarstate = (rvar.Type== WFProcessVarType.TYPE_INT) ? IWFObject.ParseIntFromProp(str) : str;
                        }
                    }
                }
            }

            if (rvarstate == null) throw new Error(IWFObject.Format('Uninitialized right variable {0}', lvar.Name));

            //
            if (lvar.Type === WFProcessVarType.TYPE_INT || lvar.Type === WFProcessVarType.TYPE_FLOAT) {
                let lvalue: number = (typeof lvarstate !== 'number') ? IWFObject.StrToDbl(lvarstate.toString()) : <number>lvarstate;
                let rvalue: number;
                
                if(this.m_cnd_type== WFProcessCndFunctType.CND_IN || this.m_cnd_type== WFProcessCndFunctType.CND_NOTIN)
                {
                    let exists = false;

                    if (varstate== null)
                    {
                        //pobranie z rvarstate
                        let strval: string = rvarstate.toString();
                        let vstrval = strval.split(',');
                        
                        for(let vstr of vstrval)
                        {
                            rvalue = IWFObject.StrToDbl(vstr);
                            if(lvalue== rvalue)
                            {
                                exists = true;
                                break;
                            }
                        }
                    }
                    else
                    {
                        for(ii=0; ii< varstate.RowCount; ii++)
                        {
                            let obj= (IWFObject.IsNullOrEmpty(this.m_r_const)) ? varstate.GetValue(0, ii) : varstate.GetValue2(this.m_r_const, ii);
                            rvalue = (typeof obj !== 'number') ? IWFObject.StrToDbl(obj.toString()) : <number>obj;
                            if(lvalue== rvalue)
                            {
                                exists = true;
                                break;
                            }
                        }
                    }

                    return (this.m_cnd_type == WFProcessCndFunctType.CND_IN) ? exists : !exists;
                } 
                
                rvalue = (typeof rvarstate !== 'number') ? IWFObject.StrToDbl(rvarstate.toString()) : <number>rvarstate;
                switch (this.m_cnd_type) {
                    case WFProcessCndFunctType.CND_EQUAL: return (lvalue === rvalue);
                    case WFProcessCndFunctType.CND_NEQUAL: return (lvalue !== rvalue);
                    case WFProcessCndFunctType.CND_GREATER: return (lvalue > rvalue);
                    case WFProcessCndFunctType.CND_GREATEREQ: return (lvalue >= rvalue);
                    case WFProcessCndFunctType.CND_LESS: return (lvalue < rvalue);
                    case WFProcessCndFunctType.CND_LESSEQ: return (lvalue <= rvalue);
                    default:
                        throw new Error(IWFObject.Format('Invalid compare operator {0}', lvar.Name));
                }
            }

            //inne operatory
            let slvalue: string = lvarstate.toString();
            let srvalue: string = rvarstate.toString();
            switch (this.m_cnd_type) { 
                case WFProcessCndFunctType.CND_EQUAL: return (slvalue.localeCompare(srvalue) === 0);
                case WFProcessCndFunctType.CND_NEQUAL: return (slvalue.localeCompare(srvalue) !== 0);
                case WFProcessCndFunctType.CND_GREATER: return (slvalue.localeCompare(srvalue) > 0);
                case WFProcessCndFunctType.CND_GREATEREQ: return (slvalue.localeCompare(srvalue) >= 0);
                case WFProcessCndFunctType.CND_LESS: return (slvalue.localeCompare(srvalue) < 0);
                case WFProcessCndFunctType.CND_LESSEQ: return (slvalue.localeCompare(srvalue) <= 0);
                case WFProcessCndFunctType.CND_RX: return (new RegExp(srvalue, 'i')).test(slvalue);
                case WFProcessCndFunctType.CND_NRX: return !(new RegExp(srvalue, 'i')).test(slvalue);

                case WFProcessCndFunctType.CND_IN:
                case WFProcessCndFunctType.CND_NOTIN:
                    let exists = false;

                    if (varstate == null)
                    {
                        //pobranie z rvarstate
                        let strval = rvarstate.toString();
                        let vstrval = strval.split(',');

                        for(let vstr of vstrval)
                        {                                
                            if (slvalue.localeCompare(vstr) == 0)
                            {
                                exists = true;
                                break;
                            }
                        }
                    }
                    else
                    {
                        for (ii = 0; ii < varstate.RowCount; ii++)
                        {
                            let obj = (IWFObject.IsNullOrEmpty(this.m_r_const)) ? varstate.GetValue(0, ii) : varstate.GetValue2(this.m_r_const, ii);
                            let strval = obj.toString();
                            if (slvalue.localeCompare(strval) == 0)
                            {
                                exists = true;
                                break;
                            }
                        }
                    }

                    return (this.m_cnd_type == WFProcessCndFunctType.CND_IN) ? exists : !exists;

                default:
                    throw new Error(IWFObject.Format('Invalid compare operator {0}', lvar.Name));
            }

            return false;
        }

        if (this.m_grpop_type === WFProcessGroupCndType.CND_OR) {
            for (ii = 0; ii < this.m_grpop_functs.length; ii++) {
                let subfn: WFProcessCndFunct = this.m_grpop_functs[ii];
                if (subfn.CheckConditions(snd, cont, states, sys)) return true;
            }
            return false;
        }

        //AND
        for (ii = 0; ii < this.m_grpop_functs.length; ii++) {
            let subfn: WFProcessCndFunct = this.m_grpop_functs[ii];
            if (!subfn.CheckConditions(snd, cont, states, sys)) return false;
        }

        return true;
    }
}
import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, CodeName, CodeCodeName, BusinessPartnerAddress, BusinessPartner } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';
import { WFCompany, IWFObject, WFBusinessPartnerDB, WFDocumentOcrResults, WFOcrFldType } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';

@Component({
  selector: 'app-sbobusinesspartneredit',
  templateUrl: './sbobusinesspartneredit.component.html',
  styleUrls: ['./sbobusinesspartneredit.component.scss']
})

export class SbobusinesspartnereditComponent implements AfterViewInit {
  public m_busy_str: string;
  public m_typeitems: Array<ComboIValueDesc>;
  public m_cardcodegetter: boolean;

  public m_cardcode: string;
  public m_cardcode_readonly: boolean;
  public m_type: ComboIValueDesc;
  public m_cardtype_enabled: boolean;
  public m_cardname: string;
  public m_cardfname: string;
  public m_cardtaxid: string;
  public m_bill_street: string;
  public m_bill_city: string;
  public m_bill_zipcode: string;

  @ViewChild('m_bill_country', {static: false}) private m_bill_country: ComboboxadpComponent;
  @ViewChild('m_bill_state', {static: false}) private m_bill_state: ComboboxadpComponent;

  public m_supp_street: string;
  public m_supp_city: string;
  public m_supp_zipcode: string;

  @ViewChild('m_supp_country', {static: false}) private m_supp_country: ComboboxadpComponent;
  @ViewChild('m_supp_state', {static: false}) private m_supp_state: ComboboxadpComponent;

  public m_comments: string;

  public m_cardcode_err: boolean;
  public m_type_err: boolean;
  public m_cardname_err: boolean;
  public m_cardfname_err: boolean;
  public m_cardtaxid_err: boolean;
  public m_bill_street_err: boolean;
  public m_bill_city_err: boolean;
  public m_bill_zipcode_err: boolean;
  public m_supp_street_err: boolean;
  public m_supp_city_err: boolean;
  public m_supp_zipcode_err: boolean;

  @Output() public onClosed: EventEmitter<boolean>;

  public m_src: IWFCompanyObjAdp;
  private m_cmp: WFCompany;
  private m_bp: BusinessPartner;
  private m_countries: Array<CodeName>;
  private m_states: Array<CodeCodeName>;
  private m_ccode: string;
  private m_ocrrs: WFDocumentOcrResults;

  private m_onerrorevh: EventAdp<_OnError>;

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {

    this.m_busy_str = '';
    this.m_typeitems = [
      { val: 0, desc: this.m_strdata_srv.getStr('wSboBusinessPartnerEdit_cmbCustomer') },
      { val: 1, desc: this.m_strdata_srv.getStr('wSboBusinessPartnerEdit_cmbSupplier') },
      { val: 2, desc: this.m_strdata_srv.getStr('wSboBusinessPartnerEdit_cmbProspekt') }
    ];
    this.m_cardcodegetter = true;

    this.m_cardcode = '';
    this.m_cardcode_readonly = false;
    this.m_type = this.m_typeitems[0];
    this.m_cardtype_enabled = true;
    this.m_cardname = '';
    this.m_cardfname = '';
    this.m_cardtaxid = '';
    this.m_bill_street = '';
    this.m_bill_city = '';
    this.m_bill_zipcode = '';
    this.m_supp_street = '';
    this.m_supp_city = '';
    this.m_supp_zipcode = '';
    this.m_comments = '';

    this.m_cardcode_err = false;
    this.m_type_err = false;
    this.m_cardname_err = false;
    this.m_cardfname_err = false;
    this.m_cardtaxid_err = false;
    this.m_bill_street_err = false;
    this.m_bill_city_err = false;
    this.m_bill_zipcode_err = false;
    this.m_supp_street_err = false;
    this.m_supp_city_err = false;
    this.m_supp_zipcode_err = false;

    this.onClosed = new EventEmitter<boolean>();

    this.m_bp = null;
    this.m_countries = null;
    this.m_states = null;
    this.m_ccode = null;
    this.m_ocrrs = null;

    const self = this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });

    this.m_src = null;
  }

  public SetPrms(src: IWFCompanyObjAdp, ccode: string, ocrrs: WFDocumentOcrResults): void {
    this.m_src = src;
    this.m_cardcode = this.m_ccode = ccode;
    this.m_ocrrs = ocrrs;
    this.m_cmp = this.m_cache_srv.Companies.get(this.m_src.CompanyID);
  }

  public get BusinessPartner(): BusinessPartner {
    return this.m_bp;
  }

  private ManageException(ex): void {
    this.m_global_srv.showMsg(ALR_ICONTYPE.ERROR, BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strSBOServiceError'));
  }

  public ngAfterViewInit(): void {
    try {
      this.m_busy_str = this.m_strdata_srv.getStr('strWaitDictionaries');
      const self = this;
      this.m_data_srv.getCountries(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, this.m_onerrorevh, (codes) => {
        self.GetCountriesCompleted(codes);
      });
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  private GetCountriesCompleted(codes: Array<CodeName>): void {
    try {
      this.m_countries = codes;
      const self = this;
      this.m_data_srv.getStates(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, this.m_onerrorevh, (codes2) => {
        self.GetStatesCompleted(codes2);
      });
      return;
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_str = '';
  }

  private GetStatesCompleted(codes: Array<CodeCodeName>): void {
    try {
      this.m_states = codes;
      const self = this;
      if (IWFObject.IsNullOrEmpty(this.m_ccode)) {
        this.m_data_srv.suggestCardCode(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, 'S', this.m_onerrorevh, (ccode) => {
          self.SuggestCardCodeCompleted(ccode);
        });
      } else {
        this.m_data_srv.getBP(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, this.m_ccode, this.m_onerrorevh, (bp) => {
          self.GetBPCompleted(bp);
        });
      }
      return;
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_str = '';
  }

  private SuggestCardCodeCompleted(ccode: string): void {
    try {
      if (this.m_bp == null) {
        this.m_bp = {
          CardCode: ccode,
          CardType: 'S',
          CardName: '',
          CardFName: '',
          FTaxID: '',
          FreeText: '',
          Billing: null,
          Delivery: null
        };

        if (this.m_ocrrs != null) {
          for (let fld of this.m_ocrrs.Fields) {
            if (fld.Type === WFOcrFldType.NUMBER || fld.Type === WFOcrFldType.TEXT) {
              let unm = fld.Name.toUpperCase();
              switch (unm) {
                case 'NIP':
                case 'LICTRADNUM':
                case 'FTAXID':
                  this.m_bp.FTaxID = fld.GetScalarValue();
                  break;
              }
            }
          }
        }

        this.BindData();
      } else {
        this.m_cardcode = ccode;
        this.m_cardcode_readonly = (this.m_cardcode.length === 0);
        this.m_cardcodegetter = (this.m_cardcode.length > 0);
      }
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_str = '';
  }

  //
  private SafeStr(str: string): string {
    return (str == null) ? '' : str;
  }

  private BindData(): void {
    let ii: number;

    //m_cardtype.SelectionChanged -= m_selctypexh;

    this.m_cardcode = this.SafeStr(this.m_bp.CardCode);
    switch (this.m_bp.CardType) {
      case 'C': this.m_type = this.m_typeitems[0]; break;
      case 'S': this.m_type = this.m_typeitems[1]; break;
      case 'L': this.m_type = this.m_typeitems[2]; break;
    }
    this.m_cardname = this.SafeStr(this.m_bp.CardName);
    this.m_cardfname = this.SafeStr(this.m_bp.CardFName);
    this.m_cardtaxid = this.SafeStr(this.m_bp.FTaxID);

    if (IWFObject.IsNullOrEmpty(this.m_ccode)) {
      this.m_cardcode_readonly = (this.m_cardcode.length === 0);
      this.m_cardcodegetter = (this.m_cardcode.length > 0);
      this.m_cardtype_enabled = true;
    } else {
      this.m_cardcode_readonly = true;
      this.m_cardcodegetter = false;
      this.m_cardtype_enabled = false;
    }

    for (ii = 0; ii < this.m_countries.length; ii++) {
      let cn: CodeName = this.m_countries[ii];
      this.m_bill_country.AddValidValue(cn.Code, cn.Name);
      this.m_supp_country.AddValidValue(cn.Code, cn.Name);
    }

    if (this.m_bp.Billing != null) {
      this.m_bill_street = this.SafeStr(this.m_bp.Billing.Street);
      this.m_bill_city = this.SafeStr(this.m_bp.Billing.City);
      this.m_bill_zipcode = this.SafeStr(this.m_bp.Billing.ZipCode);
      if (this.m_bp.Billing.Country != null) {
        this.m_bill_country.SelectedValue = this.m_bp.Billing.Country.Code;
      }
    }

    if (this.m_bp.Delivery != null) {
      this.m_supp_street = this.SafeStr(this.m_bp.Delivery.Street);
      this.m_supp_city = this.SafeStr(this.m_bp.Delivery.City);
      this.m_supp_zipcode = this.SafeStr(this.m_bp.Delivery.ZipCode);
      if (this.m_bp.Delivery.Country != null) {
        this.m_supp_country.SelectedValue = this.m_bp.Delivery.Country.Code;
      }
    }

    this.m_comments = this.SafeStr(this.m_bp.FreeText);
    //m_cardtype.SelectionChanged += m_selctypexh;
  }

  private GetBPCompleted(bp: BusinessPartner) {
    try {
      this.m_bp = bp;
      this.BindData();
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_str = '';
  }

  //

  public OKButton_Click(): void {
    try {
      let dorun = true;

      //

      this.m_bp.CardCode = this.m_cardcode.trim();
      if (!this.m_cardcode_readonly) {
        if ((new RegExp('^[0-9a-zA-Z_# -]{2,15}$')).test(this.m_bp.CardCode)) {
          this.m_cardcode_err = false;
        } else {
          this.m_cardcode_err = true;
          dorun = false;
        }
      }

      //

      this.m_bp.CardName = this.m_cardname.trim();
      if (this.m_bp.CardName.length > 0) {
        this.m_cardname_err = false;
      } else {
        this.m_cardname_err = true;
        dorun = false;
      }

      //

      this.m_bp.CardFName = this.m_cardfname.trim();

      //

      if (this.m_bill_street.trim().length < 1) {
        this.m_bill_street_err = true;
        dorun = false;
      } else {
        this.m_bill_street_err = false;
      }

      //

      if (this.m_bill_city.trim().length < 1) {
        this.m_bill_city_err = true;
        dorun = false;
      } else {
        this.m_bill_city_err = false;
      }

      //

      if (this.m_bill_zipcode.trim().length < 1) {
        this.m_bill_zipcode_err = true;
        dorun = false;
      } else {
        this.m_bill_zipcode_err = false;
      }

      //dodanie adresu
      let vnip= true;

      let bill: BusinessPartnerAddress = {
        Street: this.m_bill_street.trim(),
        Country: null,
        State: null,
        ZipCode: this.m_bill_zipcode.trim(),
        City: this.m_bill_city.trim()
      };

      if (!IWFObject.IsNullOrEmpty(this.m_bill_country.SelectedValue)) {
        let cn = {
          Code: this.m_bill_country.SelectedValue,
          Name: this.m_bill_country.SelectedDescrition
        };
        bill.Country = cn;

        if(cn.Code.toUpperCase() != 'PL') vnip= false;

        if (!IWFObject.IsNullOrEmpty(this.m_bill_state.SelectedValue)) {
          let cst: CodeName = {
            Code: this.m_bill_state.SelectedValue,
            Name: this.m_bill_state.SelectedDescrition
          };
          bill.State = cst;
        }
      }

      if (bill.Street.length > 0 || bill.City.length > 0 || bill.ZipCode.length > 0) {
        this.m_bp.Billing = bill;
      }

      //

      this.m_bp.FTaxID = this.m_cardtaxid.trim();
      if(vnip) {
        if ((new RegExp('^(([A-Za-z]{2})?[0-9]{10})|(\d{3}-\d{3}-\d{2}-\d{2})|(\d{3}-\d{2}-\d{2}-\d{3})$')).test(this.m_bp.FTaxID)) {
          this.m_cardtaxid_err = false;
        } else {
          this.m_cardtaxid_err = true;
          dorun = false;
        }
      } else {
        this.m_cardtaxid_err = false;
      }

      //

      if (dorun) {
        this.m_bp.CardType = this.GetCardType();
        //

        let supp: BusinessPartnerAddress = {
          Street: this.m_supp_street.trim(),
          Country: null,
          State: null,
          ZipCode: this.m_supp_zipcode.trim(),
          City: this.m_supp_city.trim()
        };

        if (!IWFObject.IsNullOrEmpty(this.m_supp_country.SelectedValue)) {
          let cn: CodeName = {
            Code: this.m_supp_country.SelectedValue,
            Name: this.m_supp_country.SelectedDescrition
          };
          supp.Country = cn;

          if (!IWFObject.IsNullOrEmpty(this.m_supp_state.SelectedValue)) {
            let cst: CodeName = {
              Code: this.m_supp_state.SelectedValue,
              Name: this.m_supp_state.SelectedDescrition
            };
            supp.State = cst;
          }
        }

        if (supp.Street.length > 0 || supp.City.length > 0 || supp.ZipCode.length > 0) {
          this.m_bp.Delivery = supp;
        }

        this.m_bp.FreeText = this.m_comments.trim();

        //

        this.m_busy_str = this.m_strdata_srv.getStr('wSboBusinessPartnerEdit_CustomerUpdate');
        const self = this;
        if (IWFObject.IsNullOrEmpty(this.m_ccode)) {
          //dodanie nowego ph
          this.m_data_srv.registerNewBP(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, this.m_bp, this.m_onerrorevh, (sret) => {
            self.RegisterNewBPCompleted(sret);
          });
        } else {
          //aktaulizacja
          this.m_data_srv.updateExistBP(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, this.m_bp, this.m_onerrorevh, (sret) => {
            self.UpdateExistBPCompleted(sret);
          });
        }
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('errWrongDataInForm'));
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  private RegisterNewBPCompleted(sret: string): void {
    try {
      let ret = sret.trim();

      if (ret.length > 0) {
        if (ret.indexOf('$$CC$$') !== 0) {
          this.m_global_srv.showWarning(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('errCannotGetCustomer') + ' {0}', sret));
          this.m_busy_str = '';
          return;
        }
        this.m_bp.CardCode = ret.substr(6);
      }

      this.onClosed.emit(true);
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_str = '';
  }

  //

  private UpdateExistBPCompleted(sret: string): void {
    try {
      if (IWFObject.IsNullOrEmpty(sret.trim())) {
        this.onClosed.emit(true);
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('errCannotUpdateCustomer') + ' {0}', sret));
      }
    } catch (ex) {
      this.ManageException(ex);
    }

    this.m_busy_str = '';
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public FindCardCode(): void {
    try {
      this.m_busy_str = this.m_strdata_srv.getStr('strWaitData');
      const self = this;
      this.m_data_srv.suggestCardCode(this.m_cache_srv.SessionID, this.m_cmp.BusinessPartnerDB, this.m_cmp.DBName, this.GetCardType(), this.m_onerrorevh, (ccode) => {
        self.SuggestCardCodeCompleted(ccode);
      });
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  public GetOnlineData(): void {
    try {
      let nip = this.m_cardtaxid.replace(/\D/g, '');
      if (nip.length === 10) {
        const self = this;
        this.m_busy_str = this.m_strdata_srv.getStr('strWaitDictionaries');
        this.m_data_srv.getBPOnline(this.m_cache_srv.SessionID, nip, '', this.m_onerrorevh, (ret) => {
          self.GetBPOnlineCompleted(ret);
        });
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strInvalidFTaxID'));
      }

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private GetBPOnlineCompleted(tmpbp: BusinessPartner): void {
    let ii: number;

    try {
      let base64tst = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (!IWFObject.IsNullOrEmpty(tmpbp.CardCode) && base64tst.test(tmpbp.CardCode)) {
        const self = this;
        let nip = this.m_cardtaxid.replace(/\D/g, '');
        this.m_data_srv.getBPOnline(this.m_cache_srv.SessionID, nip, 'aaaaa', this.m_onerrorevh, (ret) => {
          self.GetBPOnlineCompleted(ret);
        });
        return;
      }

      this.m_cardname = this.SafeStr(tmpbp.CardName);
      this.m_cardtaxid = this.SafeStr(tmpbp.FTaxID);

      if (tmpbp.Billing != null) {
        this.m_bill_street = this.SafeStr(tmpbp.Billing.Street);
        this.m_bill_city = this.SafeStr(tmpbp.Billing.City);
        this.m_bill_zipcode = this.SafeStr(tmpbp.Billing.ZipCode);
        this.m_bill_country.SelectedValue = 'PL';

        if (tmpbp.Billing.State != null) {
          let state = tmpbp.Billing.State.Name;
          for (ii = 0; ii < this.m_states.length; ii++) {
            let st = this.m_states[ii];
            if (st.Name.toUpperCase() === state.toUpperCase()) {
              this.m_bill_state.SelectedValue = st.Code2;
              break;
            }
          }
        }
      }

      if (tmpbp.Delivery != null) {
        this.m_supp_street = this.SafeStr(tmpbp.Delivery.Street);
        this.m_supp_city = this.SafeStr(tmpbp.Delivery.City);
        this.m_supp_zipcode = this.SafeStr(tmpbp.Delivery.ZipCode);
        this.m_supp_country.SelectedValue = 'PL';
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }

    this.m_busy_str = '';
  }

  private GetCardType(): string {
    switch (this.m_type.val) {
      case 0:
        return 'C';
      case 1:
        return 'S';
      case 2:
        return 'L';
    }
    return null;
  }

  public CardTypeSelectionChanged(value: ComboIValueDesc): void {
    this.m_type = value;
    this.FindCardCode();
  }

  public BillCountryChanged(cnc: string): void {
    try {
      this.m_bill_state.ClearValidValues();

      if (!IWFObject.IsNullOrEmpty(cnc)) {
        let stcode = '';
        if (this.m_bp.Billing != null) {
          if (this.m_bp.Billing.State != null) stcode = this.m_bp.Billing.State.Code;
        }

        for (let ii = 0; ii < this.m_states.length; ii++) {
          let st: CodeCodeName = this.m_states[ii];
          if (st.Code1 === cnc) {
            this.m_bill_state.AddValidValue(st.Code2, st.Name);
          }
        }

        this.m_bill_state.SelectedValue = stcode;
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }

  public SuppCountryChanged(cnc: string): void {
    try {
      this.m_supp_state.ClearValidValues();

      if (!IWFObject.IsNullOrEmpty(cnc)) {
        let stcode = '';
        if (this.m_bp.Delivery != null) {
          if (this.m_bp.Delivery.State != null) stcode = this.m_bp.Delivery.State.Code;
        }

        for (let ii = 0; ii < this.m_states.length; ii++) {
          let st: CodeCodeName = this.m_states[ii];
          if (st.Code1 === cnc) {
            this.m_supp_state.AddValidValue(st.Code2, st.Name);
          }
        }

        this.m_supp_state.SelectedValue = stcode;
      }
    } catch (ex) {
      this.ManageException(ex);
    }
  }
}

import { Injectable } from '@angular/core';
import { LANG_PL_MAP } from './lang/pl';
import { LANG_EN_MAP } from './lang/en';

// import { Http, Response, Headers, RequestOptions } from '@angular/http';
// import { Observable } from 'rxjs/Rx';

@Injectable()
export class StringDataService {
  private m_dict: Object;
  private m_lang: string;  
  private m_upper: boolean;

  constructor() {
    this.m_lang = '';
    this.setCurLang(navigator.language.split('-')[0]);
  }

  /* private fetchDict(lang:string)
  {
    const lngpath= 'assets/strings/'+lang+'.json';   
    
    this.http.get(lngpath)  
        .retry(1)
        .catch((e:Response) => 
          {
            return Observable.throw(e);
          })
        .map((res:Response)=> 
          { 
            return res.json();
          })
        .subscribe((res)=> 
          {
            this.m_lang= lang;
            this.m_dict.observeOn(res);
          },
          (err)=>
          {
            if(lang!== 'default') this.fetchDict('default');            
          });
  } */

  public get CurLang(): string {
    return this.m_lang;
  }

  public setCurLang(nlng: string) {
    nlng= nlng.toUpperCase();
    if (nlng !== this.m_lang) {
      switch (nlng) {
        case 'PL':
          this.m_dict = LANG_PL_MAP;
          break;
        case 'EN':
          this.m_dict = LANG_EN_MAP;
          break;
        default:
          this.m_dict = LANG_EN_MAP;
          break;
      }
      this.m_lang = nlng;
    }
  }

  public getDict(): Object {
    return this.m_dict;
  }

  public getStr(key: string): string {
    return this.m_dict[key];
  }
}

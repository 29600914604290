import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TreeViewItem } from './treeviewitem';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss']
})

export class TreeViewComponent {
  @Input() items: Array<TreeViewItem>;

  //@Output() selectitem: EventEmitter<TreeViewItem>;
  @Output() ctxmenu: EventEmitter<[TreeViewItem, Object]>;
  @Output() itemmousedown: EventEmitter<[TreeViewItem, MouseEvent]>;
  @Output() checkchange: EventEmitter<[TreeViewItem, boolean]>;

  constructor() {
    this.items = new Array<TreeViewItem>();
    //this.selectitem = new EventEmitter<TreeViewItem>();
    this.ctxmenu = new EventEmitter<[TreeViewItem, Object]>();
    this.itemmousedown = new EventEmitter<[TreeViewItem, MouseEvent]>();
    this.checkchange = new EventEmitter<[TreeViewItem, boolean]>();
  }

  public isDir(val) { return  (val != null && val.constructor.name === 'WFDirectoryAdp'); }

  //public SelectItem(itm: TreeViewItem): void {
  //this.selectitem.emit(itm);
  //}

  public ContextMenu(itm: TreeViewItem, nele: Object): boolean {
    this.ctxmenu.emit([itm, nele]);
    return false;
  }


  public treeclick(itm: TreeViewItem, mev: MouseEvent) {
    if (itm.Items.length > 0 && !itm.Expanded) {
      itm.Expanded = true;
    } else if (itm.Items.length > 0 && itm.Expanded) {
      itm.Expanded = false;
    }
    this.itemmousedown.emit([itm, mev]);
  }

}

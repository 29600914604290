import { IWFObject } from './IWFObject';

export enum WFDictObjType {
    CLASSATTRIB,
    GLOBAL
}

export class WFDictionary {
    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_vvalues: Map<string, string>;
    private m_createdat: Date;
    private m_updatedat: Date;
    private m_organization_id: number;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_vvalues = new Map<string, string>();
        for (let kv of obj.vvalues) this.m_vvalues.set(kv.key, kv.value);
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_organization_id = obj.organization_id;
    }


    public get ID(): number {
        return this.m_id;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get ValidValues(): Map<string, string> {
        return this.m_vvalues;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    public toString(): string {
        return this.m_name;
    }
}
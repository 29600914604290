import { WFClassAttrib } from './WFClassAttrib';
import { WFPermission } from './WFPermission';
import { WFClassTrigger } from './WFClassTrigger';
import { IWFObject } from './IWFObject';

export enum PrmCopyMethodType {
    COPY_NEVER,
    COPY_ALWAYS,
    COPY_ASKUSR
}

export enum WFClassVisibility {
    VISIBLE,
    HIDDEN
}

export enum WFCheckFormat {
    NOTCHECK,
    ALLOWEDIT,
    AUTONAME
}

export class WFClass {
    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_regex: RegExp;
    private m_auth_allowcustomschema: boolean;
    private m_auth_allowedschemas: Array<number>;
    private m_auth_defaultschema: number; //0- mozna wybrac nic,
    private m_attribs: Array<WFClassAttrib>;
    private m_columns: Array<WFClassAttrib>;
    private m_createdat: Date;
    private m_updatedat: Date;
    private m_nameformat: string;
    private m_prmcpmthtype: PrmCopyMethodType;
    private m_AuthMandatory: boolean;

    private m_perms: Array<WFPermission>;

    private m_visibility: WFClassVisibility;
    private m_base_class_id: number;
    private m_issystem: boolean;

    private m_uniqname: boolean;
    private m_checkformat: WFCheckFormat;
    private m_docnumrequired: boolean;
    private m_uniqdocnum: boolean;

    private m_classcolor: string;
    private m_organization_id: number;
    //

    private m_triggers: Array<WFClassTrigger>;

    private m_global_id: string;
    private m_autoaddrow: boolean;
    private m_att_class_id: number;
    private m_attsendtocr: boolean;

    private m_newdocpath: string;
    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_regex = new RegExp(obj.regex);
        this.m_auth_allowcustomschema = obj.auth_allowcustomschema;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_nameformat = obj.nameformat;
        this.m_auth_defaultschema = obj.auth_defaultschema;
        this.m_prmcpmthtype = obj.prmcpmthtype;
        this.m_AuthMandatory = obj.AuthMandatory;
        this.m_visibility = obj.visibility;
        this.m_base_class_id = obj.base_class_id;
        this.m_issystem = obj.issystem;
        this.m_uniqname = obj.uniqname;
        this.m_checkformat = obj.checkformat;
        this.m_docnumrequired = obj.docnumrequired;
        this.m_uniqdocnum = obj.uniqdocnum;
        this.m_classcolor = IWFObject.ParseColor(obj.classcolor);
        this.m_organization_id = obj.organization_id;
        this.m_auth_allowedschemas = obj.auth_allowedschemas;

        this.m_attribs = new Array<WFClassAttrib>();
        for (let atr of obj.attribs) this.m_attribs.push(new WFClassAttrib(atr));

        this.m_columns = new Array<WFClassAttrib>();
        for (let col of obj.columns) this.m_columns.push(new WFClassAttrib(col));

        this.m_perms = new Array<WFPermission>();
        for (let prm of obj.perms) this.m_perms.push(new WFPermission(prm));

        this.m_triggers = new Array<WFClassTrigger>();
        for (let tri of obj.triggers) this.m_triggers.push(new WFClassTrigger(tri));

        this.m_global_id= obj.global_id;
        this.m_autoaddrow = obj.autoaddrow;
        this.m_att_class_id = obj.att_class_id;
        this.m_attsendtocr = obj.attsendtocr;
        this.m_newdocpath = obj.newdocpath;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get MathRegex(): RegExp {
        return this.m_regex;
    }

    //

    public get AllowCustomSchema(): boolean {
        return this.m_auth_allowcustomschema;
    }

    //

    public get AllowedSchemas(): Array<number> {
        return this.m_auth_allowedschemas;
    }

    //

    public get DefaultSchema(): number {
        return this.m_auth_defaultschema;
    }

    //

    public get Attributes(): Array<WFClassAttrib> {
        return this.m_attribs;
    }

    public get Columns(): Array<WFClassAttrib> {
        return this.m_columns;
    }

    //

    public get NameFormat(): string {
        return this.m_nameformat;
    }

    //

    public get PermissionCopyMethodType(): PrmCopyMethodType {
        return this.m_prmcpmthtype;
    }

    //
    public get AuthMandatory(): boolean {
        return this.m_AuthMandatory;
    }

    //

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    //

    public get Permissions(): Array<WFPermission> {
        return this.m_perms;
    }

    //

    public get Visibility(): WFClassVisibility {
        return this.m_visibility;
    }

    //

    public get BaseClassID(): number {
        return this.m_base_class_id;
    }

    //

    public get IsSystemClass(): boolean {
        return this.m_issystem;
    }

    //

    public get DocNameIsUniq(): boolean {
        return this.m_uniqname;
    }

    //

    public get CheckDocNameFormat(): WFCheckFormat {
        return this.m_checkformat;
    }

    //

    public get DocNumRequired(): boolean {
        return this.m_docnumrequired;
    }

    //

    public get DocNumIsUniq(): boolean {
        return this.m_uniqdocnum;
    }
    //

    public get Classcolor(): string {
        return this.m_classcolor;
    }

    //

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    //

    public get Triggers(): Array<WFClassTrigger> {
        return this.m_triggers;
    }

    //

    public get GlobalID(): string
    {
        return this.m_global_id;
    }

    //

    public get AutoAddNewRow(): boolean {
        return this.m_autoaddrow;
    }

    //

    public get AttachmentsClassID(): number {
        return this.m_att_class_id;
    }

    //

    public get AttachmentsSendToOCR(): boolean {
        return this.m_attsendtocr;
    }

    //

    public get NewDocPath(): string {
        return this.m_newdocpath;
    }
}

import { Injectable, Renderer2, ViewContainerRef, ComponentFactoryResolver, TemplateRef, Type, ComponentRef } from '@angular/core';
import { ChildWindowComponent } from './child-window.component';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class ChildWindowContainerService {
  private m_container: ViewContainerRef;
  private m_renderer: Renderer2;
  private m_zorder: number;

  //

  /*get container(): ViewContainerRef
  {
    return this.m_container;
  }

  get renderer(): Renderer2
  {
    return this.m_renderer;
  }*/

  // 
  constructor(private m_resolver: ComponentFactoryResolver) {
    this.m_container = null;
    this.m_renderer = null;
    this.m_zorder = 100;
  }

  public bindToContainer(cnt: ViewContainerRef, rnd: Renderer2): void {
    this.m_container = cnt;
    this.m_renderer = rnd;
  }

  public getNewLayer(): number {
    this.m_zorder += 2;
    return this.m_zorder;
  }

  public showControl<T>(tp: Type<T>): ComponentRef<T> {
    if (this.m_container == null) throw new ReferenceError('Brak kontenera dla child-window');
    const fct = this.m_resolver.resolveComponentFactory(tp);
    const dlg = this.m_container.createComponent(fct);
    return dlg;
  }

  /*
  constructor(private resolver: ComponentFactoryResolver, 
              private cntsrv: ChildWindowContainerService ) 
  { 
  
  }

  public showChildWindow(title: string, content: string, can_ok: boolean, can_cancel: boolean): ComponentRef<ChildWindowComponent>
  {
    if(this.cntsrv.container== null) throw new ReferenceError('Brak kontenera dla child-window');
    
    let fct = this.resolver.resolveComponentFactory(ChildWindowComponent);

    let nodes = [this.cntsrv.renderer.createText(content)];   
    
    const dlg = this.cntsrv.container.createComponent(fct, undefined, undefined, [[], nodes, []]);    
    let cmp: ChildWindowComponent= dlg.instance;
    cmp.can_cancel= can_cancel;
    cmp.can_ok= can_ok;
    cmp.title= title;
   
    const close = function (e) {
            //apiClose.next(e);            
            dlg.destroy();
        };

    cmp.clickCancel.subscribe(close);   
    return dlg;
  } */
}

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { IWFObject, WFUser, WFPermissionInfo, WFPropType, WFPrmType, WFUserGroup, WFAuthSchema } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { TreeViewItem } from '../tree-view/treeviewitem';

enum PrmInfoImgType {
  NONE,
  READONLY,
  READWRITE
}

@Component({
  selector: 'app-parentpermissions',
  templateUrl: './parentpermissions.component.html',
  styleUrls: ['./parentpermissions.component.scss']
})

export class ParentpermissionsComponent {
  public m_busy_str: string;
  public m_incdoc: boolean;
  public m_perms: Array<TreeViewItem>;

  @Output() public onClosed: EventEmitter<boolean>;

  private m_doc: WFDocumentAdp;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {
    this.m_busy_str = '';
    this.m_incdoc = false;
    this.m_perms = new Array<TreeViewItem>();
    this.onClosed = new EventEmitter<boolean>();
    this.m_doc = null;
  }

  public SetPrms(adp: WFDocumentAdp, incdoc: boolean): void {
    this.m_doc = adp;
    this.m_incdoc = incdoc;
    this.RefreshInfoList();
  }

  public OKButton_Click(): void {
    this.onClosed.emit(true);
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public CreateInfoRow(lbl: string, incimg: PrmInfoImgType): TreeViewItem {
    let tvi = new TreeViewItem();
    tvi.Header = lbl;
    if (incimg !== PrmInfoImgType.NONE) {
      if (incimg === PrmInfoImgType.READWRITE) {
        tvi.Icon = 'cmn_edit_white.svg';
      } else {
        tvi.Icon = 'cmn_readonly.svg';
      }
    }
    return tvi;
  }

  private RefreshInfoList(): void {
    let ii: number;
    let usr: WFUser;

    let incdoc = this.m_incdoc;

    let infs: Array<WFPermissionInfo> = this.m_doc.ListPermissions(incdoc, WFPropType.DOC_CANEDIT);

    let ginfs = new Map<number, Array<WFPermissionInfo>>();
    let uginfs = new Array<WFPermissionInfo>();

    for (ii = infs.length - 1; ii > -1; ii--) {
      let inf: WFPermissionInfo = infs[ii];
      if (inf.Type === WFPrmType.CHANGE || inf.Type === WFPrmType.VIEW) {
        if (inf.UserGroupID < 0) {
          uginfs.push(inf);
        } else {
          if (ginfs.has(inf.UserGroupID)) {
            ginfs.get(inf.UserGroupID).push(inf);
          } else {
            let plst = new Array<WFPermissionInfo>();
            plst.push(inf);
            ginfs.set(inf.UserGroupID, plst);
          }
        }
      }
    }

    //dodaj grupy
    this.m_perms.splice(0, this.m_perms.length);

    for (let kv of Array.from(ginfs.entries())) {
      let grpid: number = kv[0];
      let ifs: Array<WFPermissionInfo> = kv[1];
      let tvi: TreeViewItem;

      if (grpid > 0) {
        let ugr: WFUserGroup = this.m_cache_srv.UserGroups.get(grpid);
        tvi = this.CreateInfoRow(IWFObject.Format('{0} - {1}', ugr.Name, ugr.Description), PrmInfoImgType.NONE);
      } else {
        tvi = this.CreateInfoRow(this.m_strdata_srv.getStr('AllUserGroup'), PrmInfoImgType.NONE);
      }

      for (let pif of ifs) {
        let uid= (pif.UserID === WFAuthSchema.OBJID_OWNER) ? this.m_doc.UserID : pif.UserID;
        usr = (uid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uid);
        tvi.Items.push(this.CreateInfoRow(usr.FriendlyName, (pif.Type === WFPrmType.CHANGE) ? PrmInfoImgType.READWRITE : PrmInfoImgType.READONLY));
      }

      this.m_perms.push(tvi);
    }

    //dodaj usr
    for (let pif of uginfs) {
      let uid= (pif.UserID === WFAuthSchema.OBJID_OWNER) ? this.m_doc.UserID : pif.UserID;
      usr = (uid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uid);
      this.m_perms.push(this.CreateInfoRow(usr.FriendlyName, (pif.Type === WFPrmType.CHANGE) ? PrmInfoImgType.READWRITE : PrmInfoImgType.READONLY));
    }
  }

  public IncludeDocumentsChanged(): void {
    try {
      this.RefreshInfoList();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

import { Component, OnInit, Input, Output, EventEmitter, ComponentRef, ViewChild, ElementRef } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { GlobalService } from '../global/global.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { DatePipe } from '@angular/common';
import { DatepickeradpPopupComponent } from '../datepickeradp-popup/datepickeradp-popup.component';
import { IWFObject } from '../model';

@Component({
  selector: 'app-datepickeradp',
  templateUrl: './datepickeradp.component.html',
  styleUrls: ['./datepickeradp.component.scss']
})

export class DatepickeradpComponent {
  public m_value: string;
  public m_readonly: boolean;

  public m_dateformat: string;
  private m_date: Date;
  private m_datep: DatePipe;

  private m_wnd: ComponentRef<DatepickeradpPopupComponent>;

  //

  @Output() SelectedValueChange: EventEmitter<Date>;

  //

  public set SelectedValue(nval: Date) {
    if (this.m_date != nval) {
      this.m_date = nval;
      this.FormatLabel();
    }
  }

  @Input() public get SelectedValue(): Date {
    return this.m_date;
  }

  public set ReadOnly(nval: boolean) {
    this.m_readonly = nval;
  }

  @Input() public get ReadOnly(): boolean {
    return this.m_readonly;
  }

  @ViewChild('m_textfld', {static: false}) private m_textfld: ElementRef;

  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_wnd_srv: ChildWindowContainerService) {
    this.m_value = '';
    this.m_readonly = false;
    this.m_datep = new DatePipe('en-US');
    this.m_dateformat = 'dd-MM-yyyy';
    this.m_date = null;
    this.SelectedValueChange = new EventEmitter<Date>();
    this.m_wnd = null;
  }

  private dateFromStr(str: string): Date {
    let dnow = new Date();
    str = str.replace(/-/g, '');

    /*let test= 'TEST\nTEST\nTEST';
    test= test.replace(/\n/g,'<br>');
    console.log(test);*/

    if (/^[0-3][0-9](0[0-9]|1[0-2])[0-9]{4}/i.test(str))
      return new Date(Number.parseInt(str.substr(4, 4)),
        Number.parseInt(str.substr(2, 2)) - 1,
        Number.parseInt(str.substr(0, 2)));

    if (/^[0-3][0-9](0[0-9]|1[0-2])[0-9]{2}/i.test(str))
      return new Date(2000 + Number.parseInt(str.substr(4, 2)),
        Number.parseInt(str.substr(2, 2)) - 1,
        Number.parseInt(str.substr(0, 2)));

    if (/^[0-3][0-9](0[0-9]|1[0-2])/i.test(str))
      return new Date(dnow.getFullYear(),
        Number.parseInt(str.substr(2, 2)) - 1,
        Number.parseInt(str.substr(0, 2)));

    if (/^[0-3][0-9][1-9]/i.test(str))
      return new Date(dnow.getFullYear(),
        Number.parseInt(str.substr(2, 1)) - 1,
        Number.parseInt(str.substr(0, 2)));

    if (/^[0-3][0-9]/i.test(str))
      return new Date(dnow.getFullYear(),
        dnow.getMonth(),
        Number.parseInt(str.substr(0, 2)));

    if (/^[0-9]/i.test(str))
      return new Date(dnow.getFullYear(),
        dnow.getMonth(),
        Number.parseInt(str.substr(0, 1)));

    return dnow;
  }

  public validateInput(trg: any): void {
    try {
      this.closeWnd();
      
      let str = trg.value;
      if (IWFObject.IsNullOrEmpty(str)) {
        if(this.m_date != null) {
          this.m_date = null;
          this.SelectedValueChange.emit(null);
        }
      } else {
        let ndta = this.dateFromStr(str);
        if (ndta != null) {
          if (this.m_date != ndta) {
            this.m_date = ndta;
            this.SelectedValueChange.emit(ndta);
          }
        }
      }
    } catch (ex) {
    }
    this.FormatLabel();
  }

  private closeWnd(): void {
    if (this.m_wnd != null) {
      this.m_wnd.destroy();
      this.m_wnd = null;
    }
  }

  private FormatLabel(): void {
    this.m_value = (this.m_date == null) ? '' : this.m_datep.transform(this.m_date, this.m_dateformat);
  }

  private SetDate(nval: Date): void {
    if (this.m_date != nval) {
      this.m_date = nval;
      this.FormatLabel();
      this.SelectedValueChange.emit(nval);
    }
  }

  public showCalendar(trg: any): void {
    try {
      const self = this;
      this.m_wnd = this.m_wnd_srv.showControl(DatepickeradpPopupComponent);
      this.m_wnd.instance.SetPrms(trg, this.m_date);

      this.m_wnd.instance.closePopup.subscribe(() => {
        self.closeWnd();
      });

      this.m_wnd.instance.selectValue.subscribe((nval) => {
        self.closeWnd();
        self.SetDate(nval);
      });

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public Focus(): void {
    try {
      if(!this.m_readonly) {
        this.m_textfld.nativeElement.focus();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

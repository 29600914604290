import { WFInstructionParameter } from './WFInstructionParameter';

export enum WFAuthInsType {
    BASIC,
    PARAMETRIZED,
}

export enum WFAuthInsObjType {
    USER,
    AUTHSCHEMA,
    INTERNALUSER,
    INTERNALAUTHSCHEMA,
    INTERNALAUTHSCHEMA2
}

export class WFAuthInstruction {
    private m_int_id: number;

    private m_name: string;
    private m_description: string;
    private m_type: WFAuthInsType;

    private m_obj_id: number;
    private m_obj_type: WFAuthInsObjType;

    private m_addinfo: string;
    private m_variable_id: number;

    private m_params: Array<WFInstructionParameter>;

    //DM15092023
    private m_autosave: boolean;

    constructor(obj: any) {
        this.m_int_id = obj.int_id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_type = obj.type;
        this.m_obj_id = obj.obj_id;
        this.m_obj_type = obj.obj_type;
        this.m_addinfo = obj.addinfo;
        this.m_variable_id = obj.variable_id;
        this.m_autosave= obj.autosave;

        this.m_params = new Array<WFInstructionParameter>();
        for (let prm of obj.params) this.m_params.push(new WFInstructionParameter(prm));
    }

    //

    public get InternalID(): number {
        return this.m_int_id;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get Type(): WFAuthInsType {
        return this.m_type;
    }

    public get ObjID(): number {
        return this.m_obj_id;
    }

    public get ObjType(): WFAuthInsObjType {
        return this.m_obj_type;
    }

    //

    public get AdditionalInfo(): string {
        return this.m_addinfo;
    }

    //

    public get VariableID(): number {
        return this.m_variable_id;
    }

    //

    public get AutoSave(): boolean
    {
        return this.m_autosave;
    }

    //

    public GetParamsCount(): number {
        return this.m_params.length;
    }

    //

    public GetParam(id: number): WFInstructionParameter {
        return this.m_params[id];
    }

    //

    public GetParamByID(id: number): WFInstructionParameter {
        let ii: number;
        for (ii = 0; ii < this.m_params.length; ii++) {
            let ins = this.m_params[ii];
            if (ins.InternalID === id) return ins;
        }
        return null;
    }
}
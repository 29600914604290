import { IWFCompanyObjAdp } from './IWFCompanyObjAdp';
import { WFDailyCorrespondence, WFCorrOpType, WFClassAttrib, IWFObject, WFPropType, WFPermissionInfo, WFPrmType, WFDailyCorrespondenceRef, WFFieldType } from '../model';
import { CacheService } from '../data/cache.service';
import { WFDataFetchParams } from './IWFDataFetchAdp';
import { EventAdp } from '../data/eventadp';
import { WFDocumentAdp, _OnError } from './WFDocumentAdp';
import { BUTTONSTYPE } from '../global/global.service';
import { DocumentList } from '../data/documentlist';

export type PropertyValueChange = (snd: WFDailyCorrespondenceAdp, prop: string) => void;
export type PublishDailyCorrespondence = (snd: WFDailyCorrespondenceAdp, status: boolean) => void;
export type RemoveDailyCorrespondence = (snd: WFDailyCorrespondenceAdp, status: boolean, oldid: number) => void;

export class WFDailyCorrespondenceAdp extends IWFCompanyObjAdp {
    private m_dcorr: WFDailyCorrespondence;

    //

    public OnPropertyValueChange: PropertyValueChange;
    public OnPublishDailyCorrespondence: PublishDailyCorrespondence;
    public OnRemoveDailyCorrespondence: RemoveDailyCorrespondence;

    private m_OnError: EventAdp<_OnError>;

    private m_docs: DocumentList;

    //

    constructor(csrv: CacheService, dcorr: WFDailyCorrespondence) {
        super(csrv, (dcorr.ID === 0), dcorr.Properties);
        this.m_dcorr = dcorr;
        this.OnPropertyValueChange = null;
        this.OnPublishDailyCorrespondence = null;
        this.OnRemoveDailyCorrespondence = null;
        this.m_OnError = new EventAdp<_OnError>();
        this.m_docs = new DocumentList();
        for (let rf of this.m_dcorr.References) {
            this.m_docs.Add(rf.DocumentID);
        }
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    //

    private PropertyValueChanged(pname: string): void {
        this.IsModified = true;
        if (this.OnPropertyValueChange != null) this.OnPropertyValueChange(this, pname);
    }

    //

    public get ID(): number {
        return this.m_dcorr.ID;
    }

    public set DailyCorrClassID(value: number) {
        if (value !== this.m_dcorr.DailyCorrClassID) {
            this.m_dcorr.DailyCorrClassID = value;
            this.PropertyValueChanged('DailyCorrClassID');
        }
    }

    public get DailyCorrClassID(): number {
        return this.m_dcorr.DailyCorrClassID;
    }

    public set CompanyID(value: number) {
        if (value !== this.m_dcorr.CompanyID) {
            this.m_dcorr.CompanyID = value;
            this.PropertyValueChanged('CompanyID');
        }
    }

    public get CompanyID(): number {
        return this.m_dcorr.CompanyID;
    }

    public get UserID(): number {
        return this.m_dcorr.UserID;
    }

    public set Description(value: string) {
        if (value !== this.m_dcorr.Description) {
            this.m_dcorr.Description = value;
            this.PropertyValueChanged('Description');
        }
    }

    public get Description(): string {
        return this.m_dcorr.Description;
    }

    public set OperationType(value: WFCorrOpType) {
        if (value !== this.m_dcorr.OperationType) {
            this.m_dcorr.OperationType = value;
            this.PropertyValueChanged('OperationType');
        }
    }

    public get OperationType(): WFCorrOpType {
        return this.m_dcorr.OperationType;
    }

    public set OperationDate(value: Date) {
        if (value !== this.m_dcorr.OperationDate) {
            this.m_dcorr.OperationDate = value;
            this.PropertyValueChanged('OperationDate');
        }
    }

    public get OperationDate(): Date {
        return this.m_dcorr.OperationDate;
    }

    public get CreatedAt(): Date {
        return this.m_dcorr.CreatedAt;
    }

    public get UpdatedAt(): Date {
        return this.m_dcorr.UpdatedAt;
    }

    public get Documents(): DocumentList {
        return this.m_docs;
    }

    public get LongInfo(): string {        
        //let cls = this.m_conn.DailyCorrepondenceClasses.get(this.m_dcorr.DailyCorrClassID);
        return IWFObject.Format('{0} {1} {2}',                                
                                this.m_conn.StringService.getStr('strAdd'), 
                                this.m_dcorr.DocNum, 
                                WFDocumentAdp.DateTimeToStr(this.m_dcorr.CreatedAt, this.m_conn.StringService));
                                // cls.Name);
    }

    public get DocNum(): string {
        return this.m_dcorr.DocNum;
    }

    //

    protected SetValue(key: string, value: string, checkprm: boolean): void {
        let atrs: Array<WFClassAttrib> = null;
        if (this.m_dcorr.DailyCorrClassID > 0) {
            let cls = this.m_conn.DailyCorrepondenceClasses.get(this.m_dcorr.DailyCorrClassID);
            atrs = cls.Attributes;
        }

        let sval = this._SetValue(key, value, checkprm, atrs);
        if (sval != null) {
            key = key.toUpperCase();
            this.PropertyValueChanged(IWFObject.Format('_{0}', key));
        }
    }

    //

    private _CanEdit(atrid: number, prp: WFPropType): boolean {

        let reptbl = this.m_conn.User.FindReplacements(this.m_conn.Users);
        let allow = (this.m_dcorr.UserID === this.m_conn.User.ID) ? true : (reptbl.indexOf(this.m_dcorr.UserID) >= 0);
        //console.log('allow', allow, atrid, prp);

        let pinfs: Array<WFPermissionInfo>;

        //zbadaj uprawnienia                
        let cls = this.m_conn.DailyCorrepondenceClasses.get(this.m_dcorr.DailyCorrClassID);
        if (atrid > 0)
            pinfs = cls.ListPermissions2(atrid, true);
        else
            pinfs = cls.ListPermissions(prp);

        for (let pinf of pinfs) {
            if ((pinf.UserID === this.m_conn.User.ID) || (reptbl.indexOf(pinf.UserID) >= 0)) {
                allow = (pinf.Type === WFPrmType.CHANGE);
                break;
            }
        }

        return allow;
    }

    //

    public CanEdit2(atrid: number): boolean {
        if (this.m_conn.IsSuperUser) return true;
        return this._CanEdit(atrid, WFPropType.DOC_CANEDIT);
    }

    //

    public CanEdit(atr: WFClassAttrib): boolean {
        if (this.m_conn.IsSuperUser) return true;
        return this.CanEdit2((atr == null) ? 0 : atr.ID);
    }

    //

    public get CanDeleteEntry(): boolean {
        if (this.m_conn.IsSuperUser) return true;
        return this._CanEdit(0, WFPropType.DAILY_DEL);
    }

    //

    public GetReferencesCount(): number {
        return this.m_dcorr.References.length;
    }

    public GetReference(id: number): WFDailyCorrespondenceRef {
        return this.m_dcorr.References[id];
    }

    public get CanAddReference(): boolean {
        if (this.m_conn.IsSuperUser) return true;
        return this._CanEdit(0, WFPropType.DAILY_EDTREF);
    }

    public AddReference(rf: WFDailyCorrespondenceRef): void {
        this.IsModified = true;
        this.m_dcorr.References.push(rf);
        this.m_docs.Add(rf.DocumentID);
    }

    public get CanDeleteReference(): boolean {
        if (this.m_conn.IsSuperUser) return true;
        return this._CanEdit(0, WFPropType.DAILY_EDTREF);
    }

    public DeleteReference(id: number): void {
        this.IsModified = true;
        let old = this.m_dcorr.References[id];
        this.m_dcorr.References.splice(id, 1);
        this.m_docs.Remove(old.DocumentID);
    }

    public HasReference(doc_id: number): boolean {
        for (let rf of this.m_dcorr.References) {
            if (rf.DocumentID === doc_id) return true;
        }
        return false;
    }

    //

    public Publish(): void {
        if (this.m_ismodified) {
            const self = this;
            this.m_conn.DataService.modifyDailyCorrespondence(this.m_conn.SessionID, this.m_dcorr, this.m_OnError, (ret) => {
                self.ModifyDailyCorrespondenceCompleted(ret);
            });
        }
    }


    private ModifyDailyCorrespondenceCompleted(dcr: WFDailyCorrespondence): void {
        try {
            this.m_dcorr = dcr;
            this.m_props = this.m_dcorr.Properties;
            this.IsModified = false;
            if (this.OnPublishDailyCorrespondence != null) this.OnPublishDailyCorrespondence(this, true);
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        if (this.OnPublishDailyCorrespondence != null) this.OnPublishDailyCorrespondence(this, false);
    }

    //

    public Remove(): void {
        if (this.m_dcorr.ID > 0) {
            const self = this;
            this.m_conn.DataService.removeDailyCorrespondence(this.m_conn.SessionID, this.m_dcorr, this.m_OnError, (ret) => {
                self.RemoveDailyCorrespondenceCompleted(ret);
            });
        } else {
            if (this.OnRemoveDailyCorrespondence != null) this.OnRemoveDailyCorrespondence(this, false, 0);
        }
    }

    //

    private RemoveDailyCorrespondenceCompleted(dcr: WFDailyCorrespondence): void {
        try {
            let oldid = this.m_dcorr.ID;
            this.m_dcorr = dcr;
            this.m_props = this.m_dcorr.Properties;
            this.IsModified = false;
            if (this.OnRemoveDailyCorrespondence != null) this.OnRemoveDailyCorrespondence(this, true, oldid);
            return;
        } catch (ex) {
            this.m_conn.GlobalService.manageException(ex);
        }

        if (this.OnRemoveDailyCorrespondence != null) this.OnRemoveDailyCorrespondence(this, false, this.m_dcorr.ID);
    }
    //

    public CheckMandatory(): string {
        if (this.m_dcorr.DailyCorrClassID > 0) {
            let cls = this.m_conn.DailyCorrepondenceClasses.get(this.m_dcorr.DailyCorrClassID);
            return WFDataFetchParams.CheckMandatory(cls.Attributes, this.m_props, null);
        }
        return null;
    }

    //

    public EvalExCode(code: string, lid: number, rtp: WFFieldType): Object {
        return null;
    }
}
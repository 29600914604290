import { WFInteractiveDictionary, WFClassAttrib, WFColumnFormat, IWFObject } from '../model/index';
import { IWFDataFetchAdp } from './IWFDataFetchAdp';
import { CacheService } from '../data/cache.service';
import { WFDataSource, WFBasicDataType } from '../data/data.service';
import { ReportViewerComponent } from '../report-viewer/report-viewer.component';

export class WFInteractiveDictAdp extends IWFDataFetchAdp {
    private m_dict: WFInteractiveDictionary;

    private m_cache_sum: string;
    private m_cache_dta: Array<[string, Array<any>]>;

    //

    constructor(dc: WFInteractiveDictionary) {
        super();
        this.m_dict = dc;
        this.m_cache_sum = '';
        this.m_cache_dta = null;
    }

    public get Name(): string {
        return this.m_dict.Name;
    }
    public get Description(): string {
        return this.m_dict.Description;
    }
    public get UpdatedAt(): Date {
        return this.m_dict.UpdatedAt;
    }
    public get Report(): boolean {
        return this.m_dict.Report;
    }
    public get ReportLabel(): string {
        return this.m_dict.ReportLabel;
    }
    public get ReportVisible(): boolean {
        return this.m_dict.ReportVisible;
    }
    public get Parameters(): Array<WFClassAttrib> {
        return this.m_dict.Parameters;
    }
    public get ColumnsFormat(): Array<WFColumnFormat> {
        return this.m_dict.ColumnsFormat;
    }
    public get TaskID(): string {
        return IWFObject.Format('dict{0}', this.m_dict.ID);
    }

    protected FetchDataCompleted(dta: Array<[string, Array<Object>]>, ustate2: Object): void {
        let prms: Array<Object> = <Object[]>ustate2;
        let fromcache: boolean = <boolean>prms[0];
        let actsum: string = <string>prms[1];
        let ustate: Object = prms[2];
        if (fromcache || this.m_cache_sum === actsum) {
            this.m_cache_dta = dta;
            this.m_cache_sum = actsum;
        }
        this.ConfirmFetchAction(dta, ustate);
    }

    private ExecuteInteractiveDict(serv: CacheService, prms: Map<number, Object>, usecache: boolean, uval: Object, dbname: string): void {
        let bld: Array<string> = new Array<string>();
        prms.forEach(function (obj, kv) {
            if (bld.length > 0)
                bld.push(';');
            bld.push(IWFObject.Format('{0}={1}', kv, obj));
        });

        let actsum: string = IWFObject.GetHashString(bld.join(''));
        if (usecache && actsum === this.m_cache_sum) {
            this.ConfirmFetchAction(this.m_cache_dta, uval);
        } else {
            this.SendExecuteInteractiveDict(serv, prms, [ usecache, actsum, uval ], WFDataSource.INTERACTIVEDICT, this.m_dict.ID, dbname);
        }
    }

    public FetchDictData(serv: CacheService, prms: Map<number, Object>, usecache: boolean, dbname: string, uval: Object = null): void {
        if (WFClassAttrib.CheckMandatory(this.m_dict.Parameters, prms)) {
            this.ExecuteInteractiveDict(serv, prms, usecache, uval, dbname);
        } else {
            //let edtdlg: EditParameters = new EditParameters(this, prms);
            //edtdlg.DataContext = usecache, uval;
            //edtdlg.Closed += new EventHandler(this.EditParametersClosed);
            //edtdlg.Show();
        }
    }

    /*private EditParametersClosed(sender: Object, e: EventArgs): void {
        try {
            var snd: EditParameters = <EditParameters>sender;
            if (snd.DialogResult == true) {
                var ovec: Object[] = <Object[]>snd.DataContext;
                ExecuteInteractiveDict(snd.ParamValues, <boolean>ovec[0], ovec[1]);
            }
            else {
                ConfirmFetchAction(null, snd.DataContext);
            }
        }
        catch (ex) {
            MessageWnd.Show(ex);
        }

    }*/
}
import { Component, OnInit, AfterViewInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { WFDocument, WFAuthStatus, IWFObject, WFDocumentAuthVarSchema, WFDocumentAuthVarBasic, IWFDocumentAuthVariable, WFUser, WFUserGroup, WFInstPrmType, WFInstPrmObjType, WFAuthSchema, WFAuthInstruction, WFInstructionParameter, WFDocumentAuthSchema, WFAuthMethodType, WFDocumentAuthRecipient, WFSchemaRecipient, WFObjType, WFDocAuthObjType, WFDocumentAuthLock } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { TreeViewItem } from '../tree-view/treeviewitem';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html',
  styleUrls: ['./approvals.component.scss']
})

export class ApprovalsComponent implements AfterViewInit {
  private m_doc: WFDocumentAdp;
  private m_tmprcps: Array<WFDocumentAuthRecipient>;
  private m_trcps: Array<WFSchemaRecipient>;

  @ViewChild('m_hisinstance', {static: false}) private m_hisinstance: ComboboxadpComponent;
  public m_date: string;
  public m_comment: string;
  public m_hisinstance_vis: boolean;
  public m_clearauthsts: boolean;
  public m_approvalselector_items: Array<TreeViewItem>;

  @Output() public onClosed: EventEmitter<boolean>;

  public m_busy_str: string;

  //
  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {
    this.m_doc = null;
    this.m_tmprcps = null;
    this.m_trcps = null;
    this.m_date = '';
    this.m_comment = '';
    this.m_hisinstance_vis = false;
    this.onClosed = new EventEmitter<boolean>();
    this.m_clearauthsts = false;
    this.m_approvalselector_items = Array<TreeViewItem>();
    this.m_busy_str = '';
  }

  //

  private SchemaToDocRecipients(trcps: Array<WFSchemaRecipient>): Array<WFDocumentAuthRecipient> {
    let ii: number;

    let larc = new Array<WFDocumentAuthRecipient>();
    let arc: WFDocumentAuthRecipient;

    for (ii = 0; ii < trcps.length; ii++) {
      let src: WFSchemaRecipient = trcps[ii];
      switch (src.ObjType) {
        case WFObjType.AUTHSCHEMA:
          let ash: WFAuthSchema = this.m_cache_srv.AuthSchemas.get(src.ObjID);
          let ashm: WFDocumentAuthSchema = WFDocumentAuthSchema.Create(ash.Recipients, ash.Name, ash.Type, ash.AuthUserLimit, ash.ID, ash.AdditionalInfo,
            this.m_cache_srv.AppSystem);
          arc = WFDocumentAuthRecipient.Create2(ashm, src.Order, src.AdditionalInfo, 0);
          larc.push(arc);
          break;

        case WFObjType.USER:
          arc = WFDocumentAuthRecipient.Create(src.ObjID, src.Order, src.AdditionalInfo, 0);
          larc.push(arc);
          break;

        case WFObjType.USERGROUP:
          let ugr: WFUserGroup = this.m_cache_srv.UserGroups.get(src.ObjID);
          for (let uid of ugr.Users) {
            arc = WFDocumentAuthRecipient.Create(uid, src.Order, src.AdditionalInfo, 0);
            larc.push(arc);
          }
          break;
      }
    }

    return larc;
  }

  public SetPrms(doc: WFDocumentAdp, trcps: Array<WFSchemaRecipient>): void {
    this.m_doc = doc;
    this.m_tmprcps = null;
    this.m_trcps = trcps;
  }

  private FillInfoGrid(tvi: TreeViewItem, lbl: string, st: WFAuthStatus, locked: Date): void {

    /*SolidColorBrush normalBrush = (SolidColorBrush)Application.Current.Resources["BTNBCK_ACTIVE"];
    SolidColorBrush activeBrush = (SolidColorBrush)Application.Current.Resources["BTNBCK_HIGHLIGHT"];
    SolidColorBrush alertBrush = (SolidColorBrush)Application.Current.Resources["BTNBCK_ALERT"];

    PathButton bt = new PathButton();
    bt.Margin = new Thickness(3, 3, 3, 3);
    bt.Content = lbl.ToUpper();
    bt.NormalColor = normalBrush;*/
    tvi.Header = lbl.toUpperCase();

    switch (st) {
      case WFAuthStatus.WAITING:
        if (locked == null) {
          tvi.Icon = 'appr_toapprove.svg';
        } else {
          tvi.Icon = 'appr_locked.svg';
          //ToolTipService.SetToolTip(bt, WFDocumentAdp.DateTimeToStr(locked));
          //BindingOperations.SetBinding(bt, PathButton.PathDataProperty, new Binding { Source = (string)Application.Current.Resources["APPR_LOCKED"] });
          //bt.NormalColor = activeBrush;
        }
        break;
      case WFAuthStatus.APPROVED:
        tvi.Icon = 'appr_approved.svg';
        tvi.HighlightColor = ' var(--CL_BTNBCK_HIGHLIGHT)';
        //BindingOperations.SetBinding(bt, PathButton.PathDataProperty, new Binding { Source = (string)Application.Current.Resources["APPR_APPROVED"] });
        //bt.NormalColor = activeBrush;
        break;
      case WFAuthStatus.DISAPPROVED:
        tvi.Icon = 'appr_rejected.svg';
        tvi.HighlightColor = ' var(--CL_STATREJECT)';
        //BindingOperations.SetBinding(bt, PathButton.PathDataProperty, new Binding { Source = (string)Application.Current.Resources["APPR_REJECTED"] });
        //bt.NormalColor = alertBrush;
        break;
    }
  }

  private CreateTvi(rcp: WFDocumentAuthRecipient, lck: WFDocumentAuthLock): TreeViewItem {
    let ii: number;
    let sts: WFAuthStatus;
    let tvi: TreeViewItem = new TreeViewItem();

    switch (rcp.ObjType) {
      case WFDocAuthObjType.DOCAUTHSCHEMA:
        let shm: WFDocumentAuthSchema = rcp.SubSchema;

        sts = shm.GetStatus();
        let addtxt = '';
        switch (shm.Type) {
          case WFAuthMethodType.AUTH_ONEOF:
            addtxt = this.m_strdata_srv.getStr('wApprovals_OneFrom');
            break;
          case WFAuthMethodType.AUTH_ALLOF:
            addtxt = this.m_strdata_srv.getStr('wApprovals_All');
            break;
          case WFAuthMethodType.AUTH_NOFF:
            addtxt = IWFObject.Format('{0} ' + this.m_strdata_srv.getStr('wApprovals_XFrom'), shm.Userlimit);
            break;
        }

        this.FillInfoGrid(tvi, IWFObject.Format('{0} ({1})', shm.Name, addtxt), sts, null);
        tvi.UserObj = null;

        let slck: WFDocumentAuthLock = shm.FindUnLock();

        let cc = shm.GetRecipientsCount();
        let darcp: Array<WFDocumentAuthRecipient> = new Array<WFDocumentAuthRecipient>(cc);
        for (ii = 0; ii < cc; ii++) {
          darcp[ii] = shm.GetRecipient(ii);
        }
        darcp.sort((a1, a2) => a1.Order - a2.Order);
        for (ii = 0; ii < cc; ii++) {
          let stvi: TreeViewItem = this.CreateTvi(darcp[ii], slck);
          tvi.Items.push(stvi);
        }
        break;

      case WFDocAuthObjType.USER:
        let uuid: number = (rcp.ObjID === WFAuthSchema.OBJID_OWNER) ? this.m_doc.UserID : rcp.ObjID;
        let usr: WFUser = (uuid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uuid);
        let locked: Date = null;
        if (lck != null) {
          if (lck.UserID === uuid) locked = lck.LockAt;
        }

        let str: string;
        if (rcp.AuthUserID > 0 && rcp.AuthUserID !== uuid) {
          let usr2: WFUser = (rcp.AuthUserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(rcp.AuthUserID);
          str = IWFObject.Format(this.m_strdata_srv.getStr('strReplacement'), usr2.FriendlyName, usr.FriendlyName);
        } else {
          str = usr.FriendlyName;
        }

        this.FillInfoGrid(tvi, str, rcp.Status, locked);
        tvi.UserObj = rcp;
        break;
    }

    return tvi;
  }

  public ngAfterViewInit(): void {
    let ii: number, lid: number;

    try {
      let darcp: Array<WFDocumentAuthRecipient> = null;
      let slck: WFDocumentAuthLock = null;
      let addacttp: string = null;

      if (this.m_trcps == null) {
        if (this.m_doc.IsSchemaChanged) {
          //pokaz aktualna pusta instancje
          if (this.m_doc.AuthSchemaID > 0) {
            let shm: WFAuthSchema = this.m_cache_srv.AuthSchemas.get(this.m_doc.AuthSchemaID);
            addacttp = shm.Name;
            darcp = this.SchemaToDocRecipients(shm.Recipients);
          } else {
            if (this.m_doc.AuthSchemaID === WFDocument.SCHM_CUSTOM) {
              addacttp = this.m_strdata_srv.getStr('clOther');
              let rcps: Array<WFSchemaRecipient> = this.m_doc.CloneRecipients();
              darcp = this.SchemaToDocRecipients(rcps);
            }
          }
        } else {
          //pokaz ostatnia jest jest
          if (this.m_doc.AuthPathsCount > 0) {
            lid = this.m_doc.AuthPathsCount - 1;

            let apth: WFDocumentAuthSchema = this.m_doc.GetAuthPath(lid);
            slck = apth.FindUnLock();

            let cc = apth.GetRecipientsCount();
            darcp = new Array<WFDocumentAuthRecipient>(cc);

            for (ii = 0; ii < cc; ii++) {
              darcp[ii] = apth.GetRecipient(ii);
            }
          }
        }
      } else {
        addacttp = this.m_strdata_srv.getStr('clOther');
        this.m_tmprcps = this.SchemaToDocRecipients(this.m_trcps);
        darcp = this.m_tmprcps;
      }

      //

      if (darcp != null) {
        darcp.sort((a1, a2) => a1.Order - a2.Order);
        for (ii = 0; ii < darcp.length; ii++) {
          let tvi: TreeViewItem = this.CreateTvi(darcp[ii], slck);
          this.m_approvalselector_items.push(tvi);
        }
      }

      this.m_hisinstance_vis = false;
      if (this.m_doc.AuthPathsCount > 1) {
        lid = this.m_doc.AuthPathsCount - 1;

        this.m_hisinstance_vis = true;
        for (ii = 0; ii < this.m_doc.AuthPathsCount; ii++) {
          let shm: WFDocumentAuthSchema = this.m_doc.GetAuthPath(ii);
          let authtp: string;
          switch (shm.AuthSchemaID) {
            case WFDocument.SCHM_NOAUTH:
              authtp = this.m_strdata_srv.getStr('strAuthNoAuth');
              break;

            case WFDocument.SCHM_CUSTOM:
              authtp = this.m_strdata_srv.getStr('clOther');
              break;

            default:
              authtp = shm.Name;
              break;
          }

          let stsstr = '';
          switch (shm.GetStatus()) {
            case WFAuthStatus.APPROVED:
              stsstr = this.m_strdata_srv.getStr('enumWFAuthStatusAPPROVED');
              break;
            case WFAuthStatus.DISAPPROVED:
              stsstr = this.m_strdata_srv.getStr('enumWFAuthStatusDISAPPROVED');
              break;
            case WFAuthStatus.WAITING:
              stsstr = this.m_strdata_srv.getStr('enumWFAuthStatusWAITING');
              break;
          }

          this.m_hisinstance.AddValidValue(ii.toString(), IWFObject.Format('{0} - {1}({2})', ii + 1, authtp, stsstr));
        }

        this.m_hisinstance.SelectedValue = lid.toString();
      }

      if (addacttp != null) {
        this.m_hisinstance.AddValidValue('-1', IWFObject.Format('? - {0}', addacttp));
        this.m_hisinstance.SelectedValue = '-1';
      }

      //fix angular > 7
      const _self= this;
      setTimeout(() =>{
        _self.m_clearauthsts = (_self.m_doc.CanClearAuthStatus);  
      }, 500);

      //this.m_clearauthsts = (this.m_doc.CanClearAuthStatus);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public SelectInstance(nval: string): void {
    try {
      let ii: number;
      let selid = IWFObject.ParseInt(nval);

      let darcp: Array<WFDocumentAuthRecipient> = null;
      let slck: WFDocumentAuthLock = null;

      this.m_approvalselector_items.splice(0, this.m_approvalselector_items.length);

      if (selid >= 0) {
        let apth: WFDocumentAuthSchema = this.m_doc.GetAuthPath(selid);
        slck = apth.FindUnLock();
        let cc: number = apth.GetRecipientsCount();
        darcp = new Array<WFDocumentAuthRecipient>(cc);
        for (ii = 0; ii < cc; ii++) {
          darcp[ii] = apth.GetRecipient(ii);
        }
      } else {
        if (this.m_tmprcps == null) {
          let rcps: Array<WFSchemaRecipient>;
          if (this.m_doc.AuthSchemaID > 0) {
            rcps = this.m_cache_srv.AuthSchemas.get(this.m_doc.AuthSchemaID).Recipients;
            darcp = this.SchemaToDocRecipients(rcps);
          } else {
            if (this.m_doc.AuthSchemaID === WFDocument.SCHM_CUSTOM) {
              rcps = this.m_doc.CloneRecipients();
              darcp = this.SchemaToDocRecipients(rcps);
            }
          }
        } else {
          darcp = this.m_tmprcps;
        }
      }

      if (darcp != null) {
        darcp.sort((a1, a2) => a1.Order - a2.Order);
        for (ii = 0; ii < darcp.length; ii++) {
          let tvi: TreeViewItem = this.CreateTvi(darcp[ii], slck);
          this.m_approvalselector_items.push(tvi);
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SelectApproval(seltvi: TreeViewItem): void {
    try {
      this.m_date = '';
      this.m_comment = '';

      if (seltvi != null) {
        if (seltvi.UserObj != null) {
          let st: WFDocumentAuthRecipient = <WFDocumentAuthRecipient>seltvi.UserObj;
          if (st.Status !== WFAuthStatus.WAITING) {
            this.m_date = WFDocumentAdp.DateTimeToStr(st.Approved, this.m_strdata_srv);
            this.m_comment = st.Comment;
          }
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public OKButton_Click(): void {
    this.onClosed.emit(true);
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  //

  public ClearAuthStatus_Click(): void {
    try {
      const self = this;
      this.m_global_srv.showMsg(ALR_ICONTYPE.WARNING, BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('wApprovals_ClearAsk'),
        (res) => {
          self.ClearAuthStatusAskClosed(res);
        });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ClearAuthStatusAskClosed(res: WNDRESULT): void {
    try {
      if (res === WNDRESULT.OKYES) {
        this.m_doc.ClearAuthStatus();
        this.onClosed.emit(true);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ComboValueDesc } from '../app.component';

@Component({
  selector: 'app-auto-complete-text-box-popup',
  templateUrl: './auto-complete-text-box-popup.component.html',
  styleUrls: ['./auto-complete-text-box-popup.component.scss']
})

export class AutoCompleteTextBoxPopupComponent {
  public m_refelement: any;
  public m_values: Map<string, string>;
  public m_keyword: string;
  public m_items: Array<ComboValueDesc>;

  //public m_dailycorrefs: Array<LocalDailyCorRefInfo>;

  @Output() closePopup: EventEmitter<void>;
  @Output() selectValue: EventEmitter<string>;

  constructor() { 
    this.m_refelement = null;
    this.m_values = new Map<string, string>();
    this.m_keyword = 'a';
    this.m_items = new Array<ComboValueDesc>();
    this.closePopup = new EventEmitter<void>();
    this.selectValue = new EventEmitter<string>();
  }

  public SetPrms(refele: any, vals: Map<string, string>): void {
    this.m_refelement = refele;
    this.m_values = vals;
    this.ChangeKWords('');
  }

  public ChangeKWords(kwords: string): void {
    let ukw = kwords.toUpperCase();
    if (ukw !== this.m_keyword) {
      this.m_items.splice(0, this.m_items.length);
      if (ukw.length > 0) {
        for (let kv of Array.from(this.m_values.entries())) {
          if (kv[0].toUpperCase().indexOf(ukw) >= 0 || kv[1].toUpperCase().indexOf(ukw) >= 0) this.m_items.push({ val: kv[0], desc: kv[1] });
        }
      } else {
        for (let kv of Array.from(this.m_values.entries())) {
          this.m_items.push({ val: kv[0], desc: kv[1] });
        }
      }
      this.m_keyword = ukw; 
    }
  }
 
  public SelectItem(kv: ComboValueDesc, event: any): void {
    event.stopPropagation();
    this.selectValue.emit(kv.val); 
  }
}

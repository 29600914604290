import { WFClassAttrib } from './WFClassAttrib';
import { WFColumnFormat } from './WFColumnFormat';
import { IWFObject } from './IWFObject';

export enum WFDictScriptType {
    SCRIPT_MSSQL
}

export class WFInteractiveDictionary {
    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_sctype: WFDictScriptType;
    private m_scriptctx: string;
    private m_sqlconnstr: string;
    private m_allowcache: boolean;
    private m_createdat: Date;
    private m_updatedat: Date;
    private m_params: Array<WFClassAttrib>;
    private m_organization_id: number;
    private m_report: boolean;
    private m_report_label: string;
    private m_report_visible: boolean;
    private m_columnformats: Array<WFColumnFormat>;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_sctype = obj.sctype;
        this.m_scriptctx = obj.scriptctx;
        this.m_sqlconnstr = obj.sqlconnstr;
        this.m_allowcache = obj.allowcache;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);

        this.m_params = new Array<WFClassAttrib>();
        for (let prm of obj.params) this.m_params.push(new WFClassAttrib(prm));

        this.m_organization_id = obj.organization_id;
        this.m_report = obj.report;
        this.m_report_label = obj.report_label;

        this.m_columnformats = new Array<WFColumnFormat>();
        for (let cf of obj.columnformats) this.m_columnformats.push(new WFColumnFormat(cf));

        this.m_report_visible = obj.report_visible;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public get Name(): string {
        return this.m_name;
    }

    public get Description(): string {
        return this.m_description;
    }

    public get ScriptType(): WFDictScriptType {
        return this.m_sctype;
    }

    public get ScriptContent(): string {
        return this.m_scriptctx;
    }

    public get SQLConnectionString(): string {
        return this.m_sqlconnstr;
    }

    public get AllowCache(): boolean {
        return this.m_allowcache;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public get OrganizationID(): number {
        return this.m_organization_id;
    }

    public set Report(value: boolean) {
        this.m_report = value;
    }

    public get Report(): boolean {
        return this.m_report;
    }

    public get ReportLabel(): string {
        return this.m_report_label;
    }

    public get ReportVisible(): boolean {
        return this.m_report_visible;
    }

    public get Parameters(): Array<WFClassAttrib> {
        return this.m_params;
    }

    public get ColumnsFormat(): Array<WFColumnFormat> {
        return this.m_columnformats;
    }

    public toString(): string {
        return this.m_name;
    }
}
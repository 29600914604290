import { WFAuthStatus, WFDocument, WFPermissionInfo } from './WFDocument';
import { WFDocumentAuthRecipient } from './WFDocumentAuthRecipient';
import { WFSystem } from './WFSystem';
import { WFUserGroup } from './WFUserGroup';

export enum WFDstObjType {
    COMPANY,
    CLASS,
    DOCUMENT,
    DAILYCORRESPONDENCE
}

export enum WFPropType {
    DOC_CANEDIT,
    DOC_CANMOVE,
    DIR_CANCREATE,
    DIR_CANCHANGE,
    DIR_CANMOVE,
    CMN_CANMOVE,
    DOC_CANEDITATTRIB,
    DOC_CANEDITLINES,
    DAILY_ADDNEW,
    DAILY_DEL,
    DOC_CANADDPAGE,
    DAILY_EDTREF
}

export enum WFPrmType {
    CHANGE,
    VIEW,
    DISABLE
}

export enum WFPrmObjType {
    USER,
    USERGROUP
}

export enum WFPrmDocState {
    APPROVEDNOAUTH,
    DISAPPROVED,
    BEFOREAUTH, //w sekretariacie
    INAUTH //w trakcie
}

export class WFPermission {
    private m_id: number;
    private m_proptype: WFPropType;
    private m_permtype: WFPrmType;
    private m_obj_type: WFPrmObjType;
    private m_obj_id: number;
    private m_classattrib_id: number;

    //
    private m_docstate: WFPrmDocState;
    private m_authschema_id: number;
    //

    public static ActualizePermissionInfoList(lst: Array<WFPermissionInfo>, prm: WFPermission, sys: WFSystem, doc: WFDocument): void {
        switch (prm.ObjType) {
            case WFPrmObjType.USER:
                lst.push(new WFPermissionInfo(prm.ObjID, -1, prm.PermissionType));
                break;

            case WFPrmObjType.USERGROUP:
                if (prm.ObjID > 0) {
                    let ugr: WFUserGroup = sys.AllUserGroups.get(prm.ObjID);
                    for (let uid of ugr.Users) {
                        lst.push(new WFPermissionInfo(uid, prm.ObjID, prm.PermissionType));
                    }
                } else {                   

                    switch(prm.ObjID) {
                        case WFUserGroup.ALLUSERS:
                            sys.AllUsers.forEach(function (usr, id) {
                                lst.push(new WFPermissionInfo(usr.ID, WFUserGroup.ALLUSERS, prm.PermissionType));
                            });
                            break;
                        
                        case WFUserGroup.LASTAUTH:
                            if(doc != null) {
                                let cc = doc.GetAuthPathsCount();
                                if(cc > 0) {
                                    let shm = doc.GetAuthPath(cc - 1);                                                                   
                                    shm.ExtractAllUsers((rcp) =>
                                    {                                       
                                        if (rcp.Status == WFAuthStatus.APPROVED)
                                        {                                               
                                            lst.push(new WFPermissionInfo(rcp.ObjID, WFUserGroup.LASTAUTH, prm.PermissionType));
                                        }
                                    });
                                }
                            }                       
                            break;
                        
                    }    
                }
                break;
        }
    }

    public static Create(ptp: WFPropType, prmtp: WFPrmType, objid: number, tp: WFPrmObjType): WFPermission {
        return new WFPermission({
            id: 0,
            proptype: ptp,
            permtype: prmtp,
            obj_type: tp,
            obj_id: objid,
            classattrib_id: 0,
            docstate:  WFPrmDocState.APPROVEDNOAUTH,
            authschema_id: 0
        });
    }

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_proptype = obj.proptype;
        this.m_permtype = obj.permtype;
        this.m_obj_type = obj.obj_type;
        this.m_obj_id = obj.obj_id;
        this.m_classattrib_id = obj.classattrib_id;
        this.m_docstate = obj.docstate;
        this.m_authschema_id = obj.authschema_id;
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public get PropertyType(): WFPropType {
        return this.m_proptype;
    }

    public set PermissionType(value: WFPrmType) {
        this.m_permtype = value;
    }

    public get PermissionType(): WFPrmType {
        return this.m_permtype;
    }

    public set ObjType(value: WFPrmObjType) {
        this.m_obj_type = value;
    }

    public get ObjType(): WFPrmObjType {
        return this.m_obj_type;
    }

    public set ObjID(value: number) {
        this.m_obj_id = value;
    }

    public get ObjID(): number {
        return this.m_obj_id;
    }

    public get AttribID(): number {
        return this.m_classattrib_id;
    }

    public get DocState(): WFPrmDocState {
        return this.m_docstate;
    }

    public get AuthSchemaID(): number {
        return this.m_authschema_id;
    }

    //

    public ToObject(): any {
        return {
            id: this.m_id,
            proptype: this.m_proptype,
            permtype: this.m_permtype,
            obj_type: this.m_obj_type,
            obj_id: this.m_obj_id,
            classattrib_id: this.m_classattrib_id,
            docstate: this.m_docstate,
            authschema_id: this.m_authschema_id
        };
    }
}
import { WFAuthStatus } from './WFDocument';

export class WFFilterInfo {
    private m_byclass: Map<number, Array<number>>;
    private m_byauthschema: Map<number, Array<number>>;
    private m_byuser: Map<number, Array<number>>;
    private m_bycompany: Map<number, Array<number>>;
    private m_byauthstatus: Map<WFAuthStatus, Array<number>>;
    private m_today: Array<number>;
    private m_last5days: Array<number>;
    private m_lastmonth: Array<number>;

    //

    constructor(obj: any) {
        let ii: number;
        let cc: number;

        this.m_byclass = new Map<number, Array<number>>();
        cc = obj.byclass.length / 2;
        for (ii = 0; ii < cc; ii++) {
            this.m_byclass.set(obj.byclass[ii * 2],
                obj.byclass[ii * 2 + 1]);
        }

        this.m_byauthschema = new Map<number, Array<number>>();
        cc = obj.byauthschema.length / 2;
        for (ii = 0; ii < cc; ii++) {
            this.m_byauthschema.set(obj.byauthschema[ii * 2],
                obj.byauthschema[ii * 2 + 1]);
        }

        this.m_byuser = new Map<number, Array<number>>();
        cc = obj.byuser.length / 2;
        for (ii = 0; ii < cc; ii++) {
            this.m_byuser.set(obj.byuser[ii * 2],
                obj.byuser[ii * 2 + 1]);
        }

        this.m_bycompany = new Map<number, Array<number>>();
        cc = obj.bycompany.length / 2;
        for (ii = 0; ii < cc; ii++) {
            this.m_bycompany.set(obj.bycompany[ii * 2],
                obj.bycompany[ii * 2 + 1]);
        }

        this.m_byauthstatus = new Map<WFAuthStatus, Array<number>>();
        cc = obj.byauthstatus.length / 2;
        for (ii = 0; ii < cc; ii++) {
            this.m_byauthstatus.set(obj.byauthstatus[ii * 2],
                obj.byauthstatus[ii * 2 + 1]);
        }

        this.m_today = obj.today;
        this.m_last5days = obj.last5days;
        this.m_lastmonth = obj.lastmonth;
    }

    //

    public get ByClass(): Map<number, Array<number>> {
        return this.m_byclass;
    }

    public get ByAuthSchema(): Map<number, Array<number>> {
        return this.m_byauthschema;
    }

    public get ByUser(): Map<number, Array<number>> {
        return this.m_byuser;
    }

    public get ByCompany(): Map<number, Array<number>> {
        return this.m_bycompany;
    }

    public get ByAuthStatus(): Map<WFAuthStatus, Array<number>> {
        return this.m_byauthstatus;
    }

    public get FromToday(): Array<number> {
        return this.m_today;
    }

    public get FromLast5Days(): Array<number> {
        return this.m_last5days;
    }

    public get FromLastMonth(): Array<number> {
        return this.m_lastmonth;
    }
}
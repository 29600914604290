import { Component, OnInit, EventEmitter, Output, ViewEncapsulation, AfterViewInit, ViewChild, Injectable } from '@angular/core';
import { NgbDateStruct, NgbDate, NgbDatepicker, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { StringDataService } from '../stringdata/stringdata.service';
import { IWFObject } from '../model';


@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {
  constructor(protected m_strdata_srv: StringDataService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.m_strdata_srv.getStr('lblSWeekday'+weekday.toString());
  }

  getMonthShortName(month: number): string {    
    return this.m_strdata_srv.getStr('lblSMonth'+month.toString());
  }

  getMonthFullName(month: number): string {    
    return this.m_strdata_srv.getStr('lblMonth'+month.toString());
  }

  getDayAriaLabel(date: NgbDateStruct): string {    
    return IWFObject.Format('{0}-{1}-{2}', date.day, date.month, date.year);
  }
}

@Component({
  selector: 'app-datepickeradp-popup',
  templateUrl: './datepickeradp-popup.component.html',
  styleUrls: ['./datepickeradp-popup.component.scss'],
  providers: [ { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n } ]
})

export class DatepickeradpPopupComponent implements AfterViewInit {
  public m_refelement: any;
  public m_date: NgbDateStruct;

  @ViewChild('m_dtpicker', {static: false}) private m_dtpicker: NgbDatepicker;

  @Output() closePopup: EventEmitter<void>;
  @Output() selectValue: EventEmitter<Date>;

  constructor() { 
    this.m_refelement = null;
    this.m_date= null;
    this.closePopup = new EventEmitter<void>();
    this.selectValue = new EventEmitter<Date>();
  }

  public SetPrms(refele: any, dta: Date): void {
    this.m_refelement = refele;
    if(dta== null) {
      this.m_date= null;
    } else {
      this.m_date = {
        year: dta.getFullYear(), 
        month: dta.getMonth() + 1, 
        day: dta.getDate()
      };
    }
  }

  ngAfterViewInit() {
    if(this.m_date== null) {
      this.m_dtpicker.navigateTo();
    } else {
      this.m_dtpicker.navigateTo({ 
        year: this.m_date.year, 
        month: this.m_date.month
      });
    }
  }

  public onChanged(event: NgbDateStruct): void {
    //selectValue.emit($event)
    //{year: 2020, month: 9, day: 18}
    //console.log('onChanged', event);
    this.selectValue.emit(new Date(event.year, event.month - 1, event.day));
  }
}

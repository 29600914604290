import { Component, OnInit, ViewChild, HostListener, ElementRef, NgZone, ComponentRef, ChangeDetectorRef, Renderer2, AfterViewInit, DebugElement } from '@angular/core';
import { StringDataService } from './stringdata/stringdata.service';
import { DataService, FindDocumentPrms, UserAlert, DailyCorRefInfo, MailMsgPreview } from './data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from './global/global.service';
import { Observable } from 'rxjs/Rx';
import { ChildWindowContainerService } from './child-window/child-window-container.service';
import { LoginWndComponent } from './login-wnd/login-wnd.component';
import { AlertWndComponent, ALR_ICONTYPE } from './alert-wnd/alert-wnd.component';
import { TreeViewItem } from './tree-view/treeviewitem';
import { CacheService } from './data/cache.service';
import { WFDirectory, WFCompany, IWFObject, WFDocument, PrmType, WFClass, WFAuthSchema, WFUser, WFUserGroup, FavoriteDocument, FavoriteType, WFDocStatus, WFOcrTaskMode, WFDailyCorrespondence, WFDailyCorrespondenceRef, WFDocumentPage, WFTmpWriteMode, WFFilterInfo, WFAuthStatus } from './model/index';
import { WFDocumentAdp, LoadDocument, FetchAttachmentsCompleted, PublishDocument, RemoveDocument, _OnError } from './adapters/WFDocumentAdp';
import { MultiselectpanelComponent, ListItem } from './multiselectpanel/multiselectpanel.component';
import { ComboboxadpComponent } from './comboboxadp/comboboxadp.component';
import { DocumentList, SORT_PROPERTY, _FetchCompleted, _FetchFilterInfoCompleted } from './data/documentlist';
import { WFCompanyAdp } from './adapters/WFCompanyAdp';
import { WFDirectoryAdp, PublishDirectory, RemoveDirectory } from './adapters/WFDirectoryAdp';
import { AlertsGroup } from './alertsgroup';
import { ImageButtonComponent } from './image-button/image-button.component';
import { IWFCompanyObjAdp } from './adapters/IWFCompanyObjAdp';
import { IWFDataFetchAdp } from './adapters/IWFDataFetchAdp';
import { DocviewerComponent, COMMENTSMODE, ADDMODE, CONTENTTYPE, CommentModeChanged, ContentTypeChanged, PageChanged, ClassChanged, SelectDirectory } from './docviewer/docviewer.component';

import { CookieService } from 'ngx-cookie-service';

import { EventAdp } from './data/eventadp';
import { WFDailyCorrespondenceClassAdp } from './adapters/WFDailyCorrespondenceClassAdp';
import { ReportViewerComponent } from './report-viewer/report-viewer.component';
import { EditDailyCorrespondenceComponent } from './edit-daily-correspondence/edit-daily-correspondence.component';
import { WFDailyCorrespondenceAdp, PublishDailyCorrespondence } from './adapters/WFDailyCorrespondenceAdp';
import { AddNewDocMenuComponent } from './add-new-doc-menu/add-new-doc-menu.component';
import { DatePipe } from '@angular/common';

import { TemplateNameComponent } from './template-name/template-name.component';
import { DirectoryNameComponent } from './directory-name/directory-name.component';

import { UserPanelComponent } from './user-panel/user-panel.component';
import { DownloadDocumentComponent } from './download-document/download-document.component';
import { DocumentModificationsComponent } from './document-modifications/document-modifications.component';
import { AttachmentsPopupComponent } from './attachments-popup/attachments-popup.component';
import { DocumentPrintComponent } from './document-print/document-print.component';
import { DailyCorRefsPopupComponent } from './daily-cor-refs-popup/daily-cor-refs-popup.component';
import { FilterWndComponent, FltProperty } from './filter-wnd/filter-wnd.component';
import { AboutWndComponent } from './about-wnd/about-wnd.component';
import { OcrProcessLogsComponent } from './ocr-process-logs/ocr-process-logs.component';
import { PrintreporttaskComponent } from './printreporttask/printreporttask.component';
import { WFInteractiveDictAdp } from './adapters/WFInteractiveDictAdp';
import { WFMailFolderAdp } from './adapters/WFMailFolderAdp';
import { MailBoxViewerComponent } from './mail-box-viewer/mail-box-viewer.component';
import { WFDocumentInterfaceAdp } from './adapters/WFDocumentInterfacAdp';
import { EDocViewerComponent } from './edoc-viewer/edoc-viewer.component';

//

export const APP_VERSION = "6.5.0";

const DOCUMENTSPERPAGE = 30; //100;

export const FDOC_LOADATTACHMENTS = 1; //unused
export const FDOC_AUTHSTATUS_WAITING = 2;
export const FDOC_AUTHSTATUS_APPROVED = 4;
export const FDOC_AUTHSTATUS_DISAPPROVED = 8;
export const FDOC_INCLUDE_COMPANIES_ID = 16;
export const FDOC_INCLUDE_DIRECTORIES_ID = 32;
export const FDOC_INCLUDE_USERS_ID = 64;
export const FDOC_INCLUDE_DOCSTATUS = 128;
export const FDOC_DOCSTATUS_BEFOREOCR = 256;
export const FDOC_DOCSTATUS_INOCR = 512;
export const FDOC_DOCSTATUS_AFTEROCR = 1024;
export const FDOC_DOCSTATUS_INSYSTEM = 2048;
export const FDOC_INCLUDE_APPSTATUS = 4096;
export const FDOC_DOCSTATUS_TEMPLATE = 8192;
export const FDOC_INCLUDETRASH = 16384;

const APPSTS_NOTAPP = -1;
const APPSTS_TOAPPROVE = 0;
const APPSTS_APPBYME = 1;
const APPSTS_APPBYOTHER = 2;
const APPSTS_REJBYME = 3;
const APPSTS_REJBYOTHER = 4;

const FIND_WITHPRMS = 0;
const FIND_INIT = -1;
const FIND_INSYSTEM = -2;

const TAB_INPUTDOCSTAB = 0;
const TAB_ALERTSTAB = 1;
const TAB_APROVALSTAB = 2;
const TAB_SPECIALSTAB = 3;
const TAB_COMAPNIESTAB = 4;

const HISTORY_COUNT = 20;

export interface ComboValueDesc {
    val: string;
    desc: string;
}

//SK
export interface BreadCrumbsItem {
    val: object;
    desc: string;
    id: number;
    islink: boolean;
  }
  
export interface ComboIValueDesc {
    val: number;
    desc: string;
}

export interface AttachmentsData {
    atts: Array<WFDocumentAdp>;
    prns: Array<WFDocumentAdp>;
    invdc: Array<WFDocumentAdp>;
}

interface TabSpec {
    title: string;
    source: object;
    docviewer: DocviewerComponent;
    reportviewer: ReportViewerComponent;
    attachments: AttachmentsData;
    history: Array<[object, Map<string, string>]>;
    inargs: Map<string, string>;
    mailboxviewer: MailBoxViewerComponent;
    edocviewer: EDocViewerComponent;
}

export enum TabOperation {
    MAIN,
    ACTUAL,
    NEW
}

interface AttachmentInfo {
    title: string;
    doc: WFDocumentAdp;
}

interface DragDropElementInfo {
    source: Object;
    left: number;
    top: number;
}

export interface LocalDailyCorRefInfo {
    title: string;
    info: DailyCorRefInfo;
    canjump: boolean;
}

export enum ButtonState {
    HIDDEN,
    VISIBLE,
    ACTIVE
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {
    public m_busy_text: string;
    public m_username: string;
    public m_iscreator: boolean;
    // SK
    @ViewChild('zakladki', {static: false}) private zakladki: ElementRef;


    public set BusyText(nval: string) {
        this.m_busy_text = nval;
    }

    public m_companiesselector: Array<TreeViewItem>;
    public m_companies_label: string;

    public m_keywords: string;
    public m_last_keywords: Array<string>;
    public m_dir_sortby: Array<ComboIValueDesc>;

    public m_selected_tab: number;

    private m_all_companies: Map<number, TreeViewItem>;
    private m_all_directories: Map<number, TreeViewItem>;

    private m_selectedcmp: WFCompanyAdp;
    private m_selecteddir: WFDirectoryAdp;

    public m_selecteditem: WFDocumentAdp;
    private m_selecteditemmodstate: boolean;

    //SK DODAŁ
    public m_filesonright: boolean;
    public m_scrlvisible: boolean;
    public m_scrrvisible: boolean;
    public m_selecteddircontent: string;
    public m_selecteddirlist: Array<BreadCrumbsItem>;

    private m_lastseldirectory: TreeViewItem;
    //private m_lastselattachment: DocumentAdp3;
    private m_publishall: boolean;

    private m_found_documents: DocumentList;
    private m_recent_documents: DocumentList;
    private m_dumylist: DocumentList;
    private m_filterlist: DocumentList;
    private m_filtercfg: Array<any>;
    private m_sourcefilterlist: DocumentList;

    private m_removed_documents: DocumentList;
    private m_observed_documents: DocumentList;
    private m_favorite_documents: DocumentList;

    private m_befocr_documents: DocumentList;
    private m_inocr_documents: DocumentList;
    private m_aftocr_documents: DocumentList;
    private m_temp_documents: DocumentList;

    private m_toapproval_documents: DocumentList;
    private m_approvedbyme_documents: DocumentList;
    private m_approvedbyother_documents: DocumentList;
    private m_rejectedbyme_documents: DocumentList;
    private m_rejectedbyother_documents: DocumentList;

    private m_fileselector_src: Object;
    private m_fileselector_ctx: DocumentList;
    private m_fileselector_cpage: number;
    public m_fileselector: Array<WFDocumentAdp>;

    public m_special_label: string;
    public m_specialselector: Array<TreeViewItem>;

    public m_trash: TreeViewItem;
    public m_observed: TreeViewItem;
    public m_favorite: TreeViewItem;
    public m_recent: TreeViewItem;
    public m_dailycorrs: TreeViewItem;
    public m_reports: TreeViewItem;

    //zatwierdzenia
    public m_approval_label: string;
    public m_aprovalsselector: Array<TreeViewItem>;

    public m_toapproval: TreeViewItem;
    public m_approvedbyme: TreeViewItem;
    public m_approvedbyother: TreeViewItem;
    public m_rejectedbyme: TreeViewItem;
    public m_rejectedbyother: TreeViewItem;

    //alerty
    public m_alerts_label: string;
    public m_alertsselector: Array<TreeViewItem>;

    //sekretariat
    public m_inputdocs_label: string;
    public m_inputdirselector: Array<TreeViewItem>;

    public m_imap: TreeViewItem;
    public m_edoc: TreeViewItem;

    public m_befocr: TreeViewItem;
    public m_inocr: TreeViewItem;
    public m_afterocr: TreeViewItem;
    public m_templates: TreeViewItem;

    public m_advoptions: boolean;

    private m_alertsexec: boolean;
    public m_founddocs_label: string;
    public m_founddocs_hint: string;
    public m_page_selector: boolean;
    public m_current_page: string;
    public m_sortcol: ComboIValueDesc;

    public m_sortorder1: boolean;
    public m_sortorder2: boolean;
    public m_fldoptions: boolean;
    public m_filterbtn: boolean;
    public m_sort_label: boolean;

    private m_onerrorevh: EventAdp<_OnError>;
    private m_fetchcplevh: _FetchCompleted;
    private m_onfetchfilterinfocompletedevh: _FetchFilterInfoCompleted;

    private m_onloaddocument: LoadDocument;
    private m_onfetchattachments: FetchAttachmentsCompleted;
    private m_onpublishdocument: PublishDocument;
    private m_onremovedocument: RemoveDocument;

    private m_onpublishdirectory: PublishDirectory;
    private m_onremovedirectory: RemoveDirectory;

    //

    public m_tabs: Array<TabSpec>;
    public m_seltab: TabSpec;
    private m_lastviewtab: TabSpec;

    public m_docmenu: boolean;

    //zalaczniki
    public m_attachmentsbtn: boolean;
    public m_attachmentspop: boolean;
    public m_attachments: Array<AttachmentInfo>;

    public m_optionsbtn: boolean;
    public m_optionspanel: boolean;
    public m_commentsbtn: boolean;
    public m_commentsbtnMin: boolean;
    public m_commentsbtnHide: boolean;
    public m_commentsbtnAdd: boolean;
    public m_textregionsbtn: boolean;
    public m_textregionsbtnOff: boolean;
    public m_historysubpanel1: boolean;
    public m_favoritesubpanel1: boolean;
    public m_favoritesubpanel2: boolean;
    public m_observedsubpanel1: boolean;
    public m_observedsubpanel2: boolean;
    public m_addtodailycorrpanel: boolean;
    public m_addnewdailycorrpanel: boolean;
    public m_ocrlogssubpanel: boolean;
    public m_addattachmentbtn: boolean;

    public m_editpanel: boolean;
    public m_pageimportpanel: boolean;
    public m_pagecutpanel: boolean;
    public m_pagepastepanel: boolean;
    public m_pagereplacepanel: boolean;
    public m_savetodiskpanel: boolean;
    public m_importpagefromfile: boolean;
    public m_cutcurrentpage: boolean;
    public m_chgdocstatuspanel1: boolean;
    public m_chgdocstatuspanel3: boolean;

    public m_deletedocumentpanel: boolean;
    public m_chgdocstatuspanel2: boolean;
    public m_refreshreportpanel: boolean;

    public m_expandgroups: boolean;
    public m_collapsegroups: boolean;
    public m_adddailycorrpanel: boolean;
    public m_printreport: boolean;
    public m_copyreport: boolean;

    public m_markasseen: boolean;
    public m_unmarkseen: boolean;
    public m_showunread: ButtonState;
    public m_showunbind: ButtonState;

    public m_pubilshbtn: boolean;
    public m_reloadbtn: boolean;

    private m_cmmodechgevh: CommentModeChanged;
    private m_ctxtypechgevh: ContentTypeChanged;
    private m_pagechgevh: PageChanged;
    private m_classchgevh: ClassChanged;
    private m_seldirevh: SelectDirectory;

    //public m_lastdailycorrs: Array<WFDailyCorrespondenceAdp>;
    private m_publishdcorr: PublishDailyCorrespondence;

    public m_datep: DatePipe;
    private m_toOCR: WFDocStatus;
    private m_import_dst: WFDocumentAdp;
    private m_import_att: WFDocumentAdp;

    private m_cut_page: WFDocumentPage;

    public m_dragdrop: DragDropElementInfo;
    @ViewChild('m_dragdrop_popup', {static: false}) private m_dragdrop_popup: ElementRef;
    public m_dragdrop_popup_vis: boolean;
    public m_dragdrop_popup_img: string;

    private m_mousemovebnd: any;
    private m_mouseupbnd: any;

    public m_dailycorrefsbtn: boolean;
    public m_dailycorrrefs: Array<LocalDailyCorRefInfo>;

    //

    @ViewChild('m_companies', {static: false}) private m_companies: MultiselectpanelComponent;
    @ViewChild('m_owners', {static: false}) private m_owners: MultiselectpanelComponent;
    @ViewChild('m_classes', {static: false}) private m_classes: MultiselectpanelComponent;
    @ViewChild('m_schemas', {static: false}) private m_schemas: MultiselectpanelComponent;
    @ViewChild('m_other', {static: false}) private m_other: MultiselectpanelComponent;
    @ViewChild('m_attribs', {static: false}) private m_attribs: MultiselectpanelComponent;

    //

    @ViewChild('m_inputdocstab', {static: false}) private m_inputdocstab: ImageButtonComponent;
    @ViewChild('m_alertstab', {static: false}) private m_alertstab: ImageButtonComponent;
    @ViewChild('m_aprovalstab', {static: false}) private m_aprovalstab: ImageButtonComponent;
    @ViewChild('m_specialstab', {static: false}) private m_specialstab: ImageButtonComponent;
    @ViewChild('m_comapniestab', {static: false}) private m_comapniestab: ImageButtonComponent;

    @ViewChild('m_filesel', {static: false}) private m_filesel: ElementRef;
    @ViewChild('m_filesel2', {static: false}) private m_filesel2: ElementRef;

    private m_loginwnd: ComponentRef<LoginWndComponent>;

    public m_snackbartxt: string;
    public m_addnewdoctooltip: string;
    //

    private InitLabels(): void {

        this.m_dir_sortby = [
            { val: 0, desc: this.m_strdata_srv.getStr('tblHdrName') },
            { val: 1, desc: this.m_strdata_srv.getStr('tblHdrDescr') },
            { val: 2, desc: this.m_strdata_srv.getStr('tblHdrNo') },
            { val: 3, desc: this.m_strdata_srv.getStr('tblHdrDate') },
            { val: 4, desc: this.m_strdata_srv.getStr('tblHdrCDate') },
            { val: 5, desc: this.m_strdata_srv.getStr('tblHdrUDate') }
        ];
        this.m_sortcol = this.m_dir_sortby[0];

        this.m_companies_label = this.m_strdata_srv.getStr('strCompanies').toUpperCase();

        this.m_trash.Header = this.m_strdata_srv.getStr('fldTrash').toUpperCase();
        this.m_observed.Header = this.m_strdata_srv.getStr('strObserved').toUpperCase();
        this.m_favorite.Header = this.m_strdata_srv.getStr('strFavourites').toUpperCase();
        this.m_recent.Header = this.m_strdata_srv.getStr('strRecents').toUpperCase();
        this.m_dailycorrs.Header = this.m_strdata_srv.getStr('strDailyCorr').toUpperCase();
        this.m_reports.Header = this.m_strdata_srv.getStr('strReports').toUpperCase();

        this.m_special_label = this.m_strdata_srv.getStr('strSpecial').toUpperCase();

        this.m_toapproval.Header = this.m_strdata_srv.getStr('fldAuthWait').toUpperCase();
        this.m_approvedbyme.Header = this.m_strdata_srv.getStr('fldAuthByMe').toUpperCase();
        this.m_approvedbyother.Header = this.m_strdata_srv.getStr('fldAuthByOther').toUpperCase();
        this.m_rejectedbyme.Header = this.m_strdata_srv.getStr('fldRejectByMe').toUpperCase();
        this.m_rejectedbyother.Header = this.m_strdata_srv.getStr('fldRejectByOther').toUpperCase();

        this.m_alerts_label = this.m_strdata_srv.getStr('strAlerts').toUpperCase();

        this.m_imap.Header = this.m_strdata_srv.getStr('strIMAP').toUpperCase();
        this.m_edoc.Header = this.m_strdata_srv.getStr('strEDOC').toUpperCase();

        this.m_befocr.Header = this.m_strdata_srv.getStr('strDocEntered').toUpperCase();
        this.m_inocr.Header = this.m_strdata_srv.getStr('strDocOcr').toUpperCase();
        this.m_approval_label = this.m_strdata_srv.getStr('strApprove').toUpperCase();
        this.m_afterocr.Header = this.m_strdata_srv.getStr('strDocCheck').toUpperCase();
        this.m_templates.Header = this.m_strdata_srv.getStr('strTemplates').toUpperCase();

        this.m_inputdocs_label = this.m_strdata_srv.getStr('strSecretary').toUpperCase();
    }

    constructor(public m_strdata_srv: StringDataService,
        private m_data_srv: DataService,
        private m_global_srv: GlobalService,
        private m_wnd_srv: ChildWindowContainerService,
        private m_cache_srv: CacheService,
        private m_cookie_srv: CookieService,
        private m_zone: NgZone,
        private m_render: Renderer2) {

        this.m_global_srv.SetPrms(this);

        this.m_last_keywords = [];
        this.m_busy_text = '';
        this.m_username = '';
        this.m_iscreator = false;

        this.m_companiesselector = new Array<TreeViewItem>();
        //

        this.m_trash = new TreeViewItem();
        this.m_trash.Icon = 'cmn_trash.svg';
        //

        this.m_observed = new TreeViewItem();
        this.m_observed.Icon = 'cmn_observe.svg';
        //

        this.m_favorite = new TreeViewItem();
        this.m_favorite.Icon = 'cmn_favourites.svg';
        //

        this.m_recent = new TreeViewItem();
        this.m_recent.Icon = 'cmn_watch.svg';
        //

        this.m_dailycorrs = new TreeViewItem();
        this.m_dailycorrs.Icon = 'cmn_dailycorr.svg';
        //

        this.m_reports = new TreeViewItem();
        this.m_reports.Icon = 'cmn_reports.svg';
        //

        this.m_specialselector = new Array<TreeViewItem>();
        this.m_specialselector.push(this.m_trash);
        this.m_specialselector.push(this.m_observed);
        this.m_specialselector.push(this.m_favorite);
        this.m_specialselector.push(this.m_recent);
        this.m_specialselector.push(this.m_dailycorrs);
        this.m_specialselector.push(this.m_reports);

        //

        this.m_toapproval = new TreeViewItem();
        this.m_toapproval.Icon = 'appr_toapprove.svg';
        //

        this.m_approvedbyme = new TreeViewItem();
        this.m_approvedbyme.Icon = 'appr_approved.svg';
        //

        this.m_approvedbyother = new TreeViewItem();
        this.m_approvedbyother.Icon = 'appr_approther.svg';
        //

        this.m_rejectedbyme = new TreeViewItem();
        this.m_rejectedbyme.Icon = 'appr_rejected.svg';
        //

        this.m_rejectedbyother = new TreeViewItem();
        this.m_rejectedbyother.Icon = 'appr_rejother.svg';
        //

        this.m_aprovalsselector = new Array<TreeViewItem>();
        this.m_aprovalsselector.push(this.m_toapproval);
        this.m_aprovalsselector.push(this.m_approvedbyme);
        this.m_aprovalsselector.push(this.m_approvedbyother);
        this.m_aprovalsselector.push(this.m_rejectedbyme);
        this.m_aprovalsselector.push(this.m_rejectedbyother);

        //

        this.m_alertsselector = new Array<TreeViewItem>();
        //

        this.m_imap = new TreeViewItem();
        this.m_imap.Icon = 'cmn_dailycorr.svg';

        this.m_edoc = new TreeViewItem();
        this.m_edoc.Icon = 'cmn_dailycorr.svg';

        //

        this.m_befocr = new TreeViewItem();
        this.m_befocr.Icon = 'cmn_upload.svg';
        //

        this.m_inocr = new TreeViewItem();
        this.m_inocr.Icon = 'inp_inocr.svg';
        //

        this.m_afterocr = new TreeViewItem();
        this.m_afterocr.Icon = 'inp_afterocr.svg';
        //

        this.m_templates = new TreeViewItem();
        this.m_templates.Icon = 'cmn_template.svg';
        //

        this.m_inputdirselector = new Array<TreeViewItem>();      
        /*this.m_inputdirselector.push(this.m_befocr);
        this.m_inputdirselector.push(this.m_inocr); 
        this.m_inputdirselector.push(this.m_afterocr);
        this.m_inputdirselector.push(this.m_templates);
        this.m_inputdirselector.push(this.m_imap);
        this.m_inputdirselector.push(this.m_edoc);*/

        this.InitLabels();
        //

        this.m_selected_tab = TAB_COMAPNIESTAB;

        this.m_all_companies = new Map<number, TreeViewItem>();
        this.m_all_directories = new Map<number, TreeViewItem>();

        this.m_selectedcmp = null;
        this.m_selecteddir = null;

        this.m_selecteditem = null;
        this.m_selecteditemmodstate = false;
        //SK DODAŁ
        this.m_filesonright = false;
        this.m_scrlvisible = false;
        this.m_scrrvisible = false;

        this.m_lastseldirectory = null;
        //private m_lastselattachment: DocumentAdp3;
        this.m_publishall = false;

        this.m_found_documents = new DocumentList();
        this.m_recent_documents = new DocumentList();
        this.m_dumylist = new DocumentList();
        this.m_filterlist = new DocumentList();
        this.m_filtercfg = null;
        this.m_sourcefilterlist = null;

        this.m_removed_documents = new DocumentList();
        this.m_observed_documents = new DocumentList();
        this.m_favorite_documents = new DocumentList();

        this.m_befocr_documents = new DocumentList();
        this.m_inocr_documents = new DocumentList();
        this.m_aftocr_documents = new DocumentList();
        this.m_temp_documents = new DocumentList();

        this.m_toapproval_documents = new DocumentList();
        this.m_approvedbyme_documents = new DocumentList();
        this.m_approvedbyother_documents = new DocumentList();
        this.m_rejectedbyme_documents = new DocumentList();
        this.m_rejectedbyother_documents = new DocumentList();

        this.m_fileselector_src = null;
        this.m_fileselector_ctx = this.m_found_documents;
        this.m_fileselector_cpage = 0;
        this.m_fileselector = new Array<WFDocumentAdp>();

        this.m_advoptions = false;
        this.m_alertsexec = false;
        this.m_founddocs_label = '';
        this.m_founddocs_hint = '';
        this.m_page_selector = false;
        this.m_current_page = '';

        this.m_sortorder1 = false;
        this.m_sortorder2 = false;
        this.m_fldoptions = false;
        this.m_filterbtn = false;
        this.m_sort_label = false;

        const self = this;
        this.m_onerrorevh = new EventAdp<_OnError>();
        this.m_onerrorevh.subscribe(() => { self.m_busy_text = ''; });

        this.m_fetchcplevh = (snd, lst) => { self.FetchCompleted(snd, lst); };
        this.m_onfetchfilterinfocompletedevh = (snd, inf) => { self.FetchFilterInfoCompleted(snd, inf); };

        this.m_fetchcplevh = (snd, lst) => { self.FetchCompleted(snd, lst); };

        this.m_onloaddocument = (snd, status, uobj) => { self.OnLoadDocument(snd, status, uobj); };
        this.m_onfetchattachments = (snd, docs, prns, invdocs) => { self.OnFetchAttachments(snd, docs, prns, invdocs); };
        this.m_onpublishdocument = (snd, status) => { self.OnPublishDocument(snd, status); };
        this.m_onremovedocument = (snd, status, oldid) => { self.OnRemoveDocument(snd, status, oldid); };

        this.m_onpublishdirectory = (snd, status) => { self.OnPublishDirectory(snd, status); };
        this.m_onremovedirectory = (snd, status) => { self.OnRemoveDirectory(snd, status); };

        this.m_tabs = new Array<TabSpec>();
        this.m_seltab = null;
        this.m_lastviewtab = null;
        this.m_attachments = new Array<AttachmentInfo>();
        this.m_dailycorrrefs = new Array<LocalDailyCorRefInfo>();
        this.ClearTabs();

        this.m_cmmodechgevh = (sender, oldstr) => { self.CommentModeChanged(sender, oldstr); };
        this.m_ctxtypechgevh = (sender, ctp) => { self.OnContentTypeChanged(sender, ctp); };
        this.m_pagechgevh = (sender, cpg) => { self.OnPageChanged(sender, cpg); };
        this.m_classchgevh = (sender, clsid) => { self.OnClassChanged(sender, clsid); };
        this.m_seldirevh= (sender, cmp_id, dir_id) => { self.OnSelectDirectory(sender, cmp_id, dir_id); };
        //this.m_lastdailycorrs = new Array<WFDailyCorrespondenceAdp>();

        this.m_publishdcorr = (snd, status) => {
            self.OnPublishDailyCorrespondence(snd, status);
        };

        this.m_datep = new DatePipe('en-US');
        this.m_toOCR = WFDocStatus.INOCR;
        this.m_import_dst = null;
        this.m_import_att = null;

        this.m_cut_page = null;
        this.m_dragdrop = null;
        this.m_dragdrop_popup_vis = false;
        this.m_dragdrop_popup_img = '';

        this.m_mousemovebnd = null;
        this.m_mouseupbnd = null;
        this.m_loginwnd = null;

        this.m_snackbartxt = "";
        this.m_addnewdoctooltip= "hintAddDocStandard";
    }

    //

    private InitSearchOptsLabels(): void {
        this.m_other.GetItem(0).desc = this.m_strdata_srv.getStr('cbvOtherWait');
        this.m_other.GetItem(1).desc = this.m_strdata_srv.getStr('cbvOtherApproved');
        this.m_other.GetItem(2).desc = this.m_strdata_srv.getStr('cbvOtherRejected');
        this.m_other.GetItem(3).desc = this.m_strdata_srv.getStr('strAddedAfter');
        this.m_other.GetItem(4).desc = this.m_strdata_srv.getStr('strAddedBefore');
        this.m_other.GetItem(5).desc = this.m_strdata_srv.getStr('strDocDateFrom');
        this.m_other.GetItem(6).desc = this.m_strdata_srv.getStr('strDocDateTo');
        this.m_other.GetItem(7).desc = this.m_strdata_srv.getStr('cbvOtherTrash');
        this.m_other.GetItem(8).desc = this.m_strdata_srv.getStr('cbvOtherCompanies');
        this.m_other.GetItem(9).desc = this.m_strdata_srv.getStr('cbvOtherApprovals');

        this.m_attribs.GetItem(0).desc = this.m_strdata_srv.getStr('strSerchInName');
        this.m_attribs.GetItem(1).desc = this.m_strdata_srv.getStr('strSearchInDescr');
        this.m_attribs.GetItem(2).desc = this.m_strdata_srv.getStr('strSearchInNo');
        this.m_attribs.GetItem(3).desc = this.m_strdata_srv.getStr('strSearchInKeywords');
        this.m_attribs.GetItem(4).desc = this.m_strdata_srv.getStr('strSearchInComments');
    }

    public ngAfterViewInit(): void {
        try {
            this.m_other.AddItem(this.m_strdata_srv.getStr('cbvOtherWait'), true, null);
            this.m_other.AddItem(this.m_strdata_srv.getStr('cbvOtherApproved'), true, null);
            this.m_other.AddItem(this.m_strdata_srv.getStr('cbvOtherRejected'), true, null);

            let dnow: Date = new Date();
            this.m_other.AddItem(this.m_strdata_srv.getStr('strAddedAfter'), false, dnow);

            dnow = new Date();
            dnow.setDate(dnow.getDate() - 7);
            this.m_other.AddItem(this.m_strdata_srv.getStr('strAddedBefore'), false, dnow);

            dnow = new Date();
            this.m_other.AddItem(this.m_strdata_srv.getStr('strDocDateFrom'), false, dnow);

            dnow = new Date();
            dnow.setMonth(dnow.getMonth() - 1);
            this.m_other.AddItem(this.m_strdata_srv.getStr('strDocDateTo'), false, dnow);

            //szukaj w koszu
            this.m_other.AddItem(this.m_strdata_srv.getStr('cbvOtherTrash'), false, null);
            //szukaj w katalogu firmy
            this.m_other.AddItem(this.m_strdata_srv.getStr('cbvOtherCompanies'), false, null);
            //szukja w zatwierdzeniach
            this.m_other.AddItem(this.m_strdata_srv.getStr('cbvOtherApprovals'), false, null);

            this.m_attribs.AddItem(this.m_strdata_srv.getStr('strSerchInName'), true, null);
            this.m_attribs.AddItem(this.m_strdata_srv.getStr('strSearchInDescr'), true, null);
            this.m_attribs.AddItem(this.m_strdata_srv.getStr('strSearchInNo'), true, null);
            this.m_attribs.AddItem(this.m_strdata_srv.getStr('strSearchInKeywords'), true, null);
            this.m_attribs.AddItem(this.m_strdata_srv.getStr('strSearchInComments'), true, null);

            const self = this;
            (<any>window).showDocument = function (sid: string, doc_id: number) {
                //console.log(' before sid:'+sid+' docid:'+doc_id);
                self.m_zone.run(() => {
                    self.LoginAndShowDocument(sid, doc_id);
                });
            };

            //

            if (!IWFObject.IsNullOrEmpty(window.location.search)) {
                let ustr = window.location.search.toUpperCase();
                let off = ustr.indexOf('SIG=');
                if (off >= 0) {
                    let sig = ustr.substr(off + 4).trim();
                    if (sig.length > 0) {
                        this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                        this.m_data_srv.getSignatureInfo(sig, this.m_onerrorevh, (sid, unm, sig) => {
                            self.m_busy_text = '';
                            if (IWFObject.IsNullOrEmpty(sid))
                                self.CheckLogin(sig.DocumentID);
                            else
                                self.LoadSession(sid, FIND_INIT, sig.DocumentID);
                        });

                        return;
                    }
                }

                off = ustr.indexOf('DOCID=');
                if (off >= 0) {
                    let docid = ustr.substr(off + 6).trim();
                    if (docid.length > 0) {
                        let did= IWFObject.ParseInt(docid);
                        self.CheckLogin(did);
                        return;
                    }
                }
            }

            this.CheckLogin(0);
            //this.ShowTestWindow();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public get BeforeOcr(): DocumentList {
        return this.m_befocr_documents;
    } 

    public get InOcr(): DocumentList {
        return this.m_inocr_documents;
    }

    public get AfterOcr(): DocumentList {
        return this.m_aftocr_documents;
    }

    private CheckLogin(doc_id2: number): void {
        let sesskey = this.m_cookie_srv.get('sesskey');
        if (IWFObject.IsNullOrEmpty(sesskey)) {
            this.ShowLoginWnd(FIND_INIT, doc_id2);
        } else {
            this.LoadSession(sesskey, FIND_INIT, doc_id2);
        }
    }

    private LoadSession(sesskey: string, doc_id: number, doc_id2: number): void {
        const self = this;

        let erradp = new EventAdp<_OnError>();
        erradp.subscribe(() => {
            self.m_busy_text = '';
            self.ShowLoginWnd(doc_id, doc_id2);
        });

        this.m_busy_text = this.m_strdata_srv.getStr('strWaitSession');
        this.m_cache_srv.LoadAll(sesskey,
            (msgid: string) => {
                self.m_busy_text = self.m_strdata_srv.getStr(msgid);
            },
            erradp,
            () => {
                self.m_busy_text = '';
                self.SessionReloaded(doc_id, doc_id2);
            });
    }

    private ShowLoginWnd(doc_id: number, doc_id2: number): void {
        const self = this;
        this.m_loginwnd = this.m_wnd_srv.showControl(LoginWndComponent);
        this.m_loginwnd.instance.SessionReloaded.subscribe(() => {
            self.m_loginwnd.destroy();
            self.m_loginwnd = null;
            self.SessionReloaded(doc_id, doc_id2);
        });
    }

    public LoginAndShowDocument(sid: string, doc_id: number): void {
        try {
            if (this.m_loginwnd != null) {
                this.m_loginwnd.destroy();
                this.m_loginwnd = null;
                this.LoadSession(sid, doc_id, 0);
            } else {
              if (doc_id > 0)
                this.ShowDocument(doc_id);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    /*private ShowTestWindow(): void {
        const self = this;
        const wnd = this.m_wnd_srv.showControl(DocumentPrintComponent);
        wnd.instance.onClosed.subscribe(() => {
            wnd.destroy();
        });
    }*/

    private SessionReloaded(doc_id: number, doc_id2: number): void {
        try {
            this.ReloadSession();
            this.ActualizeDirectoryTree(doc_id, doc_id2);

            let dta: Date = new Date();
            dta.setDate(dta.getDate() + 1);

            /*let opts: CookieOptionsArgs = {
                expires: dta
            };

            this.m_cookie_srv.put('sesskey', this.m_cache_srv.SessionID, opts);*/
            this.m_cookie_srv.set('sesskey', this.m_cache_srv.SessionID, dta);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private ReloadSession(): void {
        let ii: number;

        this.m_lastseldirectory = null;
        //this.m_lastselattachment = null;
        this.m_selecteditem = null;
        this.m_selecteddir = null;

        //menu glowne
        this.m_inputdirselector.splice(0, this.m_inputdirselector.length);

        if((this.m_cache_srv.Config.OcrTaskMode== WFOcrTaskMode.EX_WEBSERVICE && !IWFObject.IsNullOrEmpty(this.m_cache_srv.Config.CentralService)) ||
            (this.m_cache_srv.Config.OcrTaskMode== WFOcrTaskMode.EX_DISK)) {
            this.m_inputdirselector.push(this.m_befocr);
            this.m_inputdirselector.push(this.m_inocr);
            this.m_addnewdoctooltip= "hintAddDocStandard";
        } else {
            this.m_addnewdoctooltip= "hintAddDocWithoutOCR";
        }

        this.m_inputdirselector.push(this.m_afterocr);
        this.m_inputdirselector.push(this.m_templates);
        
        if(!IWFObject.IsNullOrEmpty(this.m_cache_srv.User.IMAP_Address))
            this.m_inputdirselector.push(this.m_imap);

        if(this.m_cache_srv.User.DocumentInterfaceConfigs.length > 0)
            this.m_inputdirselector.push(this.m_edoc);

        this.m_companiesselector.splice(0, this.m_companiesselector.length);

        this.m_fileselector.splice(0, this.m_fileselector.length);
        this.m_all_directories.clear();

        //wypelnij klasy

        this.m_classes.ClearItems();
        this.m_classes.ClearItemsList();
        this.m_classes.AddValueName = this.m_strdata_srv.getStr('cmbClassesNone');

        // schematy

        this.m_schemas.ClearItems();
        this.m_schemas.ClearItemsList();
        this.m_schemas.AddValueName = this.m_strdata_srv.getStr('cmbSchemasNone');
        this.m_schemas.AddItemToList(WFDocument.SCHM_CUSTOM.toString(), this.m_strdata_srv.getStr('cmbSchemaManual'));

        let shmids: Array<number> = new Array<number>(this.m_cache_srv.AuthSchemas.size);
        let shmids_c = 0;

        if (this.m_cache_srv.User.DocClassPermissionType !== PrmType.TYPE_DENYFROMALL) {
            for (ii = 0; ii < this.m_cache_srv.Classes.size; ii++) {
                let cls: WFClass = IWFObject.ElementAt(this.m_cache_srv.Classes.values(), ii);
                if (this.m_cache_srv.User.DocClassPermissionType === PrmType.TYPE_ALLOWFROM) {
                    if (this.m_cache_srv.User.DocClassPermissions.indexOf(cls.ID) < 0) continue;
                }
                this.m_classes.AddItemToList(cls.ID.toString(), IWFObject.Format('{0} - {1}', cls.Name, cls.Description));

                for (let ii2 = 0; ii2 < cls.AllowedSchemas.length; ii2++) {
                    let shid = cls.AllowedSchemas[ii2];
                    if (shmids.indexOf(shid, 0) < 0) {
                        let shm: WFAuthSchema = this.m_cache_srv.AuthSchemas.get(shid);
                        this.m_schemas.AddItemToList(shm.ID.toString(), IWFObject.Format('{0} - {1}', shm.Name, shm.Description));
                        shmids[shmids_c++] = shid;
                    }
                }
            }
        }

        //firmy

        this.m_companies.ClearItems();
        this.m_companies.ClearItemsList();
        this.m_companies.AddValueName = '';

        this.m_companiesselector.splice(0, this.m_companiesselector.length);
        this.m_all_companies.clear();
        this.m_selectedcmp = null;

        if (this.m_cache_srv.User.CompanyPermissionType !== PrmType.TYPE_DENYFROMALL) {
            for (ii = 0; ii < this.m_cache_srv.Companies.size; ii++) {
                let cmp: WFCompany = IWFObject.ElementAt(this.m_cache_srv.Companies.values(), ii);

                this.m_companies.AddItemToList(cmp.ID.toString(), cmp.Company);

                let tvi = new TreeViewItem();
                tvi.Header = cmp.Company.toUpperCase();
                tvi.UserObj = new WFCompanyAdp(cmp);
                tvi.Icon = 'cmn_company.svg';

                this.m_companiesselector.push(tvi);
                this.m_all_companies.set(cmp.ID, tvi);
            }
        }

        // userow

        this.m_owners.ClearItems();
        this.m_owners.ClearItemsList();
        this.m_owners.AddValueName = this.m_cache_srv.User.Name;
        this.m_owners.AddItemToList(this.m_cache_srv.User.ID.toString(), IWFObject.Format('{0} - {1}', this.m_cache_srv.User.Name, this.m_cache_srv.User.UserName));

        let ausr: WFUser = this.m_cache_srv.User;
        switch (ausr.UserPermissionType) {
            case PrmType.TYPE_ALLOWFROMALL:
                for (ii = 0; ii < this.m_cache_srv.Users.size; ii++) {
                    let usr: WFUser = IWFObject.ElementAt(this.m_cache_srv.Users.values(), ii);
                    this.m_owners.AddItemToList(usr.ID.toString(), IWFObject.Format('{0} - {1}', usr.Name, usr.UserName));
                }
                break;

            case PrmType.TYPE_ALLOWFROM:
                for (ii = 0; ii < this.m_cache_srv.Users.size; ii++) {
                    let usr: WFUser = IWFObject.ElementAt(this.m_cache_srv.Users.values(), ii);
                    if (ausr.UserPermissions.indexOf(usr.ID) >= 0) {
                        this.m_owners.AddItemToList(usr.ID.toString(), IWFObject.Format('{0} - {1}', usr.Name, usr.UserName));
                    }
                }
                break;
        }

        for (let grp of Array.from(this.m_cache_srv.UserGroups)) {
            this.m_owners.AddItemToList(IWFObject.Format('_{0}', grp[1].ID), IWFObject.Format('[{0} - {1}]', grp[1].Name.toUpperCase(), grp[1].Description.trim()));
        }

        this.m_username = this.m_cache_srv.User.Name;

        this.m_found_documents.Clear();
        this.m_removed_documents.Clear();
        this.m_observed_documents.Clear();
        this.m_favorite_documents.Clear();

        for (ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
            let fdoc: FavoriteDocument = this.m_cache_srv.User.FavoriteDocuments[ii];
            switch (fdoc.Type) {
                case FavoriteType.TYPE_OBSERVED:
                    this.m_observed_documents.Add(fdoc.DocumentID);
                    break;

                case FavoriteType.TYPE_FAVORITE:
                    this.m_favorite_documents.Add(fdoc.DocumentID);
                    break;
            }
        }

        this.RefreshObservedInfo();

        this.m_iscreator = this.m_cache_srv.User.IsCreator;
        this.m_selected_tab = TAB_COMAPNIESTAB;


        this.ClearAllSelections();
        this.m_keywords = '';

        //DM 05042018
        this.m_dailycorrs.Items.splice(0, this.m_dailycorrs.Items.length);

        for (let dc of Array.from(this.m_cache_srv.DailyCorrepondenceClasses.values())) {
            let tvi = new TreeViewItem();
            tvi.Header = (IWFObject.IsNullOrEmpty(dc.ReportLabel)) ? dc.Name : dc.ReportLabel;
            tvi.UserObj = dc;
            tvi.Hint = dc.Description;
            tvi.Icon = 'cmn_dailycorr.svg';
            this.m_dailycorrs.Items.push(tvi);
        }

        this.m_reports.Items.splice(0, this.m_reports.Items.length);

        for (let rp of Array.from(this.m_cache_srv.Dictionaries.values())) {
            if (rp.Report && rp.ReportVisible) {
                let tvi = new TreeViewItem();
                tvi.Header = (IWFObject.IsNullOrEmpty(rp.ReportLabel)) ? rp.Name : rp.ReportLabel;
                tvi.UserObj = rp;
                tvi.Hint = rp.Description;
                tvi.Icon = 'cmn_reports.svg';
                this.m_reports.Items.push(tvi);
            }
        }

        this.m_cut_page = null;

        this.m_strdata_srv.setCurLang(this.m_cache_srv.User.LanguageStr);

        this.m_imap.Items.splice(0, this.m_imap.Items.length);
        let mthflds;

        if(IWFObject.IsNullOrEmpty(this.m_cache_srv.User.IMAP_VisibleFolders))
        {
            mthflds= this.m_cache_srv.UserMailFolders;
        } 
        else 
        {
            mthflds= new Array<WFMailFolderAdp>();
            
            let varr= this.m_cache_srv.User.IMAP_VisibleFolders.split(',');
            varr= varr.map((t)=> t.trim().toUpperCase());    

            for(let mfld of this.m_cache_srv.UserMailFolders) {
                let fnm= (IWFObject.IsNullOrEmpty(mfld.Name)) ? varr[0] : mfld.Name.toUpperCase();
                if(varr.indexOf(fnm)>= 0)
                    mthflds.push(mfld); 
            }
        }

        if(mthflds.length == 1) {
            this.m_imap.UserObj= mthflds[0];
        } else {
            this.m_imap.UserObj= null;

            for(let mfld of mthflds) {
                let tvi = new TreeViewItem();
                tvi.Header = (IWFObject.IsNullOrEmpty(mfld.Name)) ? this.m_strdata_srv.getStr('strIMAP_Inbox') : mfld.Name;
                tvi.UserObj = mfld;
                tvi.Icon = 'cmn_dailycorr.svg';
                this.m_imap.Items.push(tvi);
            }  
        }

        this.m_edoc.Items.splice(0, this.m_edoc.Items.length);

        if(this.m_cache_srv.DocumentInterfaceConfigs.length == 1) {
            this.m_edoc.UserObj= this.m_cache_srv.DocumentInterfaceConfigs[0];
        } else {
            this.m_edoc.UserObj= null;

            for(let adp of this.m_cache_srv.DocumentInterfaceConfigs) {
                let tvi = new TreeViewItem();            
                tvi.Header = adp.Name;
                tvi.UserObj = adp;
                tvi.Icon = 'cmn_dailycorr.svg';
                this.m_edoc.Items.push(tvi);
            }  
        }


        this.InitLabels();
        this.InitSearchOptsLabels();
    }

    //

    public ShowAccountOptions(): void {
        try {
            const self = this;
            const wnd = this.m_wnd_srv.showControl(UserPanelComponent);
            wnd.instance.onClosed.subscribe((ret) => {
                if (ret) {
                    self.m_strdata_srv.setCurLang(self.m_cache_srv.User.LanguageStr);
                    self.InitLabels();
                    self.InitSearchOptsLabels();
                }

                if(wnd.instance.RefreshAfterClose) 
                    self.ManualReloadUserAlerts();

                wnd.destroy();                
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private Logout2(): void {
        this.ClearTabs();
        //this.m_cookie_srv.remove('sesskey');
        this.m_cookie_srv.delete('sesskey');
        this.ShowLoginWnd(FIND_INIT, 0);
        this.m_username = '';
    }

    /*private testEval(unm: string): Object {
        let prms = [ 'UNAME', 'return UNAME' ];
        let fnc = new Function(...prms);
        let args = [ unm ];
        return fnc(...args);
    }*/

    public Logout(): void {
        try {
            //testy eval

            //let ret = this.testEval('TEST');
            //return;

            if (this.GetIsModified()) {
                const self = this;
                this.m_global_srv.showMsg(ALR_ICONTYPE.WARNING, BUTTONSTYPE.YESNOCANCEL, this.m_strdata_srv.getStr('strUnsavedDateInfo'), (ret) => {
                    if (ret !== WNDRESULT.CANCEL) {
                        try {
                            if (ret === WNDRESULT.OKYES) {
                                self.PublishAll();
                            } else {
                                self.Logout2();
                            }
                        } catch (ex) {
                            self.m_global_srv.manageException(ex);
                        }
                    }
                });
                return;
            }

            this.Logout2();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private RefreshObservedInfo(): void {
        this.m_observed.Header = (this.m_observed_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldObserved').toUpperCase() + ' [{0}]', this.m_observed_documents.Count) : this.m_strdata_srv.getStr('fldObserved').toUpperCase();
        this.m_favorite.Header = (this.m_favorite_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldFavourites').toUpperCase() + ' [{0}]', this.m_favorite_documents.Count) : this.m_strdata_srv.getStr('fldFavourites').toUpperCase();
        //m_observed.Visibility = (m_observed_documents.Count > 0) ? System.Windows.Visibility.Visible : System.Windows.Visibility.Collapsed;
    }

    private ClearAllSelections(): void {
        //czysc zanzcenia TODO
    }

    private ClearTabs(): void {
        this.ClearRightBar();
        this.m_lastviewtab = null;
        this.m_seltab = null;
        this.m_tabs.splice(0, this.m_tabs.length);

        /*m_mdoc_preview.TopBarVisibility = false;
        m_mdoc_preview.Document = null;
        m_mdoc_preview.Visibility = Visibility.Collapsed;
        m_backattachment = null;
        m_mrep_preview.Report = null;
        m_mrep_preview.Visibility = Visibility.Collapsed;*/

        //m_mtab_label.Text = "";
        //m_tab.Height = 0;
    }

    private ActualizeDirectoryTree(doc_id: number, doc_id2: number): void {
        this.m_busy_text = this.m_strdata_srv.getStr('strDirectoryListUpdateInfo');
        const self = this;
        this.m_data_srv.getDirectoriesList(this.m_cache_srv.SessionID, this.m_onerrorevh,
            (arr) => {
                self.GetDirectoriesListCompleted(arr, doc_id, doc_id2);
            });
    }

    private GetDirectoriesListCompleted(dvec: Array<WFDirectory>, doc_id: number, doc_id2: number): void {
        let ii: number, ii2: number;
        let tvi: TreeViewItem, tvi2: TreeViewItem;

        try {
            this.m_all_directories.clear();
            this.m_selecteddir = null;

            //czyszczenie listy wstepnej
            for (ii = 0; ii < this.m_all_companies.size; ii++) {
                tvi = IWFObject.ElementAt(this.m_all_companies.values(), ii);
                tvi.Items.splice(0, tvi.Items.length);
                let cmpadp = <WFCompanyAdp>tvi.UserObj;
                cmpadp.Documents.Clear();
                cmpadp.TotalDocs = 0;
            }

            dvec.sort((A, B) => A.Name.localeCompare(B.Name));

            let topop: Array<WFDirectory> = new Array<WFDirectory>();
            this.m_cache_srv.Directories.clear();

            for (ii = 0; ii < dvec.length; ii++) {
                let dir: WFDirectory = dvec[ii];

                if (dir.DirectoryID === 0) {
                    //przypisanie do firmy
                    tvi2 = this.m_all_companies.get(dir.CompanyID);

                    let adp = new WFDirectoryAdp(dir, this.m_cache_srv);
                    adp.OnError.subscribe2(this.m_onerrorevh);
                    //adp.OnDropDirectory += m_ondropdirectory;
                    adp.OnPublishDirectory = this.m_onpublishdirectory;
                    adp.OnRemoveDirectory = this.m_onremovedirectory;

                    tvi = new TreeViewItem();
                    tvi.Header = dir.Name.toUpperCase();
                    tvi.UserObj = adp;

                    //tvi.Icon = 'cmn_folder.svg';
                    //tvi.MouseRightButtonDown += m_evdirsubmenu;

                    this.m_all_directories.set(dir.ID, tvi);
                    tvi2.Items.push(tvi);

                    this.m_cache_srv.Directories.set(adp.ID, adp);
                } else {
                    topop.push(dir);
                }
            }

            //rekursywnie podczep katalogi
            for (ii = 0; ii < this.m_all_companies.size; ii++) {
                tvi = IWFObject.ElementAt(this.m_all_companies.values(), ii);
                for (ii2 = 0; ii2 < tvi.Items.length; ii2++) {
                    this.BindDirectories(topop, tvi.Items[ii2]);
                }
            }

            IWFObject.ElementAt(this.m_all_companies.values(), 0).Expanded = true;
            this.m_busy_text = '';

            if (doc_id > 0) {
                this.ShowDocument(doc_id);
            } else {
                let flgs = 0;
                let prms: FindDocumentPrms;
                const self = this;

                switch (doc_id) {
                    case FIND_WITHPRMS:
                        this.SendFindDocuments();
                        break;

                    case FIND_INIT:
                        flgs |= FDOC_INCLUDE_COMPANIES_ID;
                        flgs |= FDOC_INCLUDE_DIRECTORIES_ID;
                        flgs |= FDOC_INCLUDE_USERS_ID;
                        flgs |= FDOC_INCLUDE_DOCSTATUS;
                        flgs |= FDOC_INCLUDE_APPSTATUS;

                        flgs |= FDOC_DOCSTATUS_BEFOREOCR;
                        flgs |= FDOC_DOCSTATUS_INOCR;
                        flgs |= FDOC_DOCSTATUS_AFTEROCR;
                        flgs |= FDOC_DOCSTATUS_TEMPLATE;
                        flgs |= FDOC_DOCSTATUS_INSYSTEM;

                        flgs |= FDOC_INCLUDETRASH;

                        prms = {
                            sid: this.m_cache_srv.SessionID,
                            companies: [],
                            users: [],
                            classes: [],
                            schemas: [],
                            directories: [],
                            documents: [],
                            fargs: [],
                            createdatfrom: null,
                            createdatto: null,
                            docdatefrom: null,
                            docdateto: null,
                            flags: flgs,
                            refobj: [],
                            attvalues: []
                        };

                        this.m_data_srv.findDocuments(prms, this.m_onerrorevh, (rows) => {
                            self.FindDocumentsCompleted1(rows, doc_id2);
                        });
                        break;

                    case FIND_INSYSTEM:
                        flgs |= FDOC_INCLUDE_COMPANIES_ID;
                        flgs |= FDOC_INCLUDE_DIRECTORIES_ID;
                        flgs |= FDOC_DOCSTATUS_INSYSTEM;

                        prms = {
                            sid: this.m_cache_srv.SessionID,
                            companies: [],
                            users: [],
                            classes: [],
                            schemas: [],
                            directories: [],
                            documents: [],
                            fargs: [],
                            createdatfrom: null,
                            createdatto: null,
                            docdatefrom: null,
                            docdateto: null,
                            flags: flgs,
                            refobj: [],
                            attvalues: []
                        };

                        this.m_data_srv.findDocuments(prms, this.m_onerrorevh, (rows) => {
                            self.FindDocumentsCompleted4(rows);
                        });
                        break;
                }

                this.m_busy_text = this.m_strdata_srv.getStr('strFetchDocumentsInfo');
            }


        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private BindDirectories(topop: Array<WFDirectory>, tvi: TreeViewItem): void {
        let id = (<WFDirectoryAdp>tvi.UserObj).ID;

        for (let ii = 0; ii < topop.length; ii++) {
            let dir: WFDirectory = topop[ii];
            if (dir.DirectoryID === id) {
                let adp = new WFDirectoryAdp(dir, this.m_cache_srv);
                //adp.OnDropDirectory += m_ondropdirectory;
                adp.OnError.subscribe2(this.m_onerrorevh);
                adp.OnPublishDirectory = this.m_onpublishdirectory;
                adp.OnRemoveDirectory = this.m_onremovedirectory;

                let tvi2: TreeViewItem = new TreeViewItem();
                tvi2.Header = dir.Name.toUpperCase();
                tvi2.UserObj = adp;
                //tvi2.Icon = 'cmn_folder.svg';
                //tvi2.MouseRightButtonDown += m_evdirsubmenu;

                this.m_all_directories.set(dir.ID, tvi2);
                tvi.Items.push(tvi2);
                this.BindDirectories(topop, tvi2);

                this.m_cache_srv.Directories.set(adp.ID, adp);
            }
        }
    }

    private ShowDocument(docid: number): void {
        //this.HideLeftPanel();

        if (this.m_cache_srv.ContainsDocument(docid)) {
            this.OnSelectDocument(this.m_cache_srv.GetDocument(docid), false);
        } else {
            const self = this;
            this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
            this.m_data_srv.getDocuments(this.m_cache_srv.SessionID, [docid], true, this.m_onerrorevh, (docs, ronly) => {
                self.GetDocumentsCompleted(docs, ronly);
            });
        }
    }

    private GetDocumentsCompleted(docs: Array<WFDocument>, ronly: Array<number>): void {
        try {
            if (docs.length > 0) {
                let doc: WFDocument = docs[0];
                let adp: WFDocumentAdp = new WFDocumentAdp(this.m_cache_srv, doc, true, ronly.includes(doc.ID));
                this.m_cache_srv.AddDocument(adp);
                this.OnSelectDocument(adp, false);
            } else {
                this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wDocViewer_noDocument'));
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private SendFindDocuments(): void {
        let ii: number;

        let classlist = new Array<number>();
        switch (this.m_classes.GetSelectedOptionId()) {
            case 1:
                for (ii = 0; ii < this.m_classes.GetItemsCount(); ii++) {
                    let cbx: ListItem = this.m_classes.GetItem(ii);
                    let clsid = IWFObject.ParseInt(cbx.val);
                    if (classlist.indexOf(clsid) < 0) {
                        classlist.push(clsid);
                    }
                }
                break;
            case 2:
                classlist.push(0);
                break;
        }


        let complist = new Array<number>();
        switch (this.m_companies.GetSelectedOptionId()) {
            case 1:
                for (ii = 0; ii < this.m_companies.GetItemsCount(); ii++) {
                    let cbx: ListItem = this.m_companies.GetItem(ii);
                    let compid = IWFObject.ParseInt(cbx.val);
                    if (complist.indexOf(compid) < 0) {
                        complist.push(compid);
                    }
                }
                break;
        }

        let schemaslist = new Array<number>();
        switch (this.m_schemas.GetSelectedOptionId()) {
            case 1:
                for (ii = 0; ii < this.m_schemas.GetItemsCount(); ii++) {
                    let cbx: ListItem = this.m_schemas.GetItem(ii);
                    let schemaid = IWFObject.ParseInt(cbx.val);
                    if (schemaslist.indexOf(schemaid) < 0) {
                        schemaslist.push(schemaid);
                    }
                }
                break;
            case 2:
                schemaslist.push(WFDocument.SCHM_NOAUTH);
                break;
        }

        let usrids = new Array<number>(0);

        switch (this.m_owners.GetSelectedOptionId()) {
            case 1:
                let usrlist = new Array<number>();
                for (ii = 0; ii < this.m_owners.GetItemsCount(); ii++) {
                    let cbx: ListItem = this.m_owners.GetItem(ii);
                    let id = cbx.val;
                    if (id[0] === '_') {
                        // grupa
                        let grpid = IWFObject.ParseInt(id.substr(1));
                        if (this.m_cache_srv.UserGroups.has(grpid)) {
                            for (let usrid of this.m_cache_srv.UserGroups.get(grpid).Users) {
                                if (usrlist.indexOf(usrid) < 0) {
                                    usrlist.push(usrid);
                                }
                            }

                        }
                    } else {
                        //uzytkownik
                        let usrid = IWFObject.ParseInt(id);
                        if (usrlist.indexOf(usrid) < 0) {
                            usrlist.push(usrid);
                        }
                    }
                }
                usrids = usrlist;
                break;
            case 2:
                usrids = new Array<number>(1);
                usrids[0] = this.m_cache_srv.User.ID;
                break;
        }

        let keywords = this.m_keywords.trim();
        let fargs = new Array<string>();
        if (keywords.length > 0) {
            fargs.push(keywords);
            for (ii = 0; ii < this.m_attribs.GetItemsCount(); ii++) {
                fargs.push((this.m_attribs.GetItem(ii).checked) ? 'Y' : 'N');
            }
        }

        let flgs = 0;
        if (this.m_other.GetItem(0).checked) flgs |= FDOC_AUTHSTATUS_WAITING;
        if (this.m_other.GetItem(1).checked) flgs |= FDOC_AUTHSTATUS_APPROVED;
        if (this.m_other.GetItem(2).checked) flgs |= FDOC_AUTHSTATUS_DISAPPROVED;
        if (this.m_other.GetItem(7).checked) flgs |= FDOC_INCLUDETRASH;
        if (this.m_other.GetItem(9).checked) flgs |= FDOC_INCLUDE_APPSTATUS; //status zatwierdzenia

        flgs |= FDOC_INCLUDE_COMPANIES_ID; //company id
        flgs |= FDOC_INCLUDE_DIRECTORIES_ID; //directory id
        flgs |= FDOC_DOCSTATUS_INSYSTEM; //tylko dodane

        let prms: FindDocumentPrms = {
            sid: this.m_cache_srv.SessionID,
            companies: complist,
            users: usrids,
            classes: classlist,
            schemas: schemaslist,
            directories: [],
            documents: [],
            fargs: fargs,
            createdatfrom: (this.m_other.GetItem(3).checked) ? this.m_other.GetItem(3).date : null,
            createdatto: (this.m_other.GetItem(4).checked) ? this.m_other.GetItem(4).date : null,
            docdatefrom: (this.m_other.GetItem(5).checked) ? this.m_other.GetItem(5).date : null,
            docdateto: (this.m_other.GetItem(6).checked) ? this.m_other.GetItem(6).date : null,
            flags: flgs,
            refobj: [],
            attvalues: []
        };

        const self = this;
        this.m_data_srv.findDocuments(prms, this.m_onerrorevh, (rows: Array<any>) => {
            self.FindDocumentsCompleted(rows);
        });
    }

    private FindDocumentsCompleted(rows: Array<any>): void {
        let ii: number;
        try {
            //czy kasowac katalog zatwierdzen i nadpisywac wynik wyszukiwania
            let intrash = this.m_other.GetItem(7).checked;
            let incmps = this.m_other.GetItem(8).checked;
            let inaprov = this.m_other.GetItem(9).checked;

            //DM 14092022
            //this.ClearActualView(false);

            this.m_found_documents.Clear();
            if (intrash) this.m_removed_documents.Clear();

            if (inaprov) {
                this.m_toapproval_documents.Clear();
                this.m_approvedbyme_documents.Clear();
                this.m_approvedbyother_documents.Clear();
                this.m_rejectedbyme_documents.Clear();
                this.m_rejectedbyother_documents.Clear();
            }

            this.ClearDocumentsCache();

            for (ii = 0; ii < rows.length; ii++) {
                let rw = rows[ii];
                let id: number = rw.C0;
                let cmp_id: number = rw.C1;
                let dir_id: number = rw.C2;

                if (incmps) {
                    let cadp: WFCompanyAdp = <WFCompanyAdp>this.m_all_companies.get(cmp_id).UserObj;

                    if (dir_id > 0) {
                        let adp: WFDirectoryAdp = <WFDirectoryAdp>this.m_all_directories.get(dir_id).UserObj;
                        adp.Documents.Add(id);
                        while (adp.DirectoryID > 0) {
                            adp = <WFDirectoryAdp>this.m_all_directories.get(adp.DirectoryID).UserObj;
                        }
                    } else {
                        if (dir_id === 0) {
                            cadp.Documents.Add(id);
                        }
                    }
                }

                if (dir_id < 0 && intrash) this.m_removed_documents.Add(id);
                this.m_found_documents.Add(id);

                if (inaprov) {
                    let astatus: number = rw.C3;
                    switch (astatus) {
                        case APPSTS_TOAPPROVE:
                            this.m_toapproval_documents.Add(id);
                            break;
                        case APPSTS_APPBYME:
                            this.m_approvedbyme_documents.Add(id);
                            break;
                        case APPSTS_APPBYOTHER:
                            this.m_approvedbyother_documents.Add(id);
                            break;
                        case APPSTS_REJBYME:
                            this.m_rejectedbyme_documents.Add(id);
                            break;
                        case APPSTS_REJBYOTHER:
                            this.m_rejectedbyother_documents.Add(id);
                            break;
                    }
                }
            }

            this.RefreshDirectoryTotals();

            //DM 14092022
            if(this.m_fileselector_ctx== this.m_found_documents) {
                this.FillBreadCrumb(this.m_found_documents);
                this.FillFileSelector();
            } else {
                if((intrash && this.m_fileselector_ctx==this.m_removed_documents) ||
                    (inaprov && (this.m_fileselector_ctx==this.m_toapproval_documents ||
                           this.m_fileselector_ctx==this.m_approvedbyme_documents ||
                           this.m_fileselector_ctx==this.m_approvedbyother_documents ||
                           this.m_fileselector_ctx==this.m_rejectedbyme_documents ||
                           this.m_fileselector_ctx==this.m_rejectedbyother_documents)) ||
                    (incmps))
                this.FillFileSelector();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    private FindDocumentsCompleted1(rows: Array<any>, doc_id2:number): void {
        let ii: number;
        try {
            //katalogi firmy
            this.ClearActualView(true);

            this.m_found_documents.Clear();
            this.m_removed_documents.Clear();

            //katalogi sekretariatu
            this.m_befocr_documents.Clear();
            this.m_inocr_documents.Clear();
            this.m_aftocr_documents.Clear();
            this.m_temp_documents.Clear();

            //katalogi zatwierdzen
            this.m_toapproval_documents.Clear();
            this.m_approvedbyme_documents.Clear();
            this.m_approvedbyother_documents.Clear();
            this.m_rejectedbyme_documents.Clear();
            this.m_rejectedbyother_documents.Clear();

            this.m_recent_documents.Clear();
            this.m_recent.Header = this.m_strdata_srv.getStr('strRecents').toUpperCase();

            this.ClearDocumentsCache();

            for (ii = 0; ii < rows.length; ii++) {
                let obj = rows[ii];
                let id: number = obj.C0;
                let cmp_id: number = obj.C1;
                let dir_id: number = obj.C2;
                let usr_id: number = obj.C3;
                let dstatus: WFDocStatus = <WFDocStatus>obj.C4;
                let astatus: number = obj.C5;

                if (dir_id < 0) {
                    this.m_removed_documents.Add(id);
                } else {
                    if (dstatus !== WFDocStatus.INSYSTEM) {
                        if (usr_id === this.m_cache_srv.User.ID) {
                            switch (dstatus) {
                                case WFDocStatus.BEFOREOCR:
                                    this.m_befocr_documents.Add(id);
                                    break;

                                case WFDocStatus.INOCR:
                                    this.m_inocr_documents.Add(id);
                                    break;

                                case WFDocStatus.AFTEROCR:
                                    this.m_aftocr_documents.Add(id);
                                    break;

                                case WFDocStatus.TEMPLATE:
                                    this.m_temp_documents.Add(id);
                                    break;
                            }
                        }
                    } else {
                        let cadp: WFCompanyAdp = <WFCompanyAdp>this.m_all_companies.get(cmp_id).UserObj;

                        if (dir_id > 0) {
                            let adp: WFDirectoryAdp = <WFDirectoryAdp>this.m_all_directories.get(dir_id).UserObj;
                            adp.Documents.Add(id);
                            while (adp.DirectoryID > 0) {
                                adp = <WFDirectoryAdp>this.m_all_directories.get(adp.DirectoryID).UserObj;
                            }
                        } else {
                            cadp.Documents.Add(id);
                        }

                        switch (astatus) {
                            case APPSTS_TOAPPROVE:
                                this.m_toapproval_documents.Add(id);
                                break;
                            case APPSTS_APPBYME:
                                this.m_approvedbyme_documents.Add(id);
                                break;
                            case APPSTS_APPBYOTHER:
                                this.m_approvedbyother_documents.Add(id);
                                break;
                            case APPSTS_REJBYME:
                                this.m_rejectedbyme_documents.Add(id);
                                break;
                            case APPSTS_REJBYOTHER:
                                this.m_rejectedbyother_documents.Add(id);
                                break;
                        }
                    }
                }
            }

            this.ReloadUserAlerts(true, doc_id2);
            return;

        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    private ClearActualView(cleartabs: boolean): void {
        if (cleartabs) this.ClearTabs();
        this.ClearAllSelections();
       
        this.m_fileselector.splice(0, this.m_fileselector.length);
        
        this.m_selecteditem = null;
        this.m_selecteditemmodstate = false;
        this.m_fileselector_src = null;
        this.m_fileselector_ctx = this.m_dumylist;
        this.m_page_selector = false;
        this.m_filterbtn = false;
    }

    private ClearDocumentsCache(): void {
        let ii: number;
        let lst = new Array<WFDocumentAdp>();

        for (let tab of this.m_tabs) {
            for (let obj of tab.history) {
                if (obj[0] instanceof WFDocumentAdp) {
                    let doc = <WFDocumentAdp>obj[0];
                    if (lst.indexOf(doc) < 0) lst.push(doc);
                }
            }

            if (tab.attachments != null) {
                for (let doc of tab.attachments.atts) {
                    if (lst.indexOf(doc) < 0) lst.push(doc);
                }
                for (let doc of tab.attachments.invdc) {
                    if (lst.indexOf(doc) < 0) lst.push(doc);
                }
                for (let doc of tab.attachments.prns) {
                    if (lst.indexOf(doc) < 0) lst.push(doc);
                }
            }
        }

        this.m_cache_srv.ClearDocuments(lst);
        this.m_cache_srv.MsgCache.clear();
    }

    private FindDocumentsCompleted2(rows: Array<any>): void {
        let ii: number;

        this.m_busy_text = '';
        try {
            this.ClearActualView(false);

            this.m_befocr_documents.Clear();
            this.m_inocr_documents.Clear();
            this.m_aftocr_documents.Clear();
            this.m_temp_documents.Clear();

            for (ii = 0; ii < rows.length; ii++) {
                let rw = rows[ii];
                let docid: number = rw.C0;
                let status: WFDocStatus = rw.C1;

                switch (status) {
                    case WFDocStatus.BEFOREOCR:
                        this.m_befocr_documents.Add(docid);
                        break;

                    case WFDocStatus.INOCR:
                        this.m_inocr_documents.Add(docid);
                        break;

                    case WFDocStatus.AFTEROCR:
                        this.m_aftocr_documents.Add(docid);
                        break;

                    case WFDocStatus.TEMPLATE:
                        this.m_temp_documents.Add(docid);
                        break;
                }
            }

            this.RefreshDirectoryTotals();
            if (this.m_selecteditem != null) {
                if (this.m_selecteditem.DocStatus === WFDocStatus.INOCR) {
                    this.m_selecteditem.OnLoadDocument = this.m_onloaddocument;

                    this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                    this.m_selecteditem.Reload(null);
                    return;
                }
            }

            /*try {
                TreeViewItem tvi = (TreeViewItem)m_inputdirselector.SelectedItem;
                tvi.IsSelected = false;
                m_inputdirselector.UpdateLayout();
                tvi.IsSelected = true;
            }
            catch (Exception) {
            }*/

            if(this.m_cache_srv.UserMailFolders.length > 0) {
                this.m_busy_text = this.m_strdata_srv.getStr('strRefIMAPInfo');
                const _self= this;
                this.m_cache_srv.RefreshMailFolders(this.m_onerrorevh, ()=> {
                    _self.m_busy_text= '';
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private FindDocumentsCompleted3(rows: Array<any>): void {
        let ii: number;

        try {
            this.ClearActualView(false);

            this.m_toapproval_documents.Clear();
            this.m_approvedbyme_documents.Clear();
            this.m_approvedbyother_documents.Clear();
            this.m_rejectedbyme_documents.Clear();
            this.m_rejectedbyother_documents.Clear();


            for (ii = 0; ii < rows.length; ii++) {
                let rw = rows[ii];
                let docid: number = rw.C0;
                let cmp_id: number = rw.C1;
                let dir_id: number = rw.C2;
                let astatus: number = rw.C3;

                switch (astatus) {
                    case APPSTS_TOAPPROVE:
                        this.m_toapproval_documents.Add(docid);
                        break;

                    case APPSTS_APPBYME:
                        this.m_approvedbyme_documents.Add(docid);
                        break;

                    case APPSTS_APPBYOTHER:
                        this.m_approvedbyother_documents.Add(docid);
                        break;

                    case APPSTS_REJBYME:
                        this.m_rejectedbyme_documents.Add(docid);
                        break;

                    case APPSTS_REJBYOTHER:
                        this.m_rejectedbyother_documents.Add(docid);
                        break;
                }
            }

            this.RefreshDirectoryTotals();
            /*try
            {
                TreeViewItem tvi = (TreeViewItem)m_aprovalsselector.SelectedItem;
                tvi.IsSelected = false;
                m_aprovalsselector.UpdateLayout();
                tvi.IsSelected = true;
            }
            catch (Exception)
            {
            }*/
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    private FindDocumentsCompleted4(rows: Array<any>): void {
        let ii: number;

        try {
            //katalogi firmy
            this.ClearActualView(false);
            this.ClearDocumentsCache();

            for (ii = 0; ii < rows.length; ii++) {
                let rw = rows[ii];
                let id: number = rw.C0;
                let cmp_id: number = rw.C1;
                let dir_id: number = rw.C2;

                let cadp: WFCompanyAdp = <WFCompanyAdp>this.m_all_companies.get(cmp_id).UserObj;

                if (dir_id > 0) {
                    let adp: WFDirectoryAdp = <WFDirectoryAdp>this.m_all_directories.get(dir_id).UserObj;
                    adp.Documents.Add(id);
                    while (adp.DirectoryID > 0) {
                        adp = <WFDirectoryAdp>this.m_all_directories.get(adp.DirectoryID).UserObj;
                    }
                } else {
                    cadp.Documents.Add(id);
                }
            }

            this.RefreshDirectoryTotals();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    private ReloadUserAlerts(gdcorr: boolean, doc_id2: number): void {
        if (!this.m_alertsexec) {
            this.m_alertsexec = true;
            this.m_busy_text = this.m_strdata_srv.getStr('strAlertsLoadInfo');
            const self = this;
            this.m_data_srv.getUserAlerts(this.m_cache_srv.SessionID, false, this.m_onerrorevh, (alerts) => {
                self.GetUserAlertsCompleted(alerts, false, gdcorr, doc_id2);
            });
        } /*else {
            if (gdcorr) this.GetLastDailyCorrespondences();
        }*/
    }

    private GetUserAlertsCompleted(alerts: Array<UserAlert>, auto: boolean, gdcorr: boolean, doc_id2: number): void {
        let ii: number, ii2: number, ii3: number;

        try {
            if (!auto) this.m_alertsselector.splice(0, this.m_alertsselector.length);
            let dtotal = 0;

            for (ii = 0; ii < alerts.length; ii++) {
                let vv = alerts[ii];
                let _group = vv.key;
                let groups = _group.split('\\');

                let sm = vv.sumparent;
                let alr = vv.alert;

                let dlen = vv.docs.length;

                //check/create destination path
                let ftvi: Array<TreeViewItem> = this.m_alertsselector;
                let ltvi: TreeViewItem = null;
                let grp: AlertsGroup;

                for (ii2 = 0; ii2 < groups.length; ii2++) {
                    let grpname: string = (groups[ii2].length > 0) ? groups[ii2].toUpperCase() : 'PUSTY';

                    let ntvi: TreeViewItem = null;
                    for (ii3 = 0; ii3 < ftvi.length; ii3++) {
                        let ctvi: TreeViewItem = ftvi[ii3];
                        grp = <AlertsGroup>ctvi.UserObj;
                        if (grp.GroupName.toUpperCase() === grpname.toUpperCase()) {
                            if (sm) grp.TotalDocs += dlen;
                            //if (!grp.Sum) sm = false;
                            if (alr) grp.IsAlert = true;
                            ntvi = ctvi;
                            break;
                        }
                    }

                    if (ntvi == null) {
                        ntvi = new TreeViewItem();
                        grp = new AlertsGroup(grpname, alr, sm);
                        if (sm) {
                            grp.TotalDocs = dlen;
                        } else {
                            if (ii2 === (groups.length - 1)) grp.TotalDocs = dlen;
                        }
                        ntvi.Header = grpname;
                        ntvi.Icon = 'cmn_alert.svg';
                        ntvi.UserObj = grp;
                        ftvi.push(ntvi);
                    }

                    ftvi = ntvi.Items;
                    ltvi = ntvi;
                }

                if (sm) dtotal += dlen;

                grp = <AlertsGroup>ltvi.UserObj;
                grp.Documents.Clear();
                for (ii2 = 0; ii2 < dlen; ii2++) {
                    let docid = vv.docs[ii2];
                    grp.Documents.Add(docid);
                }
            }

            if (auto) this.RefreshTotals(this.m_alertsselector);

            if (dtotal > 0) {
                this.m_alerts_label = IWFObject.Format(this.m_strdata_srv.getStr('fldAlerts').toUpperCase() + ' [{0}]', dtotal);
            } else {
                this.m_alerts_label = this.m_strdata_srv.getStr('fldAlerts').toUpperCase();
            }

            this.RefreshDirectoryTotals();

            if(doc_id2 > 0) 
                this.ShowDocument(doc_id2);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_alertsexec = false;
        this.m_busy_text = '';

        //if (gdcorr) this.GetLastDailyCorrespondences();
    }

    /*private GetLastDailyCorrespondences(): void {
        try {
            const self = this;
            this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
            this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [], this.m_onerrorevh, (dcs) => {
                self.GetLastDailyCorrespondencesCompleted(dcs);
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }*/

    /*private GetLastDailyCorrespondencesCompleted(dcs: Array<WFDailyCorrespondence>): void {
        try {
            this.m_lastdailycorrs.splice(0, this.m_lastdailycorrs.length);
            for (let dc of dcs) {
                this.m_lastdailycorrs.push(new WFDailyCorrespondenceAdp(this.m_cache_srv, dc));
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        this.m_busy_text = '';
    }*/

    private RefreshTotals(ftvi: Array<TreeViewItem>): number {
        let ii: number;
        let total = 0;

        for (ii = 0; ii < ftvi.length; ii++) {
            let gtotal = 0;
            let ctvi: TreeViewItem = ftvi[ii];
            if (ctvi.Items.length > 0) gtotal += this.RefreshTotals(ctvi.Items);

            let grp: AlertsGroup = <AlertsGroup>ctvi.UserObj;
            gtotal += grp.Documents.Count;

            grp.TotalDocs = (grp.IsSum) ? gtotal : 0;
            total += gtotal;
        }

        return total;
    }

    private GetDirectoryTotal(tvi: TreeViewItem): number {
        let adp: WFDirectoryAdp = <WFDirectoryAdp>tvi.UserObj;
        let total = adp.Documents.Count;
        if (tvi.Items.length > 0) {
            adp.isChild = false;
        } else {
            adp.isChild = true;
        }

        for (let ii = 0; ii < tvi.Items.length; ii++) {
            total += this.GetDirectoryTotal(tvi.Items[ii]);
        }

        adp.TotalDocs = total;
        return total;
    }

    private RefreshDirectoryTotals(): void {
        let ii: number, ii2: number;
        let total: number;

        this.m_trash.Header = (this.m_removed_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldTrash').toUpperCase() + ' [{0}]', this.m_removed_documents.Count) : this.m_strdata_srv.getStr('fldTrash').toUpperCase();

        if (this.m_found_documents.Count > 0) {
            this.m_founddocs_hint = IWFObject.Format(this.m_strdata_srv.getStr('fldSearchResult') + ' [{0}]', this.m_found_documents.Count);
            this.m_founddocs_label = this.m_found_documents.Count.toString();
        } else {
            this.m_founddocs_label = '';
            this.m_founddocs_hint = '';
        }

        for (ii = 0; ii < this.m_all_companies.size; ii++) {
            let tvi: TreeViewItem = IWFObject.ElementAt(this.m_all_companies.values(), ii);
            let adp: WFCompanyAdp = <WFCompanyAdp>tvi.UserObj;
            total = adp.Documents.Count;
            for (ii2 = 0; ii2 < tvi.Items.length; ii2++) {
                total += this.GetDirectoryTotal(tvi.Items[ii2]);
            }
            adp.TotalDocs = total;
        }

        this.m_befocr.Header = (this.m_befocr_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldSecretaryReaded').toUpperCase() + ' [{0}]', this.m_befocr_documents.Count) : this.m_strdata_srv.getStr('fldSecretaryReaded').toUpperCase();
        this.m_inocr.Header = (this.m_inocr_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldSecretaryOCR').toUpperCase() + ' [{0}]', this.m_inocr_documents.Count) : this.m_strdata_srv.getStr('fldSecretaryOCR').toUpperCase();
        this.m_afterocr.Header = (this.m_aftocr_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldSecretaryChecked').toUpperCase() + ' [{0}]', this.m_aftocr_documents.Count) : this.m_strdata_srv.getStr('fldSecretaryChecked').toUpperCase();
        this.m_templates.Header = (this.m_temp_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldSecretaryTemp').toUpperCase() + ' [{0}]', this.m_temp_documents.Count) : this.m_strdata_srv.getStr('fldSecretaryTemp').toUpperCase();

        total = this.m_befocr_documents.Count + this.m_inocr_documents.Count + this.m_aftocr_documents.Count + this.m_temp_documents.Count;
        this.m_inputdocs_label = (total > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldSecretary').toUpperCase() + ' [{0}]', total) : this.m_strdata_srv.getStr('fldSecretary').toUpperCase();

        this.m_toapproval.Header = (this.m_toapproval_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldAuthWait').toUpperCase() + ' [{0}]', this.m_toapproval_documents.Count) : this.m_strdata_srv.getStr('fldAuthWait').toUpperCase();
        this.m_approvedbyme.Header = (this.m_approvedbyme_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldAuthByMe').toUpperCase() + ' [{0}]', this.m_approvedbyme_documents.Count) : this.m_strdata_srv.getStr('fldAuthByMe').toUpperCase();
        this.m_approvedbyother.Header = (this.m_approvedbyother_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldAuthByOther').toUpperCase() + ' [{0}]', this.m_approvedbyother_documents.Count) : this.m_strdata_srv.getStr('fldAuthByOther').toUpperCase();
        this.m_rejectedbyme.Header = (this.m_rejectedbyme_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldRejectByMe').toUpperCase() + ' [{0}]', this.m_rejectedbyme_documents.Count) : this.m_strdata_srv.getStr('fldRejectByMe').toUpperCase();
        this.m_rejectedbyother.Header = (this.m_rejectedbyother_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldRejectByOther').toUpperCase() + ' [{0}]', this.m_rejectedbyother_documents.Count) : this.m_strdata_srv.getStr('fldRejectByOther').toUpperCase();

        total = this.m_toapproval_documents.Count; // +m_approvedbyme_documents.Count + m_approvedbyother_documents.Count + m_rejectedbyme_documents.Count + m_rejectedbyother_documents.Count;
        this.m_approval_label = (total > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('fldAuthorizations').toUpperCase() + ' [{0}]', total) : this.m_strdata_srv.getStr('fldAuthorizations').toUpperCase();

        let alerts = 0;
        for (ii = 0; ii < this.m_alertsselector.length; ii++) {
            let tvi: TreeViewItem = this.m_alertsselector[ii];
            let grp: AlertsGroup = <AlertsGroup>tvi.UserObj;
            if (grp.IsAlert) alerts += grp.TotalDocs;
        }

        if (alerts > 0) {
            this.m_alertstab.NewEventsVis = true;
            this.m_alertstab.NewEvents = alerts;
        } else {
            this.m_alertstab.NewEventsVis = false;
        }

        if(this.m_inputdocstab) {
            if (this.m_aftocr_documents.Count > 0) {
                this.m_inputdocstab.NewEventsVis = true;
                this.m_inputdocstab.NewEvents = this.m_aftocr_documents.Count;
            } else {
                this.m_inputdocstab.NewEventsVis = false;
            }
        } 

        if (this.m_toapproval_documents.Count > 0) {
            this.m_aprovalstab.NewEventsVis = true;
            this.m_aprovalstab.NewEvents = this.m_toapproval_documents.Count;
        } else {
            this.m_aprovalstab.NewEventsVis = false;
        }
    }

    //

    public SelectTab(tab: number): void {
        this.m_selected_tab = tab;
    }

    //

    public ShowDocumentList(lst: DocumentList, src: Object): void {
        this.m_fileselector_src = src;
        this.m_fileselector_ctx = (lst== null) ? this.m_dumylist : lst;
        this.FillFileSelector();
    }

    public SelectDirectory(tvi: TreeViewItem): void {
        try {
            if (tvi != null) {
                this.m_selecteddirlist = new Array<BreadCrumbsItem>();

                this.m_fileselector_cpage = 0;

                if (tvi.UserObj instanceof WFCompanyAdp) {
                    let adp: WFCompanyAdp = <WFCompanyAdp>tvi.UserObj;
                    this.m_selectedcmp = adp;
                    this.m_selecteddir = null;
                    this.FillBreadCrumb(adp);
                    this.ShowDocumentList(adp.Documents, null);
                    return;
                }

                if (tvi.UserObj instanceof WFDirectoryAdp) {
                    let adp: WFDirectoryAdp = <WFDirectoryAdp>tvi.UserObj;
                    this.m_selectedcmp = <WFCompanyAdp>this.m_all_companies.get(adp.CompanyID).UserObj;
                    this.m_selecteddir = adp;
                    this.FillBreadCrumb(adp);
                    this.ShowDocumentList(adp.Documents, null);
                    return;
                }

                if (tvi.UserObj instanceof AlertsGroup) {
                    let grp: AlertsGroup = <AlertsGroup>tvi.UserObj;
                    this.m_selectedcmp = null;
                    this.m_selecteddir = null;
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(grp.Documents, null);
                    return;
                }

                if (tvi === this.m_trash) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_removed_documents, null);
                    return;
                }

                if (tvi === this.m_observed) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_observed_documents, null);
                    return;
                }

                if (tvi === this.m_favorite) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_favorite_documents, null);
                    return;
                }

                if (tvi === this.m_befocr) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_befocr_documents, null);
                    return;
                }

                if (tvi === this.m_inocr) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_inocr_documents, null);
                    return;
                }

                if (tvi === this.m_afterocr) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_aftocr_documents, null);
                    return;
                }

                if (tvi === this.m_templates) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_temp_documents, null);
                    return;
                }

                //nowe
                if (tvi === this.m_toapproval) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_toapproval_documents, null);
                    return;
                }

                if (tvi === this.m_approvedbyme) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_approvedbyme_documents, null);
                    return;
                }

                if (tvi === this.m_approvedbyother) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_approvedbyother_documents, null);
                    return;
                }

                if (tvi === this.m_rejectedbyme) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_rejectedbyme_documents, null);
                    return;
                }

                if (tvi === this.m_rejectedbyother) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_rejectedbyother_documents, null);
                    return;
                }

                if (tvi === this.m_recent) {
                    this.FillBreadCrumb(tvi);
                    this.ShowDocumentList(this.m_recent_documents, null);
                    return;
                }

                //pobranie dziennika koreposndencji
                if (tvi.UserObj instanceof IWFDataFetchAdp) {
                    this.FillBreadCrumb(tvi);
                    this.SelectReport(<IWFDataFetchAdp>tvi.UserObj, false, null);
                    return;
                }

                if(tvi.UserObj instanceof WFMailFolderAdp) {
                    this.FillBreadCrumb(tvi);
                    this.SelectMailBox(<WFMailFolderAdp>tvi.UserObj);
                } else {
                    if(tvi.UserObj instanceof WFDocumentInterfaceAdp) {
                        this.FillBreadCrumb(tvi);
                        this.SelectEDoc(<WFDocumentInterfaceAdp>tvi.UserObj);
                    }
                }

                this.ShowDocumentList(this.m_dumylist, null);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private CanChangeDirectory(cmp_id: number): boolean {
        if (this.m_cache_srv.User.CompanyPermissionType === PrmType.TYPE_ALLOWFROM) {
            return (this.m_cache_srv.User.CompanyPermissions.indexOf(cmp_id) >= 0);
        }
        return true;
    }

    public OpenDirectoryMenu(tvi: TreeViewItem, trg: Object): void {
        try {
            if (tvi != null && tvi.UserObj != null) {

                //
                if (tvi.UserObj instanceof WFDirectoryAdp || tvi.UserObj instanceof WFCompanyAdp) {
                    let mnu0: boolean;
                    let mnu1: boolean;
                    let mnu2: boolean;
                    let cmp_id: number;
                    let dir_id: number;
                    let dir: WFDirectoryAdp;

                    if (tvi.UserObj instanceof WFCompanyAdp) {
                        let cmpa: WFCompanyAdp = <WFCompanyAdp>tvi.UserObj;
                        if (!this.CanChangeDirectory(cmpa.ID) || !cmpa.CanCreateDirectory(this.m_cache_srv)) return;
                        mnu0 = true;
                        mnu1 = false;
                        mnu2 = false;

                        cmp_id = cmpa.ID;
                        dir_id = 0;
                        dir = null;
                    } else {
                        let curadp: WFDirectoryAdp = <WFDirectoryAdp>tvi.UserObj;
                        if (!this.CanChangeDirectory(curadp.CompanyID)) return;

                        cmp_id = curadp.CompanyID;
                        dir_id = curadp.ID;
                        dir = curadp;

                        let show = false;
                        if (curadp.CanCreateDirectory) {
                            show = true;
                            mnu0 = true;
                            if ((tvi.Items.length > 0) || (curadp.Documents.Count > 0)) {
                                mnu2 = false;
                            } else {
                                let showdelitem = true;
                                /*for (let cmp of Array.from(this.m_cache_srv.Companies.values())) {
                                    for (let tst of Array.from(cmp.DefDirectories.values())) {
                                        if (tst === curadp.ID) {
                                            showdelitem = false;
                                            break;
                                        }
                                    }
                                }*/
                                mnu2 = showdelitem; //usun katalog
                            }
                        } else {
                            mnu0 = false;
                            mnu2 = false;
                        }

                        if (curadp.CanEditDirectory) {
                            show = true;
                            mnu1 = true; //zmien nazwe
                        } else {
                            mnu1 = false;
                        }

                        if (!show) return;

                    }

                    this.m_lastseldirectory = tvi;
                    //

                    const self = this;
                    const wnd = this.m_wnd_srv.showControl(AddNewDocMenuComponent);
                    const inst: AddNewDocMenuComponent = wnd.instance;
                    inst.SetPrms(trg);

                    if (mnu0) inst.AddEntry(this.m_strdata_srv.getStr('strCatalogAdd'), 0, 'cmn_add.svg');
                    if (mnu1) inst.AddEntry(this.m_strdata_srv.getStr('strCatalogChange'), 1, 'cmn_editrect.svg');
                    if (mnu2) inst.AddEntry(this.m_strdata_srv.getStr('strCatalogDel'), 2, 'cmn_minus.svg');

                    inst.closePopup.subscribe(() => {
                        wnd.destroy();
                    });

                    inst.runCommand.subscribe((ret) => {
                        wnd.destroy();
                        switch (ret) {
                            case 0:
                                self.AddNewDirectory(cmp_id, dir_id);
                                break;
                            case 1:
                                self.RenameDirectory(dir);
                                break;
                            case 2:
                                self.RemoveDirectory(dir);
                                break;
                        }

                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private AddNewDirectory(cmp_id: number, dir_id: number): void {
        try {
            const self = this;
            const wnd = this.m_wnd_srv.showControl(DirectoryNameComponent);
            wnd.instance.SetPrms('');
            wnd.instance.onClosed.subscribe((ret) => {
                wnd.destroy();
                if (ret) self.AddNewDirectory2(wnd.instance, cmp_id, dir_id);
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private AddNewDirectory2(sndwnd: DirectoryNameComponent, cmp_id: number, dir_id: number): void {
        try {

            let dir = WFDirectory.Create(sndwnd.DirName, '');
            dir.CompanyID = cmp_id;
            dir.DirectoryID = dir_id;

            let dadp = new WFDirectoryAdp(dir, this.m_cache_srv);
            //dadp.OnDropDirectory += m_ondropdirectory;
            dadp.OnError.subscribe2(this.m_onerrorevh);
            dadp.OnPublishDirectory = this.m_onpublishdirectory;
            dadp.OnRemoveDirectory = this.m_onremovedirectory;

            this.m_busy_text = this.m_strdata_srv.getStr('strFolderAddedInfo');
            this.m_publishall = false;
            dadp.Publish();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private OnPublishDirectory(snd: WFDirectoryAdp, status: boolean): void {
        let ii, ii2: number;

        try {
            let alldir: Array<TreeViewItem> = Array.from(this.m_all_directories.values());

            if (status) {
                let ntvi: TreeViewItem = null;

                for (let tvi of alldir) {
                    if (tvi.UserObj === snd) {
                        ntvi = tvi;
                        ntvi.Header = snd.DirName.toUpperCase();
                        break;
                    }
                }

                if (ntvi == null) {
                    ntvi = new TreeViewItem();
                    ntvi.Header = snd.DirName.toUpperCase();
                    ntvi.UserObj = snd;
                    /*ntvi.MouseRightButtonDown += m_evdirsubmenu;*/
                    this.m_lastseldirectory.Items.push(ntvi);
                    this.m_all_directories.set(snd.ID, ntvi);
                } else {
                    let delfcmp = true;

                    for (let prnt of alldir) {
                        let off = prnt.Items.indexOf(ntvi);
                        if (off >= 0) {
                            prnt.Items.splice(off, 1);
                            delfcmp = false;
                            break;
                        }
                    }

                    if (delfcmp) {
                        let allcmp: Array<TreeViewItem> = Array.from(this.m_all_companies.values());
                        for (let prnt of allcmp) {
                            let off = prnt.Items.indexOf(ntvi);
                            if (off >= 0) {
                                prnt.Items.splice(off, 1);
                                break;
                            }
                        }
                    }

                    if (snd.DirectoryID > 0)
                        this.m_all_directories.get(snd.DirectoryID).Items.push(ntvi);
                    else
                        this.m_all_companies.get(snd.CompanyID).Items.push(ntvi);
                }

                this.RefreshDirectoryTotals();

                this.m_cache_srv.Directories.set(snd.ID, snd);
            }


            if (this.m_publishall) {
                //znajdz kolejny katalog do publikacji
                for (ii = 0; ii < alldir.length; ii++) {
                    if (alldir[ii].UserObj === snd) {
                        for (ii2 = ii + 1; ii2 < alldir.length; ii2++) {
                            let adp: WFDirectoryAdp = <WFDirectoryAdp>alldir[ii2].UserObj;
                            if (adp.IsModified) {
                                adp.Publish();
                                return;
                            }
                        }
                        break;
                    }
                }

                //znajdz pierwszy dokument do aktualizacji
                for (ii = 0; ii < this.m_cache_srv.GetDocumentsCount(); ii++) {
                    let adp = this.m_cache_srv.GetDocumentByOff(ii);
                    if (adp.IsModified) {
                        this.PublishLocalDoc(adp);
                        return;
                    }
                }

                if (!this.m_alertsexec) {
                    const self = this;
                    this.m_alertsexec = true;
                    this.m_data_srv.getUserAlerts(this.m_cache_srv.SessionID, false, this.m_onerrorevh, (alerts) => {
                        self.GetUserAlertsCompleted(alerts, false, false, 0);
                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    private RenameDirectory(dir: WFDirectoryAdp): void {
        try {
            const self = this;
            const wnd = this.m_wnd_srv.showControl(DirectoryNameComponent);
            wnd.instance.SetPrms(dir.DirName);
            wnd.instance.onClosed.subscribe((ret) => {
                wnd.destroy();
                if (ret) self.RenameDirectory2(wnd.instance, dir);
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private RenameDirectory2(sndwnd: DirectoryNameComponent, dir: WFDirectoryAdp): void {
        try {
            dir.DirName = sndwnd.DirName;
            this.m_busy_text = this.m_strdata_srv.getStr('strFolderUpdateInfo');
            this.m_publishall = false;
            dir.Publish();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private RemoveDirectory(dir: WFDirectoryAdp): void {
        try {
            const self = this;
            this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.YESNO, IWFObject.Format(this.m_strdata_srv.getStr('strFolderDelAskInfo'), dir.DirName), (res) => {
                if (res === WNDRESULT.OKYES) self.RemoveDirectory2(dir);
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private OnRemoveDirectory(snd: WFDirectoryAdp, status: boolean): void {
        try {
            if (status) {
                let ptvi = (snd.DirectoryID > 0) ? this.m_all_directories.get(snd.DirectoryID) : this.m_all_companies.get(snd.CompanyID);
                for (let ii = 0; ii < ptvi.Items.length; ii++) {
                    let tvi = ptvi.Items[ii];
                    if (tvi.UserObj === snd) {
                        ptvi.Items.splice(ii, 1);
                        break;
                    }
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    private RemoveDirectory2(dir: WFDirectoryAdp): void {
        try {
            this.m_busy_text = this.m_strdata_srv.getStr('strFolderDelInfo');
            dir.Remove();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private FillFileSelector(): void {
        this.m_fldoptions = true;
        this.m_sort_label = true;

        if (this.m_fileselector_ctx.Count < DOCUMENTSPERPAGE) {
            this.m_page_selector = false;
        } else {
            let maxpg = (this.m_fileselector_ctx.Count / DOCUMENTSPERPAGE) | 0;
            if ((this.m_fileselector_ctx.Count % DOCUMENTSPERPAGE) > 0) maxpg++;
            this.m_page_selector = true;
            this.m_current_page = IWFObject.Format('{2}  {0} / {1}', (this.m_fileselector_cpage + 1), maxpg, this.m_strdata_srv.getStr('strPage').toUpperCase());
        }

        if (this.m_fileselector_ctx.CurrentOrder) {
            this.m_sortorder1 = false;
            this.m_sortorder2 = true;
        } else {
            this.m_sortorder1 = true;
            this.m_sortorder2 = false;
        }

        switch (this.m_fileselector_ctx.CurrentSortCol) {
            case SORT_PROPERTY.BY_DOCNAME: this.m_sortcol = this.m_dir_sortby[0]; break;
            case SORT_PROPERTY.BY_DOCDESC: this.m_sortcol = this.m_dir_sortby[1]; break;
            case SORT_PROPERTY.BY_DOCNUM: this.m_sortcol = this.m_dir_sortby[2]; break;
            case SORT_PROPERTY.BY_DOCDATE: this.m_sortcol = this.m_dir_sortby[3]; break;
            case SORT_PROPERTY.BY_CREATEDATE: this.m_sortcol = this.m_dir_sortby[4]; break;
            case SORT_PROPERTY.BY_UPDATEDATE: this.m_sortcol = this.m_dir_sortby[5]; break;
        }

        if (this.m_fileselector_ctx.Count > 0) {
            this.m_filterbtn = true;

            /*string pthdata = (m_fileselector_ctx == m_filterlist) ? (string)Application.Current.Resources["CMN_FILTERSON"] : (string)Application.Current.Resources["CMN_FILTERS"];
            var b = new Binding
            {
                Source = pthdata
            };
            BindingOperations.SetBinding(m_filterbtn, PathButton.PathDataProperty, b);*/

        } else {
            this.m_filterbtn = false;
        }

        this.FetchDocuments(this.m_fileselector_ctx.CurrentSortCol, this.m_fileselector_ctx.CurrentOrder);
    }

    //

    public ShowPrevPage(): void {
        if (this.m_fileselector_cpage > 0) {
            this.m_fileselector_cpage--;
            this.FillFileSelector();
        }
    }

    //

    public ShowNextPage(): void {
        let maxpg: number = (this.m_fileselector_ctx.Count / DOCUMENTSPERPAGE) | 0;
        if ((this.m_fileselector_ctx.Count % DOCUMENTSPERPAGE) > 0) maxpg++;

        if ((this.m_fileselector_cpage + 1) < maxpg) {
            this.m_fileselector_cpage++;
            this.FillFileSelector();
        }
    }

    //

    public SortColChanged(): void {
        try {
            let col = SORT_PROPERTY.BY_DOCNAME;
            switch (this.m_sortcol.val) {
                case 0: col = SORT_PROPERTY.BY_DOCNAME; break;
                case 1: col = SORT_PROPERTY.BY_DOCDESC; break;
                case 2: col = SORT_PROPERTY.BY_DOCNUM; break;
                case 3: col = SORT_PROPERTY.BY_DOCDATE; break;
                case 4: col = SORT_PROPERTY.BY_CREATEDATE; break;
                case 5: col = SORT_PROPERTY.BY_UPDATEDATE; break;
            }

            if (col !== this.m_fileselector_ctx.CurrentSortCol) {
                this.FetchDocuments(col, this.m_fileselector_ctx.CurrentOrder);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SetSortOrderAZ(): void {
        try {
            if (!this.m_fileselector_ctx.CurrentOrder) {
                this.m_sortorder1 = false;
                this.m_sortorder2 = true;
                this.FetchDocuments(this.m_fileselector_ctx.CurrentSortCol, true);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SetSortOrderZA(): void {
        try {
            if (this.m_fileselector_ctx.CurrentOrder) {
                this.m_sortorder1 = true;
                this.m_sortorder2 = false;
                this.FetchDocuments(this.m_fileselector_ctx.CurrentSortCol, false);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private FetchDocuments(col: SORT_PROPERTY, order: boolean): void {
        let start = this.m_fileselector_cpage * DOCUMENTSPERPAGE;
        if (start > this.m_fileselector_ctx.Count) {
            this.m_fileselector_cpage = 0;
            start = 0;
        }

        let len = ((start + DOCUMENTSPERPAGE) > this.m_fileselector_ctx.Count) ? this.m_fileselector_ctx.Count - start : DOCUMENTSPERPAGE;
        this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');

        const self = this;
        this.m_fileselector_ctx.OnError.subscribe2(this.m_onerrorevh);
        this.m_fileselector_ctx.FetchCompleted = this.m_fetchcplevh;
        this.m_fileselector_ctx.FetchDocuments(this.m_cache_srv, col, order, start, len);
    }

    private FetchCompleted(sender: DocumentList, docs: Array<WFDocumentAdp>): void {
        try {
            this.m_fileselector = docs;
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    public ClickFilterList(): void {
        try {
            this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
            let force;
            let dlst;
            if(this.m_fileselector_ctx === this.m_filterlist) {
                dlst= this.m_sourcefilterlist;
                force= true;
            } else {
                dlst= this.m_fileselector_ctx;
                force= false;
            }

            dlst.OnError.subscribe2(this.m_onerrorevh);
            dlst.FetchFilterInfoCompleted = this.m_onfetchfilterinfocompletedevh;
            dlst.FetchFilterInfo(this.m_cache_srv, force);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private FetchFilterInfoCompleted(snd: DocumentList, finf: WFFilterInfo): void {
        try {
            if (finf != null) {
                const self = this;
                const wnd = this.m_wnd_srv.showControl(FilterWndComponent);
                wnd.instance.SetPrms(finf, (this.m_fileselector_ctx === this.m_filterlist) ? this.m_filtercfg : null);
                wnd.instance.onClosed.subscribe((ret) => {
                    wnd.destroy();
                    if (ret) self.FilterDialogClosed(wnd.instance);
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        this.m_busy_text = '';
    }

    private FilterDialogClosed(dlg: FilterWndComponent): void {
        try {
            this.m_filterlist.Clear();
            this.m_filterlist.Add2(dlg.DocumentList);
            this.m_filtercfg= dlg.Selected;
            if (this.m_fileselector_ctx !== this.m_filterlist) this.m_sourcefilterlist = this.m_fileselector_ctx;
            this.m_fileselector_ctx = this.m_filterlist;
            this.m_fileselector_cpage = 0;
            this.FillFileSelector();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private ReloadApprovals(): void {
        this.m_busy_text = this.m_strdata_srv.getStr('strWaitConfirms');

        let flgs = 0; //loadattachments
        flgs |= FDOC_INCLUDE_COMPANIES_ID; //company id
        flgs |= FDOC_INCLUDE_DIRECTORIES_ID; //directory id
        flgs |= FDOC_INCLUDE_APPSTATUS; //status zatwierdzenia
        flgs |= FDOC_DOCSTATUS_INSYSTEM; //tylko dodane

        let shmsid = new Array<number>(this.m_cache_srv.AuthSchemas.size + 1);
        shmsid[0] = WFDocument.SCHM_CUSTOM;
        for (let ii = 0; ii < this.m_cache_srv.AuthSchemas.size; ii++) {
            shmsid[ii + 1] = IWFObject.ElementAt(this.m_cache_srv.AuthSchemas.values(), ii).ID;
        }

        let prms: FindDocumentPrms = {
            sid: this.m_cache_srv.SessionID,
            companies: [],
            users: [],
            classes: [],
            schemas: shmsid,
            directories: [],
            documents: [],
            fargs: [],
            createdatfrom: null,
            createdatto: null,
            docdatefrom: null,
            docdateto: null,
            flags: flgs,
            refobj: [],
            attvalues: []
        };

        const self = this;
        this.m_data_srv.findDocuments(prms, this.m_onerrorevh, (rows: Array<any>) => {
            self.FindDocumentsCompleted3(rows);
        });
    }

    public ManualReloadApprovals(): void {
        try {
            if (this.m_cache_srv.User != null) this.ReloadApprovals();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public ManualReloadDocuments(): void {
        try {
            if (this.m_cache_srv.User != null) {
                if (this.GetIsModified()) {
                    const self = this;
                    this.m_global_srv.showMsg(ALR_ICONTYPE.WARNING, BUTTONSTYPE.YESNOCANCEL, this.m_strdata_srv.getStr('strUnsavedDateInfo'), (res) => {
                        self.CheckNeedPublish(res);
                    });
                    return;
                }
                this.ActualizeDirectoryTree(FIND_INSYSTEM, 0);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ManualReloadUserAlerts(): void {
        try {
            if (this.m_cache_srv.User != null) this.ReloadUserAlerts(false, 0);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private ReloadInputDocuments(): void {
        this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');

        let flgs = 0; //loadattachments
        flgs |= FDOC_INCLUDE_DOCSTATUS;
        flgs |= FDOC_DOCSTATUS_BEFOREOCR;
        flgs |= FDOC_DOCSTATUS_INOCR;
        flgs |= FDOC_DOCSTATUS_AFTEROCR;
        flgs |= FDOC_DOCSTATUS_TEMPLATE;

        let prms: FindDocumentPrms = {
            sid: this.m_cache_srv.SessionID,
            companies: [],
            users: [this.m_cache_srv.User.ID],
            classes: [],
            schemas: [],
            directories: [],
            documents: [],
            fargs: [],
            createdatfrom: null,
            createdatto: null,
            docdatefrom: null,
            docdateto: null,
            flags: flgs,
            refobj: [],
            attvalues: []
        };

        const self = this;
        this.m_data_srv.findDocuments(prms, this.m_onerrorevh, (rows: Array<any>) => {
            self.FindDocumentsCompleted2(rows);
        });
    }

    public ManualReloadInputDocuments(): void {
        try {
            if (this.m_cache_srv.User != null) this.ReloadInputDocuments();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public ShowOcrMenu(event: any): void {
        try {

            const self = this;
            const wnd = this.m_wnd_srv.showControl(AddNewDocMenuComponent);
            const inst: AddNewDocMenuComponent = wnd.instance;

            let trg = event.target;
            //if (trg.nodeName === 'SPAN') trg = trg.offsetParent;

            inst.SetPrms(trg);

            const img = 'cmn_add.svg';
            if(!IWFObject.IsNullOrEmpty(this.m_cache_srv.Config.CentralService)) {
                inst.AddEntry(this.m_strdata_srv.getStr('strNewFile'), 0, img);
                inst.AddEntry(this.m_strdata_srv.getStr('strNewWoOCR'), -1, img);
                inst.AddEntry(this.m_strdata_srv.getStr('strNewToAfOCR'), -2, img);
                inst.AddEntry(this.m_strdata_srv.getStr('strEmptyRTF'), -3, img);
            } else {
                inst.AddEntry(this.m_strdata_srv.getStr('strNewFile'), -1, img);
                inst.AddEntry(this.m_strdata_srv.getStr('strEmptyRTF'), -3, img);
            }

            //szablony
            let len = this.m_cache_srv.GetDocumentsCount();
            for (let ii = 0; ii < len; ii++) {
                let adp = this.m_cache_srv.GetDocumentByOff(ii);
                if (adp.DocStatus === WFDocStatus.TEMPLATE) {
                    let usr = (adp.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(adp.UserID);
                    inst.AddEntry(IWFObject.Format('{0} ({1})', adp.Name, usr.Name), adp.ID, img);
                }
            }

            inst.closePopup.subscribe(() => {
                wnd.destroy();
            });

            inst.runCommand.subscribe((ret) => {
                wnd.destroy();
                if (ret > 0) {
                    self.AddDocumentFromTemplate(ret);
                } else {
                    if (ret === -3) {
                        self.AddDocumentFromTemplate(0);
                    } else {
                        self.AddFromFiles(ret, null);
                    }
                }
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public AddFromFiles(flag: number, dst: WFDocumentAdp): void {
        try {
            switch (flag) {
                case 0:
                    this.m_toOCR = (!IWFObject.IsNullOrEmpty(this.m_cache_srv.Config.CentralService)) ? WFDocStatus.INOCR : WFDocStatus.AFTEROCR;
                    break;
                case -1:
                    this.m_toOCR = WFDocStatus.AFTEROCR;
                    break;
                case -2:
                    this.m_toOCR = WFDocStatus.BEFOREOCR;
                    break;
                default:
                    this.m_toOCR = WFDocStatus.INOCR;
                    break;
            }
            this.m_import_dst = dst;
            this.m_import_att = null;

            //let event = new MouseEvent('click', {bubbles: false});
            this.m_filesel.nativeElement.value = null;
            this.m_filesel.nativeElement.click(); // .dispatchEvent(event);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private CheckFilePrms(fl: File): boolean {
        let ufname = fl.name.toUpperCase();
        if (ufname.endsWith('.TPL') && !this.m_cache_srv.User.IsAdmin) {
            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strImportTPL'));
            return false;
        }
        return true;
    }

    public SelectFileChange(event: any): void {
        try {
            let fileList: FileList = event.target.files;
            if (fileList.length > 0) {
                this.m_publishall = false;

                let arr = new Array<File>(fileList.length);
                for (let ii = 0; ii < fileList.length; ii++) {
                    let fl = fileList[ii];
                    if (!this.CheckFilePrms(fl)) return;
                    arr[ii] = fl;
                }
                this.AddNewFromFl(arr);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private AddNewFromFl(arr: Array<File>): void {
        try {
            let file = arr[0];
            this.m_busy_text = IWFObject.Format('{0} {1}', this.m_strdata_srv.getStr('strSendDataInfo'), file.name);

            const self = this;
            let rdr = new FileReader();
            rdr.onloadend = (res) => {
                let dta = btoa((<any>res.srcElement).result);
                self.m_data_srv.createPagesFromFile(self.m_cache_srv.SessionID, file.name, dta, self.m_onerrorevh, (ret) => {
                    self.CreatePagesFromFileCompleted(ret, arr);
                });
            };

            rdr.readAsBinaryString(file);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private GetNewDocumentName(): string {
        let dnow = new Date();
        return IWFObject.Format('DOCUMENT{0}', this.m_datep.transform(dnow, 'yyyyMMdd'));
    }

    private CreatePagesFromFileCompleted(pgs: Array<WFDocumentPage>, arr: Array<File>): void {
        try {

            if (this.m_import_dst != null) { //jako strony
                this.m_import_dst.AddPageRange(pgs);
                arr.splice(0, 1);
                if (arr.length > 0) {
                    this.AddNewFromFl(arr);
                } else {
                    if (this.m_import_dst.CanReplaceResource) {
                        this.m_pagereplacepanel = true;
                    } else {
                        this.m_pagereplacepanel = false;
                    }
                    this.m_import_dst = null;
                    this.m_busy_text = '';
                }
                return;
            }

            let dnow = new Date();
            let dname = this.GetNewDocumentName();
            let ndoc = WFDocument.Create(dname, '', '', dnow);

            let fl = arr[0];
            let off = fl.name.lastIndexOf('.');
            let ext = (off > 0) ? fl.name.substr(off + 1).toUpperCase() : '';

            //DM 28092022 zgloszenie GJ na teams
            /*if (['BMP', 'JPG', 'PNG', 'TIF', 'PDF', 'TIFF'].indexOf(ext) >= 0) {
                ndoc.DocStatus = this.m_toOCR;
            } else {
                ndoc.DocStatus = WFDocStatus.AFTEROCR;
            }*/

            ndoc.DocStatus = this.m_toOCR;
            ndoc.UserID = this.m_cache_srv.User.ID;
            ndoc.CanChangeResource = (ndoc.DocStatus === WFDocStatus.AFTEROCR);
            for (let pg of pgs) ndoc.Pages.push(pg);

            if (this.m_import_att != null) {
                let cls = this.m_cache_srv.Classes.get(this.m_import_att.ClassID);
                ndoc.ClassID = cls.AttachmentsClassID;
                if (ndoc.DocStatus === WFDocStatus.INOCR) ndoc.AutoPublish = true;
                ndoc.CompanyID= this.m_import_att.CompanyID;
            } else {
                if (this.m_cache_srv.User.CompanyPermissionType === PrmType.TYPE_ALLOWFROM && this.m_cache_srv.User.CompanyPermissions.length > 0) {
                    ndoc.CompanyID = this.m_cache_srv.User.CompanyPermissions[0];
                } else {
                    ndoc.CompanyID = Array.from(this.m_cache_srv.Companies.values())[0].ID;
                }
            }

            let adp = new WFDocumentAdp(this.m_cache_srv, ndoc, true, false);

            const self = this;
            let erradp = new EventAdp<_OnError>();
            erradp.subscribe(() => {
                if (self.m_import_att != null && adp.DocStatus !== WFDocStatus.BEFOREOCR) {
                    adp.DocStatus = WFDocStatus.AFTEROCR;
                    adp.Publish();
                    return;
                }
                self.m_busy_text = '';
            });
            adp.OnError.subscribe2(erradp);

            adp.OnPublishDocument = (snd, status) => {
                try {
                    self.m_cache_srv.AddDocument(snd);
                    if (self.m_import_att != null) self.m_import_att.AddAttachment(snd.ID);

                    arr.splice(0, 1);
                    if (arr.length > 0) {
                        self.AddNewFromFl(arr);
                    } else {

                        if (self.m_import_att != null) {
                            self.m_publishall = false;
                            self.PublishLocalDoc(self.m_import_att);
                            self.m_import_att = null;
                        } else {
                            self.ReloadInputDocuments();
                        }
                        self.m_busy_text = '';
                    }
                } catch (ex) {
                    self.m_global_srv.manageException(ex);
                    self.m_busy_text = '';
                }
            };

            //przypisz katalog
            if ((adp.DocStatus == WFDocStatus.INOCR && ndoc.AutoPublish) || (adp.DocStatus == WFDocStatus.INSYSTEM)) {
                this.BindDirectoryIDAndPublish(adp, false);
                return;
            } 

            adp.Publish();
        } catch (ex) {
            this.m_busy_text = '';
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private AddDocumentFromTemplate(id: number): void {
        try {
            let ndoc: WFDocument;
            let barr: string;

            if (id > 0) {
                let src = this.m_cache_srv.GetDocument(id);
                ndoc = src.CreateTemplate();
                ndoc.DocStatus = (src.IsEditable) ? WFDocStatus.AFTEROCR : WFDocStatus.BEFOREOCR;
                ndoc.Name = this.GetNewDocumentName();
                barr = null;
            } else {
                ndoc = WFDocument.Create(this.GetNewDocumentName(), '', '', new Date());
                let sctx = this.m_strdata_srv.getStr('htmlTemplate');
                sctx = sctx.replace(/\[\[NDOC\]\]/g, ndoc.Name);
                barr = btoa(sctx);
                ndoc.DocStatus = WFDocStatus.AFTEROCR;
            }

            ndoc.UserID = this.m_cache_srv.User.ID;
            if (this.m_cache_srv.User.CompanyPermissionType === PrmType.TYPE_ALLOWFROM && this.m_cache_srv.User.CompanyPermissions.length > 0) {
                ndoc.CompanyID = this.m_cache_srv.User.CompanyPermissions[0];
            } else {
                ndoc.CompanyID = Array.from(this.m_cache_srv.Companies.values())[0].ID;
            }

            ndoc.CanChangeResource = (ndoc.DocStatus === WFDocStatus.AFTEROCR);

            let adp = new WFDocumentAdp(this.m_cache_srv, ndoc, true, false);

            if (barr == null) {
                this.m_publishall = false;
                this.PublishLocalDoc(adp);
            } else {
                const self = this;
                this.m_busy_text = this.m_strdata_srv.getStr('strSendDataInfo');
                this.m_data_srv.createPagesFromFile(this.m_cache_srv.SessionID, 'template.html', barr, this.m_onerrorevh, (ret) => {
                    self.CreatePagesFromFileCompleted2(ret, adp, false);
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private CreatePagesFromFileCompleted2(pgs: Array<WFDocumentPage>, dst: WFDocumentAdp, repl: boolean): void {
        try {
            if (repl) dst.ClearPages();
            dst.AddPageRange(pgs);
            if (dst.ID === 0) {
                //dodaj dokument do folderu wstepnego wprowadzania
                this.m_publishall = false;
                this.PublishLocalDoc(dst);
                return;
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        this.m_busy_text = '';
    }

    public ShowHideAdvSearch(): void {
        this.m_advoptions = !this.m_advoptions;
    }

    public ClearSearchOptions(): void {
        try {
            this.m_other.SetItemSelectState(0, true);
            this.m_other.SetItemSelectState(1, true);
            this.m_other.SetItemSelectState(2, true);

            let dnow: Date = new Date();
            this.m_other.SetItemSelectState(3, false);
            this.m_other.GetItem(3).date = dnow;

            dnow = new Date();
            dnow.setDate(dnow.getDate() - 7);
            this.m_other.SetItemSelectState(4, false);
            this.m_other.GetItem(4).date = dnow;

            dnow = new Date();
            this.m_other.SetItemSelectState(5, false);
            this.m_other.GetItem(5).date = dnow;

            dnow = new Date();
            dnow.setMonth(dnow.getMonth() - 1);
            this.m_other.SetItemSelectState(6, false);
            this.m_other.GetItem(6).date = dnow;

            this.m_other.SetItemSelectState(7, false);
            this.m_other.SetItemSelectState(8, false);
            this.m_other.SetItemSelectState(9, false);

            //m_companies.SetAllItemSelectState(true);
            this.m_companies.ClearItems();
            this.m_owners.ClearItems();
            this.m_classes.ClearItems();
            this.m_schemas.ClearItems();

            this.m_attribs.SetAllItemSelectState(true);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public ShowSearchResults(): void {
        try {
            this.ClearAllSelections();           
            this.m_fileselector_cpage = 0;
            this.m_fileselector_ctx = this.m_found_documents;
            this.FillFileSelector();
            this.FillBreadCrumb(this.m_found_documents);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private GetIsModified(): boolean {
        let ii: number;

        for (ii = 0; ii < this.m_all_directories.size; ii++) {
            if ((<WFDirectoryAdp>IWFObject.ElementAt(this.m_all_directories.values(), ii).UserObj).IsModified) return true;
        }

        for (ii = 0; ii < this.m_cache_srv.GetDocumentsCount(); ii++) {
            let adp: WFDocumentAdp = this.m_cache_srv.GetDocumentByOff(ii);
            if (adp.IsModified) return true;
        }

        return false;
    }

    public ClearSearchTextBox(): void {
        this.m_keywords = '';
        this.m_founddocs_label = '';
        this.m_founddocs_hint = '';
    }

    public FindDocuments(): void {
        try {
            this.m_advoptions = false;
            
            //sprawdz czy istnieja nie opublikowane dokumenty
            /*if (this.GetIsModified()) {
                const self = this;
                this.m_global_srv.showWarning(BUTTONSTYPE.YESNOCANCEL, this.m_strdata_srv.getStr('strUnsavedDateInfo'),
                    (wndres) => { self.CheckNeedPublish(wndres); });
                return;
            }*/

            let incmp = this.m_other.GetItem(8).checked;
            if (incmp) {
                this.ActualizeDirectoryTree(FIND_WITHPRMS, 0);
            } else {
                //pobieramy katalogi
                this.m_busy_text = this.m_strdata_srv.getStr('strWaitSearch');
                this.SendFindDocuments();
            }           
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private CheckNeedPublish(wndres: WNDRESULT): void {
        try {
            if (wndres !== WNDRESULT.CANCEL) {
                if (wndres === WNDRESULT.OKYES) 
                    this.PublishAll();
                else
                    this.ActualizeDirectoryTree(FIND_INSYSTEM, 0);                
            } 
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private PublishAll(): void {
        let ii: number;
        this.m_publishall = true;

        this.m_busy_text = this.m_strdata_srv.getStr('strSaveChangesInfo');

        //znajdz pierwszy katalog do aktualizacji
        /*for (ii = 0; ii < m_all_directories.Count; ii++)
        {
            DirectoryAdp adp = (DirectoryAdp)m_all_directories.Values.ElementAt(ii).Header;
            if (adp.IsModified)
            {
                adp.Publish();
                return;
            }
        }*/

        //znajdz pierwszy dokument do aktualizacji
        for (ii = 0; ii < this.m_cache_srv.GetDocumentsCount(); ii++) {
            let adp = this.m_cache_srv.GetDocumentByOff(ii);
            if (adp.IsModified) {
                this.PublishLocalDoc(adp);
                return;
            }
        }
    }

    public PublishLocalDoc(doc: WFDocumentAdp): void {
        let ii: number;

        /*for (ii = 0; ii < dviews.Count; ii++)
        {
            DocViewer2 doc_preview = dviews[ii];
            if (doc == doc_preview.Document && doc_preview.ContentType == CONTENTTYPE.TYPE_EDITABLE)
            {
                byte[] rdoc = doc_preview.GetModifiedRtfDocument();
                if (rdoc != null) doc.SetTmpRtfDocument(rdoc);
                break;
            }
        }*/

        this.m_busy_text = IWFObject.Format(this.m_strdata_srv.getStr('strDocUpdateInfo'), doc.Name);
        doc.OnPublishDocument = this.m_onpublishdocument;
        doc.Publish();
    }

    //

    private OnPublishDocument(sender: WFDocumentAdp, status: boolean): void {
        let ii: number, ii2: number;
        let cmp: WFCompanyAdp;
        let dir: WFDirectoryAdp;

        try {
            if (status) {
                //uzpelniamy cache
                if (!this.m_cache_srv.ContainsDocument(sender.ID)) {
                    this.m_cache_srv.AddDocument(sender);
                    this.SelectDocument(sender, TabOperation.ACTUAL);
                } else {
                    if (sender === this.m_selecteditem) this.FetchAttachments(sender);
                }

                let needref = false;
                let needref2 = (this.m_fileselector_ctx == null || this.m_fileselector_ctx.Contains(sender.ID));

                //czyszczenie katalogów
                for (ii = 0; ii < this.m_all_companies.size; ii++) {
                    cmp = <WFCompanyAdp>IWFObject.ElementAt(this.m_all_companies.values(), ii).UserObj;
                    if (cmp.ID !== sender.CompanyID || sender.DirectoryID !== 0) {
                        if (cmp.Documents.Remove(sender.ID)) {
                            needref = true;
                            break;
                        }
                    }
                }

                for (ii = 0; ii < this.m_all_directories.size; ii++) {
                    dir = <WFDirectoryAdp>IWFObject.ElementAt(this.m_all_directories.values(), ii).UserObj;
                    if (dir.ID !== sender.DirectoryID) {
                        if (dir.Documents.Remove(sender.ID)) {
                            needref = true;
                            break;
                        }
                    }
                }

                if (sender.DirectoryID >= 0) {
                    if (this.m_removed_documents.Remove(sender.ID)) needref = true;
                }

                //aktaulizacja katalogów

                if (sender.DocStatus === WFDocStatus.INSYSTEM) {
                    if (sender.DirectoryID > 0) {
                        dir = <WFDirectoryAdp>this.m_all_directories.get(sender.DirectoryID).UserObj;
                        if (!dir.Documents.Contains(sender.ID)) {
                            dir.Documents.Add(sender.ID);
                            needref = true;
                        }
                    } else {
                        if (sender.DirectoryID < 0) {
                            if (!this.m_removed_documents.Contains(sender.ID)) {
                                this.m_removed_documents.Add(sender.ID);
                                needref = true;
                            }
                        } else {
                            cmp = <WFCompanyAdp>this.m_all_companies.get(sender.CompanyID).UserObj;
                            if (!cmp.Documents.Contains(sender.ID)) {
                                cmp.Documents.Add(sender.ID);
                                needref = true;
                            }
                        }
                    }
                }

                //przypnij wg statusu
                if (sender.DocStatus !== WFDocStatus.BEFOREOCR) {
                    if (this.m_befocr_documents.Remove(sender.ID)) needref = true;
                }

                if (sender.DocStatus !== WFDocStatus.INOCR) {
                    if (this.m_inocr_documents.Remove(sender.ID)) needref = true;
                }

                if (sender.DocStatus !== WFDocStatus.AFTEROCR) {
                    if (this.m_aftocr_documents.Remove(sender.ID)) needref = true;
                }

                if (sender.DocStatus !== WFDocStatus.TEMPLATE) {
                    if (this.m_temp_documents.Remove(sender.ID)) needref = true;
                }

                if (sender.DocStatus !== WFDocStatus.INSYSTEM) {
                    switch (sender.DocStatus) {
                        case WFDocStatus.BEFOREOCR:
                            if (!this.m_befocr_documents.Contains(sender.ID)) {
                                this.m_befocr_documents.Add(sender.ID);
                                needref = true;
                            }
                            break;

                        case WFDocStatus.INOCR:
                            if (!this.m_inocr_documents.Contains(sender.ID)) {
                                this.m_inocr_documents.Add(sender.ID);
                                needref = true;
                            }
                            break;

                        case WFDocStatus.AFTEROCR:
                            if (!this.m_aftocr_documents.Contains(sender.ID)) {
                                this.m_aftocr_documents.Add(sender.ID);
                                needref = true;
                            }
                            break;

                        case WFDocStatus.TEMPLATE:
                            if (!this.m_temp_documents.Contains(sender.ID)) {
                                this.m_temp_documents.Add(sender.ID);
                                needref = true;
                            }
                            break;
                    }
                }

                //przypnij wg stanow zatwierdzenia
                if (sender.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                    if (sender.CanApprove) {
                        //do zatwierdzenia
                        if (!this.m_toapproval_documents.Contains(sender.ID)) {
                            this.m_toapproval_documents.Add(sender.ID);
                            needref = true;
                        }
                    } else {
                        if (this.m_toapproval_documents.Remove(sender.ID)) needref = true;
                        //czy user zatwierdzil

                        if (sender.IsApprovedByMe) {
                            if (!this.m_approvedbyme_documents.Contains(sender.ID)) {
                                this.m_approvedbyme_documents.Add(sender.ID);
                                needref = true;
                            }
                        } else {
                            if (this.m_approvedbyme_documents.Remove(sender.ID)) needref = true;
                        }

                        //
                        if (sender.IsApprovedByOther) {
                            if (!this.m_approvedbyother_documents.Contains(sender.ID)) {
                                this.m_approvedbyother_documents.Add(sender.ID);
                                needref = true;
                            }
                        } else {
                            if (this.m_approvedbyother_documents.Remove(sender.ID)) needref = true;
                        }

                        //
                        if (sender.IsRejectedByMe) {
                            if (!this.m_rejectedbyme_documents.Contains(sender.ID)) {
                                this.m_rejectedbyme_documents.Add(sender.ID);
                                needref = true;
                            }
                        } else {
                            if (this.m_rejectedbyme_documents.Remove(sender.ID)) needref = true;
                        }

                        //
                        if (sender.IsRejectedByOther) {
                            if (!this.m_rejectedbyother_documents.Contains(sender.ID)) {
                                this.m_rejectedbyother_documents.Add(sender.ID);
                                needref = true;
                            }
                        } else {
                            if (this.m_rejectedbyother_documents.Remove(sender.ID)) needref = true;
                        }
                    }
                }

                if (needref) this.RefreshDirectoryTotals();

                if (needref2 !== this.m_fileselector_ctx.Contains(sender.ID)) this.FillFileSelector();

                //
                if(this.m_fileselector_ctx === this.m_filterlist && 
                   this.m_filterlist.Contains(sender.ID) &&
                   this.m_filtercfg != null)
                {                                        
                    let remove;
                    if(this.m_sourcefilterlist.Contains(sender.ID))
                    {
                        remove= false;                        

                        for(let flt of this.m_filtercfg) {
                            switch(flt.type)
                            {
                                case FltProperty.CLASS:
                                    if(flt.values.indexOf(sender.ClassID) < 0) remove= true;
                                    break;
                                case FltProperty.AUTHSCHEMA:
                                    if(flt.values.indexOf(sender.AuthSchemaID) < 0) remove= true;
                                    break;
                                case FltProperty.USER:
                                    if(flt.values.indexOf(sender.UserID) < 0) remove= true;
                                    break;
                                case FltProperty.STATUS:
                                    let ists= -1 - <number>sender.AuthStatus;
                                    if(flt.values.indexOf(sender.UserID) < 0) remove= true;
                                    break;
                                case FltProperty.COMPANY:
                                    if(flt.values.indexOf(sender.CompanyID) < 0) remove= true;
                                    break;
                                case FltProperty.OTHER:
                                    //pobranie wartosci minimalnej
                                    let now= new Date();
                                    now.setHours(0, 0, 0, 0);

                                    let cat= new Date(sender.CreatedAt.getFullYear(), 
                                                    sender.CreatedAt.getMonth(), 
                                                    sender.CreatedAt.getDate());
                                    cat.setHours(0, 0, 0, 0);
                                
                                    let tout= now.getTime() - cat.getTime();
                                    let days= tout / (24 * 60 * 60 * 1000);

                                    let val= Math.min(...flt.values); 
                                    switch(val) {
                                        case -1: //today
                                            if(days > 0) remove= true;
                                            break;

                                        case -2: //last 5 lays
                                            if(days > 5) remove= true;
                                            break;

                                        case -3: //last month
                                            if(days > 30) remove= true;
                                            break;
                                    }

                                    break;
                            }      
                            
                            if(remove) break;
                        }
                    } else {
                        remove= true;
                    }

                    if(remove) 
                    {
                        this.m_filterlist.Remove(sender.ID);
                        this.m_fileselector_cpage = 0;
                        this.FillFileSelector();
                    }                   
                }
            }


            if (this.m_publishall) {
                //znajdz kolejny dokument
                for (ii = 0; ii < this.m_cache_srv.GetDocumentsCount(); ii++) {
                    let adp: WFDocumentAdp = this.m_cache_srv.GetDocumentByOff(ii);
                    if (adp === sender) {
                        for (ii2 = ii + 1; ii2 < this.m_cache_srv.GetDocumentsCount(); ii2++) {
                            adp = this.m_cache_srv.GetDocumentByOff(ii2);
                            if (adp.IsModified) {
                                this.PublishLocalDoc(adp);
                                return;
                            }
                        }
                        break;
                    }
                }
            }

            //TODO
            /*if (!m_alertsexec)
            {
                m_alertsexec = true;
                m_serv.GetUserAlertsAsync(m_loginwnd.SessionID, false, false);
            } */
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    private OnRemoveDocument(sender: WFDocumentAdp, status: boolean, oldid: number): void {
        let ii: number;

        try {
            if (status) {
                let needref = false;

                //czysc katalogi
                for (let tvi of Array.from(this.m_all_companies.values())) {
                    let adp: WFCompanyAdp = <WFCompanyAdp>tvi.UserObj;
                    if (adp.Documents.Remove(oldid)) needref = true;
                }

                for (let tvi of Array.from(this.m_all_directories.values())) {
                    let adp: WFDirectoryAdp = <WFDirectoryAdp>tvi.UserObj;
                    if (adp.Documents.Remove(oldid)) needref = true;
                }

                if (this.m_befocr_documents.Remove(oldid)) needref = true;
                if (this.m_inocr_documents.Remove(oldid)) needref = true;
                if (this.m_aftocr_documents.Remove(oldid)) needref = true;
                if (this.m_temp_documents.Remove(oldid)) needref = true;

                if (this.m_removed_documents.Remove(oldid)) needref = true;
                if (this.m_observed_documents.Remove(oldid)) needref = true;
                if (this.m_favorite_documents.Remove(oldid)) needref = true;
                if (this.m_found_documents.Remove(oldid)) needref = true;

                if (this.m_toapproval_documents.Remove(oldid)) needref = true;
                if (this.m_approvedbyme_documents.Remove(oldid)) needref = true;
                if (this.m_approvedbyother_documents.Remove(oldid)) needref = true;
                if (this.m_rejectedbyme_documents.Remove(oldid)) needref = true;
                if (this.m_rejectedbyother_documents.Remove(oldid)) needref = true;

                if (this.m_recent_documents.Remove(oldid)) {
                    this.m_recent.Header = (this.m_recent_documents.Count > 0) ? IWFObject.Format(this.m_strdata_srv.getStr('strRecents') + ' [{0}]', this.m_recent_documents.Count).toUpperCase() : this.m_strdata_srv.getStr('strRecents').toUpperCase();
                }

                if(this.m_fileselector_ctx!= null) {
                    if (this.m_fileselector_ctx.Remove(oldid)) needref = true;
                }

                //czysc zalaczniki
                this.m_cache_srv.RemoveDocument(oldid);

                for (ii = 0; ii < this.m_cache_srv.GetDocumentsCount(); ii++) {
                    let doc: WFDocumentAdp = this.m_cache_srv.GetDocumentByOff(ii);
                    doc.RemoveAttachment(oldid);
                }

                for (ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                    let fdc: FavoriteDocument = this.m_cache_srv.User.FavoriteDocuments[ii];
                    if (fdc.DocumentID === oldid) {
                        this.m_cache_srv.User.FavoriteDocuments.splice(ii, 1);
                        break;
                    }
                }

                if (sender === this.m_selecteditem) {
                    //usun zakladke zawierajaca
                    let kv = this.FindTabIDForDocument(sender);
                    if (kv[0] >= 0) this.CloseDocumentTab(this.m_tabs[kv[0]]);

                    this.FillFileSelector();
                    this.m_selecteditem = null;
                }

                if (needref) this.RefreshDirectoryTotals();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }


    //

    private OnSelectDocument(sender: WFDocumentAdp, ntab: boolean): boolean {
        try {
            //if(sender.ReadOnly)
            this.SelectDocument(sender, (ntab || this.m_tabs.length === 0) ? TabOperation.NEW : TabOperation.ACTUAL);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        return false;
    }

    //

    private FindTabIDForDocument(doc: WFDocumentAdp): [number, DocviewerComponent] {
        let ii: number;

        let tabid = -1;
        let finddcv: DocviewerComponent = null;

        for (ii = 0; ii < this.m_tabs.length; ii++) {
            let itm: TabSpec = this.m_tabs[ii];
            if (itm.source != null) {
                if (itm.source === doc) {
                    tabid = ii;
                    finddcv = itm.docviewer;
                    break;
                }
            }
        }

        return [tabid, finddcv];
    }

    private CreateTabItem(label: string, src: object, ismod: boolean): TabSpec {
        return {
            title: label,
            source: src,
            docviewer: null,
            reportviewer: null,
            attachments: null,
            history: new Array<[object, Map<string, string>]>(),
            inargs: null,
            mailboxviewer: null,
            edocviewer: null
        };
    }

    public FindinHistory(itm: TabSpec, obj: object): number {
        for (let ii = 0; ii < itm.history.length; ii++) {
            let tst = itm.history[ii];
            if (tst[0] === obj) return ii;
        }
        return -1;
    }

    private TrimHistory(itm: TabSpec, obj: object): void {
        let off = this.FindinHistory(itm, obj);
        if (off >= 0 && off < itm.history.length) {
            off += 1;
            itm.history.splice(off, itm.history.length - off);
        }
    }

    private PutToHistory(itm: TabSpec, obj: object, inargs: Map<string, string>): void {
        let off = this.FindinHistory(itm, obj);
        if (off >= 0) itm.history.splice(off, 1);
        itm.history.push([obj, inargs]);
        if (itm.history.length > HISTORY_COUNT) itm.history.splice(0, 1);
    }

    public SelectDocument(doc: WFDocumentAdp, op: TabOperation): void {

        if (this.m_selecteditem !== doc) {
            if(doc.ReadOnly) {
                this.showInfo(this.m_strdata_srv.getStr('strNoRightsInfo'));
                return;
            }
            
            //aktualizacja wybranego dokumentu
            if (this.m_selecteditem != null) {
                if (!this.m_selecteditemmodstate && this.m_selecteditem.IsModified) {
                    const selitem = this.m_selecteditem;
                    const self = this;
                    this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.YESNOCANCEL, IWFObject.Format(this.m_strdata_srv.getStr('strDocumentUpdateAskInfo'), this.m_selecteditem.Name),
                        (wndres) => {
                            self.CheckPublichState(wndres, selitem);
                        });
                }
            }

            doc.OnError.subscribe2(this.m_onerrorevh);

            this.m_selecteditem = doc;
            this.m_selecteditemmodstate = doc.IsModified;

            //wyszukaj karty
            let kv = this.FindTabIDForDocument(doc);

            let finddcv: DocviewerComponent = kv[1];
            let tabid = kv[0];
            let itm: TabSpec = null;
            let lastsrc = (this.m_lastviewtab != null) ? this.m_lastviewtab.source : null;

            if (tabid < 0) {
                if (op === TabOperation.NEW) {
                    itm = this.CreateTabItem(doc.Name, doc, doc.IsModified);
                    this.m_tabs.push(itm);
                } else {
                    if (op === TabOperation.ACTUAL && this.m_tabs.indexOf(this.m_lastviewtab) >= 0) {
                        //tabid = this.m_lastviewtabid;
                        itm = this.m_lastviewtab;
                        itm.title = doc.Name;
                        itm.source = doc;
                    } else {
                        if (this.m_tabs.length === 0) {
                            itm = this.CreateTabItem(doc.Name, doc, doc.IsModified);
                            this.m_tabs.push(itm);
                        } else {
                            itm = this.m_tabs[0];
                            itm.title = doc.Name;
                            itm.source = doc;
                        }
                    }
                }
            } else {
                itm = this.m_tabs[tabid];
            }

            if (itm === this.m_lastviewtab && lastsrc === this.m_fileselector_src) this.TrimHistory(itm, this.m_fileselector_src);

            for (let tb of this.m_tabs) {
                let off = this.FindinHistory(tb, doc);
                if (off >= 0) tb.history.splice(off, 1);
            }

            this.PutToHistory(itm, doc, null);

            //this.CheckTabItemsVisibility();
            this.m_lastviewtab = itm;

            if (itm.docviewer != null) this.BindDocViewer(itm);
        }
    }

    private CheckPublichState(wndres: WNDRESULT, dadp: WFDocumentAdp): void {
        try {
            if (wndres !== WNDRESULT.CANCEL) {
                this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                if (wndres === WNDRESULT.OKYES) {
                    this.m_publishall = false;
                    this.PublishLocalDoc(dadp);
                } else {
                    dadp.OnLoadDocument = this.m_onloaddocument;
                    dadp.Reload(null);
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private OnLoadDocument(sender: WFDocumentAdp, status: boolean, uobj: Object): void {
        try {
            sender.OnLoadDocument = null;
            this.m_busy_text = '';
            if (status) {
                if (uobj == null) {
                    if (sender === this.m_selecteditem) {
                        this.FetchAttachments(sender);
                        this.m_selecteditemmodstate = this.m_selecteditem.IsModified;
                    }
                } else {
                    if (uobj instanceof TreeViewItem) this.MoveDocument(<TreeViewItem>uobj, sender);
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SelectDocumentTab(seltab: TabSpec): void {
        try {
           
            if (this.m_seltab !== seltab) {
                //
                this.ClearRightBar();

                if (this.m_seltab != null) {
                    if (this.m_seltab.docviewer != null) {
                        this.m_seltab.docviewer.OnContentTypeChanged = null;
                        this.m_seltab.docviewer.OnCommentModeChanged = null;
                        this.m_seltab.docviewer.OnPageChanged = null;
                        this.m_seltab.docviewer.OnClassChange = null;
                        this.m_seltab.docviewer.OnSelectDirectory = null;
                    }
                }

                this.m_seltab = seltab;
                this.m_lastviewtab = seltab;              

                if (seltab.source instanceof WFDocumentAdp) {
                    if (this.m_selecteditem != null) {
                        if (!this.m_selecteditemmodstate && this.m_selecteditem.IsModified) {
                            const selitem = this.m_selecteditem;
                            const self = this;
                            this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.YESNOCANCEL, IWFObject.Format(this.m_strdata_srv.getStr('strDocumentUpdateAskInfo'), this.m_selecteditem.Name),
                                (res) => {
                                    self.CheckPublichState(res, selitem);
                                });
                        }
                    }

                    this.m_selecteditem = <WFDocumentAdp>seltab.source;
                    if (this.m_selecteditem != null) {
                        this.m_selecteditemmodstate = this.m_selecteditem.IsModified;

                        seltab.docviewer.OnContentTypeChanged = this.m_ctxtypechgevh;
                        seltab.docviewer.OnCommentModeChanged = this.m_cmmodechgevh;
                        seltab.docviewer.OnPageChanged = this.m_pagechgevh;
                        seltab.docviewer.OnClassChange = this.m_classchgevh;
                        seltab.docviewer.OnSelectDirectory= this.m_seldirevh;

                        let adta: AttachmentsData = seltab.attachments;
                        this.FillRightBar(this.m_selecteditem, adta.atts, adta.prns, adta.invdc);
                        this.OnContentTypeChanged(seltab.docviewer, CONTENTTYPE.TYPE_UNKNOWN);
                    } else {
                        this.m_selecteditemmodstate = false;
                    }
                } else {                    
                    this.m_selecteditem = null;
                    this.m_selecteditemmodstate = false;
                    if(seltab.source instanceof WFMailFolderAdp) 
                        this.FillRightBar3(seltab.mailboxviewer);
                    else
                        this.FillRightBar2(<IWFDataFetchAdp>seltab.source);
                }

                //scrolowanie do zakladki
                this.SetScrollToActive(seltab);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //SK
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.SetScrolVisibility();
    }

    /*onResized(event: ResizedEvent): void {
        this.SetScrolVisibility();
    }*/

    //DM 29092022 powoduje problmey przy wpisywaniu wartosci w pola na formatce trzeba dodac filtr w definicji
    @HostListener('window:keydown.f1', ['$event'])
    keyEvent(event: KeyboardEvent) {
        if (event.keyCode === 112) {
            // przycisk f1
            let el = document.querySelectorAll(':hover');
            // szukanie
            for (let i = (el.length - 1); i > -1; i-- ) {
                let classname: string = el[i].className;
                let re = /\bhelp\_(\w*)\b/ig;
                if (re.test(classname)) {
                    let match = /\bhelp\_(\w*)\b/ig.exec(classname);
                    if (match != null && match.length > 1) {
                      let helpsection = match[1];
                      if (helpsection.length > 0) {
                        let win = window.open('https://altab.pl/Spuma/SPUMA_user_guide.html#' + helpsection.toLowerCase(), 'Spuma Help', 'height=600,width=800');
                        win.focus();
                      }
                      console.log(helpsection);
                    }
                    break;
                }
            }
            event.preventDefault();
        }
    }

    public SetScrollToActive(seltab: TabSpec): void {
        let divs = this.zakladki.nativeElement.children;
        for (let div of divs) {
            if (div.id === seltab.title) {
                const ofl = div.parentElement.scrollLeft;
                const pwidthall = div.parentElement.scrollWidth;
                const pwidth = div.parentElement.offsetWidth;
                let offs = div.offsetLeft;
                const dw = div.offsetWidth;
                // console.log('[SetScrollToActive] scrollLeft=' + ofl + ' offs=' + offs + ' scrollWidth=' + pwidthall + ' div.offsetWidth=' + dw + ' pwidth=' + pwidth );
                if (((offs + dw) >= (pwidth + ofl)) || (ofl > offs)) {
                    if ((offs + dw) < pwidth) {
                        offs = 0;
                    }
                    this.SetScrol(offs);
                } else {
                    this.SetScrol(this.zakladki.nativeElement.scrollLeft);
                }
                break;
            }
        }
    }

    public SetScrolVisibility(): void {
        let el = this.zakladki.nativeElement;
        const maxScrollLeft = el.scrollWidth - el.clientWidth;

        this.m_scrlvisible = false;
        this.m_scrrvisible = false;
        if (el.scrollWidth > el.offsetWidth) {
            if (el.scrollLeft < maxScrollLeft) {
                this.m_scrrvisible = true;
            }
            if (el.scrollLeft > 0) {
                this.m_scrlvisible = true;
            }
        }
    }
    public SetScrol(offs: number): void {
        let el = this.zakladki.nativeElement;
        // console.log('m_scrlvisible=' + this.m_scrlvisible + ' m_scrrvisible=' + this.m_scrrvisible + ' el.scrollLeft=' + el.scrollLeft + ' offs=' + offs + ' el.scrollWidth=' + el.scrollWidth + ' el.offsetWidth=' + el.offsetWidth + ' maxScrollLeft=' + maxScrollLeft);
        el.scrollLeft = offs;
        // console.log('[After] m_scrlvisible=' + this.m_scrlvisible + ' m_scrrvisible=' + this.m_scrrvisible + ' el.scrollLeft=' + el.scrollLeft + ' offs=' + offs + ' el.scrollWidth=' + el.scrollWidth + ' el.offsetWidth=' + el.offsetWidth + ' maxScrollLeft=' + maxScrollLeft);
        this.SetScrolVisibility();
    }

    public ScrollLeft() {
        const delta = 80;
        let el = this.zakladki.nativeElement;
        if ((el.scrollLeft - delta) <= 0) {
            this.SetScrol(0);
        } else {
            this.SetScrol(el.scrollLeft - delta);
        }
    }

    public ScrollRight() {
        const delta = 80;
        let el = this.zakladki.nativeElement;
        const maxScrollLeft = el.scrollWidth - el.clientWidth;
        if ((el.scrollLeft + delta) >= maxScrollLeft) {
            this.SetScrol(maxScrollLeft);
        } else {
            this.SetScrol(el.scrollLeft + delta);
        }
    }

    public ShowFilesOnRight(show: boolean): void {
        this.m_filesonright = show;
    }

    public CloseDocumentTab(tab: TabSpec): void {
        try {
            let off = this.m_tabs.indexOf(tab);
            this.m_tabs.splice(off, 1);
            if (this.m_seltab === tab && this.m_tabs.length > 0) {
                this.SelectDocumentTab(this.m_tabs[0]);
                return;
            }

            if (this.m_tabs.length === 0) {
                this.m_selecteditem = null;
                this.ClearRightBar();
                this.SetScrol(0);
            } else {
                this.SetScrollToActive(this.m_seltab);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private SelectPrevInTab(vv: [object, Map<string, string>], tab: TabSpec): void {
        if (tab.source !== vv[0]) {
            tab.source = vv[0];
            tab.inargs = vv[1];

            this.m_lastviewtab = tab;

            if (tab.source instanceof WFDocumentAdp) {
                let doc: WFDocumentAdp = <WFDocumentAdp>tab.source;

                tab.title = doc.Name;

                doc.OnError.subscribe2(this.m_onerrorevh);

                this.m_selecteditem = doc;
                this.m_selecteditemmodstate = doc.IsModified;

                if (tab.docviewer != null) this.BindDocViewer(tab);
                return;
            }

            if (tab.source instanceof IWFDataFetchAdp) {
                let rpt: IWFDataFetchAdp = <IWFDataFetchAdp>tab.source;

                tab.title = rpt.Name;

                //doc.OnError.subscribe2(this.m_onerrorevh);
                this.m_selecteditem = null;
                this.m_selecteditemmodstate = false;

                if (tab.reportviewer != null) this.BindReportViewer(tab);
                return;
            }

            if(tab.source instanceof WFMailFolderAdp) {
                let box: WFMailFolderAdp = <WFMailFolderAdp>tab.source;

                tab.title = box.Name;

                this.m_selecteditem = null;
                this.m_selecteditemmodstate = false;

                if (tab.mailboxviewer != null) this.BindMailBoxViewer(tab);
                return;
            }

            if(tab.source instanceof WFDocumentInterfaceAdp) {
                let doc: WFDocumentInterfaceAdp = <WFDocumentInterfaceAdp>tab.source;

                tab.title = doc.Name;

                this.m_selecteditem = null;
                this.m_selecteditemmodstate = false;

                if (tab.edocviewer != null) this.BindEDocViewer(tab);
            }
        }
    }

    private CanOpenInTab(obj: object): boolean {
        for (let tb of this.m_tabs) {
            if (tb.source === obj) return false;
        }
        return true;
    }

    public PrevHistory(tab: TabSpec): void {
        try {
            let off = this.FindinHistory(tab, tab.source);
            while (off > 0) {
                off--;
                let vv = tab.history[off];
                if (this.CanOpenInTab(vv[0])) {
                    this.SelectPrevInTab(vv, tab);
                    break;
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public NextHistory(tab: TabSpec): void {
        try {
            let off = this.FindinHistory(tab, tab.source);
            let cc = tab.history.length - 1;
            while (off < cc) {
                off++;
                let vv = tab.history[off];
                if (this.CanOpenInTab(vv[0])) {
                    this.SelectPrevInTab(vv, tab);
                    break;
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private BindDocViewer(tab: TabSpec): void {
        let doc: WFDocumentAdp = <WFDocumentAdp>tab.source;
        if (doc.IsModified) {
            this.FetchAttachments(doc);
        } else {
            doc.OnLoadDocument = this.m_onloaddocument;
            this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
            doc.Reload(null);
        }
    }

    public InitDocViewer(tab: TabSpec, ctrl: DocviewerComponent): void {
        try {
            tab.docviewer = ctrl;
            if (tab.source instanceof WFDocumentAdp) this.BindDocViewer(tab);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public TabIsDocument(tab: TabSpec): boolean {        
        return (tab.source instanceof WFDocumentAdp);
    }

    private FetchAttachments(doc: WFDocumentAdp): void {
        doc.OnError.subscribe2(this.m_onerrorevh);
        doc.OnFetchAttachmentsCompleted = this.m_onfetchattachments;
        this.m_busy_text = this.m_strdata_srv.getStr('strAttachmentsData');
        doc.FetchAttachments();
    }

    //

    private OnFetchAttachments(sender: WFDocumentAdp, atts: Array<WFDocumentAdp>, prns: Array<WFDocumentAdp>, invdc: Array<WFDocumentAdp>): void {
        try {
            this.m_busy_text = '';
        
            let dta: AttachmentsData = { atts: atts, prns: prns, invdc: invdc };
            this.m_lastviewtab.attachments = dta;

            this.m_lastviewtab.docviewer.TopBarVisibility = true;
            this.m_lastviewtab.docviewer.Document = sender;           

            if (this.m_seltab === this.m_lastviewtab) {
                this.ClearRightBar();
                this.FillRightBar(sender, atts, prns, invdc);
                this.OnContentTypeChanged(this.m_lastviewtab.docviewer, CONTENTTYPE.TYPE_UNKNOWN);
            } else {
                this.SelectDocumentTab(this.m_lastviewtab);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private set CommentVisibilityState(nval: number) {
        if (this.m_seltab != null) {
            let doc_preview: DocviewerComponent = this.m_seltab.docviewer;
            if (doc_preview != null) {
                doc_preview.OnCommentModeChanged = null;
                switch (nval % 3) {
                    case 0:
                        //this.m_commentsbtn = true;
                        this.m_commentsbtnMin = false;
                        this.m_commentsbtnHide = false;
                        //this.m_commentsbtnAdd = false;
                        doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_MAXIMALIZED;
                        doc_preview.AddCommentMode = ADDMODE.ADD_MOVE;
                        break;
                    case 1:
                        //this.m_commentsbtn = false;
                        this.m_commentsbtnMin = true;
                        this.m_commentsbtnHide = false;
                        //this.m_commentsbtnAdd = false;
                        doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_MINIMALIZED;
                        break;
                    case 2:
                        //this.m_commentsbtn = false;
                        this.m_commentsbtnMin = false;
                        this.m_commentsbtnHide = true;
                        //this.m_commentsbtnAdd = false;
                        doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_HIDDEN;
                        break;
                    //case 3:
                    //this.m_commentsbtn = false;
                    //this.m_commentsbtnMin = false;
                    //this.m_commentsbtnHide = false;
                    //this.m_commentsbtnAdd = true;
                    //doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_MAXIMALIZED;

                    //this.m_textregionsbtn = true;
                    //this.m_textregionsbtnOff = false;
                    //doc_preview.DefTextRegionsVisibility = false;

                    //doc_preview.AddCommentMode = ADDMODE.ADD_COMMENT;
                    //break;
                }
                doc_preview.OnCommentModeChanged = this.m_cmmodechgevh;
            }
        }
    }

    private get CommentVisibilityState(): number {
        if (!this.m_commentsbtnMin && !this.m_commentsbtnHide) return 0;
        if (this.m_commentsbtnMin && !this.m_commentsbtnHide) return 1;
        return 2;

        //if (this.m_commentsbtn) return 0;
        //if (this.m_commentsbtnMin) return 1;
        //if (this.m_commentsbtnHide) return 2;
        //return 3;
    }

    private set TextRegionsVisibilityState(value: number) {
        if (this.m_seltab != null) {
            let doc_preview: DocviewerComponent = this.m_seltab.docviewer;
            if (doc_preview != null) {
                doc_preview.OnCommentModeChanged = null;
                switch (value % 2) {
                    case 0:
                        //this.m_textregionsbtn = true;
                        this.m_textregionsbtnOff = false;
                        doc_preview.DefTextRegionsVisibility = false;
                        doc_preview.AddCommentMode = ADDMODE.ADD_MOVE;
                        break;

                    case 1:
                        //this.m_textregionsbtn = false;
                        this.m_textregionsbtnOff = true;

                        doc_preview.DefTextRegionsVisibility = true;
                        //this.m_commentsbtn = false;
                        this.m_commentsbtnMin = false;
                        this.m_commentsbtnHide = true;
                        this.m_commentsbtnAdd = false;

                        doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_HIDDEN;
                        doc_preview.AddCommentMode = ADDMODE.ADD_OCRREGION;
                        break;
                }
                doc_preview.OnCommentModeChanged = this.m_cmmodechgevh;
            }
        }
    }

    private get TextRegionsVisibilityState(): number {
        //if (this.m_textregionsbtn) return 0;
        if (!this.m_textregionsbtnOff) return 0;
        return 1;
    }

    //

    private ClearRightBar(): void {
        this.m_editpanel = false;
        this.m_optionspanel = false;
        this.m_optionsbtn = false;
        this.m_attachmentsbtn = false;
        this.m_attachments.splice(0, this.m_attachments.length);

        this.m_favoritesubpanel1 = false;
        this.m_favoritesubpanel2 = false;
        this.m_reloadbtn = false;
        this.m_pubilshbtn = false;
        this.m_pageimportpanel = false;

        this.m_pagecutpanel = false;
        this.m_pagepastepanel = false;
        this.m_pagereplacepanel = false;
        this.m_savetodiskpanel = false;
        this.m_importpagefromfile = false;
        this.m_cutcurrentpage = false;
        this.m_chgdocstatuspanel1 = false;
        this.m_chgdocstatuspanel2 = false;
        this.m_chgdocstatuspanel3 = false;
        this.m_deletedocumentpanel = false;
        this.m_historysubpanel1 = false;
        this.m_refreshreportpanel = false;
        this.m_expandgroups = false;
        this.m_collapsegroups = false;
        this.m_adddailycorrpanel = false;
        this.m_printreport = false;
        this.m_copyreport = false;

        this.m_markasseen= false;
        this.m_unmarkseen= false;
        this.m_showunread= ButtonState.HIDDEN;
        this.m_showunbind= ButtonState.HIDDEN;

        this.m_addtodailycorrpanel = false;
        this.m_addnewdailycorrpanel = false;

        this.m_ocrlogssubpanel = false;

        this.m_textregionsbtn = false;
        this.m_textregionsbtnOff = false;

        this.m_commentsbtn = false;
        this.m_commentsbtnMin = false;
        this.m_commentsbtnHide = false;
        this.m_commentsbtnAdd = false;

        this.m_docmenu = false;

        this.m_dailycorrefsbtn = false;
        this.m_dailycorrrefs.splice(0, this.m_dailycorrrefs.length);

        //this.CommentVisibilityState = 0;
        this.m_addattachmentbtn = false;
        this.m_attachmentspop = false;
    }

    private FillRightBar3(mbxvw: MailBoxViewerComponent): void {
        this.m_refreshreportpanel = true;
        this.m_expandgroups = true;
        this.m_collapsegroups = true;
        this.m_printreport = this.m_copyreport= false;
        this.m_editpanel = false;
        this.m_attachmentspop = false; 
        let visbtns= (mbxvw.PreviewMsg!= null); 
        this.m_markasseen= visbtns;       
        this.m_unmarkseen= visbtns;
        this.m_showunread= (mbxvw.Only_UnRead) ? ButtonState.ACTIVE : ButtonState.VISIBLE;
        this.m_showunbind= (mbxvw.Only_UnBind) ? ButtonState.ACTIVE : ButtonState.VISIBLE;  
    }

    private FillRightBar4(mbxvw: EDocViewerComponent): void {
        this.m_refreshreportpanel = true;
        this.m_expandgroups = true;
        this.m_collapsegroups = true;
        this.m_printreport = this.m_copyreport= false;
        this.m_editpanel = false;
        this.m_attachmentspop = false; 
        //let visbtns= (mbxvw.PreviewMsg!= null); 
        this.m_markasseen= false;       
        this.m_unmarkseen= false;
        this.m_showunread= ButtonState.HIDDEN;// (mbxvw.Only_UnRead) ? ButtonState.ACTIVE : ButtonState.VISIBLE;
        this.m_showunbind= (mbxvw.Only_UnBind) ? ButtonState.ACTIVE : ButtonState.VISIBLE;  
    }

    private FillRightBar2(snd: IWFDataFetchAdp): void {
        this.m_refreshreportpanel = true;
        this.m_expandgroups = true;
        this.m_collapsegroups = true;
        this.m_printreport = this.m_copyreport= true;
        this.m_editpanel = true;
        this.m_attachmentspop = false;
        if (snd instanceof WFDailyCorrespondenceClassAdp) {
            let dcorr = <WFDailyCorrespondenceClassAdp>snd;
            this.m_adddailycorrpanel = dcorr.CanAddNewEntry;
        }
    }

    private FillRightBar(sender: WFDocumentAdp, atts: Array<WFDocumentAdp>, prns: Array<WFDocumentAdp>, invdc: Array<WFDocumentAdp>): void {
        let ii: number;

        this.m_editpanel = false;

        this.m_optionspanel = true;
        this.m_optionsbtn = true;

        this.m_attachmentsbtn = true;
        this.m_attachments.splice(0, this.m_attachments.length);

        this.m_reloadbtn = true;
        this.m_pubilshbtn = true;

        //ulubione i obserwowane
        this.m_favoritesubpanel1 = true;
        this.m_favoritesubpanel2 = false;
        this.m_observedsubpanel1 = true;
        this.m_observedsubpanel2 = false;
        if (this.m_cache_srv.DailyCorrepondenceClasses.size > 0) {
            this.m_addtodailycorrpanel = true;
            this.m_addnewdailycorrpanel = true;
        } else {
            this.m_addtodailycorrpanel = false;
            this.m_addnewdailycorrpanel = false;
        }

        for (ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
            let fdoc: FavoriteDocument = this.m_cache_srv.User.FavoriteDocuments[ii];
            if (fdoc.DocumentID === sender.ID) {
                switch (fdoc.Type) {
                    case FavoriteType.TYPE_FAVORITE:
                        this.m_favoritesubpanel1 = false;
                        this.m_favoritesubpanel2 = true;
                        //this.m_observedsubpanel1 = false;
                        //this.m_observedsubpanel2 = true;

                        break;
                    case FavoriteType.TYPE_OBSERVED:
                        this.m_observedsubpanel1 = false;
                        this.m_observedsubpanel2 = true;
                        //this.m_favoritesubpanel1 = false;
                        //this.m_favoritesubpanel2 = true;
                        break;
                }
            }
        }

        //zalaczniki


        //uzupelnij zalaczniki
        for (let doc of atts) {
            this.m_attachments.push({
                title: doc.Name,
                doc: doc
            });
        }

        /*if (invdc.Length > 0)
        {
            Rectangle recttmp = new Rectangle();
            recttmp.HorizontalAlignment = System.Windows.HorizontalAlignment.Stretch;
            recttmp.Height = 1;
            recttmp.StrokeThickness = 1;
            recttmp.Stroke = (Brush)Application.Current.Resources["MAIN_NORMAL"];
            recttmp.Margin = new Thickness(0, 3, 0, 0);
            m_attachments.Children.Add(recttmp);

            //uzupelnij niewidoczne zalaczniki
            for (ii = 0; ii < invdc.Length; ii++)
            {
                AttachmentAdp aadp = new AttachmentAdp(invdc[ii], AttAdpStyle.INVISIBLE);
                aadp.MouseLeftButtonDown += m_onselectinvattachment;
                m_attachments.Children.Add(aadp);
            }
        }*/


        //dodawanie strony z pliku
        if (sender.CanEditPages) {
            this.m_pageimportpanel = true;
            this.m_pagecutpanel = true;
            this.m_pagepastepanel = true;
        } else {
            this.m_pageimportpanel = false;
            this.m_pagecutpanel = false;
            this.m_pagepastepanel = false;
        }

        if (sender.CanReplaceResource) {
            this.m_pagereplacepanel = true;
        } else {
            this.m_pagereplacepanel = false;
        }

        this.m_chgdocstatuspanel1 = false;
        this.m_deletedocumentpanel = false;
        this.m_refreshreportpanel = false;
        this.m_expandgroups = false;
        this.m_collapsegroups = false;
        this.m_printreport = false;
        this.m_copyreport= false;
        this.m_markasseen = false;
        this.m_unmarkseen = false;
        this.m_showunread = ButtonState.HIDDEN;
        this.m_showunbind= ButtonState.HIDDEN;

        this.m_adddailycorrpanel = false;
        this.m_chgdocstatuspanel2 = false;
        this.m_chgdocstatuspanel3 = false;

        if (sender.CanEditBeforeSystem) {
            switch (sender.DocStatus) {
                case WFDocStatus.BEFOREOCR:
                    this.m_chgdocstatuspanel1 = true;
                    this.m_deletedocumentpanel = true;
                    this.m_editpanel = true;
                    this.m_optionspanel = false;
                    this.m_chgdocstatuspanel3 = true;
                    break;

                case WFDocStatus.AFTEROCR:
                    this.m_chgdocstatuspanel2 = true;
                    this.m_chgdocstatuspanel3 = true;
                    this.m_deletedocumentpanel = true;
                    break;

                case WFDocStatus.TEMPLATE:
                    this.m_deletedocumentpanel = true;
                    break;
            }
        }

        //historia
        this.m_historysubpanel1 = (sender.GetModificationsCount() > 0);

        this.m_textregionsbtn = (sender.GetOcrResultsCount() > 0);

        //ostatnio obserwowane
        if (this.m_cache_srv.User.RecentMax > 0) {
            if (!this.m_recent_documents.Contains(sender.ID)) {
                this.m_recent_documents.Add(sender.ID);
                if (this.m_recent_documents.Count > this.m_cache_srv.User.RecentMax) {
                    this.m_recent_documents.Remove(this.m_recent_documents.get(0));
                }
                this.m_recent.Header = IWFObject.Format(this.m_strdata_srv.getStr('strRecents') + ' [{0}]', this.m_recent_documents.Count).toUpperCase();
                if (this.m_fileselector_ctx === this.m_recent_documents) this.FillFileSelector();
            }
        }

        //
        if (atts.length > 0 || invdc.length > 0 || prns.length > 0) {
            this.m_attachmentsbtn = true;
        } else {
            this.m_attachmentsbtn = false;
        }

        this.m_importpagefromfile = sender.CanAddPage;

        if (this.m_pageimportpanel ||
            this.m_pagecutpanel ||
            this.m_pagepastepanel ||
            this.m_pagereplacepanel ||
            this.m_deletedocumentpanel ||
            this.m_chgdocstatuspanel1 ||
            this.m_chgdocstatuspanel2 ||
            this.m_chgdocstatuspanel3 ||
            this.m_savetodiskpanel ||
            this.m_importpagefromfile ||
            this.m_cutcurrentpage) {
            this.m_editpanel = true;
        } else {
            this.m_editpanel = false;
        }

        this.m_ocrlogssubpanel = (sender.NewOcrLogs > 0);

        this.CommentVisibilityState = 0;
        this.m_docmenu = true;

        this.m_dailycorrrefs.splice(0, this.m_dailycorrrefs.length);
        if (sender.DailyCorRefs.length > 0) {
            this.m_dailycorrefsbtn = true;
            for (let lnk of sender.DailyCorRefs) this.AddlocalDailyCorRefInfo(lnk);
        } else {
            this.m_dailycorrefsbtn = false;
        }

        let attid = (sender.ClassID > 0) ? this.m_cache_srv.Classes.get(sender.ClassID).AttachmentsClassID : 0;
        this.m_addattachmentbtn = (attid > 0);
    }

    private AddlocalDailyCorRefInfo(lnk: DailyCorRefInfo): void {
        
        let name;
        let canjump;

        if(this.m_cache_srv.DailyCorrepondenceClasses.has(lnk.dailycorrclass_id)) {
            name= this.m_cache_srv.DailyCorrepondenceClasses.get(lnk.dailycorrclass_id).Name;
            canjump= true;
        } else {
            name= lnk.clsname;
            canjump= false;    
        }

        this.m_dailycorrrefs.push({
            title: IWFObject.Format('{0} {1}', WFDocumentAdp.DateTimeToStr(lnk.opdate, this.m_strdata_srv), name),
            info: lnk,
            canjump: canjump
        });
    }

    //

    private CommentModeChanged(doc_preview: DocviewerComponent, oldst: ADDMODE): void {
        switch (doc_preview.AddCommentMode) {
            case ADDMODE.ADD_COMMENT:
                this.m_textregionsbtn = true;
                doc_preview.DefTextRegionsVisibility = false;
                break;

            case ADDMODE.ADD_OCRREGION:
                this.m_commentsbtn = false;
                this.m_commentsbtnMin = false;
                this.m_commentsbtnHide = true;
                this.m_commentsbtnAdd = false;

                doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_HIDDEN;
                break;

            default:
                if (oldst === ADDMODE.ADD_OCRREGION) {
                    doc_preview.OnCommentModeChanged = null;
                    doc_preview.AddCommentMode = ADDMODE.ADD_OCRREGION;
                    doc_preview.OnCommentModeChanged = this.m_cmmodechgevh;
                } else {
                    this.m_commentsbtn = true;
                    this.m_commentsbtnMin = false;
                    this.m_commentsbtnHide = false;
                    this.m_commentsbtnAdd = false;

                    doc_preview.DefCommentVisibilityMode = COMMENTSMODE.MODE_MAXIMALIZED;
                    this.m_textregionsbtn = true;
                    doc_preview.DefTextRegionsVisibility = false;
                }
                break;
        }
    }

    private OnContentTypeChanged(doc_preview: DocviewerComponent, ctp: CONTENTTYPE): void {
        switch (doc_preview.ContentType) {
            case CONTENTTYPE.TYPE_PDF:
            case CONTENTTYPE.TYPE_IMAGE:
                this.m_commentsbtn = (doc_preview.Document.DocStatus != WFDocStatus.INOCR);
                this.m_textregionsbtn = (doc_preview.Document.GetOcrResultsCount() > 0);
                this.m_savetodiskpanel = false;
                break;
            default:
                this.m_commentsbtn = false;
                this.m_textregionsbtn = false;
                this.m_savetodiskpanel = (doc_preview.ContentType === CONTENTTYPE.TYPE_UNKNOWN);
                break;
        }
    }

    private OnPageChanged(doc_preview: DocviewerComponent, cpg: number): void {
        let pg = doc_preview.Document.GetPage(cpg);
        this.m_cutcurrentpage = doc_preview.Document.CanCutPage(pg);
    }

    private OnClassChanged(doc_preview: DocviewerComponent, class_id: number): void {
        try {
            let attid = (class_id > 0) ? this.m_cache_srv.Classes.get(class_id).AttachmentsClassID : 0;
            this.m_addattachmentbtn = (attid > 0);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //right bar TODO

    public ShowAttachmentsPanel(event: any): void {
        try {
            if (this.m_seltab != null) {
                let trg = event.target;

                const self = this;
                const wnd = this.m_wnd_srv.showControl(AttachmentsPopupComponent);
                const inst: AttachmentsPopupComponent = wnd.instance;
                inst.SetPrms(trg, this.m_seltab.attachments);

                inst.closePopup.subscribe(() => {
                    wnd.destroy();
                });

                inst.selectAttachment.subscribe((doc) => {
                    wnd.destroy();
                    self.OnSelectDocument(doc, true);
                });

                inst.removeAttachment.subscribe((doc) => {
                    wnd.destroy();
                    self.RemoveAttachmentAsk(doc);
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private RemoveAttachmentAsk(doc: WFDocumentAdp): void {
        const self = this;
        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strRemoveFromAttachments'), (ret) => {
            if (ret === WNDRESULT.OKYES) self.RemoveAttachment(doc);
        });
    }

    private RemoveAttachment(doc: WFDocumentAdp): void {
        try {
            if (this.m_seltab.source === this.m_selecteditem) {
                this.m_selecteditem.RemoveAttachment(doc.ID);
                for (let ii = 0; ii < this.m_attachments.length; ii++) {
                    let attinf = this.m_attachments[ii];
                    if (attinf.doc === doc) {
                        this.m_attachments.splice(ii, 1);
                        break;
                    }
                }
                let off = this.m_seltab.attachments.atts.indexOf(doc);
                if (off >= 0) this.m_seltab.attachments.atts.splice(off, 1);

                off = this.m_seltab.attachments.invdc.indexOf(doc);
                if (off >= 0) this.m_seltab.attachments.invdc.splice(off, 1);

                if (this.m_attachments.length === 0) this.m_attachmentsbtn = false;
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowOptionPanel(): void {
        this.m_editpanel = false;
        this.m_optionspanel = true;
    }

    // public AddCommentsMode(): void {
    //   try {
    //       this.CommentVisibilityState = 3;
    //   }
    // } catch (ex) {
    //     this.m_global_srv.manageException(ex);
    // }

    public SwitchCommentsMode(): void {
        try {
            this.CommentVisibilityState += 1;
            /*if (this.CommentVisibilityState === 0) {
                this.CommentVisibilityState = 3;
            } else if (this.CommentVisibilityState === 3) {
                this.CommentVisibilityState = 2;
            } else {
                this.CommentVisibilityState = 0;
            }*/
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowAllComments(sender: number): void {
        try {
            //0=min,1=hide,2=add
            this.CommentVisibilityState = sender;
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public AddNewComment(): void {
        try {
            if (this.m_selecteditem != null && this.m_seltab != null) {
                this.m_seltab.docviewer.AddNewCommentEntry();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowTextRegions(): void {
        try {
            this.TextRegionsVisibilityState += 1;
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public StoreDocument(): void {
        try {
            if (this.m_selecteditem != null) {
                const self = this;
                const wnd = this.m_wnd_srv.showControl(DownloadDocumentComponent);
                wnd.instance.SetPrms(this.m_selecteditem);
                wnd.instance.onClosed.subscribe(() => {
                    wnd.destroy();
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public PrintDocument(): void {
        try {
            if (this.m_selecteditem != null && this.m_seltab != null && this.m_seltab.docviewer.Document === this.m_selecteditem) {

                let ahtmlctx = this.m_seltab.docviewer.GetActualHtml();
                if (ahtmlctx != null && this.m_selecteditem.GetPagesCount() === 1) {
                    //this.m_selecteditem.
                    let htmlctx = IWFObject.Format('<html><head><meta charset="utf-8"><title>{0}</title><style>{1}</style></head><body>{2}</body></html>', this.m_selecteditem.Name, ahtmlctx[1], ahtmlctx[0]);

                    let blob = new Blob(['\ufeff' + htmlctx], { type: 'text/html;charset=utf-8;' });
                    let slnk = document.createElement('a');
                    slnk.setAttribute('target', '_blank');
                    slnk.setAttribute('href', URL.createObjectURL(blob));
                    slnk.style.visibility = 'hidden';
                    document.body.appendChild(slnk);
                    slnk.click();
                    document.body.removeChild(slnk);
                    return;
                }

                const self = this;
                const wnd = this.m_wnd_srv.showControl(DocumentPrintComponent);
                wnd.instance.SetPrms(this.m_selecteditem, (ahtmlctx != null) ? ahtmlctx[0] : '');
                wnd.instance.onClosed.subscribe(() => {
                    wnd.destroy();
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowHistory(): void {
        try {
            if (this.m_selecteditem != null) {
                const self = this;
                const wnd = this.m_wnd_srv.showControl(DocumentModificationsComponent);
                wnd.instance.SetPrms(this.m_selecteditem);
                wnd.instance.onClosed.subscribe(() => {
                    wnd.destroy();
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public AddToFavorite(): void {
        try {
            if (this.m_selecteditem != null) {
                for (let ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                    let fdoc = this.m_cache_srv.User.FavoriteDocuments[ii];
                    if ((fdoc.DocumentID === this.m_selecteditem.ID) && (fdoc.Type === FavoriteType.TYPE_FAVORITE)) return;
                }

                this.m_cache_srv.User.FavoriteDocuments.push(new FavoriteDocument(this.m_selecteditem.ID, FavoriteType.TYPE_FAVORITE));
                this.ModifyUserData();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public RemoveFromFavorite(): void {
        try {
            if (this.m_selecteditem != null) {
                for (let ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                    let fdoc = this.m_cache_srv.User.FavoriteDocuments[ii];
                    if ((fdoc.DocumentID === this.m_selecteditem.ID) && (fdoc.Type === FavoriteType.TYPE_FAVORITE)) {
                        this.m_cache_srv.User.FavoriteDocuments.splice(ii, 1);
                        this.ModifyUserData();
                        break;
                    }
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public AddToObserved(): void {
        try {
            if (this.m_selecteditem != null) {
                for (let ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                    let fdoc = this.m_cache_srv.User.FavoriteDocuments[ii];
                    if ((fdoc.DocumentID === this.m_selecteditem.ID) && (fdoc.Type === FavoriteType.TYPE_OBSERVED)) return;
                }

                this.m_cache_srv.User.FavoriteDocuments.push(new FavoriteDocument(this.m_selecteditem.ID, FavoriteType.TYPE_OBSERVED));
                this.ModifyUserData();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public RemoveFromObserved(): void {
        try {
            if (this.m_selecteditem != null) {
                for (let ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                    let fdoc = this.m_cache_srv.User.FavoriteDocuments[ii];
                    if ((fdoc.DocumentID === this.m_selecteditem.ID) && (fdoc.Type === FavoriteType.TYPE_OBSERVED)) {
                        this.m_cache_srv.User.FavoriteDocuments.splice(ii, 1);
                        this.ModifyUserData();
                        break;
                    }
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private AddDailyCorRef(sadp: WFDocumentAdp, dc: WFDailyCorrespondenceAdp): void {
        let inf: DailyCorRefInfo = { dailycorr_id: dc.ID, 
                                     dailycorrclass_id: dc.DailyCorrClassID, 
                                     linkdate: new Date(), 
                                     opdate: dc.OperationDate,
                                     clsname: this.m_cache_srv.DailyCorrepondenceClasses.get(dc.DailyCorrClassID).Name  
                                    };
        sadp.DailyCorRefs.push(inf);
        this.AddlocalDailyCorRefInfo(inf);
        this.m_dailycorrefsbtn = true;
    }

    public AddToDailyCorr(dc: WFDailyCorrespondenceAdp): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    let sadp = <WFDocumentAdp>this.m_seltab.source;
                    if (dc.HasReference(sadp.ID)) {
                        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strAlreadyExists'));
                        return;                        
                    } 

                    if(!dc.CanAddReference) {
                        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNoRightsInfo'));
                        return;
                    }

                    this.AddDailyCorRef(sadp, dc);
                    dc.AddReference(WFDailyCorrespondenceRef.Create(sadp.ID, this.m_cache_srv.User.ID));
                    dc.OnError.subscribe2(this.m_onerrorevh);
                    dc.OnPublishDailyCorrespondence = this.m_publishdcorr;
                    this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                    dc.Publish();
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private OnPublishDailyCorrespondence(snd: WFDailyCorrespondenceAdp, status: boolean): void {
        this.m_busy_text = '';
        if (!status) this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strUnableToSaveFile'));
    }

    public AddToNewDailyCorr(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    let sadp = <WFDocumentAdp>this.m_seltab.source;
                    let clsid = (this.m_cache_srv.DailyCorrepondenceClasses.size > 0) ? Array.from(this.m_cache_srv.DailyCorrepondenceClasses.values())[0].ID : 0;

                    let nentry = WFDailyCorrespondence.Create(clsid, this.m_cache_srv.User.ID);
                    nentry.CompanyID = sadp.CompanyID;
                    nentry.References.push(WFDailyCorrespondenceRef.Create(sadp.ID, this.m_cache_srv.User.ID));

                    let entryadp = new WFDailyCorrespondenceAdp(this.m_cache_srv, nentry);

                    const self = this;
                    const wnd = this.m_wnd_srv.showControl(EditDailyCorrespondenceComponent);
                    wnd.instance.SetPrms(entryadp);
                    wnd.instance.onClosed.subscribe((ret) => {
                        wnd.destroy();
                        self.AddToNewDailyCorrClosed(ret, sadp, wnd.instance);
                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private AddToNewDailyCorrClosed(ret: boolean, sadp: WFDocumentAdp, ctrl: EditDailyCorrespondenceComponent) {
        try {
            if (ret) {
                //this.m_lastdailycorrs.push(ctrl.Entry);
                this.AddDailyCorRef(sadp, ctrl.Entry);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public AddToDailyCorrByID(): void {
        try {
            if (this.m_seltab != null && this.m_global_srv.Copied_dailyenttry != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    this.AddToDailyCorr(this.m_global_srv.Copied_dailyenttry);

                    /*let sadp = <WFDocumentAdp>this.m_seltab.source;

                  const self = this;
                  this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                  this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [this.m_dcorrentry_id], this.m_onerrorevh, (dcs) => {
                      self.GetDailyCorrespondencesCompleted(dcs);
                  });*/


                    this.m_global_srv.Copied_dailyenttry = null;
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }


    //TODO
    public ShowOcrLogs(): void {
        try {

        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowEditPanel(): void {
        this.m_editpanel = true;
        this.m_optionspanel = false;
    }

    public ImportPageFromFile(): void {
        try {
            if (this.m_seltab.source === this.m_selecteditem) this.AddFromFiles(0, this.m_selecteditem);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private CutPage2(sadp: WFDocumentAdp): void {
        this.m_cut_page = sadp.GetPage(this.m_seltab.docviewer.CurPage);
        sadp.RemovePage(this.m_seltab.docviewer.CurPage);
    }

    public CutPage(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp && this.m_seltab.docviewer != null) {
                    const sadp = <WFDocumentAdp>this.m_seltab.source;
                    if (this.m_seltab.docviewer.CurPage < sadp.GetPagesCount()) {
                        if (sadp.DocStatus !== WFDocStatus.BEFOREOCR) {
                            const self = this;
                            this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strResourceChanging'), (res) => {
                                try {
                                    if (res === WNDRESULT.OKYES) this.CutPage2(sadp);
                                } catch (ex) {
                                    self.m_global_srv.manageException(ex);
                                }
                            });
                            return;
                        }

                        this.CutPage2(sadp);
                    }
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public CutCurrPage(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp && this.m_seltab.docviewer != null) {
                    const sadp = <WFDocumentAdp>this.m_seltab.source;
                    if (this.m_seltab.docviewer.CurPage < sadp.GetPagesCount()) this.CutPage2(sadp);
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public PastePage(): void {
        try {
            if (this.m_seltab != null && this.m_cut_page !== null) {
                if (this.m_seltab.source instanceof WFDocumentAdp && this.m_seltab.docviewer != null) {
                    const sadp = <WFDocumentAdp>this.m_seltab.source;

                    if (sadp.DocStatus !== WFDocStatus.BEFOREOCR) {
                        const self = this;

                        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strResourceChanging'), (res) => {
                            try {
                                if (res === WNDRESULT.OKYES) {
                                    sadp.AddPage(self.m_cut_page);
                                    self.m_cut_page = null;
                                }
                            } catch (ex) {
                                self.m_global_srv.manageException(ex);
                            }
                        });

                        return;
                    }

                    sadp.AddPage(this.m_cut_page);
                    this.m_cut_page = null;
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SendToOCR(): void {
        try {
            if (this.m_selecteditem != null) {
                this.m_publishall = false;
                this.m_selecteditem.DocStatus = WFDocStatus.INOCR;
                this.PublishLocalDoc(this.m_selecteditem);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SaveAsTemplate(): void {
        try {
            if (this.m_selecteditem != null) {
                const self = this;
                const wnd = this.m_wnd_srv.showControl(TemplateNameComponent);
                wnd.instance.SetPrms(WFTmpWriteMode.DENYALL);
                wnd.instance.onClosed.subscribe((ret) => {
                    wnd.destroy();
                    if (ret) self.SaveAsTemplateClosed(wnd.instance);
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private SaveAsTemplateClosed(snd: TemplateNameComponent): void {
        try {
            this.m_selecteditem.DocStatus = WFDocStatus.TEMPLATE;
            this.m_selecteditem.Name = snd.TmpName;
            this.m_selecteditem.TemplateWriteMode = snd.WriteMode;
            this.m_publishall = false;
            this.PublishLocalDoc(this.m_selecteditem);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public DeleteDocument(): void {
        try {
            if (this.m_selecteditem != null) {
                const self = this;
                this.m_global_srv.showWarning(BUTTONSTYPE.YESNO, IWFObject.Format(this.m_strdata_srv.getStr('strRemoveDocumentAskInfo') + ' {0}?', this.m_selecteditem.Name), (res) => {
                    if (res === WNDRESULT.OKYES) self.DeleteDocument2();
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private DeleteDocument2(): void {
        try {
            this.m_busy_text = IWFObject.Format(this.m_strdata_srv.getStr('strRemovingDocumentInfo') + ' {0}', this.m_selecteditem.Name);
            this.m_selecteditem.OnRemoveDocument = this.m_onremovedocument;
            this.m_selecteditem.Remove();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public MoveToCompany(): void {
        try {
            const self = this;
            this.m_global_srv.showInfo(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strMoveDocToCmpAskInfo'), (res) => {
                if (res === WNDRESULT.OKYES) self.MoveDocToCompanyClosed();
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private CreateDirectoryFromPath(pth: string[], cmp_id: number, dir_id: number, adp: WFDocumentAdp, publocal: boolean): void {
        let dirname = pth[0];
        pth.splice(0, 1);
        //

        let dir = WFDirectory.Create(dirname, '');
        dir.CompanyID = cmp_id;
        dir.DirectoryID = dir_id;

        this.m_busy_text = IWFObject.Format(this.m_strdata_srv.getStr('strFolderAddedInfo2'), dirname);

        const self = this;
        this.m_data_srv.modifyDirectory(this.m_cache_srv.SessionID, dir, true, this.m_onerrorevh, (ndir) => {
            try {
                let dadp = new WFDirectoryAdp(ndir, self.m_cache_srv);
                dadp.OnError.subscribe2(self.m_onerrorevh);
                dadp.OnPublishDirectory = self.m_onpublishdirectory;
                dadp.OnRemoveDirectory = self.m_onremovedirectory;

                let ntvi = new TreeViewItem();
                ntvi.Header = dadp.DirName.toUpperCase();
                ntvi.UserObj = dadp;
                self.m_all_directories.set(dadp.ID, ntvi);

                if (dadp.DirectoryID > 0)
                    self.m_all_directories.get(dadp.DirectoryID).Items.push(ntvi);
                else
                    self.m_all_companies.get(dadp.CompanyID).Items.push(ntvi);

                this.m_cache_srv.Directories.set(dadp.ID, dadp);

                if (pth.length > 0) {
                    self.CreateDirectoryFromPath(pth, cmp_id, dadp.ID, adp, publocal);
                } else {
                    self.m_busy_text = '';
                    self.RefreshDirectoryTotals();
                    self.SetDirectoryID(dadp.ID, adp, publocal);
                }
            } catch (ex) {
                self.m_global_srv.manageException(ex);
            }
        });
    }

    private SetDirectoryID(dir_id: number, adp: WFDocumentAdp, publocal: boolean): void {
        try {
            //this.m_selecteditem.DirectoryID = dir_id;
            adp.DirectoryID= dir_id;           
            if(publocal) {
                this.m_publishall = false;
                this.PublishLocalDoc(adp);//this.m_selecteditem);
            } else {
                adp.Publish();
            }
        }
        catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private BindDirectoryIDAndPublish(adp:WFDocumentAdp, publocal: boolean): void {
        let ii: number;
        let cmp = this.m_cache_srv.Companies.get(adp.CompanyID);

        if (adp.ClassID > 0) {
            let cls = this.m_cache_srv.Classes.get(adp.ClassID);
            if (IWFObject.IsNullOrEmpty(cls.NewDocPath)) {
                adp.DirectoryID = 0;
            } else {
                let pth = adp.PrepareDocPath(cls.NewDocPath);
                let prntid = 0;
                let alltvi = Array.from(this.m_all_directories.values());
                let needcreate = -1;

                for (ii = 0; ii < pth.length; ii++) {
                    let upth = pth[ii].toUpperCase();
                    let fdir: WFDirectoryAdp = null;

                    for (let tvi of alltvi) {
                        let dir = <WFDirectoryAdp>tvi.UserObj;
                        if (dir.CompanyID === adp.CompanyID &&
                            dir.DirectoryID === prntid &&
                            dir.DirName.toUpperCase() === upth) {
                            fdir = dir;
                            break;
                        }
                    }

                    if (fdir == null) {
                        needcreate = ii;
                        break;
                    }

                    prntid = fdir.ID;
                }

                if (needcreate >= 0) {
                    const self = this;

                    let cmpadp = <WFCompanyAdp>this.m_all_companies.get(adp.CompanyID).UserObj;
                    if (cmpadp.CanCreateDirectory(this.m_cache_srv)) {
                        if (needcreate > 0) pth.splice(0, needcreate);
                        this.CreateDirectoryFromPath(pth, adp.CompanyID, prntid, adp, publocal);
                    } else {
                        this.m_global_srv.showWarning(BUTTONSTYPE.YESNO, IWFObject.Format(this.m_strdata_srv.getStr('strNewDocPath'), pth.join('\\')), (ret) => {
                            if (ret === WNDRESULT.OKYES) self.SetDirectoryID(cmp.DirectoryID, adp, publocal);
                        });
                    }

                    return;
                }

                adp.DirectoryID = prntid;
            }
        } else {
            adp.DirectoryID = cmp.DirectoryID;
        }
        
        if(publocal) {
            this.m_publishall = false;
            this.PublishLocalDoc(adp);//this.m_selecteditem);
        } else {
            adp.Publish();
        }
    }

    private MoveDocToCompanyClosed(): void {
        let ii: number;

        try {
            if (this.m_selecteditem != null) {
                //let cmp = this.m_cache_srv.Companies.get(this.m_selecteditem.CompanyID);
                this.m_selecteditem.DocStatus = WFDocStatus.INSYSTEM;

                /*if (this.m_selecteditem.ClassID > 0) {
                    let cls = this.m_cache_srv.Classes.get(this.m_selecteditem.ClassID);
                    if (IWFObject.IsNullOrEmpty(cls.NewDocPath)) {
                        this.m_selecteditem.DirectoryID = 0;
                    } else {
                        let pth = this.m_selecteditem.PrepareDocPath(cls.NewDocPath);
                        let prntid = 0;
                        let alltvi = Array.from(this.m_all_directories.values());
                        let needcreate = -1;

                        for (ii = 0; ii < pth.length; ii++) {
                            let upth = pth[ii].toUpperCase();
                            let fdir: WFDirectoryAdp = null;

                            for (let tvi of alltvi) {
                                let dir = <WFDirectoryAdp>tvi.UserObj;
                                if (dir.CompanyID === this.m_selecteditem.CompanyID &&
                                    dir.DirectoryID === prntid &&
                                    dir.DirName.toUpperCase() === upth) {
                                    fdir = dir;
                                    break;
                                }
                            }

                            if (fdir == null) {
                                needcreate = ii;
                                break;
                            }

                            prntid = fdir.ID;
                        }

                        if (needcreate >= 0) {
                            const self = this;

                            let cmpadp = <WFCompanyAdp>this.m_all_companies.get(this.m_selecteditem.CompanyID).UserObj;
                            if (cmpadp.CanCreateDirectory(this.m_cache_srv)) {
                                if (needcreate > 0) pth.splice(0, needcreate);
                                this.CreateDirectoryFromPath(pth, this.m_selecteditem.CompanyID, prntid);
                            } else {
                                this.m_global_srv.showWarning(BUTTONSTYPE.YESNO, IWFObject.Format(this.m_strdata_srv.getStr('strNewDocPath'), pth.join('\\')), (ret) => {
                                    if (ret === WNDRESULT.OKYES) self.SetDirectoryID(cmp.DirectoryID);
                                });
                            }

                            return;
                        }

                        this.m_selecteditem.DirectoryID = prntid;
                    }
                } else {
                    this.m_selecteditem.DirectoryID = cmp.DirectoryID;
                }

                this.m_publishall = false;
                this.PublishLocalDoc(this.m_selecteditem);*/

                this.BindDirectoryIDAndPublish(this.m_selecteditem, true);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //raporty

    public RefreshReport(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof IWFDataFetchAdp && this.m_seltab.reportviewer != null) {
                    this.m_seltab.reportviewer.RefreshData();
                    return;
                }

                if (this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                    this.m_seltab.mailboxviewer.RefreshData();
                    return;
                }

                if (this.m_seltab.source instanceof WFDocumentInterfaceAdp && this.m_seltab.edocviewer != null) {
                    this.m_seltab.edocviewer.RefreshData();
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ExpandGroups(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof IWFDataFetchAdp && this.m_seltab.reportviewer != null) {
                    this.m_seltab.reportviewer.ExpandGroups();
                    return;
                }

                if (this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                    this.m_seltab.mailboxviewer.ExpandGroups();
                    return;
                }

                if (this.m_seltab.source instanceof WFDocumentInterfaceAdp && this.m_seltab.edocviewer != null) {
                    this.m_seltab.edocviewer.ExpandGroups();
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public CollapseGroups(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof IWFDataFetchAdp && this.m_seltab.reportviewer != null) {
                    this.m_seltab.reportviewer.CollapseGroups();
                    return;
                }

                if (this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                    this.m_seltab.mailboxviewer.CollapseGroups();
                    return;
                }

                if (this.m_seltab.source instanceof WFDocumentInterfaceAdp && this.m_seltab.edocviewer != null) {
                    this.m_seltab.edocviewer.CollapseGroups();
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public MarkAsSeen(): void {
        try {
            if (this.m_seltab != null && this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                this.m_seltab.mailboxviewer.MarkSeenFlag(true);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public UnMarkSeen(): void {
        try {
            if (this.m_seltab != null && this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                this.m_seltab.mailboxviewer.MarkSeenFlag(false);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowUnRead(): void {
        try {
            if (this.m_seltab != null && this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                let nval= (this.m_showunread== ButtonState.VISIBLE);
                this.m_seltab.mailboxviewer.Only_UnRead= nval;
                this.m_seltab.mailboxviewer.RefreshData();
                this.m_showunread= (nval) ? ButtonState.ACTIVE : ButtonState.VISIBLE;
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ShowUnBind(): void {        
        try {
            if (this.m_seltab != null && this.m_seltab.source instanceof WFMailFolderAdp && this.m_seltab.mailboxviewer != null) {
                let nval= (this.m_showunbind== ButtonState.VISIBLE);
                this.m_seltab.mailboxviewer.Only_UnBind= nval;
                this.m_seltab.mailboxviewer.RefreshData();
                this.m_showunbind= (nval) ? ButtonState.ACTIVE : ButtonState.VISIBLE;
                return;
            }

            if (this.m_seltab != null && this.m_seltab.source instanceof WFDocumentInterfaceAdp && this.m_seltab.edocviewer != null) {
                let nval= (this.m_showunbind== ButtonState.VISIBLE);
                this.m_seltab.edocviewer.Only_UnBind= nval;
                this.m_seltab.edocviewer.RefreshData();
                this.m_showunbind= (nval) ? ButtonState.ACTIVE : ButtonState.VISIBLE;
            }

        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public AddDailyCorr(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDailyCorrespondenceClassAdp && this.m_seltab.reportviewer != null) {
                    let sadp = <WFDailyCorrespondenceClassAdp>this.m_seltab.source;

                    let nentry = WFDailyCorrespondence.Create(sadp.ID, this.m_cache_srv.User.ID);
                    if (this.m_cache_srv.User.CompanyPermissionType === PrmType.TYPE_ALLOWFROM && this.m_cache_srv.User.CompanyPermissions.length > 0) {
                        nentry.CompanyID = this.m_cache_srv.User.CompanyPermissions[0];
                    } else {
                        nentry.CompanyID = Array.from(this.m_cache_srv.Companies.values())[0].ID;
                    }

                    let entryadp = new WFDailyCorrespondenceAdp(this.m_cache_srv, nentry);

                    const self = this;
                    const wnd = this.m_wnd_srv.showControl(EditDailyCorrespondenceComponent);
                    wnd.instance.SetPrms(entryadp);
                    wnd.instance.onClosed.subscribe((ret) => {
                        wnd.destroy();
                        self.AddNewDailyCorrClosed(ret, wnd.instance);
                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private AddNewDailyCorrClosed(ret: boolean, dlg: EditDailyCorrespondenceComponent): void {
        try {
            if (ret && dlg.NeedRefreshAfterClose) {
                //this.RemoveRecentDailyCorr(dlg.Entry);
                if (this.m_seltab.reportviewer != null) this.m_seltab.reportviewer.RefreshData();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public PrintReport(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof IWFDataFetchAdp && this.m_seltab.reportviewer != null) {                    
                    const self = this;
                    const wnd = this.m_wnd_srv.showControl(PrintreporttaskComponent);
                    wnd.instance.SetPrms(<IWFDataFetchAdp>this.m_seltab.source);
                    wnd.instance.onClosed.subscribe(() => {
                        wnd.destroy();
                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public CopyReport(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof IWFDataFetchAdp && this.m_seltab.reportviewer != null) {                    
                    this.m_seltab.reportviewer.CopyToClipboard();
                    
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public PublishChanges(): void {
        try {
            if (this.GetIsModified()) this.PublishAll();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public ReloadDocument(): void {
        try {           
            if (this.m_selecteditem != null) {
                if (this.m_selecteditem.IsModified) {
                    const self = this;
                    this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strReloadAskInfo'), (res) => {
                        self.ReloadAskClosed(res);
                    });
                } else {
                    this.m_selecteditem.OnLoadDocument = this.m_onloaddocument;
                    this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                    this.m_selecteditem.Reload(null);
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private ReloadAskClosed(res: WNDRESULT): void {
        try {
            if (res === WNDRESULT.OKYES) {
                this.m_selecteditem.OnLoadDocument = this.m_onloaddocument;
                this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                this.m_selecteditem.Reload(null);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //reports

    private FindTabIDForReport(rpt: IWFDataFetchAdp): [number, ReportViewerComponent] {
        let ii: number;

        let tabid = -1;
        let finddcv: ReportViewerComponent = null;

        for (ii = 0; ii < this.m_tabs.length; ii++) {
            let itm: TabSpec = this.m_tabs[ii];
            if (itm.source != null) {
                if (itm.source === rpt) {
                    tabid = ii;
                    finddcv = itm.reportviewer;
                    break;
                }
            }
        }

        return [tabid, finddcv];
    }

    public SelectReport(rpt: IWFDataFetchAdp, ntab: boolean, inargs: Map<string, string>): void {
        let ii: number;

        //aktualizacja wybranego dokumentu
        if (this.m_selecteditem != null) {
            if (!this.m_selecteditemmodstate && this.m_selecteditem.IsModified) {
                const selitem = this.m_selecteditem;
                const self = this;
                this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.YESNOCANCEL, IWFObject.Format(this.m_strdata_srv.getStr('strDocumentUpdateAskInfo'), this.m_selecteditem.Name),
                    (wndres) => {
                        self.CheckPublichState(wndres, selitem);
                    });
            }
        }

        this.m_selecteditem = null;
        this.m_selecteditemmodstate = false;

        //wyszukaj karty
        let kv = this.FindTabIDForReport(rpt);

        let finddcv: ReportViewerComponent = kv[1];
        let tabid = kv[0];
        let itm: TabSpec = null;
        let lastsrc = (this.m_lastviewtab != null) ? this.m_lastviewtab.source : null;

        if (tabid < 0) {
            if (ntab) {
                itm = this.CreateTabItem(rpt.Name, rpt, false);
                this.m_tabs.push(itm);
            } else {
                if (this.m_tabs.indexOf(this.m_lastviewtab) >= 0) {
                    //tabid = this.m_lastviewtabid;
                    itm = this.m_lastviewtab;
                    itm.title = rpt.Name;
                    itm.source = rpt;
                } else {
                    if (this.m_tabs.length === 0) {
                        itm = this.CreateTabItem(rpt.Name, rpt, false);
                        this.m_tabs.push(itm);
                    } else {
                        itm = this.m_tabs[0];
                        itm.title = rpt.Name;
                        itm.source = rpt;
                    }
                }
            }
        } else {
            itm = this.m_tabs[tabid];
        }

        if (itm === this.m_lastviewtab && lastsrc === this.m_fileselector_src) this.TrimHistory(itm, this.m_fileselector_src);
        itm.inargs = inargs;

        for (let tb of this.m_tabs) {
            let off = this.FindinHistory(tb, rpt);
            if (off >= 0) tb.history.splice(off, 1);
        }

        this.PutToHistory(itm, rpt, inargs);

        this.m_lastviewtab = itm;
        this.m_seltab = itm;

        if (itm.reportviewer != null) this.BindReportViewer(itm);
    }

    private BindReportViewer(tab: TabSpec): void {
        let rpt: IWFDataFetchAdp = <IWFDataFetchAdp>tab.source;
        tab.reportviewer.SetReportSource(rpt, tab.inargs);
        this.ClearRightBar();
        this.FillRightBar2(rpt);
    }

    public InitReportViewer(tab: TabSpec, ctrl: ReportViewerComponent): void {
        try {
            tab.reportviewer = ctrl;
            if (tab.source instanceof IWFDataFetchAdp) this.BindReportViewer(tab);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public TabIsReport(tab: TabSpec): boolean {
        return (tab.source instanceof IWFDataFetchAdp);
    }

    public GetDirectory(id: number): WFDirectoryAdp {
        return <WFDirectoryAdp>this.m_all_directories.get(id).UserObj;
    }

    // SK

    public FillBreadCrumb(obj: object): void {
        this.m_selecteddirlist = new Array<BreadCrumbsItem>();
        let itm: BreadCrumbsItem;
  
        //pusty
        if (obj == null) {
          return;
        }
  
        //Inne
        if (obj instanceof TreeViewItem){
          let tvi: TreeViewItem = <TreeViewItem>obj;
          itm = { val: tvi, desc: tvi.Header.toUpperCase(), id: 0, islink: false };
          this.m_selecteddirlist.push(itm);
          return;
        }
  
        // Katalogi
        if (obj instanceof WFDirectoryAdp) {
          let adp: WFDirectoryAdp = <WFDirectoryAdp>obj;
          itm = { val: adp, desc: adp.DirName.toUpperCase(), id: adp.ID, islink: true };
          this.m_selecteddirlist.push(itm);
          if (adp.DirectoryID > 0) {
            let adpdir: WFDirectoryAdp = <WFDirectoryAdp>this.m_all_directories.get(adp.DirectoryID).UserObj;
            itm = { val: adpdir, desc: adpdir.DirName.toUpperCase(), id: adpdir.ID, islink: true };
            this.m_selecteddirlist.push(itm);
            while (adpdir.DirectoryID > 0) {
              adpdir = <WFDirectoryAdp>this.m_all_directories.get(adpdir.DirectoryID).UserObj;
              itm = { val: adpdir, desc: adpdir.DirName.toUpperCase(), id: adpdir.ID, islink: true };
              this.m_selecteddirlist.push(itm);
            }
          }
          itm = {val: this.m_selectedcmp, desc: this.m_selectedcmp.Company.toUpperCase(), id: this.m_selectedcmp.ID, islink: true};
          this.m_selecteddirlist.push(itm);
          this.m_selecteddirlist = this.m_selecteddirlist.reverse();
          return;
        }
  
        // Firmy
        if (obj instanceof WFCompanyAdp) {
          itm = {val: this.m_selectedcmp, desc: this.m_selectedcmp.Company.toUpperCase(), id: this.m_selectedcmp.ID, islink: false};
          this.m_selecteddirlist.push(itm);
          return;
        }
  
        //DM 14092022 znalezione dokumenty
        if(obj == this.m_found_documents) {
            itm = {val: this.m_found_documents, desc: IWFObject.Format(this.m_strdata_srv.getStr('fldSearchResult').toUpperCase() + ' [{0}]', this.m_found_documents.Count), id: 0, islink: false};
            this.m_selecteddirlist.push(itm);
            return;
        }  
      }
  
      public ChooseDir(dir: BreadCrumbsItem): void {
        // Katalogi
  
        this.m_fileselector_cpage = 0;
  
        if (dir.val instanceof WFDirectoryAdp) {
          let adp: WFDirectoryAdp = <WFDirectoryAdp>dir.val;
          this.m_selectedcmp = <WFCompanyAdp>this.m_all_companies.get(adp.CompanyID).UserObj;
          this.m_selecteddir = adp;
          this.FillBreadCrumb(adp);
          this.ShowDocumentList(adp.Documents, null);
          return;
        }
  
        // Firmy
        if (dir.val instanceof WFCompanyAdp) {
          let adp: WFCompanyAdp = <WFCompanyAdp>dir.val;
          this.m_selectedcmp = adp;
          this.m_selecteddir = null;
          this.FillBreadCrumb(adp);
          this.ShowDocumentList(adp.Documents, null);
          return;
        }
      }
  
  
      // SK END
  

    public OnItemMouseDown(doc: Object, event: MouseEvent): void {
        if (event.button === 0) {           
            this.m_dragdrop_popup_vis = false;
            this.m_dragdrop = {
                source: doc,
                left: event.clientX,
                top: event.clientY
            };

            this.m_mousemovebnd = this.OnDocumentMouseMove.bind(this);
            this.m_mouseupbnd = this.OnDocumentMouseUp.bind(this);

            const self = this;
            this.m_zone.runOutsideAngular(() => {
                window.document.addEventListener('mousemove', self.m_mousemovebnd);
            });

            window.document.addEventListener('mouseup', this.m_mouseupbnd);
        }
    }

    public OnDocumentMouseMove(event: MouseEvent): void {
        try {
            event.preventDefault();

            if (!this.m_dragdrop_popup_vis) {
                let dx = this.m_dragdrop.left - event.clientX;
                let dy = this.m_dragdrop.top - event.clientY;
                let len = Math.sqrt(dx * dx + dy * dy);

                if (len > 10) {
                    const self = this;
                    this.m_zone.run(() => {
                        self.m_dragdrop_popup_vis = true;
                        self.m_dragdrop_popup_img = (self.m_dragdrop.source instanceof TreeViewItem) ? 'cmn_folderchild.svg' : 'document_48.png';
                    });
                }
            }

            if (this.m_dragdrop_popup_vis) {
                this.m_dragdrop.left = event.clientX;
                this.m_dragdrop.top = event.clientY;
                this.m_render.setStyle(this.m_dragdrop_popup.nativeElement, 'left', this.m_dragdrop.left + 'px');
                this.m_render.setStyle(this.m_dragdrop_popup.nativeElement, 'top', this.m_dragdrop.top + 'px');
            }
        } catch (ex) {
            console.log(ex.message);
        }
    }

    private getUserObject(ele: any): Object {
        if ('UserObject' in ele) return ele.UserObject;
        while (ele.parentElement != null) {
            ele = ele.parentElement;
            if ('UserObject' in ele) return ele.UserObject;
        }
        return null;
    }

    public OnDocumentMouseUp(event: MouseEvent): void {
        //this.m_moving = false;
        try {
            event.preventDefault();

            if (this.m_mousemovebnd != null) {
                window.document.removeEventListener('mousemove', this.m_mousemovebnd);
                this.m_mousemovebnd = null;
            }

            if (this.m_mouseupbnd != null) {
                window.document.removeEventListener('mouseup', this.m_mouseupbnd);
                this.m_mouseupbnd = null;
            }

            if (this.m_dragdrop != null) {
                let srcobj = this.m_dragdrop.source;
                this.m_dragdrop = null;

                if (this.m_dragdrop_popup_vis) {
                    this.m_dragdrop_popup_vis = false;

                    this.m_render.setStyle(this.m_dragdrop_popup.nativeElement, 'left', '0px');
                    this.m_render.setStyle(this.m_dragdrop_popup.nativeElement, 'top', '0px');

                    let ele = document.elementFromPoint(event.clientX, event.clientY);
                    if (ele == null) return;

                    let userobj = this.getUserObject(ele);
                    if (userobj == null) return;

                    let doc: WFDocumentAdp = null;

                    //przenoszenie dokumentu
                    if (srcobj instanceof WFDocumentAdp) {
                        doc = <WFDocumentAdp>srcobj;
                    } else {
                        if ('docviewer' in srcobj) {
                            let tab = <TabSpec>srcobj;
                            if (tab.source instanceof WFDocumentAdp) {
                                doc = <WFDocumentAdp>tab.source;
                            }
                        }
                    }

                    if (doc != null) {
                        if (doc.IsModified) {
                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strFirstSaveDataInfo'));
                            return;
                        }

                        //modyfikacja
                        if (doc.DocStatus === WFDocStatus.INOCR) {
                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCannotMoveDocuments'));
                            return;
                        }

                        //nowe walidacje
                        if (doc.DirectoryID < 0) {
                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentAlreadyRemoved'));
                            return;
                        }

                        if (userobj instanceof TreeViewItem) {
                            let tvi = <TreeViewItem>userobj;

                            if (doc.GetPagesCount() === 0) {
                                doc.OnLoadDocument = this.m_onloaddocument;
                                this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                                doc.Reload(tvi);
                            } else {
                                this.MoveDocument(tvi, doc);
                            }

                            return;
                        }

                        if ('docviewer' in userobj) {
                            let tab = <TabSpec>userobj;
                            if (tab.source instanceof WFDocumentAdp) {
                                let selecteditem = <WFDocumentAdp>tab.source;

                                if (selecteditem === doc) {
                                    this.FetchAttachments(doc);
                                } else {
                                    if (!selecteditem.ContainsAttachment(doc.ID)) {
                                        if (selecteditem.IsModified) {
                                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strFirstSaveDataInfo'));
                                            return;
                                        }

                                        if (selecteditem.DocStatus === WFDocStatus.INOCR) {
                                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCannotMoveDocuments'));
                                            return;
                                        }

                                        if (selecteditem.DirectoryID < 0) {
                                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentAlreadyRemoved'));
                                            return;
                                        }

                                        if (selecteditem.CompanyID !== doc.CompanyID) {
                                            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentDiffCompany'));
                                            return;
                                        }

                                        //czy napewno
                                        const atts = (this.m_seltab !== tab) ? tab.attachments : null;
                                        const self = this;
                                        this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strDocAttachAskInfo'), (ret) => {
                                            if (ret === WNDRESULT.OKYES) {
                                                self.BindAttAskClosed(doc, selecteditem);
                                                if (atts != null) atts.atts.push(doc);
                                            }
                                        });
                                    }
                                }

                            }
                        }

                        return;
                    }
                    //

                    if (srcobj instanceof TreeViewItem &&
                        userobj instanceof TreeViewItem) {

                        let curtvi = <TreeViewItem>srcobj;
                        let tvi = <TreeViewItem>userobj;

                        if (srcobj !== userobj && curtvi.UserObj instanceof WFDirectoryAdp &&
                            (tvi.UserObj instanceof WFCompanyAdp || tvi.UserObj instanceof WFDirectoryAdp)) {

                            let sender = <WFDirectoryAdp>curtvi.UserObj;
                            if (sender.CanMoveDirectory) {
                                if (this.CanChangeDirectory(sender.CompanyID)) {

                                    let prntvi = (sender.DirectoryID > 0) ? this.m_all_directories.get(sender.DirectoryID) : this.m_all_companies.get(sender.CompanyID);

                                    if (tvi !== prntvi) {
                                        if (tvi.UserObj instanceof WFCompanyAdp) {
                                            let adp = <WFCompanyAdp>tvi.UserObj;
                                            if (sender.CompanyID !== adp.ID) {
                                                this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCannotMoveDocumentsComp'));
                                                return;
                                            }
                                            sender.DirectoryID = 0;
                                        } else {
                                            let adp = <WFDirectoryAdp>tvi.UserObj;
                                            if (sender.CompanyID !== adp.CompanyID) {
                                                this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCannotMoveDocumentsComp'));
                                                return;
                                            }

                                            //czy adp nie jest synem sender
                                            let tadp= adp;
                                            while(tadp.DirectoryID > 0) {
                                                if(tadp.DirectoryID == sender.ID) {
                                                    this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCannotMoveDirectory'));
                                                    return;    
                                                }
                                                tadp= <WFDirectoryAdp>this.m_all_directories.get(tadp.DirectoryID).UserObj;
                                            }

                                            sender.DirectoryID = adp.ID;
                                        }

                                        //publikacja zmian
                                        this.m_busy_text = this.m_strdata_srv.getStr('strFolderUpdateInfo');
                                        this.m_publishall = false;
                                        sender.Publish();
                                    }

                                    return;
                                } else {
                                    this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNoRightsInfo'));
                                }
                            } else {
                                this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNoRightsInfo'));
                            }

                        }

                        return;
                    }

                } else {
                    if (srcobj instanceof WFDocumentAdp) {
                        this.OnSelectDocument(<WFDocumentAdp>srcobj, false);
                        return;
                    }

                    if (srcobj instanceof TreeViewItem) {
                        this.SelectDirectory(<TreeViewItem>srcobj);
                        return;
                    }

                    if ('docviewer' in srcobj) {
                        this.SelectDocumentTab(<TabSpec>srcobj);
                        return;
                    }
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private BindAttAskClosed(doc: WFDocumentAdp, selecteditem: WFDocumentAdp): void {
        try {
            this.m_publishall = false;
            selecteditem.AddAttachment(doc.ID);
            this.PublishLocalDoc(selecteditem);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private MoveDocument(itm: TreeViewItem, doc: WFDocumentAdp): void {
        if (doc.CanMove) {
            let info = '';
            if (itm.UserObj instanceof WFDirectoryAdp) info = (<WFDirectoryAdp>itm.UserObj).DirName;
            if (itm.UserObj instanceof WFCompanyAdp) info = (<WFCompanyAdp>itm.UserObj).Company;

            const self = this;
            this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, IWFObject.Format(this.m_strdata_srv.getStr('strMoveDocumentAskInfo'), info), (ret) => {
                if (ret === WNDRESULT.OKYES) self.MoveDocAskClosed(itm, doc);
            });
        } else {
            this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNoRights4DocMoveInfo'));
        }
    }

    //

    private MoveDocAskClosed(itm: TreeViewItem, doc: WFDocumentAdp): void {
        try {
            if (itm.UserObj instanceof WFCompanyAdp) {
                let adp = <WFCompanyAdp>itm.UserObj;
                if (!adp.Documents.Contains(doc.ID)) {
                    //do firmy
                    doc.DirectoryID = 0;
                    doc.CompanyID = adp.ID;
                    doc.DocStatus = WFDocStatus.INSYSTEM;
                }
            } else {
                if (itm.UserObj instanceof WFDirectoryAdp) {
                    let adp = <WFDirectoryAdp>itm.UserObj;
                    if (!adp.Documents.Contains(doc.ID)) {
                        //do katalogu
                        doc.DirectoryID = adp.ID;
                        doc.CompanyID = adp.CompanyID;
                        doc.DocStatus = WFDocStatus.INSYSTEM;
                    }
                } else {
                    if (itm === this.m_trash) {
                        //do kosza
                        doc.DirectoryID = -1;
                        doc.DocStatus = WFDocStatus.INSYSTEM;
                    } else {
                        if (itm === this.m_observed) {
                            if (!this.m_observed_documents.Contains(doc.ID)) {
                                doc.DocStatus = WFDocStatus.INSYSTEM;
                                this.m_cache_srv.User.FavoriteDocuments.push(new FavoriteDocument(doc.ID, FavoriteType.TYPE_OBSERVED));
                                this.ModifyUserData();
                            }
                        } else {
                            if (itm === this.m_favorite) {
                                if (!this.m_favorite_documents.Contains(doc.ID)) {
                                    doc.DocStatus = WFDocStatus.INSYSTEM;
                                    this.m_cache_srv.User.FavoriteDocuments.push(new FavoriteDocument(doc.ID, FavoriteType.TYPE_FAVORITE));
                                    this.ModifyUserData();
                                }
                            } else {
                                return;
                            }
                        }
                    }
                }
            }

            //
            if (doc.IsModified) {
                this.m_publishall = false;
                this.PublishLocalDoc(doc);
            }

        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private ModifyUserData(): void {
        const self = this;
        this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
        this.m_data_srv.modifyUser(this.m_cache_srv.SessionID, this.m_cache_srv.User, this.m_onerrorevh, (usr) => {
            self.ModifyUserCompleted(usr);
        });
    }

    //

    private ModifyUserCompleted(usr: WFUser): void {
        let ii: number;

        try {
            this.m_cache_srv.User = usr;

            if (this.m_selecteditem != null) {
                this.m_favoritesubpanel1 = true;
                this.m_favoritesubpanel2 = false;
                this.m_observedsubpanel1 = true;
                this.m_observedsubpanel2 = false;

                for (ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                    let fdoc = this.m_cache_srv.User.FavoriteDocuments[ii];
                    if (fdoc.DocumentID === this.m_selecteditem.ID) {
                        switch (fdoc.Type) {
                            case FavoriteType.TYPE_FAVORITE:
                                this.m_favoritesubpanel1 = false;
                                this.m_favoritesubpanel2 = true;
                                break;
                            case FavoriteType.TYPE_OBSERVED:
                                this.m_observedsubpanel1 = false;
                                this.m_observedsubpanel2 = true;
                                break;
                        }
                    }
                }
            }

            this.m_observed_documents.Clear();
            this.m_favorite_documents.Clear();

            for (ii = 0; ii < this.m_cache_srv.User.FavoriteDocuments.length; ii++) {
                let fdoc = this.m_cache_srv.User.FavoriteDocuments[ii];
                if (this.m_cache_srv.ContainsDocument(fdoc.DocumentID)) {
                    switch (fdoc.Type) {
                        case FavoriteType.TYPE_OBSERVED:
                            if (!this.m_observed_documents.Contains(fdoc.DocumentID)) {
                                this.m_observed_documents.Add(fdoc.DocumentID);
                            }
                            break;

                        case FavoriteType.TYPE_FAVORITE:
                            if (!this.m_favorite_documents.Contains(fdoc.DocumentID)) {
                                this.m_favorite_documents.Add(fdoc.DocumentID);
                            }
                            break;
                    }

                }
            }

            this.RefreshObservedInfo();
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    public ShowDailyCorRefsPanel(event: any): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    let trg = event.target;

                    const self = this;
                    const wnd = this.m_wnd_srv.showControl(DailyCorRefsPopupComponent);
                    const inst: DailyCorRefsPopupComponent = wnd.instance;
                    inst.SetPrms(trg, this.m_dailycorrrefs);

                    inst.closePopup.subscribe(() => {
                        wnd.destroy();
                    });

                    inst.selectDailyCorRef.subscribe((lnk) => {
                        wnd.destroy();
                        self.LoadDailyCorRef(lnk, null);
                    });

                    inst.removeFromDailyCor.subscribe((lnk) => {
                        wnd.destroy();
                        self.RemoveFromDailyCorAsk(lnk, <WFDocumentAdp>this.m_seltab.source);
                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private RemoveFromDailyCorAsk(lnk: DailyCorRefInfo, doc: WFDocumentAdp): void {
        const self = this;
        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strRemoveFromDailyCorr'), (ret) => {
            if (ret === WNDRESULT.OKYES) self.LoadDailyCorRef(lnk, doc);
        });
    }

    private LoadDailyCorRef(lnk: DailyCorRefInfo, doc: WFDocumentAdp): void {
        try {
            /*for (let ldc of this.m_lastdailycorrs) {
                if (ldc.ID === lnk.dailycorr_id) {
                    this.GetDailyCorrespondencesCompleted2(ldc, doc);
                    return;
                }
            }*/

            const self = this;
            this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
            this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [lnk.dailycorr_id], this.m_onerrorevh, (ret) => {
                let adp = new WFDailyCorrespondenceAdp(self.m_cache_srv, ret[0]);
                self.GetDailyCorrespondencesCompleted2(adp, doc);
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private GetDailyCorrespondencesCompleted2(adp: WFDailyCorrespondenceAdp, doc: WFDocumentAdp): void {
        try {
            if (doc != null) {
                if(adp.CanDeleteReference) {
                    let ii = 0;
                    let cc = 0;
                    while (ii < adp.GetReferencesCount()) {
                        let rf = adp.GetReference(ii);                    
                        if (rf.DocumentID === doc.ID) {
                            adp.DeleteReference(ii);
                            cc++;
                            continue;
                        }
                        ii++;
                    }

                    if (cc > 0) {
                        ii = 0;
                        while (ii < doc.DailyCorRefs.length) {
                            let rf = doc.DailyCorRefs[ii];
                            if (rf.dailycorr_id === adp.ID) {
                                doc.DailyCorRefs.splice(ii, 1);
                                this.m_dailycorrrefs.splice(ii, 1);
                                continue;
                            }
                            ii++;
                        }

                        if (this.m_dailycorrrefs.length === 0) this.m_dailycorrefsbtn = false;

                        adp.OnError.subscribe2(this.m_onerrorevh);
                        adp.OnPublishDailyCorrespondence = this.m_publishdcorr;
                        this.m_busy_text = this.m_strdata_srv.getStr('strDocUpdateInfo');
                        adp.Publish();
                        return;
                    }
                } else {
                    this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strNoRightsInfo'));
                }
            } else {
                const self = this;
                const wnd = this.m_wnd_srv.showControl(EditDailyCorrespondenceComponent);
                wnd.instance.SetPrms(adp);
                wnd.instance.onClosed.subscribe((ret) => {
                    wnd.destroy();
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        this.m_busy_text = '';
    }

    //

    public UploadNewResource(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    if ((<WFDocumentAdp>this.m_seltab.source).CanReplaceResource) {
                        this.m_filesel2.nativeElement.value = null;
                        this.m_filesel2.nativeElement.click();
                    }
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SelectFileChange2(event: any): void {
        try {
            let fileList: FileList = event.target.files;
            if (fileList.length > 0 && this.m_seltab != null) {
                let file = fileList[0];
                if (this.CheckFilePrms(file) &&
                    this.m_seltab.source instanceof WFDocumentAdp) {

                    const self = this;
                    let rdr = new FileReader();
                    rdr.onloadend = (res) => {
                        let dta = btoa((<any>res.srcElement).result);
                        self.m_seltab.docviewer.SetTmpOtherDocument(dta, file.name);
                    };

                    rdr.readAsBinaryString(file);
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public OpenTreeItemInNewTab(tvi: TreeViewItem): void {
        try {
            if (tvi != null && tvi.UserObj instanceof IWFDataFetchAdp) {
                this.SelectReport(<IWFDataFetchAdp>tvi.UserObj, true, null);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public AddAttachments(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    let doc = <WFDocumentAdp>this.m_seltab.source;
                    if (doc.IsModified) {
                        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strFirstSaveDataInfo'));
                        return;
                    }

                    let cls = this.m_cache_srv.Classes.get(doc.ClassID);

                    this.m_toOCR = (cls.AttachmentsSendToOCR) ? WFDocStatus.INOCR : WFDocStatus.INSYSTEM;
                    this.m_import_dst = null;
                    this.m_import_att = doc;

                    this.m_filesel.nativeElement.value = null;
                    this.m_filesel.nativeElement.click();
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public showInfo(txt: string): void {
        this.m_snackbartxt = txt;
        const self = this;
        window.setTimeout(() => {
            self.m_zone.run(() => {
                self.m_snackbartxt = "";
            });
        }, 2900);
    }

    //

    public ShowAboutWnd(): void {
        try {
            const self = this;
            const wnd = this.m_wnd_srv.showControl(AboutWndComponent);
            wnd.instance.clickCancel.subscribe(() => {
                wnd.destroy();
            });
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public ShowOcrprocessLog(): void {
        try {
            if (this.m_seltab != null) {
                if (this.m_seltab.source instanceof WFDocumentAdp) {
                    let doc = <WFDocumentAdp>this.m_seltab.source;

                    const self = this;
                    const wnd = this.m_wnd_srv.showControl(OcrProcessLogsComponent);
                    wnd.instance.SetPrms(doc.ID);
                    wnd.instance.onClosed.subscribe(() => {
                        wnd.destroy();
                    });
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public TabIsMailBox(tab: TabSpec): boolean {
        return (tab.source instanceof WFMailFolderAdp);
    }

    public InitMailBoxViewer(tab: TabSpec, ctrl: MailBoxViewerComponent): void {
        try {
            tab.mailboxviewer = ctrl;
            if (tab.source instanceof WFMailFolderAdp) this.BindMailBoxViewer(tab);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public OnSelectMail(tab: TabSpec, ctrl: MailBoxViewerComponent): void {
        try {
            let visbtns= (ctrl.PreviewMsg!= null); 
            this.m_markasseen= visbtns;       
            this.m_unmarkseen= visbtns;
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private FindTabIDForMailBox(box: WFMailFolderAdp): [number, MailBoxViewerComponent] {
        let ii: number;

        let tabid = -1;
        let finddcv: MailBoxViewerComponent = null;

        for (ii = 0; ii < this.m_tabs.length; ii++) {
            let itm: TabSpec = this.m_tabs[ii];
            if (itm.source != null) {
                if (itm.source === box) {
                    tabid = ii;
                    finddcv = itm.mailboxviewer;
                    break;
                }
            }
        }

        return [tabid, finddcv];
    }

    public TabIsEDoc(tab: TabSpec): boolean {
        return (tab.source instanceof WFDocumentInterfaceAdp);
    }

    public InitEDocViewer(tab: TabSpec, ctrl: EDocViewerComponent): void {
        try {
            tab.edocviewer = ctrl;
            if (tab.source instanceof WFDocumentInterfaceAdp) this.BindEDocViewer(tab);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public OnSelectEDoc(tab: TabSpec, ctrl: EDocViewerComponent): void {
        try {
            //let visbtns= (ctrl.PreviewMsg!= null); 
            //this.m_markasseen= visbtns;       
            //this.m_unmarkseen= visbtns;
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private FindTabIDForEDoc(doc: WFDocumentInterfaceAdp): [number, EDocViewerComponent] {
        let ii: number;

        let tabid = -1;
        let finddcv: EDocViewerComponent = null;

        for (ii = 0; ii < this.m_tabs.length; ii++) {
            let itm: TabSpec = this.m_tabs[ii];
            if (itm.source != null) {
                if (itm.source === doc) {
                    tabid = ii;
                    finddcv = itm.edocviewer;
                    break;
                }
            }
        }

        return [tabid, finddcv];
    }


    private CheckItemChanged(): void {
        //aktualizacja wybranego dokumentu
        if (this.m_selecteditem != null) {
            if (!this.m_selecteditemmodstate && this.m_selecteditem.IsModified) {
                const selitem = this.m_selecteditem;
                const self = this;
                this.m_global_srv.showMsg(ALR_ICONTYPE.INFORMATION, BUTTONSTYPE.YESNOCANCEL, IWFObject.Format(this.m_strdata_srv.getStr('strDocumentUpdateAskInfo'), this.m_selecteditem.Name),
                    (wndres) => {
                        self.CheckPublichState(wndres, selitem);
                    });
            }
        }

        this.m_selecteditem = null;
        this.m_selecteditemmodstate = false;
    }

    public SelectMailBox(box: WFMailFolderAdp): void {
        this.CheckItemChanged();

        //wyszukaj karty
        let kv = this.FindTabIDForMailBox(box);

        let finddcv: MailBoxViewerComponent = kv[1];
        let tabid = kv[0];
        let itm: TabSpec = null;
        let lastsrc = (this.m_lastviewtab != null) ? this.m_lastviewtab.source : null;

        if (tabid < 0) {
            itm = this.CreateTabItem(box.Name, box, false);
            this.m_tabs.push(itm);
        } else {
            itm = this.m_tabs[tabid];
        }

        if (itm === this.m_lastviewtab && lastsrc === this.m_fileselector_src) this.TrimHistory(itm, this.m_fileselector_src);
        itm.inargs = null;

        for (let tb of this.m_tabs) {
            let off = this.FindinHistory(tb, box);
            if (off >= 0) tb.history.splice(off, 1);
        }

        this.PutToHistory(itm, box, null);

        this.m_lastviewtab = itm;
        this.m_seltab = itm;

        if (itm.mailboxviewer != null) this.BindMailBoxViewer(itm);
    }

    private BindMailBoxViewer(tab: TabSpec): void {
        let rpt: WFMailFolderAdp = <WFMailFolderAdp>tab.source;
        tab.mailboxviewer.SetMailBoxSource(rpt);
        this.ClearRightBar();
        this.FillRightBar3(tab.mailboxviewer);
    }

    private BindEDocViewer(tab: TabSpec): void {
        let doc: WFDocumentInterfaceAdp = <WFDocumentInterfaceAdp>tab.source;
        tab.edocviewer.SetEDocInterface(doc);
        this.ClearRightBar();
        this.FillRightBar4(tab.edocviewer);
    }

    //

    public SelectEDoc(docs: WFDocumentInterfaceAdp): void {
        this.CheckItemChanged();

        //wyszukaj karty
        let kv = this.FindTabIDForEDoc(docs);

        let finddcv: EDocViewerComponent = kv[1];
        let tabid = kv[0];
        let itm: TabSpec = null;
        let lastsrc = (this.m_lastviewtab != null) ? this.m_lastviewtab.source : null;

        if (tabid < 0) {
            itm = this.CreateTabItem(docs.Name, docs, false);
            this.m_tabs.push(itm);
        } else {
            itm = this.m_tabs[tabid];
        }

        if (itm === this.m_lastviewtab && lastsrc === this.m_fileselector_src) this.TrimHistory(itm, this.m_fileselector_src);
        itm.inargs = null;

        for (let tb of this.m_tabs) {
            let off = this.FindinHistory(tb, docs);
            if (off >= 0) tb.history.splice(off, 1);
        }

        this.PutToHistory(itm, docs, null);

        this.m_lastviewtab = itm;
        this.m_seltab = itm;

        if (itm.edocviewer != null) this.BindEDocViewer(itm);
    }

    private OnSelectDirectory(sender: DocviewerComponent, company_id: number, directory_id: number): void {
        try {     
            let tvi= null;      
            if(company_id < 0)
            {
                switch(company_id)
                {
                    case -1: tvi= this.m_befocr; break;
                    case -2: tvi= this.m_inocr; break;
                    case -3: tvi= this.m_afterocr; break;
                    case -4: tvi= this.m_templates; break;
                }
            } else {
                tvi= (directory_id== 0) ? this.m_all_companies.get(company_id) : this.m_all_directories.get(directory_id);                
            }
            
            if(tvi!= null) this.SelectDirectory(tvi);  
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public SelectDailyCorRef(ref: LocalDailyCorRefInfo): void {
        try {
            if(ref.canjump) {
                let adp: WFDailyCorrespondenceClassAdp= this.m_cache_srv.DailyCorrepondenceClasses.get(ref.info.dailycorrclass_id);
                this.SelectReport(adp, true, null);
            }
            this.LoadDailyCorRef(ref.info, null);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }
}

import { WFClassAttrib, WFColumnFormat, WFFieldType, IWFObject, UpperMap, WFSystem } from '../model/index';
import { IWFCompanyObjAdp } from './IWFCompanyObjAdp';
import { CacheService } from '../data/cache.service';
import { ExecuteInteractiveDictPrms, EIDParam, WFDataSource, WFBasicDataType } from '../data/data.service';
import { EventAdp } from '../data/eventadp';
import { _OnError } from './WFDocumentAdp';
import { ReportViewerComponent } from '../report-viewer/report-viewer.component';

export type FetchData = (snd: IWFDataFetchAdp, dta: Array<[string, Array<Object>]>, ustate: Object) => void;

export abstract class IWFDataFetchAdp {
    private m_data: Array<[string, Array<Object>]>;
    public OnFetchData: FetchData;

    private m_OnError: EventAdp<_OnError>;

    //

    constructor() {
        this.m_data = null;
        this.OnFetchData = null;
        this.m_OnError = new EventAdp<_OnError>();
    }

    public get OnError(): EventAdp<_OnError> {
        return this.m_OnError;
    }

    public abstract get Name(): string;
    public abstract get Description(): string;
    public abstract get UpdatedAt(): Date;
    public abstract get ReportLabel(): string;
    public abstract get Parameters(): Array<WFClassAttrib>;
    public abstract get ColumnsFormat(): Array<WFColumnFormat>;
    public abstract get TaskID(): string;

    public abstract FetchDictData(serv: CacheService, prms: Map<number, Object>, usecache: boolean, dbname: string, uval?: Object): void;

    protected SendExecuteInteractiveDict(serv: CacheService, prms: Map<number, object>, uval: object, objtype: WFDataSource, objid: number, dbname: string): void {
        let dval = new Array<EIDParam>();
        for (let kv of Array.from(prms.entries())) {
            let oval: object = kv[1];
            if (oval == null) {
                dval.push({ pid: kv[0], dtp: WFBasicDataType.STRING, val: null });
            } else {
                if (oval instanceof Date) {
                    dval.push({ pid: kv[0], dtp: WFBasicDataType.DATETIME, val: IWFObject.DateToString(<Date>oval) });
                    continue;
                }
                if (typeof oval === 'number') {
                    dval.push({ pid: kv[0], dtp: WFBasicDataType.DOUBLE, val: <number>oval });
                    continue;
                }
                dval.push({ pid: kv[0], dtp: WFBasicDataType.STRING, val: oval.toString() });
            }            
        }

        let rq: ExecuteInteractiveDictPrms = {
            sid: serv.SessionID,
            dts: objtype,
            dict_id: objid,
            dval: dval,
            dbname: dbname
        };

        const self = this;
        serv.DataService.executeInteractiveDict(rq, this.m_OnError, (dt) => {
            self.ExecuteInteractiveDictCompleted(serv, dt, uval);
        });
    }

    protected abstract FetchDataCompleted(dta: Array<[string, Array<Object>]>, ustate: Object): void;

    private ExecuteInteractiveDictCompleted(serv: CacheService, dta: Array<[string, Array<Object>]>, uval: object): void {
        try {
            this.m_data = dta;
            this.FetchDataCompleted(this.m_data, uval);
            return;
        } catch (ex) {
            serv.GlobalService.manageException(ex);
        }

        if (this.OnFetchData != null)
            this.OnFetchData(this, null, uval);
    }

    protected ConfirmFetchAction(dta: Array<[string, Array<Object>]>, ustate: Object): void {
        if (this.OnFetchData != null)
            this.OnFetchData(this, dta, ustate);
    }

    public get Cache(): Array<[string, Array<Object>]>
    {
        return this.m_data;
    }
}

export class WFDataFetchParams extends IWFCompanyObjAdp {
    private m_parent: IWFDataFetchAdp;
    private m_defcmpid: number;

    //

    public static CheckMandatory(atrs: Array<WFClassAttrib>, vals: UpperMap, inargs: Map<number, Object>): string {
        let ii: number;
        let errbld = new Array<string>();

        for (ii = 0; ii < atrs.length; ii++) {
            let prm: WFClassAttrib = atrs[ii];
            let key: string = prm.Name.toUpperCase();
            let sval: string = (vals.has(key)) ? vals.get(key) : null;
            if (IWFObject.IsNullOrEmpty(sval)) {
                if (prm.Mandatory)
                    errbld.push((IWFObject.IsNullOrEmpty(prm.Label)) ? prm.Name : prm.Label);
            } else {
                if (inargs != null) {
                    try {
                        let obj: Object = null;
                        switch (prm.Type) {
                            case WFFieldType.TYPE_INT:
                                obj = IWFObject.ParseInt(sval);
                                break;
                            case WFFieldType.TYPE_FLOAT:
                                obj = IWFObject.ParseFloat(sval.replace('.', ','));
                                break;
                            case WFFieldType.TYPE_DATETIME:
                                //let ret= Date.parse(sval);
                                //if (isNaN(ret)) throw new Error();
                                obj = IWFObject.ParseDateStr(sval); //, WFDocumentAdp.DATEFORMATS, System.Globalization.CultureInfo.InvariantCulture, System.Globalization.DateTimeStyles.None);
                                break;
                            default:
                                obj = sval;
                                break;
                        }
                        if (inargs.has(prm.ID))
                            inargs.set(prm.ID, obj);
                        else inargs.set(prm.ID, obj);
                    } catch (err) {
                        errbld.push((IWFObject.IsNullOrEmpty(prm.Label)) ? prm.Name : prm.Label);
                    }
                }
            }
        }

        return errbld.join('\n');
    }

    constructor(csrv: CacheService, prnt: IWFDataFetchAdp) {
        super(csrv, false, new UpperMap());
        this.m_parent = prnt;
        this.m_defcmpid = (csrv.Companies.size > 0) ? Array.from(csrv.Companies.values())[0].ID : 0;
    }

    public set CompanyID(value: number) {
    }

    public get CompanyID(): number {
        return this.m_defcmpid;
    }

    public CanEdit(atr: WFClassAttrib): boolean {
        return true;
    }

    protected SetValue(key: string, value: string, checkprm: boolean): void {
        let sval: string = super._SetValue(key, value, checkprm, this.m_parent.Parameters);
        if (sval != null)
            this.IsModified = true;
    }

    public CheckMandatory(inargs: Map<number, Object>): string {
        return WFDataFetchParams.CheckMandatory(this.m_parent.Parameters, this.m_props, inargs);
    }

    //

    public EvalExCode(code: string, lid: number, rtp: WFFieldType): Object {
        return null;
    }
}
export enum WFInstPrmType {
    TYPE_TEXT,
    TYPE_INT,
    TYPE_FLOAT,
    TYPE_USERDICT,
    TYPE_SELUSER,
    TYPE_SCHEMA,
    TYPE_SELSCHEMA
}

export enum WFInstPrmObjType {
    ALLUSERS,
    USERGROUP,
    USERDEFINEDGROUP,
}

export class WFInstructionParameter {
    private m_int_id: number;
    private m_name: string;
    private m_description: string;
    private m_type: WFInstPrmType;
    private m_allownull: boolean;
    private m_vvalues: Map<string, string>;
    private m_variable_id: number;

    private m_obj_type: WFInstPrmObjType;
    private m_obj_id: number;

    //

    constructor(obj: any) {
        this.m_int_id = obj.int_id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_type = obj.type;
        this.m_allownull = obj.allownull;
        this.m_vvalues = new Map<string, string>();
        for (let kv of obj.vvalues) this.m_vvalues.set(kv.key, kv.value);

        this.m_variable_id = obj.variable_id;

        this.m_obj_type = obj.obj_type;
        this.m_obj_id = obj.obj_id;
    }

    //

    public get InternalID(): number {
        return this.m_int_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Descritpion(): string {
        return this.m_description;
    }

    //

    public get Type(): WFInstPrmType {
        return this.m_type;
    }

    //

    public get AllowNull(): boolean {
        return this.m_allownull;
    }

    //

    public get ValidValues(): Map<string, string> {
        return this.m_vvalues;
    }

    //

    public get VariableID(): number {
        return this.m_variable_id;
    }

    //

    public get ObjType(): WFInstPrmObjType {
        return this.m_obj_type;
    }

    //

    public get ObjID(): number {
        return this.m_obj_id;
    }
}

import { Component, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { WFMessage, WFMessageRecipient, IWFObject, WFUser, WFUserGroup, EVENTTYPE } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { _OnError, WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { WFMessageAdp } from '../adapters/WFMessageAdp';
import { EventAdp } from '../data/eventadp';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';

interface UserSpec {
  isselected: boolean;
  label: string;
  user: WFUser;
}

@Component({
  selector: 'app-request-comment',
  templateUrl: './request-comment.component.html',
  styleUrls: ['./request-comment.component.scss']
})

export class RequestCommentComponent implements AfterViewInit {
  public m_busy_str: string;
  private m_onerrorevh: EventAdp<_OnError>;

  @Output() public onClosed: EventEmitter<boolean>;

  public m_doc: WFDocumentAdp;

  @ViewChild('m_allusers', {static: false}) private m_allusers: ComboboxadpComponent;

  public m_users: Array<UserSpec>;
  public m_cbAsk4com: boolean;

  public m_subject: string;
  public m_subject_iserror: boolean;

  public m_message: string;
  public m_message_iserror: boolean;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {

    this.m_busy_str = '';
    this.onClosed = new EventEmitter<boolean>();

    const self = this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });

    this.m_doc = null;

    this.m_users = new Array<UserSpec>();
    this.m_cbAsk4com = false;

    this.m_subject = '';
    this.m_subject_iserror = false;

    this.m_message = '';
    this.m_message_iserror = false;
  }

  public SetPrms(doc: WFDocumentAdp, msa: WFMessageAdp): void {
    this.m_doc = doc;
    if (msa != null) {
      this.m_cbAsk4com = false;
      if (msa.UserID > 0) this.AddUserToGrid(msa.UserID);
      this.m_subject = IWFObject.Format('RE: {0}', msa.Subject);
      this.m_message = IWFObject.Format('{0}{0}----------------------------------{0}{1}', '\r\n', msa.Content);
    }
  }

  private AddUserToGrid(uid: number): void {
    let usr: WFUser = (uid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uid);
    this.m_users.push({ isselected: false, label: usr.FriendlyName, user: usr });
  }

  public ngAfterViewInit(): void {
    try {
      let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
      for (let usr of susr) {
        this.m_allusers.AddValidValue(usr.ID.toString(), usr.FriendlyName);
      }

      let sgrp= Array.from(this.m_cache_srv.UserGroups.values()).sort((a,b)=> IWFObject.Format('[{0} - {1}]', a.Name, a.Description).localeCompare(IWFObject.Format('[{0} - {1}]', b.Name, b.Description)));
      for (let grp of sgrp) {
        this.m_allusers.AddValidValue(IWFObject.Format('_{0}', grp.ID), IWFObject.Format('[{0} - {1}]', grp.Name, grp.Description));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public AddUsers(): void {
    let ii: number;

    try {

      let selval = this.m_allusers.SelectedValue;
      if (!IWFObject.IsNullOrEmpty(selval)) {
        let ids: Array<number>;
        if (selval[0] === '_') {
          let grpid = Number.parseInt(selval.substr(1));
          let usrgrp: WFUserGroup = this.m_cache_srv.UserGroups.get(grpid);
          ids = usrgrp.Users;
        } else {
          ids = new Array<number>();
          ids.push(Number.parseInt(selval));
        }

        for (let uid of ids) {
          let doadd = true;

          for (ii = 0; ii < this.m_users.length; ii++) {
            if (this.m_users[ii].user.ID === uid) {
              doadd = false;
              break;
            }
          }

          if (doadd) this.AddUserToGrid(uid);
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public DeleteUsers(): void {
    try {
      let ii = 0;
      while (ii < this.m_users.length) {
        if (this.m_users[ii].isselected) {
          this.m_users.splice(ii, 1);
          continue;
        }
        ii++;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public isSelectedt(): boolean {
    for (let usr of this.m_users) {
      if (usr.isselected) return true;
    }
    return false;
  }

  public OKButton_Click(): void {
    let ii: number;

    try {
      if (this.m_users.length > 0) {
        let msg2 = WFMessage.Create(this.m_cache_srv.User.ID,
          this.m_subject.trim(),
          this.m_message.trim(),
          this.m_doc.CompanyID,
          this.m_doc.ID,
          0,
          (this.m_cbAsk4com) ? EVENTTYPE.NEEDCOMMENT : EVENTTYPE.NONE, 0);

        for (ii = 0; ii < this.m_users.length; ii++) {
          let usr: WFUser = this.m_users[ii].user;

          let eml: string;
          if ((usr.CanSendEvent(EVENTTYPE.NEEDCOMMENT)) &&
            (!IWFObject.IsNullOrEmpty(usr.EMailReply))) {
            eml = usr.EMailReply;
          } else {
            eml = null;
          }

          let rc: WFMessageRecipient = WFMessageRecipient.Create(usr.ID, eml, usr.MarkAsRead(EVENTTYPE.NEEDCOMMENT));
          msg2.Recipients.push(rc);
        }

        this.m_busy_str = this.m_strdata_srv.getStr('strWaitSendingMessage');

        const self = this;
        this.m_data_srv.modifyMessage(this.m_cache_srv.SessionID, msg2, this.m_onerrorevh, (msg) => {
          self.ModifyMessageCompleted(msg);
        });
      } else {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wRequestComment_AtLeastOne'));
      }


    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public cbAsk4com_Checked(): void {
    if (this.m_cbAsk4com) {
      this.m_subject = this.m_strdata_srv.getStr('wRequestComment_Ask4CommentSubjectText');
    }
  }

  private ModifyMessageCompleted(msg: WFMessage): void {
    try {
      this.m_cache_srv.MsgCache.set(msg.ID, new WFMessageAdp(msg));
      this.m_doc.AddMessage(msg.ID);
      this.onClosed.emit(true);
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_str = '';
  }
}

import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit, ViewChild, HostListener, ViewContainerRef, ElementRef } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { IWFDataFetchAdp, FetchData, WFDataFetchParams } from '../adapters/IWFDataFetchAdp';
import { PropertyValidation, IWFCompanyObjAdp } from '../adapters/IWFCompanyObjAdp';
import { WFDocumentValidValues } from '../adapters/WFDocumentValidValues';
import { GroupDescriptor, DataResult, process, AggregateDescriptor, SortDescriptor, FilterDescriptor, CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { WFColumnFormat, IWFObject, WFClassAttrib, WFColumnTarget, WFDocument, WFDailyCorrespondenceClass, WFDailyCorrespondence } from '../model';
import { UserPropertySpec, DocviewerComponent } from '../docviewer/docviewer.component';
import { DatePipe } from '@angular/common';
import { SbobusinesspartnerComponent } from '../sbobusinesspartner/sbobusinesspartner.component';
import { GridComponent, CellClickEvent } from '@progress/kendo-angular-grid';
import { WFDailyCorrespondenceClassAdp } from '../adapters/WFDailyCorrespondenceClassAdp';
import { EditDailyCorrespondenceComponent } from '../edit-daily-correspondence/edit-daily-correspondence.component';
import { EventAdp } from '../data/eventadp';
import { _OnError, WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { WFDailyCorrespondenceAdp } from '../adapters/WFDailyCorrespondenceAdp';
import { AppComponent, TabOperation } from '../app.component';
import { DocumentPreviewComponent } from '../document-preview/document-preview.component';
//import { MENU_PANEL_TOP_PADDING } from '@angular/material/menu/typings/menu-trigger';

interface ColumnSpec {
    label: string;
    format: WFColumnFormat;
    isvisible: boolean;
    uniquename: string;
    field: string;
    issortable: boolean;
    filtertp: string;
    width: number;
    alignright: boolean;
    issum: boolean;
}

@Component({
    selector: 'app-report-viewer',
    templateUrl: './report-viewer.component.html',
    styleUrls: ['./report-viewer.component.scss']
})

export class ReportViewerComponent implements AfterViewInit {
    public m_busy_text: string;

    private m_report: IWFDataFetchAdp;
    private m_onfetchdata: FetchData;
    //private m_fieldvalidationerr: EventHandler<ValidationErrorEventArgs>;

    private m_propvalidation: PropertyValidation;
    private m_actparams: WFDataFetchParams;

    private m_openinnewwnd: boolean;
    private m_curdocintmap: Map<number, WFDocumentValidValues>;

    @Output() afterInit: EventEmitter<ReportViewerComponent>;

    public m_groups: Array<GroupDescriptor>;
    public m_sort: Array<SortDescriptor>;
    public m_filter: CompositeFilterDescriptor;

    public m_rawdata: Array<Object>;
    public m_griddta: DataResult;
    public m_columns: Array<ColumnSpec>;

    public m_inf_props: Array<UserPropertySpec>;

    public m_datep: DatePipe;
    public m_dateformat: string;

    @ViewChild('m_dataview', {static: false}) private m_dataview: GridComponent;

    private m_onerrorevh: EventAdp<_OnError>;

    public m_details_col: boolean;
    public m_documents_col: string;
    public m_docnum_col: string;
    public m_grheight: number;

    public m_aggregates: Array<AggregateDescriptor>;

    //

    public set Report(value: IWFDataFetchAdp) {
        this.SetReportSource(value, null);
    }

    public get Report(): IWFDataFetchAdp {
        return this.m_report;
    }

    //

    constructor(protected m_strdata_srv: StringDataService,
        private m_data_srv: DataService,
        private m_global_srv: GlobalService,
        private m_cache_srv: CacheService,
        private m_wnd_srv: ChildWindowContainerService) {

        this.m_busy_text = '';
        this.m_report = null;

        const self = this;
        this.m_onfetchdata = (snd, dta, ustate) => { self.OnFetchData(snd, dta, ustate); };

        //m_fieldvalidationerr = new EventHandler<ValidationErrorEventArgs>(FieldValidationError);
        //  m_dumpygotfocus = new RoutedEventHandler(DumyCtrlGotFocus);
        //  m_dataview.RowActivated += new EventHandler<RowEventArgs>(RowActivated);

        this.m_propvalidation = (snd, prop, status) => { self.OnPropertyValidation(snd, prop, status); };
        this.m_actparams = null;

        this.m_openinnewwnd = false;
        this.m_curdocintmap = new Map<number, WFDocumentValidValues>();

        this.afterInit = new EventEmitter<ReportViewerComponent>();

        this.m_groups = new Array<GroupDescriptor>();
        this.m_sort = new Array<SortDescriptor>();
        this.m_filter = null;

        this.m_rawdata = null;
        this.m_griddta = null;
        this.m_columns = new Array<ColumnSpec>();

        this.m_inf_props = new Array<UserPropertySpec>();

        this.m_datep = new DatePipe('en-US');
        this.m_dateformat = 'dd-MM-yyyy';

        this.m_onerrorevh = new EventAdp<_OnError>();
        this.m_onerrorevh.subscribe(() => { self.m_busy_text = ''; });

        this.m_details_col = false;
        this.m_documents_col = null;
        this.m_docnum_col = null;
        this.m_grheight = 0;

        this.m_aggregates= new Array<AggregateDescriptor>();
    }

    ngAfterViewInit() {
        this.afterInit.emit(this);
        this.onResize(null);
    }

    public RefreshData(): void {
        let args = new Map<number, object>();
        let errstr = this.m_actparams.CheckMandatory(args);
        if (IWFObject.IsNullOrEmpty(errstr)) {
            this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');

            let dbname = '';
            for (let cmp of Array.from(this.m_cache_srv.Companies.values())) {
                if (cmp.OrganizationID === this.m_cache_srv.User.OrganizationID || cmp.OrganizationID === 0) {
                    dbname = cmp.DBName;
                    break;
                }
            }

            this.m_report.FetchDictData(this.m_cache_srv, args, false, dbname);
        } else {
            this.m_global_srv.showWarning(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('strErrPrms'), errstr));
        }
    }

    private BindToGrid(): void {
        this.m_griddta = process(this.m_rawdata, {
            group: this.m_groups,
            sort: this.m_sort,
            filter: this.m_filter
        });
    }

    public groupChange(groups: Array<GroupDescriptor>): void {
        groups.map((grp)=> { grp.aggregates = this.m_aggregates });
        this.m_groups = groups;
        this.BindToGrid();
    }

    public sortChange(sort: Array<SortDescriptor>): void {
        this.m_sort = sort;
        this.BindToGrid();
    }

    public filterChange(filter: CompositeFilterDescriptor): void {
        this.m_filter = filter;
        this.BindToGrid();
    }

    public SetReportSource(value: IWFDataFetchAdp, inargs: Map<string, string>): void {
        let ii: number;

        if (this.m_report !== value) {
            if (this.m_report != null) {
                this.m_report.OnError.unsubscribe2(this.m_onerrorevh);
                this.m_report.OnFetchData = null;
            }

            this.m_report = value;
            this.m_columns.splice(0, this.m_columns.length);
            this.m_rawdata = null;
            this.m_griddta = null;
            this.m_groups.splice(0, this.m_groups.length);
            this.m_sort.splice(0, this.m_sort.length);
            this.m_aggregates.splice(0, this.m_aggregates.length);
            this.m_filter = null;
            this.m_inf_props.splice(0, this.m_inf_props.length);

            //m_params.Children.Clear();
            this.m_actparams = null;
            //m_attrbnds = null;
            this.m_curdocintmap.clear();

            if (this.m_report != null) {
                this.m_actparams = new WFDataFetchParams(this.m_cache_srv, this.m_report);
                //inicjacja
                if (inargs != null) {
                    for (let kv of Array.from(inargs.keys())) {
                        this.m_actparams.set(kv, inargs.get(kv));
                    }
                }

                this.m_actparams.OnPropertyValidation = this.m_propvalidation;

                if (this.m_report.Parameters.length > 0) {
                    let docctx: WFDocumentAdp = null;

                    for (ii = 0; ii < this.m_report.Parameters.length; ii++) {
                        let att = this.m_report.Parameters[ii];

                        if (!IWFObject.IsNullOrEmpty(att.EvalCode)) {

                            if(docctx == null) {
                                let ndoc = WFDocument.Create('TEMP', '', '', new Date());
                                ndoc.UserID = this.m_cache_srv.User.ID;
                                ndoc.CompanyID = Array.from(this.m_cache_srv.Companies.values())[0].ID;
                                docctx = new WFDocumentAdp(this.m_cache_srv, ndoc, false, false);
                            }                            

                            let oval: Object = docctx.EvalExCode(att.EvalCode, -1, att.Type);
                            if (oval != null) {
                                let sval: string;

                                if (typeof oval === 'string') {
                                    sval = <string>oval;
                                    sval = sval.trim();
                                    if (sval.length > 0) {
                                        let off: number = sval.indexOf('<!>');
                                        if (off > 0) {
                                            sval = sval.substr(0, off);
                                        }
                                    }
                                } else {
                                    sval= oval.toString();
                                }

                                this.m_actparams.set(att.Name.toUpperCase(), sval);
                            }
                        }

                        this.m_inf_props.push({
                            label: IWFObject.Format('{0}:', (IWFObject.IsNullOrEmpty(att.Label)) ? att.Name : att.Label).toUpperCase(),
                            attrib: att,
                            iseditable: true,
                            iserror: false,
                            dateval: null,
                            comboischb: false
                        });
                    }
                }

                this.m_report.OnFetchData = this.m_onfetchdata;
                this.RefreshData();
            }

            this.m_details_col = (this.m_report instanceof WFDailyCorrespondenceClassAdp);
            this.m_report.OnError.subscribe2(this.m_onerrorevh);
        } else {
            if (this.m_report != null) {
                if (inargs != null) {
                    for (let kv of Array.from(inargs.keys())) {
                        this.m_actparams.set(kv, inargs.get(kv));
                    }
                }
                this.RefreshData();
            }
        }

        this.onResize(null);
    }

    private OnPropertyValidation(snd: IWFCompanyObjAdp, prop: string, status: boolean): void {
        let ii: number;
        try {
            for (ii = 0; ii < this.m_inf_props.length; ii++) {
                let ps = this.m_inf_props[ii];
                if (ps.attrib.Name.toUpperCase() === prop) {
                    ps.iserror = !status;
                    break;
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private OnFetchData(snd: IWFDataFetchAdp, dta: Array<[string, Array<Object>]>, ustate: Object): void {
        let ii, ii2: number;
        let coldta: [string, Array<Object>];
        //let colformat = new Map<string, [string, boolean, number, boolean]>();
        let formatgroups = new Map<number, ColumnSpec>();

        try {

            if (dta == null || dta.length === 0) {
                this.m_griddta = null;
            } else {
                let makeGrouping = false;
                if (this.m_columns.length === 0) {
                    this.m_documents_col = null;
                    this.m_docnum_col = null;
                    //dodanie kolumn
                    for (ii = 0; ii < dta.length; ii++) {

                        //formatowanie kolumn

                        //formatowanie kolumn
                        // 1 - 0 - ukryj - 1 pokaż
                        // 2 - 0 - nie grupuj - 1 - 9 - numer grupy
                        // 3 - 0 - sortowanie

                        /*char[] colformatarr = "100".ToCharArray();
                        string[] keyformatpair = new string[] { "", "100" };
                        dta[ii].Key.Split('$').CopyTo(keyformatpair, 0);
                        string header = keyformatpair[0];
                        keyformatpair[1].CopyTo(0, colformatarr, 0, keyformatpair[1].Length);
                        int groupnum = Int32.TryParse(colformatarr[1].ToString(), out groupnum) ? groupnum : 0;
                        colformat.Add(dta[ii].Key, new object[] { header, (colformatarr[0] == '1'), groupnum, (colformatarr[2] == '1')});*/

                        coldta = dta[ii];
                        //let header = coldta[0];
                        
                        //DM03112022 stara funct
                        let colformatarr = '100';
                        let header = coldta[0];
                        let off = header.indexOf('$');
                        if (off > 0) {
                            let tst = header.substr(off + 1);
                            if (tst.length > 0) colformatarr = tst + '00';
                            header = header.substr(0, off);
                        }

                        let groupnum = Number.parseInt(colformatarr[1]);
                        if (isNaN(groupnum)) groupnum = 0;

                        //let frmt: [string, boolean, number, boolean] = [header, (colformatarr[0] === '1'), groupnum, (colformatarr[2] === '1')];
                        //colformat.set(coldta[0], frmt);
                        
                        let visible= (colformatarr[0] === '1');
                        let sortable= (colformatarr[2] === '1');

                        let fid = IWFObject.Format('f{0}', ii);
                        let frm: WFColumnFormat = null;
                        let uhdr = header.toUpperCase();
                        switch (uhdr) {
                            case 'SYS_DOCCC':
                                //frmt[1] = false;
                                visible= false;
                                this.m_documents_col = fid;
                                break;

                            case 'SYS_DOCNUM':
                                //frmt[1] = false;
                                visible= false;
                                this.m_docnum_col = fid;
                                break;

                            default:
                                for (ii2 = 0; ii2 < this.m_report.ColumnsFormat.length; ii2++) {
                                    let tst = this.m_report.ColumnsFormat[ii2];
                                    if (tst.ColumnCode.toUpperCase() === uhdr) {
                                        frm = tst;
                                        break;
                                    }
                                }
                                break;
                        }

                        //GridViewDataColumn col = new GridViewDataColumn();
                        let locHeader = this.m_strdata_srv.getStr(header);
                        let lbl: string;
                        if (IWFObject.IsNullOrEmpty(locHeader)) {
                            lbl = header;
                            if (frm != null) {
                                if (!IWFObject.IsNullOrEmpty(frm.Label)) lbl = frm.Label;
                            }
                        } else {
                            lbl = locHeader;
                        }

                        let type = 'text';

                        for (ii2 = 0; ii2 < coldta[1].length; ii2++) {
                            let obj = coldta[1][ii2];
                            if (obj != null) {
                                switch (typeof obj) {
                                    case 'string':
                                        type = 'text';
                                        break;
                                    case 'number':
                                        type = 'numeric';
                                        break;
                                    case 'boolean':
                                        type = 'boolean';
                                        break;
                                    default:
                                        if (obj instanceof Date) type = 'date';
                                        break;
                                }
                            }
                        }

                        let issum= (frm == null) ? false : (frm.Sumable && type=='numeric');

                        let col: ColumnSpec = {
                            label: lbl,
                            format: frm,
                            isvisible: (frm == null) ? visible : (frm.Width >= 0), //colformat.get(coldta[0])[1],
                            issortable: (frm == null) ? sortable : frm.Sortable,
                            uniquename: coldta[0],
                            field: fid,
                            filtertp: type,
                            width: (frm == null) ? null : ((frm.Width > 0) ? frm.Width : null),
                            alignright: (frm == null) ? false : frm.AlignRight,
                            issum: issum
                        };
                        
                        //let groupnum= (frm == null) ? 0 : frm.GroupOrder;
                        if(frm != null) 
                            groupnum= frm.GroupOrder;

                        if (groupnum > 0) {
                            formatgroups.set(groupnum, col);
                        }

                        this.m_columns.push(col);
                        makeGrouping = true;

                        if(issum) this.m_aggregates.push({ field: fid, aggregate: 'sum' });
                    }
                }

                //przepisanie kolumny na wiersze
                let rcc = dta[0][1].length;

                let rows = new Array<Object>(rcc);
                for (ii = 0; ii < rcc; ii++) {
                    let row = new Object();
                    for (ii2 = 0; ii2 < dta.length; ii2++) {
                        coldta = dta[ii2];
                        row[IWFObject.Format('f{0}', ii2)] = coldta[1][ii]; //  (coldta[1][ii] == null) ? '' : this.FormatObject(coldta[1][ii]);
                    }
                    rows[ii] = row;
                }

                this.m_rawdata = rows;

                //Grupowanie
                if (makeGrouping) {
                    let first = true;

                    for (let grp of Array.from(formatgroups.keys()).sort()) {
                        let col = formatgroups.get(grp);
                        //let collsettings = colformat.get(col.uniquename);

                        //let agrs = new Array<AggregateDescriptor>();

                        if (first) {
                            first = false;
                            this.m_aggregates.push({
                                aggregate: 'count',
                                field: col.field
                            });
                            /*agrs.push({
                                aggregate: 'count',
                                field: col.field
                            });*/
                        }

                        let gdscr: GroupDescriptor = {
                            field: col.field,
                            dir: (col.format == null) ? 'asc' : ((col.format.GroupAsc) ? 'asc' : 'desc'), //TODO (collsettings[3]) ? 'asc' : 'desc',
                            aggregates: this.m_aggregates //agrs
                        };

                        this.m_groups.push(gdscr);
                    }
                }

                //
                this.BindToGrid();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }

        this.m_busy_text = '';
    }

    //

    public SetAttrib(prop: WFClassAttrib, val: object, ps: UserPropertySpec): void {
        try {
            if (this.m_actparams != null) {
                if (val instanceof Date) {
                    let dta = <Date>val;
                    this.m_actparams.set(prop.Name, this.m_datep.transform(dta, this.m_dateformat));
                    ps.dateval = dta;
                } else {
                    this.m_actparams.set(prop.Name, (val == null) ? '' : val.toString());
                }
            }
        } catch (ex) {
            let dbg = '';
        }
    }

    public ParseDate(ps: UserPropertySpec): Date {
        let ret: Date = null;
        if (ps.dateval == null) {
            try {
                let str: string = this.m_actparams.get(ps.attrib.Name);
                if (str != null && str.length > 9) {
                    let day = IWFObject.ParseInt(str.substr(0, 2));
                    let month = IWFObject.ParseInt(str.substr(3, 2));
                    let year = IWFObject.ParseInt(str.substr(6, 4));
                    ret = new Date(year, month - 1, day);
                }
            } catch (ex) {
                ps.iserror = true;
            }
            ps.dateval = ret;
        } else {
            ret = ps.dateval;
        }
        return ret;
    }

    public FillComboBox(cmb: ComboboxadpComponent, att: WFClassAttrib): void {
        try {
            DocviewerComponent.FillComboBox(this.m_actparams, this.m_curdocintmap, this.m_cache_srv, cmb, att);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public FillSboBusinessPartner(cmp: SbobusinesspartnerComponent, att: WFClassAttrib): void {
        const self = this;
        //cmp.SetSource(this.m_document, () => self.m_selocrrs);
    }

    private GroupsOP(pth: string, arr: Array<any>, exp: boolean): void {

        for (let ii = 0; ii < arr.length; ii++) {
            let pth2 = pth + ii.toString();
            
            if (exp)
                this.m_dataview.expandGroup(pth2);
            else
                this.m_dataview.collapseGroup(pth2);

            let sobj = arr[ii];
            if('items' in sobj) this.GroupsOP(pth2 + '_', sobj.items, exp);            
        }
    }


    public ExpandGroups(): void {
        try {
            if (this.m_groups.length > 0) {
                this.GroupsOP('', this.m_griddta.data, true);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public CollapseGroups(): void {
        try {
            if (this.m_groups.length > 0) {
                this.GroupsOP('', this.m_griddta.data, false);
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private OpenDocument(doc: WFDocumentAdp): void {
        if (this.m_openinnewwnd) {
            this.m_openinnewwnd = false;
            //DocumentPreview dlg = new DocumentPreview(doc, null);
            //dlg.Show();
        } else {
            this.m_global_srv.App.SelectDocument(doc, TabOperation.NEW);
        }
    }

    /*public gridCellClick(event: any): void {
        let ii: number;

        try {

            if (event.type === 'click') {
                const self = this;

                if (this.m_report instanceof WFDailyCorrespondenceClassAdp) {
                    let id = -1;

                    for (ii = 0; ii < this.m_columns.length; ii++) {
                        let hdr = this.m_columns[ii];
                        let colid = (hdr.format == null) ? hdr.label : hdr.format.ColumnCode;
                        if (colid.toUpperCase() === 'ID') {
                            let tst = Number.parseInt(event.dataItem[hdr.field]);
                            if (!isNaN(tst)) id = tst;
                            break;
                        }
                    }

                    if (id > 0) {
                        const showentry = (event.column.field === 'det');
                        this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                        this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [id], this.m_onerrorevh, (ret) => {
                            self.GetDailyCorrespondencesCompleted(ret, showentry);
                        });
                    }

                    return;
                }
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }*/

    public gridSubCellClick(cspec: ColumnSpec, dataItem: Object): void {
        let ii: number;

        try {
            const self = this;

            let clval = dataItem[cspec.field];
            if (cspec.format.Target === WFColumnTarget.DOCUMENT) {
                let docid = Number.parseInt(clval);

                if (this.m_cache_srv.ContainsDocument(docid)) {
                    let doc = this.m_cache_srv.GetDocument(docid);
                    this.OpenDocument(doc);
                } else {
                    this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                    this.m_data_srv.getDocuments(this.m_cache_srv.SessionID, [docid], false, this.m_onerrorevh, (ret, ronly) => {
                        self.GetDocumentsCompleted(ret, ronly);
                    });
                }
                return;
            }

            //

            if (cspec.format.Target === WFColumnTarget.DAILYCORRESPONDENCE) {
                //nie cachowane
                let dcorid = Number.parseInt(clval);

                this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [dcorid], this.m_onerrorevh, (ret) => {
                    self.GetDailyCorrespondencesCompleted(ret, true);
                });

                return;
            }

            //

            if (cspec.format.Target === WFColumnTarget.SUBREPORT) {
                let adp = this.m_cache_srv.Dictionaries.get(cspec.format.TargetClassID);

                let inargs = new Map<string, string>();
                for (let srccid of Array.from(cspec.format.ParametersMapping.keys())) {
                    let usrccid = srccid.toUpperCase();
                    let colval = null;

                    for (ii = 0; ii < this.m_columns.length; ii++) {
                        let hdr2 = this.m_columns[ii];
                        let cid = (hdr2.format == null) ? hdr2.label : hdr2.format.ColumnCode;
                        if (cid.toUpperCase() === usrccid) {
                            colval = dataItem[hdr2.field];
                            break;
                        }
                    }

                    if (colval != null) {
                        let dstcids = cspec.format.ParametersMapping.get(srccid).split(';');
                        for (let dstcid of dstcids) {
                            let udstcid = dstcid.toUpperCase();
                            inargs.set(udstcid, colval);
                        }
                    }
                }

                if (this.m_openinnewwnd) {
                    this.m_openinnewwnd = false;
                    //ReportPreview dlg = new ReportPreview(adp, inargs);
                    //dlg.Show();
                } else {
                    this.m_global_srv.App.SelectReport(adp, true, inargs);
                }
            }

        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    private findID(dataItem: Object): number {
        let ii: number;

        let id = -1;

        for (ii = 0; ii < this.m_columns.length; ii++) {
            let hdr = this.m_columns[ii];
            let colid = (hdr.format == null) ? hdr.label : hdr.format.ColumnCode;
            if (colid.toUpperCase() === 'ID') {
                let tst = Number.parseInt(dataItem[hdr.field]);
                if (!isNaN(tst)) id = tst;
                break;
            }
        }

        return id;
    }

    public gridSpcCellClick(dataItem: Object): void {
        try {
            let id = this.findID(dataItem);
            if (id > 0) {
                const self = this;
                this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [id], this.m_onerrorevh, (ret) => {
                    self.GetDailyCorrespondencesCompleted(ret, true);
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    public gridSpcCellClickCopy(dataItem: Object): void {
        try {
            let id = this.findID(dataItem);
            this.m_global_srv.Copied_dailyenttry = null;
            if (id > 0) {
                const self = this;
                this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [id], this.m_onerrorevh, (ret) => {
                    self.m_global_srv.Copied_dailyenttry = new WFDailyCorrespondenceAdp(this.m_cache_srv, ret[0]);
                    self.m_busy_text = '';
                    self.m_global_srv.App.showInfo(self.m_strdata_srv.getStr('strNumCopied'));
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    //

    private GetDocumentsCompleted(docs: Array<WFDocument>, ronly: Array<number>): void {
        try {
            let doc = docs[0];
            let adp = new WFDocumentAdp(this.m_cache_srv, doc, true, ronly.includes(doc.ID));
            this.m_cache_srv.AddDocument(adp);
            this.OpenDocument(adp);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        this.m_busy_text = '';
    }

    private GetDailyCorrespondencesCompleted(dcs: Array<WFDailyCorrespondence>, showentry: boolean): void {
        try {
            let entryadp = new WFDailyCorrespondenceAdp(this.m_cache_srv, dcs[0]);

            if (showentry) {
                const self = this;
                const wnd = this.m_wnd_srv.showControl(EditDailyCorrespondenceComponent);
                wnd.instance.SetPrms(entryadp);
                (<EditDailyCorrespondenceComponent>wnd.instance).onClosed.subscribe((ret) => {
                    wnd.destroy();
                    self.EditDailyCorrClosed(wnd.instance, ret);
                });
            } else {
                this.m_global_srv.App.ShowDocumentList(entryadp.Documents, this.m_report);
            }

        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
        this.m_busy_text = '';
    }

    private EditDailyCorrClosed(dlg: EditDailyCorrespondenceComponent, ret: boolean): void {
        try {
            if (ret && dlg.NeedRefreshAfterClose) {
                this.RefreshData();
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public gridDocsCellClick(dataItem: Object): void {
        try {
            let id = this.findID(dataItem);
            if (id > 0) {
                const self = this;
                this.m_busy_text = this.m_strdata_srv.getStr('strWaitData');
                this.m_data_srv.getDailyCorrespondences(this.m_cache_srv.SessionID, [id], this.m_onerrorevh, (ret) => {
                    self.GetDailyCorrespondencesCompleted(ret, false);
                });
            }
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    public CopyToClipboard(): void {
        try {
            let dta= "";
            let ii: number;

            for(ii=0; ii < this.m_columns.length; ii++) {
                if(ii > 0) dta+= '\t';
                let col= this.m_columns[ii];
                dta+= IWFObject.Format('"{0}"', (IWFObject.IsNullOrEmpty(col.label)) ? col.uniquename : col.label);
            }
        
            for(let row of this.m_rawdata) {
                dta+= '\r\n';
                for(ii=0; ii < this.m_columns.length; ii++) {
                    if(ii > 0) dta+= '\t';
                    dta+= '"';
                    let col= this.m_columns[ii];
                    let obj= row[col.field];
                    if(obj!= null) { 
                        if(typeof obj==='number') {
                            let strval= obj.toLocaleString();
                            strval= strval.replace(/\s/g, '');
                            dta+= strval;
                        } else {
                            let strval= obj.toString();
                            strval= strval.replace(/"/g, '""');
                            dta+= strval.replace(/\t/g,' ');
                        }
                    }
                    dta+= '"';
                }
            }

            let blob= new Blob([dta], {type: 'text/plain'});
            let itm= new ClipboardItem({'text/plain': blob});
            
            let nav: any= window.navigator;
            nav.clipboard.write([itm]);
        } catch (ex) {
            this.m_global_srv.manageException(ex);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.m_grheight = window.innerHeight - (44 + ((this.m_inf_props.length > 0) ? 26 : 0));
    }
}

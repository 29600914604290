import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { GlobalService } from '../global/global.service';
import { WFOcrProcessLog } from '../model';
import { DataService } from '../data/data.service';
import { StringDataService } from '../stringdata/stringdata.service';
import { CacheService } from '../data/cache.service';
import { EventAdp } from '../data/eventadp';
import { _OnError, WFDocumentAdp } from '../adapters/WFDocumentAdp';

interface OcrProcessLogSpec {
  order: number;
  isconf: boolean;
  createdat: string;
  message: string;
}

@Component({
  selector: 'app-ocr-process-logs',
  templateUrl: './ocr-process-logs.component.html',
  styleUrls: ['./ocr-process-logs.component.scss']
})

export class OcrProcessLogsComponent implements AfterViewInit {
  public m_busy_str: string;
  private m_document_id: number;
  public m_logs: Array<OcrProcessLogSpec>;
  private m_onerrorevh: EventAdp<_OnError>;

  @Output() public onClosed: EventEmitter<boolean>;

  //

  constructor(private m_global_srv: GlobalService,
    private m_data_srv: DataService,
    private m_str_srv: StringDataService,
    private m_cache_srv: CacheService) {
    this.m_busy_str = "";
    this.m_document_id = 0;
    this.m_logs = new Array<OcrProcessLogSpec>();
    this.onClosed = new EventEmitter<boolean>();
    const self = this;
    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });
  }

  public SetPrms(doc_id: number): void {
    this.m_document_id = doc_id;
  }

  public FormatDate(dta: Date): string {
    return;
  }

  public ngAfterViewInit(): void {
    try {
      this.m_busy_str = this.m_str_srv.getStr('strWaitData');

      const self = this;
      this.m_data_srv.getOcrLogs(this.m_cache_srv.SessionID, this.m_document_id, 100,
        this.m_onerrorevh, (logs) => {
          for (let plog of logs) {
            this.m_logs.push({
              order: plog.Order,
              isconf: !plog.IsRead,
              createdat: WFDocumentAdp.DateTimeToStr(plog.CreatedAt, this.m_str_srv),
              message: plog.Message
            });
          }
          self.m_busy_str = '';
        });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public OKButton_Click(): void {
    try {
      let orders = new Array<number>();

      for (let ln of this.m_logs) {
        if (ln.isconf) orders.push(ln.order);
      }

      if (orders.length > 0) {
        this.m_busy_str = this.m_str_srv.getStr('strDataUpdate');
        const self = this;
        this.m_data_srv.confirmOcrLogs(this.m_cache_srv.SessionID, this.m_document_id, orders, this.m_onerrorevh, (conf) => {
          self.m_busy_str = '';
          self.onClosed.emit(true);
        });
      } else {
        this.onClosed.emit(true);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

}

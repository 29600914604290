import { Component, OnInit, EventEmitter, AfterViewInit, Output, ViewChild, HostListener, ElementRef } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, MailMessageInfo, MailMsgPreview } from '../data/data.service';
import { BUTTONSTYPE, GlobalService } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { GridComponent, SelectionEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, DataResult, GroupDescriptor, process, SortDescriptor } from '@progress/kendo-data-query';
import { FetchMails, WFMailFolderAdp } from '../adapters/WFMailFolderAdp';
import { EventAdp } from '../data/eventadp';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { DatePipe } from '@angular/common';
import { ComboIValueDesc, TabOperation } from '../app.component';
import { IWFObject, WFDocStatus, WFDocument } from '../model';
import { AddNewDocFromMailComponent } from '../add-new-doc-from-mail/add-new-doc-from-mail.component';
import { DocumentList } from '../data/documentlist';
import { ImapLoginComponent } from '../imap-login/imap-login.component';

interface MailMessageSpec {
    uniqueid: number;
    reciveDate: string;
    mail: MailMessageInfo;
    doclist: DocumentList;
}

interface MailAttachmentSpec {
    label: string;
    image: string;
    attachment: any;
}

enum PreviewContentType {
  NONE,
  TEXT,
  HTML
}

@Component({
  selector: 'app-mail-box-viewer',
  templateUrl: './mail-box-viewer.component.html',
  styleUrls: ['./mail-box-viewer.component.scss']
})

export class MailBoxViewerComponent implements AfterViewInit {
  public m_busy_text: string;

  private m_mailfolder: WFMailFolderAdp;
  
  private m_onfetchmails: FetchMails;

  public m_groups: Array<GroupDescriptor>;
  public m_sort: Array<SortDescriptor>;
  public m_filter: CompositeFilterDescriptor;

  public m_rawdata: Array<MailMessageSpec>;
  public m_griddta: DataResult;

  public m_grheight: number;

  @Output() afterInit: EventEmitter<MailBoxViewerComponent>;
  @ViewChild('m_dataview', {static: false}) private m_dataview: GridComponent;
  
  private m_onerrorevh: EventAdp<_OnError>;
  public m_pagesc_info: string;
  public m_pages: Array<ComboIValueDesc>;
  public m_actual_page: ComboIValueDesc;

  public m_pagekeywords: string;
  public m_pagekeywords_err: boolean;
  private m_attexpand: boolean;

  private m_previewuniqueid: number;
  public m_previewctxtype: PreviewContentType;
  public m_previewmsg: MailMsgPreview;
  public m_previewatt: Array<MailAttachmentSpec>;

  @ViewChild('m_link', {static: false}) private m_link: ElementRef;

  public m_selected: Array<number>;
  public m_only_unread: boolean;
  public m_only_unbind: boolean;

  @Output() selectMail: EventEmitter<MailBoxViewerComponent>;

  //

  public set MailFolder(value: WFMailFolderAdp) {
    this.SetMailBoxSource(value);
  }

  public get MailFolder(): WFMailFolderAdp {
    return this.m_mailfolder;
  }

  public get Only_UnRead(): boolean {
    return this.m_only_unread;
  }

  public set Only_UnRead(nval: boolean) {
    this.m_only_unread= nval;
  }

  public get Only_UnBind(): boolean {
    return this.m_only_unbind;
  }

  public set Only_UnBind(nval: boolean) {
    this.m_only_unbind= nval;
  }

  public get PreviewMsg(): MailMsgPreview {
    return this.m_previewmsg;
  }

  constructor(protected m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_busy_text = '';
    this.m_mailfolder = null;

    const self = this;
    this.m_onfetchmails = (snd, page, dta, count) => { self.OnFetchMails(snd, page, dta, count); };

    this.afterInit = new EventEmitter<MailBoxViewerComponent>();

    this.m_groups = new Array<GroupDescriptor>();
    this.m_sort = new Array<SortDescriptor>();
    this.m_filter = null;

    this.m_rawdata = null;
    this.m_griddta = null;

    this.m_onerrorevh = new EventAdp<_OnError>();
    this.m_onerrorevh.subscribe(() => { 
      self.m_busy_text = ''; 
    });

    this.m_grheight = 0;
    this.m_pagesc_info= '';
    this.m_pages= new Array<ComboIValueDesc>();
    this.m_actual_page= null;

    this.m_pagekeywords= '';
    this.m_pagekeywords_err= false;
    this.m_attexpand = false;

    this.m_previewuniqueid= 0;
    this.m_previewctxtype= PreviewContentType.NONE;
    this.m_previewmsg= null;
    this.m_previewatt= new Array<MailAttachmentSpec>();

    this.m_selected= new Array<number>();

    this.m_only_unread= true;
    this.m_only_unbind= false;

    this.selectMail = new EventEmitter<MailBoxViewerComponent>();
  }

  ngAfterViewInit() {
    this.afterInit.emit(this);
    this.onResize(null);
  }

  private BindToGrid(): void {
    this.m_griddta = process(this.m_rawdata, {
        group: this.m_groups,
        sort: this.m_sort,
        filter: this.m_filter
    });
  }

  public groupChange(groups: Array<GroupDescriptor>): void {
      this.m_groups = groups;
      this.BindToGrid();
  }

  public sortChange(sort: Array<SortDescriptor>): void {
      this.m_sort = sort;
      this.BindToGrid();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
      this.m_filter = filter;
      this.BindToGrid();
  }
 
  public selectionChange(evh: SelectionEvent): void {
    try {
      if(evh.selectedRows.length > 0) {
        let rw= evh.selectedRows[0];
        let msg= <MailMessageSpec>rw.dataItem;
        this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
        this.m_attexpand = false;
        const self = this;
        this.m_data_srv.previewMail(this.m_cache_srv.SessionID, this.m_mailfolder.Name, msg.uniqueid,
          this.m_mailfolder.Login, this.m_mailfolder.GetEncPassword(),
          this.m_onerrorevh, (msg2)=> {
            self.OnPreviewMail(msg, msg2);
            if(!msg.mail.seen) {
              msg.mail.seen= true;
              self.m_mailfolder.DecUnread();
            }
          });
        //this.m_mailfolder.PreviewMail(this.m_cache_srv, msg.uniqueid);    
      }
      return;     
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_text = '';
  }

  public SetMailBoxSource(value: WFMailFolderAdp): void {
    if (this.m_mailfolder !== value) {
        if (this.m_mailfolder != null) {
            this.m_mailfolder.OnError.unsubscribe2(this.m_onerrorevh);
            this.m_mailfolder.OnFetchMails = null;
        }

        this.m_mailfolder = value;
        this.m_rawdata = null;
        this.m_griddta = null;
        this.m_groups.splice(0, this.m_groups.length);
        this.m_sort.splice(0, this.m_sort.length);
        this.m_filter = null;
        this.m_only_unread= true;
        this.m_only_unbind= false;

        if (this.m_mailfolder != null) {
            this.m_mailfolder.OnFetchMails = this.m_onfetchmails;
            this.RefreshData();
        }
       
        this.m_mailfolder.OnError.subscribe2(this.m_onerrorevh);
    } else {
        this.RefreshData();
    }

    this.onResize(null);
  }

  private ClearPreview(): void {
    this.m_previewuniqueid= 0;
    this.m_previewmsg= null;
    this.m_previewctxtype= PreviewContentType.NONE;
    this.m_previewatt.splice(0, this.m_previewatt.length);    
    this.m_selected.splice(0, this.m_selected.length);
    this.selectMail.emit(this);
  }

  private fetchMails(page:number, kwords: string): void { 
    if(this.m_mailfolder.PasswordRequired) {      
      const self = this;

      let loginwnd = this.m_wnd_srv.showControl(ImapLoginComponent);
      loginwnd.instance.SetPrms(this.m_mailfolder.Login);
      loginwnd.instance.onClosed.subscribe((ret) => {
          if(ret) {
            try {
              self.m_busy_text = self.m_strdata_srv.getStr('strUserDataUpdate');
              self.m_mailfolder.FetchMails(self.m_cache_srv, page, kwords, 
                                        loginwnd.instance.Login,
                                        loginwnd.instance.Pass, 
                                        loginwnd.instance.Remember,
                                        self.m_only_unread,
                                        self.m_only_unbind);
            } catch(ex) {
              self.m_global_srv.manageException(ex);
            }
          }
          loginwnd.destroy();
          loginwnd = null;
      });

      return;
    }

    this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
    this.m_mailfolder.FetchMails(this.m_cache_srv, page, kwords, null, null, false, this.m_only_unread, this.m_only_unbind);
  }

  public RefreshData(): void {    
    this.m_pagekeywords= '';
    this.m_pagekeywords_err= false;

    this.ClearPreview();
    this.fetchMails(0, this.m_pagekeywords);  
  }

  private OnFetchMails(snd: WFMailFolderAdp, page: number, dta: Array<MailMessageInfo>, count: number): void {
  
    try {
        if (dta == null || dta.length === 0) {
            this.m_griddta = null;            
        } else {            
            let dtap = new DatePipe('en-US');         

            this.m_rawdata = new Array<MailMessageSpec>();
            for(let ml of dta) {
                let dlst= new DocumentList();

                if(ml.documents_id.length > 0)
                  dlst.Add2(ml.documents_id);                

                this.m_rawdata.push({
                    uniqueid: ml.uniqueid,
                    reciveDate: dtap.transform(ml.date, 'dd-MM-yyyy hh:mm:ss'),
                    mail: ml,
                    doclist: dlst
                });
            }

            this.BindToGrid();
        }

        if(page== 0) {
            this.m_pages.splice(0, this.m_pages.length);

            if(count > 0) {
                let pagesc= (count / this.m_mailfolder.PerPage) | 0;
                if((count % this.m_mailfolder.PerPage) > 0) pagesc++;
        
                for(let ii= 0; ii< pagesc; ii++) {    
                    this.m_pages.push({
                        val: ii,
                        desc: (ii + 1).toString()
                    });
                }
        
                this.m_actual_page= this.m_pages[0];
                this.m_pagesc_info= IWFObject.Format('/{0}', pagesc);
            } else {    
                this.m_actual_page= null;
                this.m_pagesc_info= '';
            }
        }
    } catch (ex) {
        this.m_global_srv.manageException(ex);
    }

    this.m_busy_text = '';
  }


  private GroupsOP(pth: string, arr: Array<any>, exp: boolean): void {
      for (let ii = 0; ii < arr.length; ii++) {
          let pth2 = pth + ii.toString();
          
          if (exp)
              this.m_dataview.expandGroup(pth2);
          else
              this.m_dataview.collapseGroup(pth2);

          let sobj = arr[ii];
          if('items' in sobj) this.GroupsOP(pth2 + '_', sobj.items, exp);            
      }
  }

  public ExpandGroups(): void {
      try {
          if (this.m_groups.length > 0) {
              this.GroupsOP('', this.m_griddta.data, true);
          }
      } catch (ex) {
          this.m_global_srv.manageException(ex);
      }
  }

  public CollapseGroups(): void {
      try {
          if (this.m_groups.length > 0) {
              this.GroupsOP('', this.m_griddta.data, false);
          }
      } catch (ex) {
          this.m_global_srv.manageException(ex);
      }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.m_grheight = window.innerHeight - (44 + 35);
  }

  public ClickPrevPage(): void {
    try {
      if(this.m_actual_page!= null && this.m_actual_page.val > 0) {
        this.ClearPreview();

        let pii= this.m_actual_page.val - 1;
        //this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
        //this.m_mailfolder.FetchMails(this.m_cache_srv, pii, this.m_pagekeywords); 
        this.fetchMails(pii, this.m_pagekeywords);
        this.m_actual_page= this.m_pages[pii];
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }   
  }

  public ClickNextPage(): void {
    try {
        if(this.m_actual_page!= null && (this.m_actual_page.val + 1) < this.m_pages.length) {
          this.ClearPreview();

          let pii= this.m_actual_page.val + 1;
          //this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
          //this.m_mailfolder.FetchMails(this.m_cache_srv, pii, this.m_pagekeywords); 
          this.fetchMails(pii, this.m_pagekeywords);
          this.m_actual_page= this.m_pages[pii];
        }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public PageChanged(nval: ComboIValueDesc): void {
    try {   
      this.ClearPreview();   
      //this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
      //this.m_mailfolder.FetchMails(this.m_cache_srv, nval.val, this.m_pagekeywords); 
      this.fetchMails(nval.val, this.m_pagekeywords);
      this.m_actual_page = nval;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickFindOnPage(): void {
    try {  
        this.ClearPreview();    
        let kv= this.m_pagekeywords.trim();
        //this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');
        //this.m_mailfolder.FetchMails(this.m_cache_srv, 0, kv); 
        this.fetchMails(0, kv);
    } catch (ex) {
        this.m_global_srv.manageException(ex);
    }
  }

  private OnPreviewMail(spc: MailMessageSpec, msg:MailMsgPreview): void {  
    this.m_busy_text = '';
    this.m_previewuniqueid= spc.uniqueid;
    this.m_previewmsg= msg;

    try {      
      /*this.m_previewctxtype= PreviewContentType.TEXT;  
      if(IWFObject.IsNullOrEmpty(this.m_previewmsg.txt)) 
          this.m_previewmsg.txt= this.m_previewmsg.html;
      return;*/

      this.m_previewatt.splice(0, this.m_previewatt.length);

      for(let att of this.m_previewmsg.attachments) {
       
        let img= 'file_copy-24px.svg';
        switch(att.contenttype.toLowerCase()) {
            case 'application/pdf':
              img= 'picture_as_pdf-24px.svg';
              break;
        }
        this.m_previewatt.push({
          image: img,
          label: att.filename,
          attachment: att
        });
      }

      // let lastrow: Array<MailAttachmentSpec> = null;
      // for(let att of this.m_previewmsg.attachments) {
       
      //   let img= 'file_copy-24px.svg';
      //   switch(att.contenttype.toLowerCase()) {
      //       case 'application/pdf':
      //         img= 'picture_as_pdf-24px.svg';
      //         break;
      //   }

      //   if(lastrow== null) {
      //     lastrow= new Array<MailAttachmentSpec>();
      //     this.m_previewatt.push(lastrow);
      //   }

      //   lastrow.push({
      //     image: img,
      //     label: att.filename,
      //     attachment: att
      //   });

      //   if(lastrow.length == 6) lastrow= null;
      // }

      this.m_global_srv.App.ShowDocumentList(spc.doclist, this.m_mailfolder);      
      this.selectMail.emit(this);

      if(!IWFObject.IsNullOrEmpty(msg.html)) {
        this.m_previewmsg.html= IWFObject.RemoveHRefs(msg.html);
        this.m_previewctxtype= PreviewContentType.HTML;  
        return;
      }
      
      if(!IWFObject.IsNullOrEmpty(msg.txt)) {
        this.m_previewctxtype= PreviewContentType.TEXT;  
        return;
      }

      this.m_previewctxtype= PreviewContentType.NONE;
    } catch (ex) {
        this.m_global_srv.manageException(ex);
    }
  }

  public ClickPreviewAttachment(att: MailAttachmentSpec): void {    
    try {
      this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');      
      //console.log(att.label+" " +att.attachment.filename);
      const self= this;
      this.m_data_srv.prepareAttachment(this.m_cache_srv.SessionID, 
        this.m_mailfolder.Name, 
        this.m_previewuniqueid,
        att.attachment.contentid,
        this.m_mailfolder.Login, this.m_mailfolder.GetEncPassword(),
        this.m_onerrorevh, (sig)=> {
          self.PreviewAttachmentCompleted(att, sig);
        });
      return;
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
    this.m_busy_text = '';
  }

  private PreviewAttachmentCompleted(att: MailAttachmentSpec, sig: string): void {
    //console.log("PreviewAttachmentCompleted: "+att.label+" " +att.attachment.filename);
    this.m_busy_text = '';
    this.m_link.nativeElement.href= this.m_data_srv.ServiceURL + '/GETRES/' + sig + '/' + att.attachment.filename;
    this.m_link.nativeElement.click();
  }

  private ShowDocArr(docs: Array<WFDocument>, tout: number, spc:MailMessageSpec): void {
    let doc= docs[0];
    docs.splice(0, 1);
    
    const self= this;
    setTimeout(()=> {
      self.ShowNewDocument(doc);
      if(docs.length > 0) { 
        self.ShowDocArr(docs, 1000, spc);
      } else {
        self.m_global_srv.App.ShowDocumentList(spc.doclist, this.m_mailfolder);
      }
    }, tout);
  }

  public AddDocument(): void {  
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(AddNewDocFromMailComponent);
      const inst: AddNewDocFromMailComponent = wnd.instance;
      inst.SetPrms(this.m_previewmsg, this.m_mailfolder.Name, this.m_previewuniqueid, this.m_mailfolder);

      let spc= this.m_griddta.data.find((t)=> t.uniqueid == this.m_previewuniqueid);

      inst.onClosed.subscribe((ret) => {
        wnd.destroy();
        if(ret!= null) {             
          for(let doc of ret) {
            spc.mail.documents_id.push(doc.ID);
            spc.doclist.Add(doc.ID);
          }

          self.ShowDocArr(ret, 100, spc);
          //for(let dc of ret)
          //  self.ShowNewDocument(dc);
        }
      });

    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private ShowNewDocument(doc: WFDocument): void {
    try {
      let adp = new WFDocumentAdp(this.m_cache_srv, doc, true, false);
      this.m_cache_srv.AddDocument(adp);

      switch(adp.DocStatus) {
        case WFDocStatus.BEFOREOCR:
          this.m_global_srv.App.BeforeOcr.Add(adp.ID);
          break;
        case WFDocStatus.INOCR:
          this.m_global_srv.App.InOcr.Add(adp.ID);
          break;
        case WFDocStatus.AFTEROCR:
          this.m_global_srv.App.AfterOcr.Add(adp.ID);
          break;
      }

      this.m_global_srv.App.SelectDocument(adp, TabOperation.NEW);      
    } catch(ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public showDocuments(ml: MailMessageSpec, ev: any): void {
      try {
        this.m_global_srv.App.ShowDocumentList(ml.doclist, ml);
        //ev.stopPropagation();
      } catch(ex) {
        this.m_global_srv.manageException(ex);
      }
  }

  public MarkSeenFlag(seen: boolean): void {    
    try {

      this.m_busy_text = this.m_strdata_srv.getStr('strUserDataUpdate');      
      
      const self= this;
      this.m_data_srv.markSeenFlag(this.m_cache_srv.SessionID, 
        this.m_mailfolder.Name, 
        this.m_selected,
        seen,
        this.m_mailfolder.Login, this.m_mailfolder.GetEncPassword(),
        this.m_onerrorevh, ()=> {
          self._markSeenFlagCompleted(seen);
        });
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private _markSeenFlagCompleted(seen: boolean): void {
    try {
      this.m_busy_text= '';

      for(let uid of this.m_selected) {
        let frow= this.m_rawdata.find((t)=> t.uniqueid== uid);
        if(frow.mail.seen!= seen) {
          frow.mail.seen= seen;
          if(seen)
            this.m_mailfolder.DecUnread();
          else
            this.m_mailfolder.IncUnread();
        }
      }

    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

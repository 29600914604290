import { IWFObject } from './IWFObject';

export class WFOcrProcessLog {
    private m_documents_id: number;
    private m_order: number;
    private m_isread: boolean;
    private m_message: string;
    private m_createdat: Date;

    //

    constructor(obj: any) {
        this.m_documents_id = obj.documents_id;
        this.m_order = obj.order;
        this.m_isread = obj.isread;
        this.m_message = obj.message;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
    }

    //

    public get DocumentsID(): number {
        return this.m_documents_id;
    }

    public get Order(): number {
        return this.m_order;
    }

    public get IsRead(): boolean {
        return this.m_isread;
    }

    public get Message(): string {
        return this.m_message;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }
}
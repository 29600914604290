import { WFAuthStatus } from './WFDocument';
import { WFDocumentAuthSchema } from './WFDocumentAuthSchema';
import { IWFObject } from './IWFObject';

export enum WFDocAuthObjType {
    USER,
    DOCAUTHSCHEMA
}

export class WFDocumentAuthRecipient {
    private m_objid: number;
    private m_objtype: WFDocAuthObjType;
    private m_order: number;
    private m_authstatus: WFAuthStatus;
    private m_approved: Date;
    private m_comment: string;
    private m_visibility: boolean;
    private m_subschema: WFDocumentAuthSchema;
    private m_addinfo: string;
    private m_auth_user_id: number;
    private m_instruction_int_id: number;

    //

    constructor(obj: any) {
        this.m_objid = obj.objid;
        this.m_objtype = obj.objtype;
        this.m_order = obj.order;
        this.m_authstatus = obj.authstatus;
        this.m_approved = IWFObject.ParseDate(obj.approved);
        this.m_comment = obj.comment;
        this.m_visibility = obj.visibility;

        if (this.m_objtype === WFDocAuthObjType.DOCAUTHSCHEMA)
            this.m_subschema = new WFDocumentAuthSchema(obj.subschema);
        else
            this.m_subschema = null;

        this.m_addinfo = obj.addinfo;
        this.m_auth_user_id = obj.auth_user_id;
        this.m_instruction_int_id = obj.instruction_int_id;
    }

    public static Create(usrid: number, order: number, addinfo: string, insintid: number): WFDocumentAuthRecipient {
        return new WFDocumentAuthRecipient({
            objid: usrid,
            objtype: WFDocAuthObjType.USER,
            order: order,
            authstatus: WFAuthStatus.WAITING,
            approved: new Date(),
            comment: null,
            visibility: false,
            subschema: null,
            addinfo: (addinfo == null) ? '' : IWFObject.CutString(addinfo, 4000),
            auth_user_id: 0,
            instruction_int_id: insintid
        });
    }

    public static Create2(shm: WFDocumentAuthSchema, order: number, addinfo: string, insintid: number) {
        return new WFDocumentAuthRecipient({
            objid: shm.ID,
            objtype: WFDocAuthObjType.DOCAUTHSCHEMA,
            order: order,
            authstatus: shm.GetStatus(),
            approved: new Date(),
            comment: null,
            visibility: false,
            subschema: shm.ToObject(),
            addinfo: (addinfo == null) ? '' : IWFObject.CutString(addinfo, 4000),
            auth_user_id: 0,
            instruction_int_id: insintid
        });
    }

    //

    public get ObjID(): number {
        return this.m_objid;
    }

    //

    public get ObjType(): WFDocAuthObjType {
        return this.m_objtype;
    }

    //

    public get Order(): number {
        return this.m_order;
    }

    //

    public ChangeStatus(apr: boolean, cmm: string, user_id: number): boolean {
        this.m_authstatus = (apr) ? WFAuthStatus.APPROVED : WFAuthStatus.DISAPPROVED;
        this.m_comment = IWFObject.CutString(cmm, 1024);
        this.m_approved = new Date();
        this.m_auth_user_id = user_id;
        return true;
    }

    //

    public get Status(): WFAuthStatus {
        if (this.m_objtype === WFDocAuthObjType.DOCAUTHSCHEMA) return this.m_subschema.GetStatus();
        return this.m_authstatus;
    }

    //

    public get Approved(): Date {
        return this.m_approved;
    }

    //

    public get Comment(): string {
        return this.m_comment;
    }

    //

    public set Visibility(nval: boolean) {
        this.m_visibility = nval;
    }

    public get Visibility(): boolean {
        return this.m_visibility;
    }

    //

    public get SubSchema(): WFDocumentAuthSchema {
        return this.m_subschema;
    }

    //

    public get AdditionalInfo(): string {
        return this.m_addinfo;
    }

    //

    public get AuthUserID(): number {
        return this.m_auth_user_id;
    }

    //

    public get InstructionInternalID(): number {
        return this.m_instruction_int_id;
    }

    //

    public ToObject(): any {
        return {
            objid: this.m_objid,
            objtype: this.m_objtype,
            order: this.m_order,
            authstatus: this.m_authstatus,
            approved: IWFObject.DateToString(this.m_approved),
            comment: this.m_comment,
            visibility: this.m_visibility,
            subschema: (this.m_objtype === WFDocAuthObjType.DOCAUTHSCHEMA) ? this.m_subschema.ToObject() : null,
            addinfo: this.m_addinfo,
            auth_user_id: this.m_auth_user_id,
            instruction_int_id: this.m_instruction_int_id
        };
    }
}

import { WFClassAttrib, WFFieldType, UpperMap } from '../model/index';
import { CacheService } from '../data/cache.service';
import { WFDocumentValidValues } from './WFDocumentValidValues';

export type PropertyValidation = (snd: IWFCompanyObjAdp, prop: string, status: boolean) => void;

export abstract class IWFCompanyObjAdp {
    protected m_conn: CacheService;
    protected m_ismodified: boolean;
    protected m_props: UpperMap;

    public OnPropertyValidation: PropertyValidation;

    //

    constructor(csrv: CacheService, ismod: boolean, prps: UpperMap) {
        this.m_conn = csrv;
        this.m_ismodified = ismod;
        this.m_props = prps;
        this.OnPropertyValidation = null;
    }

    public set IsModified(value: boolean) {
        if (this.m_ismodified !== value) {
            this.m_ismodified = value;
        }
    }

    public get IsModified(): boolean {
        return this.m_ismodified;
    }

    public abstract get CompanyID(): number;
    public abstract set CompanyID(id: number);

    public IsStrValid(sval: string, cl: WFClassAttrib): string {
        if (cl.Type === WFFieldType.TYPE_FLOAT)
            sval = sval.replace(',', '.');
        if (!cl.Validate(sval, this.CompanyID, this.m_conn.CompanyDicts, this.m_conn.AppSystem))
            return null;
        return sval;
    }

    public abstract CanEdit(atr: WFClassAttrib): boolean;

    protected _SetValue(key: string, value: string, checkprm: boolean, atrs: Array<WFClassAttrib>): string {
        key = key.toUpperCase();
        let sval: string = value.trim();
        if (atrs != null) {
            for (let att of atrs) {
                if (att.Name.toUpperCase() === key) {
                    if (checkprm) {
                        if (!this.CanEdit(att))
                            return null;
                    }
                    let sret: string = this.IsStrValid(sval, att);
                    if (sret == null) {
                        if (this.OnPropertyValidation == null) throw new Error();

                        if (sval.length > 0)
                            this.m_props.set(key, sval);
                        else this.m_props.delete(key);

                        this.OnPropertyValidation(this, key, false);
                        return null;
                    }
                    sval = sret;
                    break;
                }
            }
        }

        if (this.m_props.has(key)) {
            let oldval: string = this.m_props.get(key);
            if (oldval === sval)
                return null;
            if (sval.length > 0)
                this.m_props.set(key, sval);
            else this.m_props.delete(key);
        } else {
            if (sval.length > 0)
                this.m_props.set(key, sval);
            else
                return null;
        }

        if (this.OnPropertyValidation != null) this.OnPropertyValidation(this, key, true);
        return sval;
    }

    protected abstract SetValue(key: string, value: string, checkprm: boolean): void;

    public set(key: string, value: string): void {
        this.SetValue(key, value, true);
    }

    public get(key: string): string {
        key = key.toUpperCase();
        if (this.m_props.has(key))
            return this.m_props.get(key);
        return null;
    }

    public abstract EvalExCode(code: string, lid: number, rtp: WFFieldType): Object;

    public GetInteractiveDictDocMapper(container: Map<number, WFDocumentValidValues>, int_id: number): WFDocumentValidValues {
        if (container.has(int_id))
            return container.get(int_id);

        let dvv: WFDocumentValidValues = new WFDocumentValidValues(this, this.m_conn.Dictionaries.get(int_id));
        container.set(int_id, dvv);
        return dvv;
    }

    public get CacheService(): CacheService {
        return this.m_conn;
    }

    public getAsNumber(key: string): number {
        const strval: string = this.get(key);
        if (!isNaN(Number.parseFloat(strval))) {
            return Number.parseFloat(strval);
        } else return 0;
    }
}
import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ChildWindowContainerService } from './child-window-container.service';

export enum PopupPosition {
  TOP,
  BOTTOM,
  LEFT,
  RIGHT
}

@Component({
  selector: 'app-child-popup',
  templateUrl: './child-popup.component.html',
  styleUrls: ['./child-popup.component.scss']
})

export class ChildPopupComponent implements AfterViewInit {
  public m_zorder: number;
  public m_left: number;
  public m_top: number;
  public m_width: number;
  public m_height: number;
  private m_refelement: any;
  private m_position: PopupPosition;
  private m_offx: number;
  private m_offy: number;
  public m_class: string[];

  //

  @ViewChild('m_bck_layer', {static: false}) private m_bck_layer: ElementRef;

  //

  get refelement(): any {
    return this.m_refelement;
  }

  @Input('refelement')
  set refelement(val: any) {
    if (this.m_refelement != val) {
      this.m_refelement = val;
      this.calcOffset();
    }
  }

  get width(): number {
    return this.m_width;
  }

  @Input('width')
  set width(val: number) {
    if (val < 100) val = 100;
    this.m_width = val;
  }

  get height(): number {
    return this.m_height;
  }

  @Input('height')
  set height(val: number) {
    if (val < 100) val = 100;
    this.m_height = val;
  }

  get position(): PopupPosition {
    return this.m_position;
  }

  @Input('position')
  set position(val: PopupPosition) {
    if (this.m_position !== val) {
      this.m_position = val;
      this.calcOffset();
    }
  }

  get offsetX(): number {
    return this.m_offx;
  }

  @Input('offsetX')
  set offsetX(val: number) {
    if (this.m_offx !== val) {
      this.m_offx = val;
      this.calcOffset();
    }
  }

  get offsetY(): number {
    return this.m_offy;
  }

  @Input('offsetY')
  set offsetY(val: number) {
    if (this.m_offy !== val) {
      this.m_offy = val;
      this.calcOffset();
    }
  }

  @Input('class')
  set class(val: string[]) {    
    this.m_class = val;
  }
  get class(): string[] {
    return this.m_class;
  }


  //

  public contentTemplate: TemplateRef<any>;
  @Output() closePopup: EventEmitter<void>;

  private m_initialized: boolean;
  //

  constructor(private childwndsrv: ChildWindowContainerService) {
    this.m_zorder = childwndsrv.getNewLayer();
    this.m_left = 0;
    this.m_top = 0;
    this.closePopup = new EventEmitter<void>();
    this.m_width = 100;
    this.m_height = 100;
    this.contentTemplate = null;
    this.m_position = PopupPosition.BOTTOM;
    this.m_offx = this.m_offy = 0;
    this.m_class = [];
    this.m_initialized = false;
  }

  public ngAfterViewInit(): void {
    this.m_initialized = true;
    this.correctPos();
  }

  private correctPos(): void {
    //var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //var height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if(this.m_initialized) {
      if(this.m_left < 0) { 
        this.m_left= 0;
      } else {
        if((this.m_left + this.m_width) > this.m_bck_layer.nativeElement.clientWidth) this.m_left = this.m_bck_layer.nativeElement.clientWidth - this.m_width; 
      }

      if(this.m_top < 0) { 
        this.m_top= 0;
      } else {
        if((this.m_top + this.m_height) > this.m_bck_layer.nativeElement.clientHeight) this.m_top = this.m_bck_layer.nativeElement.clientHeight - this.m_height; 
      }
    }
  }

  private calcOffset(): void {
    if (this.m_refelement == null) {
      this.m_left = 0;
      this.m_top = 0;
    } else {
      let rect = this.m_refelement.getBoundingClientRect();
      let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let offx = rect.left + scrollLeft;
      let offy = rect.top + scrollTop;
      /*let pr = this.m_refelement;

      while (pr) {
        offx += pr.offsetLeft - pr.scrollLeft + pr.clientLeft;
        offy += pr.offsetTop - pr.scrollTop + pr.clientTop;
        pr = pr.offsetParent;
      }*/
      
      if (this.m_position === PopupPosition.TOP) {
        this.m_left = offx + this.m_offx;
        this.m_top = ((offy > this.height) ? offy - this.height : 0) + this.m_offy;
        this.correctPos();
        return;
      } 
      
      if (this.m_position === PopupPosition.BOTTOM) {
        this.m_left = offx + this.m_offx;
        this.m_top = offy + this.m_refelement.clientHeight + + this.m_offy;
        this.correctPos();
        return;
      }

      this.m_top = offy + this.m_offy;

      if (this.m_position === PopupPosition.LEFT) {
        this.m_left = offx - this.width + this.m_offx;
        if (this.m_left < 0) this.m_left = 0;
      } else {
        this.m_left = offx + this.m_refelement.clientWidth + this.m_offx;
      }
      this.correctPos();
    }
  }
}

export * from './WFClass';
export * from './WFClassAttrib';
export * from './WFColumnFormat';
export * from './WFCompany';
export * from './WFUser';
export * from './WFUserGroup';
export * from './WFAuthSchema';
export * from './WFAuthInstruction';
export * from './WFReconSchema';
export * from './WFOcrDefinition';
export * from './WFInteractiveDictionary';
export * from './WFDictionary';
export * from './WFConfiguration';
export * from './WFCompanyDict';
export * from './WFCustomProcess';
export * from './WFProcessVarState';
export * from './WFSystem';
export * from './WFDocument';
export * from './WFDocumentAuthLock';
export * from './WFDocumentAuthRecipient';
export * from './WFDocumentAuthSchema';
export * from './WFDocumentAuthVariable';
export * from './WFDocumentLine';
export * from './WFDocumentModification';
export * from './WFDocumentOcrMap';
export * from './WFDocumentOcrResults';
export * from './WFDocumentPage';
export * from './WFDocumentRef';
export * from './WFPageComment';
export * from './WFCommentEntry';
export * from './WFMessage';
export * from './WFDirectory';
export * from './WFFilterInfo';
export * from './WFSchemaRecipient';
export * from './IWFCustomInstruction';
export * from './IWFObject';
export * from './WFProcessAction';
export * from './WFAuthPermission';
export * from './WFProcessVariable';
export * from './WFLineopInstruction';
export * from './WFScriptopInstruction';
export * from './WFDialogWndInstruction';
export * from './WFExcallopInstruction';
export * from './WFPermission';
export * from './WFDailyCorrespondenceClass';
export * from './WFDailyCorrespondence';
export * from './WFDailyCorrespondenceRef';
export * from './WFOcrTextRegion';
export * from './WFOcrResultPage';
export * from './WFOcrField';
export * from './WFOcrColumn';
export * from './WFInstructionParameter';
export * from './WFOcrProcessLog';



import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ComboIValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService, CodeName, CodeCodeName, BusinessPartnerAddress, BusinessPartner } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { IWFObject, WFPermission, WFAuthSchema, WFPrmObjType, WFDocumentAuthVarSchema, WFAuthVarSchemaRecipient, WFObjType, WFSchemaRecipient, WFClass, WFAuthPermission, WFDocument, WFAuthMethodType } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { ApprovalsComponent } from '../approvals/approvals.component';

export interface EditCustomAuthRowSpec {
  selected: boolean;
  order: ComboIValueDesc;
  objtype: ComboIValueDesc;
  objid_cmb: ComboboxadpComponent;
  rcp: WFSchemaRecipient;
}

@Component({
  selector: 'app-editauthschema',
  templateUrl: './editauthschema.component.html',
  styleUrls: ['./editauthschema.component.scss']
})
export class EditauthschemaComponent {
  public m_busy_str: string;

  public m_auth_type: Array<ComboIValueDesc>;
  public m_type: ComboIValueDesc;
  public m_rcplimit: string;
  public m_rcplimit_err: boolean;
  public m_showtree: boolean;
  public m_btnplus: boolean;
  public m_btnminus: boolean;
  public m_copyprm: boolean;
  public m_copycust: boolean;
  public m_recipients: Array<EditCustomAuthRowSpec>;

  public m_orders: Array<ComboIValueDesc>;
  public m_objtypes: Array<ComboIValueDesc>;
  public m_recipts_cnt: boolean;

  private m_doc: WFDocumentAdp;
  private m_varsts: WFDocumentAuthVarSchema;
  private m_readonly: boolean;

  @Output() public onClosed: EventEmitter<boolean>;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    let ii: number;
    this.m_busy_str = '';

    this.m_auth_type = new Array<ComboIValueDesc>();
    this.m_auth_type.push({ val: 0, desc: this.m_strdata_srv.getStr('wApprovals_OneFrom') });
    this.m_auth_type.push({ val: 1, desc: this.m_strdata_srv.getStr('wApprovals_All') });
    this.m_auth_type.push({ val: 2, desc: this.m_strdata_srv.getStr('wEditAuthSchema_XFrom') });
    this.m_type = this.m_auth_type[0];
    this.m_rcplimit = '';
    this.m_rcplimit_err = false;

    this.m_showtree = true;
    this.m_btnplus = true;
    this.m_btnminus = true;
    this.m_copyprm = true;
    this.m_copycust = true;
    this.m_recipients = new Array<EditCustomAuthRowSpec>();
    this.m_recipts_cnt = true;

    this.m_orders = new Array<ComboIValueDesc>(10);
    for (ii = 0; ii < this.m_orders.length; ii++) {
      this.m_orders[ii] = { val: ii, desc: ii.toString() };
    }

    this.m_objtypes = new Array<ComboIValueDesc>(3);
    this.m_objtypes[0] = { val: <number>WFObjType.USER, desc: this.m_strdata_srv.getStr('enumWFObjTypeUSER') };
    this.m_objtypes[1] = { val: <number>WFObjType.USERGROUP, desc: this.m_strdata_srv.getStr('enumWFObjTypeUSERGROUP') };
    this.m_objtypes[2] = { val: <number>WFObjType.AUTHSCHEMA, desc: this.m_strdata_srv.getStr('enumWFObjTypeAUTHSCHEMA') };

    this.m_doc = null;
    this.m_varsts = null;
    this.m_readonly = false;

    this.onClosed = new EventEmitter<boolean>();
  }

  //

  public SetPrms(doc: WFDocumentAdp, ronly: boolean): void {
    this.m_doc = doc;
    this.m_readonly = ronly;
    this.m_varsts = null;
    let cedit = (this.m_doc.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA) && this.m_doc.AuthSchemaID === WFDocument.SCHM_CUSTOM && !this.m_readonly);

    switch (doc.AuthMethodType) {
      case WFAuthMethodType.AUTH_ONEOF:
        this.m_type = this.m_auth_type[0];
        break;
      case WFAuthMethodType.AUTH_ALLOF:
        this.m_type = this.m_auth_type[1];
        break;
      case WFAuthMethodType.AUTH_NOFF:
        this.m_type = this.m_auth_type[2];
        break;
    }

    this.m_rcplimit = doc.AuthUserLimit.toString();

    let rcps: Array<WFSchemaRecipient>;
    this.m_copycust = false;

    if (doc.AuthSchemaID === WFDocument.SCHM_CUSTOM) {
      rcps = doc.CloneRecipients();
      this.m_recipts_cnt = cedit;
      this.m_btnplus = cedit;
      this.m_btnminus = cedit;
      this.m_copyprm = cedit;
    } else {
      if (doc.AuthSchemaID === WFDocument.SCHM_NOAUTH)
        rcps = doc.CloneRecipients();
      else
        rcps = this.m_cache_srv.AuthSchemas.get(doc.AuthSchemaID).Recipients;

      this.m_recipts_cnt = false;
      this.m_btnplus = false;
      this.m_btnminus = false;
      this.m_copyprm = false;

      if (this.m_doc.CanEdit3(WFAuthPermission.STDATR_AUTHSCHEMA) && !this.m_readonly) {
        if (this.m_doc.ClassID === 0) {
          this.m_copycust = true;
        } else {
          let cls: WFClass = this.m_cache_srv.Classes.get(this.m_doc.ClassID);
          if (cls.AllowCustomSchema) this.m_copycust = true;
        }
      }
    }

    //

    for (let rc of rcps) {
      this.AddNewRow(rc);
    }
  }

  public SetPrms2(doc: WFDocumentAdp, varsts: WFDocumentAuthVarSchema): void {
    this.m_doc = doc;
    this.m_varsts = varsts;
    this.m_showtree = false;
    this.m_copycust = false;
    this.m_copyprm = false;
    this.m_recipts_cnt = true;
    this.m_btnplus = true;
    this.m_btnminus = true;

    switch (this.m_varsts.SchemaType) {
      case WFAuthMethodType.AUTH_ONEOF:
        this.m_type = this.m_auth_type[0];
        break;
      case WFAuthMethodType.AUTH_ALLOF:
        this.m_type = this.m_auth_type[1];
        break;
      case WFAuthMethodType.AUTH_NOFF:
        this.m_type = this.m_auth_type[2];
        break;
    }

    this.m_rcplimit = this.m_varsts.AuthUserLimit.toString();

    for (let avsr of this.m_varsts.Recipients) {
      let src: WFSchemaRecipient = WFSchemaRecipient.Create(avsr.ObjID, avsr.ObjType);
      src.Order = avsr.Order;
      this.AddNewRow(src);
    }
  }

  //

  private CastRecipients(): Array<WFSchemaRecipient> {
    let ii: number;

    let rcps = new Array<WFSchemaRecipient>();
    for (ii = 0; ii < this.m_recipients.length; ii++) {
      let rc: EditCustomAuthRowSpec = this.m_recipients[ii];
      let rcp: WFSchemaRecipient = rc.rcp;
      rcp.Order = rc.order.val;
      rcp.ObjType = <WFObjType>rc.objtype.val;
      rcp.ObjID = IWFObject.ParseInt(rc.objid_cmb.SelectedValue);
      rcps.push(rcp);
    }

    return rcps;
  }

  public OKButton_Click(): void {
    try {
      if (this.m_recipts_cnt) {
        if (this.m_recipients.length === 0) {
          this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wEditAuthSchema_AddAtLeastOne'));
          return;
        }

        //
        let dorun = true;

        let authulimit = 0;
        try {
          authulimit = IWFObject.ParseInt(this.m_rcplimit.trim());
          this.m_rcplimit_err = false;
        } catch (ex2) {
          this.m_rcplimit_err = true;
          dorun = false;
        }

        //

        if (dorun) {
          let authmtype: WFAuthMethodType = WFAuthMethodType.AUTH_ALLOF;
          switch (this.m_type.val) {
            case 0:
              authmtype = WFAuthMethodType.AUTH_ONEOF;
              break;
            case 1:
              authmtype = WFAuthMethodType.AUTH_ALLOF;
              break;
            case 2:
              authmtype = WFAuthMethodType.AUTH_NOFF;
              break;
          }

          let rcps: Array<WFSchemaRecipient> = this.CastRecipients();

          //sprawdzenie ew błędów schematu
          let errInfo = '';
          if (this.m_doc.AuthMethodType === WFAuthMethodType.AUTH_ONEOF || this.m_doc.AuthMethodType === WFAuthMethodType.AUTH_NOFF) {
            let order = -1;
            for (let rcp of rcps) {
              if (order === -1 || order === rcp.Order) {
                order = rcp.Order;
              } else {
                errInfo = this.m_strdata_srv.getStr('wEditCustomAuth_msg1');
                break;
              }
            }
          }

          if (errInfo !== '') {
            this.m_global_srv.showWarning(BUTTONSTYPE.OK, IWFObject.Format(this.m_strdata_srv.getStr('wEditCustomAuth_msg2'), errInfo));
            return;
          }

          if (this.m_varsts == null) {
            this.m_doc.AuthUserLimit = authulimit;
            this.m_doc.AuthMethodType = authmtype;
            if (this.m_doc.AuthSchemaID !== WFDocument.SCHM_CUSTOM) {
              this.m_doc.SrcAuthSchemaID = this.m_doc.AuthSchemaID;
              this.m_doc.AuthSchemaID = WFDocument.SCHM_CUSTOM;
            }
            this.m_doc.SetRecipients(rcps);
          } else {
            this.m_varsts.AuthUserLimit = authulimit;
            this.m_varsts.SchemaType = authmtype;
            this.m_varsts.Recipients.splice(0, this.m_varsts.Recipients.length);
            for (let rc of rcps) {
              this.m_varsts.Recipients.push(WFAuthVarSchemaRecipient.Create2(rc));
            }
          }

          this.onClosed.emit(true);
        }
      } else {
        this.onClosed.emit(true);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  private ShowApprovals(rcps: Array<WFSchemaRecipient>): void {
    const self = this;
    const wnd = this.m_wnd_srv.showControl(ApprovalsComponent);
    const inst: ApprovalsComponent = <ApprovalsComponent>wnd.instance;
    inst.SetPrms(this.m_doc, rcps);
    inst.onClosed.subscribe((ret) => {
      wnd.destroy();
    });
  }

  public ShowTree(): void {
    try {
      if (this.m_rcplimit) {
        let rcps: Array<WFSchemaRecipient> = this.CastRecipients();
        if (rcps.length > 0) {
          this.ShowApprovals(rcps);
        }
      } else {
        this.ShowApprovals(null);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickAddNewRow(): void {
    try {
      if (this.m_recipts_cnt) {
        let objid = -1;
        let tp: WFObjType = WFObjType.USER;

        for (let usr of Array.from(this.m_cache_srv.Users.values())) {
          if (!usr.IsLocked) {
            objid = usr.ID;
            break;
          }
        }

        if (objid < 0) {
          if (this.m_cache_srv.UserGroups.size > 0) {
            objid = IWFObject.ElementAt(this.m_cache_srv.UserGroups.values(), 0).ID;
            tp = WFObjType.USERGROUP;
          } else {
            if (this.m_cache_srv.AuthSchemas.size > 0) {
              objid = IWFObject.ElementAt(this.m_cache_srv.AuthSchemas.values(), 0).ID;
              tp = WFObjType.AUTHSCHEMA;
            }
          }
        }

        if (objid > 0) this.AddNewRow(WFSchemaRecipient.Create(objid, tp));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickRemoveRows(): void {
    try {
      if (this.m_recipts_cnt &&
        this.m_recipients.length > 0) {
        const self = this;
        this.m_global_srv.showWarning(BUTTONSTYPE.OKCANCEL, this.m_strdata_srv.getStr('strDelCheckedLinesAsk'),
          (ret) => {
            self.RemoveRowsClosedAsk(ret);
          });
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private RemoveRowsClosedAsk(ret: WNDRESULT): void {
    try {
      if (ret === WNDRESULT.OKYES) {
        let ii = 0;
        while (ii < this.m_recipients.length) {
          let rcp = this.m_recipients[ii];
          if (rcp.selected) {
            //usun wiersz
            this.m_recipients.splice(ii, 1);
          } else {
            ii++;
          }
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickCopyPermissions(): void {
    try {
      if (this.m_recipts_cnt) {
        let cc = this.m_doc.GetPermissionsCount();
        for (let ii = 0; ii < cc; ii++) {
          let pr: WFPermission = this.m_doc.GetPermission(ii);
          this.AddNewRow(WFSchemaRecipient.Create(pr.ObjID, (pr.ObjType === WFPrmObjType.USER) ? WFObjType.USER : WFObjType.USERGROUP));
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ClickCopyCustom(): void {
    try {
      if (this.m_copycust) {
        this.m_recipts_cnt = true;
        this.m_btnplus = true;
        this.m_btnminus = true;
        this.m_copyprm = true;

        let rcps: Array<WFSchemaRecipient>;
        if (this.m_doc.AuthSchemaID === WFDocument.SCHM_NOAUTH) {
          rcps = this.m_doc.CloneRecipients();
        } else {
          let srcrcps: Array<WFSchemaRecipient> = this.m_cache_srv.AuthSchemas.get(this.m_doc.AuthSchemaID).Recipients;
          rcps = new Array<WFSchemaRecipient>();
          for (let src of srcrcps) {
            rcps.push(WFSchemaRecipient.Create2(src));
          }
        }

        this.m_recipients.splice(0, this.m_recipients.length);
        for (let rc of rcps) {
          this.AddNewRow(rc);
        }

        this.m_copycust = false;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  private AddNewRow(rcp: WFSchemaRecipient): void {
    this.m_recipients.push({
      selected: false,
      order: this.m_orders.find((tst) => tst.val === rcp.Order),
      objtype: this.m_objtypes.find((tst) => tst.val === rcp.ObjType),
      objid_cmb: null,
      rcp: rcp
    });
  }

  public InitComboBox(cmb: ComboboxadpComponent, rcp: EditCustomAuthRowSpec): void {
    rcp.objid_cmb = cmb;
    this.ObjTypeChanged(rcp.objtype, rcp);
  }

  public ObjTypeChanged(nval: ComboIValueDesc, rcp: EditCustomAuthRowSpec): void {
    rcp.objtype = nval;

    try {
      //ComboBox cmb2 = (ComboBox)sender;
      //int cmb3id = m_recipients.Children.IndexOf(cmb2);
      //ComboBoxAdp cmb3 = (ComboBoxAdp)m_recipients.Children[cmb3id + 1];
      rcp.objid_cmb.ClearValidValues();
      let rc: WFSchemaRecipient = rcp.rcp;
      let selval = '0';

      switch (<WFObjType>rcp.objtype.val) {
        case WFObjType.AUTHSCHEMA:
          let sshm= Array.from(this.m_cache_srv.AuthSchemas.values()).sort((a,b)=> IWFObject.Format('{0} {1}', a.Name.toUpperCase(), a.Description).localeCompare(IWFObject.Format('{0} {1}', b.Name.toUpperCase(), b.Description)));
          for (let shm of sshm) {
            if (shm.ID !== this.m_doc.AuthSchemaID) {
              rcp.objid_cmb.AddValidValue(shm.ID.toString(), IWFObject.Format('{0} {1}', shm.Name.toUpperCase(), shm.Description).trim());
              if (rc.ObjID === shm.ID &&
                rc.ObjType === WFObjType.AUTHSCHEMA) selval = shm.ID.toString();
            }
          }
          break;

        case WFObjType.USER:
          let sval = WFAuthSchema.OBJID_OWNER.toString();
          rcp.objid_cmb.AddValidValue(sval, '(owner)');
          if (rc.ObjID === WFAuthSchema.OBJID_OWNER && rc.ObjType === WFObjType.USER) selval = sval;

          sval = this.m_cache_srv.User.ID.toString();
          rcp.objid_cmb.AddValidValue(sval, this.m_cache_srv.User.FriendlyName);
          if (rc.ObjID === this.m_cache_srv.User.ID && rc.ObjType === WFObjType.USER) selval = sval;

          let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
          for (let usr of susr) {
            if (!usr.IsLocked) {
              rcp.objid_cmb.AddValidValue(usr.ID.toString(), usr.FriendlyName);
              if (rc.ObjID === usr.ID &&
                rc.ObjType === WFObjType.USER) selval = usr.ID.toString();
            }
          }
          break;

        case WFObjType.USERGROUP:
          let sgrp= Array.from(this.m_cache_srv.UserGroups.values()).sort((a,b)=> IWFObject.Format('{0} - {1}', a.Name.toUpperCase(), a.Description.trim()).localeCompare(IWFObject.Format('{0} - {1}', b.Name.toUpperCase(), b.Description.trim())));
          for (let grp of sgrp) {
            rcp.objid_cmb.AddValidValue(grp.ID.toString(), IWFObject.Format('{0} - {1}', grp.Name.toUpperCase(), grp.Description.trim()));
            if (rc.ObjID === grp.ID &&
              rc.ObjType === WFObjType.USERGROUP) selval = grp.ID.toString();
          }
          break;
      }

      rcp.objid_cmb.SelectedValue = selval;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { StringDataService } from './stringdata.service'

@Pipe({
  name: 'stringdata'
})

export class StringDataPipe implements PipeTransform {
  constructor(private strserv: StringDataService) {
  }

  transform(value: string, args?: any): string {
    //console.log('> '+value);
    let upper = false;
    if (args) {
      if (typeof args === 'boolean') {
        upper = <boolean>args;
      } else {
        if ('upper' in args) upper = args.upper;
      }
    }

    let dict = this.strserv.getDict();
    if (upper) {
      if (value in dict) return dict[value].toUpperCase();
    } else {
      if (value in dict) return dict[value];
    }
    
    return value;
  }

}

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { ButtonDef } from '../child-window/child-window.component';

export enum ALR_ICONTYPE {
  INFORMATION,
  WARNING,
  ERROR
}

@Component({
  selector: 'app-alert-wnd',
  templateUrl: './alert-wnd.component.html',
  styleUrls: ['./alert-wnd.component.scss']
})
export class AlertWndComponent {
  public m_typemap: Array<string> = [
    '_icoInfo',
    '_icoAlert',
    '_icoError'
  ];

  @Input() buttons: Array<ButtonDef>;
  @Input() icontype: ALR_ICONTYPE;
  @Input() content: string;
  @Input() can_cancel: boolean;

  @Output() clickButton: EventEmitter<number>;
  @Output() clickCancel: EventEmitter<void>;

  constructor() {
    this.can_cancel = true;
    this.buttons = new Array<ButtonDef>();
    this.icontype = ALR_ICONTYPE.INFORMATION;
    this.content = '';
    this.clickCancel = new EventEmitter<void>();
    this.clickButton = new EventEmitter<number>();
  }
}

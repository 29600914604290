import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { IWFObject, WFDocumentAuthVarSchema, WFDocumentAuthVarBasic, IWFDocumentAuthVariable, WFUser, WFUserGroup, WFInstPrmType, WFInstPrmObjType, WFAuthSchema, WFAuthInstruction, WFInstructionParameter, WFDocumentAuthSchema, WFAuthMethodType, WFDocumentAuthRecipient, WFAuthInsType } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { EditauthschemaComponent } from '../editauthschema/editauthschema.component';
import { ComboValueDesc } from '../app.component';

export interface ParamSpec {
  label: string;
  inst: WFInstructionParameter;
  iserror: boolean;
  value: string;
  subshm: WFDocumentAuthVarSchema;
}

export enum APPWNDRESULT {
  CANCEL,
  APPROVE,
  DISAPPROVE,
  RESERVE
}

@Component({
  selector: 'app-approve-wnd',
  templateUrl: './approve-wnd.component.html',
  styleUrls: ['./approve-wnd.component.scss']
})

export class ApproveWndComponent {
  private m_doc: WFDocumentAdp;
  private m_isreserve: boolean;
  public m_busy_str: string;
  public m_addinfo: string;
  public m_addinfoscrl: boolean;
  public m_params: Array<ParamSpec>;
  public m_message: string;
  public m_reservebtn: boolean;
  public m_reservebtn_ctx: string;
  public m_cancomm: boolean;
  private m_autosave: boolean;

  @Output() public onClosed: EventEmitter<APPWNDRESULT>;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService,
    private m_wnd_srv: ChildWindowContainerService) {

    this.m_doc = null;
    this.m_busy_str = '';
    this.m_addinfo = '';
    this.m_addinfoscrl = false;
    this.m_params = new Array<ParamSpec>();
    this.m_message = '';
    this.m_reservebtn = false;
    this.m_reservebtn_ctx = this.m_strdata_srv.getStr('wApproveWnd_Reserve');
    this.onClosed = new EventEmitter<APPWNDRESULT>();
    this.m_cancomm= true;
    this.m_autosave= false;
  }

  public get AutoSave(): boolean
  {
    return this.m_autosave;
  }

  public SetPrms(adp: WFDocumentAdp): void {
    let ii: number;

    this.m_doc = adp;
    this.m_reservebtn = false;
    this.m_isreserve = true;

    let lshm: WFDocumentAuthSchema = this.m_doc.GetAuthPath(this.m_doc.AuthPathsCount - 1);
    let appuid = this.m_doc.ApprovalForUserID;

    let addinf = lshm.FindAdditionalInfo(appuid);
    if (addinf == null) {
      if (appuid === this.m_doc.UserID) addinf = lshm.FindAdditionalInfo(WFAuthSchema.OBJID_OWNER);
    }

    if (IWFObject.IsNullOrEmpty(addinf)) {
      this.m_addinfo = '';
      this.m_addinfoscrl = false;
    } else {
      this.m_addinfo = addinf;
      this.m_addinfoscrl = true;
    }

    if (this.m_doc.CanLockAuthSchema) {
      this.m_reservebtn = true;
      this.m_isreserve = true;
      this.m_reservebtn_ctx = this.m_strdata_srv.getStr('wApproveWnd_Reserve');
    } else {
      if (this.m_doc.CanUnLockAuthSchema) {
        this.m_reservebtn = true;
        this.m_isreserve = false;
        this.m_reservebtn_ctx = this.m_strdata_srv.getStr('wApproveWnd_Release');
      }
    }

    //parametry
    this.m_cancomm= true;

    if (lshm.Type === WFAuthMethodType.AUTH_CUSTOM) {
      let recipt: WFDocumentAuthRecipient = lshm.FindAuthRecipient(appuid);
      if (recipt == null) {
        if (appuid === this.m_doc.UserID) recipt = lshm.FindAuthRecipient(WFAuthSchema.OBJID_OWNER);
      }

      let sctshm: WFAuthSchema = this.m_cache_srv.AuthSchemas.get(lshm.AuthSchemaID);
      let ins: WFAuthInstruction = sctshm.GetInstructionByID(lshm.InstructionInternalID);// recipt.InstructionInternalID);
      if (ins != null) {
        let pcc = ins.GetParamsCount();
        if (pcc > 0) {
          for (ii = 0; ii < pcc; ii++) {
            let ipr: WFInstructionParameter = ins.GetParam(ii);
            this.m_params.push({
              label: (IWFObject.IsNullOrEmpty(ipr.Descritpion)) ? ipr.Name : ipr.Descritpion,
              inst: ipr,
              iserror: false,
              value: '',
              subshm: (ipr.Type === WFInstPrmType.TYPE_SCHEMA) ? WFDocumentAuthVarSchema.Create() : null
            });
          }
        }

        this.m_autosave= ins.AutoSave;
      }
    } 
  }

  //

  public FillComboBox(cbx: ComboboxadpComponent, ipr: WFInstructionParameter): void {
    switch (ipr.Type) {
      case WFInstPrmType.TYPE_SELUSER:
        if (ipr.AllowNull) cbx.AddValidValue('', this.m_strdata_srv.getStr('wUserPanel_SelectUser'));

        switch (ipr.ObjType) {
          case WFInstPrmObjType.ALLUSERS:
            cbx.AddValidValue('0', this.m_strdata_srv.getStr('strOwnerSO'));
            let susr= Array.from(this.m_cache_srv.Users.values()).sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
            for (let usr of susr) {
              cbx.AddValidValue(usr.ID.toString(), usr.FriendlyName);
            }
            break;

          case WFInstPrmObjType.USERGROUP:
            let ugr: WFUserGroup = this.m_cache_srv.UserGroups.get(ipr.ObjID);
            let ausr= new Array<WFUser>();
            for (let uid of ugr.Users) {
              let usr: WFUser = (uid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uid);
              //cbx.AddValidValue(usr.ID.toString(), usr.FriendlyName);
              ausr.push(usr);
            }
            let susr2= ausr.sort((a,b)=> a.FriendlyName.localeCompare(b.FriendlyName));
            for(let usr of susr2) {
               cbx.AddValidValue(usr.ID.toString(), usr.FriendlyName);
            }
            break;

          case WFInstPrmObjType.USERDEFINEDGROUP:
            let aval= new Array<ComboValueDesc>();
            for (let suid of Array.from(ipr.ValidValues.keys())) {
              let uname: string;
              if (suid === '0') {
                uname = this.m_strdata_srv.getStr('strOwnerSO');
              } else {
                let uid = IWFObject.ParseInt(suid);
                let usr: WFUser = (uid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(uid);
                uname = usr.FriendlyName;
              }
              //cbx.AddValidValue(suid, uname);
              aval.push({ val: suid, desc: uname });
            }
            let sval= aval.sort((a,b)=> a.desc.localeCompare(b.desc));
            for(let iv of sval) {
              cbx.AddValidValue(iv.val, iv.desc);
            }
            break;
        }
        break;

      case WFInstPrmType.TYPE_SELSCHEMA:
        switch (ipr.ObjType) {
          case WFInstPrmObjType.ALLUSERS:
            let sshm= Array.from(this.m_cache_srv.AuthSchemas.values()).sort((a,b)=> a.Name.localeCompare(b.Name));
            for (let shm of sshm) {
              if (shm.Type !== WFAuthMethodType.AUTH_CUSTOM) cbx.AddValidValue(shm.ID.toString(), shm.Name);
            }
            break;

          case WFInstPrmObjType.USERDEFINEDGROUP:
            let ashm= new Array<WFAuthSchema>();
            for (let shuid of Array.from(ipr.ValidValues.keys())) {
              let shid = IWFObject.ParseInt(shuid);
              let shm: WFAuthSchema = this.m_cache_srv.AuthSchemas.get(shid);
              //cbx.AddValidValue(shuid, shm.Name);
              ashm.push(shm);
            }
            let sshm2= ashm.sort((a,b)=> a.Name.localeCompare(b.Name));
            for(let shm of sshm2) {
              cbx.AddValidValue(shm.ID.toString(), shm.Name);
            }
            break;
        }
        break;

      case WFInstPrmType.TYPE_USERDICT:
        let sval= Array.from(ipr.ValidValues.entries()).sort((a,b)=> a[1].localeCompare(b[1]));
        for (let kv of sval) {
          cbx.AddValidValue(kv[0], kv[1]);
        }
        break;
    }
  }

  public ClickEditCustomSchema(prop: ParamSpec): void {
    try {
      const self = this;
      const wnd = this.m_wnd_srv.showControl(EditauthschemaComponent);
      const inst: EditauthschemaComponent = <EditauthschemaComponent>wnd.instance;
      inst.SetPrms2(this.m_doc, prop.subshm);
      inst.onClosed.subscribe((ret) => {
        wnd.destroy();
      });
   } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  //

  public CancelButton(): void {
    this.onClosed.emit(APPWNDRESULT.CANCEL);
  }

  public OKYesButton(): void {
    let ii: number;
    let dstbvar: WFDocumentAuthVarBasic;
    let strval: string;
    let tmpf: number;

    try {
      //sprawdz parametry
      let doret = true;
      let prms = new Map<number, IWFDocumentAuthVariable>();

      for (ii = 0; ii < this.m_params.length; ii++) {
        let spc: ParamSpec = this.m_params[ii];
        let ipr: WFInstructionParameter = spc.inst;

        switch (ipr.Type) {
          case WFInstPrmType.TYPE_SELUSER:
            dstbvar = WFDocumentAuthVarBasic.Create();

            spc.iserror = false;
            if (!IWFObject.IsNullOrEmpty(spc.value)) {
              dstbvar.Values.push(spc.value);
            } else {
              if (!ipr.AllowNull) {
                spc.iserror = true;
                doret = false;
              }
            }
            prms.set(ipr.VariableID, dstbvar);
            break;

          case WFInstPrmType.TYPE_SELSCHEMA:
            dstbvar = WFDocumentAuthVarBasic.Create();
            spc.iserror = false;
            if (!IWFObject.IsNullOrEmpty(spc.value)) {
              dstbvar.Values.push(spc.value);
            } else {
              if (!ipr.AllowNull) {
                spc.iserror = true;
                doret = false;
              }
            }
            prms.set(ipr.VariableID, dstbvar);
            break;


          case WFInstPrmType.TYPE_SCHEMA:
            prms.set(ipr.VariableID, spc.subshm);
            break;

          case WFInstPrmType.TYPE_INT:
          case WFInstPrmType.TYPE_FLOAT:
            dstbvar = WFDocumentAuthVarBasic.Create();

            spc.iserror = false;
            strval = spc.value.trim();
            if (strval.length > 0) {
              strval = strval.replace(',', '.');
              if (!isNaN(Number.parseFloat(strval))) {
                dstbvar.Values.push(strval);
              } else {
                spc.iserror = true;
                doret = false;
              }
            } else {
              if (!ipr.AllowNull) {
                spc.iserror = true;
                doret = false;
              }
            }
            prms.set(ipr.VariableID, dstbvar);
            break;

          case WFInstPrmType.TYPE_TEXT:
            dstbvar = WFDocumentAuthVarBasic.Create();
            spc.iserror = false;
            strval = spc.value.trim();
            if (strval.length > 0) {
              dstbvar.Values.push(strval);
            } else {
              if (!ipr.AllowNull) {
                spc.iserror = true;
                doret = false;
              }
            }
            prms.set(ipr.VariableID, dstbvar);
            break;

          case WFInstPrmType.TYPE_USERDICT:
            dstbvar = WFDocumentAuthVarBasic.Create();
            spc.iserror = false;
            if (!IWFObject.IsNullOrEmpty(spc.value)) {
              dstbvar.Values.push(spc.value);
            } else {
              spc.iserror = true;
              doret = false;
            }
            prms.set(ipr.VariableID, dstbvar);
            break;
        }
      }

      if (doret) {
        if (this.m_doc.Approve(this.m_message.trim(), prms)) {
          this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentApprovedInfo'));
          this.onClosed.emit(APPWNDRESULT.APPROVE);
        } else {
          this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentNoApprovedInfo'));
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public NoButton(): void {
    try {
      if (this.m_doc.Disapprove(this.m_message.trim())) {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentRejectedInfo'));
        this.onClosed.emit(APPWNDRESULT.DISAPPROVE);
      } else {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentNoRejectedInfo'));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public ReserveButton(): void {
    try {
      let res = (this.m_isreserve) ? this.m_doc.LockAuthSchema() : this.m_doc.UnLockAuthSchema();
      if (res) {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentReserveInfo'));
        this.onClosed.emit(APPWNDRESULT.RESERVE);
      } else {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strDocumentNoReserveInfo'));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

import { DocumentList } from './data/documentlist';

export class AlertsGroup {
    private m_gname: string;
    private m_docs: DocumentList;
    private m_totaldocs: number;
    private m_isalr: boolean;
    private m_issum: boolean;

    constructor(gname: string, isalr: boolean, issum: boolean) {
        this.m_gname = gname;
        this.m_docs = new DocumentList();
        this.m_totaldocs = 0;
        this.m_isalr = isalr;
        this.m_issum = issum;
    }

    public get GroupName(): string {
        return this.m_gname;
    }

    //

    public set TotalDocs(nval: number) {
        this.m_totaldocs = nval;
    }

    public get TotalDocs(): number {
        return this.m_totaldocs;
    }

    //

    public get Documents(): DocumentList {
        return this.m_docs;
    }

    //

    public set IsAlert(nval: boolean) {
        this.m_isalr = nval;
    }

    public get IsAlert(): boolean {
        return this.m_isalr;
    }

    //

    public set IsSum(nval: boolean) {
        this.m_issum = nval;
    }

    public get IsSum(): boolean {
        return this.m_issum;
    }
}
import { Component, OnInit, Input } from '@angular/core';
import { WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { CacheService } from '../data/cache.service';
import { VisibleFieldType, WFUser, WFDocumentAuthSchema, WFDocument, WFClass, WFDocStatus, WFAuthStatus } from '../model/index';
import { StringDataService } from '../stringdata/stringdata.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-documentadp',
  templateUrl: './documentadp.component.html',
  styleUrls: ['./documentadp.component.scss']
})

export class DocumentadpComponent {
  public m_doc: WFDocumentAdp;
  public m_sel: boolean;

  @Input() set Document(nval: WFDocumentAdp) {
    this.m_doc = nval;
  }

  @Input() set Selected(nval: boolean) {
    this.m_sel= nval;
  }

  constructor(private m_cache_srv: CacheService,
    private m_str_srv: StringDataService) {
    this.m_doc = null;
    this.m_sel= false;
  }

  private FormatDate(dt: Date, format: string): string {
    //return '--data--';

	if (dt != null) {
      let dtap = new DatePipe('en-US');
      return dtap.transform(dt, format);
	  //return dt.toLocaleString();
	  //moment(dt).format(format);
    }
    return '';

  }

  private GetFieldStr(ftp: VisibleFieldType): string {
    switch (ftp) {
      case VisibleFieldType.NAME: return this.m_doc.Name.toUpperCase();
      case VisibleFieldType.DESCRIPTION: return this.m_doc.Description;
      case VisibleFieldType.DOCNUM: return this.m_doc.DocNum;
      case VisibleFieldType.CREATE_DATE: return this.FormatDate(this.m_doc.CreatedAt, 'dd-MM-yyyy hh:ss');
      case VisibleFieldType.UPDATE_DATE: return this.FormatDate(this.m_doc.UpdatedAt, 'dd-MM-yyyy hh:ss');

      case VisibleFieldType.DOC_DATE: return this.FormatDate(this.m_doc.DocDate, 'dd-MM-yyyy');
      case VisibleFieldType.ENTER_DATE: return this.FormatDate(this.m_doc.EnterDate, 'dd-MM-yyyy');
      case VisibleFieldType.CLASSNAME:
        return (this.m_doc.ClassID > 0) ? this.m_cache_srv.Classes.get(this.m_doc.ClassID).Name.toUpperCase() : this.m_str_srv.getStr('strNone').toUpperCase();

      case VisibleFieldType.AUTHSCHEMANAME:
        if (this.m_doc.AuthPathsCount > 0) {
          let ashm: WFDocumentAuthSchema = this.m_doc.GetAuthPath(this.m_doc.AuthPathsCount - 1);
          if (ashm.AuthSchemaID > 0) return ashm.Name;
          if (ashm.AuthSchemaID === WFDocument.SCHM_CUSTOM) return this.m_str_srv.getStr('strAuthCustom');
        }
        return this.m_str_srv.getStr('strAuthNoAuth');

      case VisibleFieldType.USERNAME:
        if (this.m_doc.UserID > 0) {
          let usr: WFUser = (this.m_doc.UserID === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(this.m_doc.UserID);
          return usr.Name;
        }
        return this.m_str_srv.getStr('strNone');

      case VisibleFieldType.COMPANYNAME:
        if (this.m_doc.CompanyID > 0) {
          return this.m_cache_srv.Companies.get(this.m_doc.CompanyID).Company;
        }
        return this.m_str_srv.getStr('strNone');
    }

    return '';
  }

  public get ClassColor(): string {
    let ret = '#ffffff';
    if (this.m_doc != null) {
      if (this.m_doc.ClassID > 0) {
        let cls: WFClass = this.m_cache_srv.Classes.get(this.m_doc.ClassID);
        ret = cls.Classcolor;
      }
    }
    return ret;
  }

  public get DocClassName(): string {
    return (this.m_doc != null) ? this.GetFieldStr(VisibleFieldType.CLASSNAME) : '';
  }

  public get DocName(): string {
    return (this.m_doc != null) ? this.GetFieldStr(this.m_cache_srv.User.Field1Type) : '';
  }

  public get DocNum(): string {
    return (this.m_doc != null) ? this.GetFieldStr(this.m_cache_srv.User.Field2Type) : '';
  }

  public get DocDate(): string {
    return (this.m_doc != null) ? this.GetFieldStr(this.m_cache_srv.User.Field3Type) : '';
  }

  public get DocDesc(): string {
    return (this.m_doc != null) ? this.GetFieldStr(this.m_cache_srv.User.Field4Type) : '';
  }

  public get IconState(): number {
    if(this.m_doc!= null && this.m_doc.DocStatus== WFDocStatus.INSYSTEM && this.m_doc.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
      return <number>this.m_doc.AuthStatus;
    }
    return -1;
  }
}

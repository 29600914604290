import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { TreeViewItem } from '../tree-view/treeviewitem';
import { WFFilterInfo, IWFObject, WFAuthStatus } from '../model';

export enum FltProperty {
  CLASS,
  AUTHSCHEMA,
  USER,
  STATUS,
  COMPANY,
  OTHER
}

@Component({
  selector: 'app-filter-wnd',
  templateUrl: './filter-wnd.component.html',
  styleUrls: ['./filter-wnd.component.scss']
})

export class FilterWndComponent {
  @Output() public onClosed: EventEmitter<boolean>;
  public m_busy_str: string;
  public m_filterselector: Array<TreeViewItem>;
  private m_docsids: Array<number>;
  private m_otherexist: boolean;
  private m_seltypes: Array<any>;
  //

  public get DocumentList(): Array<number> {
    return this.m_docsids;
  }

  public get Selected(): Array<any> {
    return this.m_seltypes;
  }

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {
    //
    this.m_busy_str = '';
    this.onClosed = new EventEmitter<boolean>();
    this.m_filterselector = new Array<TreeViewItem>();
    this.m_docsids = new Array<number>();
    this.m_otherexist = false;
    this.m_seltypes= new Array<any>();
  }

  //

  private CreateTvi(tvi: TreeViewItem, dsc: string, cc: number): void {
    tvi.CheckBox = true;
    tvi.Header = IWFObject.Format('{0} [{1}]', dsc.toUpperCase(), cc);
  }

  private SetCheckState(tvi: TreeViewItem, nstate: boolean): void {
    tvi.CheckState = nstate;
    for (let ii = 0; ii < tvi.Items.length; ii++) {
      let stvi = tvi.Items[ii];
      this.SetCheckState(stvi, nstate);
    }
  }

  private ReCreateTvi(tvi: TreeViewItem): number {
    let retcc = 0;
    let kv: [number, Array<number>] = <[number, Array<number>]>tvi.UserObj;
    retcc += kv[1].length;
    for (let stvi of tvi.Items) {
      retcc += this.ReCreateTvi(stvi);
    }
    let cls = this.m_cache_srv.Classes.get(kv[0]);
    this.CreateTvi(tvi, cls.Name, retcc);
    return retcc;
  }

  public SetPrms(inf: WFFilterInfo, sel: Array<any>): void {
    let ii, ii2: number;
    let tvi: TreeViewItem;
    let lst: Array<number>;
    let dsc: string;

    if(sel != null) this.m_seltypes= sel;

    //klasy
    let leafs = new Array<number>();
    for (let clsid of Array.from(inf.ByClass.keys())) {
      if (clsid > 0) {
        let add = true;
        for (let cls of Array.from(this.m_cache_srv.Classes.values())) {
          if (cls.BaseClassID === clsid) {
            add = false;
            break;
          }
        }
        if (add) leafs.push(clsid);
      }
    }

    let emptylst = new Array<number>();
    let clstvi = new TreeViewItem();
    clstvi.UserObj = [0, emptylst, FltProperty.CLASS];
    let clscc = 0;

    let selcls= this.m_seltypes.find((t)=> t.type== FltProperty.CLASS);
    selcls= (selcls== null) ? emptylst : selcls.values;

    for (ii = 0; ii < leafs.length; ii++) {
      let lclsid = leafs[ii];

      let pth = new Array<number>();
      while (lclsid > 0) {
        let cls = this.m_cache_srv.Classes.get(lclsid);
        pth.push(cls.ID);
        lclsid = cls.BaseClassID;
      }

      let stvi = clstvi;
      for (ii2 = pth.length - 1; ii2 > -1; ii2--) {
        let clsid = pth[ii2];

        let dsttvi: TreeViewItem = null;
        for (let ftvi of stvi.Items) {
          let kv: [number, Array<number>] = <[number, Array<number>]>ftvi.UserObj;
          if (kv[0] === clsid) {
            dsttvi = ftvi;
            break;
          }
        }

        if (dsttvi == null) {
          dsttvi = new TreeViewItem();
          lst = (inf.ByClass.has(clsid)) ? inf.ByClass.get(clsid) : emptylst;
          dsttvi.UserObj = [clsid, lst];
          dsttvi.CheckState= (selcls.indexOf(clsid) >= 0);
          stvi.Items.push(dsttvi);
          if(dsttvi.CheckState) {
            stvi.Expanded= true;
            clstvi.Expanded= true;
          }
        }

        stvi = dsttvi;
      }
    }

    for (let stvi of clstvi.Items) {
      clscc += this.ReCreateTvi(stvi);
    }

    if (inf.ByClass.has(0)) {
      lst = inf.ByClass.get(0);
      tvi = new TreeViewItem();
      tvi.UserObj = [-1, lst];
      tvi.CheckState= (selcls.indexOf(-1) >= 0);
      this.CreateTvi(tvi, this.m_strdata_srv.getStr('strNone'), lst.length);
      clstvi.Items.push(tvi);      
      clscc += lst.length;
      if(tvi.CheckState) clstvi.Expanded= true;
    }

    this.CreateTvi(clstvi, this.m_strdata_srv.getStr('strFilter_Class'), clscc);
    this.m_filterselector.push(clstvi);

    let authtvi = new TreeViewItem();
    authtvi.UserObj = [0, emptylst, FltProperty.AUTHSCHEMA];
    let authcc = 0;

    let selauth= this.m_seltypes.find((t)=> t.type== FltProperty.AUTHSCHEMA);
    selauth= (selauth== null) ? emptylst : selauth.values;

    for (let shmid of Array.from(inf.ByAuthSchema.keys())) {
      if (shmid > 0) {
        if (!this.m_cache_srv.AuthSchemas.has(shmid)) continue;
        let shm = this.m_cache_srv.AuthSchemas.get(shmid);
        dsc = shm.Name;
      } else {
        dsc = (shmid === 0) ? this.m_strdata_srv.getStr('strAuthCustom') : this.m_strdata_srv.getStr('strAuthNoAuth');
      }
      lst = inf.ByAuthSchema.get(shmid);
      tvi = new TreeViewItem();
      tvi.UserObj = [shmid, lst];
      tvi.CheckState= (selauth.indexOf(shmid) >= 0);
      this.CreateTvi(tvi, dsc, lst.length);
      authtvi.Items.push(tvi);
      authcc += lst.length;
      if(tvi.CheckState) authtvi.Expanded= true;
    }

    this.CreateTvi(authtvi, this.m_strdata_srv.getStr('strFilter_AuthSchema'), authcc);
    this.m_filterselector.push(authtvi);

    //usr
    let usrtvi = new TreeViewItem();
    usrtvi.UserObj = [0, emptylst, FltProperty.USER];
    let usrcc = 0;

    let selusr= this.m_seltypes.find((t)=> t.type== FltProperty.USER);
    selusr= (selusr== null) ? emptylst : selusr.values;

    for (let usrid of Array.from(inf.ByUser.keys())) {
      if (usrid > 0) {
        let usr = (usrid === this.m_cache_srv.User.ID) ? this.m_cache_srv.User : this.m_cache_srv.Users.get(usrid);
        dsc = usr.FriendlyName;
      } else {
        dsc = this.m_strdata_srv.getStr('strNone');
      }
      lst = inf.ByUser.get(usrid);
      tvi = new TreeViewItem();
      tvi.UserObj = [usrid, lst];
      tvi.CheckState= (selusr.indexOf(usrid) >= 0);
      this.CreateTvi(tvi, dsc, lst.length);
      usrtvi.Items.push(tvi);
      usrcc += lst.length;
      if(tvi.CheckState) usrtvi.Expanded= true;
    }

    this.CreateTvi(usrtvi, this.m_strdata_srv.getStr('strFilter_User'), usrcc);
    this.m_filterselector.push(usrtvi);

    //status
    let ststvi = new TreeViewItem();
    ststvi.UserObj = [0, emptylst, FltProperty.STATUS];
    let stscc = 0;

    let selsts= this.m_seltypes.find((t)=> t.type== FltProperty.STATUS);
    selsts= (selsts== null) ? emptylst : selsts.values;

    for (let sts of Array.from(inf.ByAuthStatus.keys())) {
      switch (sts) {
        case WFAuthStatus.APPROVED:
          dsc = this.m_strdata_srv.getStr('enumWFAuthStatusAPPROVED');
          break;
        case WFAuthStatus.DISAPPROVED:
          dsc = this.m_strdata_srv.getStr('enumWFAuthStatusDISAPPROVED');
          break;
        default:
          dsc = this.m_strdata_srv.getStr('enumWFAuthStatusWAITING');
          break;
      }
      lst = inf.ByAuthStatus.get(sts);
      tvi = new TreeViewItem();
      
      let ists= -1 - <number>sts;
      tvi.UserObj = [ists, lst];
      tvi.CheckState= (selsts.indexOf(ists) >= 0);
      this.CreateTvi(tvi, dsc, lst.length);
      ststvi.Items.push(tvi);
      stscc += lst.length;
      if(tvi.CheckState) ststvi.Expanded= true;
    }

    this.CreateTvi(ststvi, this.m_strdata_srv.getStr('strFilter_AuthStatus'), stscc);
    this.m_filterselector.push(ststvi);

    //firmy
    let cmptvi = new TreeViewItem();
    cmptvi.UserObj = [0, emptylst, FltProperty.COMPANY];
    let cmpcc = 0;

    let selcmp= this.m_seltypes.find((t)=> t.type== FltProperty.COMPANY);
    selcmp= (selcmp== null) ? emptylst : selcmp.values;
    
    for (let cmpid of Array.from(inf.ByCompany.keys())) {
      if (cmpid > 0) {
        if (!this.m_cache_srv.Companies.has(cmpid)) continue;
        let cmp = this.m_cache_srv.Companies.get(cmpid);
        dsc = cmp.Company;
      } else {
        dsc = this.m_strdata_srv.getStr('strNone');
      }
      lst = inf.ByCompany.get(cmpid);
      tvi = new TreeViewItem();
      tvi.UserObj = [cmpid, lst];
      tvi.CheckState= (selcmp.indexOf(cmpid) >= 0);
      this.CreateTvi(tvi, dsc, lst.length);
      cmptvi.Items.push(tvi);
      cmpcc += lst.length;
      if(tvi.CheckState) cmptvi.Expanded= true;
    }

    this.CreateTvi(cmptvi, this.m_strdata_srv.getStr('strFilter_Company'), cmpcc);
    this.m_filterselector.push(cmptvi);

    //pozostale
    let othtvi = new TreeViewItem();
    othtvi.UserObj = [0, emptylst, FltProperty.OTHER];
    let othcc = 0;

    let seloth= this.m_seltypes.find((t)=> t.type== FltProperty.OTHER);
    seloth= (seloth== null) ? emptylst : seloth.values;

    if (inf.FromToday.length > 0) {
      tvi = new TreeViewItem();
      tvi.UserObj = [-1, inf.FromToday];
      tvi.CheckState= (seloth.indexOf(-1) >= 0);
      this.CreateTvi(tvi, this.m_strdata_srv.getStr('strFilter_ToDay'), inf.FromToday.length);
      othtvi.Items.push(tvi);
      othcc += inf.FromToday.length;
      if(tvi.CheckState) othtvi.Expanded= true;
    }

    if (inf.FromLast5Days.length > 0) {
      tvi = new TreeViewItem();
      tvi.UserObj = [-2, inf.FromLast5Days];
      tvi.CheckState= (seloth.indexOf(-2) >= 0);
      this.CreateTvi(tvi, this.m_strdata_srv.getStr('strFilter_Last5Days'), inf.FromLast5Days.length);
      othtvi.Items.push(tvi);
      othcc += inf.FromLast5Days.length;
      if(tvi.CheckState) othtvi.Expanded= true;
    }

    if (inf.FromLastMonth.length > 0) {
      tvi = new TreeViewItem();
      tvi.UserObj = [-3, inf.FromLastMonth];
      tvi.CheckState= (seloth.indexOf(-3) >= 0);
      this.CreateTvi(tvi, this.m_strdata_srv.getStr('strFilter_LastMonth'), inf.FromLastMonth.length);
      othtvi.Items.push(tvi);
      othcc += inf.FromLastMonth.length;
      if(tvi.CheckState) othtvi.Expanded= true;
    }

    if (othcc > 0) {
      this.CreateTvi(othtvi, this.m_strdata_srv.getStr('strFilter_Other'), othcc);
      this.m_filterselector.push(othtvi);
      this.m_otherexist = true;
    } else {
      this.m_otherexist = false;
    }
  }

  public TviChecked(tvi: TreeViewItem, state: boolean): void {
    try {
      for (let ii = 0; ii < tvi.Items.length; ii++) {
        let stvi = tvi.Items[ii];
        this.SetCheckState(stvi, state);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private SumSubTree(tvi: TreeViewItem, dstsel: Array<number>, dstall: Array<number>, tps: Array<number>): number {
    let ii, did: number;
    let retch = 0;

    let kv: [number, Array<number>] = <[number, Array<number>]>tvi.UserObj;
    if (tvi.CheckState) {
      retch++;

      for (ii = 0; ii < kv[1].length; ii++) {
        did = kv[1][ii];
        if (dstsel.indexOf(did) < 0) dstsel.push(did);
      }

      if(kv[0] != 0) {
        if(tps.indexOf(kv[0]) < 0) tps.push(kv[0]);
      }
    }

    for (ii = 0; ii < kv[1].length; ii++) {
      did = kv[1][ii];
      if (dstall.indexOf(did) < 0) dstall.push(did);
    }

    for (ii = 0; ii < tvi.Items.length; ii++) {
      retch += this.SumSubTree(tvi.Items[ii], dstsel, dstall, tps);
    }

    return retch;
  }

  private AndGroupList(alst: Array<number>, blst: Array<number>): Array<number> {
    let ret = new Array<number>();

    if (blst.length > alst.length) {
      let tmp = alst;
      alst = blst;
      blst = tmp;
    }

    for (let aid of alst) {
      let add = false;
      for (let bid of blst) {
        if (aid === bid) {
          add = true;
          break;
        }
      }
      if (add) ret.push(aid);
    }

    return ret;
  }

  /*private AddTreeItem(stvi: TreeViewItem): Array<number> {   
    let ret = true;
    
    let ssel = new Array<number>();
    let sall = new Array<number>();
    let schc = this.SumSubTree(stvi, ssel, sall);

    if (schc > 0) {
      if (ssel.length === 0) {
        //stvi.Background = m_failbck;
        dorun = false;
        continue;
      }
    } else {
      ssel = sall;
    }

    if (andlst == null) {
      andlst = ssel;
    } else {
      andlst = this.AndGroupList(andlst, ssel);
    }
  }*/
  
  public OKButton_Click(): void {
    let ii: number;

    try {

      let dorun = true;

      let andlst: Array<number> = null;

      let ccc = (this.m_otherexist) ? this.m_filterselector.length - 1 : this.m_filterselector.length;

      let seltps = new Array<any>();

      for (ii = 0; ii < ccc; ii++) {
        let stvi = this.m_filterselector[ii];
        let ssel = new Array<number>();
        let sall = new Array<number>();
        let tps= new Array<number>();

        let schc = this.SumSubTree(stvi, ssel, sall, tps);
        if (schc > 0) {
          if (ssel.length === 0) {
            //stvi.Background = m_failbck;
            dorun = false;
            continue;
          }

          seltps.push({
            type: stvi.UserObj[2],
            values: tps
          });

        } else {
          ssel = sall;
        }

        if (andlst == null) {
          andlst = ssel;
        } else {
          andlst = this.AndGroupList(andlst, ssel);
        }
      }

      if (dorun) {
        if (this.m_otherexist) {
          let ltvi = this.m_filterselector[this.m_filterselector.length - 1];
          let lssel = new Array<number>();
          let lsall = new Array<number>();
          let tps= new Array<number>();
          if(this.SumSubTree(ltvi, lssel, lsall, tps) > 0) {
            seltps.push({
              type: ltvi.UserObj[2],
              values: tps
            });
          }
          if (lssel.length > 0) andlst = this.AndGroupList(andlst, lssel);
        }

        if (andlst.length > 0) {
          this.m_docsids = andlst;
          this.m_seltypes = seltps;
          this.onClosed.emit(true);
        } else {
          this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('strCheckAtLeast1Item'));
        }
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

import { Injectable } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { AlertWndComponent, ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { ChildWindowContainerService } from '../child-window/child-window-container.service';
import { AppComponent } from '../app.component';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { InterpolationConfig } from '@angular/compiler';
import { WFDailyCorrespondenceAdp } from '../adapters/WFDailyCorrespondenceAdp';


export enum BUTTONSTYPE {
    OK,
    OKCANCEL,
    YESNO,
    YESNOCANCEL
}

export enum WNDRESULT {
    OKYES,
    NO,
    CANCEL
}

export enum INFOBARSTATEMODE {
    DOCUMENT,
    SESSION
}

export type DLGACTION = (res: WNDRESULT) => void;

export interface IConfig {
    DATASERVICE_ADR: string;
    INFOBARMODE: INFOBARSTATEMODE;
}

@Injectable()
export class GlobalService {
    private m_app: AppComponent;
    private m_cfg: IConfig;

    private m_copied_dailyenttry: WFDailyCorrespondenceAdp;

    public get Copied_dailyenttry(): WFDailyCorrespondenceAdp {
      return this.m_copied_dailyenttry;
    }
    public set Copied_dailyenttry(value: WFDailyCorrespondenceAdp) {
      this.m_copied_dailyenttry = value;
    }

    public get App(): AppComponent {
        return this.m_app;
    }

    //

    constructor(private m_wndsrv: ChildWindowContainerService,
        private m_strsrv: StringDataService,
        private m_http: HttpClient) {
        this.m_app = null;
        this.m_cfg = null;
        this.m_copied_dailyenttry = null;
    }

    public LoadConfig() {
        const self = this;
        return new Promise((resolve, reject) => {
            this.m_http.get<IConfig>('assets/config/config.json').
                //map(function (val, ind) {
                //    return <IConfig>val.json();
                //})
                subscribe(
                    function (val) {                        
                        let dta= val;//.json();                        
                        self.m_cfg = dta;
                        resolve(true);
                    },
                    function (err) {
                        resolve(false);
                    }
                );
        });
    }

    public get Config(): IConfig {
        return this.m_cfg;
    }

    public SetPrms(app: AppComponent): void {
        this.m_app = app;
    }

    public manageException(ex: any, onclose?: DLGACTION): void {
        const wnd = this.m_wndsrv.showControl(AlertWndComponent);
        wnd.instance.icontype = ALR_ICONTYPE.ERROR;
        wnd.instance.content = ex.message;
        wnd.instance.clickCancel.subscribe(function () {
            wnd.destroy();
            if (onclose != null) onclose(WNDRESULT.CANCEL);
        });
    }

    public showMsg(icontp: ALR_ICONTYPE, btnstp: BUTTONSTYPE, msg: string, onclose?: DLGACTION): void {
        const wnd = this.m_wndsrv.showControl(AlertWndComponent);
        const cmp = wnd.instance;
        cmp.icontype = ALR_ICONTYPE.INFORMATION;
        cmp.content = msg;

        switch (btnstp) {
            case BUTTONSTYPE.OK:
                cmp.can_cancel = false;
                cmp.buttons.push({ id: WNDRESULT.OKYES, desc: this.m_strsrv.getStr('btOK') });
                break;
            case BUTTONSTYPE.OKCANCEL:
                cmp.can_cancel = true;
                cmp.buttons.push({ id: WNDRESULT.OKYES, desc: this.m_strsrv.getStr('btOK') });
                cmp.buttons.push({ id: WNDRESULT.CANCEL, desc: this.m_strsrv.getStr('btCancel') });
                break;
            case BUTTONSTYPE.YESNO:
                cmp.can_cancel = false;
                cmp.buttons.push({ id: WNDRESULT.OKYES, desc: this.m_strsrv.getStr('btYes') });
                cmp.buttons.push({ id: WNDRESULT.CANCEL, desc: this.m_strsrv.getStr('btNo') });
                break;
            case BUTTONSTYPE.YESNOCANCEL:
                cmp.can_cancel = true;
                cmp.buttons.push({ id: WNDRESULT.OKYES, desc: this.m_strsrv.getStr('btYes') });
                cmp.buttons.push({ id: WNDRESULT.NO, desc: this.m_strsrv.getStr('btNo') });
                cmp.buttons.push({ id: WNDRESULT.CANCEL, desc: this.m_strsrv.getStr('btCancel') });
                break;
        }

        if (onclose == null) {
            cmp.can_cancel = true;
            cmp.clickButton.subscribe(function (btnid) {
                wnd.destroy();
            });
            cmp.clickCancel.subscribe(function () {
                wnd.destroy();
            });
        } else {
            cmp.clickButton.subscribe(function (btnid) {
                onclose(btnid);
                wnd.destroy();
            });

            if (cmp.can_cancel) {
                cmp.clickCancel.subscribe(function () {
                    onclose(WNDRESULT.CANCEL);
                    wnd.destroy();
                });
            }

            /*if (cmp.can_cancel) {
                cmp.clickCancel.subscribe(function () {
                    if (oncancel()) wnd.destroy();
                });
            }

            if (cmp.can_ok) {
                cmp.clickOk.subscribe(function () {
                    if (onok()) wnd.destroy();
                });
            }*/
        }
    }

    //

    public showInfo(btns: BUTTONSTYPE, msg: string, onclose?: DLGACTION): void {
        this.showMsg(ALR_ICONTYPE.INFORMATION, btns, msg, onclose);
    }

    public showWarning(btns: BUTTONSTYPE, msg: string, onclose?: DLGACTION): void {
        this.showMsg(ALR_ICONTYPE.WARNING, btns, msg, onclose);
    }
}

export enum WFRelationType {
    DONTCOPY,
    COPY,
    INDEX,
    REMEMBER
}

export class WFRelation {
    private m_ocrname: string;
    private m_attrib_code: string;
    private m_maptype: WFRelationType;

    //

    public static Create(ocrname: string, attribcode: string, maptype: WFRelationType): WFRelation {
        return new WFRelation({
            ocrname: ocrname,
            attrib_code: attribcode,
            maptype: maptype
        });
    }

    constructor(obj: any) {
        this.m_ocrname = obj.ocrname;
        this.m_attrib_code = obj.attrib_code;
        this.m_maptype = <WFRelationType>obj.maptype;
    }

    //

    public get OcrName(): string {
        return this.m_ocrname;
    }

    public get AttribCode(): string {
        return this.m_attrib_code;
    }

    public set MapType(value: WFRelationType) {
        this.m_maptype = value;
    }

    public get MapType(): WFRelationType {
        return this.m_maptype;
    }

    //

    public ToObject(): any {
        return {
            ocrname: this.m_ocrname,
            attrib_code: this.m_attrib_code,
            maptype: this.m_maptype
        };
    }
}

export class WFDocumentOcrMap {
    private m_class_id: number;
    private m_cardcode: string;
    private m_schemacode: string;
    private m_relation: WFRelation;

    //

    constructor(obj: any) {
        this.m_class_id = obj.class_id;
        this.m_cardcode = obj.cardcode;
        this.m_schemacode = obj.schemacode;
        this.m_relation = new WFRelation(obj.relation);
    }

    public static Create(class_id: number, cardcode: string, schemacode: string, rel: WFRelation): WFDocumentOcrMap {
        return new WFDocumentOcrMap({
            class_id: class_id,
            cardcode: cardcode,
            schemacode: schemacode,
            relation: rel.ToObject()
        });
    }

    //

    public get ClassID(): number {
        return this.m_class_id;
    }

    public get CardCode(): string {
        return this.m_cardcode;
    }

    public get SchemaCode(): string {
        return this.m_schemacode;
    }

    public get Relation(): WFRelation {
        return this.m_relation;
    }

    //

    public ToObject(): any {
        return {
            class_id: this.m_class_id,
            cardcode: this.m_cardcode,
            schemacode: this.m_schemacode,
            relation: this.m_relation.ToObject()
        };
    }
}
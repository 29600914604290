import { WFModificationEntry } from './WFModificationEntry';
import { IWFObject } from './IWFObject';

export class WFDocumentModification {
    private m_id: number;
    private m_user_id: number;
    private m_entries: Array<WFModificationEntry>;
    private m_createdat: Date;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_user_id = obj.user_id;
        this.m_entries = new Array<WFModificationEntry>();
        for (let ent of obj.entries) this.m_entries.push(new WFModificationEntry(ent));
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public get UserID(): number {
        return this.m_user_id;
    }

    public GetEntriesCount(): number {
        return this.m_entries.length;
    }

    public GetEntry(id: number): WFModificationEntry {
        return this.m_entries[id];
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    //

    public ToObject(): any {
        let entries = new Array<any>();
        for (let ent of this.m_entries) entries.push(ent.ToObject());

        return {
            id: this.m_id,
            user_id: this.m_user_id,
            entries: entries,
            createdat: IWFObject.DateToString(this.m_createdat)
        };
    }
}

import { WFPropType } from './WFPermission';

export enum WFAuthPrmObjType {
    USER,
    USERGROUP,
    AUTHSCHEMA,
    AUTHINSTRUCTION
}

export enum WFAuthSeq {
    ALWAYS,
    WAITING,
    APPROVED,
    DISAPPROVED
}

export class WFAuthPermission {
    public static readonly STDATR_NAME = -1;
    public static readonly STDATR_DESCRIPTION = -2;
    public static readonly STDATR_DOCNUM = -3;
    public static readonly STDATR_CLASS = -4;
    public static readonly STDATR_AUTHSCHEMA = -5;
    public static readonly STDATR_DOCDATE = -6;
    public static readonly STDATR_ENTERDATE = -7;

    //

    private m_attribs_id: number;
    private m_allow: boolean;
    private m_obj_id: number;
    private m_obj_type: WFAuthPrmObjType;
    private m_authseq: WFAuthSeq;
    private m_proptp: WFPropType;

    //

    public static Create(src: WFAuthPermission): WFAuthPermission {
        return new WFAuthPermission({
            attribs_id: src.m_attribs_id,
            allow: src.m_allow,
            obj_id: src.m_obj_id,
            obj_type: src.m_obj_type,
            authseq: src.m_authseq,
            proptp: src.m_proptp
        });
    }

    constructor(obj: any) {
        this.m_attribs_id = obj.attribs_id;
        this.m_allow = obj.allow;
        this.m_obj_id = obj.obj_id;
        this.m_obj_type = obj.obj_type;
        this.m_authseq = obj.authseq;
        this.m_proptp = obj.proptp;
    }

    //

    public get AttribsID(): number {
        return this.m_attribs_id;
    }

    public get Allow(): boolean {
        return this.m_allow;
    }

    public get ObjID(): number {
        return this.m_obj_id;
    }

    public get ObjType(): WFAuthPrmObjType {
        return this.m_obj_type;
    }

    public get AuthSeq(): WFAuthSeq {
        return this.m_authseq;
    }

    public get Property(): WFPropType {
        return this.m_proptp;
    }

    //

    public ToObject(): any {
        return {
            attribs_id: this.m_attribs_id,
            allow: this.m_allow,
            obj_id: this.m_obj_id,
            obj_type: this.m_obj_type,
            authseq: this.m_authseq,
            proptp: this.m_proptp
        };
    }
}
import { WFCompanyDict } from './WFCompanyDict';
import { WFSystem } from './WFSystem';
import { WFDictionary } from './WFDictionary';
import { IWFObject } from './IWFObject';

export enum WFFieldType {
    TYPE_INT,
    TYPE_FLOAT,
    TYPE_TEXT,
    TYPE_DATETIME,
    TYPE_CUSTOM,
    TYPE_SYSDICT,
    TYPE_USRDICT,
    TYPE_INTDICT,
    TYPE_STATICDICT,
    TYPE_LONGTEXT
}

export enum WFAtrObjType {
    CLASS,
    DAILYCORRCLASS,
    INTERACTIVEDICT
}

export class WFClassAttrib {
    private static INTRX: RegExp = new RegExp('^-?[0-9]+$');
    private static FLTRX: RegExp = new RegExp('^-?([0-9]+|[0-9]+\\.[0-9]+)$');
    private static DTARX: RegExp = new RegExp('^[0-3][0-9]-[0-1][0-9]-[0-9]{4}$');

    //

    private m_id: number;
    private m_name: string;
    private m_description: string;
    private m_regex: RegExp;
    private m_type: WFFieldType;
    private m_extension_id: number;

    private m_companiesdicts_id: Map<number, number>;
    private m_mandatory: boolean;
    private m_vvalues: Map<string, string>;
    //5.8
    private m_schemafcode: string;
    private m_erpfcode: string;
    //
    private m_evalcode: string;
    private m_allowcustomval: boolean;
    private m_interactivedics_id: number;

    private m_label: string;
    private m_visorder: number;
    private m_defwidth: number;

    //
    private m_addervisibility: boolean;
    private m_cmp_classattrib_id: number;
    private m_allowgroup: boolean;

    //

    public static CheckMandatory(col: Array<WFClassAttrib>, prms: Map<number, Object>): boolean {
        for (let pr of col) {
            let oval: Object;
            if (prms.has(pr.ID)) {
                oval = prms.get(pr.ID);
            } else {
                oval = null;
            }

            if (oval == null && pr.Mandatory) return false;
        }

        return true;
    }

    public static SortArribs(atrs: Array<WFClassAttrib>): void {
        let ii: number;

        let sort = false;
        let visrdr = atrs[0].VisibilityOrder;
        for (ii = 1; ii < atrs.length; ii++) {
            if (atrs[ii].VisibilityOrder !== visrdr) {
                sort = true;
                break;
            }
        }

        if (sort) atrs.sort((a, b) => a.VisibilityOrder - b.VisibilityOrder);
    }


    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_regex = new RegExp(obj.regex, 'i');
        this.m_type = obj.type;
        this.m_extension_id = obj.extension_id;
        this.m_mandatory = obj.mandatory;
        this.m_schemafcode = obj.schemafcode;
        this.m_erpfcode = obj.erpfcode;
        this.m_evalcode = obj.evalcode;
        this.m_allowcustomval = obj.allowcustomval;
        this.m_interactivedics_id = obj.interactivedics_id;
        this.m_label = obj.label;
        this.m_visorder = obj.visorder;
        this.m_defwidth = obj.defwidth;
        this.m_addervisibility = obj.addervisibility;
        this.m_cmp_classattrib_id = obj.cmp_classattrib_id;
        this.m_allowgroup = obj.allowgroup;

        this.m_vvalues = new Map<string, string>();
        for (let kv of obj.vvalues) this.m_vvalues.set(kv.key, kv.value);

        this.m_companiesdicts_id = new Map<number, number>();
        for (let kv of obj.companiesdicts_id) this.m_companiesdicts_id.set(kv.key, kv.value);
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get MatchRegex(): RegExp {
        return this.m_regex;
    }

    //

    public get Type(): WFFieldType {
        return this.m_type;
    }

    //

    public get ExtensionID(): number {
        return this.m_extension_id;
    }

    //

    public get Mandatory(): boolean {
        return this.m_mandatory;
    }

    //

    public get ValidValues(): Map<string, string> {
        return this.m_vvalues;
    }

    //

    public get CompaniesDictsID(): Map<number, number> {
        return this.m_companiesdicts_id;
    }

    //

    public get ReconSchemaFieldCode(): string {
        return this.m_schemafcode;
    }

    //

    public get ErpFieldCode(): string {
        return this.m_erpfcode;
    }

    //

    public get EvalCode(): string {
        return this.m_evalcode;
    }

    //

    public get AllowCustomValue(): boolean {
        return this.m_allowcustomval;
    }

    //

    public get DictionaryID(): number {
        return this.m_interactivedics_id;
    }

    //

    public get Label(): string {
        return this.m_label;
    }

    //

    public get VisibilityOrder(): number {
        return this.m_visorder;
    }

    //

    public get DefaultWidth(): number {
        return this.m_defwidth;
    }

    //

    public get AdderVisibility(): boolean {
        return this.m_addervisibility;
    }

    //

    public get CompareClassAttribID(): number {
        return this.m_cmp_classattrib_id;
    }

    //

    public get AllowGroup(): boolean {
        return this.m_allowgroup;
    }

    //

    public Validate(val: string, company_id: number, cmpdc: Map<number, WFCompanyDict>, sys: WFSystem): boolean {

        if (IWFObject.IsNullOrEmpty(val)) {
            if (this.m_mandatory)
                return false;
        } else {
            switch (this.m_type) {
                case WFFieldType.TYPE_SYSDICT:
                    if (this.m_companiesdicts_id.has(company_id) && !this.m_allowcustomval) {
                        let did: number = this.m_companiesdicts_id.get(company_id);
                        let dc: WFCompanyDict = cmpdc.get(did);
                        if (!dc.Values.has(val))
                            return false;
                    }
                    break;
                case WFFieldType.TYPE_USRDICT:
                    if (!this.m_vvalues.has(val) && !this.m_allowcustomval)
                        return false;
                    break;
                case WFFieldType.TYPE_STATICDICT:
                    let sdc: WFDictionary = sys.AllDictionaries.get(this.m_interactivedics_id);
                    if (!sdc.ValidValues.has(val) && !this.m_allowcustomval)
                        return false;
                    break;
                case WFFieldType.TYPE_INT:
                    if (!WFClassAttrib.INTRX.test(val))
                        return false;
                    break;
                case WFFieldType.TYPE_FLOAT:
                    if (!WFClassAttrib.FLTRX.test(val))
                        return false;
                    break;
                case WFFieldType.TYPE_DATETIME:
                    if (!WFClassAttrib.DTARX.test(val))
                        return false;
                    break;
                default:
                    if (this.m_regex != null) {
                        if (!this.m_regex.test(val))
                            return false;
                    }
                    break;
            }
        }
        return true;
    }
}
import { Component, AfterViewInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { IValidValues, FetchCompleted } from '../adapters/WFDocumentValidValues';
import { GlobalService } from '../global/global.service';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { IWFObject } from '../model/index';
import { CacheService } from '../data/cache.service';
import { ComboValueDesc } from '../app.component';

@Component({
  selector: 'app-comboboxadp',
  templateUrl: './comboboxadp.component.html',
  styleUrls: ['./comboboxadp.component.scss']
})

export class ComboboxadpComponent implements AfterViewInit {
  private static EMPTYVAL: ComboValueDesc = { val: '', desc: '' };

  public m_values: Map<string, string>;
  public m_dict: IValidValues;
  public m_freevalue: boolean;
  public m_enabled: boolean;
  public m_value: ComboValueDesc;
  public m_warr: boolean;
  public m_filtered: Array<ComboValueDesc>;
  private m_fetchdtaevh: FetchCompleted;
  private m_fetchdtachevh: FetchCompleted;
  public m_canclear: boolean;
  private m_clearvalue: string;
  public m_classes: Array<string>;
  private m_lineid: number;

  @Output() afterInit: EventEmitter<ComboboxadpComponent>;

  @Output() SelectedValueChange: EventEmitter<string>;

  //

  public JoinValDesc(val: ComboValueDesc): string {
    if (val == null) return '';
    if (this.m_dict != null && !IWFObject.IsNullOrEmpty(val.desc) && val.val !== val.desc) return val.val + '<!>' + val.desc;
    return val.val;
  }

  public set SelectedValue(nval: string) {
    if (nval == null) nval = '';
    let aval = (this.m_value !== ComboboxadpComponent.EMPTYVAL) ? this.JoinValDesc(this.m_value) : '';

    if (nval !== aval) {
      if (nval.length > 0) {
        let val: string;

        let deloff = nval.indexOf('<!>');
        if (deloff > 0) {
          val = nval.substr(0, deloff);
          let desc = nval.substr(deloff + 3);
          if (!this.m_values.has(val)) {
            this.m_values.set(val, desc);
            this.clearFilter();
          }
        } else {
          val = nval;
        }

        if (this.m_values.has(val)) {
          this.m_value = { val: val, desc: this.m_values.get(val) };
        } else {
          let nobj: ComboValueDesc = { val: val, desc: val };
          this.clearFilter();
          this.m_filtered.push(nobj);
          this.m_value = nobj;
        }
        this.SelectedValueChange.emit(nval);
        if (this.m_freevalue) this.CheckValueExists(val);
      } else {
        this.m_value = ComboboxadpComponent.EMPTYVAL;
        this.SelectedValueChange.emit('');
      }
    }
  }

  @Input() public get SelectedValue(): string {
    return (this.m_value !== ComboboxadpComponent.EMPTYVAL) ? this.JoinValDesc(this.m_value) : null;
  }

  @ViewChild('m_cmb', {static: false}) private m_cmb: ComboBoxComponent;

  //

  constructor(private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {
    this.m_values = new Map<string, string>();
    this.m_dict = null;
    this.m_freevalue = false;
    this.m_enabled = true;
    this.m_value = ComboboxadpComponent.EMPTYVAL;
    this.m_warr = false;
    this.m_canclear = true;
    this.m_clearvalue = '';
    this.m_filtered = new Array<ComboValueDesc>();
    this.SelectedValueChange = new EventEmitter<string>();
    this.afterInit = new EventEmitter<ComboboxadpComponent>();
    this.m_classes= new Array<string>();
    this.m_lineid= -1;

    const self = this;
    this.m_fetchdtaevh = (vvals, ustate) => { self.OnFetchData(vvals, ustate); };
    this.m_fetchdtachevh = (vvals, ustate) => { self.OnFetchDataToCheckValue(vvals, ustate); };
  }

  ngAfterViewInit() {
    this.afterInit.emit(this);
  }

  //

  @Input()
  public set CanFreeValue(nval: boolean) {
    this.m_freevalue = nval;
    if (nval) {
      if (this.m_value != null) this.CheckValueExists(this.m_value.val);
    } else {
      this.ShowWarning(false);
    }
  }

  public get CanFreeValue(): boolean {
    return this.m_freevalue;
  }

  public get SelectedDescrition(): string {
    return (this.m_value !== ComboboxadpComponent.EMPTYVAL) ? this.m_value.desc : null;
  }

  @Input()
  public set IsEnabled(nval: boolean) {
    this.m_enabled = nval;
  }

  public get IsEnabled(): boolean {
    return this.m_enabled;
  }

  @Input()
  public set CanClear(nval: boolean) {
    this.m_canclear = nval;
  }

  public get CanClear(): boolean {
    return this.m_canclear;
  }

  @Input()
  public set ClearValue(nval: string) {
    this.m_clearvalue = nval;
  }

  public get ClearValue(): string {
    return this.m_clearvalue;
  }

  @Input()
  public set Classes(nval: Array<string>) {
    this.m_classes = nval;
  }

  public get Classes(): Array<string> {
    return this.m_classes;
  }

  @Input()
  public set LineID(nval: number) {
    this.m_lineid = nval;
  }

  public get LineID(): number {
    return this.m_lineid;
  }

  public AddValidValue(val: string, dsc: string): void {
    this.m_dict = null;
    this.m_values.set(val, dsc);   
    let fnd= this.m_filtered.find((t)=> t.val== val);
    if(fnd) {
      fnd.desc= dsc;
    } else {
      fnd= { val: val, desc: dsc };
      this.m_filtered.push(fnd);
    } 

    if(this.m_value.val== val) { 
      //this.m_value= fnd;
      this.m_cmb.text= dsc;
    }  
  }

  public ClearValidValues(): void {
    this.m_dict = null;
    this.m_values.clear();
    this.m_filtered = new Array<ComboValueDesc>();
  }

  public SetValidValues(str: Map<string, string>): void {
    this.m_dict = null;
    this.m_values = str;
    this.clearFilter();
    if (this.m_freevalue && this.m_value != null) this.CheckValueExists(this.m_value.val);
  }

  public SetValidValues2(vfth: IValidValues): void {
    this.m_dict = vfth;
    this.m_values = new Map<string, string>();
    if (this.m_freevalue && this.m_value != null) this.CheckValueExists(this.m_value.val);
  }

  public get ValidValuesCount(): number {
    return this.m_values.size;
  }

  private clearFilter() {
    let ret = Array<ComboValueDesc>();
    for (let kv of Array.from(this.m_values)) {
      ret.push({ val: kv[0], desc: kv[1] });
    }
    this.m_filtered = ret;
  }

  public handleFilter(value: string) {
    if (value.length > 0) {
      let ret = Array<ComboValueDesc>();
      value = value.toUpperCase();
      for (let kv of Array.from(this.m_values)) {
        let ukv = kv[0].toUpperCase();
        let uval = kv[1].toUpperCase();
        if (ukv.indexOf(value) >= 0 || uval.indexOf(value) >= 0) ret.push({ val: kv[0], desc: kv[1] });
      }
      this.m_filtered = ret;
    } else {
      this.clearFilter();
    }
  }

  public handleValChange(value) {
    let nval = (value) ? value : this.m_clearvalue;

    if (typeof (nval) === 'string') {
      let kv = Array.from(this.m_values.entries()).find((tst) => tst[1] === nval);
      if (kv == null || kv == undefined) {
        if (this.m_freevalue || nval == this.m_clearvalue) this.SelectedValue = nval;              
      } else {
        this.SelectedValue = kv[0];  
      }
    } else {
      if (this.m_dict == null) {
        this.SelectedValue = (nval != null) ? nval.val : '';
      } else {
        this.SelectedValue = (nval != null) ? this.JoinValDesc(nval) : '';
      }
    }
  }

  public FindByDescription(dsc: string): void {
    if (this.m_enabled) {
      this.FetchData(dsc);
    }
  }

  public get ValueOutOfRange(): boolean {
    return this.m_warr;
  }

  private ShowWarning(vis: boolean): void {
    //if (oldval != m_warr.Visibility && ValueOutOfRangeChanged != null) ValueOutOfRangeChanged(this, null);
    this.m_warr = vis;
  }

  private CheckValueExists(val: string): void {
    if (IWFObject.IsNullOrEmpty(val)) {
      this.ShowWarning(false);
    } else {
      if (this.m_values.has(val)) {
        this.ShowWarning(false);
      } else {
        if (this.m_dict == null) {
          this.ShowWarning(true);
        } else {
          this.m_dict.SetOnFetchCompleted(this.m_fetchdtachevh);
          this.m_dict.FetchValidValues(this.m_cache_srv, val, true, this.m_lineid);
        }
      }
    }
  }

  private FetchData(kwords: string): void {
    if (this.m_dict == null) {
      //this.OpenSelector(kwords);
    } else {
      this.m_dict.SetOnFetchCompleted(this.m_fetchdtaevh);
      this.m_dict.FetchValidValues(this.m_cache_srv, kwords, false, this.m_lineid);
    }
  }

  private OnFetchData(vvals: Map<string, string>, ustate: object): void {
    try {
      if (vvals != null) {
        this.m_values = vvals;
        this.clearFilter();
        //this.OpenSelector((string)ustate);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  private OnFetchDataToCheckValue(vvals: Map<string, string>, ustate: object): void {
    try {
      let val: string = ustate.toString();
      this.ShowWarning(!vvals.has(val));
      if (this.m_values.size === 0) {
        this.m_values = vvals;
        this.clearFilter();
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public handleOpen(): void {
    try {
      if (this.m_enabled) this.FetchData('');
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public Focus(): void {
    try {
      if(this.m_enabled) {
        this.m_cmb.toggle(true);
        this.FetchData('');
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }
}

import { WFOcrColumn, WFOcrFldType } from './WFOcrColumn';

export class WFOcrField {
    private static readonly SCALARKEY = '_';

    private m_id: number;
    private m_name: string;
    private m_type: WFOcrFldType;

    private m_columns: Array<WFOcrColumn>;
    private m_rows: Array<Map<string, string>>;

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_name = obj.name;
        this.m_type = obj.type;

        this.m_columns = new Array<WFOcrColumn>();
        for (let cl of obj.columns) this.m_columns.push(new WFOcrColumn(cl));

        this.m_rows = new Array<Map<string, string>>();
        for (let rw of obj.rows) {
            let row = new Map<string, string>();
            for (let kv of rw) row.set(kv.key, kv.value);
            this.m_rows.push(row);
        }
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Type(): WFOcrFldType {
        return this.m_type;
    }

    //

    public GetScalarValue(): string {
        if (this.m_rows.length > 0) {
            if (this.m_rows[0].has(WFOcrField.SCALARKEY)) return this.m_rows[0].get(WFOcrField.SCALARKEY);
        }
        return null;
    }

    //

    /*public void SetScalarValue(string nval)
    {
        if (m_rows.Count < 1) m_rows.Add(new Dictionary<string, string>());
        Dictionary<string, string> nv = m_rows[0];
        if (nv.ContainsKey(SCALARKEY))
            nv.set(SCALARKEY, nval);
        else
            nv.Add(SCALARKEY, nval);
    }*/

    //

    public GetColumnsCount(): number {
        return this.m_columns.length;
    }

    //

    public GetColumn(id: number): WFOcrColumn {
        return this.m_columns[id];
    }

    //

    public GetColumnNM(nm: string): WFOcrColumn {
        let uid = nm.trim().toUpperCase();
        for (let ii = 0; ii < this.m_columns.length; ii++) {
            let cl = this.m_columns[ii];
            if (cl.Name.toUpperCase() === uid) return cl;
        }
        return null;
    }

    //

    /*public void RemoveColumn(int id)
    {
        if (m_type != WFOcrFldType.TABLE) throw new ArgumentException();
        string unm = m_columns[id].Name.ToUpper();
        m_columns.RemoveAt(id);
        for (int ii = 0; ii < m_rows.Count; ii++)
        {
            Dictionary<string, string> rw= m_rows[ii];
            if (rw.ContainsKey(unm)) rw.Remove(unm); 
        }
    }
 
    public void AddColumn(WFOcrColumn cdef)
    {
        if (m_type != WFOcrFldType.TABLE) throw new ArgumentException();
        string unm = cdef.Name.ToUpper();
        for (int ii = 0; ii < m_columns.Count; ii++)
        {
            if (m_columns[ii].Name.ToUpper() == unm) throw new ArgumentOutOfRangeException();
        }
        m_columns.Add(cdef);
    }*/

    //

    public GetRowsCount(): number {
        return this.m_rows.length;
    }

    public GetValue(colid: string, rwid: number): string {
        colid = colid.toUpperCase();
        let rw = this.m_rows[rwid];
        return (rw.has(colid)) ? rw.get(colid) : null;
    }

    /*public void SetValue(string colid, int rwid, string nval)
    {
        colid = colid.ToUpper();
        Dictionary<string, string> rw = m_rows[rwid];
        if (rw.ContainsKey(colid))
        {
            rw.set(colid, nval);
        }
        else
        {
            rw.Add(colid, nval);
        }
    }
 
    public void AddRows(int cc)
    {
        if (m_type != WFOcrFldType.TABLE) throw new ArgumentException();
        for (int ii = 0; ii < cc; ii++)
        {
            m_rows.Add(new Dictionary<string, string>());
        }
    }*/

    public ToObject(): any {
        let columns = new Array<any>();
        for (let cl of this.m_columns) columns.push(cl.ToObject());

        let rows = new Array<any>();
        for (let rw of this.m_rows) {
            let arr = new Array<any>();
            for (let kv of Array.from(rw.entries())) arr.push({ key: kv[0], value: kv[1] });
            rows.push(arr);
        }

        return {
            id: this.m_id,
            name: this.m_name,
            type: this.m_type,
            columns: columns,
            rows: rows
        };
    }
}
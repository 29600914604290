import { WFCompany, WFPropType, WFPermissionInfo, WFPrmType } from '../model/index';
import { DocumentList } from '../data/documentlist';
import { CacheService } from '../data/cache.service';

export class WFCompanyAdp {
    private m_cmp: WFCompany;
    private m_totaldocs: number;
    private m_docs: DocumentList;

    constructor(cmp: WFCompany) {
        this.m_cmp = cmp;
        this.m_totaldocs = 0;
        this.m_docs = new DocumentList();
    }

    public get ID(): number {
        return this.m_cmp.ID;
    }

    public get Company(): string {
        return this.m_cmp.Company;
    }

    //

    public CanCreateDirectory(conn: CacheService): boolean {
        if (conn.IsSuperUser) return true;

        let allow = false;
        let replusrs: Array<number> = conn.User.FindReplacements(conn.Users);

        //zbadaj uprawnienia
        let pinfs: Array<WFPermissionInfo> = this.m_cmp.ListPermissions(WFPropType.DIR_CANCREATE, conn.AppSystem);
        for (let pinf of pinfs) {
            if ((pinf.UserID === conn.User.ID) || (replusrs.indexOf(pinf.UserID) >= 0)) {
                allow = (pinf.Type === WFPrmType.CHANGE);
                break;
            }
        }

        return allow;
    }

    //

    public set TotalDocs(nval: number) {
        this.m_totaldocs = nval;
    }

    public get TotalDocs(): number {
        return this.m_totaldocs;
    }

    //

    public get Documents(): DocumentList {
        return this.m_docs;
    }
}
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { WFFieldType, WFClassAttrib, IWFObject } from '../model';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { WFDocumentAdp } from '../adapters/WFDocumentAdp';
import { StringDataService } from '../stringdata/stringdata.service';
import { GlobalService } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { WFDocumentValidValues } from '../adapters/WFDocumentValidValues';

@Component({
  selector: 'app-column-value-selector',
  templateUrl: './column-value-selector.component.html',
  styleUrls: ['./column-value-selector.component.scss']
})

export class ColumnValueSelectorComponent {
  public m_refelement: any;

  public m_type: WFFieldType;
  public m_value: string;
  public m_valuedta: Date;
  public m_iserror: boolean;
  public m_allowcustomvalue: boolean;
  public m_dateformat: string;
  public m_allrows: boolean;
  private m_attrib: WFClassAttrib;
  private m_document: WFDocumentAdp;
  private m_curdocintmap: Map<number, WFDocumentValidValues>;

  @Output() closePopup: EventEmitter<void>;
  @Output() selectValue: EventEmitter<void>;

  //

  get value(): string {
    return this.m_value;
  }

  get valueData(): Date {
    return this.m_valuedta;
  }

  get allRows(): boolean {
    return this.m_allrows;
  }

  //

  constructor(public m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {

    this.m_refelement = null;
    this.closePopup = new EventEmitter<void>();
    this.selectValue = new EventEmitter<void>();
    this.m_type = WFFieldType.TYPE_TEXT;
    this.m_value = '';
    this.m_valuedta = new Date();
    this.m_iserror = false;
    this.m_allowcustomvalue = false;
    this.m_dateformat = 'dd-MM-yyyy';
    this.m_allrows = false;
    this.m_attrib = null;
    this.m_document = null;
    this.m_curdocintmap = null;
  }

  public SetPrms(refelement: any, atr: WFClassAttrib, dateformat: string, doc: WFDocumentAdp, curmp: Map<number, WFDocumentValidValues>): void {
    this.m_refelement = refelement;
    this.m_type = atr.Type;
    this.m_allowcustomvalue = atr.AllowCustomValue;
    this.m_dateformat = dateformat;
    this.m_attrib = atr;
    this.m_document = doc;
    this.m_curdocintmap = curmp;
  }

  public ValidValue(nval: string): void {
    try {
      this.m_iserror = !this.m_attrib.Validate(nval, this.m_document.CompanyID, this.m_cache_srv.CompanyDicts, this.m_cache_srv.AppSystem);
      this.m_value = nval;
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SetNewValue(nval: string): void {
    this.m_value = nval;
    this.selectValue.emit();
  }

  public SetNewDtaValue(nval: Date): void {
    this.m_valuedta = nval;
    this.selectValue.emit();
  }

  public FillComboBox(cmb: ComboboxadpComponent): void {
    try {
      switch (this.m_attrib.Type) {
        case WFFieldType.TYPE_SYSDICT:
          if (this.m_document.CompanyID > 0) {
            let cmp = this.m_cache_srv.Companies.get(this.m_document.CompanyID);
            if (this.m_attrib.CompaniesDictsID.has(this.m_document.CompanyID)) {
              let dcid = this.m_attrib.CompaniesDictsID.get(this.m_document.CompanyID);
              for (let tdc of cmp.Dictionaries) {
                if (tdc.ID === dcid) {
                  cmb.SetValidValues(tdc.Values);
                  break;
                }
              }
            }
          }
          break;

        case WFFieldType.TYPE_USRDICT:
          cmb.SetValidValues(this.m_attrib.ValidValues);
          break;

        case WFFieldType.TYPE_INTDICT:
          if (this.m_cache_srv.Dictionaries.has(this.m_attrib.DictionaryID)) {
            let dvv = this.m_document.GetInteractiveDictDocMapper(this.m_curdocintmap, this.m_attrib.DictionaryID);
            cmb.SetValidValues2(dvv);
          }
          break;

        case WFFieldType.TYPE_STATICDICT:
          if (this.m_cache_srv.StaticDictionaries.has(this.m_attrib.DictionaryID)) {
            let dcc = this.m_cache_srv.StaticDictionaries.get(this.m_attrib.DictionaryID);
            cmb.SetValidValues(dcc.ValidValues);
          }
          break;
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public SetValueClick(): void {
    if (!this.m_iserror) this.selectValue.emit();
  }
}

import { Component, Output, EventEmitter } from '@angular/core';
import { DataService } from '../data/data.service';
import { GlobalService, BUTTONSTYPE } from '../global/global.service';
import { StringDataService } from '../stringdata/stringdata.service';
import { CacheService } from '../data/cache.service';

@Component({
  selector: 'app-imap-login',
  templateUrl: './imap-login.component.html',
  styleUrls: ['./imap-login.component.css']
})

export class ImapLoginComponent {
  public m_login_str: string;
  public m_pass_str: string;
  public m_busy_str: string;
  public m_remember: boolean;

  @Output() public onClosed: EventEmitter<boolean>;

  constructor(private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    public m_str_srv: StringDataService,
    private m_cache_srv: CacheService) { 

      this.m_login_str = '';
      this.m_pass_str = '';
      this.m_busy_str = '';
      this.m_remember= false;

      this.onClosed = new EventEmitter<boolean>();
  }

  public SetPrms(login:string): void {
    this.m_login_str = login;
  }

  public get Login(): string {
    return this.m_login_str;
  }

  public get Pass(): string {
    return this.m_pass_str;
  }

  public get Remember(): boolean {
    return this.m_remember;
  }

  public clickLogin(): void {
    try {
      if (this.m_login_str.length >= 4 && this.m_pass_str.length >= 4) {
        /*let login = this.m_login_str.trim();
        let pass = this.m_pass_str.trim();

        let cdate = new Date();

        let epass = this.EncryptPassword(pass, LoginWndComponent.PUBLICKEY1);
        epass = this.EncryptPassword(epass, Md5.hashAsciiStr(cdate.getDate() + '' + (cdate.getMonth() + 1) + '' + cdate.getFullYear()).toString());
        epass = this.EncryptPassword(epass, LoginWndComponent.PUBLICKEY2);
        epass = this.EncryptPassword(epass, Md5.hashAsciiStr(cdate.getDate() + '' + (cdate.getMonth() + 1) + '' + cdate.getFullYear()).toString());
        epass = this.EncryptPassword(epass, LoginWndComponent.PUBLICKEY3);
        epass = this.EncryptPassword(epass, Md5.hashAsciiStr((cdate.getMonth() + 1) + '' + cdate.getDate() + '' + cdate.getFullYear()).toString());

        const self = this;

        this.m_busy_str = this.m_str_srv.getStr('strWaitSession');
        this.m_data_srv.getSessionID(login, epass, this.m_onerrorevh,
          (sid) => {
            //this.m_cookie_srv.put('lastlogin', this.m_login_str);
            this.m_cookie_srv.set('lastlogin', this.m_login_str);            
            self.GetSessionIDCompleted(sid);
          });*/

        this.onClosed.emit(true);    
      } else {
        this.m_global_srv.showInfo(BUTTONSTYPE.OK, this.m_str_srv.getStr('strNeedLogin'));
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public clickCancel(): void {
    this.onClosed.emit(false);
  }
}

export class WFClassTrigger {
    private m_name: string;
    private m_description: string;
    private m_label: string;
    private m_classattribs_id: number;

    //

    constructor(obj: any) {
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_label = obj.label;
        this.m_classattribs_id = obj.classattribs_id;
    }

    //

    public get Name(): string {
        return this.m_name;
    }

    //

    public get Description(): string {
        return this.m_description;
    }

    //

    public get Label(): string {
        return this.m_label;
    }

    //

    public get Position(): number {
        return this.m_classattribs_id;
    }
}

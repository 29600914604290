import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { EventAdp } from '../data/eventadp';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { DataService, DownloadInfo, PreparePrintPrms } from '../data/data.service';
import { CacheService } from '../data/cache.service';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';
import { IWFObject } from '../model';

interface PreviewPageInfo {
  b64data: string;
  width: number;
  height: number;
}

@Component({
  selector: 'app-document-print',
  templateUrl: './document-print.component.html',
  styleUrls: ['./document-print.component.scss']
})

export class DocumentPrintComponent implements AfterViewInit {
  public m_busy_str: string;

  private m_doc: WFDocumentAdp;
  public m_range: boolean;
  public m_rangefrom: string;
  public m_rangefrom_err: boolean;
  public m_rangeto: string;
  public m_rangeto_err: boolean;
  public m_rangecount: string;
  public m_prnauth: boolean;
  public m_prnauth_vis: boolean;
  public m_cmpreview: boolean;
  public m_cmhistory: boolean;
  public m_cmlines: boolean;
  public m_cmlines_vis: boolean;
  public m_cmosinglepage: boolean;
  public m_testpage: Array<PreviewPageInfo>;
  public m_signature: string;
  public m_acthtml: string;

  @ViewChild('m_rangefrom_cmb', {static: false}) private m_rangefrom_cmb: ComboboxadpComponent;
  @ViewChild('m_rangeto_cmb', {static: false}) private m_rangeto_cmb: ComboboxadpComponent;
  @ViewChild('m_prnauth_cmb', {static: false}) private m_prnauth_cmb: ComboboxadpComponent;

  @Output() public onClosed: EventEmitter<boolean>;
  private m_onerrorevh: EventAdp<_OnError>;

  @ViewChild('m_downlink', {static: false}) private m_downlink: ElementRef;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_global_srv: GlobalService,
    private m_data_srv: DataService,
    private m_cache_srv: CacheService) {
    //
    this.m_busy_str = '';
    this.m_doc = null;
    this.m_range = true;
    this.m_rangefrom = '';
    this.m_rangefrom_err = false;
    this.m_rangeto = '';
    this.m_rangeto_err = false;
    this.m_rangecount = '/0';
    this.m_prnauth = false;
    this.m_prnauth_vis = true;
    this.m_cmpreview = false;
    this.m_cmhistory = false;
    this.m_cmlines = false;
    this.m_cmlines_vis = false;
    this.m_cmosinglepage = false;
    this.m_testpage = new Array<PreviewPageInfo>();
    this.m_signature = '';
    this.m_acthtml = '';
    //for(let ii = 0; ii < 10; ii++) this.m_testpage.push({ b64data: '' });

    this.onClosed = new EventEmitter<boolean>();
    this.m_onerrorevh = new EventAdp<_OnError>();
    const self = this;
    this.m_onerrorevh.subscribe(() => { self.m_busy_str = ''; });
  }

  //

  public ngAfterViewInit(): void {
    let ii: number;

    try {
      this.m_cmlines_vis = false;
      if (this.m_doc.ClassID > 0) {
        if (this.m_doc.AllColumns.length > 0) this.m_cmlines_vis = true;
      }

      this.m_prnauth_vis = (this.m_doc.AuthPathsCount > 0);

      let npages = this.m_doc.GetPagesCount();

      let dcts = new Map<string, string>();
      for (ii = 0; ii < npages; ii++) {
        let pgstr = (ii + 1).toString();
        dcts.set(pgstr, pgstr);
      }

      this.m_rangefrom_cmb.SetValidValues(dcts);
      this.m_rangeto_cmb.SetValidValues(dcts);

      const self = this;
      this.m_rangefrom_cmb.SelectedValueChange.subscribe((nval) => {
        if (self.m_rangefrom !== nval) {
          self.m_rangefrom = nval;
          self.RangeChanged();
        }
      });

      this.m_rangeto_cmb.SelectedValueChange.subscribe((nval) => {
        if (self.m_rangeto !== nval) {
          self.m_rangeto = nval;
          self.RangeChanged();
        }
      });

      this.FetchPrintResources();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public InRangeChange(): void {
    try {
      this.m_range = !this.m_range;
      this.FetchPrintResources();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CheckBoxChange(field: string): void {
    try {
      this[field] = !this[field];
      this.FetchPrintResources();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public RangeChanged(): void {
    try {
      this.FetchPrintResources();
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public FetchPrintResources(): void {
    this.m_busy_str = this.m_strdata_srv.getStr('strWaitData');
    const self = this;

    let prms: PreparePrintPrms = {
      docid: this.m_doc.ID,
      rangefrom: (this.m_range) ? Number.parseInt(this.m_rangefrom) : 0,
      rangeto: (this.m_range) ? Number.parseInt(this.m_rangeto) : 0,
      mwidth: 128,
      mheight: 170,
      prnauth: this.m_prnauth,
      cmpreview: this.m_cmpreview,
      cmhistory: this.m_cmhistory,
      cmlines: this.m_cmlines,
      cmosinglepage: this.m_cmosinglepage,
      acthtml: this.m_acthtml
    };

    this.m_data_srv.preparePrint(this.m_cache_srv.SessionID, prms, this.m_onerrorevh, (sig, prev) => {
      self.PreparePrintCompleted(sig, prev);
    });
  }

  private PreparePrintCompleted(sig: string, prev: Array<any>): void {
    this.m_signature = this.m_data_srv.ServiceURL + '/GETRES/' + sig + '/' + this.m_doc.Name + '.pdf';
    this.m_testpage.splice(0, this.m_testpage.length);
    for (let dta of prev) this.m_testpage.push({ b64data: 'data:image/jpeg;base64,' + dta.image, width: dta.width, height: dta.height });
    this.m_busy_str = '';
  }

  //

  public SetPrms(doc: WFDocumentAdp, acthtml: string): void {
    this.m_doc = doc;
    this.m_acthtml = acthtml;

    let npages = this.m_doc.GetPagesCount();
    this.m_rangefrom = '1';
    if (npages > 5) npages = 5;
    this.m_rangeto = npages.toString();
    this.m_rangecount = IWFObject.Format('/{0}', npages);
  }

  //

  public OKButton_Click(): void {
    this.m_downlink.nativeElement.click();
    this.onClosed.emit(true);
  }

  //

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }
}

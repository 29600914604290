import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { StringDataService } from '../stringdata/stringdata.service';
import { DataService } from '../data/data.service';
import { GlobalService, WNDRESULT, BUTTONSTYPE } from '../global/global.service';
import { CacheService } from '../data/cache.service';
import { WFRelation, WFRelationType, WFClassAttrib, IWFObject } from '../model/index';
import { ALR_ICONTYPE } from '../alert-wnd/alert-wnd.component';
import { WFDocumentAdp, _OnError } from '../adapters/WFDocumentAdp';
import { EventAdp } from '../data/eventadp';
import { ComboIValueDesc, ComboValueDesc } from '../app.component';
import { ComboboxadpComponent } from '../comboboxadp/comboboxadp.component';

interface LineSpec {
  shmcode: string;
  attrib_id: string;
  optype: ComboIValueDesc;
}

@Component({
  selector: 'app-column-map-definitions',
  templateUrl: './column-map-definitions.component.html',
  styleUrls: ['./column-map-definitions.component.scss']
})

export class ColumnMapDefinitionsComponent {
  @Output() public onClosed: EventEmitter<boolean>;
  public m_busy_str: string;

  private m_clsatt: Array<WFClassAttrib>;
  private m_clsatt_vv: Map<string, string>;
  private m_opdesc: Array<ComboIValueDesc>;

  public m_relations: Array<LineSpec>;
  //

  constructor(private m_strdata_srv: StringDataService,
    private m_data_srv: DataService,
    private m_global_srv: GlobalService,
    private m_cache_srv: CacheService) {

    this.m_busy_str = '';
    this.onClosed = new EventEmitter<boolean>();
    this.m_clsatt = new Array<WFClassAttrib>();
    this.m_clsatt_vv = new Map<string, string>();

    this.m_opdesc = new Array<ComboIValueDesc>();
    this.m_opdesc.push({ val: <number>WFRelationType.DONTCOPY, desc: this.m_strdata_srv.getStr('wColumnMapDefinitions_cmbDontCopy') });
    this.m_opdesc.push({ val: <number>WFRelationType.COPY, desc: this.m_strdata_srv.getStr('wColumnMapDefinitions_cmbCopy') });
    this.m_opdesc.push({ val: <number>WFRelationType.INDEX, desc: this.m_strdata_srv.getStr('wColumnMapDefinitions_cmbSearch') });
    this.m_opdesc.push({ val: <number>WFRelationType.REMEMBER, desc: this.m_strdata_srv.getStr('wColumnMapDefinitions_cmbRemember') });

    this.m_relations = new Array<LineSpec>();
  }

  public SetPrms(clsatt: Array<WFClassAttrib>): void {
    this.m_clsatt = clsatt;
    this.m_clsatt_vv.clear(); 
    for (let att of clsatt) {
      this.m_clsatt_vv.set(att.ID.toString(), att.Name);
    }
  }

  public AddRelation(shmcode: string, atrid: number, rtp: WFRelationType): void {
    let optp: ComboIValueDesc;
    for (let op of this.m_opdesc) {
      if (op.val === <Number>rtp) {
        optp = op;
        break;
      }
    }

    this.m_relations.push({
      shmcode: shmcode,
      attrib_id: (atrid < 0) ? '' : atrid.toString(),
      optype: optp
     });
  }

  public get RelationsCount(): number {
    return this.m_relations.length;
  }

  public GetSchemaColumn(rlid: number): string {
    return this.m_relations[rlid].shmcode;
  }

  public GetClassAttrib(rlid: number): WFClassAttrib {
    let ln = this.m_relations[rlid];
    if (!IWFObject.IsNullOrEmpty(ln.attrib_id)) {
      let claid = Number.parseInt(ln.attrib_id);
      for (let att of this.m_clsatt) {
        if (att.ID === claid) return att;
      }
    }
    return null;
  }

  //

  public GetRelationType(rlid: number): WFRelationType {
    let ln = this.m_relations[rlid];
    return <WFRelationType>ln.optype.val;
  }

  //

  public OKButton_Click(): void {
    let ii: number;

    try {
      let doerr = true;
      for (ii = 0; ii < this.m_relations.length; ii++) {
        let rl = this.m_relations[ii];
        if (!IWFObject.IsNullOrEmpty(rl.attrib_id) && (rl.optype.val === 1 || rl.optype.val === 3)) {
          doerr = false;
          break;
        }
      }

      if (doerr) {
        this.m_global_srv.showWarning(BUTTONSTYPE.OK, this.m_strdata_srv.getStr('wColumnMapDefinitions_NoRelation'));
      } else {
        this.onClosed.emit(true);
      }
    } catch (ex) {
      this.m_global_srv.manageException(ex);
    }
  }

  public CancelButton_Click(): void {
    this.onClosed.emit(false);
  }

  public FillComboBox(cmb: ComboboxadpComponent): void {
    cmb.SetValidValues(this.m_clsatt_vv);
  }
}

import { IWFObject } from './IWFObject';

export class WFDailyCorrespondenceRef {
    private m_document_id: number;
    private m_user_id: number;
    private m_linkdate: Date;

    //

    public static Create(docid: number, usrid: number): WFDailyCorrespondenceRef {
        return new WFDailyCorrespondenceRef({
            document_id: docid,
            user_id: usrid,
            linkdate: new Date()
        });
    }

    constructor(obj: any) {
        this.m_document_id = obj.document_id;
        this.m_user_id = obj.user_id;
        this.m_linkdate = IWFObject.ParseDate(obj.linkdate);
    }

    public get DocumentID(): number {
        return this.m_document_id;
    }

    //

    public get UserID(): number {
        return this.m_user_id;
    }

    //

    public get LinkDate(): Date {
        return this.m_linkdate;
    }

    //

    public ToObject(): any {
        return {
            document_id: this.m_document_id,
            user_id: this.m_user_id,
            linkdate: IWFObject.DateToString(this.m_linkdate)
        };
    }
}
import { IWFObject } from './IWFObject';

export class WFDocumentAuthLock {
    private m_userid: number;
    private m_lockat: Date;
    private m_unlockat: Date;

    //

    public static Create(uid: number): WFDocumentAuthLock {
        return new WFDocumentAuthLock({
            userid: uid,
            lockat: new Date(),
            unlockat: null
        });
    }

    constructor(obj: any) {
        this.m_userid = obj.userid;
        this.m_lockat = IWFObject.ParseDate(obj.lockat);
        this.m_unlockat = IWFObject.ParseDate(obj.unlockat);
    }

    //

    public get UserID(): number {
        return this.m_userid;
    }

    //

    public get LockAt(): Date {
        return this.m_lockat;
    }

    //

    public set UnLockAt(value: Date) {
        if (value.getTime() < this.m_lockat.getTime()) throw new Error();
        this.m_unlockat = value;
    }

    public get UnLockAt(): Date {
        return this.m_unlockat;
    }

    //

    public ToObject(): any {
        return {
            userid: this.m_userid,
            lockat: IWFObject.DateToString(this.m_lockat),
            unlockat: IWFObject.DateToString(this.m_unlockat)
        };
    }
}

import { IWFObject } from './IWFObject';

export class WFSignature {
    private m_id: string;
    private m_document_id: number;
    private m_user_id: number;
    private m_sighours: number;
    private m_createdat: Date;
    //

    /*
    constructor(docid: number, usrid: number, sigh: number) {
            this.SignatureTime = sigh;
            this.m_createdat = Date.Now;
            this.m_id = GetHashString(String.Format("{0};{1};{2}", docid, usrid, this.m_createdat.ToFileTime()));
            this.m_document_id = docid;
            this.m_user_id = usrid;
        }*/

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_document_id = obj.document_id;
        this.m_user_id = obj.user_id;
        this.m_sighours = obj.sighours;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
    }

    //
    public get ID(): string {
        return this.m_id;
    }
    public get DocumentID(): number {
        return this.m_document_id;
    }
    public get UserID(): number {
        return this.m_user_id;
    }
    public set SignatureTime(value: number) {
        if (value < 0) throw new Error();
        this.m_sighours = value;
    }
    public get SignatureTime(): number {
        return this.m_sighours;
    }
    public get CreatedAt(): Date {
        return this.m_createdat;
    }
}
import { IWFObject } from './IWFObject';
import { WFDailyCorrespondenceRef } from './WFDailyCorrespondenceRef';
import { UpperMap } from '.';

export enum WFCorrOpType {
    ENTRY,
    EXIT,
    OTHER
}

export class WFDailyCorrespondence {
    private m_id: number;
    private m_dailycorrclass_id: number;
    private m_user_id: number;
    private m_company_id: number;
    private m_optype: WFCorrOpType;
    private m_opdate: Date;
    private m_description: string;
    private m_createdat: Date;
    private m_updatedat: Date;

    private m_refs: Array<WFDailyCorrespondenceRef>;
    private m_properties: UpperMap;

    private m_docnum: string;

    //

    public static Create(clsid: number, usrid: number): WFDailyCorrespondence {
        let now = new Date();
        return new WFDailyCorrespondence({
            id: 0,
            dailycorrclass_id: clsid,
            user_id: usrid,
            company_id: 0, 
            optype: <number>WFCorrOpType.ENTRY,
            opdate: now,
            description: '',
            createdat: now,
            updatedat: now,
            refs: [],
            properties: [],
            docnum: ""
        });
    }

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_dailycorrclass_id = obj.dailycorrclass_id;
        this.m_user_id = obj.user_id;
        this.m_company_id = obj.company_id;
        this.m_optype = <WFCorrOpType>obj.optype;
        this.m_opdate = IWFObject.ParseDate(obj.opdate);
        this.m_description = obj.description;
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);
        this.m_docnum= obj.docnum;

        this.m_refs = new Array<WFDailyCorrespondenceRef>();
        for (let rf of obj.refs) {
            this.m_refs.push(new WFDailyCorrespondenceRef(rf));
        }

        this.m_properties = new UpperMap();
        for (let kv of obj.properties) {
            this.m_properties.set(kv.key, kv.value);
        }
    }

    public get ID(): number {
        return this.m_id;
    }

    public set DailyCorrClassID(value: number) {
        if (value < 0) throw new Error();
        this.m_dailycorrclass_id = value;
    }

    public get DailyCorrClassID(): number {
        return this.m_dailycorrclass_id;
    }

    public get UserID(): number {
        return this.m_user_id;
    }

    //

    public set CompanyID(value: number) {
        if (value < 0) throw new Error();
        this.m_company_id = value;
    }

    public get CompanyID(): number {
        return this.m_company_id;
    }

    //

    public get OperationType(): WFCorrOpType {
        return this.m_optype;
    }

    public set OperationType(value: WFCorrOpType) {
        this.m_optype = value;
    }

    //

    public get OperationDate(): Date {
        return this.m_opdate;
    }

    public set OperationDate(value: Date) {
        this.m_opdate = value;
    }


    public get Description(): string {
        return this.m_description;
    }

    public set Description(value: string) {
        this.m_description = (!IWFObject.IsNullOrEmpty(value)) ? value.trim() : null;
    }

    public get References(): Array<WFDailyCorrespondenceRef> {
        return this.m_refs;
    }

    public get Properties(): UpperMap {
        return this.m_properties;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public get DocNum(): string {
        return this.m_docnum;
    }

    public ToObject(): any {
        let refs = new Array<any>();
        for (let rf of this.m_refs) refs.push(rf.ToObject());

        let properties = new Array<any>();
        for (let kv of Array.from(this.m_properties.entries())) properties.push({ key: kv[0], value: kv[1] });

        return {
            id: this.m_id,
            dailycorrclass_id: this.m_dailycorrclass_id,
            user_id: this.m_user_id,
            company_id: this.m_company_id,
            optype: <number>this.m_optype,
            opdate: IWFObject.DateToString(this.m_opdate),
            description: this.m_description,
            createdat: IWFObject.DateToString(this.m_createdat),
            updatedat: IWFObject.DateToString(this.m_updatedat),
            refs: refs,
            properties: properties,
            docnum: this.m_docnum
        };
    }

}
import { WFPrmType, WFPrmObjType, WFPrmDocState } from './WFPermission';
import { WFDocumentRef } from './WFDocumentRef';
import { WFDocumentPage } from './WFDocumentPage';
import { WFPermission, WFPropType } from './WFPermission';
import { WFDocumentLine, UpperMap } from './WFDocumentLine';
import { WFDocumentModification } from './WFDocumentModification';
import { WFDocumentOcrResults } from './WFDocumentOcrResults';
import { WFAuthSchema, WFAuthEditMode, WFAuthMode, WFAuthMethodType } from './WFAuthSchema';
import { WFAuthPermission } from './WFAuthPermission';
import { WFDocumentAuthSchema } from './WFDocumentAuthSchema';
import { WFDocumentAuthRecipient, WFDocAuthObjType } from './WFDocumentAuthRecipient';
import { WFDocumentOcrMap, WFRelation } from './WFDocumentOcrMap';
import { IWFObject } from './IWFObject';
import { WFSystem } from './WFSystem';
import { WFClass } from './WFClass';
import { WFClassAttrib, WFFieldType } from './WFClassAttrib';
import { WFUser } from './WFUser';
import { WFUserGroup, WFGroupRole } from './WFUserGroup';
import { WFCompany } from './WFCompany';
import { WFSchemaRecipient, WFObjType } from './WFSchemaRecipient';
import { IWFDocumentAuthVariable, WFDocAuthVarType, WFDocumentAuthVarSchema, WFDocumentAuthVarBasic } from './WFDocumentAuthVariable';
import { WFAuthInstruction } from './WFAuthInstruction';
import { WFInstructionParameter } from './WFInstructionParameter';
import { WFProcessVariable, WFProcessVarType } from './WFProcessVariable';
import { WFModObjType } from './WFModificationEntry';
import { StringDataPipe } from '../stringdata/stringdata.pipe';

export enum WFAuthStatus {
    WAITING,
    APPROVED,
    DISAPPROVED
}

export enum WFDocStatus {
    BEFOREOCR,
    INOCR,
    AFTEROCR,
    INSYSTEM,
    TEMPLATE
}

//

export class WFPermissionInfo {
    private m_userid: number;
    private m_usergroupsid: number;
    private m_type: WFPrmType;

    constructor(uid: number, grpid: number, ptp: WFPrmType) {
        this.m_userid = uid;
        this.m_usergroupsid = grpid;
        this.m_type = ptp;
    }

    public get UserID(): number {
        return this.m_userid;
    }

    public get UserGroupID(): number {
        return this.m_usergroupsid;
    }

    public get Type(): WFPrmType {
        return this.m_type;
    }
}

//

export enum WFTmpWriteMode {
    DENYALL,
    ALLOWALL,
    ALLOWONLYCONTENT
}

//

export class WFDocument {
    public static readonly SCHM_NOAUTH = -1;
    public static readonly SCHM_CUSTOM = 0;

    private m_id: number;
    private m_user_id: number;
    private m_class_id: number;
    private m_company_id: number;
    // private int m_document_id;
    private m_directory_id: number;

    private m_name: string;
    private m_description: string;
    private m_docdate: Date;
    private m_enterdate: Date;
    private m_docnum: string;
    private m_createdat: Date;
    private m_updatedat: Date;

    private m_docref: Array<WFDocumentRef>;
    private m_docpages: Array<WFDocumentPage>;
    private m_perms: Array<WFPermission>;

    private m_properties: UpperMap;
    private m_lines: Array<WFDocumentLine>;

    private m_docstatus: WFDocStatus;
    private m_reconschemas_code: string;

    //private string m_externalid;

    //5.8
    private m_template_id: number;
    private m_modifications: Array<WFDocumentModification>;
    private m_iseditable: boolean;

    //
    private m_ocrresults: Array<WFDocumentOcrResults>;
    private m_definitioncode: string;
    private m_ocrcode: string;
    //

    private m_defeditmode: Array<WFAuthEditMode>;

    private m_hdreditmodex: Array<WFAuthPermission>;
    private m_lnseditmodex: Array<WFAuthPermission>;

    private m_attachments_id: Array<number>;
    private m_curauthstatus: WFAuthStatus;

    private m_canchangeres: boolean;

    private m_authpaths: Array<WFDocumentAuthSchema>;

    private m_sysdocnum: number;
    private m_rm_users_id: number;

    private m_tmpwritemode: WFTmpWriteMode;

    private m_autopublish: boolean; //automatyczna publikacja w katalogu firmy - po ocr
    private m_ocrmaps: Array<WFDocumentOcrMap>; //ostatni schemat mapowania
    private m_grouping: Array<number>;

    private m_istpl: boolean;

    //

    public static Create(nm: string, desc: string, dnum: string, ddate: Date): WFDocument {
        let dnow = new Date();
        return new WFDocument({
            id: 0,
            user_id: 0,
            class_id: 0,
            company_id: 0,
            directory_id: 0,
            name: nm,
            description: desc,
            docnum: dnum,
            docdate: ddate,
            enterdate: dnow,
            createdat: dnow,
            updatedat: dnow,
            docref: [],
            docpages: [],
            perms: [],
            properties: [],

            lines: [],
            docstatus: WFDocStatus.BEFOREOCR,
            reconschemas_code: '',

            //5.8
            template_id: 0,
            modifications: [],
            iseditable: false,

            ocrresults: [],
            definitioncode: '',
            ocrcode: '',

            defeditmode0: WFAuthEditMode.MODE_DENY,
            defeditmode1: WFAuthEditMode.MODE_ALLOW,
            defeditmode2: WFAuthEditMode.MODE_DENY,
            defeditmode3: WFAuthEditMode.MODE_ALLOW,
            defeditmode4: WFAuthEditMode.MODE_ALLOW,
            defeditmode5: WFAuthEditMode.MODE_ALLOW,

            hdreditmodex: [],
            lnseditmodex: [],

            attachments_id: [],
            curauthstatus: WFAuthStatus.WAITING,
            canchangeres: false,

            authpaths: [],
            sysdocnum: 0,
            rm_users_id: 0,
            tmpwritemode: WFTmpWriteMode.DENYALL,

            autopublish: false,
            ocrmaps: [],
            grouping: [],
            istpl: false
        });
    }

    //

    private static ZeroID(obj: Object): void {
        let props = Object.keys(obj);
        for (let pr of props) {
            if (pr === 'id') {
                obj[pr] = 0;
                continue;
            }

            let ob = obj[pr];
            if (ob == null) continue;

            if (typeof ob === 'object') {
                this.ZeroID(ob);
                continue;
            }

            if (ob instanceof Array) {
                let arr = <Array<Object>>ob;
                for (let vv of arr) {
                    if (typeof vv === 'object') this.ZeroID(ob);
                }
            }
        }
    }

    public static Create2(src: WFDocument): WFDocument {
        let ii: number;
        let dnow = new Date();
        let ret = WFDocument.Create(src.m_name, src.m_description, src.m_docnum, src.m_docdate);

        ret.m_user_id = src.m_user_id;
        ret.m_class_id = src.m_class_id;
        ret.m_company_id = src.m_company_id;
        ret.m_directory_id = src.m_directory_id;

        for (let rf of src.m_docref) {
            let obj = rf.ToObject();
            this.ZeroID(obj);
            ret.m_docref.push(new WFDocumentRef(obj));
        }

        for (let pg of src.m_docpages) {
            let obj = pg.ToObject();
            this.ZeroID(obj);
            ret.m_docpages.push(new WFDocumentPage(obj));
        }

        for (let pr of src.m_perms) {
            let obj = pr.ToObject();
            this.ZeroID(obj);
            ret.m_perms.push(new WFPermission(obj));
        }

        for (let ky of Array.from(src.m_properties.entries())) {
            ret.m_properties.set(ky[0], ky[1]);
        }

        ret.m_docstatus = src.m_docstatus;

        ret.m_reconschemas_code = src.m_reconschemas_code;
        //m_externalid = src.m_externalid;
        ret.m_enterdate = src.m_enterdate;

        ret.m_template_id = src.m_template_id;
        for (let sdc of src.m_lines) {
            let obj = sdc.ToObject();
            this.ZeroID(obj);
            ret.m_lines.push(new WFDocumentLine(obj));
        }

        ret.m_iseditable = src.m_iseditable;

        for (let ors of src.m_ocrresults) {
            let obj = ors.ToObject();
            this.ZeroID(obj);
            ret.m_ocrresults.push(new WFDocumentOcrResults(obj));
        }

        ret.m_definitioncode = src.m_definitioncode;
        ret.m_ocrcode = src.m_ocrcode;

        for (ii = 0; ii < src.m_defeditmode.length; ii++) {
            ret.m_defeditmode[ii] = src.m_defeditmode[ii];
        }

        for (let prm of src.m_hdreditmodex) {
            let obj = prm.ToObject();
            this.ZeroID(obj);
            ret.m_hdreditmodex.push(new WFAuthPermission(obj));
        }

        for (let prm of src.m_lnseditmodex) {
            let obj = prm.ToObject();
            this.ZeroID(obj);
            ret.m_lnseditmodex.push(new WFAuthPermission(obj));
        }

        ret.m_attachments_id = ret.m_attachments_id.concat(src.m_attachments_id);

        ret.m_curauthstatus = src.m_curauthstatus;
        ret.m_canchangeres = src.m_canchangeres;

        ret.m_sysdocnum = 0;
        if (src.m_authpaths.length > 0 && src.m_curauthstatus === WFAuthStatus.WAITING) {
            let obj = src.m_authpaths[src.m_authpaths.length - 1].ToObject();
            this.ZeroID(obj);
            ret.m_authpaths.push(new WFDocumentAuthSchema(obj));
        }

        ret.m_rm_users_id = 0;
        ret.m_tmpwritemode = src.m_tmpwritemode;
        ret.m_autopublish = src.m_autopublish;
        ret.m_grouping = ret.m_grouping.concat(src.m_grouping);

        ret.m_istpl = src.m_istpl;
        return ret;
    }

    //

    constructor(obj: any) {
        this.m_id = obj.id;
        this.m_user_id = obj.user_id;
        this.m_class_id = obj.class_id;
        this.m_company_id = obj.company_id;
        this.m_directory_id = obj.directory_id;
        this.m_name = obj.name;
        this.m_description = obj.description;
        this.m_docnum = obj.docnum;
        this.m_docdate = IWFObject.ParseDate(obj.docdate);
        this.m_createdat = IWFObject.ParseDate(obj.createdat);
        this.m_updatedat = IWFObject.ParseDate(obj.updatedat);

        this.m_docref = new Array<WFDocumentRef>();
        for (let rf of obj.docref) this.m_docref.push(new WFDocumentRef(rf));

        this.m_docpages = new Array<WFDocumentPage>();
        for (let pg of obj.docpages) this.m_docpages.push(new WFDocumentPage(pg));

        this.m_perms = new Array<WFPermission>();
        for (let prm of obj.perms) this.m_perms.push(new WFPermission(prm));

        this.m_properties = new UpperMap();
        for (let kv of obj.properties) this.m_properties.set(kv.key, kv.value);

        this.m_docstatus = obj.docstatus;
        this.m_reconschemas_code = obj.reconschemas_code;
        this.m_enterdate = IWFObject.ParseDate(obj.enterdate);
        this.m_template_id = obj.template_id;

        this.m_modifications = new Array<WFDocumentModification>();
        for (let md of obj.modifications) this.m_modifications.push(new WFDocumentModification(md));

        this.m_lines = new Array<WFDocumentLine>();
        for (let ln of obj.lines) this.m_lines.push(new WFDocumentLine(ln));

        this.m_iseditable = obj.iseditable;

        this.m_ocrresults = new Array<WFDocumentOcrResults>();
        for (let ocr of obj.ocrresults) this.m_ocrresults.push(new WFDocumentOcrResults(ocr));

        this.m_definitioncode = obj.definitioncode;
        this.m_ocrcode = obj.ocrcode;

        this.m_defeditmode = new Array<WFAuthEditMode>();
        this.m_defeditmode.push(obj.defeditmode0);
        this.m_defeditmode.push(obj.defeditmode1);
        this.m_defeditmode.push(obj.defeditmode2);
        this.m_defeditmode.push(obj.defeditmode3);
        this.m_defeditmode.push(obj.defeditmode4);
        this.m_defeditmode.push(obj.defeditmode5);

        this.m_hdreditmodex = new Array<WFAuthPermission>();
        for (let em of obj.hdreditmodex) this.m_hdreditmodex.push(new WFAuthPermission(em));

        this.m_lnseditmodex = new Array<WFAuthPermission>();
        for (let lm of obj.lnseditmodex) this.m_lnseditmodex.push(new WFAuthPermission(lm));

        this.m_attachments_id = obj.attachments_id;
        this.m_curauthstatus = obj.curauthstatus;
        this.m_canchangeres = obj.canchangeres;

        this.m_authpaths = new Array<WFDocumentAuthSchema>();
        for (let ap of obj.authpaths) this.m_authpaths.push(new WFDocumentAuthSchema(ap));

        this.m_sysdocnum = obj.sysdocnum;
        this.m_rm_users_id = obj.rm_users_id;
        this.m_tmpwritemode = obj.tmpwritemode;
        this.m_autopublish = obj.autopublish;

        this.m_ocrmaps = new Array<WFDocumentOcrMap>();
        for (let om of obj.ocrmaps) this.m_ocrmaps.push(new WFDocumentOcrMap(om));

        this.m_grouping = obj.grouping;
        this.m_istpl = obj.istpl;
    }

    public ToObject(): any {
        let docref = new Array<any>();
        for (let rf of this.m_docref) docref.push(rf.ToObject());

        let docpages = new Array<any>();
        for (let pg of this.m_docpages) docpages.push(pg.ToObject());

        let perms = new Array<any>();
        for (let prm of this.m_perms) perms.push(prm.ToObject());

        let properties = new Array<any>();
        for (let kv of Array.from(this.m_properties.entries())) properties.push({ key: kv[0], value: kv[1] });

        let modifications = new Array<any>();
        for (let md of this.m_modifications) modifications.push(md.ToObject());

        let lines = new Array<any>();
        for (let ln of this.m_lines) lines.push(ln.ToObject());

        let ocrresults = new Array<any>();
        for (let ocr of this.m_ocrresults) ocrresults.push(ocr.ToObject());

        let hdreditmodex = new Array<any>();
        for (let em of this.m_hdreditmodex) hdreditmodex.push(em.ToObject());

        let lnseditmodex = new Array<any>();
        for (let lm of this.m_lnseditmodex) lnseditmodex.push(lm.ToObject());

        let authpaths = new Array<any>();
        for (let ap of this.m_authpaths) authpaths.push(ap.ToObject());

        let ocrmaps = new Array<any>();
        for (let om of this.m_ocrmaps) ocrmaps.push(om.ToObject());

        let dt = new Date();

        return {
            id: this.m_id,
            user_id: this.m_user_id,
            class_id: this.m_class_id,
            company_id: this.m_company_id,
            directory_id: this.m_directory_id,
            name: this.m_name,
            description: this.m_description,
            docnum: this.m_docnum,
            docdate: IWFObject.DateToString(this.m_docdate),
            createdat: IWFObject.DateToString(this.m_createdat),
            updatedat: IWFObject.DateToString(this.m_updatedat),
            docref: docref,
            docpages: docpages,
            perms: perms,
            properties: properties,
            docstatus: this.m_docstatus,
            reconschemas_code: this.m_reconschemas_code,
            enterdate: IWFObject.DateToString(this.m_enterdate),
            template_id: this.m_template_id,
            modifications: modifications,
            lines: lines,
            iseditable: this.m_iseditable,
            ocrresults: ocrresults,
            definitioncode: this.m_definitioncode,
            ocrcode: this.m_ocrcode,
            defeditmode0: this.m_defeditmode[0],
            defeditmode1: this.m_defeditmode[1],
            defeditmode2: this.m_defeditmode[2],
            defeditmode3: this.m_defeditmode[3],
            defeditmode4: this.m_defeditmode[4],
            defeditmode5: this.m_defeditmode[5],
            hdreditmodex: hdreditmodex,
            lnseditmodex: lnseditmodex,
            attachments_id: this.m_attachments_id,
            curauthstatus: this.m_curauthstatus,
            canchangeres: this.m_canchangeres,
            authpaths: authpaths,
            sysdocnum: this.m_sysdocnum,
            rm_users_id: this.m_rm_users_id,
            tmpwritemode: this.m_tmpwritemode,
            autopublish: this.m_autopublish,
            ocrmaps: ocrmaps,
            grouping: this.m_grouping,
            istpl: this.m_istpl
        };
    }

    //

    public get ID(): number {
        return this.m_id;
    }

    public set UserID(value: number) {
        if (this.m_user_id !== value) {
            if (this.m_id > 0) throw new Error();
            this.m_user_id = value;
        }
    }

    public get UserID(): number {
        return this.m_user_id;
    }

    public set ClassID(value: number) {
        this.m_class_id = value;
    }

    public get ClassID(): number {
        return this.m_class_id;
    }

    public set CompanyID(value: number) {
        this.m_company_id = value;
    }

    public get CompanyID(): number {
        return this.m_company_id;
    }

    public get AttachmentsID(): Array<number> {
        return this.m_attachments_id;
    }

    public set DirectoryID(value: number) {
        this.m_directory_id = value;
    }

    public get DirectoryID(): number {
        return this.m_directory_id;
    }

    public SetDirectoryID(dir_id: number, usr_id: number): void {
        if (dir_id !== this.m_directory_id) {
            if (usr_id <= 0) throw new Error();
            this.m_directory_id = dir_id;
            this.m_rm_users_id = usr_id;
        }
    }

    public get RemoveUsersID(): number {
        return this.m_rm_users_id;
    }

    public set Name(value: string) {
        this.m_name = IWFObject.CutString(value, 100);
    }

    public get Name(): string {
        return this.m_name;
    }

    public set Description(value: string) {
        this.m_description = value.trim();
    }

    public get Description(): string {
        return this.m_description;
    }

    public set DocDate(value: Date) {
        this.m_docdate = value;
    }

    public get DocDate(): Date {
        return this.m_docdate;
    }

    public set EnterDate(value: Date) {
        this.m_enterdate = value;
    }

    public get EnterDate(): Date {
        return this.m_enterdate;
    }

    public set DocNum(value: string) {
        this.m_docnum = IWFObject.CutString(value, 100);
    }

    public get DocNum(): string {
        return this.m_docnum;
    }

    public get CreatedAt(): Date {
        return this.m_createdat;
    }

    public get UpdatedAt(): Date {
        return this.m_updatedat;
    }

    public get Refs(): Array<WFDocumentRef> {
        return this.m_docref;
    }

    public get Pages(): Array<WFDocumentPage> {
        return this.m_docpages;
    }

    public get Permissions(): Array<WFPermission> {
        return this.m_perms;
    }

    public set TemplateID(value: number) {
        this.m_template_id = value;
    }

    public get TemplateID(): number {
        return this.m_template_id;
    }

    public set TemplateWriteMode(value: WFTmpWriteMode) {
        this.m_tmpwritemode = value;
    }

    public get TemplateWriteMode(): WFTmpWriteMode {
        return this.m_tmpwritemode;
    }

    public get IsEditable(): boolean {
        return this.m_iseditable;
    }

    public get OcrResults(): Array<WFDocumentOcrResults> {
        return this.m_ocrresults;
    }

    public set DefinitionCode(value: string) {
        this.m_definitioncode = value.trim();
    }

    public get DefinitionCode(): string {
        return this.m_definitioncode;
    }

    public set OcrCode(value: string) {
        this.m_ocrcode = value.trim();
    }

    public get OcrCode(): string {
        return this.m_ocrcode;
    }

    public set AutoPublish(value: boolean) {
        this.m_autopublish = value;
    }

    public get AutoPublish(): boolean {
        return this.m_autopublish;
    }

    public get IsTPL(): boolean {
        return this.m_istpl;
    }

    public GetOcrMapsCount(): number {
        return this.m_ocrmaps.length;
    }

    public GetOcrMap(id: number): WFDocumentOcrMap {
        return this.m_ocrmaps[id];
    }

    public FindOcrMaps(schemacode: string, ccode: string, app: WFSystem): Array<WFDocumentOcrMap> {
        let ret = new Array<WFDocumentOcrMap>();
        for (let ii = 0; ii < this.m_ocrmaps.length; ii++) {
            let mp = this.m_ocrmaps[ii];
            if (mp.ClassID === this.m_class_id && mp.CardCode === ccode && mp.SchemaCode === schemacode)
                ret.push(mp);
        }
        return ret;
    }

    public AddOcrMaps(rels: Array<WFRelation>, schemacode: string, ccode: string, app: WFSystem): void {
        let ii: number, ii2: number;

        for (ii = 0; ii < rels.length; ii++) {
            let rel: WFRelation = rels[ii];
            let attribcode: string = rel.AttribCode.toUpperCase();
            let ocrname: string = rel.OcrName.toUpperCase();
            let add = true;
            for (ii2 = 0; ii2 < this.m_ocrmaps.length; ii2++) {
                let mp: WFDocumentOcrMap = this.m_ocrmaps[ii2];
                if (mp.ClassID === this.m_class_id && mp.CardCode === ccode && mp.SchemaCode === schemacode && mp.Relation.AttribCode.toUpperCase() === attribcode && mp.Relation.OcrName.toUpperCase() === ocrname) {
                    mp.Relation.MapType = rel.MapType;
                    add = false;
                    break;
                }
            }
            if (add) {
                let ret: WFDocumentOcrMap = WFDocumentOcrMap.Create(this.m_class_id, ccode, schemacode, rel);
                this.m_ocrmaps.push(ret);
            }
        }
    }

    public ClearOcrMaps(schemacode: string, ccode: string, app: WFSystem): void {
        let ii = 0;
        while (ii < this.m_ocrmaps.length) {
            let mp: WFDocumentOcrMap = this.m_ocrmaps[ii];
            if (mp.ClassID === this.m_class_id && mp.CardCode === ccode && mp.SchemaCode === schemacode)
                this.m_ocrmaps.splice(ii, 1);
            else ii++;
        }
    }

    public get Grouping(): Array<number> {
        return this.m_grouping;
    }

    public GetDefaultEditMode(ast: WFAuthStatus, tp: WFAuthMode): WFAuthEditMode {
        return this.m_defeditmode[<number>ast * 2 + <number>tp];
    }

    public SetDefaultEditMode2(ast: WFAuthStatus, tp: WFAuthMode, md: WFAuthEditMode): void {
        this.m_defeditmode[<number>ast * 2 + <number>tp] = md;
    }

    public get HeaderExceptions(): Array<WFAuthPermission> {
        return this.m_hdreditmodex;
    }

    public get LinesExceptions(): Array<WFAuthPermission> {
        return this.m_lnseditmodex;
    }

    public GetAuthPathsCount(): number {
        return this.m_authpaths.length;
    }

    public GetAuthPath(id: number): WFDocumentAuthSchema {
        return this.m_authpaths[id];
    }

    public GetStatus(): WFAuthStatus {
        if (this.m_authpaths.length > 0) {
            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) return lshm.GetStatus();
        }
        return WFAuthStatus.WAITING;
    }

    public get AuthSchemaID(): number {
        if (this.m_authpaths.length > 0) {
            return this.m_authpaths[this.m_authpaths.length - 1].AuthSchemaID;
        }
        return WFDocument.SCHM_NOAUTH;
    }

    public get AuthMethodType(): WFAuthMethodType {
        if (this.m_authpaths.length > 0) {
            return this.m_authpaths[this.m_authpaths.length - 1].Type;
        }
        return WFAuthMethodType.AUTH_ALLOF;
    }

    public get AuthUserLimit(): number {
        if (this.m_authpaths.length > 0) {
            return this.m_authpaths[this.m_authpaths.length - 1].Userlimit;
        }
        return 1;
    }

    public get AdditionalInfo(): string {
        if (this.m_authpaths.length > 0) {
            return this.m_authpaths[this.m_authpaths.length - 1].AdditionalInfo;
        }
        return '';
    }

    private ClearAuthSchemas(): void {
        let ii = 0;
        while (ii < this.m_authpaths.length) {
            let shm: WFDocumentAuthSchema = this.m_authpaths[ii];
            if (shm.ID > 0) {
                ii++;
                continue;
            }
            this.m_authpaths.splice(ii, 1);
        }
    }

    private SetPrmsFromAuthSchema(shm: WFAuthSchema): void {
        let ii: number;

        this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER, shm.GetDefaultEditMode(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER));
        this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER, shm.GetDefaultEditMode(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER));
        this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER, shm.GetDefaultEditMode(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER));
        this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES, shm.GetDefaultEditMode(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES));
        this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES, shm.GetDefaultEditMode(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES));
        this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES, shm.GetDefaultEditMode(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES));

        this.m_hdreditmodex.splice(0, this.m_hdreditmodex.length);
        for (ii = 0; ii < shm.HeaderExceptions.length; ii++) {
            this.m_hdreditmodex.push(new WFAuthPermission(shm.HeaderExceptions[ii].ToObject()));
        }

        this.m_lnseditmodex.splice(0, this.m_lnseditmodex.length);
        for (ii = 0; ii < shm.LinesExceptions.length; ii++) {
            this.m_lnseditmodex.push(new WFAuthPermission(shm.LinesExceptions[ii].ToObject()));
        }
    }

    public SetAuthSchema(shm: WFAuthSchema, sys: WFSystem): boolean {
        let ii: number;
        if (this.m_docstatus === WFDocStatus.INSYSTEM) {
            if (this.m_authpaths.length > 0) {
                this.ClearAuthSchemas();
                if (this.m_authpaths.length > 0) {
                    let lshm: WFDocumentAuthSchema = this.m_authpaths[this.m_authpaths.length - 1];
                    if (lshm.GetApprovedCount(WFAuthStatus.APPROVED) === 0 && lshm.GetApprovedCount(WFAuthStatus.DISAPPROVED) === 0) {
                        if (lshm.CmpRecipients(shm, sys)) {
                            this.SetPrmsFromAuthSchema(shm);
                            return true;
                        }
                        this.m_authpaths.splice(this.m_authpaths.length - 1, 1);
                    } else {
                        if (lshm.GetStatus() === WFAuthStatus.WAITING && lshm.AuthSchemaID === shm.ID)
                            this.m_authpaths.splice(this.m_authpaths.length - 1, 1);
                    }
                    for (ii = 0; ii < this.m_authpaths.length; ii++) {
                        this.m_authpaths[ii].SetVisibility(false);
                    }
                }
            }
        } else {
            this.m_authpaths.splice(0, this.m_authpaths.length);
        }
        let ashm: WFDocumentAuthSchema;
        if (shm.Type === WFAuthMethodType.AUTH_CUSTOM) {
            ashm = WFDocumentAuthSchema.Create3(shm.Name, WFAuthMethodType.AUTH_CUSTOM, 1, shm.ID, shm.AdditionalInfo);
        } else {
            ashm = WFDocumentAuthSchema.Create(shm.Recipients,
                shm.Name,
                shm.Type,
                shm.AuthUserLimit,
                shm.ID,
                shm.AdditionalInfo,
                sys);
        }
        this.m_authpaths.push(ashm);
        this.SetPrmsFromAuthSchema(shm);
        this.m_curauthstatus = WFAuthStatus.WAITING;
        return true;
    }

    public CloneLastAuthPath(sys: WFSystem): boolean {
        let ii: number;

        if (this.m_authpaths.length > 0) {
            this.ClearAuthSchemas();
            if (this.m_authpaths.length > 0) {
                let lshm: WFDocumentAuthSchema = this.m_authpaths[this.m_authpaths.length - 1];
                if (lshm.GetApprovedCount(WFAuthStatus.APPROVED) === 0 && lshm.GetApprovedCount(WFAuthStatus.DISAPPROVED) === 0) {
                    this.m_authpaths.splice(this.m_authpaths.length - 1, 1);
                    if (this.m_authpaths.length === 0) return false;
                    lshm = this.m_authpaths[this.m_authpaths.length - 1];
                }
                let ashm: WFDocumentAuthSchema;
                for (ii = 0; ii < this.m_authpaths.length; ii++) {
                    this.m_authpaths[ii].SetVisibility(false);
                }
                if (lshm.AuthSchemaID > 0) {
                    let shm: WFAuthSchema = sys.AllSchemas.get(lshm.AuthSchemaID);
                    if (shm.Type === WFAuthMethodType.AUTH_CUSTOM) {
                        ashm = WFDocumentAuthSchema.Create3(shm.Name, WFAuthMethodType.AUTH_CUSTOM, 1, shm.ID, shm.AdditionalInfo);
                    } else {
                        ashm = WFDocumentAuthSchema.Create(shm.Recipients,
                            shm.Name,
                            shm.Type,
                            shm.AuthUserLimit,
                            shm.ID,
                            shm.AdditionalInfo,
                            sys);
                    }
                    this.m_authpaths.push(ashm);
                    this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER, shm.GetDefaultEditMode(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER));
                    this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER, shm.GetDefaultEditMode(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER));
                    this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER, shm.GetDefaultEditMode(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER));
                    this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES, shm.GetDefaultEditMode(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES));
                    this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES, shm.GetDefaultEditMode(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES));
                    this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES, shm.GetDefaultEditMode(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES));
                    this.m_hdreditmodex.splice(0, this.m_hdreditmodex.length);
                    for (ii = 0; ii < shm.HeaderExceptions.length; ii++) {
                        this.m_hdreditmodex.push(WFAuthPermission.Create(shm.HeaderExceptions[ii]));
                    }
                    this.m_lnseditmodex.splice(0, this.m_lnseditmodex.length);
                    for (ii = 0; ii < shm.LinesExceptions.length; ii++) {
                        this.m_lnseditmodex.push(WFAuthPermission.Create(shm.LinesExceptions[ii]));
                    }
                } else {
                    let nrcps: Array<WFSchemaRecipient> = new Array<WFSchemaRecipient>();
                    let cc: number = lshm.GetRecipientsCount();
                    for (ii = 0; ii < cc; ii++) {
                        let arc: WFDocumentAuthRecipient = lshm.GetRecipient(ii);
                        let src: WFSchemaRecipient = null;
                        switch (arc.ObjType) {
                            case WFDocAuthObjType.DOCAUTHSCHEMA:
                                src = WFSchemaRecipient.Create(arc.SubSchema.AuthSchemaID, WFObjType.AUTHSCHEMA);
                                break;
                            case WFDocAuthObjType.USER:
                                src = WFSchemaRecipient.Create(arc.ObjID, WFObjType.USER);
                                break;
                        }
                        if (src != null) {
                            src.Order = arc.Order;
                            nrcps.push(src);
                        }
                    }
                    ashm = WFDocumentAuthSchema.Create(nrcps,
                        lshm.Name,
                        lshm.Type,
                        lshm.Userlimit,
                        WFDocument.SCHM_CUSTOM,
                        lshm.AdditionalInfo,
                        sys);
                    this.m_authpaths.push(ashm);
                    this.SetDefaultEditMode3();
                }
                this.m_curauthstatus = WFAuthStatus.WAITING;
                return true;
            }
        }
        return false;
    }

    private SetDefaultEditMode3(): void {
        this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER, WFAuthEditMode.MODE_ALLOW);
        this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER, WFAuthEditMode.MODE_DENY);
        this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER, WFAuthEditMode.MODE_ALLOW);
        this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES, WFAuthEditMode.MODE_ALLOW);
        this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES, WFAuthEditMode.MODE_ALLOW);
        this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES, WFAuthEditMode.MODE_ALLOW);
        this.m_hdreditmodex.splice(0, this.m_hdreditmodex.length);
        this.m_lnseditmodex.splice(0, this.m_lnseditmodex.length);
    }

    public SetAuthCustomSchema(type: WFAuthMethodType, rcps: Array<WFSchemaRecipient>, userlimit: number, addinfo: string, srcshm: WFAuthSchema, sys: WFSystem): boolean {
        let ii: number;

        if (rcps.length < 1 || userlimit < 1) return false;

        if (this.m_docstatus === WFDocStatus.INSYSTEM) {
            if (this.m_authpaths.length > 0) {
                this.ClearAuthSchemas();
                if (this.m_authpaths.length > 0) {
                    let lshm: WFDocumentAuthSchema = this.m_authpaths[this.m_authpaths.length - 1];
                    if (lshm.GetApprovedCount(WFAuthStatus.APPROVED) === 0 && lshm.GetApprovedCount(WFAuthStatus.DISAPPROVED) === 0) {
                        if (lshm.CmpRecipients2(type, userlimit, rcps, WFDocument.SCHM_CUSTOM, sys)) {
                            return true;
                        }
                        this.m_authpaths.splice(this.m_authpaths.length - 1, 1);
                    }
                    for (ii = 0; ii < this.m_authpaths.length; ii++) {
                        this.m_authpaths[ii].SetVisibility(false);
                    }
                }
            }
        } else {
            this.m_authpaths.splice(0, this.m_authpaths.length);
        }

        let ashm: WFDocumentAuthSchema = WFDocumentAuthSchema.Create(rcps, '', type, userlimit, WFDocument.SCHM_CUSTOM, addinfo, sys);
        this.m_authpaths.push(ashm);
        this.m_curauthstatus = WFAuthStatus.WAITING;
        if (srcshm == null) {
            this.SetDefaultEditMode3();
        } else {
            this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER, srcshm.GetDefaultEditMode(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_HEADER));
            this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER, srcshm.GetDefaultEditMode(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_HEADER));
            this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER, srcshm.GetDefaultEditMode(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_HEADER));
            this.SetDefaultEditMode2(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES, srcshm.GetDefaultEditMode(WFAuthStatus.WAITING, WFAuthMode.MODE_IN_LINES));
            this.SetDefaultEditMode2(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES, srcshm.GetDefaultEditMode(WFAuthStatus.APPROVED, WFAuthMode.MODE_IN_LINES));
            this.SetDefaultEditMode2(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES, srcshm.GetDefaultEditMode(WFAuthStatus.DISAPPROVED, WFAuthMode.MODE_IN_LINES));
            this.m_hdreditmodex.splice(0, this.m_hdreditmodex.length);
            for (ii = 0; ii < srcshm.HeaderExceptions.length; ii++) {
                this.m_hdreditmodex.push(WFAuthPermission.Create(srcshm.HeaderExceptions[ii]));
            }
            this.m_lnseditmodex.splice(0, this.m_lnseditmodex.length);
            for (ii = 0; ii < srcshm.LinesExceptions.length; ii++) {
                this.m_lnseditmodex.push(WFAuthPermission.Create(srcshm.LinesExceptions[ii]));
            }
        }
        return true;
    }

    public SetNoAuthSchema(): boolean {
        let ii: number;
        if (this.m_docstatus === WFDocStatus.INSYSTEM) {
            if (this.m_authpaths.length > 0) {
                this.ClearAuthSchemas();
                if (this.m_authpaths.length > 0) {
                    let lshm: WFDocumentAuthSchema = this.m_authpaths[this.m_authpaths.length - 1];
                    if (lshm.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                        for (ii = 0; ii < this.m_authpaths.length; ii++) {
                            this.m_authpaths[ii].SetVisibility(false);
                        }
                        lshm = WFDocumentAuthSchema.Create3('', WFAuthMethodType.AUTH_ALLOF, 1, WFDocument.SCHM_NOAUTH, '');
                        this.m_authpaths.push(lshm);
                    }
                }
            }
        } else {
            this.m_authpaths.splice(0, this.m_authpaths.length);
        }
        this.m_curauthstatus = WFAuthStatus.WAITING;
        this.SetDefaultEditMode3();
        return true;
    }

    public GetLastAuthSchema(): WFDocumentAuthSchema {
        for (let ii: number = this.m_authpaths.length - 1; ii > -1; ii--) {
            let shm: WFDocumentAuthSchema = this.m_authpaths[ii];
            if (shm.ID > 0) return shm;
        }
        return null;
    }

    public CheckApprove(user_id: number): boolean {
        let st: WFAuthStatus = this.GetStatus();
        if (st === WFAuthStatus.WAITING && this.m_authpaths.length > 0) {
            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) {
                let drc: WFDocumentAuthRecipient = lshm.FindAuthRecipient(user_id);
                if (drc == null) {
                    if (this.m_user_id === user_id)
                        drc = lshm.FindAuthRecipient(WFAuthSchema.OBJID_OWNER);
                }
                if (drc != null)
                    return true;
            }
        }
        return false;
    }

    public CheckApprove2(users_id: number[]): number {
        let ii: number;
        let st: WFAuthStatus = this.GetStatus();
        if (st === WFAuthStatus.WAITING && this.m_authpaths.length > 0) {
            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) {
                for (ii = 0; ii < users_id.length; ii++) {
                    let ruser_id: number = users_id[ii];
                    let drc: WFDocumentAuthRecipient = lshm.FindAuthRecipient(ruser_id);
                    if (drc == null) {
                        if (this.m_user_id === ruser_id)
                            drc = lshm.FindAuthRecipient(WFAuthSchema.OBJID_OWNER);
                    }
                    if (drc != null)
                        return ruser_id;
                }
            }
        }
        return 0;
    }

    //

    public ChangeStatus(user_id: number,
        sig_user_id: number,
        approve: boolean,
        comments: string,
        vars: Map<number, IWFDocumentAuthVariable>,
        sys: WFSystem): boolean {

        let ii: number, tmpi: number;
        let tmpf: number;
        let st: WFAuthStatus = this.GetStatus();
        if (st === WFAuthStatus.WAITING && this.m_authpaths.length > 0) {
            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) {
                let drc: WFDocumentAuthRecipient = lshm.FindAuthRecipient(user_id);
                if (drc == null) {
                    if (this.m_user_id === user_id)
                        drc = lshm.FindAuthRecipient(WFAuthSchema.OBJID_OWNER);
                }

                if (drc != null) {
                    if (approve && lshm.Type === WFAuthMethodType.AUTH_CUSTOM) {
                        let actshm: WFAuthSchema = sys.AllSchemas.get(lshm.AuthSchemaID);
                        let actins: WFAuthInstruction = actshm.GetInstructionByID(lshm.InstructionInternalID);// drc.InstructionInternalID);
                        if (actins != null) {
                            let vars2: Map<number, IWFDocumentAuthVariable> = new Map<number, IWFDocumentAuthVariable>();
                            let pcc: number = actins.GetParamsCount();
                            for (ii = 0; ii < pcc; ii++) {
                                let par: WFInstructionParameter = actins.GetParam(ii);
                                if (!vars.has(par.VariableID)) return false;

                                let vvr: WFProcessVariable = actshm.GetVariableByID(par.VariableID);
                                let vvr2: IWFDocumentAuthVariable = vars.get(par.VariableID);
                                let vvr3: IWFDocumentAuthVariable;
                                if (vvr.Type === WFProcessVarType.TYPE_SCHEMA) {
                                    if (vvr2.Type !== WFDocAuthVarType.TYPE_SCHEMA) return false;

                                    let shmvar: WFDocumentAuthVarSchema = <WFDocumentAuthVarSchema>vvr2;
                                    if (!par.AllowNull && shmvar.Recipients.length === 0) return false;
                                    vvr3 = new WFDocumentAuthVarSchema(shmvar.ToObject());
                                } else {
                                    if (vvr2.Type !== WFDocAuthVarType.TYPE_BASIC) return false;

                                    let varr: WFDocumentAuthVarBasic = <WFDocumentAuthVarBasic>vvr2;
                                    let bvar: WFDocumentAuthVarBasic = WFDocumentAuthVarBasic.Create();
                                    switch (vvr.Type) {
                                        case WFProcessVarType.TYPE_INT:
                                            for (let fval of varr.Values) {
                                                if (fval.length > 0) {
                                                    if (!isNaN(Number.parseInt(fval)))
                                                        bvar.Values.push(fval);
                                                }
                                            }
                                            break;
                                        case WFProcessVarType.TYPE_FLOAT:
                                            for (let fval of varr.Values) {
                                                if (fval.length > 0) {
                                                    let cfval: string = fval.replace(',', '.');
                                                    if (!isNaN(Number.parseFloat(cfval)))
                                                        bvar.Values.push(cfval);
                                                }
                                            }
                                            break;
                                        case WFProcessVarType.TYPE_TEXT:
                                            for (let sval of varr.Values) {
                                                if (sval.length > 0)
                                                    bvar.Values.push(sval);
                                            }
                                            break;
                                    }
                                    if (!par.AllowNull && bvar.Values.length === 0) return false;
                                    vvr3 = bvar;
                                }
                                vars2.set(par.VariableID, vvr3);
                            }
                            lshm.MergeVariables(vars2);
                        }
                    }
                    if (drc.ChangeStatus(approve, comments, sig_user_id)) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
    private GetActualAuthSchema(user_id: number): WFDocumentAuthSchema {
        let st: WFAuthStatus = this.GetStatus();
        if (st === WFAuthStatus.WAITING && this.m_authpaths.length > 0) {
            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) {
                let isown: boolean = (this.m_user_id === user_id);
                let drc: WFDocumentAuthRecipient = lshm.FindAuthRecipient(user_id);
                if (drc == null) {
                    if (isown)
                        drc = lshm.FindAuthRecipient(WFAuthSchema.OBJID_OWNER);
                }
                if (drc != null) {
                    return lshm.FindAuthSchema(drc);
                }
            }
        }
        return null;
    }
    public CanLockAuthSchema(user_id: number): boolean {
        let shm: WFDocumentAuthSchema = this.GetActualAuthSchema(user_id);
        if (shm != null) {
            let res: boolean = shm.CanLock(user_id);
            if (!res && (this.m_user_id === user_id))
                res = shm.CanLock(WFAuthSchema.OBJID_OWNER);
            return res;
        }
        return false;
    }
    public LockAuthSchema(user_id: number, sys: WFSystem): boolean {
        let shm: WFDocumentAuthSchema = this.GetActualAuthSchema(user_id);
        if (shm != null) {
            let res: boolean = shm.LockSchema(user_id);
            if (!res && (this.m_user_id === user_id))
                res = shm.LockSchema(WFAuthSchema.OBJID_OWNER);
            return res;
        }
        return false;
    }
    public CanUnLockAuthSchema(user_id: number): boolean {
        let shm: WFDocumentAuthSchema = this.GetActualAuthSchema(user_id);
        if (shm != null) {
            let res: boolean = shm.CanUnLock(user_id);
            if (!res && (this.m_user_id === user_id))
                res = shm.CanUnLock(WFAuthSchema.OBJID_OWNER);
            return res;
        }
        return false;
    }
    public UnLockAuthSchema(user_id: number, sys: WFSystem): boolean {
        let shm: WFDocumentAuthSchema = this.GetActualAuthSchema(user_id);
        if (shm != null) {
            let res: boolean = shm.UnLockSchema(user_id);
            if (!res && (this.m_user_id === user_id))
                res = shm.UnLockSchema(WFAuthSchema.OBJID_OWNER);
            return res;
        }
        return false;
    }
    public ListPermissions(includedoc: boolean, ptp: WFPropType, sys: WFSystem, /*addcanedit: boolean,*/ stage: WFPrmDocState, authschemaid: number): Array<WFPermissionInfo> {
        let props: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        /*if (addcanedit) {
            let adusr = true;

            for (let ugr of Array.from(sys.AllUserGroups.values())) {
                if (ugr.GroupRole === WFGroupRole.CREATORS && ugr.Users.indexOf(this.m_user_id) >= 0) {
                    for (let uid of ugr.Users) {
                        props.push(new WFPermissionInfo(uid, ugr.ID, WFPrmType.CHANGE));
                    }
                    adusr = false;
                }
            }

            if (adusr) props.push(new WFPermissionInfo(this.m_user_id, -1, WFPrmType.CHANGE));
        }*/

        if (this.m_company_id > 0) {
            let cmp: WFCompany = sys.AllCompanies.get(this.m_company_id);
            for (let prm of cmp.Permissions) {
                if ((prm.DocState === stage) && (prm.AuthSchemaID === 0 || prm.AuthSchemaID === authschemaid || (prm.AuthSchemaID < 0 && authschemaid == WFDocument.SCHM_CUSTOM)) && (prm.PropertyType === ptp)) 
                    WFPermission.ActualizePermissionInfoList(props, prm, sys, this);
            }
        }
        if (this.m_class_id > 0) {
            let allcls: Array<WFClass> = new Array<WFClass>();
            let cls: WFClass = sys.AllClasses.get(this.m_class_id);
            while (cls != null) {
                allcls.push(cls);
                cls = (cls.BaseClassID > 0) ? sys.AllClasses.get(cls.BaseClassID) : null;
            }
            for (let ii: number = allcls.length - 1; ii > -1; ii--) {
                cls = allcls[ii];
                for (let prm of cls.Permissions) {
                    if ((prm.DocState === stage) && (prm.AuthSchemaID === 0 || prm.AuthSchemaID === authschemaid || (prm.AuthSchemaID < 0 && authschemaid == WFDocument.SCHM_CUSTOM)) && (prm.PropertyType === ptp)) 
                        WFPermission.ActualizePermissionInfoList(props, prm, sys, this);
                }
            }
        }

        if (includedoc) {
            for (let prm of this.m_perms) {
                if ((prm.DocState === stage) && (prm.AuthSchemaID === 0 || prm.AuthSchemaID === authschemaid || (prm.AuthSchemaID < 0 && authschemaid == WFDocument.SCHM_CUSTOM)) && (prm.PropertyType === ptp)) 
                    WFPermission.ActualizePermissionInfoList(props, prm, sys, this);
            }
        }

        let ret: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        if (props.length > 0) {
            let ids: Array<number> = new Array(props.length);
            let ids_c = 0;
            for (let ii: number = props.length - 1; ii > -1; ii--) {
                let prmi: WFPermissionInfo = props[ii];
                let uid = (prmi.UserID === WFAuthSchema.OBJID_OWNER) ? this.m_user_id : prmi.UserID;
                if (ids.indexOf(uid, 0) < 0) {
                    ret.push(prmi);
                    ids[ids_c++] = uid;
                }
            }
        }
        return ret;
    }

    public ListPermissions2(includedoc: boolean, atribid: number, sys: WFSystem, /*addcanedit: boolean,*/ stage: WFPrmDocState, authschemaid: number): Array<WFPermissionInfo> {
        let props: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        /*if (addcanedit) {
            let adusr = true;

            for (let ugr of Array.from(sys.AllUserGroups.values())) {
                if (ugr.GroupRole === WFGroupRole.CREATORS && ugr.Users.indexOf(this.m_user_id) >= 0) {
                    for (let uid of ugr.Users) {
                        props.push(new WFPermissionInfo(uid, ugr.ID, WFPrmType.CHANGE));
                    }
                    adusr = false;
                }
            }

            if (adusr) props.push(new WFPermissionInfo(this.m_user_id, -1, WFPrmType.CHANGE));
        }*/
        if (this.m_company_id > 0) {
            let cmp: WFCompany = sys.AllCompanies.get(this.m_company_id);
            for (let prm of cmp.Permissions) {
                if ((prm.DocState === stage) && (prm.AuthSchemaID === 0 || prm.AuthSchemaID === authschemaid || (prm.AuthSchemaID < 0 && authschemaid == WFDocument.SCHM_CUSTOM)) &&
                    ((prm.PropertyType === WFPropType.DOC_CANEDIT) /*&& addcanedit)*/ || (prm.PropertyType === WFPropType.DOC_CANEDITATTRIB && (prm.AttribID === atribid || prm.AttribID === 0))))
                        WFPermission.ActualizePermissionInfoList(props, prm, sys, this);
            }
        }
        if (this.m_class_id > 0) {
            let allcls: Array<WFClass> = new Array<WFClass>();
            let cls: WFClass = sys.AllClasses.get(this.m_class_id);
            while (cls != null) {
                allcls.push(cls);
                cls = (cls.BaseClassID > 0) ? sys.AllClasses.get(cls.BaseClassID) : null;
            }
            for (let ii: number = allcls.length - 1; ii > -1; ii--) {
                cls = allcls[ii];
                for (let prm of cls.Permissions) {
                    if ((prm.DocState === stage) && (prm.AuthSchemaID === 0 || prm.AuthSchemaID === authschemaid || (prm.AuthSchemaID < 0 && authschemaid == WFDocument.SCHM_CUSTOM)) &&
                        ((prm.PropertyType === WFPropType.DOC_CANEDIT)/* && addcanedit)*/ || (prm.PropertyType === WFPropType.DOC_CANEDITATTRIB && (prm.AttribID === atribid || prm.AttribID === 0))))
                        WFPermission.ActualizePermissionInfoList(props, prm, sys, this);
                }
            }
        }
        if (includedoc) {
            for (let prm of this.m_perms) {
                if ((prm.DocState === stage) && (prm.AuthSchemaID === 0 || prm.AuthSchemaID === authschemaid || (prm.AuthSchemaID < 0 && authschemaid == WFDocument.SCHM_CUSTOM)) &&
                    ((prm.PropertyType === WFPropType.DOC_CANEDIT)/* && addcanedit)*/ || (prm.PropertyType === WFPropType.DOC_CANEDITATTRIB && (prm.AttribID === atribid || prm.AttribID === 0))))
                    WFPermission.ActualizePermissionInfoList(props, prm, sys, this);
            }
        }
        let ret: Array<WFPermissionInfo> = new Array<WFPermissionInfo>();
        if (props.length > 0) {
            let ids: Array<number> = new Array(props.length);
            let ids_c = 0;
            for (let ii: number = props.length - 1; ii > -1; ii--) {
                let prmi: WFPermissionInfo = props[ii];
                let uid = (prmi.UserID === WFAuthSchema.OBJID_OWNER) ? this.m_user_id : prmi.UserID;
                if (ids.indexOf(uid, 0) < 0) {
                    ret.push(prmi);
                    ids[ids_c++] = uid;
                }
            }
        }
        return ret;
    }

    public CheckVisibility(usr: WFUser, sys: WFSystem): boolean {
        let visible = true;

        if (this.m_docstatus === WFDocStatus.INSYSTEM) {
            let stage: WFPrmDocState = WFPrmDocState.APPROVEDNOAUTH;
            let authschemaid = 0;

            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) {
                authschemaid = lshm.AuthSchemaID;

                if (lshm.AuthSchemaID !== WFDocument.SCHM_NOAUTH) {
                    let sts: WFAuthStatus = lshm.GetStatus();
                    if (sts !== WFAuthStatus.APPROVED) {
                        stage = (sts === WFAuthStatus.WAITING) ? WFPrmDocState.INAUTH : WFPrmDocState.DISAPPROVED;
                        visible = lshm.UserExists(usr.ID);
                    }
                }
            }

            let isown = (this.m_user_id === usr.ID);
            let replusrs: number[] = usr.FindReplacements(sys.AllUsers);
            let pinfs: Array<WFPermissionInfo> = this.ListPermissions(true, WFPropType.DOC_CANEDIT, sys, stage, authschemaid);
            for (let inf of pinfs) {
                if ((inf.Type === WFPrmType.DISABLE) && ((inf.UserID === usr.ID) || replusrs.indexOf(inf.UserID) >= 0) || (inf.UserID === WFAuthSchema.OBJID_OWNER && isown)) {
                    visible = false;
                    break;
                }
            }
        }

        return visible;
    }

    /*private CheckLevelVisibility(userid: number,
        aid: WFDocumentAuthRecipient,
        shm: WFDocumentAuthSchema): number {
        let ii: number;
        let cc: number = shm.GetRecipientsCount();
        let trcps: WFDocumentAuthRecipient[] = new Array(cc);
        for (; ii < cc; ii++) {
            trcps[ii] = shm.GetRecipient(ii);
        }
        Array<any>.Sort(trcps, (s1, s2) => s1.Order - s2.Order);
        for (; ii < trcps.length; ii++) {
            let rc: WFDocumentAuthRecipient = trcps[ii];
            switch (rc.ObjType) {
                case WFDocAuthObjType.DOCAUTHSCHEMA:
                    let res: number = CheckLevelVisibility(userid, aid, rc.SubSchema);
                    if (res != 0)
                        return res;
                    break;
                case WFDocAuthObjType.USER:
                    if ((rc.ObjID == userid) || (rc.ObjID == WFAuthSchema.OBJID_OWNER && this.m_user_id == userid))
                        return 1;
                    break;
            }
            if (rc == aid)
                return -1;
        }
        return 0;
    }
    public CheckExists(user_id: number): boolean {
        if (this.m_authpaths.length > 0) {
            let lshm: WFDocumentAuthSchema = this.GetLastAuthSchema();
            if (lshm != null) {
                if (lshm.UserExists(user_id))
                    return true;
                if (this.m_user_id == user_id) {
                    if (lshm.UserExists(WFAuthSchema.OBJID_OWNER))
                        return true;
                }
            }
        }
        return false;
    }*/

    public get Properties(): UpperMap {
        return this.m_properties;
    }

    public get Lines(): Array<WFDocumentLine> {
        return this.m_lines;
    }

    public set DocStatus(value: WFDocStatus) {
        this.m_docstatus = value;
    }

    public get DocStatus(): WFDocStatus {
        return this.m_docstatus;
    }

    public set ReconSchemasCode(value: string) {
        if (value != null) {
            let nval: string = value.trim();
            if (nval.length > 0) {
                if (!RegExp('^[0-9a-zA-Z]+$').test(nval)) throw new Error();
                this.m_reconschemas_code = nval;
                return;
            }
        }
        this.m_reconschemas_code = null;
    }

    public get ReconSchemasCode(): string {
        return this.m_reconschemas_code;
    }
    public set CanChangeResource(value: boolean) {
        this.m_canchangeres = value;
    }
    public get CanChangeResource(): boolean {
        return this.m_canchangeres;
    }
    public get SysDocNum(): number {
        return this.m_sysdocnum;
    }
    public GetModificationsCount(): number {
        return this.m_modifications.length;
    }
    public GetModification(id: number): WFDocumentModification {
        return this.m_modifications[id];
    }

    private SetProperty(obj: any, pname: string, val: string): void {
        //let prps = Object.getOwnPropertyNames(obj);
        //let smb= Object.getOwnPropertySymbols(obj);

        //if (prps.indexOf(pname) < 0) throw new Error(IWFObject.Format('Property {0}.{1} not found', typeof obj, pname));

        //let tp = Object.getOwnPropertyDescriptor(obj, pname);

        //if (tp.writable) {
        try {
            let oval = obj[pname];
            if (typeof oval === 'number') {
                obj[pname] = Number.parseInt(val);
                return;
            }

            if (oval instanceof Date) {
                obj[pname] = Date.parse(val);
                return;
            }

            obj[pname] = val;
        } catch (ex) {

        }
        //}

        /*let tp: Type = obj.GetType();
        let inf: PropertyInfo = tp.GetProperty(pname);
        if (inf == null)
            throw new Exception(String.Format("Property {0}.{1} not found", tp.Name, pname));
        if (inf.CanWrite) {
            let ptp: Type = inf.PropertyType;
            if (ptp.IsEnum || ptp == number) {
                let ival: number = number.Parse(val);
                inf.SetValue(obj, ival, null);
                return
            }
            if (ptp == Date) {
                let dta: Date = (String.IsNullOrEmpty(val)) ? Date.MinValue : Date.Parse(val);
                inf.SetValue(obj, dta, null);
                return
            }
            if (ptp == number) {
                let fval: number = (String.IsNullOrEmpty(val)) ? 0 : number.Parse(val);
                inf.SetValue(obj, fval, null);
                return
            }
            inf.SetValue(obj, val, null);
        }*/
    }


    public RebuildDocument(id: number): WFDocument {
        let ii: number;
        let ii2: number;

        let dnow = new Date();
        let ndoc: WFDocument = new WFDocument({
            id: 0,
            user_id: this.m_user_id,
            class_id: this.m_class_id,
            company_id: this.m_company_id,
            directory_id: this.m_directory_id,
            name: this.m_name,
            description: this.m_description,
            docnum: this.m_docnum,
            docdate: this.m_docdate,
            createdat: dnow,
            updatedat: dnow,
            docref: [],
            docpages: [],
            perms: [],
            properties: [],
            docstatus: this.m_docstatus,
            reconschemas_code: this.m_reconschemas_code,
            enterdate: this.m_enterdate,
            template_id: this.m_template_id,
            modifications: [],
            lines: [],
            iseditable: this.m_iseditable,
            ocrresults: [],
            definitioncode: this.m_definitioncode,
            ocrcode: this.m_ocrcode,
            defeditmode0: this.m_defeditmode[0],
            defeditmode1: this.m_defeditmode[1],
            defeditmode2: this.m_defeditmode[2],
            defeditmode3: this.m_defeditmode[3],
            defeditmode4: this.m_defeditmode[4],
            defeditmode5: this.m_defeditmode[5],
            hdreditmodex: [],
            lnseditmodex: [],
            attachments_id: [],
            curauthstatus: this.m_curauthstatus,
            canchangeres: this.m_canchangeres,
            authpaths: [],
            sysdocnum: this.m_sysdocnum,
            rm_users_id: this.m_rm_users_id,
            tmpwritemode: this.m_tmpwritemode,
            autopublish: this.m_autopublish,
            ocrmaps: [],
            grouping: this.m_grouping
        });

        let pgs = new Map<number, WFDocumentPage>();
        for (ii = 0; ii < this.m_docpages.length; ii++) {
            let srcpg: WFDocumentPage = this.m_docpages[ii];
            let npg: WFDocumentPage = WFDocumentPage.Create(srcpg.ResourcesID, srcpg.ResourcesPageNum);
            pgs.set(srcpg.ID, npg);
        }

        let refs = new Map<number, WFDocumentRef>();
        for (ii = 0; ii < this.m_docref.length; ii++) {
            let srcrf: WFDocumentRef = this.m_docref[ii];
            let nrf: WFDocumentRef = WFDocumentRef.Create(srcrf.CompanyID, srcrf.ObjectType, srcrf.ObjectID);
            refs.set(srcrf.ID, nrf);
        }

        let perms = new Map<number, WFPermission>();
        for (ii = 0; ii < this.m_perms.length; ii++) {
            let srcprm: WFPermission = this.m_perms[ii];
            let nprm: WFPermission = WFPermission.Create(srcprm.PropertyType, srcprm.PermissionType, srcprm.ObjID, srcprm.ObjType);
            perms.set(srcprm.ID, nprm);
        }

        for (let propkv of Array.from(this.m_properties.entries())) {
            ndoc.m_properties.set(propkv[0], propkv[1]);
        }

        for (ii = 0; ii < this.m_lines.length; ii++) {
            let ln: WFDocumentLine = this.m_lines[ii];
            let lndta = ln.ToObject();
            WFDocument.ZeroID(lndta);
            let nln: WFDocumentLine = new WFDocumentLine(lndta);
            ndoc.m_lines.push(nln);
        }

        let stoff: number = id - 1;
        for (ii = this.m_modifications.length - 1; ii > stoff; ii--) {
            let docm: WFDocumentModification = this.m_modifications[ii];
            let len: number = docm.GetEntriesCount();
            for (ii2 = 0; ii2 < len; ii2++) {
                let ent = docm.GetEntry(ii2);
                if (IWFObject.IsNullOrEmpty(ent.FieldCode)) {
                    switch (ent.ObjType) {
                        case WFModObjType.DOCUMENT:
                            if (ent.FieldCode[0] === '_') {
                                let fname: string = ent.FieldCode.substr(1);
                                ndoc.m_properties.delete(fname);
                            }
                            break;
                        case WFModObjType.PAGE:
                            pgs.delete(ent.ObjId);
                            break;
                        case WFModObjType.REF:
                            refs.delete(ent.ObjId);
                            break;
                        case WFModObjType.PERMISSION:
                            perms.delete(ent.ObjId);
                            break;
                        case WFModObjType.LINE:
                            if (ent.ObjId < ndoc.m_lines.length)
                                ndoc.m_lines.splice(ent.ObjId, 1);
                            break;
                    }
                } else {
                    if (ent.FieldCode[0] === '_') {
                        let fname: string = ent.FieldCode.substr(1);
                        ndoc.m_properties.set(fname, ent.OldValue);
                    } else {
                        switch (ent.ObjType) {
                            case WFModObjType.DOCUMENT:
                                switch (ent.FieldCode) {
                                    case 'UserID':
                                        ndoc.m_user_id = Number.parseInt(ent.OldValue);
                                        break;
                                    case 'ClassID':
                                        ndoc.m_class_id = Number.parseInt(ent.OldValue);
                                        break;
                                    case 'DocStatus':
                                        ndoc.m_docstatus = <WFDocStatus>Number.parseInt(ent.OldValue);
                                        break;
                                    case 'AuthStatus':
                                        break;
                                    default:
                                        this.SetProperty(ndoc, ent.FieldCode, ent.OldValue);
                                        break;
                                }
                                break;
                            case WFModObjType.PAGE:
                                if (!pgs.has(ent.ObjId))
                                    pgs.set(ent.ObjId, WFDocumentPage.Create(0, 0));
                                let pg: WFDocumentPage = pgs.get(ent.ObjId);
                                switch (ent.FieldCode) {
                                    case 'ID':
                                        break;
                                    default:
                                        this.SetProperty(pg, ent.FieldCode, ent.OldValue);
                                        break;
                                }
                                break;
                            case WFModObjType.REF:
                                if (!refs.has(ent.ObjId))
                                    refs.set(ent.ObjId, WFDocumentRef.Create(0, '', ''));
                                let rf: WFDocumentRef = refs.get(ent.ObjId);
                                switch (ent.FieldCode) {
                                    case 'ID':
                                        break;
                                    default:
                                        this.SetProperty(rf, ent.FieldCode, ent.OldValue);
                                        break;
                                }
                                break;
                            case WFModObjType.PERMISSION:
                                if (!perms.has(ent.ObjId))
                                    perms.set(ent.ObjId, WFPermission.Create(WFPropType.DOC_CANEDIT, WFPrmType.VIEW, 1, WFPrmObjType.USER));
                                let pr: WFPermission = perms.get(ent.ObjId);
                                switch (ent.FieldCode) {
                                    case 'ID':
                                        break;
                                    default:
                                        this.SetProperty(pr, ent.FieldCode, ent.OldValue);
                                        break;
                                }
                                break;
                            case WFModObjType.LINE:
                                let dstid: number = ent.ObjId + 1;
                                for (let lid: number = ndoc.Lines.length; lid < dstid; lid++) {
                                    let nln: WFDocumentLine = WFDocumentLine.Create(0);
                                    ndoc.Lines.push(nln);
                                }
                                let actln: WFDocumentLine = ndoc.Lines[ent.ObjId];
                                actln.set(ent.FieldCode, ent.OldValue);
                                break;
                        }
                    }
                }
            }
        }
        for (ii = 0; ii < pgs.size; ii++) {
            ndoc.m_docpages.push(IWFObject.ElementAt(pgs.values(), ii));
        }
        for (ii = 0; ii < refs.size; ii++) {
            ndoc.m_docref.push(IWFObject.ElementAt(refs.values(), ii));
        }
        for (ii = 0; ii < perms.size; ii++) {
            ndoc.m_perms.push(IWFObject.ElementAt(perms.values(), ii));
        }
        return ndoc;
    }
    /*public static DATEFORMATS: string[] = ["d",
        "d-M-yy",
        "d-MM-yy",
        "d-M-yyyy",
        "d-MM-yyyy",
        "dd-M-yy",
        "dd-MM-yy",
        "dd-M-yyyy",
        "dd-MM-yyyy",
        "d/M/yy",
        "d/MM/yy",
        "d/M/yyyy",
        "d/MM/yyyy",
        "dd/M/yy",
        "dd/MM/yy",
        "dd/M/yyyy",
        "dd/MM/yyyy",
        "yy-M-d",
        "yy-MM-d",
        "yyyy-M-d",
        "yyyy-MM-d",
        "yy-M-dd",
        "yy-MM-dd",
        "yyyy-M-dd",
        "yyyy-MM-dd",
        "yy/M/d",
        "yy/MM/d",
        "yyyy/M/d",
        "yyyy/MM/d",
        "yy/M/dd",
        "yy/MM/dd",
        "yyyy/M/dd",
        "yyyy/MM/dd"];*/

    private GetConstructor(val: string): string {
        val = val.replace(/\\/g, '\\\\'); 
        val = val.replace(/\'/g, '\\\'');
        val = val.replace(/\r/g, ' ');
        val = val.replace(/\n/g, ' '); 
        return IWFObject.Format('\'{0}\'', val);
    }
    private GetConstructor2(dta: Date): string {
        return IWFObject.Format('new Date({0},{1},{2},{3},{4},{5})', dta.getFullYear(), dta.getMonth(), dta.getDate(), dta.getHours(), dta.getMinutes(), dta.getSeconds());
    }
    private GetConstructor3(dval: number): string {
        return dval.toString().replace(',', '.');
    }
    private GetAnyConstructor(val: Object): string {
        if (val == null)
            return 'null';
        if (typeof val === 'number')
            return this.GetConstructor3(<number>val);
        if (val instanceof Date)
            return this.GetConstructor2(<Date>val);
        if (typeof val === 'string')
            return this.GetConstructor(<string>val);
        return val.toString();
    }

    public GetJSProperty(pname: string, val: number): string {
        return IWFObject.Format('var {0}={1};', pname, val);
    }
    public GetJSProperty2(pname: string, val: string): string {
        return IWFObject.Format('var {0}={1};', pname, this.GetConstructor(val));
    }
    public GetJSProperty3(pname: string, dta: Date): string {
        return IWFObject.Format('var {0}={1};', pname, this.GetConstructor2(dta));
    }
    public GetJSProperty4(pname: string, dval: number): string {
        return IWFObject.Format('var {0}={1};', pname, this.GetConstructor3(dval));
    }
    public GetAnyJSProperty(pname: string, val: Object): string {
        return IWFObject.Format('var {0}={1};', pname, this.GetAnyConstructor(val));
    }

    private ParseDate(str: string): Date {
        if (str != null && str.length > 9) {
            let day = IWFObject.ParseInt(str.substr(0, 2));
            let month = IWFObject.ParseInt(str.substr(3, 2));
            let year = IWFObject.ParseInt(str.substr(6, 4));
            return new Date(year, month - 1, day);
        }
        return null;
    }

    public PrepareScript(evcd: string, sys: WFSystem, lid: number = -1): Array<string> {
        let ii: number, ii2: number;
        let sval: string;
        let ln: UpperMap;
        let cls: WFClass = null;
        let allattribs: Array<WFClassAttrib> = new Array<WFClassAttrib>();
        let allcolumns: Array<WFClassAttrib> = new Array<WFClassAttrib>();
        if (this.m_class_id > 0) {
            cls = sys.AllClasses.get(this.m_class_id);
            if (cls.ID !== this.m_class_id)
                throw new Error();
            let clspath: Array<WFClass> = new Array<WFClass>();
            let actcls: WFClass = cls;
            while (actcls != null) {
                clspath.push(actcls);
                actcls = (actcls.BaseClassID > 0) ? sys.AllClasses.get(actcls.BaseClassID) : null;
            }
            for (ii = clspath.length - 1; ii > -1; ii--) {
                let cl: WFClass = clspath[ii];
                allattribs = allattribs.concat(cl.Attributes);
                allcolumns = allcolumns.concat(cl.Columns);
            }
        }
        let usr: WFUser = sys.AllUsers.get(this.m_user_id);
        if (this.m_user_id !== usr.ID)
            throw new Error();

        let bld: Array<string> = new Array<string>();
        bld.push('function lsum(lcnd,lop) { ');
        bld.push('var ret= 0; ');
        bld.push('if(lcnd==\'\') { ');
        bld.push('for(var X=0; X< L.length; X++) ret+= eval(lop); ');
        bld.push('} else { ');
        bld.push('for(var X=0; X< L.length; X++) { ');
        bld.push('if(eval(lcnd)) ret+= eval(lop); ');
        bld.push('}} ');
        bld.push('return ret; ');
        bld.push('} ');

        let evalins: Array<string> = new Array<string>();
        evalins.push(this.GetJSProperty('USERID', this.m_user_id));
        evalins.push(this.GetJSProperty2('USER', usr.Name));
        evalins.push(this.GetJSProperty2('USERNAME', usr.UserName));
        evalins.push(this.GetJSProperty2('USEREMAIL', usr.EMailReply));
        evalins.push(this.GetJSProperty('CLASSID', this.m_class_id));
        evalins.push(this.GetJSProperty('DOCID', this.m_id));
        evalins.push(this.GetJSProperty('COMPANYID', this.m_company_id));
        evalins.push(this.GetJSProperty2('NAME', this.m_name));
        evalins.push(this.GetJSProperty2('DESCRIPTION', this.m_description));
        evalins.push(this.GetJSProperty2('DOCNUM', this.m_docnum));
        evalins.push(this.GetJSProperty3('DOCDATE', this.m_docdate));
        evalins.push(this.GetJSProperty3('ENTERDATE', this.EnterDate));

        for (let satr of allattribs) {
            let pname: string = IWFObject.Format('_{0}', satr.Name.toUpperCase());
            let pval: Object = null;
            switch (satr.Type) {
                case WFFieldType.TYPE_FLOAT:
                    if(this.m_properties.has(satr.Name)) {
                        try {
                            pval= IWFObject.ParseFloat(this.m_properties.get(satr.Name));
                        } catch(ex) { }
                    } 
                    break;
                case WFFieldType.TYPE_INT:
                    if(this.m_properties.has(satr.Name)) {
                        try {
                            pval= IWFObject.ParseInt(this.m_properties.get(satr.Name));
                        } catch(ex) { }
                    }
                    break;
                case WFFieldType.TYPE_DATETIME:
                    if(this.m_properties.has(satr.Name)) {
                        try {
                            pval= this.ParseDate(this.m_properties.get(satr.Name));
                        } catch(ex) { }
                    }
                    break;
                default:
                    pval = (this.m_properties.has(satr.Name)) ? this.m_properties.get(satr.Name) : '';
                    break;
            }
            evalins.push(this.GetAnyJSProperty(pname, pval));
        }

        let chctx: string;
        if (!IWFObject.IsNullOrEmpty(sys.Config.JSFunctions)) {
            chctx = IWFObject.Format('{0} {1}', sys.Config.JSFunctions, evcd);
        } else {
            chctx = evcd;
        }
        let lines: Array<Array<Object>>;
        if (allcolumns.length > 0 && (chctx.indexOf('L[') >= 0 || chctx.indexOf('L.') >= 0 || chctx.indexOf('lsum') >= 0)) {
            lines = new Array(this.m_lines.length);
            for (ii = 0; ii < this.m_lines.length; ii++) {
                lines[ii] = new Array(allcolumns.length);
            }
            for (ii = 0; ii < allcolumns.length; ii++) {
                let col: WFClassAttrib = allcolumns[ii];
                let cname: string = col.Name.toUpperCase();
                evalins.push(this.GetJSProperty(cname, ii));
                switch (col.Type) {
                    case WFFieldType.TYPE_INT:
                        for (ii2 = 0; ii2 < this.m_lines.length; ii2++) {
                            ln = this.m_lines[ii2];
                            lines[ii2][ii] = null;
                            if (ln.has(col.Name)) {
                                sval = ln.get(col.Name);   
                                if(!IWFObject.IsNullOrEmpty(sval)) {
                                    try {
                                        lines[ii2][ii]= IWFObject.ParseInt(sval);
                                    } catch(ex) { }
                                }
                            } 
                        }
                        break;
                    case WFFieldType.TYPE_FLOAT:
                        for (ii2 = 0; ii2 < this.m_lines.length; ii2++) {
                            ln = this.m_lines[ii2];
                            lines[ii2][ii] = null;
                            if (ln.has(col.Name)) {
                                sval = ln.get(col.Name);
                                if(!IWFObject.IsNullOrEmpty(sval)) {
                                    try {
                                        lines[ii2][ii] = IWFObject.ParseFloat(sval);//<number>0;
                                    } catch(ex) { }
                                }
                            } 
                        }
                        break;
                    case WFFieldType.TYPE_DATETIME:
                        for (ii2 = 0; ii2 < this.m_lines.length; ii2++) {
                            ln = this.m_lines[ii2];
                            lines[ii2][ii] = null;
                            if (ln.has(col.Name)) {
                                sval = ln.get(col.Name);
                                if(!IWFObject.IsNullOrEmpty(sval)) {
                                    try {
                                        lines[ii2][ii]= this.ParseDate(sval);// new Date(Date.parse(sval)) : null;
                                    } catch(ex) { }
                                }
                            } 
                        }
                        break;
                    default:
                        for (ii2 = 0; ii2 < this.m_lines.length; ii2++) {
                            ln = this.m_lines[ii2];
                            if (ln.has(col.Name)) {
                                sval = ln.get(col.Name);
                                lines[ii2][ii] = (!IWFObject.IsNullOrEmpty(sval)) ? sval : '';
                            } else {
                                lines[ii2][ii] = null;
                            }
                        }
                        break;
                }
            }
        } else {
            lines = null;
        }

        let ret: Object;
        if (lines != null) {
            let lbld: Array<string> = new Array<string>();
            for (ii = 0; ii < lines.length; ii++) {
                if (ii > 0) lbld.push(',');
                lbld.push('Array(');
                let row: Array<Object> = lines[ii];
                lbld.push(this.GetAnyConstructor(row[0]));
                for (ii2 = 1; ii2 < row.length; ii2++) {
                    lbld.push(',');
                    lbld.push(this.GetAnyConstructor(row[ii2]));
                }
                lbld.push(')');
            }
            evalins.push(IWFObject.Format('var L= Array({0});', lbld.join('\n')));
        } else {
            evalins.push('var L= Array();');
        }
        evalins.push(this.GetJSProperty('LID', lid));
        evalins.push(bld.join('\n'));
        if (!IWFObject.IsNullOrEmpty(sys.Config.JSFunctions)) {
            evalins.push(sys.Config.JSFunctions);
        }
        evalins.push('function _inner_eval() {');

        if (evcd.indexOf('return') >= 0) {
            evalins.push(evcd);
        } else {
            evalins.push(IWFObject.Format('return {0};', evcd));
        }

        evalins.push('}');
        evalins.push('_inner_eval()');
        return evalins;
    }

    public EvalScript(evcd: string, sys: WFSystem, lid: number = -1): Object {
        let evalins = this.PrepareScript(evcd, sys, lid);
        //let eng: Microsoft.JScript.Vsa.VsaEngine = Microsoft.JScript.Vsa.VsaEngine.CreateEngine();
        //ret = Microsoft.JScript.Eval.JScriptEvaluate(evalins.ToString(), eng);

        /*function lsum(lcnd,lop) {
        var ret= 0;
        if(lcnd=='') {
        for(var X=0; X< L.length; X++) ret+= eval(lop);
        } else {
        for(var X=0; X< L.length; X++) {
        if(eval(lcnd)) ret+= eval(lop);
        }}
        return ret;
        }*/

        return eval(evalins.join('\n'));
    }

    public PrepareNewDocName(sys: WFSystem): string {
        let ii: number;
        let prn: string;
        if (this.m_class_id > 0) {
            let cls: WFClass = sys.AllClasses.get(this.m_class_id);
            if (IWFObject.IsNullOrEmpty(cls.NameFormat)) {
                prn = IWFObject.Format('{0}_{{DOCNUM}}', cls.Name);
            } else {
                prn = cls.NameFormat;
            }
            prn = prn.replace(/{CLASSNAME}/g, cls.Name);
            let ccode = '';
            while (cls != null) {
                for (ii = 0; ii < cls.Attributes.length; ii++) {
                    let atr: WFClassAttrib = cls.Attributes[ii];
                    if (this.m_properties.has(atr.Name)) {
                        let vl: string = this.m_properties.get(atr.Name);
                        if (vl == null) vl = '';
                        prn = prn.replace(IWFObject.Format('{{{0}}}', atr.Name.toUpperCase()), IWFObject.ExtractValue(vl));
                        if (atr.Type === WFFieldType.TYPE_CUSTOM) ccode = vl;
                    }
                }
                cls = (cls.BaseClassID > 0) ? sys.AllClasses.get(cls.BaseClassID) : null;
            }
            prn = prn.replace(/{CARDCODE}/g, IWFObject.ExtractValue(ccode));
        } else {
            prn = 'NZ_{DOCNUM}';
        }
        prn = prn.replace(/{ID}/g, this.m_id.toString());
        prn = prn.replace(/{DOCNUM}/g, this.m_docnum);
        if (this.m_company_id > 0) {
            let cmp: WFCompany = sys.AllCompanies.get(this.m_company_id);
            prn = prn.replace(/{CMPNAME}/g, cmp.Company);
        }
        if (this.m_user_id > 0) {
            let usr: WFUser = sys.AllUsers.get(this.m_user_id);
            prn = prn.replace(/{USERNAME}/g, usr.Name);
        }

        let dta: Date;
        let strdta: string;
        let yyyy: string;

        if(this.m_docdate== null) {
            prn = prn.replace(/{DOCDATE}/g, '');
            prn = prn.replace(/{DD_DD}/g, '');
            prn = prn.replace(/{DD_MM}/g, '');
            prn = prn.replace(/{DD_YYYY}/g, '');
            prn = prn.replace(/{DD_YY}/g, '');
        } else {
            dta = this.m_docdate;
            strdta = dta.getFullYear().toString() + '-' + IWFObject.PadZeroStr(dta.getMonth() + 1) + '-' + IWFObject.PadZeroStr(dta.getDate());
            prn = prn.replace(/{DOCDATE}/g, strdta) ;
            prn = prn.replace(/{DD_DD}/g, IWFObject.PadZeroStr(dta.getDate()));
            prn = prn.replace(/{DD_MM}/g, IWFObject.PadZeroStr(dta.getMonth() + 1));
            yyyy = dta.getFullYear().toString();
            prn = prn.replace(/{DD_YYYY}/g, yyyy);
            prn = prn.replace(/{DD_YY}/g, yyyy.substr(2, 2));
        }

        if(this.m_enterdate== null) {
            prn = prn.replace(/{ENTERDATE}/g, '');
            prn = prn.replace(/{ED_DD}/g, '');
            prn = prn.replace(/{ED_MM}/g, '');
            prn = prn.replace(/{ED_YYYY}/g, '');
            prn = prn.replace(/{ED_YY}/g, '');
        } else {
            dta = this.m_enterdate;
            strdta = dta.getFullYear().toString() + '-' + IWFObject.PadZeroStr(dta.getMonth() + 1) + '-' + IWFObject.PadZeroStr(dta.getDate());
            prn = prn.replace(/{ENTERDATE}/g, strdta);
            prn = prn.replace(/{ED_DD}/g, IWFObject.PadZeroStr(dta.getDate()));
            prn = prn.replace(/{ED_MM}/g, IWFObject.PadZeroStr(dta.getMonth() + 1));
            yyyy = dta.getFullYear().toString();
            prn = prn.replace(/{ED_YYYY}/g, yyyy);
            prn = prn.replace(/{ED_YY}/g, yyyy.substr(2, 2));
        }

        dta = this.m_createdat;
        strdta = dta.getFullYear().toString() + '-' + IWFObject.PadZeroStr(dta.getMonth() + 1) + '-' + IWFObject.PadZeroStr(dta.getDate());
        prn = prn.replace(/{CREATEDATE}/g, strdta);
        prn = prn.replace(/{CD_DD}/g, IWFObject.PadZeroStr(dta.getDate()));
        prn = prn.replace(/{CD_MM}/g, IWFObject.PadZeroStr(dta.getMonth() + 1));
        yyyy = dta.getFullYear().toString();
        prn = prn.replace(/{CD_YYYY}/g, yyyy);
        prn = prn.replace(/{CD_YY}/g, yyyy.substr(2, 2));

        return prn;
    }

    public FindCardCode(sys: WFSystem): string {
        let ii: number;
        if (this.m_class_id > 0) {
            let cls: WFClass = sys.AllClasses.get(this.m_class_id);
            while (cls != null) {
                for (ii = 0; ii < cls.Attributes.length; ii++) {
                    let atr: WFClassAttrib = cls.Attributes[ii];
                    if (this.m_properties.has(atr.Name) && atr.Type === WFFieldType.TYPE_CUSTOM) {
                        let vl: string = this.m_properties.get(atr.Name);
                        if (vl == null) return '';
                        return IWFObject.ExtractValue(vl);
                    }
                }
                cls = (cls.BaseClassID > 0) ? sys.AllClasses.get(cls.BaseClassID) : null;
            }
        }
        return '';
    }
}
